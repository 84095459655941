import React, { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { swal } from "sweetalert";
import { API_CALL } from "../../../../api/ApiServiceCall";
import { API_CONSTANTS, API_METHOD } from "../../../../common/constants";
import { getCredentialLoginAccountProfileDetailAtom } from "../../../atom/getCredentialLoginAccountProfileDetail";
function CredentialProfileImageForMenu() {
  useEffect(() => {
    getAllProfileData();
  }, []);
  const [
    credentialLoginAccountProfileDetailAtom,
    setCredentialLoginAccountProfileDetailAtom,
  ] = useRecoilState(getCredentialLoginAccountProfileDetailAtom);
  const [getCredentialUserDetail, setCredentialUserDetail] = useState(
    JSON.parse(localStorage.getItem("localUserData"))
  );

  const getAllProfileData = async () => {
    const request = {
      apiUrl:
        API_CONSTANTS.BASE_URL +
        API_CONSTANTS.GET_INSTRUCTOR_BY_EMAIL +
        `?instructorEmail=${
          getCredentialUserDetail?.email ||
          getCredentialUserDetail?.data?.user?.email
        }`,
      method: API_METHOD.GET,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      setCredentialLoginAccountProfileDetailAtom(result);
    } else if (result.status > 200) {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }
  };
  const profileImgRef = useRef(null);
  const handleImageError = (profileImgRef) => {
    profileImgRef.current.src =
      "https://cdn-icons-png.flaticon.com/512/149/149071.png";
  };

  return (
    <div>
      {/* {
            credentialLoginAccountProfileDetailAtom?.data?.profileImgPath ? <img src={credentialLoginAccountProfileDetailAtom?.data?.profileImgPath} alt="Profile Image" style={{
                "height": "40px",
                "width": "40px", "objectFit": "cover"
            }} className=" rounded-circle" />
                : <img src={"https://cdn-icons-png.flaticon.com/512/149/149071.png"} alt={"Profile Image"} className="img-adjs2 rounded-circle" />

        } */}
      <img
        src={
          credentialLoginAccountProfileDetailAtom?.data?.profileImgPath ||
          "https://cdn-icons-png.flaticon.com/512/149/149071.png"
        }
        alt="Profile Image"
        style={{
          height: "40px",
          width: "40px",
          objectFit: "cover",
        }}
        className="rounded-circle"
        ref={profileImgRef}
        onError={() => handleImageError(profileImgRef)}
      />
    </div>
  );
}

export default CredentialProfileImageForMenu;
