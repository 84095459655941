import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useRecoilValue } from "recoil";
import swal from "sweetalert";
import CourseCard from "../common/CourseCard";
import { API_CALL } from "./../../api/ApiServiceCall";
import { API_METHOD } from "./../../common/constants";
import { courseSearchFullEndpointState } from "./../atoms/course-list-atom";
import CoursesFilter from "./CoursesFilter";
import CoursesMenu from "./CoursesMenu";
import ExtraFilterMenu from "./ExtraFilterMenu";

function Courses() {
  const [courses, setCourses] = useState({
    loading: false,
    error: null,
    data: [],
  });
  const courseSearchFullEndpoint = useRecoilValue(
    courseSearchFullEndpointState
  );
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [itemOffset, setItemOffset] = useState(0);
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(null);
  const [selectedCategoryName, setSelectedCategoryName] = useState("");

  const getCourses = async (endPoint) => {
    setCourses({ loading: true, data: [], error: null });

    const request = {
      apiUrl: endPoint,
      method: API_METHOD.GET,
    };

    let result = await API_CALL(request);

    if (result.status === 200) {
      setCourses({ loading: false, data: result.data, error: null });
    } else if (result.status > 200) {
      setCourses({ loading: false, data: [], error: result.message });

      if (result.message == "Token is Not Valid") return null;

      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }
  };

  useEffect(() => {
    getCourses(courseSearchFullEndpoint);
  }, [courseSearchFullEndpoint]);

  useEffect(() => {
    if (courses?.data?.length) {
      const endOffset = itemOffset + Number(itemsPerPage);
      let sliceItem = courses?.data?.slice(itemOffset, endOffset);
      setCurrentItems(sliceItem);
      let pageCountResult = Math.ceil(courses?.data?.length / itemsPerPage);
      setPageCount(pageCountResult);
    }
  }, [courses?.data?.length, itemOffset, itemsPerPage]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % courses?.data?.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      {/* selectedCategoryName, setSelectedCategoryName */}
      <div className="student-conatiner pt-5 mb-4">
        <h2 className="fs-4">
          {selectedCategoryName ? selectedCategoryName : "All"} Courses
        </h2>
      </div>
      <div style={{ maxWidth: "1080px", margin: "0 auto" }}>
        <CoursesMenu setSelectedCategoryName={setSelectedCategoryName} />
      </div>
      <div className="student-conatiner">
        <ExtraFilterMenu />
        <div className="d-flex align-items-start gap-3">
          <CoursesFilter />
          {courses.loading ? (
            <div className="p-2 pt-0 flex-grow-1">
              {[1, 2, 3, 4, 5].map((item) => (
                <div
                  key={item}
                  className="p-4 bg-white rounded-4 d-flex mb-4 pe-auto"
                >
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ width: "100%", height: "130px" }}
                  >
                    <div
                      className="spinner-border text-secondary"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : courses?.data?.length > 0 && currentItems ? (
            <div className="p-2 pt-0 flex-grow-1">
              {currentItems.map((course) => (
                <CourseCard
                  key={course._id}
                  course={course}
                  liveCourse={course.isLive}
                />
              ))}
            </div>
          ) : (
            <p>No Courses Found</p>
          )}
        </div>

        {courses?.data?.length ? (
          <div className="mt-8 d-flex justify-content-between align-items-center">
            <ReactPaginate
              breakLabel="..."
              nextLabel="Next ->"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="<- Previous"
              renderOnZeroPageCount={null}
              containerClassName="pagination d-inline-flex prevent-text-select"
              activeLinkClassName="pagination-activeLink prevent-text-select"
              previousLinkClassName="pagination-container pagination-previous-btn prevent-text-select"
              pageLinkClassName="pagination-container prevent-text-select"
              nextLinkClassName="pagination-container pagination-next-btn prevent-text-select"
              disabledClassName="pagination_link_disabled"
            />

            <div className="col-lg-4 fz-13px fw-500 text-muted text-end">
              Show{" "}
              <select
                className="px-2 py-1 "
                onChange={(e) => {
                  setItemsPerPage(e.target.value);
                }}
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={75}>75</option>
                <option value={100}>100</option>
              </select>{" "}
              courses per page
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default Courses;
