
import { Link, useNavigate } from "react-router-dom";
import StarRatings from "react-star-ratings";
import { useSetRecoilState } from "recoil";
import swal from "sweetalert";
import { API_CALL } from "../../api/ApiServiceCall";
import {
  API_CONSTANTS,
  API_METHOD,
  PICTURE_STORE,
} from "../../common/constants";
import { cartState } from "./../atoms/cart-atom";
import { wishListState } from "./../atoms/wishlist-atom";
import { currencySymbol } from "./../common/constants";
import "./../css/wishlist.css";

function WishListCourse({ course, liveCourse }) {
  const setWishList = useSetRecoilState(wishListState);
  const setCart = useSetRecoilState(cartState);
  const navigate = useNavigate();

  const getWishList = async () => {
    const request = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.WISH_LIST,
      method: API_METHOD.GET,
    };

    let result = await API_CALL(request);

    if (result.status === 200) {
      setWishList(result.data ? result.data : []);
    } else if (result.status > 200) {
      if (result.message == "Token is Not Valid") return null;

      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }
  };

  const handleDelete = async (courseCode) => {
    const request = {
      apiUrl:
        API_CONSTANTS.BASE_URL +
        API_CONSTANTS.DELETE_WISHLIST_BY_COURSE_CODE(courseCode),
      method: API_METHOD.DELETE,
    };

    let result = await API_CALL(request);

    if (result.status === 200) {
      swal({
        title: "Success",
        text: result.message,
        icon: PICTURE_STORE.TickImage,
        imageHeight: 580,
        imageWidth: 580,
        className: "success-window",
        button: true,
      });
      
       setWishList((prevWishlist) =>
      prevWishlist.filter((item) => item.courseCode !== courseCode)
    );
    } else if (result.status > 200) {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }
  };

  const handleAddToCart = async (courseCode) => {
    const requestBodyParam = JSON.stringify({
      courseCode: courseCode,
    });
    const request = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.CART,
      method: API_METHOD.POST,
      requestBodyParams: requestBodyParam,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      swal("Good job!", "Added to Cart", "success").then(() => {
        setCart((prevCart) => ({
          ...prevCart,
          items: [...prevCart.items, course],
        }));
      });
    } else if (result.status > 200) {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }
  };

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        navigate(`/course/${course.courseCode}`);
      }}
      className="p-4 bg-white rounded-4 d-md-flex my-4"
      style={{ cursor: "pointer" }}
    >
      {course.thumbNailImagePath && (
        <img
          src={course.thumbNailImagePath}
          alt="course thubnail"
          className="rounded-3 card-image"
          // style={{ height: '140px', width: '220px', objectFit: 'cover', flexShrink: 0 }}
        />
      )}
      {/* px-md-4 */}

      <div className="flex-grow-1 px-md-4 d-flex flex-column justify-content-between">
        <div>
          <h6 className="fs-5">{course.courseName}</h6>
          <div className="d-flex instructor-livecourse-container">
            <p style={{ fontSize: "0.9rem" }} className="mb-0">
              by{" "}
              <Link
                to={`/instructor-profile/${course.instructorId}`}
                style={{ color: "#395061", cursor: "pointer" }}
              >
                {course.instructorName}
              </Link>
            </p>
            {liveCourse && (
              <button
                className="btn ms-3 px-2 rounded-pill"
                style={{
                  backgroundColor: "#F75656",
                  color: "#fff",
                  fontSize: "0.6rem",
                  paddingTop: "3px",
                  paddingBottom: "3px",
                }}
              >
                Live Course
              </button>
            )}
          </div>
        </div>

        {/* align-items-center className="d-flex" */}

        {/* align-items-center d-flex gap-2 */}

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p className="mb-2">
            <StarRatings
              rating={course.rating}
              starRatedColor="#F7AC16"
              starDimension="20px"
              starSpacing="1px"
            />
            <span
              style={{
                fontSize: "0.8rem",
                marginTop: "10px",
                marginLeft: "10px",
              }}
            >
              {course.rating} ({course.ratingCount})
            </span>
          </p>
          <h3 className="fs-5 price-small-screen">
            {currencySymbol[course.currency]}
            {course.fee}
          </h3>
        </div>
        <h3 className="fs-5 price-larger-screen">
          {currencySymbol[course.currency]}
          {course.fee}
        </h3>
      </div>
      {/* className="d-flex flex-column justify-content-center" */}

      {/* className="btns-container" */}

      <div
        className="d-flex flex-column justify-content-center"
        style={{ flexShrink: 0 }}
      >
        <button
          onClick={(e) => {
            e.stopPropagation();
            handleAddToCart(course.courseCode);
          }}
          className="btn py-2 px-4 mb-3"
          style={{
            backgroundColor: "#F0E1EB",
            color: "#395061",
            width: "100%",
            flexShrink: 0,
          }}
        >
          Add to cart
        </button>
        <button
          onClick={(e) => {
            e.stopPropagation();
            handleDelete(course.courseCode);
          }}
          className="btn py-2 align-self-end px-4"
          style={{
            backgroundColor: "#F0E1EB",
            color: "#F65656",
            width: "100%",
            flexShrink: 0,
          }}
        >
          Remove
        </button>
      </div>
    </div>
  );
}

export default WishListCourse;
