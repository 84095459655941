import React, { useState } from "react";
import { useForm } from "react-hook-form";
function Step2CourseDetail({
  setCurrentStep,
  formData,
  register,
  watch,
  currencyList,
}) {
  const {
    formState: { errors },
  } = useForm();
  const [isFocused, setisFocused] = useState({
    currencyFocused: false,
    feeFocused: false,
  });

  const watchingRequiredFieldsStep2 = watch(["currency", "fee"]); //this will give array value so we are converting to object
  const watchingRequiredFieldsDataStep2 = {
    currency: watchingRequiredFieldsStep2[0],
    fee: watchingRequiredFieldsStep2[1],
  };
  const navigateStep3 = () => {
    const validate = true && watchingRequiredFieldsDataStep2.fee;
    if (validate) {
      setCurrentStep("step3");
    } else {
      setisFocused({
        currencyFocused: true,
        feeFocused: true,
      });
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-6">
          <label className="py-4 fw-500">Currency</label>
          <div className="tag-ajus">
            <select
              defaultValue={"INR"}
              {...register("currency")}
              disabled
              onBlur={() =>
                setisFocused({ ...isFocused, currencyFocused: true })
              }
            >
              <option value="" disabled>
                Select Currency
              </option>
              <option value="INR">₹</option>
              {/* <option value="USD">$</option> */}
            </select>
          </div>
          <small className="text-danger my-0 d-block fz-12px fw-500">
            {isFocused.currencyFocused &&
            watchingRequiredFieldsDataStep2.currency === ""
              ? "Please Fill this field"
              : ""}
          </small>
        </div>
        <div className="col-8"></div>
        <div className="col-6">
          <label className="py-4 fw-500">Fee</label>
          <div className="tag-ajus">
            <select
              defaultValue={""}
              {...register("fee")}
              onBlur={() => setisFocused({ ...isFocused, feeFocused: true })}
            >
              <option value="" disabled>
                Select Fee
              </option>
              <option value="999">999</option>
              {currencyList.map((item, index, array) => (
                <option value={item?.price} key={index}>
                  {item?.price} VideoCount({item?.videoCount})
                </option>
              ))}
            </select>
          </div>
          <small className="text-danger my-0 d-block fz-12px fw-500">
            {isFocused.feeFocused && watchingRequiredFieldsDataStep2.fee === ""
              ? "Please Fill this field"
              : ""}
          </small>
        </div>
      </div>

      <div className="py-5"></div>
      <div className="d-flex justify-content-between">
        <button
          type="button"
          className=" border-0 bg-secondary text-white fz-14px py-12px w-14 rounded-3 text-center"
          onClick={() => setCurrentStep("step1")}
        >
          Back
        </button>
        <button
          type="button"
          className=" border-0 bg-prime text-white fz-14px py-12px w-14 rounded-3 text-center"
          onClick={navigateStep3}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default Step2CourseDetail;
