import { Col, Nav, Row, Tab } from "react-bootstrap";
import CompletedCourses from "./CompletedCourses";
import CoursesInProgress from "./CoursesInProgress";
import PurchasedCourses from "./PurchasedCourses";

function Dashboard() {
  return (
    <div className="student-conatiner tch">
      <div className="py-4"></div>
      <h1 className="fs-4 my-4">Learner Dashboard</h1>
      <Tab.Container
        id="hr-tabs-example"
        defaultActiveKey="purchased-courses"
        mountOnEnter={true}
      >
        <Row>
          <Col sm={12}>
            <Nav variant="pills brd-btm">
              <Nav.Item>
                <Nav.Link eventKey="purchased-courses">
                  Purchased Courses
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="course-in-progress">
                  Course In Progress
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="complete">Completed</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={12} className="mt-4">
            <Tab.Content>
              <Tab.Pane eventKey="purchased-courses">
                <PurchasedCourses />
              </Tab.Pane>
              <Tab.Pane eventKey="course-in-progress">
                <CoursesInProgress itemsPerPage={4} />
              </Tab.Pane>
              <Tab.Pane eventKey="complete">
                <CompletedCourses itemsPerPage={4} />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
      <div className="py-4"></div>
    </div>
  );
}

export default Dashboard;
