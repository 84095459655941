import React from 'react'

function ChatIcon() {
    return (
        <svg width="40" height="30" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 37.5C9.81625 37.5 9.63125 37.4587 9.4575 37.3762C9.02625 37.1675 8.75 36.7313 8.75 36.25V30H5C2.9325 30 1.25 28.3175 1.25 26.25V6.25C1.25 4.1825 2.9325 2.5 5 2.5H35C37.0675 2.5 38.75 4.1825 38.75 6.25V26.25C38.75 28.3175 37.0675 30 35 30H19.8137L10.7812 37.2262C10.555 37.4075 10.2788 37.5 10 37.5ZM5 5C4.31 5 3.75 5.56125 3.75 6.25V26.25C3.75 26.9387 4.31 27.5 5 27.5H10C10.6912 27.5 11.25 28.0588 11.25 28.75V33.65L18.5938 27.7738C18.8162 27.5963 19.09 27.5 19.375 27.5H35C35.69 27.5 36.25 26.9387 36.25 26.25V6.25C36.25 5.56125 35.69 5 35 5H5Z" fill="#395061" />
        <path d="M30 15H10C9.30875 15 8.75 14.44 8.75 13.75C8.75 13.06 9.30875 12.5 10 12.5H30C30.6912 12.5 31.25 13.06 31.25 13.75C31.25 14.44 30.6912 15 30 15Z" fill="#395061" />
        <path d="M20 20H10C9.30875 20 8.75 19.44 8.75 18.75C8.75 18.06 9.30875 17.5 10 17.5H20C20.6912 17.5 21.25 18.06 21.25 18.75C21.25 19.44 20.6912 20 20 20Z" fill="#395061" />
    </svg>
    )
}

export default ChatIcon