import moment from "moment";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import { API_CALL } from "../../../../../api/ApiServiceCall";
import { API_CONSTANTS, API_METHOD } from "../../../../../common/constants";
import CouponTable from "./CouponTable";
function CouponForm() {
  const {
    register,
    reset,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [triggerGetDiscountRequestApi, setTriggerGetDiscountRequestApi] =
    useState();

  const onSubmit = async (data) => {
    const requestBodyParam = JSON.stringify({
      couponName: data?.couponName,
      discountPercentage: data?.discount,
      numberOfCoupon: data?.noOfCoupon,
      startDate: data?.startDate,
      expiryDate: data?.expiryDate,
    });
    const request = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.REQUEST_FOR_DISCOUNT,
      method: API_METHOD.POST,
      requestBodyParams: requestBodyParam,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      reset();
      setTriggerGetDiscountRequestApi(Math.random());
    } else if (result.status > 200) {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }
  };
    const startDate = watch("startDate");
    const expiryDate = watch("expiryDate");
  return (
    <div className="py-5 px-3">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-lg-4">
            <label className="fz-14px mb-3">Coupon name</label>
            <input
              className="default-inpt"
              {...register("couponName", {
                required: {
                  value: true,
                  message: "Please fill this field",
                },
              })}
            />
            <small className="text-danger my-2 d-block fz-12px fw-500">
              {" "}
              {errors.couponName?.message && errors.couponName?.message}
            </small>
          </div>
          <div className="col-lg-4">
            <label className="fz-14px mb-3">Discount percentage</label>
            <select
              className="default-inpt"
              {...register("discount", {
                required: {
                  value: true,
                  message: "Please fill this field",
                },
              })}
            >
              <option value={""}>select</option>
              <option value={10}>10%</option>
              <option value={20}>20%</option>
              <option value={30}>30%</option>
              <option value={40}>40%</option>
              <option value={50}>50%</option>
              <option value={60}>60%</option>
              <option value={70}>70%</option>
              <option value={80}>80%</option>
              <option value={90}>90%</option>
            </select>
            <small className="text-danger my-2 d-block fz-12px fw-500">
              {" "}
              {errors.discount?.message && errors.discount?.message}
            </small>
          </div>
          <div className="col-lg-4">
            <label className="fz-14px mb-3">Number of coupon</label>
            <input
              className="default-inpt"
              type={"number"}
              {...register("noOfCoupon", {
                required: {
                  value: true,
                  message: "Please fill this field",
                },
                validate: {
                  nonNegative: (value) => {
                    const parsedValue = parseInt(value, 10);
                    return (
                      (!isNaN(parsedValue) && parsedValue >= 1) ||
                      "Please enter a positive number"
                    );
                  },
                },
              })}
            />
            <small className="text-danger my-2 d-block fz-12px fw-500">
              {" "}
              {errors.noOfCoupon?.message && errors.noOfCoupon?.message}
            </small>
          </div>
          <div className="my-2"></div>
          <div className="col-lg  live-course-stp3">
            <label className="fz-14px mb-3">Start date</label>
            <input
              className="default-inpt"
              min={moment().format("YYYY-MM-DD")}
              {...register("startDate", {
                required: {
                  value: true,
                  message: "Please fill this field",
                },
                validate: (value) =>
                  value !== expiryDate ||
                  "Start date and expiry date should not be the same",
              })}
              type={"date"}
            />
            <small className="text-danger my-2 d-block fz-12px fw-500">
              {" "}
              {errors.startDate?.message && errors.startDate?.message}
            </small>
          </div>
          <div className="col-lg  live-course-stp3">
            <label className="fz-14px mb-3">Expiry date</label>
            <input
              className="default-inpt"
              min={moment().format("YYYY-MM-DD")}
              {...register("expiryDate", {
                required: {
                  value: true,
                  message: "Please fill this field",
                },
                validate: (value) =>
                  value !== startDate ||
                  "Start date and expiry date should not be the same",
              })}
              type={"date"}
            />
            <small className="text-danger my-2 d-block fz-12px fw-500">
              {" "}
              {errors.expiryDate?.message && errors.expiryDate?.message}
            </small>
          </div>
          <div className="col-lg">
            <label className="fz-14px mb-3 invisible">Submit form</label>
            <button
              type="submit"
              className=" border-0 bg-prime text-white fz-14px py-12px w-100 rounded-3 text-center"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
      {/* ----------------------------- */}
      <CouponTable
        triggerGetDiscountRequestApi={triggerGetDiscountRequestApi}
      />
    </div>
  );
}
export default CouponForm;
