import React from 'react'
import { Link } from 'react-router-dom'
import img_404 from '../images/page_notfound_404/img404.gif'

function PageNotFound() {
  return (
    <>
      <div className='vh-100 text-center'>
        <img src={img_404} alt="loading..." className='mt-5 pt-5 mx-auto d-block d-flex align-self-center ' />
        <Link to="/"><button className='btn btn-secondary'>Go Home</button></Link>
      </div>

    </>
  )
}

export default PageNotFound