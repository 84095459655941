import moment from "moment";
import React, { useEffect, useState } from "react";
import { MdModeEditOutline } from "react-icons/md";
import swal from "sweetalert";
import { API_CALL } from "../../../api/ApiServiceCall";
import { API_CONSTANTS, API_METHOD } from "../../../common/constants";
import DemoClassTimings from "../timingsTab/TimingsTab";
import DemoTimeCounter from "./DemoTimeCounter";

function DemoClassGoLive() {
  const [scheduledDemoClassData, setScheduledDemoClassData] = useState("");
  const [isScheduledDemoClass, setIsScheduledDemoClass] = useState(false);
  const [onEditDataDemoClass, setOnEditDataDemoClass] = useState("");

  useEffect(() => {
    getScheduledDemoClasses();
  }, []);

  const getScheduledDemoClasses = async () => {
    const clickedDemoClassData = JSON.parse(
      localStorage.getItem("DemoClassData")
    );
    const request = {
      apiUrl:
        API_CONSTANTS.BASE_URL +
        API_CONSTANTS.GET_DEMO_CLASS_LIST_BY_COURSE_CODE +
        `?courseCode=${clickedDemoClassData?.courseCode}`,
      method: API_METHOD.GET,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      setScheduledDemoClassData(result.data);
    } else if (result.status > 200) {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }
  };
  const onEdit = (data) => {
    setOnEditDataDemoClass(data);
    setIsScheduledDemoClass(true);
  };
  return (
    <div>
      {/* <pre>{JSON.stringify(scheduledDemoClassData, null, 4)}</pre> */}
      {isScheduledDemoClass ? (
        <DemoClassTimings
          getScheduledDemoClasses={getScheduledDemoClasses}
          onEditDataDemoClass={onEditDataDemoClass}
          setOnEditDataDemoClass={setOnEditDataDemoClass}
          setIsScheduledDemoClass={setIsScheduledDemoClass}
        />
      ) : scheduledDemoClassData ? (
        scheduledDemoClassData.map((dmData, index, array) => (
          <div
            className="chp-list cursor-default  pb-2 position-relative"
            key={dmData?._id}
          >
            <div className="d-flex    align-items-center  pb-1">
              <div className="flex-column">
                <h1 className=" word-break fz-17px fw-500 m-0">
                  {" "}
                  {dmData.topicName}{" "}
                  <span
                    className="d-inline-block fz-10px fw-500 text-prime text-center rounded-pill  py-1 px-3 "
                    style={{ background: "#F0E1EB" }}
                  >
                    {" "}
                    Scheduled at {new Date(
                      dmData.demoDate
                    ).toDateString()} /{" "}
                    {moment(dmData.demoTime, "HH:mm").format("h:mm a")}
                  </span>
                </h1>
              </div>
              <div className="d-flex justify-content-end px-2 ms-auto my-auto opc-0">
                <div
                  className="me-3"
                  role="button"
                  type="button"
                  onClick={() => onEdit(dmData)}
                >
                  <MdModeEditOutline className="text-prime" />
                </div>
                {/* <div className='me-4' role="button" type="button"  ><FiTrash2 className='text-danger' /></div> */}
              </div>

              <div
                role={"button"}
                className={dmData.topicName.length > 100 ? "w-35 text-end" : ""}
              >
                {dmData.status == "COMPLETED" ? (
                  <button
                    className=" opacity-100 bg-transparent border-0 text-success"
                    disabled
                  >
                    COMPLETED
                  </button>
                ) : (
                  <DemoTimeCounter
                    dmData={dmData}
                    clickedDemoClassData={dmData}
                  />
                )}
              </div>
            </div>
          </div>
        ))
      ) : (
        ""
      )}
      <div className="d-flex pt-3 justify-content-end">
        {isScheduledDemoClass ? (
          ""
        ) : (
          <button
            className="bg-prime border-0 text-white fz-14px rounded px-5 py-2"
            onClick={() => setIsScheduledDemoClass(true)}
          >
            Schedule demo
          </button>
        )}
      </div>
    </div>
  );
}

export default DemoClassGoLive;
