import React from "react";
import { useRecoilState } from "recoil";
import "../../css/sideTab.css";
import { overviewCustomTabAtomState } from "../atom/overviewCustomTab";
import DemoClassGoLive from "./demoClassGoLive/DemoClassGoLive";
import DemoClassTab from "./demoClassTab/DemoClassTab";
function DemoClassOverview() {
  const [activeComponentListAtom, setActiveComponentListAtom] = useRecoilState(
    overviewCustomTabAtomState
  );

  return (
    <div className="py-5">
      <div className="px-4 democlass">
        <div className="row">
          <div className="col-lg-3">
            <div className="overview-card p-lg-5 ">
              <ul className=" list-style-none m-0 p-0">
                <li
                  role={"button"}
                  onClick={() =>
                    setActiveComponentListAtom({
                      component: <DemoClassTab />,
                      componentIdentifier: "Demo_Classes",
                    })
                  }
                  className={
                    activeComponentListAtom.componentIdentifier ==
                    "Demo_Classes"
                      ? "active"
                      : ""
                  }
                >
                  Demo Classes
                </li>
                {/* <li role={"button"} onClick={() => setActiveComponentListAtom({ component: <DemoClassTimings />, componentIdentifier: "Timings" })} className={activeComponentListAtom.componentIdentifier == "Timings" ? "active" : ""}>Schedule Demo Timing</li> */}
                <li
                  role={"button"}
                  onClick={() =>
                    setActiveComponentListAtom({
                      component: <DemoClassGoLive />,
                      componentIdentifier: "Go_Live",
                    })
                  }
                  className={
                    activeComponentListAtom.componentIdentifier == "Go_Live"
                      ? "active"
                      : ""
                  }
                >
                  Go Live
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-9">
            <div className="overview-card">
              {activeComponentListAtom.component ? (
                activeComponentListAtom.component
              ) : (
                <DemoClassTab />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DemoClassOverview;
