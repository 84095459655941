import React, { useState } from "react";
import { useForm } from "react-hook-form";
import MyS3Component from "../../s3/s3";
function Step3CourseDetail({
  setCurrentStep,
  loading,
  error,
  setThumbnailImageData,
  setIntroVideoData,
}) {
  const {
    formState: { errors },
  } = useForm();
  const [source, setSource] = React.useState();
  const [vSource, setVSource] = React.useState();
  const [iSource, setISource] = React.useState();
  const [fileValidation, setFileValidation] = useState("");
  const [videoValidation, setVideoValidation] = useState("");

  //for video preview
  const handleFileChange = (e) => {
    // if(e.target.files[0] === undefined) return;
    let file = e.target.files[0] !== undefined ? e.target.files[0] : vSource;
    const uniqueId = "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
    let fileExtension = file?.name?.split(".").pop();
    const uniqueFileName = `${uniqueId}.${fileExtension}`;
    file = new File([file], uniqueFileName, { type: file.type });
    if (file.type == "video/mp4") {
      setIntroVideoData(file);
      setVSource(file);
      const url = URL.createObjectURL(e.target.files[0] ?? vSource);
      setSource(url);
      setVideoValidation("");
    } else {
      setVideoValidation("Please select the mp4 format");
    }
  };
  const [image, setImage] = useState("");
  //for Image preview
  async function readImage(e, func) {
    // if (e.target.files[0] === undefined) return;
        let file =
          e.target.files[0] !== undefined ? e.target.files[0] : iSource;
    const uniqueId = "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
    let fileExtension = file.name.split(".").pop();
    const uniqueFileName = `${uniqueId}.${fileExtension}`;
    file = new File([file], uniqueFileName, { type: file.type });
    let fileFormat = fileExtension.toLowerCase();
    if (fileFormat == "png" || fileFormat == "jpeg" || fileFormat == "jpg") {
      setThumbnailImageData(file);
      setISource(file)
      const reader = new FileReader();
      reader.onload = function (e) {
        let binaryData = e.target.result;
        let base64String = window.btoa(binaryData);
        func(base64String);
      };
      setFileValidation("");
      let image = reader.readAsBinaryString(file);

      return image;
    } else {
      setFileValidation("Please select JPG, PNG, or JPEG format");
    }
  }
  const sameWH = {
    height: "350px",
    // objectFit: "cover",
    width: "100%",
    borderRadius: "7px",
  };
  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <h5>Thumbnail</h5>
          <small className="text-danger mb-2 d-block fz-12px fw-500">
            {fileValidation}
          </small>
          <p className="text-muted fz-12px">
            Width 600 px, Height 350px. Format will be JPG, PNG, JPEG.
          </p>
          {image ? (
            <img
              src={`data:image/jpeg;base64,${image}`}
              alt="uploaded"
              className="mb-3 rounded-3"
              style={sameWH}
            />
          ) : (
            ""
          )}
          <input
            className={image ? "d-none file-prim" : "file-prim"}
            id="change_image"
            type="file"
            accept="image/gif, image/jpeg, image/png"
            onChange={(event) => {
              readImage(event, setImage);
            }}
            // required
          />
          {image ? (
            <label
              htmlFor="change_image"
              className="w-100 py-2 text-center text-white fz-13px bg-secondary rounded-2"
              role="button"
            >
              Change Image
            </label>
          ) : (
            ""
          )}
        </div>
        <div className="col-lg-1"></div>
        <div className="col">
          <h5>Intro Video</h5>
          <small className="text-danger mb-2 d-block fz-12px fw-500">
            {videoValidation}
          </small>
          <p className="text-muted fz-12px">
            Width 600 px, Height 350px. Format will be mp4.
          </p>
          {source ? (
            <>
              {" "}
              <video
                style={sameWH}
                className="VideoInput_video mb-2"
                controls
                src={source}
              />
            </>
          ) : (
            ""
          )}
          <span className="text-danger">{error}</span>
          <input
            className={source ? "d-none file-prim" : "file-prim"}
            id="change_video"
            type="file"
            onChange={handleFileChange}
            accept=".mp4"
            // required
          />
          {source ? (
            <label
              htmlFor="change_video"
              className="w-100 py-2 text-center text-white fz-13px bg-secondary rounded-2"
              role="button"
            >
              Change intro Video
            </label>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="py-3"></div>
      <div className="d-flex justify-content-between">
        <button
          type="button"
          className=" border-0 bg-secondary text-white fz-14px py-12px w-14 rounded-3 text-center"
          onClick={() => setCurrentStep("step2")}
        >
          Back
        </button>
        <button
          type="submit"
          className=" border-0 bg-prime text-white fz-14px py-12px w-14 rounded-3 text-center"
          disabled={loading ? true : false}
        >
          {loading ? (
            <>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              {loading}%
            </>
          ) : (
            "Create Course"
          )}
        </button>
      </div>
      <div className="py-3"></div>
    </div>
  );
}

export default Step3CourseDetail;
