import React, { useEffect, useState } from "react";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import { useRecoilState } from "recoil";
import swal from "sweetalert";
import { API_CALL } from "../../../../../api/ApiServiceCall";
import { API_CONSTANTS, API_METHOD } from "../../../../../common/constants";
import { goLiveCourseViewAtomState } from "../../../../atom/goLiveCourseView";
import { liveCourseAssessmentDataListAtomState } from "../../../../atom/liveCourseAssessmentDataListAtom";
import { liveCourseAssessmentViewAtomFunc } from "../../../../atom/liveCourseAssessmentViewAtom";
import { refetchAssessmentTitleListAtomFunc } from "../../../../atom/refetchAssessmentTitleListAtom";
import LiveCourseGoLive from "../go-live-course/liveCourseGoLive";
import LiveCourseAssessmentList from "./create-assessment-name/assessmentList/liveCourseAssessmentList";
import CreateAssessmentName from "./create-assessment-name/createAssessmentName";
export const cancelAssessmentName = React.createContext();
function LiveCourseTab() {
  const [isCancelAssessmentName, setIsCancelAssessmentName] = useState(false);
  const [liveCourseAssessmentViewAtom, setLiveCourseAssessmentViewAtom] =
    useRecoilState(liveCourseAssessmentViewAtomFunc);
  const [viewComponentForGoLive, setViewComponentForGoLive] = useRecoilState(
    goLiveCourseViewAtomState
  );
  const [
    liveCourseAssessmentDataListAtom,
    setliveCourseAssessmentDataListAtom,
  ] = useRecoilState(liveCourseAssessmentDataListAtomState);
  const [refetchAssessmentTitleListAtom, setRefetchAssessmentTitleListAtom] =
    useRecoilState(refetchAssessmentTitleListAtomFunc);

  const showAlert = () => {
    swal({
      title: "Please Note!",
      text: "Admin approval is required for this to become active",
      className: "swal-ql-theme-btn",
    });
  };

  useEffect(() => {
    getAllLiveCourseAssessmentDetails();
  }, []);
  const getAllLiveCourseAssessmentDetails = async () => {
    const courseCode = localStorage.getItem("courseCode");
    const request = {
      apiUrl:
        API_CONSTANTS.BASE_URL +
        API_CONSTANTS.GET_ASSESSMENT_BY_LIVE_COURSE_CODE +
        `/?courseCode=${courseCode}`,
      method: API_METHOD.GET,
    };
    let result = await API_CALL(request);

    if (result.status === 200) {
      setliveCourseAssessmentDataListAtom(result.data);
    } else if (result.status > 200) {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }
  };
  return (
    <div className="video-tabs overview-card">
      {/*<Tab.Container id="left-tabs-example" defaultActiveKey="first" animation={true}  mountOnEnter unmountOnExit> */}
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <Row>
          <Col sm={12}>
            <Nav variant="pills">
              <Nav.Item>
                <Nav.Link eventKey="first" className="px-5">
                  Assessments
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="second" className="px-5" disabled={false}>
                  Go To Live
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={12}>
            <Tab.Content>
              <Tab.Pane eventKey="second">
                {/* //need to change state variable */}
                {viewComponentForGoLive ? (
                  viewComponentForGoLive
                ) : (
                  <LiveCourseGoLive />
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="first">
                {/* {JSON.stringify(assessmentDataList)} */}
                {liveCourseAssessmentViewAtom ? (
                  liveCourseAssessmentViewAtom
                ) : (
                  <cancelAssessmentName.Provider
                    value={{ setIsCancelAssessmentName }}
                  >
                    <LiveCourseAssessmentList />
                    <div className="my-4"></div>
                    {isCancelAssessmentName ? <CreateAssessmentName /> : ""}
                    <div className="d-flex justify-content-end">
                      <button
                        onClick={() => setIsCancelAssessmentName(true)}
                        disabled={
                          liveCourseAssessmentDataListAtom?.courseStatus ==
                          "INREVIEW"
                            ? true
                            : false
                        }
                        className="px-3 border-0 bg-prime text-white fz-14px py-2 rounded-3"
                      >
                        Create New Assessment
                      </button>
                    </div>
                  </cancelAssessmentName.Provider>
                )}
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
}

export default LiveCourseTab;
