import React from "react";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import Payout from "./payout/payout";
import GoogleSignUserProfileImage from "./profile-image-upload/googleSignInProfileImage";
import ProfileImageForm from "./profile-image-upload/profileImageForm";
import AccountSetting from "./profile_menu/account-setting";
import CouponForm from "./profile_menu/coupon/CouponForm";
import UserProfile from "./profile_menu/user-profile";
import Notification from "./teacher_notifications/notification";

function TeacherProfile() {
  const getUserDetail = JSON.parse(localStorage.getItem("localUserData"));
  const getCredentialUserDetail = JSON.parse(
    localStorage.getItem("localUserData")
  );

  return (
    <>
      <div className="container tch">
        <div className="py-4"></div>
        {getUserDetail?.data?.user?.photoURL ? (
          <GoogleSignUserProfileImage getUserDetail={getUserDetail} />
        ) : (
          <ProfileImageForm getCredentialUserDetail={getCredentialUserDetail} />
        )}
        <div className="py-4"></div>
        <Tab.Container
          id="hr-tabs-example"
          defaultActiveKey="first"
          mountOnEnter
        >
          <Row>
            <Col sm={12}>
              <Nav variant="pills brd-btm">
                <Nav.Item>
                  <Nav.Link eventKey="first">Profile</Nav.Link>
                </Nav.Item>
                {/* Checking for gmail login or not */}
                <Nav.Item>
                  <Nav.Link eventKey="settings">Account Settings</Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link eventKey="payout">Payment Info</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="notifications">Notifications</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="coupons">Coupons</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={12}>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <UserProfile />
                </Tab.Pane>
                <Tab.Pane eventKey="settings">
                  <AccountSetting />
                </Tab.Pane>
                <Tab.Pane eventKey="payout">
                  <Payout />
                </Tab.Pane>
                <Tab.Pane eventKey="notifications">
                  <Notification />
                </Tab.Pane>
                <Tab.Pane eventKey="coupons">
                  <CouponForm />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </>
  );
}

export default TeacherProfile;
