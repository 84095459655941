import moment from "moment";
import React from "react";
import { useRecoilState } from "recoil";
import { goLiveCourseViewAtomState } from "../../../../atom/goLiveCourseView";
import RightArrow from "../../../../svg-icons/rightArrow";
import GoLiveCourseForm from "./goLiveCourseForm";

function DateTimeEntryCheck({ goLiveItemData }) {
  const [viewComponentForGoLive, setViewComponentForGoLive] = useRecoilState(
    goLiveCourseViewAtomState
  );
  const openLiveCourseForm = () => {
    setViewComponentForGoLive(
      <GoLiveCourseForm goLiveClickedData={goLiveItemData} />
    );
  };
  const dateTimeEntryCheck = () => {
    let apiDate = moment(goLiveItemData.scheduledDate).format("YYYY-MM-DD");
    let apiTime = goLiveItemData.endTime;
    let splittedTime = apiTime.split(":");
    let updatedDateTime = moment(apiDate).set({
      hour: splittedTime[0],
      minute: splittedTime[1],
    });
    // console.log("Date Time: ", moment(updatedDateTime).format());
    // console.log("Added time stamp: ", moment(updatedDateTime).format("hh:mm"));
    //Checking date was same or yet to come
    if (moment().isSameOrBefore(updatedDateTime)) {
      // console.log("isSameOrBefore")
      return (
        <button
          onClick={() => openLiveCourseForm()}
          className=" bg-transparent  border-0   m-0"
        >
          <RightArrow size={8} />
        </button>
      );
    } else {
      // console.log("expi")
      return (
        <button
          disabled
          className="text-danger  bg-transparent  border-0   m-0"
        >
          Expired
        </button>
      );
    }
  };
  return <>{dateTimeEntryCheck()}</>;
}

export default DateTimeEntryCheck;
