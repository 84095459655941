import moment from "moment";
import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import swal from "sweetalert";
import { API_CALL } from "../../api/ApiServiceCall";
import { API_CONSTANTS, API_METHOD } from "../../common/constants";
import { notificationsDataAtomState } from "../atom/notificationsDataAtomState";
import NotificationPopupIcon from "../svg-icons/notificationPopupIcon";

function InstructorNotification() {
  const [getNotificationsAtom, setNotificationsAtom] = useRecoilState(
    notificationsDataAtomState
  );
  const getAllNotifications = async () => {
    const request = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.GET_NOTIFICATIONS,
      method: API_METHOD.GET,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      setNotificationsAtom(result.data);
    } else if (result.status > 200) {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }
  };

  const markAsRead = async () => {
    const requestBodyParam = JSON.stringify({
      userID: getNotificationsAtom?.result?.userId,
    });
    const request = {
      apiUrl:
        API_CONSTANTS.BASE_URL + API_CONSTANTS.NOTIFICATION_MARK_ALL_AS_READ,
      method: API_METHOD.POST,
      requestBodyParams: requestBodyParam,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
    } else if (result.status > 200) {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }
  };
  useEffect(() => {
    if (!getNotificationsAtom) {
      getAllNotifications();
    }
    markAsRead();
  }, []);
  return (
    <div className="container py-4">
      {" "}
      <h4>Notifications</h4>
      {/* <pre>{JSON.stringify(getNotificationsAtom.result.userId, 3, null)}</pre> */}
      <div className="py-lg-3"></div>
      {!getNotificationsAtom?.result?.notificationList ? (
        <div className="text-center">
          <span
            className="spinner-border text-secondary spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Loading...</span>
        </div>
      ) : getNotificationsAtom?.result?.notificationList?.length ? (
        getNotificationsAtom?.result?.notificationList?.map((nData, idx) => (
          <div
            style={{ borderBottom: "1px solid #d6d3d3" }}
            key={nData.notificationDate + idx}
          >
            <div className="py-lg-2"></div>
            <div className="row align-items-center">
              <div className="col-lg-1 text-lg-center">
                {nData?.isRead ? (
                  <div className="opacity-50">
                    <NotificationPopupIcon />
                  </div>
                ) : (
                  <NotificationPopupIcon />
                )}
              </div>
              <div className="col-lg ps-lg-0">
                <div className="d-flex justify-content-between">
                  <h6
                    className={
                      nData?.isRead ? "text-muted  fw-600" : "text-dark  fw-600"
                    }
                  >
                    {nData.subject}
                  </h6>
                  {/* {nData.notificationDate} */}
                  <p className="text-muted fz-12px">
                    {moment(new Date(nData.notificationDate)).format(
                      "DD/MM/YYYY, hh:mm A"
                    )}
                  </p>
                </div>
                {/* <p className='text-muted fz-15px py-2'>{nData.emptyContent}</p> */}
              </div>
            </div>
            <div className="py-lg-2"></div>
          </div>
        ))
      ) : (
        <div style={{ borderBottom: "1px solid #d6d3d3" }}>
          <div className="row">
            <div className="col-lg-1 text-center">
              <NotificationPopupIcon />
            </div>
            <div className="col-lg ps-lg-0">
              <div className="d-flex justify-content-between">
                <h6 className="fw-600">No notification was received</h6>
                <p className="text-muted fz-12px"></p>
              </div>
              <p className="text-muted fz-15px">
                The notification has not been sent.
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default InstructorNotification;
