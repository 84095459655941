import React, { useRef, useState } from "react";
import Select from "react-select";
import swal from "sweetalert";
import { API_CALL } from "../../../../api/ApiServiceCall";
import {
  API_CONSTANTS,
  API_METHOD,
  BANK_NAMES,
  PICTURE_STORE,
} from "../../../../common/constants";

function AddBankForm({ handleClose, getPaymentInfo }) {
  const [isValidation, setIsValidation] = useState(false);
  const [formData, setFormData] = useState({
    accountHolderName: "",
    accountNumber: "",
    bankName: "",
    IFSCcode: "",
  });
  const [ifcsCodeErrorMsg, setIfcsCodeErrorMsg] = useState(false);
  const [accountHolderNameErrorMsg, setAccountHolderNameErrorMsg] = useState("");

  const accountHolderNameFocusRef = useRef("");
  const bankNameFocusRef = useRef("");
  const accountNumberFocusRef = useRef("");
  const IFSCcodeFocusRef = useRef("");

  const options = BANK_NAMES.ALL_BANK_NAMES;
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isAllow =
      formData.accountHolderName &&
      formData.accountNumber &&
      formData.bankName &&
      formData.IFSCcode &&
      !ifcsCodeErrorMsg;
    if (isAllow) {
      setLoading(true);
      const requestBodyParam = JSON.stringify({
        accountHolderName: formData.accountHolderName,
        accountNumber: formData.accountNumber,
        bankName: formData.bankName,
        IFSCcode: formData.IFSCcode,
        // paymentInfoId: ""
      });

      const request = {
        apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.CREATE_PAYMENT_INFO,
        method: API_METHOD.POST,
        requestBodyParams: requestBodyParam,
      };
      let result = await API_CALL(request);
      if (result.status === 200) {
        handleClose();
        swal({
          title: "Success",
          text: result.message,
          icon: PICTURE_STORE.TickImage,
          className: "success-window",
          button: true,
        });
        setLoading(false);
        getPaymentInfo();
      } else if (result.status > 200) {
        swal({
          title: "ERROR",
          text: result.message,
          icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
          className: "error-window",
          button: true,
        });
        setLoading(false);
      }
    } else {
      setIsValidation(true);
      // Note: the if order was important to validate properly
      if (!formData.IFSCcode) {
        IFSCcodeFocusRef.current.focus();
      }
      if (!formData.accountNumber) {
        accountNumberFocusRef.current.focus();
      }
      if (!formData.bankName) {
        bankNameFocusRef.current.focus();
      }
      if (!formData.accountHolderName) {
        accountHolderNameFocusRef.current.focus();
      }
    }
  };

  const handleFormChange = (e) => {
    let formValueHolder = { ...formData, [e.target.name]: e.target.value };

    let regIfscCode = new RegExp("^[A-Za-z]{4}[a-zA-Z0-9]{7}$");
    if (e.target.name === "IFSCcode") {
      if (e.target.value.match(regIfscCode)) {
        setIfcsCodeErrorMsg(false);
        setFormData(formValueHolder);
      } else {
        setIfcsCodeErrorMsg("Invalid");
        setFormData(formValueHolder);
      }
    } else {
      setFormData(formValueHolder);
    }
  };

  const handleAccountHolderNameInput = (e) => {
    const value = e.target.value.replace(/[^a-zA-Z\s]/g, '');
    e.target.value = value;
    if (e.target.value.trim() === "") {
      e.target.value = "";
    }
    if (value !== e.target.value) {
      setAccountHolderNameErrorMsg("Please enter alphabets");
    } else {
      setAccountHolderNameErrorMsg("");
    }
    setFormData({ ...formData, accountHolderName: e.target.value });
  };

  return (
    <div className="px-3 payout-info">
      {/* {JSON.stringify(formData, null, 3)} */}
      <form onSubmit={handleSubmit}>
        <label className="pb-2 fz-15px">
          Name<span className="text-danger">*</span>
        </label>
        <input
          className=" default-inpt mb-2"
          type="text"
          maxLength={"60"}
          ref={accountHolderNameFocusRef}
          value={formData?.accountHolderName}
          placeholder="Account Holder Name"
          onInput={handleAccountHolderNameInput}
          name="accountHolderName"
          onChange={(e) => handleFormChange(e)}
        />
        {isValidation && !formData?.accountHolderName ? (
          <small className="text-danger mb-2 d-block fz-12px fw-500">
            Please fill this field
          </small>
        ) : accountHolderNameErrorMsg ? (
          <small className="text-danger mb-2 d-block fz-12px fw-500">
            {accountHolderNameErrorMsg}
          </small>
        ) : (
          ""
        )}

        <label className="pb-2 fz-15px">
          Bank Name<span className="text-danger">*</span>
        </label>

        <Select
          ref={bankNameFocusRef}
          options={options}
          placeholder="Select bank name"
          defaultInputValue={formData?.bankName}
          className="fz-14px mb-2"
          name="bankName"
          onChange={(e) => setFormData({ ...formData, bankName: e.value })}
        />

        {isValidation && !formData?.bankName ? (
          <small className="text-danger mb-2 d-block fz-12px fw-500">
            Please fill this field
          </small>
        ) : (
          ""
        )}

        <label className="pb-2 fz-15px">
          Account number<span className="text-danger">*</span>
        </label>
        <input
          ref={accountNumberFocusRef}
          onInput={(e) => {
            if (e.target.value.trim() === "") {
              e.target.value = "";
            }
            if (!e.target.value.match(/^(\d+(\.\d+)?)$/)) {
              e.target.value = e.target.value.replace(/\D/g, "");
            }
          }}
          className="default-inpt mb-2"
          type="text"
          maxLength={17}
          minLength={9}
          value={formData?.accountNumber}
          placeholder="Enter the Account Number"
          name="accountNumber"
          onChange={(e) => handleFormChange(e)}
        />
        {isValidation && !formData?.accountNumber ? (
          <small className="text-danger mb-2 d-block fz-12px fw-500">
            Please fill this field
          </small>
        ) : (
          ""
        )}

        <label className="pb-2 fz-15px">
          IFSC Code<span className="text-danger">*</span>
        </label>
        <input
          ref={IFSCcodeFocusRef}
          onInput={(e) => {
            if (e.target.value.trim() === "") {
              e.target.value = "";
            }
          }}
          className="default-inpt mb-2"
          type="text"
          maxLength={11}
          minLength={11}
          value={formData?.IFSCcode}
          placeholder="Enter the IFSC code"
          name="IFSCcode"
          onChange={(e) => handleFormChange(e)}
        />
        {isValidation && ifcsCodeErrorMsg ? (
          !formData?.IFSCcode ? (
            ""
          ) : (
            <small className="text-danger mb-2 d-block fz-12px fw-500">
              {ifcsCodeErrorMsg}
            </small>
          )
        ) : (
          ""
        )}
        {isValidation && !formData?.IFSCcode ? (
          <small className="text-danger mb-2 d-block fz-12px fw-500">
            Please fill this field
          </small>
        ) : (
          ""
        )}

        <div className="d-flex justify-content-end mt-2">
          <input
            type="submit"
            value={loading ? "Please wait..." : "Submit"}
            disabled={loading ? true : false}
            className="border-0 bg-prime text-white fz-14px py-2 w-14 rounded-3 text-center"
          />
        </div>
      </form>
    </div>
  );
}

export default AddBankForm;
