import { useState } from "react";
/**
* Custom hook to retrieve and store cookies for our application.
* @param {String} key The key to store our data to
* @param {String} defaultValue The default value to return in case the cookie doesn't exist
*/
// document.cookie = "theme=dark; expires=Fri, 18 April 2020 12:00 UTC; fullname=Brandon Baars; expires=Fri, 18 April 2020 12:00:00 UTC; path=/";
// The expires is optional. If it’s not supplied it’s only stored during that session and then erased immediately. The path is also optional and tells the browser where the cookie belongs to.
const useCookie = (key, defaultValue) => {
    const getItem = key =>
        document.cookie.split("; ").reduce((total, currentCookie) => {
            const item = currentCookie.split("=");
            const storedKey = item[0];
            const storedValue = item[1];
            return key === storedKey
                ? decodeURIComponent(storedValue)
                : total;
        }, '');
    const setItem = (key, value, validity) => {
        const now = new Date();
        let expires = validity
        if (expires !== 0) {
            expires = 1;
        }
        // set the time to be now + numberOfDays
        // now.setTime(now.getTime() + (expires * 365 * 24 * 60 * 60));
        now.setFullYear(now.getFullYear() + 10);
        console.log(now)
        document.cookie = `${key}=${value}; expires=${now.toUTCString()}; path=/`;
    };
    const getCookie = () => getItem(key) || defaultValue;
    const [cookie, setCookie] = useState(getCookie());
    const updateCookie = (value, validity) => {
        setCookie(value);
        if (value) {
            setItem(key, value, validity);
        } else {
            setItem(key, value, 0);
        }
    };
    return [cookie, updateCookie];
};
export default useCookie;