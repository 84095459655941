import React, { useEffect, useState } from "react";
import { FiTrash2 } from "react-icons/fi";
import { MdModeEditOutline } from "react-icons/md";
import { useRecoilState } from "recoil";
import swal from "sweetalert";
import { API_CALL } from "../../../../../../api/ApiServiceCall";
import { API_CONSTANTS, API_METHOD } from "../../../../../../common/constants";
import { allChapterDataListAtomFunc } from "../../../../../atom/allChapterDataAtom";
import { getAllChapterAccordionsInCourseAtom } from "../../../../../atom/callFunctionGetAllChapterAccordionsAtom";
import { clickedChapterDataListAtomFunc } from "../../../../../atom/clickedChapterDataListAtomFunc";
import { currentViewComponentLsnAssessAtomFunc } from "../../../../../atom/lsnChapAssessComponentRender";
import RightArrow from "../../../../../svg-icons/rightArrow";
import CreateChapter from "../createChapter";
import SingleChapterView from "./singleChapterView";

function ChapterList() {
  useEffect(() => {
    setClickedChapterDataListAtom("");
  }, []); //Resetting  the ClickedChapterDataListAtom to prevent stale data for sec in other chapters
  const [clickedChapterDataListAtom, setClickedChapterDataListAtom] =
    useRecoilState(clickedChapterDataListAtomFunc);
  const [
    currentViewComponentLsnAssessAtom,
    setCurrentViewComponentLsnAssessAtom,
  ] = useRecoilState(currentViewComponentLsnAssessAtomFunc);
  const [isEdit, setIsEdit] = useState(false);
  const [clickedChapterDataForEdit, setClickedChapterDataForEdit] =
    useState("");
  const [
    callFunctionGetAllChapterAccordionsAtom,
    setCallFunctionGetAllChapterAccordionsAtom,
  ] = useRecoilState(getAllChapterAccordionsInCourseAtom);
  const [allChapterDataListAtom, setAllChapterDataListAtom] = useRecoilState(
    allChapterDataListAtomFunc
  ); // setAllChapterDataListAtom this state updating from main-uploaded-video component whenever we create chapter
  const clickedViewComponent = (chapterItemsData) => {
    setCurrentViewComponentLsnAssessAtom(
      <SingleChapterView clickedChapterData={chapterItemsData} />
    );
  };
  //onDelete
  const onDeleteChapter = async (chapterOrder) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover",
      icon: "https://cdn-icons-png.flaticon.com/512/6460/6460112.png",
      imageClass: "img-warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        const userCode = JSON.parse(localStorage.getItem("userCode"));
        let requestBodyParam = JSON.stringify({
          chapterOrder: chapterOrder,
          courseCode: userCode.data,
        });
        const request = {
          apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.DELETE_CHAPTER_NAME,
          method: API_METHOD.POST,
          requestBodyParams: requestBodyParam,
        };
        let result = await API_CALL(request);
        if (result.status === 200) {
          setCallFunctionGetAllChapterAccordionsAtom({
            changeOccur: Math.random(),
          });
        } else if (result.status > 200) {
          swal({
            title: "ERROR",
            text: result.message,
            icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
            className: "error-window",
            button: true,
          });
        }
      }
    });
  };
  //onEdit
  const onEditChapter = (chapterItemsData) => {
    setIsEdit(true);
    setClickedChapterDataForEdit(chapterItemsData);
  };

  const jsxChapterList = allChapterDataListAtom ? (
    allChapterDataListAtom?.data[0]?.chapterList.map((chapterItems, index) => (
      <div key={chapterItems?.chapterName + index}>
        <div className="chp-list d-flex justify-content-md-between align-items-center cursor-default">
          <h1
            className="m-0 fz-16px fw-500"
            role="button"
            onClick={() => clickedViewComponent(chapterItems)}
          >
            {chapterItems?.chapterName}
          </h1>
          {allChapterDataListAtom?.data[0]?.courseStatus === "INREVIEW" ? (
            ""
          ) : (
            <div className="d-flex justify-content-end  ms-auto opc-0">
              <div
                className="me-3"
                role="button"
                type="button"
                onClick={() => onEditChapter(chapterItems)}
              >
                <MdModeEditOutline className="text-prime" />
              </div>
              <div
                className="me-4"
                role="button"
                type="button"
                onClick={() => onDeleteChapter(chapterItems.chapterOrder)}
              >
                <FiTrash2 className="text-danger" />
              </div>
            </div>
          )}
          <div role="button" onClick={() => clickedViewComponent(chapterItems)}>
            <RightArrow size={8} />
          </div>
        </div>
      </div>
    ))
  ) : (
    <>
      {" "}
      <span
        className="spinner-border text-secondary spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
      <span className="visually-hidden">Loading...</span>
    </>
  );
  return (
    <div className="p-lg-2">
      {/* <pre> {JSON.stringify(allChapterDataListAtom, null, 4)}</pre> */}
      {isEdit ? (
        <CreateChapter
          setIsCreateChapterName={setIsEdit}
          clickedChapterDataForEdit={clickedChapterDataForEdit}
        />
      ) : (
        jsxChapterList
      )}
    </div>
  );
}

export default ChapterList;
