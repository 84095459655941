import React, { useEffect, useState } from "react";
import { FiTrash2 } from "react-icons/fi";
import { MdModeEditOutline } from "react-icons/md";
import { useRecoilState } from "recoil";
import swal from "sweetalert";
import { API_CALL } from "../../../../../../../api/ApiServiceCall";
import {
  API_CONSTANTS,
  API_METHOD,
} from "../../../../../../../common/constants";
import { liveCourseAssessmentDataListAtomState } from "../../../../../../atom/liveCourseAssessmentDataListAtom";
import { liveCourseAssessmentViewAtomFunc } from "../../../../../../atom/liveCourseAssessmentViewAtom";
import { liveCourseClickedAssessmentDataAtomFunc } from "../../../../../../atom/liveCourseClickedAssessmentDataAtom";
import LeftArrow from "../../../../../../svg-icons/leftArrow";
import EditClickedLiveCourseAssessment from "./editAssessmentLiveCourse";

function InsideDataAssessment() {
  const [liveCourseAssessmentViewAtom, setLiveCourseAssessmentViewAtom] =
    useRecoilState(liveCourseAssessmentViewAtomFunc);
  const [
    liveCourseClickedAssessmentDataAtom,
    setLiveCourseClickedAssessmentDataAtom,
  ] = useRecoilState(liveCourseClickedAssessmentDataAtomFunc);
  const [viewAssessmentData, setViewAssessmentData] = useState("");
  const [
    liveCourseAssessmentDataListAtom,
    setliveCourseAssessmentDataListAtom,
  ] = useRecoilState(liveCourseAssessmentDataListAtomState);

  const onDelete = async () => {
    const courseCode = localStorage.getItem("courseCode");
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover",
      icon: "https://cdn-icons-png.flaticon.com/512/6460/6460112.png",
      imageClass: "img-warning",
      animation: false,
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        const requestBodyParam = JSON.stringify({
          courseCode: courseCode,
          assessmentCode: liveCourseClickedAssessmentDataAtom.assessmentCode,
        });
        // alert(requestBodyParam)
        const request = {
          apiUrl:
            API_CONSTANTS.BASE_URL +
            API_CONSTANTS.DELETE_LIVE_COURSE_ASSESSMENT_BY_ASSESSMENT_CODE,
          method: API_METHOD.POST,
          requestBodyParams: requestBodyParam,
        };
        let result = await API_CALL(request);
        if (result.status === 200) {
          setLiveCourseAssessmentViewAtom(false);
        } else if (result.status > 200) {
          swal({
            title: "ERROR",
            text: result.message,
            icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
            className: "error-window",
            button: true,
          });
        }
      }
    });
  };
  const onEdit = () => {
    setLiveCourseAssessmentViewAtom(<EditClickedLiveCourseAssessment />);
  };
  useEffect(() => {
    getInnerAssessmentData();
  }, []);

  const getInnerAssessmentData = async () => {
    const courseCode = localStorage.getItem("courseCode");
    const request = {
      apiUrl:
        API_CONSTANTS.BASE_URL +
        API_CONSTANTS.GET_LIVE_COURSE_ASSESSMENT_BY_ASSESSMENT_CODE +
        `/?courseCode=${courseCode}&assessmentCode=${liveCourseClickedAssessmentDataAtom.assessmentCode}`,
      method: API_METHOD.GET,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      setViewAssessmentData(result.data);
      setLiveCourseClickedAssessmentDataAtom(result.data.assessmentdata);
    } else if (result.status > 200) {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }
  };

  return (
    <div>
      {/* <pre>{JSON.stringify(viewAssessmentData, null, 3)}</pre> Inside data */}
      {/* <pre>{JSON.stringify(liveCourseClickedAssessmentDataAtom, null, 3)}</pre> Inside data */}

      <div className="d-flex justify-content-end align-items-center pb-3">
        <button
          className="border-0 fz-17px my-0 py-0  bg-transparent   me-auto"
          role="button"
          onClick={() => setLiveCourseAssessmentViewAtom(false)}
        >
          <LeftArrow size={7} />{" "}
          {liveCourseClickedAssessmentDataAtom.assessmentTitle}
        </button>
        {liveCourseAssessmentDataListAtom?.courseStatus == "INREVIEW" ||
        liveCourseClickedAssessmentDataAtom.assessmentDetails.length == 0 ? (
          ""
        ) : (
          <>
            <div className="me-3" role="button" type="button" onClick={onEdit}>
              <MdModeEditOutline className="text-prime" /> Edit
            </div>
            <button
              className="p-0 m-0 border-0 bg-transparent fz-14px fw-500 text-danger"
              onClick={onDelete}
            >
              {" "}
              <FiTrash2 size={17} className="text-danger" /> Delete
            </button>
          </>
        )}
      </div>
      {viewAssessmentData
        ? viewAssessmentData?.assessmentdata.assessmentDetails?.map(
            (assessmentData, assesQuestionIndex) => {
              return (
                <div key={assessmentData._id}>
                  <h6>
                    {assesQuestionIndex + 1 + "."}
                    {assessmentData.assessmentQuestion}
                  </h6>
                  {assessmentData.assessmentChoice.map(
                    (assessmentOptions, assessmentOptionKey) => {
                      return (
                        <p key={assessmentOptionKey + assessmentOptions}>
                          option{assessmentOptionKey + 1} -{assessmentOptions}
                        </p>
                      );
                    }
                  )}
                </div>
              );
            }
          )
        : "Loading..."}
    </div>
  );
}

export default InsideDataAssessment;
