import { useState } from "react";
import { Container, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import swal from "sweetalert";
import { API_CONSTANTS } from "../../common/constants";
import "../../css/live-class.css";

function CreateNewPassword({ setSelected, getEmailid }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
  } = useForm({ mode: "all" });
  const [EyeVisible, setEyeVisible] = useState({
    newOne: "password",
    confirm: "password",
  });
  const [loading, setLoading] = useState(false);
  const [passwordNotMatch, setPasswordNotMatch] = useState(false);
  const [error, setError] = useState();

  const onSubmit = async (data) => {
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: getEmailid,
        newPassword: data.password,
        confirmPassword: data.confirm,
        userType: "STUDENT",
      }),
    };
    //  console.log("option check",requestOptions);
    fetch(API_CONSTANTS.BASE_URL + API_CONSTANTS.RESET_PASSWORD, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 200) {
          console.log(result.data);
          setLoading(false);
          setSelected();
          // reset();
        } else if (result.status > 200) {
          setError(result.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        swal({
          title: "ERROR",
          text: error,
          icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
          className: "error-window",
          button: true,
        });
        setLoading(false);
      });
  };

  const strongRegex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  );

  return (
    <Container>
      <Modal.Header closeButton className="mh">
        <Modal.Title className="mdlt">Create New Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="login-col" onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="newpass">New Password</label>
          <div className="position-relative">
            {" "}
            <input
              maxLength={16}
              className="mb-2"
              placeholder="Password "
              type={EyeVisible.newOne}
              id="newpass"
              {...register("password", {
                required: true,
                minLength: { value: 8, message: "Too short" },
                pattern: strongRegex,
                validate: (value) => {
                  if (value === getValues("confirm")) {
                    return setPasswordNotMatch(false);
                    ///return true
                  } else if (getValues("confirm")?.length > 0) {
                    return setPasswordNotMatch(true);
                    ///return <span>Password fields doesn't match</span>
                  }
                },
              })}
            />
            <div className="passfield">
              {EyeVisible.newOne === "password" ? (
                <span
                  onClick={() =>
                    setEyeVisible({ ...EyeVisible, newOne: "text" })
                  }
                >
                  <AiOutlineEyeInvisible />
                </span>
              ) : (
                <span
                  onClick={() =>
                    setEyeVisible({ ...EyeVisible, newOne: "password" })
                  }
                >
                  <AiOutlineEye />
                </span>
              )}
            </div>
          </div>
          <small className="text-danger mb-2 d-block fz-12px fw-500">
            {" "}
            {errors.password?.type === "required" && (
              <p> Please fill this field</p>
            )}
          </small>
          <small className="text-danger mb-2 d-block fz-12px fw-500">
            {" "}
            {errors.password?.type === "pattern" && (
              <p>
                {" "}
                Must contain at least one special character, one number and one
                uppercase and lowercase letter, and at least 8 or more
                characters and maximum 16 Characters
              </p>
            )}
          </small>
          <small className="text-danger mb-2 d-block fz-12px fw-500">
            {" "}
            {errors.password && <p>{errors.password.message}</p>}
          </small>
          <label htmlFor="Confirm">Confirm New Password</label>
          <div className="position-relative">
            <input
              placeholder="Confirm Password"
              maxLength={16}
              type={EyeVisible.confirm}
              className="mb-2"
              id="Confirm"
              {...register("confirm", {
                required: true,
                minLength: { value: 5, message: "Too short" },
                pattern: strongRegex,
                validate: (value) => {
                  if (value === getValues("password")) {
                    return setPasswordNotMatch(false);
                    ///return true
                  } else if (getValues("password")?.length > 0) {
                    return setPasswordNotMatch(true);
                    ///return <span>Password fields doesn't match</span>
                  }
                },
              })}
            />

            {passwordNotMatch && (
              <span
                style={{ fontSize: "0.8rem" }}
                className="text-danger fw-500"
              >
                Password fields doesn't match
              </span>
            )}

            <div className="passfield">
              {EyeVisible.confirm === "password" ? (
                <span
                  onClick={() =>
                    setEyeVisible({ ...EyeVisible, confirm: "text" })
                  }
                >
                  <AiOutlineEyeInvisible />
                </span>
              ) : (
                <span
                  onClick={() =>
                    setEyeVisible({ ...EyeVisible, confirm: "password" })
                  }
                >
                  <AiOutlineEye />
                </span>
              )}
            </div>
          </div>
          <small className="text-danger mb-2 d-block fz-12px fw-500">
            {" "}
            {errors.confirm?.type === "required" && (
              <p>Please fill this field</p>
            )}
          </small>
          <small className="text-danger mb-2 d-block fz-12px fw-500">
            {" "}
            {errors.confirm && <p>{errors.confirm.message}</p>}
          </small>
          <button className="login-form-btn">Create New Password</button>
          <small className="text-danger mb-2 d-block fz-15px fw-500 text-center">
            {" "}
            {error}
          </small>
        </form>
      </Modal.Body>
    </Container>
  );
}
export default CreateNewPassword;
