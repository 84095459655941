import { useEffect, useState } from "react";
import swal from "sweetalert";
import { API_CALL } from "../../../api/ApiServiceCall";
import { API_CONSTANTS, API_METHOD } from "../../../common/constants";

function FAQ({ courseCode }) {
  const [faq, setFaq] = useState({ loading: false, error: null, data: [] });

  const getFaq = async (courseCode) => {
    setFaq({ loading: true, data: [], error: null });

    const request = {
      apiUrl:
        API_CONSTANTS.BASE_URL + API_CONSTANTS.FAQ_BY_COURSE_CODE(courseCode),
      method: API_METHOD.GET,
    };

    let result = await API_CALL(request);

    if (result.status === 200) {
      setFaq({
        loading: false,
        data: result.data[0].faqList ? result.data[0].faqList : [],
        error: null,
      });
    } else if (result.status > 200) {
      setFaq({ loading: false, data: [], error: result.message });

      if (result.message == "Token is Not Valid") return null;

      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }
  };

  useEffect(() => {
    if (courseCode) {
      getFaq(courseCode);
    }
  }, [courseCode]);

  return (
    <div>
      {faq.data?.length > 0 ? (
        faq.data?.map((faq) => (
          <FAQItem
            key={faq.faqOrder}
            heading={faq.question}
            answer={faq.answer}
          />
        ))
      ) : (
        <p>No Faq's</p>
      )}
    </div>
  );
}

function FAQItem({ heading, answer }) {
  const [open, setOpen] = useState(false);

  return (
    <div
      className="p-3 rounded-2 prevent-text-select my-3"
      style={{ backgroundColor: " #F3F3F3" }}
    >
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ cursor: "pointer" }}
        onClick={() => setOpen((prevState) => !prevState)}
      >
        <h2
          className="fs-6 p-0 m-0 fw-normal"
          style={{ color: open ? "#000" : "#395061" }}
        >
          {heading}
        </h2>
        {open ? (
          <svg
            width="15"
            height="9"
            className="flex-shrink-0"
            viewBox="0 0 15 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.937438 8.20062C1.18599 8.2004 1.42429 8.10149 1.59994 7.92562L6.39493 3.13062C6.54003 2.98549 6.7123 2.87036 6.9019 2.79181C7.09149 2.71326 7.29471 2.67283 7.49993 2.67283C7.70516 2.67283 7.90837 2.71326 8.09797 2.79181C8.28757 2.87036 8.45984 2.98549 8.60493 3.13062L13.3968 7.91937C13.5736 8.09015 13.8104 8.18464 14.0562 8.1825C14.3021 8.18037 14.5372 8.08177 14.711 7.90795C14.8848 7.73413 14.9834 7.499 14.9856 7.25319C14.9877 7.00738 14.8932 6.77056 14.7224 6.59375L9.93431 1.805C9.28914 1.1611 8.41487 0.799471 7.50337 0.799471C6.59187 0.799471 5.7176 1.1611 5.07244 1.805L0.277438 6.6C0.146428 6.73092 0.0571336 6.89772 0.0208244 7.07934C-0.0154839 7.26097 0.00281807 7.44927 0.0734234 7.62051C0.144029 7.79174 0.263773 7.93822 0.417543 8.04146C0.571315 8.14471 0.752222 8.20009 0.937438 8.20062Z"
              fill="#395061"
            />
          </svg>
        ) : (
          <svg
            width="15"
            height="9"
            className="flex-shrink-0"
            viewBox="0 0 15 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.0626 0.799378C13.814 0.799597 13.5757 0.898512 13.4001 1.07438L8.60507 5.86938C8.45997 6.01451 8.2877 6.12964 8.0981 6.20819C7.90851 6.28674 7.70529 6.32717 7.50007 6.32717C7.29484 6.32717 7.09163 6.28674 6.90203 6.20819C6.71243 6.12964 6.54016 6.01451 6.39507 5.86938L1.6032 1.08063C1.42638 0.909854 1.18957 0.815359 0.943757 0.817496C0.697948 0.819632 0.462812 0.918227 0.288992 1.09205C0.115172 1.26587 0.0165757 1.501 0.0144397 1.74681C0.0123037 1.99262 0.106798 2.22944 0.277571 2.40625L5.06569 7.195C5.71086 7.8389 6.58513 8.20053 7.49663 8.20053C8.40813 8.20053 9.2824 7.8389 9.92756 7.195L14.7226 2.4C14.8536 2.26908 14.9429 2.10228 14.9792 1.92066C15.0155 1.73903 14.9972 1.55073 14.9266 1.3795C14.856 1.20826 14.7362 1.06178 14.5825 0.958539C14.4287 0.855294 14.2478 0.799911 14.0626 0.799378Z"
              fill="#395061"
            />
          </svg>
        )}
      </div>
      {open && (
        <div
          className="mt-2 text-muted"
          style={{ fontSize: "0.9rem", lineHeight: "1.7" }}
        >
          {answer}
          {/* It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using */}
        </div>
      )}
    </div>
  );
}

export default FAQ;
