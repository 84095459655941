import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import { API_CALL } from '../../../../api/ApiServiceCall';
import { API_CONSTANTS, API_METHOD } from '../../../../common/constants';

export const CreateIndependentAssessment = () => {
    const navigate = useNavigate();
    const [getCategory, setGetCategory] = useState([])
    const [getSubCategory, setGetSubCategory] = useState(false)
    const [getCategoryFormData, setGetCategoryFormData] = useState("")
    const [getSubCategoryFormData, setGetSubCategoryFormData] = useState("")
    const [isValidate, setIsValidate] = useState({
        courseTitle: false, currency: false, fee: false, getCategoryFormData: false, getSubCategoryFormData: false
    })
    const [independentAssessmentTitle, setIndependentAssessmentTitle] = useState("");
    const [independentAssessmentCurrency, setIndependentAssessmentCurrency] = useState({
        currency: "INR",
        fee: ""
    });

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getAllCategory();

    }, [])
    const getAllCategory = async () => {
        const request = {
            apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.GET_ALL_CATEGORY,
            method: API_METHOD.GET,
        }
        let result = await API_CALL(request)
        if (result.status === 200) {
            setGetCategory(result.data);
            // alert(JSON.stringify(result));
        } else {
            swal({
                title: "ERROR",
                text: result.message,
                icon: 'https://cdn-icons-png.flaticon.com/512/2569/2569174.png',
                className: "error-window",
                button: true,
            })
        }

    }
    const getCurrentCategoryCode = (e) => {
        setGetSubCategoryFormData("")
        setGetCategoryFormData(e.target.value)
        // const index = e.target.selectedIndex;
        // const el = e.target.childNodes[index]
        // const option = el.getAttribute('id');
        getSubCategoryByCode(e.target.value)
    }
    const getSubCategoryByCode = async (catCode) => {
        const request = {
            apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.GET_SUB_CATEGORY_BY_CATEGORYCODE + `/?categoryCode=${catCode}`,
            method: API_METHOD.GET,
        }
        let result = await API_CALL(request)

        if (result.status === 200) {
            setGetSubCategory(result);
        } else if (result.status > 200) {
            console.log('errror', result)
        }
    }

    const submitAssessment = (e) => {
        e.preventDefault()
        const isAllow = independentAssessmentTitle && getCategoryFormData && getSubCategoryFormData && independentAssessmentCurrency.fee && independentAssessmentCurrency.currency
        if (isAllow) {
            allowCreateAssessment();
        } else {
            setIsValidate({ courseTitle: true, getCategoryFormData: true, getSubCategoryFormData: true, currency: true, fee: true });
        }
    }

    const allowCreateAssessment = async () => {
        setLoading(true);
        const requestBodyParam = JSON.stringify({
            assessmentTitle: independentAssessmentTitle,
            catogory: getCategoryFormData,
            subCategory: getSubCategoryFormData,
            currency: independentAssessmentCurrency.currency,
            fee: independentAssessmentCurrency.fee,
            isEdit: false
        })
        const request = {
            apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.CREATE_UPDATE_ASSESSMENT_DETAILS,
            method: API_METHOD.POST,
            requestBodyParams: requestBodyParam,
        }
        let result = await API_CALL(request)
        if (result.status === 200) {
            localStorage.setItem("independentAssessmentCode", result.data)
            setLoading(false);
            navigate('/courses/live/inner-independent-assessment');
        } else if (result.status > 200) {
            swal({
                title: "ERROR",
                text: result.message,
                icon: 'https://cdn-icons-png.flaticon.com/512/2569/2569174.png',
                className: "error-window",
                button: true,
            })
            setLoading(false);
        }
    }

    return (
        <div className="px-4 mx-2">
            <div className='  pt-4'>
                <form onSubmit={submitAssessment}>
                    <label className='pb-2 fz-14px text-dark fw-500'>Assessment Title</label>
                    <input type="text" className="default-inpt" defaultValue={independentAssessmentTitle} onBlur={() => setIsValidate({ ...isValidate, courseTitle: true })} onChange={e => setIndependentAssessmentTitle(e.target.value)} />
                    {isValidate.courseTitle && independentAssessmentTitle === "" ? <small className='text-danger my-0 d-block fz-12px mt-3 fw-500'>Please Fill this field</small> : ""}
                    <div className="py-3"></div>
                    <div className="row">
                        <div className="col">
                            <label className="fz-14px text-dark fw-500 pb-3">Category</label>

                            <div className='tag-ajus mb-2'>
                                <select defaultValue={''} onChange={getCurrentCategoryCode} onBlur={() => setIsValidate({ ...isValidate, getCategoryFormData: true })}>
                                    <option value="" className='d-none'>Select a category</option>
                                    {getCategory ? getCategory.map((catDatas, index) => (

                                        <option key={catDatas._id + index} value={catDatas.categoryCode}>{catDatas.categoryName}</option>

                                    )) : <option value="" >Loading...</option>}
                                </select>
                            </div>
                            {isValidate.getCategoryFormData && getCategoryFormData === "" ? <small className='text-danger my-0 d-block fz-12px mt-3 fw-500'>Please Fill this field</small> : ""}

                        </div>
                        <div className="col">
                            <label className="fz-14px text-dark fw-500 pb-3">Sub - Category</label>
                            <div className='tag-ajus mb-2'>
                                <select defaultValue={''} onChange={(e) => setGetSubCategoryFormData(e.target.value)} onBlur={() => setIsValidate({ ...isValidate, getSubCategoryFormData: true })}>
                                    <option value="" className='d-none'>Select a Sub - category</option>
                                    {getSubCategory ? getSubCategory.data.map((subCatDatas, index) => (

                                        <option key={subCatDatas._id + "key" + index} value={subCatDatas.subCategoryCode} >{subCatDatas.subCategoryName}</option>

                                    )) : ""}
                                </select>
                            </div>
                            {isValidate.getSubCategoryFormData && getSubCategoryFormData === "" ? <small className='text-danger my-0 d-block fz-12px mt-3 fw-500'>Please Fill this field</small> : ""}


                        </div>
                    </div>
                    <div className="row py-4">
                        <div className="col-6">
                            <label className="py-3 fw-500">Currency</label>
                            <div className='tag-ajus'>
                                <select defaultValue={independentAssessmentCurrency?.currency} disabled onBlur={() => setIsValidate({ ...isValidate, currency: true })} onChange={(e) => setIndependentAssessmentCurrency({ ...independentAssessmentCurrency, currency: e.target.value })}>
                                    <option value="" disabled>Select Currency</option>
                                    <option value="INR">₹</option>
                                    <option value="USD">$</option>
                                </select>
                            </div>
                            {isValidate.currency && independentAssessmentCurrency.currency === "" ? <small className='text-danger my-0 d-block fz-12px my-2 fw-500'>Please Fill this field</small> : ""}

                        </div>
                        <div className='col-6 '>
                            <label className="py-3 fw-500">Fee</label>
                            <div className='tag-ajus'>
                                <select defaultValue={""} onBlur={() => setIsValidate({ ...isValidate, fee: true })} onChange={(e) => setIndependentAssessmentCurrency({ ...independentAssessmentCurrency, fee: e.target.value })}>
                                    <option value="" disabled>Select Fee</option>
                                    <option value="1000">1000</option>
                                    <option value="2000">2000</option>
                                    <option value="5000">5000</option>
                                </select>
                            </div>
                            {isValidate.fee && independentAssessmentCurrency.fee === "" ? <small className='text-danger my-0 d-block fz-12px my-2 fw-500'>Please Fill this field</small> : ""}
                        </div>

                    </div>
                    <div className='d-flex  justify-content-end align-items-end  py-5 '>
                        <button className='border-0 bg-prime text-white fz-14px py-12px w-14 rounded-3 text-center' type='submit'>Next</button>
                    </div>
                </form></div></div>
    )
}
