import React, { useState } from "react";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import { API_CALL } from "../../../../../api/ApiServiceCall";
import { API_CONSTANTS, API_METHOD } from "../../../../../common/constants";

function EditFaqLiveCourse({
  setIsFaqActiveIndex,
  question,
  answer,
  faqOrder,
  getUpdatedFaq,
}) {
  const [loading, setLoading] = useState(false);

  const {
    register,
    reset,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = async (data) => {
    setLoading(true);
    const userCode = localStorage.getItem("courseCode");
    const requestBodyParam = JSON.stringify({
      courseCode: userCode,
      question: data.faqQuestionUpdation,
      answer: data.faqAnswerUpdation,
      faqOrder: faqOrder,
    });
    const request = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.CREATE_LIVE_COURSE_FAQ,
      method: API_METHOD.POST,
      requestBodyParams: requestBodyParam,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      setLoading(false);
      getUpdatedFaq();
      setIsFaqActiveIndex(-1);
    } else if (result.status > 200) {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
      setLoading(false);
    }
  };
  return (
    <div>
      <div className="login-col">
        <form id="faqUpdateForm" onSubmit={handleSubmit(onSubmit)}>
          <input
            type="text"
            defaultValue={question}
            className="w-100"
            placeholder="Question"
            {...register("faqQuestionUpdation")}
            required
          />
          <textarea
            cols="6"
            defaultValue={answer}
            rows="5"
            className="w-100 default-inpt"
            placeholder="Answer"
            {...register("faqAnswerUpdation")}
            required
          />
        </form>
      </div>
      <div className="d-flex justify-content-end">
        <button
          className="border-0 bg-secondary my-3 text-white fz-14px py-2 w-14 rounded-3 text-center"
          onClick={() => setIsFaqActiveIndex(-1)}
        >
          Cancel
        </button>
        <button
          type="submit"
          form="faqUpdateForm"
          className="border-0 bg-prime my-3 ms-lg-3 text-white fz-14px py-2 w-14 rounded-3 text-center"
          disabled={loading ? true : false}
        >
          {loading ? "Please Wait..." : "Update"}
        </button>
      </div>
    </div>
  );
}

export default EditFaqLiveCourse;
