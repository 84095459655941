import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import swal from "sweetalert";
import { API_CALL } from "../../../../../api/ApiServiceCall";
import socket from "../../../../../common/Socket";
import { API_CONSTANTS, API_METHOD } from "../../../../../common/constants";
import CouponTickets from "./CouponTickets";
import EnableCoupon from "./EnableCoupon";

function CouponTable({ triggerGetDiscountRequestApi }) {
  const [discountDataList, setDiscountDataList] = useState("");
  const [clickedRowData, setClickedRowData] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };
  const [SECONDS] = useState(5000);
  const courseListRef = useRef(null);

  useEffect(() => {
    getDiscountRequest();
    const interval = setInterval(() => {
      newMessageCount(courseListRef.current);
    }, SECONDS);
    socket.open();
    socket.on("connection-success", async (response) => {
      console.log(`Socket connected ${response.socketId}`);
    });
    return () => {
      socket.close();
      socket.disconnect();
      clearInterval(interval);
      socket.off("newMessageCount");
      socket.on("disconnect", function () {});
    };
  }, [triggerGetDiscountRequestApi]);
  const newMessageCount = (data) => {
    socket.emit(
      "newMessageCount",
      { ticketType: "DISCOUNT" },
      async (response) => {
        console.log("response===>", response);
        let apiArry = data;
        let socketArry = response;
        let combinedArry = apiArry.map((obj1) => {
          let obj2 = socketArry.find((obj2) => obj1._id === obj2.courseCode);
          return Object.assign({}, obj1, obj2);
        });
        setDiscountDataList(combinedArry);
      }
    );
  };
  const getDiscountRequest = async () => {
    const request = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.GET_DISCOUNT_REQUEST,
      method: API_METHOD.GET,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      courseListRef.current = result.data;
      setDiscountDataList(result.data);
      // newMessageCount(result.data);
    } else if (result.status > 200) {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }
  };

  const onSubmitForReview = async (data) => {
    swal({
      title: "Are you sure?",
      imageClass: "img-warning",
      buttons: true,
      buttons: ["Cancel", "Yes"],
      closeOnClickOutside: false,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        const requestBodyParam = JSON.stringify({
          discountId: data._id,
        });
        const request = {
          apiUrl:
            API_CONSTANTS.BASE_URL + API_CONSTANTS.COUPON_SUBMIT_FOR_APPROVAL,
          method: API_METHOD.POST,
          requestBodyParams: requestBodyParam,
        };
        let result = await API_CALL(request);
        if (result.status === 200) {
          getDiscountRequest();
          swal("", "Submitted for review", "success");
        } else if (result.status > 200) {
          swal({
            title: "ERROR",
            text: result.message,
            icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
            className: "error-window",
            button: true,
          });
        }
      }
    });
  };
  const actionWrapper = (dsData) => {
    const expiryDate = moment(dsData.expiryDate).add(1, "days");
    // Add 1 day to the current date
    const currentDate = moment(dsData.currentDate);

    const isExpired = expiryDate.isBefore(currentDate);

    switch (dsData?.requestedstatus) {
      case "APPROVED":
        return (
          <button
            className={`ACTIVE border-0 w-100 text-white p-2 fz-14px rounded-2`}
            disabled
          >
            Approved
          </button>
        );

      case "CREATED":
        return (
          <button
            className="border-0 w-100 bg-prime text-white p-2 fz-14px rounded-2"
            style={{ whiteSpace: "nowrap" }}
            onClick={() => onSubmitForReview(dsData)}
            disabled={isExpired}
          >
            {isExpired ? "Submit for review" : "submit for review"}
          </button>
        );
      case "REQUESTED":
        return (
          <button
            className="border-0 w-100 bg-warning text-white p-2 fz-14px rounded-2"
            disabled
          >
            In Review
          </button>
        );

      default:
        return "Error";
    }
  };

  const onEnable = (dsData) => {
    setClickedRowData(dsData);
    handleShow();
  };
  return (
    <div className="py-5 my-3 bg-white rounded-3">
      <div className="px-lg-4 position-relative">
        <div className="d-flex align-items-lg-center justify-content-lg-between">
          <h6>Coupon details</h6>
          {/* <div className="bg-pink px-4 py-2 rounded-2 d-flex align-items-center" role={"button"}>
                            <PrinterIcon width={19} />
                            <p className="ms-2 my-0 fz-14px fw-500 text-prime">Print</p>
                        </div> */}
        </div>
        <div className="py-2"></div>
        {/* ------- */}
        <div className="table-responsive-md">
          <table
            className="table table-borderless"
            style={{ overflowX: "hidden" }}
          >
            <thead>
              <tr>
                <th className="text-muted fw-400 fz-15px text-center">
                  Time Stamp
                </th>
                <th className="text-muted fw-400 fz-15px text-center">
                  Coupon name
                </th>
                <th className="text-muted fw-400 fz-15px text-center">
                  Discount
                </th>
                <th className="text-muted fw-400 fz-15px text-center">
                  No of Coupons
                </th>
                <th className="text-muted   fw-400 fz-15px text-center">
                  {" "}
                  Start date
                </th>
                <th className="text-muted fw-400 fz-15px text-center">
                  Expiry date
                </th>
                <th className="text-muted fw-400 fz-15px text-center">
                  Raise a Ticket
                </th>
                {/* <th className='text-muted fw-400 fz-15px'>Status</th> */}
                <th className="text-muted fw-400 fz-15px w-25 text-center">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {!discountDataList ? (
                <tr>
                  <td> Loading...</td>
                </tr>
              ) : discountDataList.length ? (
                discountDataList.map((dsData, index, array) => (
                  <tr key={dsData?._id}>
                    <td className="text-center">
                      {moment(dsData?.createdTime).format("DD MMM,YYYY")}
                    </td>
                    <td className="text-center">{dsData?.couponName}</td>
                    <td className="text-center">
                      {dsData?.discountPercentage}%
                    </td>
                    <td className="text-center">{dsData?.numberOfCoupon}</td>
                    <td className="text-center">
                      {moment(dsData?.startDate).format("DD MMM,YYYY")}
                    </td>
                    <td className="text-center">
                      {moment(dsData?.expiryDate).format("DD MMM,YYYY")}
                    </td>
                    <td className="text-center">
                      <div className="mx-4 position-relative">
                        <CouponTickets data={dsData} />
                        {dsData?.newMessageCount ? (
                          <div className="ticket-notification-badge-table-discount">
                            {dsData?.newMessageCount}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </td>
                    <td className=" d-flex align-items-center">
                      {actionWrapper(dsData)}
                      {moment(moment().format("YYYY-MM-DD")).isSameOrBefore(
                        moment(dsData?.expiryDate).format("YYYY-MM-DD")
                      ) ? (
                        <button
                          className={`bg-pink border-0 text-prime px-3 py-2 mx-2  fz-14px rounded-2`}
                          disabled={
                            dsData?.requestedstatus == "APPROVED" &&
                            moment(
                              moment().format("YYYY-MM-DD")
                            ).isSameOrBefore(
                              moment(dsData?.expiryDate).format("YYYY-MM-DD")
                            )
                              ? false
                              : true
                          }
                          onClick={() => onEnable(dsData)}
                        >
                          Enable
                        </button>
                      ) : (
                        <span className="text-danger  fz-14px mx-2">
                          Expired{" "}
                        </span>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                "No data"
              )}
            </tbody>
          </table>
        </div>
        {/* ------- */}
        {/* <pre>{JSON.stringify(discountDataList, null, 3)}</pre> */}
        <Modal
          show={show}
          fullscreen={true}
          onHide={handleClose}
          animation={true}
          centered
          backdrop={"static"}
          className="mh"
        >
          <Modal.Header closeButton className="mh">
            <Modal.Title className="mdlt">Active Courses </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <EnableCoupon clickedRowData={clickedRowData} />
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}
export default CouponTable;
