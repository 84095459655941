import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import swal from "sweetalert";
import { API_CALL } from "../api/ApiServiceCall";
import { API_CONSTANTS, API_METHOD, STORAGE_KEY } from "../common/constants";
import ErroImg from "../images/icons/error.png";
export default function PrivateRoute(props) {
  // ssavigation = useNavigation();
  const {
    pagesFor,
    isloginUrl,
    isSignupUrl,
    loginComponent,
    signupComponent,
    isStudentLoginUrl,
  } = props;
  let location = useLocation();

  const authCookie = document.cookie
    .split("; ")
    .reduce((total, currentCookie) => {
      const item = currentCookie.split("=");
      const storedKey = item[0];
      const storedValue = item[1];
      return STORAGE_KEY.AUTH === storedKey
        ? decodeURIComponent(storedValue)
        : total;
    }, "");

  const userData = localStorage.getItem(STORAGE_KEY.LOCAL_USER_DATA);
  const userCode = JSON.parse(localStorage.getItem("localUserData"));
  // let isLoggedIn = authCookie && userData;  //prev code checking for cookie
  let isLoggedIn = userData; // we are not checking cookie now

  const isUserLoggedIn = (user) =>
    isLoggedIn && userData && JSON.parse(userData).userType === user;
  // || isloginUrl || isSignupUrl

  const emailCheck = async () => {
    console.log("coming emailcheck");
    let request;
    if (userCode.userType === "INSTRUCTOR") {
      request = {
        apiUrl:
          API_CONSTANTS.BASE_URL +
          API_CONSTANTS.GET_INSTRUCTOR_BY_EMAIL +
          `?instructorEmail=${userCode?.email || userCode?.data?.user?.email}`,
        method: API_METHOD.GET,
      };
    } else {
      let email = userCode?.email || userCode?.data?.user?.email;
      request = {
        apiUrl:
          API_CONSTANTS.BASE_URL +
          API_CONSTANTS.GET_STUDENT_BY_EMAIL +
          `?email=${email}`,
        // apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.GET_STUDENT_BY_EMAIL(userCode?.email || userCode?.data?.user?.email),
        method: API_METHOD.GET,
      };
    }
    let result = await API_CALL(request);
    console.log("result", result);

    if (result.status === 200) {
      console.log("true");
    } else if (result.status > 200) {
      swal({
        title: "Please try to re-login",
        icon: ErroImg,
        className: "error-window",
        button: true,
        closeOnClickOutside: false,
      }).then(function () {
        if (userCode.userType === "INSTRUCTOR") {
          localStorage.clear();
          window.location = "/login";
        } else {
          localStorage.clear();
          window.location = "/student-login";
        }
      });
    }
  };

  useEffect(() => {
    console.log("location data", location);
    emailCheck();
  }, [location?.pathname]);

  if (isloginUrl && isUserLoggedIn("STUDENT")) {
    return <Navigate to="/student-dashboard" />;
  }

  if (isloginUrl && !isStudentLoginUrl && isUserLoggedIn("INSTRUCTOR")) {
    return <Navigate to="/transaction" />;
  }

  if (isUserLoggedIn("STUDENT") && pagesFor === "INSTRUCTOR") {
    return <Navigate to="/student-dashboard" />;
  }

  // || isloginUrl || isSignupUrl

  if (isUserLoggedIn("INSTRUCTOR") && pagesFor === "STUDENT") {
    return <Navigate to="/transaction" />;
  }

  if (!isUserLoggedIn("STUDENT") && pagesFor === "STUDENT") {
    return <Navigate to="/student-login" />;
  }

  if (!isUserLoggedIn("INSTRUCTOR") && pagesFor === "INSTRUCTOR") {
    return <Navigate to="/login" />;
  }

  if (isSignupUrl) return signupComponent;

  if (isloginUrl) return loginComponent;

  return <Outlet />;
}
