import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { API_CALL } from "./../../api/ApiServiceCall";
import { API_CONSTANTS, API_METHOD } from "./../../common/constants";

function JoinLiveAlert() {
  const [liveClass, setLiveClass] = useState(null);

  const getLiveClass = async () => {
    const request = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.JOIN_LIVE_ALERT,
      method: API_METHOD.GET,
    };

    let result = await API_CALL(request);

    if (result.status === 200) {
      if (!result?.data) return null;

      if (result?.data?.length === 0) return null;

      const lastAlertShowedAtTime = localStorage.getItem(
        "lastAlertShowedAtTime"
      );
      if (lastAlertShowedAtTime) {
        const previousTime = new Date(lastAlertShowedAtTime);
        const now = new Date();
        const timeDiffInMs = now - previousTime;
        const timeDiffInMinutes = Math.round(timeDiffInMs / (1000 * 60));

        if (Number(timeDiffInMinutes) <= 5) {
          console.log("Below 5 mins");
        } else {
          localStorage.setItem("lastAlertShowedAtTime", Date.now());
          setLiveClass(result);
        }
      } else {
        localStorage.setItem("lastAlertShowedAtTime", Date.now());
        setLiveClass(result);
      }
    } else if (result.status > 200) {
      setLiveClass(null);

      if (result.message == "Token is Not Valid") return null;

      console.error(result.message);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      getLiveClass();
    }, 20000); //

    return () => clearInterval(interval);
  }, []);

  return (
    <Modal
      show={liveClass?.data?.length}
      onHide={() => setLiveClass(null)}
      centered
      size="md"
      backdrop="static"
    >
      <div className="mh">
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="m-0 p-0 text-prime fz-19px">
              Live {liveClass?.data?.length > 1 ? "classes" : "class"} will be
              start in 5 minutes
            </h5>
          </Modal.Title>
        </Modal.Header>
      </div>
      <Modal.Body>
        {liveClass?.data?.map((liveClass) => (
          <div
            style={{
              border: "1px solid lightgray",
              padding: "20px",
              marginBottom: "10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderRadius: "5px",
            }}
          >
            <h6
              className="text-prime"
              style={{ marginBottom: 0, paddingBottom: 0 }}
            >
              {liveClass.courseName}
            </h6>
            <div style={{ width: "110px", flexShrink: 0 }}>
              <Link
                to={`/course/${liveClass.courseCode}`}
                onClick={() => setLiveClass(null)}
              >
                Go to Course
              </Link>
            </div>
          </div>
        ))}
      </Modal.Body>
    </Modal>
  );
}

export default JoinLiveAlert;
