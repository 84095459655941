import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { liveCourseFormDataState } from "../../../atom/liveCourseFormDataState";
import { liveCourseStepFormViewAtomState } from "../../../atom/liveCourseStepFormViewAtom";
import Step1LiveCourse from "./step1-live-course/step1LiveCourse";
import Step2LiveCourse from "./step2-live-course.js/step2LiveCourse";
import Step3LiveCourse from "./step3-live-course/step3LiveCourse";

function LiveCoursesStepForm() {
  useEffect(() => {
    setLiveCourseStepFormViewAtomComponent({ componentName: "Step1" });
  }, []);

  const [
    liveCourseStepFormViewAtomComponent,
    setLiveCourseStepFormViewAtomComponent,
  ] = useRecoilState(liveCourseStepFormViewAtomState);
  const isStep2Active =
    liveCourseStepFormViewAtomComponent.componentName === "Step2" ||
    liveCourseStepFormViewAtomComponent.componentName === "Step3";
  const isStep3Active =
    liveCourseStepFormViewAtomComponent.componentName === "Step3";
  const [liveCourseFormDataAtom, setLiveCourseFormDataAtom] = useRecoilState(
    liveCourseFormDataState
  );
  return (
    <div className="container">
      <div className=" py-6">
        <ul className="list-unstyled multi-steps">
          <li className="is-active">Course Details</li>
          <li className={isStep2Active ? "is-active" : ""}>
            Pricing &amp; Intro
          </li>
          <li className={isStep3Active ? "is-active" : ""}>Time Duration</li>
        </ul>
      </div>

      {/* {liveCourseStepFormViewAtomComponent.component ? liveCourseStepFormViewAtomComponent.component : <Step1LiveCourse />} */}
      {/* <pre> {JSON.stringify(liveCourseFormDataAtom,null,3)}</pre> */}
      {
        <div
          className={
            liveCourseStepFormViewAtomComponent.componentName === "Step1"
              ? "d-block"
              : "d-none"
          }
        >
          <Step1LiveCourse />
        </div>
      }
      {
        <div
          className={
            liveCourseStepFormViewAtomComponent.componentName === "Step2"
              ? "d-block"
              : "d-none"
          }
        >
          <Step2LiveCourse />
        </div>
      }
      {
        <div
          className={
            liveCourseStepFormViewAtomComponent.componentName === "Step3"
              ? "d-block"
              : "d-none"
          }
        >
          <Step3LiveCourse />
        </div>
      }
    </div>
  );
}

export default LiveCoursesStepForm;
