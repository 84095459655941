import React from "react";
import { useLocation } from "react-router-dom";
import CourseTopMenu from "../components/courses/course-top-menu";
import CoursesSidebar from "../components/courses/sidebar";
import DashboardSidebar from "../components/dashboard/sidebar/dashboardSideBar";
import JoinLiveAlert from '../student/common/JoinLiveAlert';
import StudentMenu from '../student/common/student_menu';
import { COMPONENT_IDENTIFIER } from "./constants";
import Footer from "./footer";
import Menu from "./menu";



export default function WrapperComponent({ componentIdentifier = "", ...props }) {

    const location = useLocation();
    const isPathName = location.pathname === '/courses/recorded' || location.pathname === '/courses/recorded/learnslist' || location.pathname === '/mylearners' || location.pathname === '/courses/live' || location.pathname === '/courses/live/assessment';
    switch (componentIdentifier) {
        case "":
            return (
                <div> {props.children}</div>
            )
        case COMPONENT_IDENTIFIER.COURSE:
            return (
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-2 vh-100 bg-sidebr bg-white lg-block">
                            <CoursesSidebar />
                        </div>
                        <div className={isPathName ? "col bg-wall" : "col"}>
                            <CourseTopMenu />
                            {props.children}
                        </div>
                    </div>
                </div>
            )
        case COMPONENT_IDENTIFIER.COURSE_OVERVIEW:
            return (
                <div className="container-fluid">
                    {/* Overview Layout */}
                    <div className="row">
                        <div className="col-lg-2 vh-100 bg-sidebr bg-white lg-block">
                            <CoursesSidebar />
                        </div>
                        <div className="col bg-wall">
                            <CourseTopMenu />
                            {props.children}
                        </div>
                    </div>
                </div>
            )

        case COMPONENT_IDENTIFIER.HOME:
            return (
                <>
                    <Menu />
                    <main>{props.children}</main>
                    <Footer />
                </>
            )
        case COMPONENT_IDENTIFIER.PROFILE:
            return (
                <>
                    {/* <ProfileMenuTeacher /> */}
                    <Menu />
                    <div className="py-5"></div>
                    <main>{props.children}</main>
                </>
            )
        case COMPONENT_IDENTIFIER.DASHBOARD:
            return (
                <>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-2 vh-100 bg-sidebr bg-white">
                                <DashboardSidebar />
                            </div>
                            <div className={"col bg-wall"}>
                                {props.children}
                            </div>
                        </div>
                    </div>
                </>
            )
        case COMPONENT_IDENTIFIER.STUDENT_PROFILE:
            return (
                <>
                    <StudentMenu />
                    <div className="py-5"></div>
                    <main>{props.children}</main>
                    <div className="py-5"></div>
                    <JoinLiveAlert />
                </>
            )
        case COMPONENT_IDENTIFIER.STUDENT_HOME:
            return (
                <>
                    <StudentMenu />
                    <div style={{ background: '#fcfcfc' }} className="py-4"></div>
                    <main style={{ background: '#fcfcfc' }}>{props.children}</main>
                    <Footer />
                    <JoinLiveAlert />
                </>
            )
        default:
            return (
                <div> {props.children}</div>
            )
    }
}