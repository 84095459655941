import React from "react";

function Support() {
  return (
    <div className="student-conatiner py-5 mt-4">
      <h1 className="fs-2 my-4">Support</h1>
      <ul>
        <li className="mb-3">
          <b>Tutorials and How-To Videos:</b>
          <br /> Step-by-step guidance and videos on how to perform specific
          tasks on the phone, such as setting up email, using the navigation
          app, and taking screenshots.
        </li>
        <li className="mb-3">
          <b>Contact Information:</b>
          <br /> Contact details for the manufacturer's customer support team,
          including phone numbers, email addresses, and a web form for
          submitting support tickets.
        </li>
        <li className="mb-3">
          <b>Troubleshooting Tips:</b>
          <br /> Information and guidance on how to diagnose and resolve common
          issues with the phone, such as battery life, freezing or errors.
        </li>
        <li className="mb-3">
          <b>Software and Firmware Updates:</b>
          <br /> Instructions and downloads for updating the phone's software or
          firmware, to fix bugs and improve performance.
        </li>
        <li className="mb-3">
          <b>Community Support:</b>
          <br /> A discussion forum or community space where users can ask
          questions, share knowledge and troubleshoot with other users.
        </li>
        <li className="mb-3">
          <b>Return Policy and Warranty:</b>
          <br /> Information on how to return the phone if it is not working,
          what will be covered under the warranty, how to claim the warranty if
          phone is damaged
        </li>
        <li className="mb-3">
          <b>Accessory Support:</b>
          <br /> Information on compatible accessories such as cases, charging
          docks, and screen protectors.
        </li>
      </ul>
    </div>
  );
}

export default Support;
