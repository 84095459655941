import React, { useEffect, useState } from "react";
import { Container, Modal } from "react-bootstrap";
import swal from "sweetalert";
import { API_CALL } from "../../../../api/ApiServiceCall";
import { API_CONSTANTS, API_METHOD } from "../../../../common/constants";
import "../../../../css/payout.css";
// import { getCredentialLoginAccountProfileDetailAtom } from '../../../atom/getCredentialLoginAccountProfileDetail'
import AccountLists from "./AccountLists";
import AddBankForm from "./AddBankForm";

function Payout() {
  const [accountList, setAccountList] = useState("");
  useEffect(() => {
    getPaymentInfo();
  }, []);

  const getPaymentInfo = async () => {
    const usrdetailforHeader = JSON.parse(
      localStorage.getItem("localUserData")
    );

    const request = {
      apiUrl:
        API_CONSTANTS.BASE_URL +
        API_CONSTANTS.GET_PAYMENT_INFO_BY_EMAIL +
        `/?email=${usrdetailforHeader.email}`,
      method: API_METHOD.GET,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      setAccountList(result.data);
    } else if (result.status > 200) {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className="my-4">
      <AccountLists accountList={accountList} getPaymentInfo={getPaymentInfo} />
      <div className="d-flex justify-content-end">
        <button
          className="text-center border-0 px-5  my-3 fz-10px bg-prime text-white fz-14px py-2 rounded-3"
          onClick={handleShow}
        >
          + Add Payment Info
        </button>
      </div>

      <Container>
        <Modal
          show={show}
          onHide={handleClose}
          centered
          size="lg"
          backdrop="static"
        >
          <div className="mh">
            <Modal.Header closeButton>
              <Modal.Title>
                <h5 className="m-0 p-0 text-prime fz-19px">
                  Add Bank Account Detail
                </h5>
              </Modal.Title>
            </Modal.Header>
          </div>
          <Modal.Body>
            <AddBankForm
              handleClose={handleClose}
              getPaymentInfo={getPaymentInfo}
            />
          </Modal.Body>
        </Modal>
      </Container>
    </div>
  );
}

export default Payout;
