import React from "react";

function AttachIcon() {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 23 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.625 2.21323C19.4731 1.06511 17.9131 0.42041 16.2867 0.42041C14.6604 0.42041 13.1003 1.06511 11.9484 2.21323L1.85236 12.3085C1.44299 12.7113 1.11741 13.1912 0.894397 13.7204C0.671388 14.2496 0.555364 14.8178 0.553015 15.3921C0.550666 15.9664 0.66204 16.5355 0.880713 17.0665C1.09939 17.5975 1.42103 18.08 1.8271 18.4862C2.23316 18.8923 2.71561 19.214 3.24663 19.4327C3.77764 19.6515 4.3467 19.7629 4.921 19.7606C5.49529 19.7584 6.06346 19.6424 6.59272 19.4195C7.12198 19.1965 7.60187 18.871 8.0047 18.4617L17.4703 8.99683C17.945 8.51218 18.2109 7.86079 18.2109 7.18237C18.2109 6.50396 17.945 5.85257 17.4703 5.36792C16.9814 4.90155 16.3316 4.64136 15.6559 4.64136C14.9802 4.64136 14.3304 4.90155 13.8414 5.36792L5.00705 14.2023C4.87223 14.3508 4.79967 14.5454 4.80439 14.7459C4.80912 14.9464 4.89076 15.1374 5.03241 15.2793C5.17407 15.4213 5.36489 15.5033 5.56537 15.5085C5.76585 15.5136 5.96063 15.4415 6.10939 15.307L14.9469 6.47339C15.1377 6.29042 15.3919 6.18826 15.6563 6.18826C15.9206 6.18826 16.1748 6.29042 16.3656 6.47339C16.5508 6.6631 16.6544 6.91768 16.6544 7.18277C16.6544 7.44785 16.5508 7.70243 16.3656 7.89214L6.90001 17.357C6.37704 17.8799 5.66778 18.1736 4.92825 18.1735C4.18873 18.1734 3.47953 17.8796 2.95666 17.3566C2.43379 16.8336 2.14008 16.1244 2.14016 15.3848C2.14023 14.6453 2.43407 13.9361 2.95705 13.4132L13.0531 3.31792C13.9148 2.47999 15.0716 2.01493 16.2735 2.02332C17.4754 2.0317 18.6256 2.51287 19.4755 3.36274C20.3254 4.2126 20.8065 5.36286 20.8149 6.56473C20.8233 7.7666 20.3582 8.92346 19.5203 9.78511L10.0555 19.25C9.91317 19.3973 9.83443 19.5947 9.83621 19.7995C9.83799 20.0043 9.92015 20.2003 10.065 20.3451C10.2098 20.49 10.4058 20.5721 10.6106 20.5739C10.8155 20.5757 11.0128 20.497 11.1602 20.3546L20.625 10.8906C21.7739 9.73896 22.4192 8.17864 22.4192 6.55191C22.4192 4.92517 21.7739 3.36485 20.625 2.21323Z"
        fill="#395061"
      />
    </svg>
  );
}

export default AttachIcon;
