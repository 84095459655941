import React, { useEffect, useState } from "react";
import { MdModeEditOutline } from "react-icons/md";
import { useRecoilState } from "recoil";
import swal from "sweetalert";
import { API_CALL } from "../../../../../../../api/ApiServiceCall";
import {
  API_CONSTANTS,
  API_METHOD,
} from "../../../../../../../common/constants";
import { liveCourseAssessmentViewAtomFunc } from "../../../../../../atom/liveCourseAssessmentViewAtom";
import { liveCourseClickedAssessmentDataAtomFunc } from "../../../../../../atom/liveCourseClickedAssessmentDataAtom";
import { refetchAssessmentTitleListAtomFunc } from "../../../../../../atom/refetchAssessmentTitleListAtom";
import RightArrow from "../../../../../../svg-icons/rightArrow";
import ClickedAssessmentView from "../clicked-assessment-view/clickedAssessmentView";
import EditAssessmentTitle from "./editAssessmentTitleLiveCourse";

function LiveCourseAssessmentList() {
  const [assessmentDataList, setAssessmentListData] = useState([]);
  const [liveCourseAssessmentViewAtom, setLiveCourseAssessmentViewAtom] =
    useRecoilState(liveCourseAssessmentViewAtomFunc);
  const [
    liveCourseClickedAssessmentDataAtom,
    setLiveCourseClickedAssessmentDataAtom,
  ] = useRecoilState(liveCourseClickedAssessmentDataAtomFunc);
  const [refetchAssessmentTitleListAtom, setRefetchAssessmentTitleListAtom] =
    useRecoilState(refetchAssessmentTitleListAtomFunc);

  useEffect(() => {
    getAllLiveCourseAssessmentDetails();
  }, [refetchAssessmentTitleListAtom]);
  const getAllLiveCourseAssessmentDetails = async () => {
    const courseCode = localStorage.getItem("courseCode");
    const request = {
      apiUrl:
        API_CONSTANTS.BASE_URL +
        API_CONSTANTS.GET_ASSESSMENT_BY_LIVE_COURSE_CODE +
        `/?courseCode=${courseCode}`,
      method: API_METHOD.GET,
    };
    let result = await API_CALL(request);

    if (result.status === 200) {
      setAssessmentListData(result.data);
    } else if (result.status > 200) {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }
  };
  const showClickedAssessmentData = (clickedAssessmentData) => {
    setLiveCourseClickedAssessmentDataAtom(clickedAssessmentData);
    setLiveCourseAssessmentViewAtom(<ClickedAssessmentView />);
  };
  // onClick={() => onDelete(assessData)}
  // const onDelete = async (clickedAssessmentDataForDelete) => {
  //     const courseCode = localStorage.getItem("courseCode")

  //     swal({
  //         title: "Are you sure?",
  //         text: "Once deleted, you will not be able to recover",
  //         icon: 'https://cdn-icons-png.flaticon.com/512/6460/6460112.png',
  //         imageClass: 'img-warning',
  //         animation: false,
  //         buttons: true,
  //         dangerMode: true,
  //     }).then(async (willDelete) => {
  //         if (willDelete) {
  //             const requestBodyParam = JSON.stringify({
  //                 'courseCode':courseCode,
  //                 'assessmentCode':clickedAssessmentDataForDelete.assessmentCode
  //             });
  //             // alert(requestBodyParam)
  //             const request = {
  //                 apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.DELETE_LIVE_COURSE_ASSESSMENT_BY_ASSESSMENT_CODE,
  //                 method: API_METHOD.POST,
  //                 requestBodyParams: requestBodyParam,
  //             }
  //             let result = await API_CALL(request)
  //             if (result.status === 200) {
  //                 getAllLiveCourseAssessmentDetails();
  //             } else if (result.status > 200) {
  //                 swal(result.message);
  //             }
  //         }
  //     });
  // }

  const onClickEditTitle = (data) => {
    setLiveCourseClickedAssessmentDataAtom(data);
    setLiveCourseAssessmentViewAtom(<EditAssessmentTitle />);
  };
  return (
    <div>
      {/* <pre>{JSON.stringify(assessmentDataList, null, 3)}</pre> */}

      {assessmentDataList?.assesmentList?.length
        ? assessmentDataList.assesmentList.map((assessData) => (
            <div
              className="chp-list d-flex justify-content-md-between align-items-center cursor-default"
              key={assessData._id}
            >
              <h1
                className="m-0 fz-16px fw-500"
                role="button"
                onClick={() => showClickedAssessmentData(assessData)}
              >
                {assessData.assessmentTitle}
              </h1>
              {assessmentDataList?.courseStatus == "INREVIEW" ? (
                ""
              ) : (
                <div className="d-flex justify-content-end  ms-auto opc-0">
                  <div
                    className="me-3"
                    role="button"
                    type="button"
                    onClick={() => onClickEditTitle(assessData)}
                  >
                    <MdModeEditOutline className="text-prime" />
                  </div>
                  {/* <div className='me-4' role="button" type="button" ><FiTrash2 className='text-danger' /></div> */}
                </div>
              )}
              <div
                role="button"
                onClick={() => showClickedAssessmentData(assessData)}
              >
                <RightArrow size={8} />
              </div>
            </div>
          ))
        : ""}
    </div>
  );
}

export default LiveCourseAssessmentList;
