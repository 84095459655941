import React, { useEffect, useState } from "react";
import { Container, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import swal from "sweetalert";
import { API_CALL } from "../../../../../api/ApiServiceCall";
import {
  API_CONSTANTS,
  API_METHOD,
  STORAGE_KEY,
} from "../../../../../common/constants";
import useCookie from "../../../../../common/cookie_hook";
import { NewEmailState } from "../../../../atom/accountSettingNewEmailValue";

function VerifyChangeEmailOTP({ email, setSelectedModal }) {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(60);
  const [newEmailAtom, setNewEmailAtom] = useRecoilState(NewEmailState);
  const [cookie, updateCookie] = useCookie(STORAGE_KEY.AUTH);

  const navigate = useNavigate();
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });
  const resendOtp = async () => {
    setLoading(true);
    let requestBody = JSON.stringify({
      email: email,
      userType: "INSTRUCTOR",
    });
    const request = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.RESEND_OTP,
      method: API_METHOD.POST,
      requestBodyParams: requestBody,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      setSeconds(60);
    } else if (result.status > 200) {
      setError(result.message);
    }
    setLoading(false);
  };
  const onSubmit = async (data) => {
    setLoading(true);
    const requestBodyParam = JSON.stringify({
      code: data.code,
      oldEmail: email,
      newEmail: newEmailAtom,
    });
    const request = {
      apiUrl:
        API_CONSTANTS.BASE_URL +
        API_CONSTANTS.CHANGE_INSTRUCTOR_EMAIL_VERIFY_CODE,
      method: API_METHOD.POST,
      requestBodyParams: requestBodyParam,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      setLoading(false);
      swal(
        "Email Changed!",
        `Login with your new mail-id ${newEmailAtom}`,
        "success"
      ).then(() => {
        localStorage.clear();
        updateCookie(null);
        navigate("/login");
      });
    } else if (result.status > 200) {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
      setLoading(false);
    }
  };

  return (
    <div>
      {" "}
      <Container>
        <Modal.Header closeButton className="mh">
          <Modal.Title className="mdlt">Verify It’s You</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <p className='fz-16px pb-1 text-black'>We Sent 6 Digit OTP to {accountDetails.email}</p> */}
          <form className="login-col" onSubmit={handleSubmit(onSubmit)}>
            <label htmlFor="otp">Enter 6 Digit OTP Code</label>
            <p className="fz-15px pb-1 text-black">
              We Sent 6 Digit OTP to {newEmailAtom}
            </p>
            <input
              type="number"
              placeholder="Enter 6 Digit OTP Code"
              id="otp"
              {...register("code")}
              className="mb-0"
              required
            />
            <div className="d-flex align-items-center justify-content-end mt-2 mb-3 otp">
              {" "}
              {minutes === 0 && seconds === 0 ? null : (
                <h6 className="m-0">
                  {" "}
                  {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                </h6>
              )}
              <button
                role="button"
                type="button"
                onClick={resendOtp}
                disabled={seconds === 0 ? false : true}
                className="ms-2 mb-0 w-auto border-0 bg-transparent"
              >
                Resend
              </button>
            </div>
            <small className="text-danger mb-2 d-block fz-12px fw-500">
              {" "}
              {error}
            </small>
            <button
              className="login-form-btn"
              type="submit"
              disabled={seconds === 0 ? true : false}
            >
              {loading ? "Please wait..." : "Submit"}
            </button>
          </form>
        </Modal.Body>
      </Container>
    </div>
  );
}

export default VerifyChangeEmailOTP;
