import React, { useState } from "react";
import "react-quill/dist/quill.snow.css";
import { useRecoilState } from "recoil";
import swal from "sweetalert";
import { API_CALL } from "../../../../../api/ApiServiceCall";
import { API_CONSTANTS, API_METHOD } from "../../../../../common/constants";
import { OverviewDataState } from "../../../../atom/overviewData";
import { OverviewTriggerRefetch } from "../../../../atom/overviewRefetchTrigger";
import MyS3Component from "../../../../s3/s3";
export default function VideoEdit({ setIsEdit }) {
  const [overviewData, setOverviewData] = useRecoilState(OverviewDataState);
  const [overviewRefecthTrigger, setOverviewRefecthTrigger] = useRecoilState(
    OverviewTriggerRefetch
  );
  const [loading, setLoading] = useState(false);
  const [courseEditThumbnailImage, setCourseEditThumbnailImage] = useState();
  const [courseEditThumbnailVideo, setCourseEditThumbnailVideo] = useState();
  const [imageFileValidation, setImageFileValidation] = useState("");
  const [videoValidation, setVideoValidation] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    const userCode = JSON.parse(localStorage.getItem("userCode"));
    setLoading(true);
    const formData = new FormData();
    formData.append("courseCode", userCode.data);
    formData.append("thumbnail", courseEditThumbnailImage);
    formData.append("thumbnail", courseEditThumbnailVideo);

    let requestBodyParam = formData;
    const usrdetailforHeader = JSON.parse(
      localStorage.getItem("localUserData")
    );
    let apiHeader = {
      "x-auth-token": usrdetailforHeader?.jwt,
    };
    if (usrdetailforHeader?.data?.user?.email) {
      apiHeader = {
        token: usrdetailforHeader?.data?.user?.email,
        gmailUserType: "INSTRUCTOR",
      };
    }

    const request = {
      apiUrl:
        API_CONSTANTS.BASE_URL + API_CONSTANTS.UPDATE_THUMBNAIL_INTRO_VIDEO,
      method: API_METHOD.POST,
      headers: apiHeader,
      requestBodyParams: requestBodyParam,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      setLoading(false);
      setIsEdit(false);
      setOverviewRefecthTrigger({ ChangeOccur: Math.random() });
    } else if (result.status > 200) {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
      setLoading(false);
    }
  };

  const [source, setSource] = React.useState();
  //for video preview
  const handleFileChange = (e) => {
    let file = e.target.files[0];
    const uniqueId = "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
    let fileExtension = file.name.split(".").pop();
    const uniqueFileName = `${uniqueId}.${fileExtension}`;
    file = new File([file], uniqueFileName, { type: file.type });
    if (file.type === "video/mp4") {
      setVideoValidation(false);
      setCourseEditThumbnailVideo(file);
      const url = URL.createObjectURL(e.target.files[0]);
      setSource(url);
    } else {
      setVideoValidation("Please select the mp4 format");
    }
  };

  const [image, setImage] = useState("");
  //for Image preview

  async function readImage(e) {
    let file = e.target.files[0];
    const uniqueId = "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
    let fileExtension = file.name.split(".").pop();
    const uniqueFileName = `${uniqueId}.${fileExtension}`;
    file = new File([file], uniqueFileName, { type: file.type });

    let fileFormat = fileExtension.toLowerCase();

    if (fileFormat == "png" || fileFormat == "jpeg" || fileFormat == "jpg") {
      setImageFileValidation(false);
      setCourseEditThumbnailImage(file);
      const reader = new FileReader();
      reader.onload = function (e) {
        let binaryData = e.target.result;
        let base64String = window.btoa(binaryData);
        setImage(base64String);
      };
      reader.readAsBinaryString(file);
    } else {
      setImageFileValidation("Please select JPG, PNG, or JPEG format");
    }
  }

  return (
    <>
      <div className="container overview-card">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col">
              <h5>Thumbnail</h5>
              {imageFileValidation ? (
                <small className="text-danger mb-2 d-block fz-12px fw-500">
                  {imageFileValidation}
                </small>
              ) : (
                ""
              )}
              <p className="text-muted fz-12px">
                Width 600 px, Height 350px. Format will be JPG, PNG, JPEG.
              </p>
              {overviewData && image ? (
                ""
              ) : (
                <MyS3Component
                  imageUrl={overviewData?.data[0]?.thumbNailImagePath}
                />
              )}

              {image ? (
                <img
                  src={`data:image/jpeg;base64,${image}`}
                  alt="uploaded"
                  className="img-brd p-0   rounded-3"
                />
              ) : (
                ""
              )}
              <input
                className={image ? "d-none" : "d-none"}
                id="change_image"
                type="file"
                accept="image/gif, image/jpeg, image/png"
                onChange={(event) => {
                  readImage(event);
                }}
              />
              <label
                htmlFor="change_image"
                className="w-100 py-2 text-center text-white fz-13px bg-secondary rounded-2"
                role="button"
              >
                Change Image
              </label>
            </div>
            <div className="col">
              <h5>Intro Video2</h5>
              {videoValidation ? (
                <small className="text-danger mb-2 d-block fz-12px fw-500">
                  {videoValidation}
                </small>
              ) : (
                ""
              )}
              <p className="text-muted fz-12px">
                Width 600 px, Height 350px. Format will be mp4.
              </p>
              {overviewData && source ? (
                ""
              ) : (
                <div
                  className="mb-2"
                  style={{
                    width: "100%",
                    height: "300px",
                    overflow: "hidden",
                  }}
                >
                  <MyS3Component
                    imageUrl={overviewData?.data[0]?.introVideoPath}
                  />
                </div>
              )}
              {source ? (
                <div
                  style={{
                    width: "100 %",
                    height: "300px",
                    overflow: "hidden",
                  }}
                >
                  <video src={source} className="video-brd m-0" />
                </div>
              ) : (
                ""
              )}
              <input
                className={source ? "d-none" : "d-none"}
                id="change_video"
                type="file"
                onChange={handleFileChange}
                accept=".mp4"
              />
              <label
                htmlFor="change_video"
                className="w-100 py-2 text-center text-white fz-13px bg-secondary rounded-2"
                role="button"
              >
                Change intro Video
              </label>
            </div>
          </div>
          <div className="d-flex justify-content-end pt-3">
            <div
              className="border-0 bg-secondary text-white fz-14px py-2 w-14 rounded-3 text-center"
              type="button"
              role="button"
              onClick={() => setIsEdit(false)}
            >
              Cancel
            </div>
            <button
              className="ms-lg-3 border-0 bg-prime text-white fz-14px py-2 w-14 rounded-3 text-center"
              type="submit"
              disabled={loading || (!courseEditThumbnailImage && !courseEditThumbnailVideo)}
            >
              {loading ? (
                <>
                  <span
                    className="spinner-border text-white spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>{" "}
                  Uploading...
                </>
              ) : (
                "Done"
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
