import React, { useEffect, useState } from "react";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import swal from "sweetalert";
import { API_CALL } from "../../../../api/ApiServiceCall";
import { API_CONSTANTS, API_METHOD } from "../../../../common/constants";
import { isSingleLiveCourseEditAtomFunc } from "../../../atom/isSingleLiveCourseEditAtom";
import { singleLiveCourseDataAtomFunc } from "../../../atom/singleLiveCourseDataAtom";
import Step1LiveCourseEdit from "./edit-live-course-overview/step1LiveCourseEdit";
import Step2LiveCourseEdit from "./edit-live-course-overview/step2LiveCourseEdit";
import Step3LiveCourseEdit from "./edit-live-course-overview/step3LiveCourseEdit";
import LiveCourseTab from "./live-course-assessment/liveCourseTab";
import LiveCourseStep1OverviewData from "./live-course-overview-data/liveCourseStep1OverviewData";
import LiveCourseStep2OverviewData from "./live-course-overview-data/liveCourseStep2OverviewData";
import LiveCourseStep3OverviewData from "./live-course-overview-data/liveCourseStep3OverviewData";
import CreateLiveCourseFaq from "./liveCourseFaq/createLiveCourseFaq";
function OverviewLiveCourse() {
  const [singleLiveCourseDataAtom, setSingleLiveCourseDataAtom] =
    useRecoilState(singleLiveCourseDataAtomFunc);
  const [isSingleLiveCourseEditAtom, setIsSingleLiveCourseEditAtom] =
    useRecoilState(isSingleLiveCourseEditAtomFunc);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    getCurrentLiveCourseData();
  }, [isSingleLiveCourseEditAtom.refetchOnChange]);

  const getCurrentLiveCourseData = async () => {
    const courseCode = localStorage.getItem("courseCode");
    const request = {
      apiUrl:
        API_CONSTANTS.BASE_URL +
        API_CONSTANTS.GET_LIVE_COURSE_BY_COURSE_CODE +
        `/?courseCode=${courseCode}`,
      method: API_METHOD.GET,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      setSingleLiveCourseDataAtom(result.data[0]);
    } else if (result.status > 200) {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }
  };
  const reviewLiveCourse = async () => {
    swal({
      title: "Are you sure?",
      imageClass: "img-warning",
      buttons: true,
      buttons: ["Cancel", "Yes"],
      closeOnClickOutside: false,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        setLoading(true);
        const courseCode = localStorage.getItem("courseCode");
        const requestBodyParam = JSON.stringify({
          courseCode: courseCode,
        });
        const request = {
          apiUrl:
            API_CONSTANTS.BASE_URL +
            API_CONSTANTS.SUBMIT_FOR_REVIEW_LIVE_COURSE,
          method: API_METHOD.POST,
          requestBodyParams: requestBodyParam,
        };
        let result = await API_CALL(request);
        if (result.status === 200) {
          swal("", "Successfully Submit For Review", "success").then(() => {
            setLoading(false);
            navigate("/courses/live");
          });
        } else if (result.status > 200) {
          swal({
            title: "ERROR",
            text: result.message,
            icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
            className: "error-window",
            button: true,
          });
        }
      }
    });
  };
  let isBlock = true;
  if (singleLiveCourseDataAtom) {
    if (singleLiveCourseDataAtom.courseStatus === "INREVIEW") {
      isBlock = true;
    } else {
      isBlock = false;
    }
  }

  return (
    <div className="container overview mt-5">
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <Row>
          <Col sm={3}>
            <Nav variant="pills" className="flex-column overview-card">
              <Nav.Item>
                <Nav.Link eventKey="first">Overview</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="second">Pricing {"&"} Intro</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="third">Course Duration</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="fourth">Live</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="fifth">FAQ</Nav.Link>
              </Nav.Item>

              <button
                className="  border-0 bg-prime text-white fz-14px py-2 rounded-3"
                disabled={isBlock ? true : false}
                onClick={reviewLiveCourse}
              >
                {loading ? "Loading..." : "Submit For Review"}
              </button>
            </Nav>
          </Col>
          <Col sm={9}>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <div className="container overview-card">
                  {isSingleLiveCourseEditAtom.edit ? (
                    <Step1LiveCourseEdit />
                  ) : (
                    <LiveCourseStep1OverviewData />
                  )}
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <div className="container overview-card">
                  {isSingleLiveCourseEditAtom.edit ? (
                    <Step2LiveCourseEdit />
                  ) : (
                    <LiveCourseStep2OverviewData />
                  )}
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="third">
                <div className="container overview-card">
                  {isSingleLiveCourseEditAtom.edit ? (
                    <Step3LiveCourseEdit />
                  ) : (
                    <LiveCourseStep3OverviewData />
                  )}
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="fourth">
                <LiveCourseTab />
              </Tab.Pane>
              <Tab.Pane eventKey="fifth">
                <CreateLiveCourseFaq />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
      {/* <pre className='container col-7'>
        {JSON.stringify(singleLiveCourseDataAtom, null, 4)}
      </pre> */}
      <div className="py-5"></div>
    </div>
  );
}

export default OverviewLiveCourse;
