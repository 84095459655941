import { useRecoilState } from "recoil";
import { extraFilterState } from "./../atoms/search-atom";

function ExtraFilterMenu() {
  const [extraFilter, setExtraFilter] = useRecoilState(extraFilterState);

  return (
    <div className="py-5">
      <button
        onClick={() => setExtraFilter({ key: "popularCourse", value: true })}
        className="btn px-4 py-2 me-4"
        style={{
          backgroundColor:
            extraFilter.key === "popularCourse" ? "lightgray" : "#fff",
          color: extraFilter.key === "popularCourse" ? "#395061" : "gray",
        }}
      >
        Popular
      </button>
      <button
        onClick={() => setExtraFilter({ key: "newCourse", value: true })}
        className="btn px-4 py-2"
        style={{
          backgroundColor:
            extraFilter.key === "newCourse" ? "lightgray" : "#fff",
          color: extraFilter.key === "newCourse" ? "#395061" : "gray",
        }}
      >
        New
      </button>
    </div>
  );
}

export default ExtraFilterMenu;
