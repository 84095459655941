import React from 'react'

function MicIcon() {
  return (
   
   <svg width="22"  fill="#395061" height="20" viewBox="0 0 22 30" xmlns="http://www.w3.org/2000/svg">
   <path fillRule="evenodd" clipRule="evenodd" d="M6.27047 26.7646H15.6452C16.2924 26.7646 16.817 27.2914 16.817 27.9411C16.817 28.5445 16.3646 29.0417 15.7818 29.1097L15.6452 29.1176H6.27047C5.62328 29.1176 5.09863 28.5909 5.09863 27.9411C5.09863 27.3378 5.55101 26.8405 6.13381 26.7726L6.27047 26.7646H15.6452H6.27047ZM20.4511 13.357C21.098 13.3761 21.6071 13.918 21.5881 14.5675C21.5388 16.2526 21.002 18.0381 20.0914 19.6216C17.179 24.6859 10.7289 26.421 5.68452 23.4971C2.45782 21.6268 0.495729 18.2302 0.41242 14.5598C0.397676 13.9102 0.910238 13.3716 1.55726 13.3568C2.20428 13.342 2.74074 13.8566 2.75549 14.5062C2.82034 17.3636 4.34569 20.0042 6.85636 21.4594C10.7797 23.7335 15.7965 22.384 18.0617 18.4451C18.7845 17.1882 19.2079 15.7798 19.2454 14.4984C19.2644 13.849 19.8042 13.3379 20.4511 13.357ZM10.9578 0.882324C14.1938 0.882324 16.817 3.51594 16.817 6.76468V14.4117C16.817 17.6605 14.1938 20.2941 10.9578 20.2941C7.72188 20.2941 5.09863 17.6605 5.09863 14.4117V6.76468C5.09863 3.51594 7.72188 0.882324 10.9578 0.882324Z" />
</svg>
  )
}

export default MicIcon