import React from 'react'

function AppGverify() {
  return (
    <div>google-site-verification: google3a1db498c3a60f17.html</div>
  )
}

export default AppGverify

