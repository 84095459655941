import moment from "moment";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import swal from "sweetalert";
import { API_CALL } from "../../../api/ApiServiceCall";
import { API_CONSTANTS, API_METHOD } from "../../../common/constants";
import messagesStyles from "../../../css/messages.module.css";
import ChatWindow from "../chat/ChatWindow";
// import { useLocation } from 'react-router-dom'
import { useEffect } from "react";

const sListStyle = { height: "50px", width: "50px", objectFit: "cover" };

function MessagesMain() {
  const [isOpenChat, setIsOpenChat] = useState(false);
  const [studentList, setStudentList] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [localUserData, setLocalUserData] = useState(
    JSON.parse(localStorage.getItem("localUserData"))
  );
  const [studentProfile, setStudentProfile] = useState("");
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");

  // const location = useLocation();
  // const query = new URLSearchParams(location.search);
  // const id = query.get('id');

  const getAllProfileData = async (email) => {
    const request = {
      apiUrl:
        API_CONSTANTS.BASE_URL +
        API_CONSTANTS.GET_STUDENT_BY_EMAIL +
        `?email=${email}`,
      // apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS?.GET_STUDENT_BY_EMAIL(email),
      method: API_METHOD.GET,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      setStudentProfile(result?.data);
    }
  };
  const maxString = (str, maxLength) => {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + "...";
    }
    return str;
  };
  useEffect(() => {
    getAllProfileData(localUserData?.email);
  }, [localUserData?.userId]);

  useEffect(() => {
    getAllStudentList();
  }, []);

  const getAllStudentList = async () => {
    let user = JSON.parse(localStorage.getItem("localUserData"));
    const request = {
      apiUrl:
        API_CONSTANTS.BASE_URL +
        API_CONSTANTS.GET_ALL_STUDENT +
        `?userType=${"STUDENT"}&userId=${user.userId}`,
      method: API_METHOD.GET,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      const sortedList = result?.data?.sort((a, b) => {
        const aLastActive = moment(a.lastActive).isValid()
          ? moment(a.lastActive)
          : moment();
        const bLastActive = moment(b.lastActive).isValid()
          ? moment(b.lastActive)
          : moment();

        if (!moment(a.lastActive).isValid()) return 1;
        if (!moment(b.lastActive).isValid()) return -1;

        return bLastActive - aLastActive;
      });
      setStudentList(sortedList);
      //setStudentList(result.data
      if (id) {
        const data = sortedList.filter((s) => s.id === id);
        setIsOpenChat(
          <ChatWindow
            clickedStudentData={data[0]}
            updateStudentListAndSort={updateStudentListAndSort}
          />
        );
      }
    } else if (result.status > 200) {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }
  };
  const updateStudentListAndSort = () => {
    getAllStudentList();
  };
  const imageNotFound = (imgId) => {
    const updatedList = studentList.map((data) => {
      if (data.id === imgId) {
        data.profileImgPath =
          "https://cdn-icons-png.flaticon.com/512/149/149071.png";
      }
      return data;
    });
    setStudentList(updatedList);
  };

  const onUserClick = (clickedData) => {
    setIsOpenChat(
      <ChatWindow
        clickedStudentData={clickedData}
        studentProfile={studentProfile}
        updateStudentListAndSort={updateStudentListAndSort}
      />
    );
  };

  return (
    <div
      className="px-4 mx-3"
      style={{ marginLeft: "auto", marginRight: "auto" }}
    >
      <div className="student-conatiner">
        <div className="py-3"></div>
        <div className="row">
          <div className=" "></div>
          <div
            className={`${messagesStyles.bxSd} ${messagesStyles.csScroller} rounded-1 py-4 col-4 bg-white  `}
          >
            <div className="srch-bg ">
              <input
                className="srch-ui"
                type="search"
                placeholder="Search by name..."
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <div className="py-3"></div>
            <ul className={`${messagesStyles.height} list-unstyled `}>
              {studentList?.filter(
                (obj) =>
                  obj?.fullName
                    ?.toLowerCase()
                    ?.indexOf(searchQuery?.toLowerCase()) !== -1
              )?.length
                ? studentList
                    ?.filter(
                      (obj) =>
                        obj?.fullName
                          ?.toLowerCase()
                          ?.indexOf(searchQuery?.toLowerCase()) !== -1
                    )
                    ?.map((sData, index, array) => (
                      <li
                        className={
                          moment(sData?.lastActive).fromNow() == "Invalid date"
                            ? "d-none"
                            : ""
                        }
                        onClick={() => onUserClick(sData)}
                        role="button"
                        key={sData?.id}
                      >
                        <div className="row g-0">
                          <div className="col-2">
                            {sData?.profileImgPath ? (
                              <img
                                src={sData?.profileImgPath}
                                alt={"Profile Image"}
                                className=" rounded-circle"
                                style={sListStyle}
                                onError={() => imageNotFound(sData.id)}
                              />
                            ) : (
                              <img
                                src={
                                  "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                                }
                                alt={"Profile Image"}
                                style={sListStyle}
                                className=" rounded-circle"
                              />
                            )}
                          </div>
                          <div className="col my-auto mx-2">
                            <h6>{sData?.fullName}</h6>
                            <p
                              style={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {maxString(sData?.message, 20)}{" "}
                            </p>
                            {/* <h6>test@yahoo.com</h6> */}
                          </div>
                          <div className={`col-2 my-auto `}>
                            <h6 className="m-0 text-muted fz-13px">
                              {moment(sData?.lastActive).fromNow() ==
                              "Invalid date"
                                ? ""
                                : moment(sData?.lastActive).fromNow()}
                            </h6>
                          </div>
                        </div>
                        <hr></hr>
                      </li>
                    ))
                : studentList && <p>No Instructor Found!</p>}
            </ul>
          </div>
          <div className="col  ">{isOpenChat ? isOpenChat : ""}</div>
        </div>
        <div className="py-4"></div>
      </div>
    </div>
  );
}

export default MessagesMain;
