import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import swal from "sweetalert";
import { API_CALL } from "./../../api/ApiServiceCall";
import { API_CONSTANTS, API_METHOD } from "./../../common/constants";
import ProgressCourseCard from "./ProgressCourseCard";

function InprogressCoursesList({ currentItems }) {
  return (
    <>
      {currentItems?.length > 0 ? (
        currentItems?.map((course, id) => {
          return (
            <ProgressCourseCard
              key={course._id}
              course={course}
              currentIteme={currentItems}
              value={id}
            />
          );
        })
      ) : (
        <p>No Courses.</p>
      )}
    </>
  );
}

function CoursesInProgress() {
  const [courses, setCourses] = useState({
    loading: true,
    error: null,
    data: [],
  });

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [itemOffset, setItemOffset] = useState(0);
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(null);

  const getCourses = async () => {
    setCourses({ loading: true, data: [], error: null });

    const request = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.COURSES_IN_PROGRESS,
      method: API_METHOD.GET,
    };

    let result = await API_CALL(request);

    if (result.status === 200) {
      const progressCourses = result?.data?.filter(
        (course) => course.courseCode
      );
      setCourses({
        loading: false,
        data: progressCourses ? progressCourses : [],
        error: null,
      });
    } else if (result.status > 200) {
      setCourses({ loading: false, data: [], error: result.message });
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }
  };

  useEffect(() => {
    getCourses();
  }, []);

  useEffect(() => {
    if (courses?.data?.length) {
      const endOffset = itemOffset + Number(itemsPerPage);
      let sliceItem = courses?.data?.slice(itemOffset, endOffset);
      setCurrentItems(sliceItem);
      let pageCountResult = Math.ceil(courses?.data?.length / itemsPerPage);
      setPageCount(pageCountResult);
    }
  }, [courses?.data?.length, itemOffset, itemsPerPage]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % courses?.data?.length;
    setItemOffset(newOffset);
  };

  return (
    <div>
      {courses.loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "300px" }}
        >
          <div class="spinner-border" role="status">
            <span class="sr-only"></span>
          </div>
        </div>
      ) : currentItems ? (
        <InprogressCoursesList currentItems={currentItems} />
      ) : (
        <p>No Courses.</p>
      )}

      {courses.data?.length ? (
        <div className="mt-8 d-flex justify-content-between align-items-center">
          <ReactPaginate
            breakLabel="..."
            nextLabel="Next ->"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="<- Previous"
            renderOnZeroPageCount={null}
            containerClassName="pagination d-inline-flex prevent-text-select"
            activeLinkClassName="pagination-activeLink prevent-text-select"
            previousLinkClassName="pagination-container pagination-previous-btn prevent-text-select"
            pageLinkClassName="pagination-container prevent-text-select"
            nextLinkClassName="pagination-container pagination-next-btn prevent-text-select"
            disabledClassName="pagination_link_disabled"
          />
          <div className="col-lg-4 fz-13px fw-500 text-muted text-end">
            Show{" "}
            <select
              className="px-2 py-1 "
              onChange={(e) => {
                setItemsPerPage(e.target.value);
              }}
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={75}>75</option>
              <option value={100}>100</option>
            </select>{" "}
            courses per page
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default CoursesInProgress;
