import React from "react";
import { useRecoilState } from "recoil";
import { isSingleLiveCourseEditAtomFunc } from "../../../../atom/isSingleLiveCourseEditAtom";
import { singleLiveCourseDataAtomFunc } from "../../../../atom/singleLiveCourseDataAtom";
import EditIcon from "../../../../svg-icons/editIcon";
import MyS3Component from "../../../../s3/s3";

function LiveCourseStep2OverviewData() {
  const [singleLiveCourseDataAtom, setSingleLiveCourseDataAtom] =
    useRecoilState(singleLiveCourseDataAtomFunc);
  const [isSingleLiveCourseEditAtom, setIsSingleLiveCourseEditAtom] =
    useRecoilState(isSingleLiveCourseEditAtomFunc);
  let isBlock = true;
  if (singleLiveCourseDataAtom) {
    if (singleLiveCourseDataAtom?.courseStatus == "INREVIEW") {
      isBlock = true;
    } else {
      isBlock = false;
    }
  }
  console.log("zameer", singleLiveCourseDataAtom.thumbNailImagePath);
  return (
    <>
      {isBlock ? (
        ""
      ) : (
        <div
          className="d-flex justify-content-end mt-2"
          role="button"
          onClick={() =>
            setIsSingleLiveCourseEditAtom({
              ...isSingleLiveCourseEditAtom,
              edit: true,
            })
          }
        >
          <EditIcon />
          &nbsp;Edit
        </div>
      )}
      <div className="p-3">
        <div className="row m-0">
          <div className="col-lg-6">
            <p className="text-muted fz-16px fw-500">Currency</p>
            <h6 className="text-dark">{singleLiveCourseDataAtom.currency}</h6>
          </div>
          <div className="col-lg-6">
            <p className="text-muted fz-16px fw-500">Fee</p>
            <h6 className="text-dark">
              {singleLiveCourseDataAtom?.fee || "Loading..."}
            </h6>
          </div>
          <div className="py-3"></div>
          <div className="col-lg-6">
            <p className="text-muted fz-16px fw-500">Thumbnail</p>
            {/* <img
              src={singleLiveCourseDataAtom?.thumbNailImagePath}
              alt="..."
              className="img-brd p-0"
            /> */}
            <MyS3Component
              imageUrl={singleLiveCourseDataAtom.thumbNailImagePath}
            />
          </div>
          <div className="col-lg-6">
            <p className="text-muted fz-16px fw-500">Intro Video</p>
            <div style={{ height: "300px", overflow: "hidden" }}>
              {/* <video
                className="video-brd m-0"
                controls
                autoPlay={false}
                src={singleLiveCourseDataAtom.introVideoPath}
              /> */}
              <MyS3Component
                imageUrl={singleLiveCourseDataAtom.introVideoPath}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LiveCourseStep2OverviewData;
