import React, { useEffect, useState } from "react";
import { BiCircle } from "react-icons/bi";
import { FiTrash2 } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { API_CALL } from "../../../../api/ApiServiceCall";
import { API_CONSTANTS, API_METHOD } from "../../../../common/constants";

function InnerIndependentAssessmentCreation() {
  const navigate = useNavigate();
  const [assessmentStatus, setAssessmentStatus] = useState(true);

  useEffect(() => {
    getAssessmentDetail();
  }, []);

  const getAssessmentDetail = async () => {
    const assessmentCode = localStorage.getItem("independentAssessmentCode");
    const request = {
      apiUrl:
        API_CONSTANTS.BASE_URL +
        API_CONSTANTS.GET_ASSESSMENT_BY_ASSESSMENT_CODE +
        `/?assessmentCode=${assessmentCode}`,
      method: API_METHOD.GET,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      if (result.data.assessmentDetails.length) {
        const formData = result.data.assessmentDetails;
        setAssessmentStatus(result?.data?.assessmentStatus);
        const formattedData = formData.map((inputData) => {
          let formObject = {
            question: inputData.assessmentQuestion,
            point: inputData.point,
            answer: inputData.assessmentAnswer,
            assessmentOrder: inputData.assessmentOrder,
            assessmentQuestionStatus: inputData?.assessmentQuestionStatus,
            _id: inputData._id,
          };
          inputData.assessmentChoice.map((value, index) => {
            let indx = index + 1;
            formObject["option" + indx] = value;
          });

          return formObject;
        });

        setInputList(formattedData);
      }
    } else if (result.status > 200) {
      swal("Error", result);
    }
  };

  const [inputList, setInputList] = useState([]);
  const [loading, setLoading] = useState(false);

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    if (name == "point") {
      //specific field check
      if (e.target.value.length > e.target.getAttribute("maxLength")) {
        e.target.value = e.target.value.slice(
          0,
          e.target.getAttribute("maxLength")
        );
      } else if (e.target.value == "0") {
        // const list = [...inputList];
        // list[index][name] = "1";
        // setInputList(list);
        swal({
          text: "The point should be greater than 1 and less than 10",
          icon: "warning",
        });
      } else {
        //set valid value
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
      }
    } else {
      //set valid value
      const list = [...inputList];
      list[index][name] = value;
      setInputList(list);
    }
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    swal({
      title: "Are you sure?",
      text: "To recreate this field after it's been deleted, you'll need to create it again",
      icon: "https://cdn-icons-png.flaticon.com/512/6460/6460112.png",
      imageClass: "img-warning",
      animation: false,
      className: "swal-center",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        if (inputList[index]?._id) {
          const assessmentCode = localStorage.getItem(
            "independentAssessmentCode"
          );
          const requestBodyParam = JSON.stringify({
            assessmentCode: assessmentCode,
            questionId: inputList[index]._id,
          });
          const request = {
            apiUrl:
              API_CONSTANTS.BASE_URL +
              API_CONSTANTS.DELETE_IND_ASSESSMENT_BY_ID,
            method: API_METHOD.POST,
            requestBodyParams: requestBodyParam,
          };
          let result = await API_CALL(request);
          if (result.status === 200) {
            getAssessmentDetail();
            const list = [...inputList];
            list.splice(index, 1);
            setInputList(list);
          } else if (result.status > 200) {
            swal({
              title: "ERROR",
              text: result.message,
              icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
              className: "error-window",
              button: true,
            });
          }
        } else {
          const list = [...inputList];
          list.splice(index, 1);
          setInputList(list);
        }
      }
    });
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([
      ...inputList,
      {
        question: "",
        point: "1",
        option1: "",
        option2: "",
        option3: "",
        option4: "",
        answer: "",
      },
    ]);
  };

  const handleSubmitForAssessment = async (e) => {
    e.preventDefault();

    const assessmentCode = localStorage.getItem("independentAssessmentCode");
    setLoading(true);
    const assessmentDetails = inputList.map((items) => {
      return {
        assessmentQuestion: items.question,
        assessmentQuestionStatus: items.assessmentQuestionStatus,
        point: items.point,
        assessmentChoice: [
          items.option1,
          items.option2,
          items.option3,
          items.option4,
        ],
        assessmentAnswer: items.answer,
      };
    });
    const requestBodyParam = JSON.stringify({
      assessmentCode: assessmentCode,
      assessmentDetails: assessmentDetails,
    });

    const request = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.CREATE_UPDATE_ASSESSMENT,
      method: API_METHOD.POST,
      requestBodyParams: requestBodyParam,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      getAssessmentDetail();
      setLoading(false);
      swal({
        title: "Assessment Saved Successfully!",
        icon: "success",
        button: "ok",
        className: "swal-ql-theme-btn",
      });

      navigate("/courses/live/assessment");
    } else if (result.status > 200) {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
      setLoading(false);
    }
  };
  useEffect(() => {
    var e = document.querySelectorAll("option");
    e.forEach((x) => {
      if (x.textContent.length > 80)
        x.textContent = x.textContent.substring(0, 80) + "...";
    });
  });
  const checkForDuplicateQuestion = (e, index) => {
    const prevList = [...inputList];
    prevList.splice(index, 1);
    const checkList = prevList.some((item) => {
      return (
        item?.question.trim().toLocaleLowerCase() ==
        e.target.value.trim().toLocaleLowerCase()
      );
    });

    if (checkList) {
      swal({
        text: "This question was already exists!",
        icon: "warning",
      });
      const list = [...inputList];
      list[index]["question"] = "";
      setInputList(list);
    }
  };
  const checkForDuplicateOptions = (e, index) => {
    if (e.target.value) {
      const prevList = [...inputList];
      const rawObj = prevList[index];
      const keysOnArry = Object.keys(rawObj);
      // console.log(keysOnArry);
      let isDup = false;
      const currentValue = e.target.value.trim().toLocaleLowerCase();
      let option1 = rawObj.option1.trim().toLocaleLowerCase();
      let option2 = rawObj.option2.trim().toLocaleLowerCase();
      let option3 = rawObj.option3.trim().toLocaleLowerCase();
      let option4 = rawObj.option4.trim().toLocaleLowerCase();

      if (rawObj._id) {
        if (keysOnArry[6] == e.target.name) {
          // console.log("option1 Block")
          const isCurrentOptionSame =
            currentValue == option2 ||
            currentValue == option3 ||
            currentValue == option4;
          isDup = isCurrentOptionSame;
        }
        if (keysOnArry[7] == e.target.name) {
          // console.log("option2 Block")
          const isCurrentOptionSame =
            currentValue == option1 ||
            currentValue == option3 ||
            currentValue == option4;
          isDup = isCurrentOptionSame;
        }
        if (keysOnArry[8] == e.target.name) {
          // console.log("option3 Block")
          const isCurrentOptionSame =
            currentValue == option1 ||
            currentValue == option2 ||
            currentValue == option4;
          isDup = isCurrentOptionSame;
        }
        if (keysOnArry[9] == e.target.name) {
          // console.log("option4 Block");
          const isCurrentOptionSame =
            currentValue == option1 ||
            currentValue == option2 ||
            currentValue == option3;
          isDup = isCurrentOptionSame;
        }
      } else {
        if (keysOnArry[2] == e.target.name) {
          // console.log("option1 Block")
          const isCurrentOptionSame =
            currentValue == option2 ||
            currentValue == option3 ||
            currentValue == option4;
          isDup = isCurrentOptionSame;
        }
        if (keysOnArry[3] == e.target.name) {
          // console.log("option2 Block")
          const isCurrentOptionSame =
            currentValue == option1 ||
            currentValue == option3 ||
            currentValue == option4;
          isDup = isCurrentOptionSame;
        }
        if (keysOnArry[4] == e.target.name) {
          // console.log("option3 Block")
          const isCurrentOptionSame =
            currentValue == option1 ||
            currentValue == option2 ||
            currentValue == option4;
          isDup = isCurrentOptionSame;
        }
        if (keysOnArry[5] == e.target.name) {
          // console.log("option4 Block");
          const isCurrentOptionSame =
            currentValue == option1 ||
            currentValue == option2 ||
            currentValue == option3;
          isDup = isCurrentOptionSame;
        }
      }

      if (isDup) {
        swal({
          text: "This option was already exists for this question!",
          icon: "warning",
        });
        prevList[index][e.target.name] = "";
        setInputList(prevList);
      }
    }
  };
  const review = async () => {
    swal({
      title: "Are you sure?",
      imageClass: "img-warning",
      // icon: "https://t4.ftcdn.net/jpg/01/57/24/19/240_F_157241916_H518BbbcbFavVTRCGjQsYOWGKrc4RGnZ.jpg",
      buttons: true,
      buttons: ["Cancel", "Yes"],
      closeOnClickOutside: false,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        const assessCode = localStorage.getItem("independentAssessmentCode");
        const requestBodyParam = JSON.stringify({
          assessmentCode: assessCode,
        });
        const request = {
          apiUrl:
            API_CONSTANTS.BASE_URL +
            API_CONSTANTS.SUBMIT_IND_ASSESSMENT_FOR_REVIEW,
          method: API_METHOD.POST,
          requestBodyParams: requestBodyParam,
        };
        let result = await API_CALL(request);
        if (result.status === 200) {
          swal("", "Successfully Submit For Review", "success").then(() => {
            setLoading(false);
            navigate("/courses/live/assessment");
          });
        } else if (result.status > 200) {
          swal({
            title: "ERROR",
            text: result.message,
            icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
            className: "error-window",
            button: true,
          });
        }
      }
    });
  };
  const statusCodes = ["INREVIEW"];

  return (
    <div>
      <>
        {/* <pre>{JSON.stringify(inputList, null, 4)}</pre> */}
        <div className="p-2">
          <div className="d-flex justify-content-end py-4 align-items-center">
            {inputList[0]?._id ? (
              <button
                className="border-0 bg-prime text-white fz-14px py-2 px-5 rounded-3 text-center me-auto"
                type="button"
                onClick={review}
                disabled={statusCodes.includes(assessmentStatus) ? true : false}
              >
                Submit for review
              </button>
            ) : (
              ""
            )}
            {/* {statusCodes.includes(assessmentStatus) ? "" : <p className={inputList[0]?._id ? "fz-14px text-muted fw-400 my-auto" : "fz-14px text-muted fw-400 me-auto"}>Note: Please add the questions and answers to the assessment</p>} */}
            {/* <p className='fz-14px text-muted fw-400'> {"<"} Back</p> */}
          </div>

          {inputList.length ? (
            ""
          ) : (
            <p className={`text-center pt-3 text-muted fw-500 `}>
              No questions and answers created
            </p>
          )}

          <form id="Independentassessment" onSubmit={handleSubmitForAssessment}>
            {inputList.map((x, i) => {
              return (
                <div key={"assesment" + i}>
                  <div className="d-flex justify-content-between ">
                    <textarea
                      disabled={
                        statusCodes.includes(assessmentStatus) ? true : false
                      }
                      rows={"1"}
                      type="text"
                      className="question-input"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") e.preventDefault();
                      }}
                      onBlur={(e) => checkForDuplicateQuestion(e, i)}
                      name="question"
                      onChange={(e) => handleInputChange(e, i)}
                      value={x.question}
                      placeholder="Enter Question here*"
                      required
                    />

                    <div className="d-flex justify-content-end align-items-center">
                      Point* &nbsp;
                      <input
                        disabled={
                          statusCodes.includes(assessmentStatus) ? true : false
                        }
                        type="number"
                        maxLength={1}
                        name="point"
                        onChange={(e) => handleInputChange(e, i)}
                        value={x.point}
                        className="point-input"
                        required
                      />
                      <>
                        {statusCodes.includes(assessmentStatus) ? (
                          ""
                        ) : (
                          <div onClick={() => handleRemoveClick(i)}>
                            <FiTrash2 className="trsh-assesment" />
                          </div>
                        )}
                      </>
                    </div>
                  </div>
                  <div className="assesment-option">
                    <div className="d-flex">
                      <label className="pt-2">
                        <BiCircle />
                      </label>{" "}
                      <textarea
                        disabled={
                          statusCodes.includes(assessmentStatus) ? true : false
                        }
                        onBlur={(e) => checkForDuplicateOptions(e, i)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") e.preventDefault();
                        }}
                        className="w-50 border-0 mx-2 p-2"
                        rows={"2"}
                        type="text"
                        placeholder="Option1*"
                        name="option1"
                        onChange={(e) => handleInputChange(e, i)}
                        value={x.option1}
                        required
                      />
                    </div>
                    <div>
                      <label>
                        <BiCircle />
                      </label>{" "}
                      <textarea
                        disabled={
                          statusCodes.includes(assessmentStatus) ? true : false
                        }
                        onBlur={(e) => checkForDuplicateOptions(e, i)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") e.preventDefault();
                        }}
                        className="w-50 border-0 mx-2 p-2"
                        rows={"2"}
                        type="text"
                        name="option2"
                        placeholder="Option2*"
                        onChange={(e) => handleInputChange(e, i)}
                        value={x.option2}
                        required
                      />
                    </div>
                    <div>
                      <label>
                        <BiCircle />
                      </label>{" "}
                      <textarea
                        disabled={
                          statusCodes.includes(assessmentStatus) ? true : false
                        }
                        onBlur={(e) => checkForDuplicateOptions(e, i)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") e.preventDefault();
                        }}
                        className="w-50 border-0 mx-2 p-2"
                        rows={"2"}
                        type="text"
                        name="option3"
                        placeholder="Option3*"
                        onChange={(e) => handleInputChange(e, i)}
                        value={x.option3}
                        required
                      />
                    </div>
                    <div>
                      <label>
                        <BiCircle />
                      </label>{" "}
                      <textarea
                        disabled={
                          statusCodes.includes(assessmentStatus) ? true : false
                        }
                        onBlur={(e) => checkForDuplicateOptions(e, i)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") e.preventDefault();
                        }}
                        className="w-50 border-0 mx-2 p-2"
                        rows={"2"}
                        type="text"
                        name="option4"
                        placeholder="Option4*"
                        onChange={(e) => handleInputChange(e, i)}
                        value={x.option4}
                        required
                      />
                    </div>
                  </div>

                  <div className="tag-ajus w-50 rounded-2">
                    <select
                      disabled={
                        statusCodes.includes(assessmentStatus) ? true : false
                      }
                      name="answer"
                      onChange={(e) => handleInputChange(e, i)}
                      value={
                        x.answer === x.option1 ||
                        x.answer === x.option2 ||
                        x.answer === x.option3 ||
                        x.answer === x.option4
                          ? x.answer
                          : "Please Select Answer*"
                      }
                      required
                    >
                      <option value="">Please Select Answer*</option>
                      <option value={x.option1} title={x.option1}>
                        {x.option1}
                      </option>
                      <option value={x.option2} title={x.option2}>
                        {x.option2}
                      </option>
                      <option value={x.option3} title={x.option3}>
                        {x.option3}
                      </option>
                      <option value={x.option4} title={x.option4}>
                        {x.option4}
                      </option>
                    </select>
                  </div>

                  <div className="py-3"></div>
                </div>
              );
            })}
          </form>
        </div>

        <div className="row mb-3 px-lg-2">
          <div className="col ">
            <button
              className="border-0 bg-secondary text-white fz-14px py-2 px-5 rounded-3 text-center"
              role="button"
              type="button"
              onClick={handleAddClick}
              disabled={statusCodes.includes(assessmentStatus) ? true : false}
            >
              + Create New
            </button>
          </div>
          <div className="col d-flex justify-content-end">
            {/* <button form="lessonName" className='border-0 bg-secondary text-white fz-14px py-2 px-5 mx-2 rounded-3 text-center' role="button" onClick={() => setCurrentViewComponentLsnAssessAtom(<SingleChapterView clickedChapterData={clickedChapterDataListAtom} />)}>Cancel</button> */}
            {inputList.length ? (
              <button
                form="Independentassessment"
                className="border-0 bg-prime text-white fz-14px py-2 px-5 rounded-3 text-center"
                role="button"
                type="submit"
                disabled={
                  loading || statusCodes.includes(assessmentStatus)
                    ? true
                    : false
                }
              >
                {loading ? "please wait ...." : "Save"}
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      </>
    </div>
  );
}

export default InnerIndependentAssessmentCreation;

{
  /* <InsideDataAssessment />   <CreateAssessmentInsideClickedOne />  */
}
