import moment from "moment";
import React, { useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import swal from "sweetalert";
import { API_CALL } from "../../../../../api/ApiServiceCall";
import { API_CONSTANTS, API_METHOD } from "../../../../../common/constants";
import { handleInitMeeting } from "../../../../../utils/dyteApis";
import { goLiveCourseViewAtomState } from "../../../../atom/goLiveCourseView";
import CloseFileIcon from "../../../../svg-icons/closeFileIcon";
import LiveCourseGoLive from "./liveCourseGoLive";

function GoLiveCourseForm({ goLiveClickedData }) {
  console.log("goLiveClickedData", goLiveClickedData);
  const [resource, setResource] = useState(
    goLiveClickedData?.resourceDetails || []
  );
  const [loading, setLoading] = useState(false);
  const [caption, setCaption] = useState(goLiveClickedData?.liveCaption);
  const [dyteToken, setDyteToken] = useState(null);
  const [viewComponentForGoLive, setViewComponentForGoLive] = useRecoilState(
    goLiveCourseViewAtomState
  );
  const navigate = useNavigate();
  const removeResource = (resIndex) => {
    setResource((prevState) =>
      prevState.filter((file, index) => {
        if (index !== resIndex) {
          return true;
        }
        return false;
      }).map(file => {
        if (file instanceof File) {
          return file;
        } else {
          return new File([file], file.resourceName);
        }
      })
    );
  };

  const isTokenProvided = !!goLiveClickedData?.joinLiveLink;
  const onChangeGetFilesSelected = (e) => {
    var filesArr = Array.prototype.slice.call(e.target.files);

    filesArr = filesArr.map((file) => {
      const newFileName = file.name.replace(/\s+/g, "_");
      return new File([file], newFileName, { type: file.type });
    });

    setResource((prevState) => [
      ...prevState.map((file) => {
        if (file instanceof File) {
          return file;
        } else {
          const newFileName = file.resourceName.replace(/\s+/g, "_");
          return new File([file], newFileName);
        }
      }),
      ...filesArr,
    ]);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const courseCode = localStorage.getItem("courseCode");
    const formData = new FormData();
    // Get the date portion from the scheduledDate
    const datePart = moment(goLiveClickedData.scheduledDate).format(
      "YYYY-MM-DD"
    );
    // Concatenate the date and time
    const mergedDateTime = moment(`${datePart} ${goLiveClickedData.startTime}`);
    // Format the merged date and time
    const formattedDateTime = mergedDateTime.toISOString();

    formData.append("courseCode", courseCode);
    formData.append("liveCaption", caption);
    formData.append("startTime", goLiveClickedData.startTime);
    formData.append("endTime", goLiveClickedData.endTime);
    formData.append("weekDay", goLiveClickedData.weekDay);
    formData.append("scheduledDate", goLiveClickedData.scheduledDate);
    formData.append("scheduledDateTime", formattedDateTime);
    formData.append("liveClassOrder", goLiveClickedData?.liveClassOrder);
    formData.append("liveUrl", `${window.location.origin}/live-room`);
    resource.forEach((resourceData, index) => {
      if (resourceData instanceof File) {
        formData.append("liveCourseResourse", resourceData);
      } else {
        formData.append(
          "liveCourseResourse",
          new File([resourceData], resourceData.resourceName)
        );
      }
    });

    let requestBodyParam = formData;
    const usrdetailforHeader = JSON.parse(
      localStorage.getItem("localUserData")
    );
    let apiHeader = {
      "x-auth-token": usrdetailforHeader?.jwt,
    };
    if (usrdetailforHeader?.data?.user?.email) {
      apiHeader = {
        token: usrdetailforHeader?.data?.user?.email,
        gmailUserType: "INSTRUCTOR",
      };
    }
    const request = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.CREATE_LIVE_COURSE_CLASS,
      method: API_METHOD.POST,
      headers: apiHeader,
      requestBodyParams: requestBodyParam,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      setLoading(false);
      setViewComponentForGoLive(<LiveCourseGoLive />);
      // setViewComponentForGoLive(<LiveCourseRoom />)
    } else if (result.status > 200) {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
      setLoading(false);
    }
  };
  const goLiveRoom = async () => {
    const userData = JSON.parse(localStorage.getItem("localUserData"));
    const userType = "webinar_presenter";
    await handleInitMeeting(caption, userData, userType).then((dyteData) => {
      console.log("tokenData, roomId", dyteData);
      onStartLiveCourse(dyteData);
    });
  };

  // ---onStart Live code
  const onStartLiveCourse = async (dyteData) => {
    const { tokenData, roomId } = dyteData;
    const courseCode = localStorage.getItem("courseCode");
    const requestBodyParam = JSON.stringify({
      liveClassId: goLiveClickedData._id,
      courseCode: courseCode,
      liveClassOrder: goLiveClickedData.liveClassOrder,
      dyteID: roomId,
    });
    localStorage.setItem("liveCourseData", requestBodyParam);
    const request = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.START_LIVE_COURSE_CLASS,
      method: API_METHOD.POST,
      requestBodyParams: requestBodyParam,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      navigate(`/live-room/${tokenData}`);
    } else if (result.status > 200) {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        closeOnClickOutside: false,
        button: false,
      });
    }
  };
  // ---onStart Live code End

  //  moment().format('HHmm') + "===" + moment(goLiveClickedData?.startTime, "h:mm").format('HHmm')
  const [block, setBlock] = useState(true);
  const courseStatus = localStorage.getItem("courseStatus");
  const isBlock = () => {
    let apiDate = moment(goLiveClickedData.scheduledDate).format("YYYY-MM-DD");
    let currentDate = moment().format("YYYY-MM-DD");
    let apiEndTime = goLiveClickedData.endTime;
    let apiStartTime = goLiveClickedData.startTime;
    let splittedStartTime = apiStartTime.split(":");
    let splittedEndTime = apiEndTime.split(":");
    let mergedStartTime = moment(apiDate).set({
      hour: splittedStartTime[0],
      minute: splittedStartTime[1],
    });
    let mergedEndTime = moment(apiDate).set({
      hour: splittedEndTime[0],
      minute: splittedEndTime[1],
    });

    if (moment(currentDate).isSame(apiDate)) {
      if (
        moment().isSameOrAfter(mergedStartTime) &&
        moment().isSameOrBefore(mergedEndTime)
      ) {
        setBlock(false);
      } else {
        setBlock(true);
      }
    } else {
      // console.log("expi")
      setBlock(true);
    }
    if (
      courseStatus.toUpperCase() == "INREVIEW" ||
      courseStatus.toUpperCase() == "REJECTED" ||
      courseStatus.toUpperCase() == "BANNED"
    ) {
      setBlock(true);
    }
  };
  useEffect(() => {
    isBlock();
  }, []);
  return (
    <div>
      <p
        onClick={() => setViewComponentForGoLive(<LiveCourseGoLive />)}
        role="button"
        className="d-inline-block"
      >
        <BiArrowBack /> Back
      </p>
      <p className="fz-15px fw-500">Live Class Topic</p>
      <form onSubmit={handleSubmit}>
        <input
          className="default-inpt"
          autoComplete="off"
          disabled={courseStatus.toUpperCase() == "INREVIEW"}
          defaultValue={caption}
          onChange={(e) => setCaption(e.target.value)}
          required
        />

        <div className="d-flex mt-3">
          <p className="fz-15px fw-500 pe-4">Attachments</p>{" "}
          <label
            className={`${
              courseStatus.toUpperCase() == "INREVIEW"
                ? "cursor-default"
                : "text-muted fz-13px"
            }`}
            role="button"
            htmlFor="add_attachments"
          >
            Upload Resource
          </label>
          <input
            id="add_attachments"
            type="file"
            disabled={courseStatus.toUpperCase() == "INREVIEW"}
            onClick={(e) => (e.currentTarget.value = null)}
            onChange={onChangeGetFilesSelected}
            multiple={true}
            accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf"
            className="d-none"
          />
        </div>
        {resource
          ? resource.map((file, index) => (
              <div key={index} className="word-break-all">
                <div
                  className="d-inline-block"
                  type="button"
                  onClick={() => removeResource(index)}
                >
                  {" "}
                  <CloseFileIcon size={29} />
                </div>
                {file?.resourceName || file?.name}
              </div>
            ))
          : ""}
        <div className="d-flex mt-3">
          <div className="ms-auto">
            <button
              className=" border-0 px-5 bg-secondary text-white fz-14px py-2 mx-3 rounded-3"
              disabled={courseStatus.toUpperCase() == "INREVIEW"}
            >
              Save
            </button>
            <button
              className="  border-0 px-5 bg-prime text-white fz-14px py-2 rounded-3"
              disabled={!isTokenProvided || block}
              role="button"
              type="button"
              onClick={goLiveRoom}
            >
              {" "}
              Start Live{" "}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}


export default GoLiveCourseForm;
