import moment from "moment";
import {
  default as React,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { AiFillFile, AiOutlineCloseCircle } from "react-icons/ai";
import { IoMdSend } from "react-icons/io";
import { MdOutlineClose } from "react-icons/md";
import swal from "sweetalert";
import { socket } from "../../../common/constants";
import "../../../css/chat.css";
import "../../../css/smallUtilities.css";
import ErroImg from "../../../images/icons/error.png";
import AttachIcon from "../../svg-icons/attachIcon";
import TableMessageIcon from "../../svg-icons/tableMessageIcon";

const attachStyle = {
  position: "absolute",
  left: "26px",
  top: "12px",
  bottom: "0",
  zIndex: 1,
  right: "auto",
};
const pxMed = {
  padding: "13px 35px",
};
const filePreview = {
  position: "absolute",
  zIndex: "9",
  width: "100%",
  textAlign: "center",
  bottom: "-7px",
  background: "rgb(248 248 248)",
  height: "120px",
};
const closeFile = {
  position: "absolute",
  left: "5px",
  top: "8px",
};
const aln = {
  right: "18px",
  position: "absolute",
  top: "auto",
  bottom: "auto",
};

function RaiseTicketChatWindowForTransaction({
  index,
  isTicketOpen,
  setIsTicketOpen,
  data,
}) {
  const [isOpenChatWindow, setisOpenChatWindow] = useState(false);
  const message = useRef("");
  const [msgList, setMsgList] = useState([]);
  const [status, setStatus] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
const [isTicketSending, setIsTicketSending] = useState(false);

  const [localUserData, setLocalUserData] = useState(
    JSON.parse(localStorage.getItem("localUserData"))
  );
  const [file, setFile] = useState({
    fileData: "",
    fileName: "",
  });
  const [loading, setLoading] = useState(false);

  console.log(socket.connected);

  const receiveMessages = useCallback((response) => {
    console.log("recev live chat-------->");
    console.log(response);
    if (response.type == "FILE") {
      setMsgList((chatMessages) => [
        ...chatMessages,
        {
          userName: response.userName,
          time: response.createdAt,
          message: response.message,
          type: response.type,
          fileName: response.fileName,
        },
      ]);
    } else {
      setMsgList((chatMessages) => [
        ...chatMessages,
        {
          userName: response.userName,
          time: response.createdAt,
          message: response.message,
        },
      ]);
    }
  }, []);
  useEffect(() => {
    if (isOpenChatWindow) {
      socket.open();

      socket.on("connection-success", async (response) => {
        console.log(`Socket connected ${response.socketId}`);
      });

      socket.emit(
        "join-instructor",
        {
          courseCode: data?._id,
          userName: JSON.parse(localStorage.getItem("localUserData")).name,
        },
        async (res) => {
          console.log(`join-instructor ${res}`);
          console.log(res);
        }
      );

      socket.emit(
        "getPreviousMessage",
        { courseCode: data?._id, ticketType: "TRANSACTION" },
        (response) => {
          console.log("getPreviousMessage");
          console.log(response);

          setMsgList((chatMessages) => [...response]);
        }
      );
      // ======To reset msg count=========
      socket.emit(
        "resetMessageCount",
        {
          courseCode: data?._id,
          userType: JSON.parse(localStorage.getItem("localUserData")).userType,
        },
        (response) => {
          // console.log("Reset Message Count Ran!", response)
        }
      );
      // ======To reset msg end=========
      socket.on("message", receiveMessages);
    } else {
      socket.off("message", receiveMessages);
      socket.close();
      socket.on("disconnect", function () {
        console.log("client disconnected from server");
      });
    }
    return () => {
      socket.close();
      socket.on("disconnect", function () {
        console.log("client disconnected from server");
      });
    };
  }, [isOpenChatWindow]);
const isWhitespaceString = (str) => !/\S/.test(str);
  // For text msg
  const sendMsg = (e) => {
    e.preventDefault();
    if (isTicketSending) return;
    setIsTicketSending(true);
    if (isWhitespaceString(message.current.value)) {
      setIsTicketSending(false);
      return;
    }
    if (message.current.value) {
      socket.emit(
        "sendMessage",
        {
          message: message.current.value,
          courseCode: data?._id,
          roomName: data?._id,
          userName: localUserData.name,
          ticketType: "TRANSACTION",
          courseType: "RECORDED",
          userId: localUserData.userId,
          type: "TEXT",
        },
        (responseData) => {
          console.log("sendMessage callback");
          console.log(responseData);
          if (responseData.success) {
            setMsgList((chatMessages) => [
              ...chatMessages,
              {
                userName: localUserData.name,
                dt: moment().format(),
                message: message.current.value,
              },
            ]);
            setTimeout(() => {
              message.current.value = "";
              setIsTicketSending(false);
            }, 500);
          } else {
            setIsTicketSending(false);
          }
        }
      );
      // finalSend();
    } else {
      setIsTicketSending(false);
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      sendMsg(e);
    }
  };
  // For file upload
  const sendFiles = (e) => {
    if (e.target.files) {
      let files = e.target.files;
      setFile({
        fileData: files[0],
        fileName: files[0].name,
      });
    }
  };
  const onConfrimUpload = () => {
    if (file && file.fileData && file.fileData.size > 1024 * 1024) {
      // File size is greater than 1MB, show a SweetAlert popup
      swal({
        title: "File Size Limit Exceeded",
        text: "Please upload a file smaller than 1MB.",
        icon: ErroImg,
        className: "error-window",
        button: true,
        closeOnClickOutside: false,
      });
      return;
    }
    setLoading(true);
    socket.emit(
      "upload",
      {
        file: file?.fileData,
        fileName: file?.fileName,
        courseCode: data?._id,
        roomName: data?._id,
        userName: localUserData.name,
        userId: localUserData?.userId,
        ticketType: "TRANSACTION",
        courseType: "RECORDED",
        type: "FILE",
      },
      (res) => {
        console.log(res);
        setMsgList((chatMessages) => [
          ...chatMessages,
          {
            userName: res?.userName,
            dt: moment().format(),
            type: "FILE",
            fileName: res.fileName,
            message: res.message,
          },
        ]);
        setLoading(false);
        setFile({
          fileData: "",
          fileName: "",
        });
      }
    );
  };
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [msgList]);

  const openChatWindow = () => {
    setisOpenChatWindow(!isOpenChatWindow);
  };
  const onCancelUpload = () => {
    setFile({
      fileData: "",
      fileName: "",
    });
  };
  const onRaiseTicket = () => {
    setisOpenChatWindow(true);
    setIsTicketOpen(index);
  };
  return (
    <div className="position-relative">
      {isTicketOpen === index ? (
        <div className=" h-75  justify-content-end d-flex align-items-end">
          {isOpenChatWindow ? (
            <div className="chatWindowRaiseTicket ticket-chat">
              <div className="d-flex justify-content-between px-4 pt-4 align-items-lg-start">
                <h6 className="m-0 p-0">Ticket</h6>{" "}
                <div
                  onClick={() => setisOpenChatWindow(false)}
                  className="adjus-x"
                >
                  <MdOutlineClose />
                </div>
              </div>
              <div className="chatHeight position-relative">
                {msgList?.map((val, index) => (
                  <div key={index} className="py-1 " ref={messagesEndRef}>
                    <p
                      className={`${
                        val?.userName == localUserData.name
                          ? "text-end"
                          : "text-start"
                      } m-0 p-0 fw-600 fz-14px`}
                    >
                      <span className="text-muted fz-10px fw-400 me-1 ">
                        {moment(val?.dt).calendar()}
                      </span>
                      {val?.userName == localUserData.name
                        ? "Me"
                        : val?.userName}
                    </p>
                    {val?.type == "FILE" ? (
                      <div
                        className={` d-block   ${
                          val?.userName?.replace(/\s/g, "") ==
                          localUserData.name?.replace(/\s/g, "")
                            ? "text-end"
                            : "text-start"
                        } `}
                      >
                        <a
                          className={`text-decoration-none text-prime d-inline-flex  fw-500 text-prime  fz-12px bg-pink py-2 px-3 rounded `}
                          href={val?.message}
                          title={val?.message?.split("/").pop()}
                        >
                          {val?.message?.split("/").pop().substr(0, 30)}...
                          <img
                            src="https://cdn-icons-png.flaticon.com/512/2820/2820279.png"
                            className="ms-2  d-block"
                            style={{
                              height: "18px",
                              width: "18px",
                              objectFit: "contain",
                            }}
                          />
                        </a>
                      </div>
                    ) : (
                      <div
                        className={`${
                          val?.userName == localUserData.name
                            ? "text-end"
                            : "text-start"
                        } fw-500 fz-15px`}
                      >
                        {val?.message?.split("\n").map((line, i) => (
                          <p key={i} className="m-0 p-0">
                            {line}
                          </p>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
              {file?.fileName ? (
                <div style={filePreview} className="p-3">
                  <AiFillFile size={30} color={"grey"} />
                  <div className="d-flex justify-content-center align-items-center">
                    <p className="fz-13px m-0">{file?.fileName}</p>{" "}
                    <button
                      className="bg-transparent border-0"
                      disabled={loading ? true : false}
                      style={closeFile}
                      onClick={onCancelUpload}
                    >
                      <AiOutlineCloseCircle size={18} />
                    </button>
                    <button
                      className="bg-transparent border-0"
                      style={aln}
                      onClick={onConfrimUpload}
                      role="button"
                      disabled={loading ? true : false}
                    >
                      {loading ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span className="visually-hidden">Loading...</span>
                        </>
                      ) : (
                        <IoMdSend className="ms-2" size={20} />
                      )}
                    </button>
                  </div>
                </div>
              ) : (
                ""
              )}
              <form
                className="w-100 d-flex px-3 pb-2  position-relative"
                onSubmit={sendMsg}
                style={{ pointerEvents: formSubmitted ? "none" : "auto" }}
              >
                <input
                  className="d-none"
                  id="attachFile"
                  type="file"
                  accept="image/gif, image/jpeg, image/png"
                  onClick={(event) => {
                    event.target.value = null;
                  }}
                  onChange={(event) => {
                    sendFiles(event);
                  }}
                />
                <label htmlFor="attachFile" role="button">
                  <span style={attachStyle}>
                    <AttachIcon />
                  </span>
                </label>
                <textarea
                  className=" default-inpt rounded-9 pe-4"
                  style={{
                    overflow: "hidden",
                    padding: "13px 40px",
                    maxHeight: "50px",
                    height: "50px",
                    minHeight: "50px",
                    resize: "none",
                  }}
                  placeholder="write a message..."
                  type={"text"}
                  ref={message}
                  onKeyDown={handleKeyDown}
                  disabled={isTicketSending}
                />
                <button
                  className="border-0 align-arrow  fz-18px bg-transparent text-prime"
                  type="submit"
                  disabled={isTicketSending}
                >
                  <IoMdSend />
                </button>
              </form>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
      <div onClick={onRaiseTicket} role="button">
        <TableMessageIcon width={35} />
      </div>
      {data?.newMessageCount ? (
        <div className="position-relative">
          <div className="ticket-notification-badge-table">
            {data?.newMessageCount}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default RaiseTicketChatWindowForTransaction;

// import React from 'react'
// import { useEffect } from 'react';
// import { useRef } from 'react';
// import { useState } from 'react';
// import TableMessageIcon from '../../svg-icons/tableMessageIcon'
// import { GrClose } from 'react-icons/gr'
// import { API_CALL } from '../../../api/ApiServiceCall';
// import { API_CONSTANTS, API_METHOD } from '../../../common/constants';
// import swal from 'sweetalert';
// import { logger } from '../../../common/logger';
// function RaiseTicketChatWindowForTransaction({ index, isTicketOpen, setIsTicketOpen, data }) {
//     useEffect(() => {
//         // getMsg();
//     }, [])
//     const getMsg = async () => {

//         const request = {
//             apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.GET_WITHDRAW_TICKET_BY_EMAIL + `/?transactionId=${data.transactionId}`,
//             method: API_METHOD.GET,
//         }
//         let result = await API_CALL(request)
//         if (result.status === 200) {
//             setMsgList(result?.data[0]?.messages || [])
//         } else if (result.status > 200) {
//             swal({
//                 title: "ERROR",
//                 text: result.message,
//                 icon: 'https://cdn-icons-png.flaticon.com/512/2569/2569174.png',
//                 className: "error-window",
//                 button: true,
//             })
//         }
//     }
//     const message = useRef("");
//     const [msgList, setMsgList] = useState([])

//     const sendMsg = (e) => {
//         e.preventDefault()
//         if (message.current.value) {
//             const currentMsg = [message.current.value]
//             setMsgList([...msgList, message.current.value])
//             message.current.value = "";
//             onApiSubmit(currentMsg)
//         }
//     }
//     const onApiSubmit = async (currentMsg) => {
//         const requestBodyParam = JSON.stringify({
//             "transactionId": data.transactionId,
//             "messages": currentMsg
//         })
//         const request = {
//             apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.RAISE_WITHDRAW_TICKET,
//             method: API_METHOD.POST,
//             requestBodyParams: requestBodyParam,
//         }
//         let result = await API_CALL(request)
//         if (result.status === 200) {

//         } else if (result.status > 200) {
//             swal({
//                 title: "ERROR",
//                 text: result.message,
//                 icon: 'https://cdn-icons-png.flaticon.com/512/2569/2569174.png',
//                 className: "error-window",
//                 button: true,
//             })
//         }
//     }

//     return (
//         <div className="position-relative">
//             {isTicketOpen === index ?
//                 <div className='chat-window p-0 ticket-chat' style={{
//                     'right': '50px',
//                     'bottom': '39px'
//                 }} >
//                     <div className='d-flex justify-content-lg-end align-items-lg-center'>
//                         {/* <h6 className='me-auto m-0 px-3 py-4'>Chat</h6> */}
//                         <button className='bg-transparent border-0 px-3 pt-2' onClick={() => setIsTicketOpen(-1)}><GrClose /></button>
//                     </div>
//                     <div className="text-right px-3" style={{ height: '160px', overflowY: 'scroll' }}>
//                         {msgList.map((lst, indx) => (
//                             <div key={lst + indx} className=''>
//                                 <p className='m-0 fw-600 fz-14px text-end'><span className='text-muted fz-10px fw-400'>{new Date().toLocaleTimeString([], { hour: '2-digit', minute: "2-digit" })}</span> You</p>
//                                 <p className='text-end fz-16px'>{lst}</p>
//                             </div>
//                         ))}
//                     </div>
//                     <form className='px-2 pb-2 d-block bg-white bottom-0' onSubmit={sendMsg}>
//                         <input className='chat-input rounded-1' type={"text"} ref={message} />
//                     </form>
//                 </div> : ""}
//             <div onClick={() => setIsTicketOpen(index)} role="button"><TableMessageIcon width={35} /></div></div>
//     )
// }

// export default RaiseTicketChatWindowForTransaction
