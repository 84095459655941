import React from "react";

function UploadIcon({ size }) {
  return (
    <svg
      width={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0068 2.57787L10.9998 18.0159C10.9998 18.2811 11.1052 18.5355 11.2927 18.723C11.4802 18.9105 11.7346 19.0159 11.9998 19.0159C12.265 19.0159 12.5194 18.9105 12.7069 18.723C12.8945 18.5355 12.9998 18.2811 12.9998 18.0159L13.0068 2.59487L15.9188 5.50787C16.1063 5.69535 16.3606 5.80066 16.6258 5.80066C16.891 5.80066 17.1453 5.69535 17.3328 5.50787V5.50787C17.5203 5.32035 17.6256 5.06604 17.6256 4.80087C17.6256 4.53571 17.5203 4.2814 17.3328 4.09387L14.1218 0.878872C13.8432 0.600092 13.5124 0.378942 13.1483 0.228057C12.7842 0.0771728 12.3939 -0.000488281 11.9998 -0.000488281C11.6057 -0.000488281 11.2154 0.0771728 10.8513 0.228057C10.4872 0.378942 10.1564 0.600092 9.87781 0.878872L6.66681 4.09087C6.47934 4.2784 6.37402 4.53271 6.37402 4.79787C6.37402 5.06304 6.47934 5.31735 6.66681 5.50487V5.50487C6.85434 5.69235 7.10865 5.79766 7.37381 5.79766C7.63897 5.79766 7.89328 5.69235 8.08081 5.50487L11.0068 2.57787Z"
        fill="white"
      />
      <path
        d="M22 17V21C22 21.2652 21.8946 21.5196 21.7071 21.7071C21.5196 21.8947 21.2652 22 21 22H3C2.73478 22 2.48043 21.8947 2.29289 21.7071C2.10536 21.5196 2 21.2652 2 21V17C2 16.7348 1.89464 16.4804 1.70711 16.2929C1.51957 16.1054 1.26522 16 1 16V16C0.734783 16 0.480429 16.1054 0.292893 16.2929C0.105357 16.4804 0 16.7348 0 17L0 21C0 21.7957 0.31607 22.5587 0.878679 23.1213C1.44129 23.6839 2.20435 24 3 24H21C21.7956 24 22.5587 23.6839 23.1213 23.1213C23.6839 22.5587 24 21.7957 24 21V17C24 16.7348 23.8946 16.4804 23.7071 16.2929C23.5196 16.1054 23.2652 16 23 16C22.7348 16 22.4804 16.1054 22.2929 16.2929C22.1054 16.4804 22 16.7348 22 17Z"
        fill="white"
      />
    </svg>
  );
}

export default UploadIcon;
