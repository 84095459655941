import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

function CompletedCourseCard({ course, currentItem, value }) {
  const [resolvedImageUrls, setResolvedImageUrls] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const handleCourseClick = (e) => {
    e.stopPropagation();
    navigate(`/course/${course.courseCode}`);
  };
  // useEffect(() => {
  //     const fetchImages = async () => {
  //         if (!currentItem) {
  //             setIsLoading(true)
  //             return; // Return early if currentItems is null or undefined
  //           }
  //       const imageUrls = await Promise.all(
  //         currentItem.map(async (datas,id) => {
  //           try {
  //             setIsLoading(true)
  //             const blobUrl1 = await imageUrlToBlobUrl(datas.thumbNailImagePath);
  //             const blobUrl = URL.createObjectURL(blobUrl1);

  //             // setTimeout(() => {
  //             //     URL.revokeObjectURL(blobUrl);
  //             // },1000);
  //             console.log("blobUrl",blobUrl)
  //             setIsLoading(false)

  //             return blobUrl ;

  //           } catch (error) {
  //             setIsLoading(false)
  //             console.log('Error fetching image:', error);
  //             return null;
  //           }
  //         })
  //       );

  //       setResolvedImageUrls(imageUrls);
  //     };

  //     fetchImages();
  //   }, [currentItem]);
  return (
    <div
      onClick={handleCourseClick}
      className="p-4 bg-white rounded-4 d-flex my-4"
      style={{ cursor: "pointer" }}
    >
      {/* {isLoading ? (
                <div className='d-flex align-items-center justify-content-center py-5'>
                <span className="spinner-border spinner-border-sm text-secondary" role="status" aria-hidden="true"></span>
                <span className="visually-hidden">Loading...</span>
            </div>
             ) : ( */}
      <img
        src={course.thumbNailImagePath}
        alt={course.courseName}
        className="rounded-3"
        style={{
          height: "140px",
          width: "220px",
          objectFit: "cover",
          flexShrink: 0,
        }}
      />
      {/* )} */}
      <div className="flex-grow-1 px-4">
        <h6 className="fs-5">{course.courseName}</h6>
        <div className="d-flex align-items-center">
          <p style={{ fontSize: "0.9rem" }} className="mb-0">
            by{" "}
            <Link
              to={`/instructor-profile/${course.instructorId}`}
              style={{ color: "#395061", cursor: "pointer" }}
            >
              {course.instructorName}
            </Link>
          </p>
          {course.isLive && !course.isExpired && (
            <button
              className="btn ms-4 rounded-pill"
              style={{ backgroundColor: "#F75656", color: "#fff" }}
            >
              Live Course
            </button>
          )}
          {course.isExpired && (
            <button
              className="btn ms-4 rounded-pill"
              style={{ backgroundColor: "#F75656", color: "#fff" }}
            >
              Expired
            </button>
          )}
        </div>
        <p className="mt-3 fw-semibold" style={{ color: "#29D363" }}>
          Completed
        </p>
      </div>
      <div className="d-flex align-items-end" style={{ flexShrink: 0 }}>
        <Link
          to={`/course/${course.courseCode}`}
          className="btn px-4 py-2"
          style={{ backgroundColor: "#F0E1EB", color: "#395061" }}
        >
          <span className="me-2">Go to course</span>
          <svg
            width="18"
            height="12"
            viewBox="0 0 23 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M22.6531 9.23649L15.3614 16.5281C15.165 16.7179 14.9018 16.8229 14.6287 16.8205C14.3556 16.8181 14.0943 16.7086 13.9012 16.5155C13.7081 16.3223 13.5985 16.0611 13.5961 15.7879C13.5938 15.5148 13.6988 15.2517 13.8885 15.0552L19.4021 9.5417H1.08329C0.807026 9.5417 0.542074 9.43195 0.346723 9.2366C0.151373 9.04125 0.041626 8.7763 0.041626 8.50003C0.041626 8.22376 0.151373 7.95881 0.346723 7.76346C0.542074 7.56811 0.807026 7.45836 1.08329 7.45836H19.4021L13.8885 1.94483C13.789 1.84874 13.7097 1.7338 13.6551 1.60671C13.6005 1.47962 13.5717 1.34293 13.5705 1.20462C13.5693 1.06631 13.5957 0.929144 13.6481 0.801126C13.7004 0.673111 13.7778 0.556807 13.8756 0.459002C13.9734 0.361197 14.0897 0.283848 14.2177 0.231472C14.3457 0.179096 14.4829 0.15274 14.6212 0.153942C14.7595 0.155144 14.8962 0.183882 15.0233 0.238474C15.1504 0.293066 15.2653 0.372421 15.3614 0.47191L22.6531 7.76357C22.8484 7.95891 22.9581 8.22382 22.9581 8.50003C22.9581 8.77624 22.8484 9.04115 22.6531 9.23649Z"
              fill="#395061"
            />
          </svg>
        </Link>
      </div>
    </div>
  );
}

export default CompletedCourseCard;
