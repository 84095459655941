import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { API_CALL } from "../../api/ApiServiceCall";
import { API_CONSTANTS, API_METHOD } from "../../common/constants";

function AssessmentsList({ currentItems }) {
  return (
    <>
      {currentItems?.length > 0 ? (
        currentItems?.map((assessment) => {
          return (
            <CompletedAssessmentCard
              key={assessment.assessmentCode}
              assessment={assessment}
            />
          );
        })
      ) : (
        <p>No Courses.</p>
      )}
    </>
  );
}

function CompletedAssessments() {
  const [completedAssessemnts, setCompletedAssessemnts] = useState({
    loading: true,
    error: null,
    data: null,
  });

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [itemOffset, setItemOffset] = useState(0);
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(null);

  const getCompletedAssessments = async () => {
    setCompletedAssessemnts({ loading: true, data: null, error: null });

    const request = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.COMPLEATED_ASSESSMENTS,
      method: API_METHOD.GET,
    };

    let result = await API_CALL(request);

    if (result.status === 200) {
      const data = result.data?.filter(
        (assessment) => assessment.assessmentStatus !== "BANNED"
      );
      setCompletedAssessemnts({
        loading: false,
        data: data ? data : [],
        error: null,
      });
    } else if (result.status > 200) {
      setCompletedAssessemnts({
        loading: false,
        data: null,
        error: result.message,
      });
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }
  };

  useEffect(() => {
    console.log("COMPLETED");
    getCompletedAssessments();
  }, []);

  useEffect(() => {
    if (completedAssessemnts?.data?.length) {
      const endOffset = itemOffset + Number(itemsPerPage);
      let sliceItem = completedAssessemnts?.data?.slice(itemOffset, endOffset);
      setCurrentItems(sliceItem);
      let pageCountResult = Math.ceil(
        completedAssessemnts?.data?.length / itemsPerPage
      );
      setPageCount(pageCountResult);
    }
  }, [completedAssessemnts?.data?.length, itemOffset, itemsPerPage]);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % completedAssessemnts?.data?.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      {completedAssessemnts.loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "300px" }}
        >
          <div class="spinner-border" role="status">
            <span class="sr-only"></span>
          </div>
        </div>
      ) : completedAssessemnts.data?.length > 0 ? (
        <AssessmentsList currentItems={currentItems} />
      ) : (
        <p>No Assessments</p>
      )}

      {completedAssessemnts.data?.length ? (
        <div className="mt-8 d-flex justify-content-between align-items-center">
          <ReactPaginate
            breakLabel="..."
            nextLabel="Next ->"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="<- Previous"
            renderOnZeroPageCount={null}
            containerClassName="pagination d-inline-flex prevent-text-select"
            activeLinkClassName="pagination-activeLink prevent-text-select"
            previousLinkClassName="pagination-container pagination-previous-btn prevent-text-select"
            pageLinkClassName="pagination-container prevent-text-select"
            nextLinkClassName="pagination-container pagination-next-btn prevent-text-select"
            disabledClassName="pagination_link_disabled"
          />

          <div className="col-lg-4 fz-13px fw-500 text-muted text-end">
            Show{" "}
            <select
              className="px-2 py-1 "
              onChange={(e) => {
                setItemsPerPage(e.target.value);
              }}
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={75}>75</option>
              <option value={100}>100</option>
            </select>{" "}
            courses per page
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

function CompletedAssessmentCard({ assessment }) {
  const navigate = useNavigate();

  useEffect(() => {
    console.log("Completed assessments");
  }, []);

  return (
    <div
      className="bg-white d-flex justify-content-between align-items-center py-4 rounded-4 mb-4"
      style={{ paddingLeft: "2.1em", paddingRight: "2.1em" }}
    >
      <div className="d-flex gap-3">
        <svg
          width="50"
          height="50"
          viewBox="0 0 70 70"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ flexShrink: 0 }}
        >
          <g clip-path="url(#clip0_612_3714)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M35 0C15.6953 0 0 15.6953 0 35C0 54.3047 15.6953 70 35 70C54.3047 70 70 54.3047 70 35C70 15.6953 54.3047 0 35 0Z"
              fill="#29D363"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M51.9248 23.2012C52.7725 24.0488 52.7725 25.4434 51.9248 26.291L31.417 46.7988C30.9932 47.2227 30.4326 47.4414 29.8721 47.4414C29.3115 47.4414 28.751 47.2227 28.3272 46.7988L18.0732 36.5449C17.2256 35.6973 17.2256 34.3027 18.0732 33.4551C18.9209 32.6074 20.3154 32.6074 21.1631 33.4551L29.8721 42.1641L48.835 23.2012C49.6826 22.3398 51.0772 22.3398 51.9248 23.2012Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_612_3714">
              <rect width="70" height="70" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <div>
          <div className="d-flex gap-3">
            <h3 className="mb-0 fs-5">{assessment.assessmentTitle}</h3>
            {assessment.assessmentStatus && (
              <>
                {assessment.assessmentStatus === "BANNED" ? (
                  <div style={{ paddingRight: "20px" }}>
                    <span
                      className="rounded-pill text-white px-2 py-1"
                      style={{ backgroundColor: "#F75656", fontSize: "0.8rem" }}
                    >
                      Banned
                    </span>
                  </div>
                ) : (
                  <div style={{ paddingRight: "20px" }}>
                    {assessment.isAssessmentPass ? (
                      <span
                        className="rounded-pill text-white px-2 py-1"
                        style={{
                          backgroundColor: "#29D363",
                          fontSize: "0.8rem",
                        }}
                      >
                        Passed
                      </span>
                    ) : (
                      <span
                        className="rounded-pill text-white px-2 py-1"
                        style={{
                          backgroundColor: "#F75656",
                          fontSize: "0.8rem",
                        }}
                      >
                        Failed
                      </span>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
          <p className="mt-2" style={{ fontSize: "0.9rem", color: "gray" }}>
            By : {assessment.instructorName}
          </p>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <span className="fw-500" style={{ marginRight: "60px" }}>
          Received Grade {assessment.assessmentPercentage}%
        </span>
        {assessment.assessmentStatus != "BANNED" && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              navigate(
                `/attend-assessment?assessmentCode=${assessment.assessmentCode}`
              );
            }}
            className="btn fw-500"
            style={{ fontSize: "1.1rem", color: "#395061" }}
          >
            VIEW
          </button>
        )}
      </div>
    </div>
  );
}

export default CompletedAssessments;
