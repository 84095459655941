import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { AiFillAndroid, AiFillApple } from 'react-icons/ai'
import FullLogoIcon from '../components/svg-icons/fullLogoIcon'

function InstructorAppRedirectModal() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => { setShow(true); }
    useEffect(() => {
        const userAgent = window.navigator.userAgent;
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) {
            handleShow();
        }

    }, [])

    return (
        <Modal show={show} onHide={handleClose} animation={false} centered backdrop={"static"} className='mh'>
        <Modal.Header closeButton={false} className='mh'>
          <Modal.Title className='mdlt'>Install  App</Modal.Title>
          <Modal.Header style={{ borderBottom: 0 }} closeButton></Modal.Header>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <FullLogoIcon />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20px' }}>
          <div style={{ flex: '0 0 auto', marginRight: '15px' }}>
              <button className='border-0 bg-prime text-white' style={{ border: '0', color: '#fff', fontSize: '15px', padding: '12px', borderRadius: '3px', textAlign: 'center', flexGrow: '1' }}>
                  <AiFillAndroid style={{ marginBottom: '1px' }} size={20} /> Android
              </button>
          </div>
          <div style={{ flex: '0 0 auto' }}>
              <button  className='border-0 bg-prime text-white' style={{ border: '0',color: '#fff', fontSize: '15px', padding: '12px', borderRadius: '3px', textAlign: 'center', width: '100px' }}>
                  <AiFillApple style={{ marginBottom: '1px' }} size={20} /> iOS
              </button>
          </div>
      </div>


        </Modal.Body>
      </Modal>
    )
}

export default InstructorAppRedirectModal