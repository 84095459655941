import { useState, useEffect } from 'react';
import swal from 'sweetalert';
import { useRecoilValue } from 'recoil';
import ExtraFilterMenu from './ExtraFilterMenu';
import CoursesFilter from './CoursesFilter';
import CourseCard from '../common/CourseCard';
import { API_CALL } from './../../api/ApiServiceCall';
import { API_METHOD } from './../../common/constants';
import { courseSearchFullEndpointState, searchState } from './../atoms/search-atom';

function Courses() {
    const [courses, setCourses] = useState({
        loading: false,
        error: null,
        data: []
    });

    const courseSearchFullEndpoint = useRecoilValue(courseSearchFullEndpointState);
    const search = useRecoilValue(searchState);

    const getCourses = async (endPoint) => {
        if (!endPoint.includes('givenCourseName=')) {
            return setCourses({ loading: false, error: null, data: [] });
        }

        setCourses({ loading: true, data: [], error: null });

        const request = {
            apiUrl: endPoint,
            method: API_METHOD.GET,
        }

        let result = await API_CALL(request);

        if (result.status === 200) {
            setCourses({ loading: false, data: result.data, error: null });
        } else if (result.status > 200) {
            setCourses({ loading: false, data: [], error: result.message });

            if (result.message == "Token is Not Valid") return null

            swal({
                title: "ERROR",
                text: result.message,
                icon: 'https://cdn-icons-png.flaticon.com/512/2569/2569174.png',
                className: "error-window",
                button: true,
            })
        }
    }

    useEffect(() => {
        getCourses(courseSearchFullEndpoint)
    }, [courseSearchFullEndpoint])

    return (
        <>
            <div className="student-conatiner pt-5 mb-0">
                <h2 className="fs-4 mb-0 pb-0">{courses.data.length} Result For “{search.typedString}”</h2>
            </div>
            <div className="student-conatiner">
                <ExtraFilterMenu />
                <div className="d-flex align-items-start gap-3">
                    <CoursesFilter />
                    {courses.loading ? (
                        <div className="p-2 pt-0 flex-grow-1">
                            {[1, 2, 3, 4, 5].map(item => (
                                <div key={item} className="p-4 bg-white rounded-4 d-flex mb-4 pe-auto">
                                    <div className="d-flex justify-content-center align-items-center" style={{ width: '100%', height: '130px' }}>
                                        <div className="spinner-border text-secondary" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : courses?.data?.length > 0 ? (
                        <div className="p-2 pt-0 flex-grow-1">
                            {courses.data.map(course => (
                                <CourseCard
                                    key={course._id}
                                    course={course}
                                    liveCourse={course.isLive}
                                />
                            ))}
                        </div>
                    ) : <p>No Courses Found</p>}
                </div>
            </div>
            <br /><br /><br />
        </>
    )
}

export default Courses