import {
  RecaptchaVerifier,
  getAuth,
  signInWithPhoneNumber,
} from "firebase/auth";
import React, { useEffect, useState } from "react";
import { Container, Modal } from "react-bootstrap";
import { useRecoilState } from "recoil";
import { getCredentialLoginAccountProfileDetailAtom } from "../../../../atom/getCredentialLoginAccountProfileDetail";
import VerifyMobileOtp from "./VerifyMobileOtp";
function ChangeMobileNumber({ closeModal }) {
  const [mobileNumber, setMobileNumber] = useState("");
  const [component, setComponent] = useState(null);
  const [error, setError] = useState("");
  const [mobLength, setRequiredMobLength] = useState("");
  const [loading, setLoading] = useState(false);
  const [
    credentialLoginAccountProfileDetailAtom,
    setCredentialLoginAccountProfileDetailAtom,
  ] = useRecoilState(getCredentialLoginAccountProfileDetailAtom);

  useEffect(() => {}, []);

  const auth = getAuth();
  const generateRecap = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        },
      },
      auth
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // alert("hi")
    let isNumberDuplicate =
      credentialLoginAccountProfileDetailAtom?.data?.mobileNumber ==
      "+" + mobileNumber;
    // console.log(isNumberDuplicate)
    if (
      mobileNumber &&
      mobLength == mobileNumber.length &&
      !isNumberDuplicate
    ) {
      setError("");
      setLoading(true);
      generateRecap();
      const mobNo = "+91" + mobileNumber;
      const appVerifier = window.recaptchaVerifier;
      signInWithPhoneNumber(auth, mobNo, appVerifier)
        .then((confirmationResult) => {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          window.confirmationResult = confirmationResult;
          // ...
          setComponent("verify");
        })
        .catch((error) => {
          setError("Request failed, please try again");
          setLoading(false);
        });
    } else if (mobileNumber === "") {
      setError("Please Enter Mobile Number");
    } else {
      // console.log(credentialLoginAccountProfileDetailAtom?.data?.mobileNumber);
      // console.log(mobileNumber);

      setError("Same Mobile Number Already exists");
    }
  };

  return (
    <div>
      <div id="recaptcha-container"></div>
      {/* {credentialLoginAccountProfileDetailAtom?.data?.mobileNumber}
            {mobileNumber} */}
      <Container>
        <Modal.Header closeButton className="mh">
          <Modal.Title className="mdlt">Change Mobile Number</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {component ? (
              <VerifyMobileOtp
                closeModal={closeModal}
                mobileNumber={mobileNumber}
              />
            ) : (
              <form className="login-col" onSubmit={handleSubmit}>
                {/* <pre> {JSON.stringify(credentialLoginAccountProfileDetailAtom?.data?.mobileNumber, null, 4)}</pre>
                                {mobileNumber} */}

                <label className="fz-15px text-prime">
                  Enter the new mobile number
                </label>
                {/* <PhoneInput
                                    country='in'
                                    countryCodeEditable={false}
                                    buttonClass="border-0 ps-1"
                                    className="ph-frm border-0"
                                    placeholder='Enter Mobile Number'
                                    value={mobileNumber}
                                    // onChange={(mbNum) => setMobileNumber(mbNum)}
                                    inputProps={{
                                        name: 'phone',
                                        required: true,
                                        autoFocus: false,
                                        required: true,
                                    }}
                                    onChange={(inputPhone, countryData) => {
                                        // console.log("req", countryData.format.length, "===>currentLen", inputPhone.length, "num", inputPhone)
                                        setRequiredMobLength(countryData.format.split(".").length - 1)
                                        setMobileNumber(inputPhone)
                                    }
                                    }
                                /> */}
                <input
                  type="number"
                  className="ph-frm border-0"
                  placeholder="Enter Mobile Number"
                  buttonClass="border-0 ps-1"
                  value={mobileNumber}
                  onChange={(e) => {
                    const PhoneNoInput = e.target.value;
                    setRequiredMobLength(PhoneNoInput.length);
                    setMobileNumber(PhoneNoInput);
                  }}
                />
                {mobLength == mobileNumber.length ? (
                  ""
                ) : (
                  <small className="text-danger fw-500 pt-2 d-block">
                    Invalid
                  </small>
                )}
                {error && (
                  <small className="text-danger fw-500 pt-2 d-block">
                    {error}
                  </small>
                )}
                <div className="py-2"></div>

                <button
                  className="login-form-btn"
                  type="submit"
                  disabled={loading ? true : false}
                >
                  {loading ? "Please wait..." : "Submit"}
                </button>
              </form>
            )}
          </div>
        </Modal.Body>
      </Container>
    </div>
  );
}

export default ChangeMobileNumber;
