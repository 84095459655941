import { Modal, Nav, Navbar } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import { API_CALL } from '../api/ApiServiceCall';
import QlearningLogo from '../components/svg-icons/mainLogo';
import { API_CONSTANTS, API_METHOD, STORAGE_KEY } from './constants';
import useCookie from './cookie_hook';

import { useState } from 'react';
import FullLogoIcon from '../components/svg-icons/fullLogoIcon';
import { AiFillAndroid, AiFillApple } from 'react-icons/ai';
import GetPlayStoreIcon from '../components/svg-icons/GetPlayStoreIcon';
import GetAppStoreIcon from '../components/svg-icons/GetAppStoreIcon';
function Menu() {
  const navigate = useNavigate();
  const [cookie, updateCookie] = useCookie(STORAGE_KEY.AUTH)
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => { setShow(true); }
  const isAuthenticated = cookie && localStorage.getItem(STORAGE_KEY.LOCAL_USER_DATA);
  const logoutFromDB = async () => {
    const requestBodyParam = JSON.stringify({})
    const request = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.LOGOUT_INSTRUCTOR,
      method: API_METHOD.POST,
      requestBodyParams: requestBodyParam,
    }
    let result = await API_CALL(request)
    if (result.status === 200) {
      localStorage.clear();
      updateCookie(null);
      navigate("/login");
    } else if (result.status > 200) {
      swal({
        title: "ERROR",
        text: result.message,
        icon: 'https://cdn-icons-png.flaticon.com/512/2569/2569174.png',
        className: "error-window",
        button: true,
      })
    }
  }
  const customSignOut = () => {
    // const auth = getAuth();
    // signOut(auth).then(() => {

    // }).catch((error) => {
    //   console.log(error)
    // });
    swal({
      title: "Are You Sure To Logout?",
      text: "",
      icon: "warning",
      imageClass: 'img-warning',
      buttons: true,
      buttons: ["No", "Yes"],
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        logoutFromDB()
      }
    })
  }

  const onLogin = () => {
    const userAgent = window.navigator.userAgent;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) {
      setShow(true);
    } else {
      setShow(false);
      navigate("/login");
    }
  }

  const onCreateAccount = () => {
    const userAgent = window.navigator.userAgent;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) {
      setShow(true);
    } else {
      setShow(false);
      navigate("/create-account");
    }
  }

  return (
    <>

      <Modal show={show} onHide={handleClose} animation={false} centered backdrop={"static"} className='mh'>
        <Modal.Header closeButton={false} className='mh'>
          <Modal.Title className='mdlt'>Install  App</Modal.Title>
          <Modal.Header style={{ borderBottom: 0 }} closeButton></Modal.Header>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <FullLogoIcon />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20px' }}>
          <div style={{ flex: '0 0 auto', marginRight: '15px' }}>
              <button className='border-0 bg-prime text-white' style={{ border: '0', color: '#fff', fontSize: '15px', padding: '12px', borderRadius: '3px', textAlign: 'center', flexGrow: '1' }}>
                  <AiFillAndroid style={{ marginBottom: '1px' }} size={20} /> Android
              </button>
          </div>
          <div style={{ flex: '0 0 auto' }}>
              <button  className='border-0 bg-prime text-white' style={{ border: '0',color: '#fff', fontSize: '15px', padding: '12px', borderRadius: '3px', textAlign: 'center', width: '100px' }}>
                  <AiFillApple style={{ marginBottom: '1px' }} size={20} /> iOS
              </button>
          </div>
      </div>


        </Modal.Body>
      </Modal>
      <Navbar className='nav-bg fixed-top py-1 ' expand="lg">
        <div className='container-fluid'>
          <Link to="/">
            <QlearningLogo />
          </Link>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              // style={{ maxHeight: '100px' }}
              navbarScroll
            >
              {/* <Form className="d-flex">
              <div className="me-lg-0 tag">
                <select>
                  <option value="">Category</option>
                  <option value="">Cooking</option>
                  <option value="">Education</option>
                  <option value="">Programming</option>
                  <option value="">More</option>
                </select>
              </div>

              <div className='srch-bg ms-lg-3'>
                <input className="srch-ui" type="search" placeholder="Search Course" />
              </div>
            </Form> */}


            </Nav>
            {/* <Nav.Item className="ml-lg-auto">
            <Form className="d-flex me-lg-4 lang">
              <select>
                <option value="">English</option>
                <option value="">Tamil</option>
              </select>
            </Form>

          </Nav.Item> */}


            <Nav.Item className="ml-lg-auto">
              <a href="#" className='menu-font-style me-lg-2'>  <GetPlayStoreIcon height={"35"} width={"100"} /></a>
            </Nav.Item>
            <Nav.Item className="ml-lg-auto">
              <a href="#" className='menu-font-style me-lg-3'>  <GetAppStoreIcon height={"36"} width={"100"} /></a>
            </Nav.Item>
            <Nav.Item className="ml-lg-auto">
              <Link to="/student-home" className='menu-font-style me-lg-4'>Become A Student</Link>
            </Nav.Item>
            <Nav.Item className="ml-lg-auto">
              {isAuthenticated ? <Link className='menu-font-style me-lg-4' to="/transaction">Dashboard</Link> : ""}
            </Nav.Item>


            {/* <Link to="/instructor-notification" className='notification mx-lg-4 position-relative'><span className={`${getNotificationsAtom.count ? notificationStyles.badge : "d-none"}`}>{getNotificationsAtom.count}</span><NotificationIcon classNameProp={"bell-svg"} />
          </Link> */}

            {/* <Nav.Item className="ml-lg-auto">
            {isAuthenticated ? <Link to="/courses" className='menu-font-style me-lg-4'>Course</Link> : ""}
          </Nav.Item>

          <Nav.Item className="ml-lg-auto">
            {isAuthenticated ? <Link to="/profile/teacher/teacher-profile" className='menu-font-style me-lg-4'>Profile</Link> : ""}
          </Nav.Item> */}
            {/* <Nav.Item className="ml-lg-auto">
            <Link to="#" className='me-lg-4'>
              <svg className='cart' viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_184_2835)">
                  <path d="M23.6594 4.24688C23.3664 3.89525 22.9995 3.61245 22.5849 3.41856C22.1703 3.22467 21.7181 3.12444 21.2604 3.125H4.41875L4.375 2.75938C4.28547 1.99939 3.92019 1.29868 3.34843 0.790081C2.77666 0.281483 2.03815 0.000356035 1.27292 0L1.04167 0C0.765399 0 0.500448 0.109747 0.305097 0.305097C0.109747 0.500448 0 0.7654 0 1.04167C0 1.31793 0.109747 1.58289 0.305097 1.77824C0.500448 1.97359 0.765399 2.08333 1.04167 2.08333H1.27292C1.52806 2.08337 1.77431 2.17704 1.96497 2.34658C2.15563 2.51612 2.27744 2.74974 2.30729 3.00313L3.74062 15.1906C3.88943 16.4581 4.49841 17.6269 5.45199 18.475C6.40556 19.3232 7.63735 19.7917 8.91354 19.7917H19.7917C20.0679 19.7917 20.3329 19.6819 20.5282 19.4866C20.7236 19.2912 20.8333 19.0263 20.8333 18.75C20.8333 18.4737 20.7236 18.2088 20.5282 18.0134C20.3329 17.8181 20.0679 17.7083 19.7917 17.7083H8.91354C8.26881 17.7065 7.64042 17.5053 7.11452 17.1324C6.58861 16.7594 6.19095 16.2329 5.97604 15.625H18.3927C19.6138 15.6251 20.7962 15.1961 21.7331 14.413C22.6701 13.6298 23.3021 12.5424 23.5187 11.3406L24.3365 6.80521C24.4181 6.35522 24.3997 5.89278 24.2827 5.45066C24.1657 5.00855 23.9529 4.59758 23.6594 4.24688ZM22.2917 6.43542L21.4729 10.9708C21.3428 11.6927 20.963 12.3458 20.3999 12.8158C19.8367 13.2858 19.1262 13.5428 18.3927 13.5417H5.64479L4.66458 5.20833H21.2604C21.4134 5.20742 21.5648 5.24023 21.7037 5.30443C21.8426 5.36864 21.9656 5.46265 22.0641 5.5798C22.1625 5.69695 22.234 5.83435 22.2733 5.98223C22.3126 6.13011 22.3189 6.28484 22.2917 6.43542Z" fill="#395061" />
                  <path d="M7.29159 25.0001C8.44218 25.0001 9.37492 24.0673 9.37492 22.9167C9.37492 21.7661 8.44218 20.8334 7.29159 20.8334C6.14099 20.8334 5.20825 21.7661 5.20825 22.9167C5.20825 24.0673 6.14099 25.0001 7.29159 25.0001Z" fill="#395061" />
                  <path d="M17.7083 25.0001C18.8589 25.0001 19.7917 24.0673 19.7917 22.9167C19.7917 21.7661 18.8589 20.8334 17.7083 20.8334C16.5577 20.8334 15.625 21.7661 15.625 22.9167C15.625 24.0673 16.5577 25.0001 17.7083 25.0001Z" fill="#395061" />
                </g>
                <defs>
                  <clipPath id="clip0_184_2835">
                    <rect width="25" height="25" fill="white" />
                  </clipPath>
                </defs>
              </svg></Link>
          </Nav.Item> */}

            <Nav.Item className="ml-lg-auto">
              {isAuthenticated ? <button className='login-btn' onClick={customSignOut}>Log Out</button> : <div onClick={onLogin} ><button className='login-btn'>Log In</button></div>}
            </Nav.Item>
            <Nav.Item className="ml-lg-auto">
              {isAuthenticated ? "" : <div onClick={onCreateAccount}><button className='acc-btn ms-lg-3'>Create Account</button></div>}
            </Nav.Item>

          </Navbar.Collapse>
        </div>
      </Navbar>
    </>
  )
}

export default Menu