import React, { useEffect, useState } from "react";
import { BiCircle } from "react-icons/bi";
import { FiTrash2 } from "react-icons/fi";
import { useRecoilState } from "recoil";
import swal from "sweetalert";
import { API_CALL } from "../../../../../../../api/ApiServiceCall";
import {
  API_CONSTANTS,
  API_METHOD,
} from "../../../../../../../common/constants";
import { clickedChapterDataListAtomFunc } from "../../../../../../atom/clickedChapterDataListAtomFunc";

function EditClickedAssessment({
  getLessonbyLessonOrderFunc,
  setIsEdit,
  getClickedLessonData,
}) {
  const [clickedChapterDataListAtom, setClickedChapterDataListAtom] =
    useRecoilState(clickedChapterDataListAtomFunc);

  useEffect(() => {
    formatDataForForm();
  }, []);

  const [inputList, setInputList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [assessmentName, setAssessmentName] = useState(
    getClickedLessonData.lessonName
  );
  const formatDataForForm = () => {
    const formData = getClickedLessonData.assessmentDetails;
    const formattedData = formData.map((inputData) => {
      let formObject = {
        question: inputData.assessmentQuestion,
        point: inputData.point,
        answer: inputData.assessmentAnswer,
        assessmentOrder: inputData.assessmentOrder,
        _id: inputData._id,
      };
      inputData.assessmentChoice.map((value, index) => {
        let indx = index + 1;
        formObject["option" + indx] = value;
      });

      return formObject;
    });
    // alert(JSON.stringify(formattedData,null,4))
    setInputList(formattedData);
  };
  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    if (name == "point") {
      if (e.target.value.length > e.target.getAttribute("maxLength")) {
        e.target.value = e.target.value.slice(
          0,
          e.target.getAttribute("maxLength")
        );
      } else if (e.target.value == "0") {
        // const list = [...inputList];
        // list[index][name] = 1;
        // setInputList(list);
        swal({
          text: "The point should be greater than 1 and less than 10",
          icon: "warning",
        });
      } else {
        //set valid value
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
      }
    } else {
      const list = [...inputList];
      list[index][name] = value;
      setInputList(list);
    }
  };

  // handle click event of the Remove button
  const handleRemoveClick = (assessmentId, index) => {
    swal({
      title: "Are you sure?",
      text: "To recreate this field after it's been deleted, you'll need to create it again",
      icon: "https://cdn-icons-png.flaticon.com/512/6460/6460112.png",
      imageClass: "img-warning",
      animation: false,
      className: "swal-center",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      // alert(assessmentId)
      if (willDelete) {
        if (assessmentId) {
          const userCode = JSON.parse(localStorage.getItem("userCode"));
          const requestBodyParam = JSON.stringify({
            courseCode: userCode.data,
            chapterOrder: clickedChapterDataListAtom.data.chapterOrder,
            assesmentOrder: getClickedLessonData.lessonOrder,
            assessmentId: assessmentId,
          });
          const request = {
            apiUrl:
              API_CONSTANTS.BASE_URL + API_CONSTANTS.DELETE_ASSESSMENT_BY_ID,
            method: API_METHOD.POST,
            requestBodyParams: requestBodyParam,
          };
          let result = await API_CALL(request);
          if (result.status === 200) {
            const list = [...inputList];
            list.splice(index, 1);
            setInputList(list);
            getLessonbyLessonOrderFunc();
          } else if (result.status > 200) {
            swal({
              title: "ERROR",
              text: result.message,
              icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
              className: "error-window",
              button: true,
            });
          }
        } else {
          const list = [...inputList];
          list.splice(index, 1);
          setInputList(list);
        }
      }
    });
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([
      ...inputList,
      {
        question: "",
        point: "1",
        option1: "",
        option2: "",
        option3: "",
        option4: "",
        answer: "",
      },
    ]);
  };

  const handleSubmitForAssessment = async () => {
    setLoading(true);
    const formattedAssessmentDetails = inputList.map((items) => {
      return {
        assessmentQuestion: items.question,
        point: items.point,
        assessmentChoice: [
          items.option1,
          items.option2,
          items.option3,
          items.option4,
        ],
        assessmentAnswer: items.answer,
      };
    });
    const userCode = JSON.parse(localStorage.getItem("userCode"));
    const requestBodyParam = JSON.stringify(
      {
        courseCode: userCode.data,
        chapterOrder: clickedChapterDataListAtom.data.chapterOrder,
        assessmentOrder: getClickedLessonData.lessonOrder, //sending lessonOrder only. assessmentOrder key is for backend propose
        assessmentName: assessmentName,
        assessmentDetails: formattedAssessmentDetails,
      },
      null,
      4
    );
    // alert(requestBodyParam)
    const request = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.CREATE_ASSESMENT,
      method: API_METHOD.POST,
      requestBodyParams: requestBodyParam,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      getLessonbyLessonOrderFunc();
      setIsEdit(false);
      setLoading(false);
    } else if (result.status > 200) {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }
  };
  useEffect(() => {
    var e = document.querySelectorAll("option");
    e.forEach((x) => {
      if (x.textContent.length > 80)
        x.textContent = x.textContent.substring(0, 80) + "...";
    });
  });

  const checkForDuplicateQuestion = (e, index) => {
    const prevList = [...inputList];
    prevList.splice(index, 1);
    const checkList = prevList.some((item) => {
      return (
        item?.question.trim().toLocaleLowerCase() ==
        e.target.value.trim().toLocaleLowerCase()
      );
    });

    if (checkList) {
      swal({
        text: "This question was already exists!",
        icon: "warning",
      });
      const list = [...inputList];
      list[index]["question"] = "";
      setInputList(list);
    }
  };
  const checkForDuplicateOptions = (e, index) => {
    if (e.target.value) {
      const prevList = [...inputList];
      const rawObj = prevList[index];
      const keysOnArry = Object.keys(rawObj);
      // console.log(keysOnArry);
      let isDup = false;
      const currentValue = e.target.value.trim().toLocaleLowerCase();
      let option1 = rawObj.option1.trim().toLocaleLowerCase();
      let option2 = rawObj.option2.trim().toLocaleLowerCase();
      let option3 = rawObj.option3.trim().toLocaleLowerCase();
      let option4 = rawObj.option4.trim().toLocaleLowerCase();
      if (rawObj._id) {
        if (keysOnArry[5] == e.target.name) {
          // console.log("option1 Block")
          const isCurrentOptionSame =
            currentValue == option2 ||
            currentValue == option3 ||
            currentValue == option4;
          isDup = isCurrentOptionSame;
        }
        if (keysOnArry[6] == e.target.name) {
          // console.log("option2 Block")
          const isCurrentOptionSame =
            currentValue == option1 ||
            currentValue == option3 ||
            currentValue == option4;
          isDup = isCurrentOptionSame;
        }
        if (keysOnArry[7] == e.target.name) {
          // console.log("option3 Block")
          const isCurrentOptionSame =
            currentValue == option1 ||
            currentValue == option2 ||
            currentValue == option4;
          isDup = isCurrentOptionSame;
        }
        if (keysOnArry[8] == e.target.name) {
          // console.log("option4 Block");
          const isCurrentOptionSame =
            currentValue == option1 ||
            currentValue == option2 ||
            currentValue == option3;
          isDup = isCurrentOptionSame;
        }
      } else {
        if (keysOnArry[2] == e.target.name) {
          // console.log("option1 Block")
          const isCurrentOptionSame =
            currentValue == option2 ||
            currentValue == option3 ||
            currentValue == option4;
          isDup = isCurrentOptionSame;
        }
        if (keysOnArry[3] == e.target.name) {
          // console.log("option2 Block")
          const isCurrentOptionSame =
            currentValue == option1 ||
            currentValue == option3 ||
            currentValue == option4;
          isDup = isCurrentOptionSame;
        }
        if (keysOnArry[4] == e.target.name) {
          // console.log("option3 Block")
          const isCurrentOptionSame =
            currentValue == option1 ||
            currentValue == option2 ||
            currentValue == option4;
          isDup = isCurrentOptionSame;
        }
        if (keysOnArry[5] == e.target.name) {
          // console.log("option4 Block");
          const isCurrentOptionSame =
            currentValue == option1 ||
            currentValue == option2 ||
            currentValue == option3;
          isDup = isCurrentOptionSame;
        }
      }
      if (isDup) {
        swal({
          text: "This option was already exists for this question!",
          icon: "warning",
        });
        prevList[index][e.target.name] = "";
        setInputList(prevList);
      }
    }
  };
  return (
    <div>
      <>
        {/* <pre>{JSON.stringify(inputList, null, 4)}</pre> */}
        <div className="container">
          <form
            id="updateAssessment"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmitForAssessment();
            }}
          >
            <div className="login-col pt-4">
              <input
                autoComplete="off"
                type="text"
                value={assessmentName}
                placeholder="Assessment Name Here"
                onChange={(e) => setAssessmentName(e.target.value)}
                required
              />
            </div>
            <p className="fz-14px text-muted fw-400">
              Note: Please add the questions and answers to the assessment
            </p>
            {inputList.map((questionAnswerData, i) => {
              return (
                <div key={"AssessmentEdit_id_" + i}>
                  {/* Assessment Order - {questionAnswerData.assessmentOrder} */}
                  <div className="d-flex justify-content-between ">
                    <textarea
                      rows={"1"}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") e.preventDefault();
                      }}
                      autoComplete="off"
                      onBlur={(e) => checkForDuplicateQuestion(e, i)}
                      type="text"
                      className="question-input"
                      name="question"
                      onChange={(e) => handleInputChange(e, i)}
                      value={questionAnswerData.question}
                      placeholder="Enter Question here*"
                      required
                    />

                    <div className="d-flex justify-content-end align-items-center">
                      Point* &nbsp;
                      <input
                        autoComplete="off"
                        type="number"
                        maxLength={1}
                        name="point"
                        onChange={(e) => handleInputChange(e, i)}
                        value={questionAnswerData.point}
                        className="point-input"
                        required
                      />
                      <div
                        onClick={() =>
                          handleRemoveClick(questionAnswerData?._id, i)
                        }
                      >
                        <FiTrash2 className="trsh-assesment" />
                      </div>
                    </div>
                  </div>
                  <div className="assesment-option">
                    <div>
                      <label>
                        <BiCircle />
                      </label>{" "}
                      <textarea
                        onBlur={(e) => checkForDuplicateOptions(e, i)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") e.preventDefault();
                        }}
                        autoComplete="off"
                        className="p-2 mx-2 w-50 border-0"
                        type="text"
                        placeholder="Option1*"
                        name="option1"
                        onChange={(e) => handleInputChange(e, i)}
                        value={questionAnswerData.option1}
                        required
                      />
                    </div>
                    <div>
                      <label>
                        <BiCircle />
                      </label>{" "}
                      <textarea
                        onBlur={(e) => checkForDuplicateOptions(e, i)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") e.preventDefault();
                        }}
                        autoComplete="off"
                        className="p-2 mx-2 w-50  border-0"
                        type="text"
                        name="option2"
                        placeholder="Option2*"
                        onChange={(e) => handleInputChange(e, i)}
                        value={questionAnswerData.option2}
                        required
                      />
                    </div>
                    <div>
                      <label>
                        <BiCircle />
                      </label>{" "}
                      <textarea
                        onBlur={(e) => checkForDuplicateOptions(e, i)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") e.preventDefault();
                        }}
                        autoComplete="off"
                        className="p-2 mx-2 w-50 border-0"
                        type="text"
                        name="option3"
                        placeholder="Option3*"
                        onChange={(e) => handleInputChange(e, i)}
                        value={questionAnswerData.option3}
                        required
                      />
                    </div>
                    <div>
                      <label>
                        <BiCircle />
                      </label>{" "}
                      <textarea
                        onBlur={(e) => checkForDuplicateOptions(e, i)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") e.preventDefault();
                        }}
                        autoComplete="off"
                        className="p-2 mx-2 w-50 border-0"
                        type="text"
                        name="option4"
                        placeholder="Option4*"
                        onChange={(e) => handleInputChange(e, i)}
                        value={questionAnswerData.option4}
                        required
                      />
                    </div>
                  </div>
                  Answer
                  <div className="tag-ajus w-50 rounded-2">
                    <select
                      name="answer"
                      onChange={(e) => handleInputChange(e, i)}
                      value={
                        questionAnswerData.answer ===
                          questionAnswerData.option1 ||
                        questionAnswerData.answer ===
                          questionAnswerData.option2 ||
                        questionAnswerData.answer ===
                          questionAnswerData.option3 ||
                        questionAnswerData.answer === questionAnswerData.option4
                          ? questionAnswerData.answer
                          : "Please Select Answer*"
                      }
                      required
                    >
                      <option value="">Please Select Answer*</option>
                      <option
                        value={questionAnswerData.option1}
                        title={questionAnswerData.option1}
                      >
                        {questionAnswerData.option1}
                      </option>
                      <option
                        value={questionAnswerData.option2}
                        title={questionAnswerData.option2}
                      >
                        {questionAnswerData.option2}
                      </option>
                      <option
                        value={questionAnswerData.option3}
                        title={questionAnswerData.option3}
                      >
                        {questionAnswerData.option3}
                      </option>
                      <option
                        value={questionAnswerData.option4}
                        title={questionAnswerData.option4}
                      >
                        {questionAnswerData.option4}
                      </option>
                    </select>
                  </div>
                  <div className="py-3"></div>
                </div>
              );
            })}
          </form>
        </div>

        <div className="row mb-3 px-2">
          <div className="col d-flex">
            <button
              className="border-0 bg-prime text-white fz-14px py-2 px-5 rounded-3 text-center"
              role="button"
              type="button"
              onClick={handleAddClick}
            >
              + Create New
            </button>
          </div>
          <div className="col-12 d-flex justify-content-end">
            <button
              type="button"
              className="border-0 bg-secondary text-white mx-2 fz-14px py-2 px-5 rounded-3 text-center"
              role="button"
              onClick={() => setIsEdit(false)}
            >
              Cancel
            </button>
            <button
              form="updateAssessment"
              className="border-0 bg-prime text-white fz-14px py-2 px-5 rounded-3 text-center"
              role="button"
              type="submit"
              disabled={loading ? true : false}
            >
              {loading ? "Please Wait..." : "Done"}
            </button>
          </div>
        </div>
      </>
    </div>
  );
}

export default EditClickedAssessment;
