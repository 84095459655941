import React from 'react'

function TermsAndConditions() {
    return (
        <div className="student-conatiner py-5 mt-4">
            <h1 className="fs-3 my-4 text-center">Terms and Conditions</h1>
            <ol>
                <li className="mb-3">Eligibility: Users must be at least 18 years old to access the website and purchase courses.</li>
                <li className="mb-3">Use of website: Users may only use the website for personal, non-commercial purposes. Users may not reproduce, distribute, modify, display, or publicly perform any content from the website without the express written permission of the website owner.</li>
                <li className="mb-3">User accounts: Users are responsible for maintaining the confidentiality of their account login information and are fully responsible for all activities that occur under their account. Users must immediately notify the website of any unauthorized use of their account.</li>
                <li className="mb-3">Intellectual property: All content on the website, including but not limited to text, graphics, images, and software, is the property of the website owner or its licensors and is protected by copyright and other intellectual property laws.</li>
                <li className="mb-3">Disclaimer of warranties: The website owner makes no representations or warranties of any kind, express or implied, as to the operation of the website or the information, content, materials, or products included on the website. The website owner will not be liable for any damages of any kind arising from the use of the website, including but not limited to direct, indirect, incidental, punitive, and consequential damages.</li>
                <li className="mb-3">Limitation of liability: In no event will the website owner be liable for any damages, including without limitation, indirect or consequential damages, or any damages whatsoever arising from the use or loss of use of the website, or from any unauthorized access to or alteration of the user's transmissions or data.</li>
                <li className="mb-3">Governing law: These terms and conditions and any disputes arising out of or in connection with the website will be governed by and construed in accordance with the laws of the state of [State], without giving effect to any principles of conflicts of law.</li>
                <li className="mb-3">Entire agreement: These terms and conditions constitute the entire agreement between the user and the website owner and supersede all prior or contemporaneous communications and proposals, whether oral or written. If any provision of these terms and conditions is found to be invalid or unenforceable, that provision will be enforced to the maximum extent possible and the remaining provisions will remain in full force and effect.</li>
            </ol>
        </div>
    )
}

export default TermsAndConditions