import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { handleJoinParticipants } from "../../utils/dyteApis";

function TimeCounter({ clickedLiveClassListItems }) {
  const navigate = useNavigate();
  console.log("clickedLiveClassListItems", clickedLiveClassListItems);
  const [day, setDate] = useState(0);
  const [hour, setHour] = useState(0);
  const [min, setMin] = useState(0);
  const [sec, setSec] = useState(0);

  useEffect(() => {
    const currentTimestamp = moment().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    const targetTimestamp = moment(clickedLiveClassListItems.date);
    const duration = moment.duration(targetTimestamp.diff(currentTimestamp));
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    setDate(days);
    setHour(hours);
    setMin(minutes);
    setSec(seconds);

    const timer = setInterval(() => {
      duration.subtract(1, "second");
      setDate(duration.days());
      setHour(duration.hours());
      setMin(duration.minutes());
      setSec(duration.seconds());
      // }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [clickedLiveClassListItems.date]);

  const goLiveRoom = async () => {
    const userData = JSON.parse(localStorage.getItem("localUserData"));
    const roomId = clickedLiveClassListItems.dyteId;
    const userType = "webinar_viewer";
    await handleJoinParticipants(roomId, userData, userType).then(
      (dyteData) => {
        console.log("tokenData, roomId", dyteData);
        localStorage.setItem("courseCode", clickedLiveClassListItems.liveUUID);
        navigate(`/live-room/${dyteData?.tokenData}`);
      }
    );
  };

  const Timejsx = (
    <p className="p-0 m-0 fw-500">
      {day < 10 ? `0${day}` : day}
      <small className="fz-12px fw-600">d</small>:
      {hour < 10 ? `0${hour}` : hour}
      <small className="fz-12px fw-600">hr</small>:{min < 10 ? `0${min}` : min}
      <small className="fz-12px fw-600">m</small>
      {min === 0 && (
        <>
          :{sec < 10 ? `0${sec}` : sec}
          <small className="fz-12px fw-600">s</small>
        </>
      )}
    </p>
  );

  const start = new Date("2000-01-01 " + clickedLiveClassListItems?.startTime);
  const end = new Date("2000-01-01 " + clickedLiveClassListItems?.endTime);
  const targetHour = (end - start) / (1000 * 60 * 60);
  const differenceInHours = targetHour * -1;

  if (clickedLiveClassListItems?.liveCourse && hour <= differenceInHours) {
    return <p className="m-0 opacity-100 text-success">Completed</p>;
  }

  if (!clickedLiveClassListItems?.liveCourse && hour <= -1) {
    return <p className="m-0 opacity-100 text-success">Completed</p>;
  }

  if (hour <= 0 && min <= 0 && sec <= 0) {
    return (
      <>
        {clickedLiveClassListItems?.dyteId?.length ? (
          <Link
            className="bg-transparent border-0 p-0 fw-500 fz-15px mt-1"
            onClick={() => goLiveRoom()}
          >
            Join Live
          </Link>
        ) : (
          <div>Not Started</div>
        )}
      </>
    );
  }

  return Timejsx;
}

export default TimeCounter;
