import { useState } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil'
import { assessmentState } from './../../atom/assessment';

function CoursesAccordion({ courseCode, totalCourseDuration, chapterList, handleSelectedCourse, isPurchase }) {
    return (
        <div className="bg-white overflow-hidden" style={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>
            <div className="px-3 d-flex justify-content-between align-items-center" style={{ backgroundColor: '#395061', color: '#F0E1EB', paddingTop: '11px', paddingBottom: '11px' }}>
                <p className="p-0 m-0">Course Curriculum</p>
                {totalCourseDuration && (
                    <p className="p-0 m-0 d-flex justify-content-end align-items-center">
                        <svg width="15" height="15" viewBox="0 0 15 15" fill="#3366ff" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_58_3877)">
                                <path d="M7.5 0C6.01664 0 4.5666 0.439867 3.33323 1.26398C2.09986 2.08809 1.13856 3.25943 0.570907 4.62987C0.00324965 6.00032 -0.145275 7.50832 0.144114 8.96317C0.433503 10.418 1.14781 11.7544 2.1967 12.8033C3.2456 13.8522 4.58197 14.5665 6.03683 14.8559C7.49168 15.1453 8.99968 14.9967 10.3701 14.4291C11.7406 13.8614 12.9119 12.9001 13.736 11.6668C14.5601 10.4334 15 8.98336 15 7.5C14.9979 5.51153 14.207 3.60513 12.8009 2.19907C11.3949 0.793017 9.48847 0.00215068 7.5 0V0ZM8.125 7.42437C8.12507 7.53032 8.0982 7.63454 8.04693 7.72725C7.99565 7.81996 7.92165 7.89812 7.83188 7.95437L5.43188 9.45437C5.36211 9.49795 5.28445 9.52737 5.20332 9.54094C5.12218 9.5545 5.03917 9.55196 4.95902 9.53344C4.87888 9.51493 4.80316 9.48081 4.7362 9.43304C4.66923 9.38526 4.61234 9.32476 4.56875 9.255C4.52517 9.18523 4.49576 9.10757 4.48219 9.02643C4.46862 8.9453 4.47117 8.86229 4.48968 8.78214C4.5082 8.70199 4.54231 8.62628 4.59009 8.55931C4.63787 8.49235 4.69836 8.43546 4.76813 8.39187L6.875 7.07812V4.375C6.875 4.20924 6.94085 4.05027 7.05806 3.93306C7.17527 3.81585 7.33424 3.75 7.5 3.75C7.66576 3.75 7.82474 3.81585 7.94195 3.93306C8.05916 4.05027 8.125 4.20924 8.125 4.375V7.42437Z" fill="#F0E1EB" />
                            </g>
                            <defs>
                                <clipPath id="clip0_58_3877">
                                    <rect width="15" height="15" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        <span style={{ fontSize: '0.8rem' }} className="ml-4"><span>&nbsp;</span>
                            {totalCourseDuration && moment.utc(totalCourseDuration * 1000).format('HH:mm:ss')}
                        </span>
                    </p>
                )}
            </div>
            <div>
            {chapterList.map(chapter => {
                if (!chapter || chapter.chapterStatus !== "CREATED" || !chapter.lessonList || chapter.lessonList.length === 0 || !chapter.lessonList.some(lesson => lesson?.lessonStatus === "ACTIVE" || lesson?.lessonStatus === "INREVIEW" ) 
                ) {
                    return null;
                }

                return (
                    <CourseAccordion
                        key={chapter._id} 
                        name={chapter.chapterName}
                        lessonList={chapter.lessonList}
                        handleSelectedCourse={handleSelectedCourse}
                        chapterDuration={chapter.chapterDuration}
                        chapterOrder={chapter.chapterOrder}
                        courseCode={courseCode}
                        isPurchase={isPurchase}
                    />
                )
            })}
        </div>

        </div>
    );
}

function CourseAccordion({ name, last, lessonList, handleSelectedCourse, chapterDuration, chapterOrder, courseCode, isPurchase }) {
    console.log("chapterDuration",chapterDuration)
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const setAssessment = useSetRecoilState(assessmentState);

    const handleLessonSelect = (lesson, chapterOrder) => {
        ///if (!isPurchase) return null;

        if (lesson.isAssesment) {
            setAssessment({
                courseCode,
                chapterOrder,
                ...lesson
            });

            return navigate(`/assessment-preview?courseCode=${courseCode}&chapterOrder=${chapterOrder}&lessonOrder=${lesson.lessonOrder}`);
        }
        handleSelectedCourse({
            selected: true,
            thumbNailImagePath: "",
            lessonName: lesson.lessonName,
            lessonVideoPath: lesson.lessonVideoPath,
            chapterOrder: chapterOrder,
            lessonOrder: lesson.lessonOrder
        })
    }

    return (
        <div className={`p-3 prevent-text-select ${!last && 'border-bottom'}`}>
            <div onClick={() => setOpen(prevState => !prevState)} style={{ cursor: 'pointer' }}>
                <div className="d-flex justify-content-between align-items-center m-0 p-0">
                    <h6 className="p-0 m-0" style={{ fontSize: '0.8rem' }}>{name}</h6>
                    {open ? (
                        <svg width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.937438 8.20062C1.18599 8.2004 1.42429 8.10149 1.59994 7.92562L6.39493 3.13062C6.54003 2.98549 6.7123 2.87036 6.9019 2.79181C7.09149 2.71326 7.29471 2.67283 7.49993 2.67283C7.70516 2.67283 7.90837 2.71326 8.09797 2.79181C8.28757 2.87036 8.45984 2.98549 8.60493 3.13062L13.3968 7.91937C13.5736 8.09015 13.8104 8.18464 14.0562 8.1825C14.3021 8.18037 14.5372 8.08177 14.711 7.90795C14.8848 7.73413 14.9834 7.499 14.9856 7.25319C14.9877 7.00738 14.8932 6.77056 14.7224 6.59375L9.93431 1.805C9.28914 1.1611 8.41487 0.799471 7.50337 0.799471C6.59187 0.799471 5.7176 1.1611 5.07244 1.805L0.277438 6.6C0.146428 6.73092 0.0571336 6.89772 0.0208244 7.07934C-0.0154839 7.26097 0.00281807 7.44927 0.0734234 7.62051C0.144029 7.79174 0.263773 7.93822 0.417543 8.04146C0.571315 8.14471 0.752222 8.20009 0.937438 8.20062Z" fill="black" />
                        </svg>
                    ) : (
                        <svg width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.0626 0.799378C13.814 0.799597 13.5757 0.898512 13.4001 1.07438L8.60507 5.86938C8.45997 6.01451 8.2877 6.12964 8.0981 6.20819C7.90851 6.28674 7.70529 6.32717 7.50007 6.32717C7.29484 6.32717 7.09163 6.28674 6.90203 6.20819C6.71243 6.12964 6.54016 6.01451 6.39507 5.86938L1.6032 1.08063C1.42638 0.909854 1.18957 0.815359 0.943757 0.817496C0.697948 0.819632 0.462812 0.918227 0.288992 1.09205C0.115172 1.26587 0.0165757 1.501 0.0144397 1.74681C0.0123037 1.99262 0.106798 2.22944 0.277571 2.40625L5.06569 7.195C5.71086 7.8389 6.58513 8.20053 7.49663 8.20053C8.40813 8.20053 9.2824 7.8389 9.92756 7.195L14.7226 2.4C14.8536 2.26908 14.9429 2.10228 14.9792 1.92066C15.0155 1.73903 14.9972 1.55073 14.9266 1.3795C14.856 1.20826 14.7362 1.06178 14.5825 0.958539C14.4287 0.855294 14.2478 0.799911 14.0626 0.799378V0.799378Z" fill="black" />
                        </svg>
                    )}
                </div>
                {chapterDuration && (
                    <span style={{ fontSize: '0.7rem' }}>
                        {moment.utc(chapterDuration * 1000).format('HH:mm:ss')}
                    </span>
                )}
            </div>

            {open && (
                <div>
                    {lessonList.map(lesson => (
                        <div key={lesson._id} className="d-flex gap-3 my-3">
                            {/* {JSON.stringify(lesson, null, 3)} */}
                            <div>
                                {lesson.isAssesment ? (
                                    <svg width="25" height="25" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="14" cy="14" r="14" fill="#F0E1EB" />
                                        <path d="M10.9662 14.9504C11.0184 15.0054 11.0918 15.037 11.1676 15.0371H11.168C11.2437 15.0371 11.317 15.0057 11.3693 14.951L11.7276 14.5756C11.8114 14.4878 11.8082 14.3487 11.7204 14.2649C11.6326 14.1811 11.4935 14.1843 11.4097 14.2721L11.1683 14.5251L11.1271 14.4818C11.0435 14.3938 10.9044 14.3903 10.8164 14.4738C10.7285 14.5574 10.7249 14.6965 10.8085 14.7845L10.9662 14.9504Z" fill="#395061" />
                                        <path d="M12.5317 13.7229C12.5317 13.521 12.3674 13.3567 12.1655 13.3567H10.3701C10.1682 13.3567 10.0039 13.521 10.0039 13.7229V15.5183C10.0039 15.7202 10.1682 15.8845 10.3701 15.8845H12.1655C12.3674 15.8845 12.5317 15.7202 12.5317 15.5183V13.7229ZM12.0922 15.4451H10.4434V13.7962H12.0922V15.4451Z" fill="#395061" />
                                        <path d="M13.3877 15.8845H16.5061C16.6274 15.8845 16.7258 15.7861 16.7258 15.6648C16.7258 15.5434 16.6274 15.445 16.5061 15.445H13.3877C13.2663 15.445 13.168 15.5434 13.168 15.6648C13.168 15.7861 13.2663 15.8845 13.3877 15.8845Z" fill="#395061" />
                                        <path d="M13.3877 14.9872H16.5061C16.6274 14.9872 16.7258 14.8888 16.7258 14.7674C16.7258 14.646 16.6274 14.5477 16.5061 14.5477H13.3877C13.2663 14.5477 13.168 14.646 13.168 14.7674C13.168 14.8888 13.2663 14.9872 13.3877 14.9872Z" fill="#395061" />
                                        <path d="M10.9662 11.8648C11.0185 11.9197 11.0919 11.9513 11.1677 11.9513H11.168C11.2437 11.9513 11.3171 11.9199 11.3693 11.8652L11.7276 11.4899C11.8114 11.4021 11.8082 11.263 11.7204 11.1792C11.6326 11.0954 11.4935 11.0986 11.4097 11.1864L11.1682 11.4394L11.1271 11.396C11.0435 11.308 10.9044 11.3045 10.8164 11.3881C10.7285 11.4717 10.7249 11.6108 10.8085 11.6987L10.9662 11.8648Z" fill="#395061" />
                                        <path d="M12.5317 10.6372C12.5317 10.4352 12.3674 10.271 12.1655 10.271H10.3701C10.1682 10.271 10.0039 10.4352 10.0039 10.6372V12.4325C10.0039 12.6345 10.1682 12.7988 10.3701 12.7988H12.1655C12.3674 12.7988 12.5317 12.6345 12.5317 12.4325V10.6372ZM12.0922 12.3593H10.4434V10.7104H12.0922V12.3593Z" fill="#395061" />
                                        <path d="M13.3877 12.7987H16.5061C16.6274 12.7987 16.7258 12.7004 16.7258 12.579C16.7258 12.4576 16.6274 12.3593 16.5061 12.3593H13.3877C13.2663 12.3593 13.168 12.4576 13.168 12.579C13.168 12.7004 13.2663 12.7987 13.3877 12.7987Z" fill="#395061" />
                                        <path d="M13.3877 11.9014H16.5061C16.6274 11.9014 16.7258 11.8031 16.7258 11.6817C16.7258 11.5603 16.6274 11.462 16.5061 11.462H13.3877C13.2663 11.462 13.168 11.5603 13.168 11.6817C13.168 11.8031 13.2663 11.9014 13.3877 11.9014Z" fill="#395061" />
                                        <path d="M10.9662 18.0417C11.0185 18.0967 11.0919 18.1282 11.1677 18.1283H11.168C11.2437 18.1283 11.3171 18.0969 11.3693 18.0421L11.7276 17.6668C11.8114 17.579 11.8082 17.44 11.7204 17.3561C11.6326 17.2723 11.4935 17.2755 11.4097 17.3633L11.1682 17.6163L11.1271 17.573C11.0435 17.485 10.9044 17.4815 10.8164 17.565C10.7285 17.6486 10.7249 17.7877 10.8085 17.8757L10.9662 18.0417Z" fill="#395061" />
                                        <path d="M12.1655 16.4479H10.3701C10.1682 16.4479 10.0039 16.6122 10.0039 16.8141V18.6095C10.0039 18.8115 10.1682 18.9757 10.3701 18.9757H12.1655C12.3674 18.9757 12.5317 18.8115 12.5317 18.6095V16.8141C12.5317 16.6122 12.3674 16.4479 12.1655 16.4479ZM12.0922 18.5363H10.4434V16.8874H12.0922V18.5363Z" fill="#395061" />
                                        <path d="M17.1213 18.5363H13.3877C13.2663 18.5363 13.168 18.6346 13.168 18.756C13.168 18.8774 13.2663 18.9757 13.3877 18.9757H17.1213C17.2426 18.9757 17.341 18.8774 17.341 18.756C17.341 18.6346 17.2426 18.5363 17.1213 18.5363Z" fill="#395061" />
                                        <path d="M13.168 17.8586C13.168 17.98 13.2663 18.0784 13.3877 18.0784H17.1213C17.2426 18.0784 17.341 17.98 17.341 17.8586C17.341 17.7373 17.2426 17.6389 17.1213 17.6389H13.3877C13.2663 17.6389 13.168 17.7373 13.168 17.8586Z" fill="#395061" />
                                        <path d="M18.4381 18.7178C18.3167 18.7178 18.2184 18.8161 18.2184 18.9375V20.3241C18.2184 20.4545 18.1123 20.5605 17.9819 20.5605H9.36344C9.23304 20.5605 9.12695 20.4545 9.12695 20.3241V8.53831C9.12695 8.40791 9.23304 8.30183 9.36344 8.30183H11.2205V8.84039C11.2205 9.16386 11.4837 9.427 11.8072 9.427H15.5382C15.8616 9.427 16.1248 9.16386 16.1248 8.84039V8.3018H16.7027C16.824 8.3018 16.9224 8.20345 16.9224 8.08207C16.9224 7.96069 16.824 7.86234 16.7027 7.86234H16.1248V7.46461C16.1248 7.14114 15.8616 6.87797 15.5382 6.87797H14.8029V6.67324C14.8029 6.30199 14.5009 6 14.1297 6H13.2156C12.8444 6 12.5424 6.30202 12.5424 6.67324V6.87794H11.8072C11.4837 6.87794 11.2205 7.14111 11.2205 7.46458V7.86231H9.36344C8.99072 7.86231 8.6875 8.16557 8.6875 8.53825V20.324C8.6875 20.6967 8.99072 20.9999 9.36344 20.9999H17.9819C18.3546 20.9999 18.6578 20.6967 18.6578 20.324V18.9375C18.6578 18.8161 18.5594 18.7178 18.4381 18.7178ZM11.66 7.46461C11.66 7.38343 11.726 7.31742 11.8072 7.31742H12.6501C12.833 7.31742 12.9818 7.16862 12.9818 6.98569V6.67324C12.9818 6.54434 13.0867 6.43945 13.2156 6.43945H14.1297C14.2586 6.43945 14.3635 6.54434 14.3635 6.67324V6.98566C14.3635 7.16859 14.5123 7.31739 14.6952 7.31739H15.5382C15.6193 7.31739 15.6854 7.38343 15.6854 7.46458V8.84033C15.6854 8.92148 15.6193 8.98749 15.5382 8.98749H11.8072C11.726 8.98749 11.66 8.92145 11.66 8.84033V7.46461Z" fill="#395061" />
                                        <path d="M19.8571 9.17047H19.4344V7.51071C19.4344 7.10768 19.1066 6.77982 18.7035 6.77982H18.1801C17.7771 6.77982 17.4492 7.10768 17.4492 7.51071C17.4492 7.51071 17.4494 15.4388 17.4495 15.4403C17.4494 15.4418 17.4492 15.4434 17.4492 15.4449V16.2569C17.4492 16.3976 17.478 16.5343 17.5349 16.663L18.085 17.9086C18.1483 18.052 18.285 18.1412 18.4418 18.1412C18.5986 18.1412 18.7354 18.052 18.7987 17.9086L19.3487 16.663C19.4056 16.5342 19.4344 16.3976 19.4344 16.2569V15.4449C19.4344 15.4433 19.4342 15.4418 19.4342 15.4403C19.4342 15.4388 19.4344 14.0074 19.4344 14.0074C19.4344 13.886 19.3361 13.7876 19.2147 13.7876C19.0934 13.7876 18.995 13.886 18.995 14.0074V15.216H17.8887V8.85055H18.995V13.0933C18.995 13.2147 19.0934 13.313 19.2147 13.313C19.3361 13.313 19.4344 13.2147 19.4344 13.0933V9.60992H19.857C19.8651 9.60992 19.8717 9.61651 19.8717 9.62457V12.0865C19.8717 12.2079 19.9701 12.3062 20.0914 12.3062C20.2128 12.3062 20.3111 12.2079 20.3111 12.0865V9.62457C20.3112 9.37417 20.1075 9.17047 19.8571 9.17047ZM18.995 16.2569C18.995 16.3361 18.9787 16.413 18.9468 16.4855L18.4419 17.6289L17.937 16.4855C17.9049 16.413 17.8887 16.3361 17.8887 16.2569V15.6646H18.995V16.2569H18.995ZM17.8887 8.41109V7.51071C17.8887 7.35002 18.0194 7.21927 18.1801 7.21927H18.7036C18.8643 7.21927 18.995 7.35002 18.995 7.51071V8.41106H17.8887V8.41109Z" fill="#395061" />
                                    </svg>
                                ) : (
                                    <svg width="25" height="25" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="14" cy="14" r="14" fill="#F0E1EB" />
                                        <path d="M19.78 13.134C20.4467 13.5189 20.4467 14.4811 19.78 14.866L11.86 19.4386C11.1934 19.8235 10.36 19.3424 10.36 18.5726L10.36 9.42738C10.36 8.65758 11.1934 8.17646 11.86 8.56136L19.78 13.134Z" fill="#395061" />
                                    </svg>
                                )}
                            </div>

                            <div className="flex-grow-1">
                                <button
                                    onClick={() => handleLessonSelect(lesson, chapterOrder, isPurchase)}
                                    className="p-0 m-0 d-block border-0 bg-transparent"
                                    style={{ fontSize: '0.8rem', textAlign: 'left' }}
                                >
                                    {lesson.lessonName}
                                </button>
                                {lesson.lessonDuration && (
                                    <span style={{ fontSize: '0.6rem', marginTop: '-10px' }}>
                                        {moment.utc(lesson.lessonDuration * 1000).format('HH:mm:ss')}
                                    </span>
                                )}
                            </div>
                            <div>
                                {isPurchase ? (
                                    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.1661 6.66868H4.83402V5.83548C4.8334 3.53466 6.69808 1.66892 8.99891 1.6683C10.5149 1.66787 11.9114 2.49099 12.6453 3.81747C12.8688 4.21987 13.3761 4.365 13.7785 4.1416C14.1809 3.91819 14.326 3.41085 14.1026 3.00845C12.5424 0.190396 8.99301 -0.829256 6.17492 0.731017C4.31859 1.75879 3.16689 3.71365 3.16759 5.83552V7.02202C1.65118 7.68384 0.670156 9.18025 0.667969 10.8348V15.834C0.670742 18.1337 2.53433 19.9973 4.83402 20.0001H13.1661C15.4658 19.9973 17.3294 18.1337 17.3321 15.834V10.8348C17.3294 8.535 15.4658 6.67141 13.1661 6.66868ZM9.83327 14.1676C9.83327 14.6277 9.46024 15.0007 9.00008 15.0007C8.53991 15.0007 8.16688 14.6277 8.16688 14.1676V12.5011C8.16688 12.041 8.53991 11.6679 9.00008 11.6679C9.46024 11.6679 9.83327 12.041 9.83327 12.5011V14.1676Z" fill="#395061" />
                                    </svg>
                                ) : (
                                    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.8334 7.02V5.83332C14.8334 2.61168 12.2218 0 9.00007 0C5.77839 0 3.16675 2.61168 3.16675 5.83332V7.02C1.65011 7.68191 0.668936 9.17855 0.666748 10.8333V15.8333C0.669482 18.1334 2.53335 19.9973 4.83339 20H13.1667C15.4668 19.9973 17.3306 18.1334 17.3334 15.8333V10.8333C17.3312 9.17855 16.35 7.68191 14.8334 7.02ZM9.83339 14.1667C9.83339 14.6269 9.46031 15 9.00007 15C8.53984 15 8.16675 14.6269 8.16675 14.1667V12.5C8.16675 12.0398 8.53984 11.6667 9.00007 11.6667C9.46031 11.6667 9.83339 12.0398 9.83339 12.5V14.1667ZM13.1668 6.66668H4.83339V5.83336C4.83339 3.53219 6.69886 1.66668 9.00007 1.66668C11.3013 1.66668 13.1668 3.53215 13.1668 5.83336V6.66668Z" fill="#395061" />
                                    </svg>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}


export default CoursesAccordion;
