import { useState, useEffect } from 'react'
import { MdLogin } from 'react-icons/md'
import Modal from 'react-bootstrap/Modal';
import { Container } from 'react-bootstrap';
import { getAuth, signInWithPhoneNumber, RecaptchaVerifier } from 'firebase/auth';
import PhoneInput from 'react-phone-input-2'
import VerifyMobileOtpLogin from './VerifyMobileOtpLogin';
import swal from 'sweetalert';
import { API_CONSTANTS, API_METHOD } from '../../common/constants';
import { API_CALL } from '../../api/ApiServiceCall';
import { useRecoilState } from 'recoil';
import { mobileLoginModalComponentState } from './../atoms/mobileOtpLoginModalComponent';

function LoginWithMobileOtp() {
    const [modalShow, setModalShow] = useState(false);
    const [mobileNumber, setMobileNumber] = useState("");
    const [error, setError] = useState("");
    const [mobLength, setRequiredMobLength] = useState("");
    const [loading, setLoading] = useState(false);
    const [MobileLoginModalComponentAtom, setMobileLoginModalComponentAtom] = useRecoilState(mobileLoginModalComponentState);

    const handleMobileLogin = () => {
        setMobileLoginModalComponentAtom("")
        setRequiredMobLength("");
        setMobileNumber("");
        setError("")
        setLoading(false)
        setModalShow(true);
    }

    const auth = getAuth();

    const generateRecap = () => {
        window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible',
            'callback': (response) => {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
            }
        }, auth);
    }

    const getAccountDetailByMobileNum = async () => {
        const requestBodyParam = JSON.stringify({
            mobileNumber: "+91" + mobileNumber,
            userType: "STUDENT"
        });

        const request = {
            apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.GET_ACCOUNT_DETAILS_BY_MOBILE_NUM,
            method: API_METHOD.POST,
            requestBodyParams: requestBodyParam,
        }
        let result = await API_CALL(request)
        if (result.status === 200) {
            return result;
        } else if (result.status > 200) {
            setLoading(false);
            swal({
                title: "ERROR",
                text: result.message,
                icon: 'https://cdn-icons-png.flaticon.com/512/2569/2569174.png',
                className: "error-window",
                button: true,
            })
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let isRegistered = false;
        if (mobileNumber && mobLength == mobileNumber.length) {
            setLoading(true)
            isRegistered = await getAccountDetailByMobileNum();
            if (!isRegistered?.data?.length) {
                setError("Please register this number");
                isRegistered = false;
                setLoading(false)
            } else {
                isRegistered = true;
            }
        }
        if (mobileNumber && mobLength == mobileNumber.length && isRegistered) {
            setLoading(true)
            generateRecap();
            const mobNo = "+91" + mobileNumber;
            const appVerifier = window.recaptchaVerifier;
            signInWithPhoneNumber(auth, mobNo, appVerifier).then((confirmationResult) => {
                // SMS sent. Prompt user to type the code from the message, then sign the
                // user in with confirmationResult.confirm(code).
                window.confirmationResult = confirmationResult;
                // ... 
                setLoading(false)
                setMobileLoginModalComponentAtom(<VerifyMobileOtpLogin closeModal={setModalShow} mobileNumber={mobileNumber} />)
            }).catch((error) => {
                setError("Request failed, please try again");
                setLoading(false);
            });
        }
    }
    
    const handleModalClose = () => {
        const recaptchaElement = document.getElementById("div-recaptcha-container");
        console.log("MODAL CLOSE CLICKED", recaptchaElement);
        setModalShow(false);
        recaptchaElement.innerHTML = `<div id="recaptcha-container"></div>`;
    }

    return (
      <div>
        <div id="div-recaptcha-container">
          <div id="recaptcha-container"></div>
        </div>

        <button
          type="button"
          role="buttons"
          className="glogin-form-btn border-0"
          style={{ background: "#f3f3f3" }}
          onClick={handleMobileLogin}
          disabled={!"loadingGoogle" ? true : false}
        >
          {!"loadingGoogle" ? (
            "Please wait..."
          ) : (
            <>
              {" "}
              <MdLogin /> Log in With Mobile
            </>
          )}
        </button>
        <Container>
          <Modal
            className="mh"
            show={modalShow}
            backdrop="static"
            onHide={() => handleModalClose()}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Mobile number login
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* <p>Mobile Number: {mobileNumber}</p> */}
              {MobileLoginModalComponentAtom ? (
                MobileLoginModalComponentAtom
              ) : (
                <form className="login-col" onSubmit={handleSubmit}>
                  <label className="fz-15px text-prime">
                    Enter the mobile number
                  </label>
                  {/* <PhoneInput
                                    country='in'
                                    countryCodeEditable={false}
                                    buttonClass="border-0 ps-1"
                                    className="ph-frm border-0"
                                    placeholder='Enter Mobile Number'
                                    value={mobileNumber}
                                    // onChange={(mbNum) => setMobileNumber(mbNum)}
                                    inputProps={{
                                        name: 'phone',
                                        required: true,
                                        autoFocus: false,
                                        required: true,
                                    }}
                                    onChange={(inputPhone, countryData) => {
                                        // console.log("req", countryData.format.length, "===>currentLen", inputPhone.length, "num", inputPhone)
                                        setRequiredMobLength(countryData.format.split(".").length - 1)
                                        setMobileNumber(inputPhone)
                                    }
                                    }
                                /> */}
                  <input
                    type="tel"
                    className="ph-frm border-0"
                    placeholder="Enter Mobile Number"
                    value={mobileNumber}
                    onChange={(e) => {
                      const MobileNoInput = e.target.value;
                      setRequiredMobLength(MobileNoInput.length);
                      setMobileNumber(MobileNoInput);
                    }}
                    disabled={loading ? true : false}
                    required
                  />
                  {mobLength == mobileNumber.length ? (
                    ""
                  ) : (
                    <small className="text-danger fw-500 pt-2 d-block">
                      Invalid
                    </small>
                  )}
                  {error && (
                    <small className="text-danger fw-500 pt-2 d-block">
                      {error}
                    </small>
                  )}
                  <div className="py-2"></div>
                  <button
                    className="login-form-btn"
                    type="submit"
                    disabled={loading ? true : false}
                  >
                    {loading ? "Please wait..." : "Submit"}
                  </button>
                </form>
              )}
            </Modal.Body>
          </Modal>
        </Container>
      </div>
    );
}

export default LoginWithMobileOtp