import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import swal from "sweetalert";
import { API_CALL } from "../../../../../../api/ApiServiceCall";
import { API_CONSTANTS, API_METHOD } from "../../../../../../common/constants";
import { clickedChapterDataListAtomFunc } from "../../../../../atom/clickedChapterDataListAtomFunc";
import { currentViewComponentLsnAssessAtomFunc } from "../../../../../atom/lsnChapAssessComponentRender";
import LeftArrow from "../../../../../svg-icons/leftArrow";
import AssessmentCreationForm from "./assessment-creation-form/assessmentCreationForm";
import AssessmentList from "./assessment-list/assessmentList";
import LessonCreationForm from "./lesson-creation/lessonCreationForm";
import LessonList from "./lesson-list/lessonList";

function SingleChapterView({ clickedChapterData }) {
  const [
    currentViewComponentLsnAssessAtom,
    setCurrentViewComponentLsnAssessAtom,
  ] = useRecoilState(currentViewComponentLsnAssessAtomFunc);
  const [clickedChapterDataListAtom, setClickedChapterDataListAtom] =
    useRecoilState(clickedChapterDataListAtomFunc);
  const [isCreateLessonActive, setisCreateLessonActive] = useState(false);
  const [clickedChapterDetailFromApiCall, setClickedChapterDetailFromApiCall] =
    useState("");
  useEffect(() => {
    getLesson();
    getChapterByOrder();
  }, []);
  // GET_LESSONS_and_ASSESSMENT_BY_COURSE
  const getLesson = async () => {
    const userCode = JSON.parse(localStorage.getItem("userCode"));
    const request = {
      apiUrl:
        API_CONSTANTS.BASE_URL +
        API_CONSTANTS.GET_LESSONS_BY_COURSE +
        `/?courseCode=${userCode?.data}&chapterOrder=${
          clickedChapterData.chapterOrder ||
          clickedChapterDataListAtom.data.chapterOrder
        }`,
      method: API_METHOD.GET,
    };
    let result = await API_CALL(request);

    if (result.status === 200) {
      setClickedChapterDataListAtom(result);
    } else if (result.status > 200) {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }
  };
  const getChapterByOrder = async () => {
    const userCode = JSON.parse(localStorage.getItem("userCode"));
    const request = {
      apiUrl:
        API_CONSTANTS.BASE_URL +
        API_CONSTANTS.GET_CHAPTER_BY_CHAPTER_ORDER +
        `/?courseCode=${userCode?.data}&chapterOrder=${
          clickedChapterData.chapterOrder ||
          clickedChapterDataListAtom.data.chapterOrder
        }`,
      method: API_METHOD.GET,
    };
    let result = await API_CALL(request);

    if (result.status === 200) {
      setClickedChapterDetailFromApiCall(result);
    } else if (result.status > 200) {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }
  };

  return (
    <div>
      <div
        role="button"
        className="d-inline-flex align-items-center mb-2"
        onClick={() => setCurrentViewComponentLsnAssessAtom(null)}
      >
        <LeftArrow size={8} />
        <small className="fz-16px fw-500  ms-2">
          {" "}
          {clickedChapterDetailFromApiCall
            ? clickedChapterDetailFromApiCall.data.chapterList[0].chapterName
            : "Loading..."}
        </small>
      </div>
      {/* ------------  Props <pre>{JSON.stringify(clickedChapterData,null,1)}</pre>-------------------- */}

      {clickedChapterDataListAtom ? (
        clickedChapterDataListAtom.data.lessonList.map(
          (singleLessonDataItems, index) =>
            singleLessonDataItems.isAssesment ? (
              <AssessmentList
                key={singleLessonDataItems._id + "assessment" + index}
                assessmentListData={singleLessonDataItems}
              />
            ) : (
              <LessonList
                key={singleLessonDataItems._id}
                lessonListData={singleLessonDataItems}
              />
            )
        )
      ) : (
        <>
          <span
            className="spinner-border  d-block text-secondary spinner-border-sm "
            role="status"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Loading...</span>
        </>
      )}

      {/* <pre> {JSON.stringify(clickedChapterDataListAtom, null, 4)}</pre> */}
      <div className="d-flex justify-content-end align-items-center mt-3 mb-1">
        <button
          disabled={
            clickedChapterDataListAtom?.data?.courseStatus == "INREVIEW"
              ? true
              : false
          }
          className="border-0 text-dark bg-transparent   fz-14px   w-25 rounded-3 text-center"
          role="button"
          onClick={() =>
            setCurrentViewComponentLsnAssessAtom(<AssessmentCreationForm />)
          }
        >
          + Create Assessment
        </button>
        <button
          disabled={
            clickedChapterDataListAtom?.data?.courseStatus == "INREVIEW"
              ? true
              : false
          }
          className="border-0  bg-prime text-white fz-14px py-2 w-25 rounded-3 text-center"
          role="button"
          onClick={() =>
            setCurrentViewComponentLsnAssessAtom(<LessonCreationForm />)
          }
        >
          + Create New Lesson
        </button>
      </div>
    </div>
  );
}

export default SingleChapterView;
