import React, { useEffect, useRef, useState } from 'react'
import { FiTrash2 } from 'react-icons/fi'
import { MdModeEdit } from 'react-icons/md'
import { RiNewspaperLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import swal from 'sweetalert'
import { API_CALL } from '../../../../api/ApiServiceCall'
import { API_CONSTANTS, API_METHOD } from '../../../../common/constants'
import InstructorLoader from '../../../../common/InstructorLoader'
import Paginate from '../../../../common/Pagination'
import IndependentAssessmentIcon from '../../../svg-icons/independentAssessmentIcon'
import TicketAndInreviewAssessment from './ticket/TicketAndInreviewAssessment'
import socket from '../../../../common/Socket'

export const IndependentAssessment = () => {
    const [SECONDS] = useState(5000);
    const [independentAssessmentList, setIndependentAssessmentList] = useState(null);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [itemOffset, setItemOffset] = useState(0);
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(null);
    const assessmentListRef = useRef(null)

    useEffect(() => {
        getAllIndependentAssessmentList();
        localStorage.removeItem("independentAssessmentCode");
        const interval = setInterval(() => {
            newMessageCount(assessmentListRef.current);
        }, SECONDS);
        socket.open();
        socket.on("connection-success", async (response) => {
            // console.log(`Socket connected ${response.socketId}`);
        });
        return () => {
            socket.close();
            socket.disconnect();
            socket.off("newMessageCount")
            clearInterval(interval);
            socket.on("disconnect", function () { });
        }
    }, [])

    const newMessageCount = (data) => {
        socket.emit("newMessageCount", { ticketType: "ASSESSMENT" }, async (response) => {

            let apiArry = data;
            let socketArry = response;
            let combinedArry = apiArry.map(obj1 => {
                let obj2 = socketArry.find(obj2 => obj1.assessmentCode === obj2.courseCode);
                return Object.assign({}, obj1, obj2);
            });
            setIndependentAssessmentList(combinedArry)
        });
    }
    const getAllIndependentAssessmentList = async () => {
        const request = {
            apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.GET_ASSESSMENT_BY_EMAIL,
            method: API_METHOD.GET,
        }
        let result = await API_CALL(request)
        if (result.status === 200) {
            assessmentListRef.current = result.data
            setIndependentAssessmentList(result.data)
            newMessageCount(result.data);

        } else if (result.status > 200) {
            swal({
                title: "ERROR",
                text: result.message,
                icon: 'https://cdn-icons-png.flaticon.com/512/2569/2569174.png',
                className: "error-window",
                button: true,
            })

        }
    }
    const clickedAssessmentCode = (assessmentCode) => {
        localStorage.setItem("independentAssessmentCode", assessmentCode)
    }

    const onDeleteAssessment = async (assessmentCode) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this assessment!",
            icon: 'https://cdn-icons-png.flaticon.com/512/6460/6460112.png',
            imageClass: 'img-warning',
            buttons: true,
            className: "swal-center",
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                const requestBodyParam = JSON.stringify({
                    'assessmentCode': assessmentCode,
                })
                const request = {
                    apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.DELETE_INDEP_ASSESMENT_BY_ASSESSMENT_CODE,
                    method: API_METHOD.POST,
                    requestBodyParams: requestBodyParam,
                }
                let result = await API_CALL(request)
                if (result.status === 200) {
                    getAllIndependentAssessmentList()
                } else if (result.status > 200) {
                    swal({
                        title: "ERROR",
                        text: result.message,
                        icon: 'https://cdn-icons-png.flaticon.com/512/2569/2569174.png',
                        className: "error-window",
                        button: true,
                    })
                }

            }
        });
    }
    const statusCodes = ["INREVIEW", "BANNED", "ACTIVE"]
    // ----pagination start----------
    useEffect(() => {
        if (independentAssessmentList) {
            // console.log(independentAssessmentList)
            const endOffset = itemOffset + Number(itemsPerPage);
            let sliceItem = independentAssessmentList.slice(itemOffset, endOffset);
            setCurrentItems(sliceItem)
            let pageCountResult = Math.ceil(independentAssessmentList.length / itemsPerPage);
            setPageCount(pageCountResult)
        }
    }, [independentAssessmentList, itemOffset, itemsPerPage])

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % independentAssessmentList.length;
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
    };
    return (
        <div className='mt-5 px-4'>
            <div className="d-flex justify-content-end mx-1 mb-3">
                <Link to="/courses/live/create-assessment" ><button className=' border-0 bg-prime text-white fz-14px py-26px rounded-9 px-4'>+ Create New Assessment</button></Link>
            </div>
            {/* <pre>{JSON.stringify(independentAssessmentList, null, 4)}</pre> */}
            {!currentItems ? <InstructorLoader /> : currentItems.length ? currentItems?.map((assessmentData) => (
                <div key={assessmentData._id}>
                    <div className="bg-white overview-crds p-4  mb-4  mx-1 rounded-9">
                        <div className="row">
                            <div className="col-lg-2  m-auto text-center">
                                <IndependentAssessmentIcon />
                            </div>
                            <div className="col-lg-6 m-auto">
                                <h5 className='fw-600 fz-17px'>{assessmentData.assessmentTitle}</h5>
                                <div className="d-flex align-items-center mt-2 ">
                                    {assessmentData?.currency && assessmentData?.fee ? <h6 className='me-lg-4 fw-600 fz-16px'>{new Intl.NumberFormat('en-IN', { style: 'currency', currency: assessmentData?.currency, minimumFractionDigits: 0, maximumFractionDigits: 0, }).format(assessmentData?.fee)} </h6> : <h6 className='me-lg-4 fw-600 fz-16px '>{assessmentData?.currency} {assessmentData?.fee} </h6>}

                                </div>
                                <span className={assessmentData.assessmentDetails.length ? "fz-13px fw-500 d-inline-block text-prime" : "fz-13px fw-500 d-inline-block text-danger"}><RiNewspaperLine size={18} color={assessmentData.assessmentDetails.length ? "#5e5e5e" : ""} className=' me-lg-1' />{assessmentData.assessmentDetails.length} {assessmentData.assessmentDetails.length <= 1 ? "Question" : "Questions"} </span>

                            </div>
                            <div className="col  m-auto">

                                {statusCodes.includes(assessmentData?.assessmentStatus) ?
                                    <TicketAndInreviewAssessment clickedAssessmentCode={clickedAssessmentCode} assessmentData={assessmentData} />
                                    : <div className='text-end'>
                                        <Link to={"/courses/live/edit-assessment"} onClick={() => clickedAssessmentCode(assessmentData?.assessmentCode)}><p className='fw-500 text-prime' role="button"> <MdModeEdit className='mx-1' size={21} />Edit</p></Link>
                                        <p className='del fw-500' role="button" onClick={() => onDeleteAssessment(assessmentData?.assessmentCode)}><FiTrash2 className='mx-1' />Delete</p>
                                    </div>
                                }
                            </div>


                        </div>

                    </div>


                </div>)) : <div className='d-flex align-items-center justify-content-center py-5 my-5pa'><h6 className={`text-muted fz-19px text-center`} > No assessments created</h6></div>}
            {currentItems?.length ?
                <div className="row mx-1">
                    <div className="col-lg-8">
                        <Paginate onPageChange={handlePageClick} pageCount={pageCount} isShow={currentItems?.length} />
                    </div>
                    <div className="col-lg-4 fz-13px fw-500 text-muted text-end">
                        Show  <select className='px-2 py-1 ' onChange={(e) => setItemsPerPage(e.target.value)}>
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={75}>75</option>
                            <option value={100}>100</option>
                        </select> assessment per page
                    </div>
                </div> : ""}

        </div>

    )
}
