import { useRef } from "react";
import { Carousel } from "react-bootstrap";
import successimage1 from "../../images/teacher/slider.png";

function Learnerscarousel() {
  const slideControl = useRef(null);
  const onPrevClick = () => slideControl.current.prev();
  const onNextClick = () => slideControl.current.next();

  return (
    <section className="mt-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <h3 className="our-xp">Listen From Our Learners!</h3>
            <div className="py-3"></div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-5">
            <div className="bg-abstract">
              <img src={successimage1} alt="" />
            </div>
          </div>
          <div className="col align-self-end  p-0">
            <Carousel
              fade
              indicators={false}
              controls={false}
              ref={slideControl}
              interval={3000000}
            >
              <Carousel.Item className="cst-p">
                <div className="slider-card">
                  <p className="slider-para">
                    "I've tried various e-learning platforms, but Qlearning
                    stands out from the rest. The quality of the courses is
                    exceptional, and the interactive learning experience keeps
                    me engaged throughout. I highly recommend it to anyone
                    looking to upgrade their knowledge."
                  </p>
                  <p className="author">- Sarah M</p>
                </div>
              </Carousel.Item>
              <Carousel.Item className="cst-p">
                <div className="slider-card">
                  <p className="slider-para">
                    "QLearning's immersive workshops empower budding writers,
                    creating a supportive space for growth and confidence in
                    creative writing."
                  </p>
                  <p className="author">- Emily Chen</p>
                </div>
              </Carousel.Item>
              <Carousel.Item className="cst-p">
                <div className="slider-card">
                  <p className="slider-para">
                    "Teaching languages here is a joy. QLearning's interactive
                    language labs and cultural modules redefine language
                    acquisition."
                  </p>
                  <p className="author">- Maria Hernandez</p>
                </div>
              </Carousel.Item>
            </Carousel>
            <div className="controll-container">
              <div className="slide-prev" onClick={onPrevClick}>
                <svg
                  className="size-18px"
                  viewBox="0 0 43 31"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.42435 14.1744L14.5494 1.04941C14.903 0.707863 15.3766 0.518872 15.8682 0.523144C16.3599 0.527416 16.8301 0.724608 17.1778 1.07225C17.5254 1.41989 17.7226 1.89016 17.7269 2.38178C17.7311 2.8734 17.5422 3.34703 17.2006 3.70066L7.27623 13.625L40.25 13.625C40.7473 13.625 41.2242 13.8226 41.5758 14.1742C41.9275 14.5258 42.125 15.0027 42.125 15.5C42.125 15.9973 41.9275 16.4742 41.5758 16.8258C41.2242 17.1775 40.7473 17.375 40.25 17.375L7.27623 17.375L17.2006 27.2994C17.3797 27.4723 17.5225 27.6792 17.6208 27.908C17.7191 28.1368 17.7708 28.3828 17.773 28.6318C17.7751 28.8807 17.7277 29.1276 17.6334 29.358C17.5391 29.5885 17.3999 29.7978 17.2239 29.9739C17.0478 30.1499 16.8385 30.2892 16.608 30.3834C16.3776 30.4777 16.1307 30.5251 15.8817 30.523C15.6328 30.5208 15.3867 30.4691 15.158 30.3708C14.9292 30.2726 14.7223 30.1297 14.5494 29.9506L1.42435 16.8256C1.07284 16.474 0.875379 15.9972 0.875379 15.5C0.875379 15.0028 1.07284 14.526 1.42435 14.1744Z"
                    fill="#8C8C8C"
                  />
                </svg>
              </div>
              <div className="slide-next" onClick={onNextClick}>
                <svg
                  className="size-18px"
                  viewBox="0 0 43 31"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M41.5757 16.8256L28.4506 29.9506C28.097 30.2921 27.6234 30.4811 27.1318 30.4769C26.6401 30.4726 26.1699 30.2754 25.8222 29.9278C25.4746 29.5801 25.2774 29.1098 25.2731 28.6182C25.2689 28.1266 25.4578 27.653 25.7994 27.2993L35.7238 17.375H2.75C2.25272 17.375 1.77581 17.1774 1.42418 16.8258C1.07254 16.4742 0.875 15.9973 0.875 15.5C0.875 15.0027 1.07254 14.5258 1.42418 14.1742C1.77581 13.8225 2.25272 13.625 2.75 13.625H35.7238L25.7994 3.70062C25.6203 3.52765 25.4775 3.32076 25.3792 3.092C25.2809 2.86324 25.2292 2.6172 25.227 2.36824C25.2249 2.11928 25.2723 1.87238 25.3666 1.64195C25.4609 1.41152 25.6001 1.20218 25.7762 1.02613C25.9522 0.850078 26.1615 0.710851 26.392 0.616575C26.6224 0.522298 26.8693 0.47486 27.1183 0.477024C27.3672 0.479187 27.6133 0.530911 27.842 0.629177C28.0708 0.727444 28.2777 0.870284 28.4506 1.04937L41.5757 14.1744C41.9272 14.526 42.1246 15.0028 42.1246 15.5C42.1246 15.9972 41.9272 16.474 41.5757 16.8256Z"
                    fill="#395061"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Learnerscarousel;
