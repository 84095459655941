import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import swal from "sweetalert";
import { API_CALL } from "../../api/ApiServiceCall";
import { API_CONSTANTS, API_METHOD } from "../../common/constants";
import CoursesList from "../common/CoursesList";
import PurchasedCourseCard from "./PurchasedCourseCard";

function PurchasedCoursesList({ currentItems }) {
  return (
    <>
      {currentItems?.length > 0 ? (
        currentItems?.map((course, id) => {
          return (
            <PurchasedCourseCard
              key={course._id}
              currentItem={currentItems}
              value={id}
              course={course}
            />
          );
        })
      ) : (
        <p>No Courses.</p>
      )}
    </>
  );
}

// { courses }
// { itemsPerPage }
function PurchasedCourses() {
  const [purchasedCourses, setPurchasedCourses] = useState({
    loading: true,
    error: null,
    data: [],
  });

  const [topCourses, setTopCourses] = useState({
    loading: true,
    courses: [],
    error: null,
  });

  const [recommendedCourses, setRecommendedCourses] = useState({
    loading: true,
    data: [],
    error: null,
  });

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [itemOffset, setItemOffset] = useState(0);
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(null);

  const getCourses = async () => {
    // PURCHASED COURSES
    setPurchasedCourses({ loading: true, data: [], error: null });

    const request = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.PURCHASED_COURSE,
      method: API_METHOD.GET,
    };

    let result = await API_CALL(request);

    if (result.status === 200) {
      const purchasedCourses = result?.data?.filter(
        (course) => course.courseCode
      );
      setPurchasedCourses({
        loading: false,
        data: purchasedCourses ? purchasedCourses : [],
        error: null,
      });
    } else if (result.status > 200) {
      setPurchasedCourses({ loading: false, data: [], error: result.message });
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }

    // TOP COURSES
    setTopCourses({ loading: true, courses: [], error: null });

    const topCoursesRequest = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.TOP_COURSES,
      method: API_METHOD.GET,
    };

    let topCoursesResult = await API_CALL(topCoursesRequest);

    if (topCoursesResult.status === 200) {
      setTopCourses({
        loading: false,
        courses: topCoursesResult.data ? topCoursesResult.data : [],
        error: null,
      });
    } else if (topCoursesResult.status > 200) {
      setTopCourses({
        loading: false,
        courses: [],
        error: topCoursesResult.message,
      });

      swal({
        title: "ERROR",
        text: topCoursesResult.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }

    // RECOMENDED COURSES
    setRecommendedCourses({ loading: true, data: [], error: null });

    const recommendedRequest = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.RECOMMENDED_FOR_YOU,
      method: API_METHOD.GET,
    };

    let recommendedResult = await API_CALL(recommendedRequest);

    if (recommendedResult.status === 200) {
      setRecommendedCourses({
        loading: false,
        data: recommendedResult.data ? recommendedResult.data : [],
        error: null,
      });
    } else if (recommendedResult.status > 200) {
      setRecommendedCourses({
        loading: false,
        data: [],
        error: recommendedResult.message,
      });

      if (recommendedResult.message == "Token is Not Valid") return null;

      swal({
        title: "ERROR",
        text: recommendedResult.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }
  };

  useEffect(() => {
    getCourses();
  }, []);

  useEffect(() => {
    if (purchasedCourses?.data?.length) {
      const endOffset = itemOffset + Number(itemsPerPage);
      let sliceItem = purchasedCourses?.data?.slice(itemOffset, endOffset);
      setCurrentItems(sliceItem);
      let pageCountResult = Math.ceil(
        purchasedCourses?.data?.length / itemsPerPage
      );
      setPageCount(pageCountResult);
    }
  }, [purchasedCourses?.data?.length, itemOffset, itemsPerPage]);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % purchasedCourses?.data?.length;
    setItemOffset(newOffset);
  };

  return (
    <div>
      {purchasedCourses.loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "300px" }}
        >
          <div class="spinner-border" role="status">
            <span class="sr-only"></span>
          </div>
        </div>
      ) : currentItems ? (
        <PurchasedCoursesList currentItems={currentItems} />
      ) : (
        <p>No Courses.</p>
      )}

      {purchasedCourses.data?.length > 0 && (
        <div className="mt-8 d-flex justify-content-between align-items-center">
          <ReactPaginate
            breakLabel="..."
            nextLabel="Next ->"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="<- Previous"
            renderOnZeroPageCount={null}
            containerClassName="pagination d-inline-flex prevent-text-select"
            activeLinkClassName="pagination-activeLink prevent-text-select"
            previousLinkClassName="pagination-container pagination-previous-btn prevent-text-select"
            pageLinkClassName="pagination-container prevent-text-select"
            nextLinkClassName="pagination-container pagination-next-btn prevent-text-select"
            disabledClassName="pagination_link_disabled"
          />

          <div className="col-lg-4 fz-13px fw-500 text-muted text-end">
            Show{" "}
            <select
              className="px-2 py-1 "
              onChange={(e) => {
                setItemsPerPage(e.target.value);
              }}
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={75}>75</option>
              <option value={100}>100</option>
            </select>{" "}
            courses per page
          </div>
        </div>
      )}

      {recommendedCourses.data?.length > 0 && (
        <CoursesList
          title="Recommended For You"
          data={{ courses: recommendedCourses.data }}
          currentItem={currentItems}
        />
      )}

      {topCourses.data?.length > 0 && (
        <CoursesList title="Top Courses" data={topCourses} />
      )}

      {/* <p>{JSON.stringify(recommendedCourses)}</p> */}
    </div>
  );
}

export default PurchasedCourses;
