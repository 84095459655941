import React from "react";

function NotificationPopupIcon() {
  return (
    <svg
      width="45"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="50" height="50" rx="10" fill="#F0E1EB" />
      <path
        d="M36.7138 28.8792L34.0055 19.1178C33.4271 17.0297 32.167 15.195 30.4256 13.9058C28.6841 12.6166 26.5615 11.9469 24.3956 12.0033C22.2296 12.0597 20.1447 12.839 18.4728 14.2171C16.8008 15.5953 15.6379 17.493 15.169 19.6084L13.0742 29.0355C12.9729 29.4922 12.9753 29.9658 13.0815 30.4215C13.1876 30.8771 13.3947 31.3031 13.6874 31.668C13.9801 32.0329 14.3511 32.3275 14.7728 32.5299C15.1946 32.7323 15.6564 32.8375 16.1242 32.8376H19.8815C20.1206 34.015 20.7594 35.0735 21.6896 35.8338C22.6198 36.5941 23.7843 37.0095 24.9857 37.0095C26.1871 37.0095 27.3516 36.5941 28.2818 35.8338C29.212 35.0735 29.8508 34.015 30.0899 32.8376H33.7044C34.1858 32.8374 34.6606 32.7259 35.0918 32.512C35.523 32.2981 35.899 31.9874 36.1904 31.6043C36.4818 31.2211 36.6807 30.7759 36.7717 30.3032C36.8627 29.8305 36.8432 29.3432 36.7149 28.8792H36.7138ZM24.9857 34.9209C24.3417 34.9182 23.7142 34.7167 23.1891 34.3437C22.6641 33.9708 22.267 33.4448 22.0524 32.8376H27.919C27.7044 33.4448 27.3073 33.9708 26.7822 34.3437C26.2572 34.7167 25.6297 34.9182 24.9857 34.9209ZM34.5326 30.3428C34.4354 30.4716 34.3094 30.5759 34.1647 30.6474C34.02 30.7188 33.8606 30.7554 33.6992 30.7542H16.1242C15.9683 30.7542 15.8143 30.7192 15.6737 30.6517C15.533 30.5842 15.4094 30.486 15.3118 30.3643C15.2142 30.2426 15.1452 30.1006 15.1098 29.9486C15.0745 29.7967 15.0737 29.6388 15.1076 29.4865L17.2024 20.0595C17.5715 18.3993 18.485 16.9101 19.7976 15.8287C21.1103 14.7473 22.7468 14.1357 24.4469 14.0911C26.1471 14.0466 27.8133 14.5717 29.1808 15.5829C30.5483 16.5941 31.5385 18.0334 31.994 19.672L34.7024 29.4334C34.7466 29.5878 34.7543 29.7503 34.7248 29.9081C34.6953 30.066 34.6295 30.2148 34.5326 30.3428Z"
        fill="#395061"
      />
    </svg>
  );
}

export default NotificationPopupIcon;
