import { useContext, useEffect, useState } from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { BsArrowLeftCircleFill, BsArrowRightCircleFill } from "react-icons/bs";
import { useRecoilState, useSetRecoilState } from "recoil";
import swal from "sweetalert";
import { API_CALL } from "./../../api/ApiServiceCall";
import { API_CONSTANTS, API_METHOD } from "./../../common/constants";
import {
  filterState,
  selectedCategoryCodeState,
} from "./../atoms/course-list-atom";
import "./courses-menu.css";

function CoursesMenu({ setSelectedCategoryName }) {
  const [categories, setCategories] = useState({
    loading: false,
    error: null,
    data: [],
  });

  const setFilter = useSetRecoilState(filterState);

  const [selectedCategoryCode, setSelectedCategoryCode] = useRecoilState(
    selectedCategoryCodeState
  );

  const getCategories = async () => {
    setCategories({ loading: true, courses: [], error: null });

    const request = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.GET_ALL_CATEGORY,
      method: API_METHOD.GET,
    };

    let result = await API_CALL(request);

    if (result.status === 200) {
      const data = result.data;
      setCategories({ loading: false, data: data, error: null });
    } else if (result.status > 200) {
      setCategories({ loading: false, data: [], error: result.message });

      if (result.message == "Token is Not Valid") return null;

      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  const handleCategorySelect = (categoryCode) => {
    setSelectedCategoryCode(categoryCode);

    setFilter((prevData) => ({
      ...prevData,
      selectedSubCategories: [],
    }));

    const updatedData = categories.data.map((category) => {
      return category.categoryCode === categoryCode
        ? {
            ...category,
            active: true,
          }
        : {
            ...category,
            active: false,
          };
    });

    setCategories({
      ...categories,
      data: updatedData,
    });
  };

  return (
    <div>
      {categories.loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100px" }}
        >
          <div class="spinner-border" role="status">
            <span class="sr-only"></span>
          </div>
        </div>
      ) : (
        categories.data.length > 0 && (
          <ScrollMenu
            LeftArrow={LeftArrow}
            RightArrow={RightArrow}
            scrollContainerClassName="scroll-container p-4 rounded-4 menu-scroll-container"
          >
            {categories.data.map((category) => {
              if (selectedCategoryCode === category.categoryCode) {
                setSelectedCategoryName(category.categoryName);
              }

              return (
                <button
                  key={category._id}
                  className={`btn px-4 rounded-3 border-0 me-3 ${
                    category.active ||
                    selectedCategoryCode === category.categoryCode
                      ? "category-btn-active"
                      : "bg-white"
                  }`}
                  onClick={() => handleCategorySelect(category.categoryCode)}
                  style={{ whiteSpace: "nowrap" }}
                >
                  {category.categoryName}
                </button>
              );
            })}
          </ScrollMenu>
        )
      )}
    </div>
  );
}

function LeftArrow() {
  const { isFirstItemVisible, scrollPrev } = useContext(VisibilityContext);

  return (
    <div
      className="d-flex align-items-center"
      style={{ visibility: isFirstItemVisible ? "hidden" : "visible" }}
    >
      <BsArrowLeftCircleFill
        style={{
          width: "45px",
          height: "45px",
          marginRight: "-20px",
          zIndex: "333",
          color: "rgba(57, 80, 97, 0.6)",
          cursor: "pointer",
        }}
        onClick={() => scrollPrev()}
      />
    </div>
  );
}

function RightArrow() {
  const { isLastItemVisible, scrollNext } = useContext(VisibilityContext);

  return (
    <div
      className="d-flex align-items-center courses-menu"
      style={{ visibility: isLastItemVisible ? "hidden" : "visible" }}
    >
      <BsArrowRightCircleFill
        style={{
          width: "45px",
          height: "45px",
          marginLeft: "-25px",
          zIndex: "333",
          color: "rgba(57, 80, 97, 0.6)",
          cursor: "pointer",
        }}
        onClick={() => scrollNext()}
      />
    </div>
  );
}

export default CoursesMenu;
