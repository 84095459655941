import moment from "moment";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { API_CALL } from "../../../api/ApiServiceCall";
import { API_CONSTANTS, API_METHOD } from "../../../common/constants";

function AccountActivity() {
  const [activity, setActivity] = useState([]);
  useEffect(() => {
    getAllActivity();
  }, []);

  const getAllActivity = async () => {
    const request = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.USER_ACCOUNT_ACTIVITY,
      method: API_METHOD.GET,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      setActivity(result.data);
    } else if (result.status > 200) {
      console.log("errror", result);
    }
  };

  return (
    <div>
      {" "}
      <Modal.Body>
        <Modal.Header closeButton className="pt-3"></Modal.Header>
        <div>
          {activity.length != 0
            ? activity.map((userActivity, i) => (
                <div key={i} className="row brd-btm py-2 my-3">
                  <div className="col-lg-2">
                    <svg
                      width="50"
                      height="50"
                      viewBox="0 0 50 50"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="50" height="50" rx="10" fill="#F0E1EB" />
                      <g clipPath="url(#clip0_2080_4170)">
                        <path
                          d="M23.8422 21.2852C23.4645 21.2852 23.1582 21.5914 23.1582 21.9691V25.691C23.1582 26.7523 24.0215 27.6156 25.0828 27.6156H27.5641C27.9418 27.6156 28.248 27.3094 28.248 26.9316C28.248 26.5539 27.9418 26.2477 27.5641 26.2477H25.0828C24.7758 26.2477 24.5262 25.998 24.5262 25.691V21.9691C24.5262 21.591 24.2199 21.2852 23.8422 21.2852Z"
                          fill="#395061"
                        />
                        <path
                          d="M31.8742 18.403C30.0379 16.567 27.5965 15.5557 24.9996 15.5557C23.5824 15.5557 22.1957 15.8619 20.9258 16.4483L21.1273 15.933C21.2648 15.5811 21.091 15.1846 20.7395 15.0471C20.3875 14.9096 19.991 15.0834 19.8535 15.435L19.0102 17.5924C18.916 17.833 18.9262 18.1045 19.0379 18.3377C19.1496 18.5705 19.3551 18.7487 19.6016 18.826L21.8117 19.519C21.8797 19.5405 21.9488 19.5506 22.0164 19.5506C22.3074 19.5506 22.577 19.3635 22.6688 19.0713C22.7816 18.7108 22.5813 18.3272 22.2207 18.2143L21.1297 17.8721C22.3184 17.2498 23.6422 16.9241 24.9992 16.9241C27.2309 16.9241 29.3285 17.7932 30.9066 19.3709C32.4844 20.9491 33.3535 23.0467 33.3535 25.2783C33.3535 27.51 32.4844 29.608 30.9066 31.1858C29.3285 32.7635 27.2309 33.6326 24.9992 33.6326C22.7676 33.6326 20.6695 32.7635 19.0918 31.1858C17.5137 29.6076 16.6449 27.51 16.6449 25.2783C16.6449 23.951 16.9469 22.6823 17.5422 21.5073C17.7129 21.1705 17.5781 20.7588 17.2414 20.5881C16.9047 20.4174 16.493 20.5522 16.3223 20.8889C15.6289 22.2569 15.2773 23.7334 15.2773 25.2776C15.2773 27.8744 16.2887 30.3158 18.125 32.1522C19.9613 33.9885 22.4027 34.9998 24.9996 34.9998C27.5965 34.9998 30.0379 33.9885 31.8742 32.1522C33.7106 30.3158 34.7219 27.8744 34.7219 25.2776C34.7219 22.6807 33.7106 20.2393 31.8742 18.403Z"
                          fill="#395061"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2080_4170">
                          <rect
                            width="20"
                            height="20"
                            fill="white"
                            transform="translate(15 15)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div className="col px-lg-0">
                    <div className="d-flex pe-2">
                      <p className="mb-0 fz-15px flex-grow-1">
                        <b className="fw-600"> {userActivity.header}</b>
                      </p>
                      {/* {userActivity?.time && (
                                    <span className='mb-0 fz-15px flex-shrink-0'>
                                        {moment(userActivity.time).format('D MMMM YYYY')} at {moment(userActivity.time).format('h:mm A')}
                                    </span>
                                )} */}
                    </div>
                    <p className="fz-14px pt-1">
                      {userActivity.time
                        ? `${moment(userActivity.time).format(
                            "DD MMMM , YYYY"
                          )} at ${moment(userActivity.time).format("h:mm A")}`
                        : `${moment().format(
                            "DD MMMM , YYYY"
                          )} at ${moment().format("h:mm A")}`}
                    </p>

                    {/* <p className='mb-0 fz-15px'><b className='fw-600'> {userActivity.header}</b></p> */}
                    {/* <p className='fz-14px pt-1'>{moment(userActivity.time).format("DD MMMM , YYYY")}</p> */}
                    {/* <p className='fz-14px'>{userActivity.data} {userActivity.time ? `${moment(userActivity.time).format('D MMMM YYYY')} at ${moment(userActivity.time).format('h:mm A')}` : ""}</p> */}
                  </div>
                </div>
              ))
            : "Loading..."}
        </div>
      </Modal.Body>
    </div>
  );
}

export default AccountActivity;
