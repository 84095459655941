import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { API_CALL } from '../../../../../../../api/ApiServiceCall';
import { API_CONSTANTS, API_METHOD } from '../../../../../../../common/constants';
import { clickedChapterDataListAtomFunc } from '../../../../../../atom/clickedChapterDataListAtomFunc';
import swal from "sweetalert"
import UploadIcon from '../../../../../../svg-icons/uploadIcon';
import CloseFileIcon from '../../../../../../svg-icons/closeFileIcon';
import axios from 'axios';
import MyS3Component from '../../../../../../s3/s3';

function EditClickedLesson({ getLessonbyLessonOrderFunc, getClickedLessonData, setIsEdit }) {
    const [imagePreviewBinaryPath, setImagePreviewBinaryPath] = useState({ 'url': "", 'fileObject': "" });
    const [newResource, setnewResource] = useState([]);
    const [newLsnVideoCount, setNewLsnVideoCount] = useState("")
    const [lessonName, setLessonName] = useState(getClickedLessonData.lessonName || "")
    const [clickedChapterDataListAtom, setClickedChapterDataListAtom] = useRecoilState(clickedChapterDataListAtomFunc)
    const [loading, setLoading] = useState(false);

    const handleFileChange = (e) => {
        let file = e.target.files[0];
        const uniqueId = "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
            const r = (Math.random() * 16) | 0,
            v = c === "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        }
        );
        let fileExtension = file.name.split(".").pop();
        const uniqueFileName = `${uniqueId}.${fileExtension}`;
        file = new File([file], uniqueFileName, { type: file.type });
        setNewLsnVideoCount(1)
        const url = URL.createObjectURL(file);
        setImagePreviewBinaryPath({ 'url': url, 'fileObject': file })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const userCode = JSON.parse(localStorage.getItem('userCode'));
        const usrdetailforHeader = JSON.parse(localStorage.getItem('localUserData'));
        const formData = new FormData();
        formData.append('lessonName', lessonName)
        formData.append('courseCode', userCode.data)
        formData.append('lessonOrder', getClickedLessonData.lessonOrder)
        formData.append('lesson', imagePreviewBinaryPath.fileObject)
        formData.append('chapterOrder', clickedChapterDataListAtom.data.chapterOrder)
        formData.append('fileCount', newResource?.length + newLsnVideoCount)
        newResource.forEach((newResourceData, index) => {
            formData.append('lesson', newResource[index])
        })


        let requestBodyParam = formData;
        let apiHeader = {
            'x-auth-token': usrdetailforHeader?.jwt,
        }
        if (usrdetailforHeader?.data?.user?.email) {
            apiHeader = {
                'token': usrdetailforHeader?.data?.user?.email,
                'gmailUserType': 'INSTRUCTOR'
            }
        }

        const request = {
            url: API_CONSTANTS.BASE_URL + API_CONSTANTS.CREATE_LESSON,
            method: API_METHOD.POST,
            onUploadProgress: progressEvent => {
                let percentComplete = progressEvent.loaded / progressEvent.total
                percentComplete = parseInt(percentComplete * 100);
                // console.log(percentComplete)
                setLoading(percentComplete);
            },
            headers: apiHeader,
            data: requestBodyParam,
        }
        axios(request).then(response => {
            if (response.status == 200) {
                getLessonbyLessonOrderFunc();
                setIsEdit(false);
                setLoading(false);
            }
        }).catch((error) => {
            if (error.response.status > 200) {
                swal({
                    title: "ERROR",
                    text: error.response.data.message,
                    icon: 'https://cdn-icons-png.flaticon.com/512/2569/2569174.png',
                    className: "error-window",
                    button: true,
                })
                setLoading(false);
            } else {
                swal({
                    title: "ERROR",
                    text: error,
                    icon: 'https://cdn-icons-png.flaticon.com/512/2569/2569174.png',
                    className: "error-window",
                    button: true,
                })
            }

            setLoading(false);

        })

    }
    const removeNewResource = (resIndex) => {
        const updatedFileList = [...newResource];
        updatedFileList.splice(resIndex, 1);
        setnewResource(updatedFileList);
    }

    const onChangeGetFilesSelected = e => {
        var filesArr = Array.prototype.slice.call(e.target.files);
        setnewResource(prevState => prevState.concat(filesArr))
        // console.log(newResource);
    };

    //onDelete Resource
    const onDeleteResource = async (resourceName) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover",
            icon: 'https://cdn-icons-png.flaticon.com/512/6460/6460112.png',
            imageClass: 'img-warning',
            animation: false,
            buttons: true,
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                const requestBodyParam = JSON.stringify({
                    "courseCode": clickedChapterDataListAtom.data.courseCode,
                    "lessonOrder": getClickedLessonData.lessonOrder,
                    "chapterOrder": clickedChapterDataListAtom.data.chapterOrder,
                    "resourceName": resourceName
                });
                // alert(requestBodyParam)
                const request = {
                    apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.DELETE_RESOURCE,
                    method: API_METHOD.POST,
                    requestBodyParams: requestBodyParam,
                }
                let result = await API_CALL(request)
                if (result.status === 200) {
                    getLessonbyLessonOrderFunc()
                } else if (result.status > 200) {
                    swal({
                        title: "ERROR",
                        text: result.message,
                        icon: 'https://cdn-icons-png.flaticon.com/512/2569/2569174.png',
                        className: "error-window",
                        button: true,
                    })
                }
            }
        });
    }
    return (
      <div>
        {/* <pre>{JSON.stringify(newResource, null, 4)}</pre> */}
        <div className="login-col pt-2">
          <form id="lessonName" onSubmit={handleSubmit}>
            <input
              type="text"
              value={lessonName}
              placeholder="Lesson Name Here"
              required
              onChange={(e) => setLessonName(e.target.value)}
              autoFocus={true}
            />
            {imagePreviewBinaryPath.url ? (
              <video
                className="VideoInput_video"
                width="100%"
                height="auto"
                controls
                src={imagePreviewBinaryPath.url}
              />
            ) : (
              <MyS3Component
                className="VideoInput_video"
                style={{ width: "100%", height: "auto" }}
                controls
                imageUrl={getClickedLessonData.lessonVideoPath}
              />
            )}

            <input
              className={"d-none"}
              id="change_lesson_video"
              type="file"
              onChange={handleFileChange}
              accept=".mp4"
            />
            <label
              htmlFor="change_lesson_video"
              className="w-100 py-2 text-center text-white fz-13px bg-secondary rounded-2"
              role="button"
            >
              Change Video
            </label>
            {/* ----newResource====== */}
            <h6>Resources</h6>

            <p className="text-muted fz-12px">
              <b className="fw-500">Note:</b> Supported file formats are .docx,
              .pptx, .txt, .pdf, .xlsx, .xls{" "}
            </p>
            <input
              id="uploadFile"
              type="file"
              onClick={(e) => (e.currentTarget.value = null)}
              onChange={onChangeGetFilesSelected}
              multiple={true}
              accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf"
              className="d-none"
            />
            {/* ------previous resource list--------- */}
            {getClickedLessonData?.resourceDetails
              ? getClickedLessonData?.resourceDetails.map(
                  (resourceData, indx) => (
                    <div
                      className="d-flex align-items-center"
                      key={resourceData._id}
                    >
                      <div
                        className="d-inline-block"
                        type="button"
                        onClick={() =>
                          onDeleteResource(resourceData.resourceName)
                        }
                      >
                        {" "}
                        <CloseFileIcon size={29} />
                      </div>
                      <a
                        href={resourceData.resourcePath}
                        className="pt-1 px-1 fz-15px text-prime"
                      >
                        {" "}
                        {resourceData.resourceName}
                      </a>
                    </div>
                  )
                )
              : ""}
            {/* ---new resource list--------- */}
            {newResource
              ? newResource.map((file, index) => (
                  <div key={index}>
                    <div
                      className="d-inline-block"
                      type="button"
                      onClick={() => removeNewResource(index)}
                    >
                      {" "}
                      <CloseFileIcon size={29} />
                    </div>{" "}
                    <span className="text-prime fz-15px">{file.name}</span>
                  </div>
                ))
              : ""}
          </form>
        </div>
        <div className="d-flex align-items-center justify-content-end ">
          <label
            htmlFor="uploadFile"
            className="border-0 bg-prime text-white me-auto my-3 ms-lg-2 fz-14px py-2 px-4 rounded-3 "
            type="button"
            disabled={loading ? true : false}
          >
            <UploadIcon size={17} /> Upload New Resource
          </label>
        </div>
        <div className="d-flex align-items-center justify-content-end ">
          <button
            type="button"
            className="border-0 bg-secondary text-white fz-14px py-2 px-5 rounded-3 text-center"
            role="button"
            onClick={() => setIsEdit(false)}
          >
            Cancel
          </button>
          <button
            form="lessonName"
            className="border-0 bg-prime text-white ms-lg-2 fz-14px py-2 px-5 rounded-3 text-center"
            role="button"
            type="submit"
            disabled={loading ? true : false}
          >
            {loading}
            {loading ? "% Uploading..." : "Done"}
          </button>
        </div>
      </div>
    );
}

export default EditClickedLesson
