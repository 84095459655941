import React from 'react'

function VideoIcon() {
  return (
    <svg width="31" height="15" viewBox="0 0 31 23" xmlns="http://www.w3.org/2000/svg" fill="#395061">
    <path d="M0.529297 4.05865C0.529297 3.06019 0.925934 2.10262 1.63195 1.3966C2.33797 0.690583 3.29554 0.293945 4.294 0.293945H15.5881C16.5866 0.293945 17.5442 0.690583 18.2502 1.3966C18.9562 2.10262 19.3528 3.06019 19.3528 4.05865V19.1175C19.3528 20.1159 18.9562 21.0735 18.2502 21.7795C17.5442 22.4855 16.5866 22.8822 15.5881 22.8822H4.294C3.29554 22.8822 2.33797 22.4855 1.63195 21.7795C0.925934 21.0735 0.529297 20.1159 0.529297 19.1175V4.05865ZM24.1585 6.14053C23.8459 6.29675 23.5829 6.53692 23.399 6.83413C23.2152 7.13135 23.1177 7.47388 23.1175 7.82336V15.3528C23.1177 15.7023 23.2152 16.0448 23.399 16.342C23.5829 16.6392 23.8459 16.8794 24.1585 17.0356L27.9232 18.9179C28.2101 19.0613 28.5289 19.129 28.8493 19.1146C29.1697 19.1001 29.4811 19.0041 29.754 18.8355C30.0268 18.6669 30.2521 18.4314 30.4084 18.1513C30.5647 17.8712 30.6468 17.5559 30.6469 17.2351V5.941C30.6468 5.62027 30.5647 5.3049 30.4084 5.02481C30.2521 4.74473 30.0268 4.50922 29.754 4.34064C29.4811 4.17206 29.1697 4.07599 28.8493 4.06156C28.5289 4.04713 28.2101 4.11481 27.9232 4.25818L24.1585 6.14053Z" />
</svg>
  )
}

export default VideoIcon