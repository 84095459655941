import swal from "sweetalert";
import { API_METHOD } from "../common/constants"
import ErroImg from '../images/icons/error.png'

export const API_CALL = async (req, authorisation = true) => {

    const usrdetailforHeader = JSON.parse(localStorage.getItem('localUserData'));
    // console.log(usrdetailforHeader?.jwt);
    // console.log(JSON.stringify(usrdetailforHeader,null,4));
    let apiHeader = {
        "mode": 'no-cors',
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    }

    if (usrdetailforHeader?.jwt && authorisation) {
        apiHeader = {
            ...apiHeader,
            'x-auth-token': usrdetailforHeader?.jwt,
        }
    }

    ///console.log('LOG', apiHeader);

    if (usrdetailforHeader?.data?.user?.email) {
        apiHeader = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'token': usrdetailforHeader?.data?.user?.email,
            // 'gmailUserType': 'INSTRUCTOR'
            // 'gmailUserType': 'STUDENT'
            'gmailUserType': usrdetailforHeader?.userType
        }
    }
    if (req.headers) {
        apiHeader = req.headers;
    }

    let apiUrl = req.apiUrl
    let apiMethod = req.method ? req.method : API_METHOD.GET
    let apiRequestBody = req.requestBodyParams
    const requestOptions = {
        method: apiMethod,
        headers: apiHeader,
        body: apiRequestBody
    }
    const requestOptionsGet = {
        method: apiMethod,
        headers: apiHeader,

        body: apiRequestBody
    }

    // console.log('requestOptionsGet', requestOptionsGet);

    if (apiMethod != API_METHOD.GET) {
        // console.log("POST_____");

        // console.log("<<<=====>>>>"+JSON.stringify(requestOptions));
        let response = fetch(apiUrl, requestOptions)

            .then(response => response.json())
            .then(result => {

                if (result.message === "Token is Not Valid") {
                    swal({
                        title: "Please try to re-login",
                        text: "You are already logged in on another device or browser!",
                        icon: ErroImg,
                        className: "error-window",
                        button: true,
                        closeOnClickOutside: false
                    }).then(function () {
                        let user = JSON.parse(localStorage.getItem('localUserData'));
                        if (user.userType == "INSTRUCTOR") {
                            localStorage.clear();
                            window.location = "/login";
                        } else {
                            localStorage.clear();
                            window.location = "/student-login";
                        }
                    });
                } else if (result.message === "User is Deactivated, Contact Admin") {
                    swal({
                        title: "User is Deactivated",
                        text: "User is deactivated, contact admin",
                        icon: ErroImg,
                        className: "error-window",
                        button: true,
                        closeOnClickOutside: false
                    }).then(function () {
                        let user = JSON.parse(localStorage.getItem('localUserData'));
                        if (user.userType == "INSTRUCTOR") {
                            localStorage.clear();
                            window.location = "/login";
                        } else {
                            localStorage.clear();
                            window.location = "/student-login";
                        }
                    });
                } else {
                    return result
                }

            })
            .catch(error => {
                console.log("Error Occured for Request \n" + apiUrl + " with body \n" + JSON.stringify(requestOptions), error)
                // swal({
                //     title: "Oops! Something went Wrong!",
                //     text: "Sorry! for this try to reload the page",
                //     icon: 'https://cdn-icons-png.flaticon.com/512/2569/2569174.png',
                //     className: "error-window",
                //     button: true,
                // })
                return error;
            });
        return response;
    }
    else {
        // console.log("============>"+ JSON.stringify(requestOptionsGet));
        // console.log("GET METHOD--------------");
        // console.log('requestOptionsGet', requestOptionsGet);
        let response = fetch(apiUrl, requestOptionsGet)
            .then(response => response.json())
            .then(result => {
                console.log("Response for Request \n", result)
                if (result.message === "Token is Not Valid") {
                    swal({
                        title: "Please try to re-login",
                        text: "You are already logged in on another device or browser!",
                        icon: ErroImg,
                        className: "error-window",
                        button: true,
                        closeOnClickOutside: false
                    }).then(function () {
                        let user = JSON.parse(localStorage.getItem('localUserData'));
                        if (user.userType == "INSTRUCTOR") {
                            localStorage.clear();
                            window.location = "/login";
                        } else {
                            localStorage.clear();
                            window.location = "/student-login";
                        }
                    });
                } else if (result.message === "User is Deactivated, Contact Admin") {
                    swal({
                        title: "User is Deactivated",
                        text: "User is deactivated, contact admin",
                        icon: ErroImg,
                        className: "error-window",
                        button: true,
                        closeOnClickOutside: false
                    }).then(function () {
                        let user = JSON.parse(localStorage.getItem('localUserData'));
                        if (user.userType == "INSTRUCTOR") {
                            localStorage.clear();
                            window.location = "/login";
                        } else {
                            localStorage.clear();
                            window.location = "/student-login";
                        }
                    });
                } else {
                    return result
                }
            })
            .catch(error => {
                console.log("Error Occured for Request \n" + apiUrl + " with body \n" + error)
                // swal({
                //     title: "Oops! Something went Wrong!",
                //     text: "Sorry! for this try to reload the page",
                //     icon: 'https://cdn-icons-png.flaticon.com/512/2569/2569174.png',
                //     className: "error-window",
                //     button: true,
                // })
                return error;
            });
        return response;
    }
}

