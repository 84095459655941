import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { API_CALL } from "../../../../../api/ApiServiceCall";
import { API_CONSTANTS, API_METHOD } from "../../../../../common/constants";

function EnableCoupon({ clickedRowData }) {
  const [activeCourseList, setActiveCourseList] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [atLeastOneEnabled, setAtLeastOneEnabled] = useState(false);

  useEffect(() => {
    getActiveCoursebyInstructor();
  }, []);

  const getActiveCoursebyInstructor = async () => {
    const request = {
      apiUrl:
        API_CONSTANTS.BASE_URL +
        API_CONSTANTS.GET_ACTIVE_COURSE_BY_INSTRUCTOR +
        `?discountId=${clickedRowData?._id}`,
      method: API_METHOD.GET,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      setActiveCourseList(result.data);
      const allDiscountsEnabled = result.data.every(
        (item) => item.isDiscountEnable === true
      );
      setSelectAll(allDiscountsEnabled);
      setAtLeastOneEnabled(result.data.some((item) => item.isDiscountEnable));
    } else if (result.status > 200) {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }
  };

  const handleCheckboxChange = (itemId) => {
    const updatedCheckboxes = activeCourseList.map((item) => {
      if (item.courseCode === itemId) {
        return { ...item, isDiscountEnable: !item.isDiscountEnable };
      }
      return item;
    });
    setActiveCourseList(updatedCheckboxes);
    setSelectAll(updatedCheckboxes.every((item) => item.isDiscountEnable));
    setAtLeastOneEnabled(updatedCheckboxes.some((item) => item.isDiscountEnable));
  };

  const handleSelectAllChange = () => {
    const updatedCheckboxes = activeCourseList.map((item) => ({
      ...item,
      isDiscountEnable: !selectAll,
    }));
    setActiveCourseList(updatedCheckboxes);
    setSelectAll(!selectAll);
    setAtLeastOneEnabled(!selectAll);
  };

  const onSave = async () => {
    const getCheckedCourseCode = activeCourseList.filter(
      (item) => item.isDiscountEnable == true
    );
    const getCourseCode = getCheckedCourseCode.map(
      (item, index, array) => item.courseCode
    );

    const requestBodyParam = JSON.stringify({
      activeCourseList: getCourseCode,
      discountId: clickedRowData._id,
    });
    const request = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.ENABLE_DISCOUNT_TO_COURSE,
      method: API_METHOD.POST,
      requestBodyParams: requestBodyParam,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      swal("", "Discount was enabled", "success");
    } else if (result.status > 200) {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }
  };

  return (
    <>
      <div className="d-flex  align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <label>
            <input
              className="form-check-input"
              type="checkbox"
              checked={selectAll}
              onChange={handleSelectAllChange}
            />{" "}
            Enable All
          </label>
        </div>

        <button
          className="border-0 bg-prime text-white mt-3 fz-14px py-2 px-4 rounded-3"
          disabled={!atLeastOneEnabled}
          onClick={onSave}
        >
          Save
        </button>
      </div>
      <div className="my-3"></div>
      <div className="row">
        {!activeCourseList ? (
          <div className="text-center">
            <span
              className="spinner-border text-secondary spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : activeCourseList.length ? (
          activeCourseList.map((acvData, index, array) => (
            <div className="col-6" key={acvData?.courseCode}>
              <div className="bg-light overview-crds p-4 mb-4  rounded-9">
                <div className="row">
                  <div className="col-lg-5 my-auto">
                    {/* {datas.courseCode} */}
                    <img
                      src={acvData?.thumbNailImagePath}
                      className="rounded-3"
                    />
                  </div>
                  <div className="col-lg-7 my-auto">
                    <h5
                      className="fw-600 fz-17px"
                      style={{ wordBreak: "break-word" }}
                    >
                      {acvData?.courseName}
                    </h5>
                    <h6 className="fw-600 fz-17px">₹ {acvData?.fee}</h6>
                    {acvData?.isLive ? (
                      <span className="fz-12px fw-500 text-white BANNED px-3 me-3 rounded-4 py-1">
                        Live Course
                      </span>
                    ) : (
                      ""
                    )}
                    <label>
                      <input
                        type="checkbox"
                        className="form-check-input me-1"
                        checked={acvData.isDiscountEnable}
                        onChange={() =>
                          handleCheckboxChange(acvData.courseCode)
                        }
                      />
                      {"Enable discount"}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          "No Active Available"
        )}

        <div className="py-4 px-5">
          {/* <pre>{JSON.stringify(activeCourseList, null, 4)}</pre> */}
        </div>
      </div>
    </>
  );
}

export default EnableCoupon;
