import { atom } from "recoil";

export const notificationsState = atom({
  key: "cartCountState-b3ac-bd4c-zd54-8f8e-b5a836382362a1e6d458",
  default: {
    count: 0,
    result: {
      _id: "",
      email: "",
      userId: "",
      userType: "",
      notificationList: [],
      createdTime: "2022-12-15T13:00:09.301Z",
      __v: 0,
    },
  },
});

const prevData = {
  count: 5,
  result: {
    _id: "639b1a59f8f787156ca3ff91",
    email: "sisetif662@dni8.com",
    userId: "639b1a47f8f787156ca3ff89",
    userType: "STUDENT",
    notificationList: [
      {
        isRead: false,
        header: "NOLI01",
        subject: "User Logged in Successfully",
        content:
          "Your Logged in time is: Wed Jan 11 2023 15:06:04 GMT+0000 (Coordinated Universal Time)",
        emptyContent: " ",
        notificationDate: "2023-01-11T15:06:04.946Z",
        _id: "63bed05cde3e4d4373ed5404",
      },
      {
        isRead: false,
        header: "NOLI01",
        subject: "User Logged in Successfully",
        content:
          "Your Logged in time is: Wed Jan 11 2023 14:35:28 GMT+0000 (Coordinated Universal Time)",
        emptyContent: " ",
        notificationDate: "2023-01-11T14:35:28.464Z",
        _id: "63bec930f86956c43eeb8a39",
      },
    ],
    createdTime: "2022-12-15T13:00:09.301Z",
    __v: 0,
  },
};
