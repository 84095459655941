import React from 'react'
import { useRecoilState } from 'recoil';
import { currentViewComponentLsnAssessAtomFunc } from '../../../../../../atom/lsnChapAssessComponentRender';
import LessonIcon from '../../../../../../svg-icons/lessonIcon';
import RightArrow from '../../../../../../svg-icons/rightArrow';
import ViewLesson from './viewLesson';

function LessonList({ lessonListData }) {
    const [currentViewComponentLsnAssessAtom, setCurrentViewComponentLsnAssessAtom] = useRecoilState(currentViewComponentLsnAssessAtomFunc)

    return (
        <div>
            {/* <pre> {JSON.stringify(lessonListData, null, 1)}</pre> */}
            {/* {lessonListData.chapterOrder}
            {lessonListData.courseCode} */}
            <div className="chp-list d-flex justify-content-md-between align-items-center" onClick={() => setCurrentViewComponentLsnAssessAtom(<ViewLesson singleClickedLesson={lessonListData} />)}>
                <h1 className='m-0 fz-16px fw-500'>  <LessonIcon /> {lessonListData.lessonName}</h1>
                <RightArrow size={8} />
            </div>
        </div>
    )
}

export default LessonList