import React from 'react'

function CallIcon() {
  return (
  <svg width="28" height="10" viewBox="0 0 36 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill="white" fillRule="evenodd" clipRule="evenodd" d="M18.1102 5.97158H17.8902C14.1621 6.0315 11.7155 6.75064 10.5506 8.12898L10.5259 8.83514C10.4788 9.82752 10.3861 10.519 10.2478 10.9096C9.95986 11.7228 9.56629 12.1032 7.95026 12.5812C5.8243 13.21 4.00413 13.2943 3.03068 13.2943C2.12675 13.2943 1.48857 12.3815 1.30191 11.8808L1.21438 11.585L1.12734 11.2323C0.896321 10.1929 0.614017 7.9252 2.0976 5.96749C2.7383 5.12204 3.77642 4.28257 5.21302 3.51622C8.39684 1.98037 12.2527 1.1316 16.7806 0.969936L17.5939 0.949217L17.5939 0.941406L18 0.94494L18.4066 0.941406L18.4065 0.949217L19.2199 0.969936C23.7478 1.1316 27.6036 1.98037 30.7874 3.51622C32.224 4.28257 33.2622 5.12204 33.9029 5.96749C35.5075 8.08492 35.0463 10.565 34.8186 11.4613L34.7335 11.7732C34.6085 12.2357 33.9432 13.2943 32.9698 13.2943L32.5023 13.2878C31.47 13.2591 29.8725 13.1202 28.0502 12.5812C26.4342 12.1032 26.0406 11.7228 25.7527 10.9096C25.5836 10.4322 25.4827 9.50533 25.4499 8.12898C24.2849 6.75064 21.8384 6.0315 18.1102 5.97158Z" />
                        </svg>
  )
}

export default CallIcon