import { useState, useEffect } from 'react';
import { Row, Col, Nav, Tab } from 'react-bootstrap';
import AccountSetting from './profile_menu/account-setting';
import UserProfile from './profile_menu/user-profile';
import Notification from './student_notifications/notification';
import ProfileImageForm from './profile-image-upload/profileImageForm';
import GoogleSignUserProfileImage from './profile-image-upload/googleSignInProfileImage';
import PaymentHistory from '../payment-history';


function StudentProfile() {
    const [userDetails, setUserDetails] = useState(JSON.parse(localStorage.getItem("localUserData")));

    const [userData, setUserData] = useState({});

    return (
        <div className='container tch'>
            <div className="py-4"></div>

            {userDetails?.data?.user?.photoURL ? (
                <GoogleSignUserProfileImage userData={userData} userDetail={userDetails} />
            ) : (
                <ProfileImageForm userData={userData} credentialUserDetail={userDetails} />
            )}
            <div className="py-4"></div>
            <Tab.Container id="hr-tabs-example" defaultActiveKey="first">
                <Row>
                    <Col sm={12}>
                        <Nav variant="pills brd-btm"  >
                            <Nav.Item>
                                <Nav.Link eventKey="first">Profile</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="settings">Account Settings</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="notifications">Notifications</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="paymentHistory">Payment History</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    <Col sm={12}>
                        <Tab.Content>
                            <Tab.Pane eventKey="first">
                                <UserProfile updateUserDetails={setUserDetails} setUserData={setUserData} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="settings">
                                <AccountSetting />
                            </Tab.Pane>
                            <Tab.Pane eventKey="notifications">
                                <Notification />
                            </Tab.Pane>
                            <Tab.Pane eventKey="paymentHistory">
                                <PaymentHistory />
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </div>
    )
}

export default StudentProfile