import React from "react";
import { BiUserCircle } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { API_CALL } from "../../api/ApiServiceCall";
import { API_CONSTANTS, API_METHOD, STORAGE_KEY } from "../../common/constants";
import useCookie from "../../common/cookie_hook";

function ChooseAccount({ accountDetailData, closeModal }) {
  const [cookie, updateCookie] = useCookie(STORAGE_KEY.AUTH);
  const navigate = useNavigate();

  const loginWithMobileNum = async (user) => {
    const formatMobileNumber = "+91" + user.mobileNumber.replace(/^0+/, "");
    const requestBodyParam = JSON.stringify({
      mobileNumber: formatMobileNumber,
      userType: "STUDENT",
      accountId: user.accountId,
      email: user.email,
    });

    const request = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.LOGIN_WITH_MOBILE_NUM,
      method: API_METHOD.POST,
      requestBodyParams: requestBodyParam,
    };

    let result = await API_CALL(request);
    if (result.status === 200) {
      closeModal(false);
      let localUserData = JSON.stringify({
        ...result?.data,
        name: result.data.Name,
        email: result.data.Email,
        userType: "STUDENT",
        jwt: result.data.JWT,
      });
      updateCookie(result.data.JWT);
      localStorage.setItem(STORAGE_KEY.LOCAL_USER_DATA, localUserData);
      navigate("/student-dashboard");
    } else if (result.status > 200) {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }
  };

  return (
    <div>
      <h6 className="text-prime">Choose your account</h6>
      {accountDetailData ? (
        accountDetailData.map((user) => (
          <div
            key={user.accountId}
            onClick={() => loginWithMobileNum(user)}
            role="button"
          >
            <div className="row g-4">
              <div className="col-lg-1 mt-auto">
                <BiUserCircle
                  color="#8c8c8c"
                  size={32}
                  strokeWidth={"0.4px"}
                  stroke={"white"}
                />
              </div>
              <div className="col-lg-11 text-left">
                <p className="m-0 fz-15px">{user?.email}</p>
                <p className="m-0  fz-15px">{user?.mobileNumber}</p>
              </div>
            </div>
            <hr className="my-3"></hr>
          </div>
        ))
      ) : (
        <>
          <span
            className="spinner-border spinner-border-sm text-secondary"
            role="status"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Loading...</span>
        </>
      )}
    </div>
  );
}

export default ChooseAccount;
