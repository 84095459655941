import React from 'react'

function ScreenShareIcon() {
  return (
    <svg width="30" height="20" viewBox="0 0 26 26" fill="red" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.4401 25.4446H16.0023V23.7257H19.4323C20.571 23.7257 21.663 23.2734 22.4682 22.4683C23.2733 21.6631 23.7257 20.5711 23.7257 19.4324V6.56019C23.7257 5.42153 23.2733 4.3295 22.4682 3.52434C21.663 2.71919 20.571 2.26686 19.4323 2.26686H6.599C5.46034 2.26686 4.36831 2.71919 3.56315 3.52434C2.758 4.3295 2.30566 5.42153 2.30566 6.56019V9.99019H0.555664V6.56019C0.556677 5.76868 0.713969 4.98517 1.01851 4.25459C1.32306 3.52402 1.76886 2.86077 2.33034 2.3029C2.89183 1.74503 3.55794 1.30352 4.29046 1.0037C5.02299 0.703878 5.8075 0.551648 6.599 0.555745H19.4401C21.032 0.557803 22.558 1.19107 23.6836 2.31668C24.8092 3.44228 25.4425 4.96834 25.4446 6.56019V19.4402C25.4425 21.032 24.8092 22.5581 23.6836 23.6837C22.558 24.8093 21.032 25.4426 19.4401 25.4446Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M1.41122 23.7256C1.5801 23.7256 1.7452 23.7756 1.88572 23.8693C2.02625 23.9629 2.1359 24.0961 2.20088 24.252C2.26586 24.4078 2.28325 24.5795 2.25086 24.7452C2.21848 24.911 2.13776 25.0634 2.01889 25.1834C1.90001 25.3033 1.74829 25.3854 1.58285 25.4193C1.4174 25.4532 1.24563 25.4373 1.08917 25.3738C0.932705 25.3102 0.798555 25.2018 0.703617 25.0621C0.608679 24.9224 0.557199 24.7578 0.555664 24.5889C0.555664 24.362 0.645803 24.1444 0.806251 23.9839C0.966699 23.8235 1.18431 23.7334 1.41122 23.7334V23.7256Z" />
    <path d="M5.86011 25.4446H4.149C4.149 24.4916 3.77042 23.5776 3.09653 22.9037C2.42265 22.2298 1.50868 21.8512 0.555664 21.8512V20.1401C1.96186 20.1422 3.30987 20.7017 4.3042 21.696C5.29853 22.6904 5.85805 24.0384 5.86011 25.4446V25.4446Z" />
    <path d="M9.42233 25.4443H7.71122C7.70916 23.5472 6.95461 21.7283 5.61313 20.3868C4.27165 19.0454 2.4528 18.2908 0.555664 18.2887V16.5776C2.90599 16.5817 5.15887 17.5172 6.8208 19.1792C8.48273 20.8411 9.41822 23.094 9.42233 25.4443V25.4443Z" />
    <path d="M13.0001 25.4445H11.2657C11.2615 22.6052 10.1319 19.8835 8.12423 17.8759C6.11661 15.8683 3.39487 14.7386 0.555664 14.7344V13C3.85614 13 7.02143 14.3111 9.35522 16.6449C11.689 18.9787 13.0001 22.144 13.0001 25.4445Z" />
</svg>
  )
}

export default ScreenShareIcon