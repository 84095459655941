import React from "react";

function DashboardPage() {
  return (
    <div className="py-6 container">
      <h6>Dashboard</h6>
      <div className="py-2"></div>
      <div className="row">
        <div className="col-lg-4">
          <div className="card rounded-3 p-4 shape-line">
            <small className="fw-500 pb-3 d-inline-block">Total Revenue</small>
            <p className="m-0">$ 11516516515.00</p>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="card rounded-3 p-4 shape-line">
            <small className="fw-500 pb-3 d-inline-block">Total Revenue</small>
            <p className="m-0">$ 11516516515.00</p>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="card rounded-3 p-4 shape-line">
            <small className="fw-500 pb-3 d-inline-block">Total Revenue</small>
            <p className="m-0">$ 11516516515.00</p>
          </div>
        </div>
      </div>
      <div className="py-4"></div>
      <div className="row">
        <div className="col-lg-4">
          <div className="card rounded-3 p-4 shape-line">
            <small className="fw-500 pb-3 d-inline-block">Total Revenue</small>
            <p className="m-0">$ 11516516515.00</p>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="card rounded-3 p-4 shape-line">
            <small className="fw-500 pb-3 d-inline-block">Total Revenue</small>
            <p className="m-0">$ 11516516515.00</p>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="card rounded-3 p-4 shape-line">
            <small className="fw-500 pb-3 d-inline-block">Total Revenue</small>
            <p className="m-0">$ 11516516515.00</p>
          </div>
        </div>
      </div>
      <div className="py-5"></div>
      <h6>Latest Transactions</h6>
    </div>
  );
}

export default DashboardPage;
