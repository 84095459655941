import React from "react";
import { Accordion } from "react-bootstrap";
import { useRecoilState } from "recoil";
import { isSingleLiveCourseEditAtomFunc } from "../../../../atom/isSingleLiveCourseEditAtom";
import { singleLiveCourseDataAtomFunc } from "../../../../atom/singleLiveCourseDataAtom";
import EditIcon from "../../../../svg-icons/editIcon";

function LiveCourseStep1OverviewData() {
  const [singleLiveCourseDataAtom, setSingleLiveCourseDataAtom] =
    useRecoilState(singleLiveCourseDataAtomFunc);
  const [isSingleLiveCourseEditAtom, setIsSingleLiveCourseEditAtom] =
    useRecoilState(isSingleLiveCourseEditAtomFunc);

  let isBlock = true;
  if (singleLiveCourseDataAtom) {
    if (singleLiveCourseDataAtom?.courseStatus == "INREVIEW") {
      isBlock = true;
    } else {
      isBlock = false;
    }
  }
  return (
    <>
      {/* <pre>{JSON.stringify(singleLiveCourseDataAtom, null, 5)}</pre> */}
      {isBlock ? (
        ""
      ) : (
        <div
          className="d-flex justify-content-end mt-2"
          role="button"
          onClick={() =>
            setIsSingleLiveCourseEditAtom({
              ...isSingleLiveCourseEditAtom,
              edit: true,
            })
          }
        >
          <EditIcon /> &nbsp;Edit
        </div>
      )}
      <div className="p-3">
        {singleLiveCourseDataAtom ? (
          singleLiveCourseDataAtom?.rejectionComments?.length &&
          singleLiveCourseDataAtom?.courseStatus != "ACTIVE" ? (
            <p className="text-danger fz-14px fw-500">Reason for rejection</p>
          ) : (
            ""
          )
        ) : (
          ""
        )}
        <div className="faq-sec accordion-header-btn-p-0">
          <Accordion defaultActiveKey="0" flush>
            {singleLiveCourseDataAtom &&
            singleLiveCourseDataAtom?.courseStatus != "ACTIVE"
              ? singleLiveCourseDataAtom?.rejectionComments?.map(
                  (items, index) => (
                    <Accordion.Item eventKey={index} key={items?._id}>
                      <Accordion.Header>
                        {index + 1}. {items?.comment?.substring(0, 50)}
                        {items?.comment?.length > 50 ? "..." : ""}
                      </Accordion.Header>
                      <Accordion.Body className="p-0">
                        <textarea
                          value={items?.comment}
                          row={"2"}
                          className=" default-inpt resize-none"
                          readOnly
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                  )
                )
              : ""}
          </Accordion>
        </div>
        <div className="py-2"></div>
        <p className="text-muted fz-14px fw-500">Course Title</p>
        <h6 className="text-dark">
          {singleLiveCourseDataAtom?.courseName || "Loading..."}
        </h6>

        <div className="py-2"></div>
        <div className="text-muted fz-14px fw-500 pb-2">Course Overview</div>
        <div
          className="w-100 overflow-auto"
          dangerouslySetInnerHTML={{
            __html: singleLiveCourseDataAtom?.courseOverview || "Loading...",
          }}
        />
        <div className="d-flex">
          <p className="text-muted pt-3 fz-14px fw-500">
            Category{" "}
            <b className="fz-15px fw-500 text-black">
              {" "}
              {singleLiveCourseDataAtom?.categoryName || "Loading..."}
            </b>
          </p>
          <p className="text-muted pt-3 mx-lg-5 fz-14px fw-500">
            Sub Category{" "}
            <b className="fz-15px fw-500 text-black">
              {" "}
              {singleLiveCourseDataAtom?.subCategoryName || "Loading..."}
            </b>
          </p>
        </div>
      </div>
    </>
  );
}

export default LiveCourseStep1OverviewData;
