import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import swal from "sweetalert";
import {
  filterState,
  selectedCategoryCodeState,
} from "../atoms/course-list-atom";
import { API_CALL } from "./../../api/ApiServiceCall";
import { API_CONSTANTS, API_METHOD } from "./../../common/constants";
import FilterAccordion from "./FilterAccordion";
import PriceFilterAccordion from "./PriceFilterAccordion";

function CoursesFilter() {
  const [subCategories, setSubCategories] = useState({
    loading: false,
    data: [],
    error: null,
  });
  const selectedCategoryCode = useRecoilValue(selectedCategoryCodeState);
  const [filter, setFilter] = useRecoilState(filterState);

  const getSubcategory = async (categoryCode) => {
    setSubCategories({ loading: true, data: [], error: null });

    const request = {
      apiUrl:
        API_CONSTANTS.BASE_URL +
        API_CONSTANTS.SUB_CATEGORY_BY_CATEGORY_CODE(categoryCode),
      method: API_METHOD.GET,
    };

    let result = await API_CALL(request);

    if (result.status === 200) {
      setSubCategories({ loading: false, data: result.data, error: null });
    } else if (result.status > 200) {
      setSubCategories({ loading: false, data: [], error: result.message });

      if (result.message == "Token is Not Valid") return null;

      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }
  };

  useEffect(() => {
    if (selectedCategoryCode) {
      getSubcategory(selectedCategoryCode);
    }
  }, [selectedCategoryCode]);

  const handleSubCategorySelect = (e) => {
    if (e.target.checked) {
      setFilter({
        ...filter,
        selectedSubCategories: [
          ...filter.selectedSubCategories,
          e.target.value,
        ],
      });
    } else {
      const filteredSelectedSubCategories = filter.selectedSubCategories.filter(
        (subCategory) => subCategory !== e.target.value
      );
      setFilter({
        ...filter,
        selectedSubCategories: filteredSelectedSubCategories,
      });
    }
  };

  const handlePriceFilter = (e) => {
    if (e.target.checked) {
      setFilter({
        ...filter,
        prices: filter.prices.map((item) => {
          return item.name === e.target.name
            ? { ...item, selected: true }
            : { ...item, selected: false };
        }),
      });
    } else {
      setFilter({
        ...filter,
        prices: filter.prices.map((item) => {
          return item.name === e.target.name
            ? { ...item, selected: false }
            : { ...item, selected: false };
        }),
      });
    }
  };

  return (
    <div className="p-4 pb-0 bg-white rounded-4" style={{ overflow: "hidden" }}>
      {/* <p style={{ width: '2000px' }}>{JSON.stringify(filter.prices)}</p> */}

      <div style={{ width: "200px" }}>
        <h3 className="fs-5">Filter</h3>

        {subCategories.data.length > 0 && (
          <FilterAccordion
            title="Sub-Category"
            data={subCategories.data.map((subCategory) => ({
              label: subCategory.subCategoryName,
              name: subCategory.subCategoryName,
              value: subCategory.subCategoryCode,
            }))}
            handleSubCategorySelect={handleSubCategorySelect}
          />
        )}
        <PriceFilterAccordion
          title="Price"
          data={filter.prices}
          handleSubCategorySelect={handlePriceFilter}
        />
        {/* <FilterAccordion title="Rating" /> */}
        {/* <FilterAccordion title="Price"
                    data={[
                        { _id: 1, subCategoryCode: 'price', subCategoryName: '0 - 100' },
                        { _id: 2, subCategoryCode: 'price', subCategoryName: '101 - 500' },
                        { _id: 3, subCategoryCode: 'price', subCategoryName: '501 - 999' }
                    ]}
                    handleSubCategorySelect={handleSubCategorySelect}
                /> */}
        {/* <FilterAccordion title="Course Type" />
                <FilterAccordion title="Language" />
                <FilterAccordion title="Topic" /> */}
      </div>
    </div>
  );
}

export default CoursesFilter;
