import React, { useEffect, useState } from "react";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useResetRecoilState } from "recoil";
import swal from "sweetalert";
import { API_CALL } from "../../../api/ApiServiceCall";
import { API_CONSTANTS, API_METHOD } from "../../../common/constants";
import { OverviewDataState } from "../../atom/overviewData";
import { OverviewTriggerRefetch } from "../../atom/overviewRefetchTrigger";
import CreateFaq from "./faq/createFaq";
import OverviewContent from "./overview-contents/overviewContent";
import OverviewEdit from "./overview-contents/overviewContentEdit";
import PricingContent from "./overview-contents/pricing-overview/pricingContent";
import PricingEdit from "./overview-contents/pricing-overview/pricingContentEdit";
import VideoEdit from "./overview-contents/video-overview/videoEdit";
import VideoContent from "./overview-contents/video-overview/videoOverview";
import VideoOverview from "./video-inside-overview/videos";
export default function CourseOverview() {
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [overviewData, setOverviewData] = useRecoilState(OverviewDataState);
  const [overviewRefecthTrigger, setOverviewRefecthTrigger] = useRecoilState(
    OverviewTriggerRefetch
  );
  const resetOverviewData = useResetRecoilState(OverviewDataState);
  const navigate = useNavigate();
  useEffect(() => {
    resetOverviewData();
    getAllCourse();
  }, [overviewRefecthTrigger]);

  const getAllCourse = async () => {
    const userCode = JSON.parse(localStorage.getItem("userCode"));
    const request = {
      apiUrl:
        API_CONSTANTS.BASE_URL +
        API_CONSTANTS.GET_COURSE_OVERVIEW +
        `/?courseCode=${userCode?.data}`,
      method: API_METHOD.GET,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      // alert(JSON.stringify(result.data[0].courseOverview));
      // reset();
      setOverviewData(result);
      // console.log("----------------------------------",overviewData?.data[0]?.courseOverview);
    } else if (result.status > 200) {
      console.log("errror", result);
    }
  };
  const alertUser = async () => {
    setLoading(true);
    swal({
      title: "Are you sure?",
      imageClass: "img-warning",
      buttons: true,
      buttons: ["Cancel", "Yes"],
      closeOnClickOutside: false,
      dangerMode: true,
    }).then(async (willDelete) => {
      setLoading(false);
      if (willDelete) {
        setLoading(true);
        const userCode = JSON.parse(localStorage.getItem("userCode"));
        const requestBodyParam = JSON.stringify({
          courseCode: userCode.data,
        });
        const request = {
          apiUrl:
            API_CONSTANTS.BASE_URL + API_CONSTANTS.SUBMIT_FOR_REVIEW_RECORDED,
          method: API_METHOD.POST,
          requestBodyParams: requestBodyParam,
        };
        let result = await API_CALL(request);
        if (result.status === 200) {
          swal("", "Successfully Submit For Review", "success").then(() => {
            setLoading(false);
            navigate("/courses/recorded");
          });
        } else if (result.status > 200) {
          swal({
            title: "ERROR",
            text: result.message,
            icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
            className: "error-window",
            button: true,
          });
        }
        setLoading(false);
      }
    });
  };

  let isBlock = true;
  if (overviewData?.data) {
    if (overviewData?.data[0]?.courseStatus === "INREVIEW") {
      isBlock = true;
    } else {
      isBlock = false;
    }
  }

  return (
    <div className="container overview mt-5">
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <Row>
          <Col sm={3}>
            <Nav variant="pills" className="flex-column overview-card">
              <Nav.Item>
                <Nav.Link eventKey="first">Overview</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="second">Pricing</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="third">Thumbnail &amp; Intro</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="fourth">Videos</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="fifth">FAQ</Nav.Link>
              </Nav.Item>

              <button
                className="  border-0 bg-prime text-white fz-14px py-2 rounded-3"
                onClick={alertUser}
                disabled={loading || isBlock ? true : false}
              >
                {loading ? "Loading..." : "Submit For Review"}
              </button>
            </Nav>
          </Col>
          <Col sm={9}>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                {isEdit ? (
                  <OverviewEdit setIsEdit={setIsEdit} />
                ) : (
                  <OverviewContent setIsEdit={setIsEdit} />
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                {isEdit ? (
                  <PricingEdit setIsEdit={setIsEdit} />
                ) : (
                  <PricingContent setIsEdit={setIsEdit} />
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="third">
                {isEdit ? (
                  <VideoEdit setIsEdit={setIsEdit} />
                ) : (
                  <VideoContent setIsEdit={setIsEdit} />
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="fourth">
                <VideoOverview />
              </Tab.Pane>
              <Tab.Pane eventKey="fifth">
                <CreateFaq />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
      <div className="py-5"></div>
    </div>
  );
}
