import { useEffect } from "react";
import "./progress-circle.css";

function CourseProgressCircle({ courseProgressPercentage }) {
  useEffect(() => {
    const meters = document.querySelectorAll("svg[data-value] .meter");

    meters.forEach((path) => {
      let length = path.getTotalLength();
      let value = parseInt(path.parentNode.getAttribute("data-value"));
      let to = length * ((100 - value) / 100);
      path.getBoundingClientRect();
      path.style.strokeDashoffset = Math.max(0, to);
      path.nextElementSibling.textContent = `${value}%`;
    });
  }, [courseProgressPercentage]);

  return (
    <div className="bg-white p-4 my-4 rounded-3">
      <h4 className="mb-2 fs-6">Course Progress</h4>
      <div className="d-flex justify-content-between align-items-center px-5">
        <span className="fs-3" style={{ color: "#395061" }}>
          {courseProgressPercentage}%
        </span>
        <svg
          viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          data-value={courseProgressPercentage}
          className="course-progress-circle"
          width="90"
          height="90"
        >
          <circle r="45" cx="50" cy="50" />
          <path
            class="meter"
            d="M5,50a45,45 0 1,0 90,0a45,45 0 1,0 -90,0"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-dashoffset="282.78302001953125"
            stroke-dasharray="282.78302001953125"
          />
          <text
            x="50"
            y="50"
            text-anchor="middle"
            dominant-baseline="central"
            font-size="20"
          ></text>
        </svg>
      </div>
    </div>
  );
}

export default CourseProgressCircle;
