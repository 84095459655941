import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { API_CALL } from "../../../../../api/ApiServiceCall";
import { API_CONSTANTS, API_METHOD } from "../../../../../common/constants";
import { allChapterDataListAtomFunc } from "../../../../atom/allChapterDataAtom";
import { getAllChapterAccordionsInCourseAtom } from "../../../../atom/callFunctionGetAllChapterAccordionsAtom";
import ChapterList from "../uploaded-videos/chapter-list/chapterList";
import CreateChapter from "../uploaded-videos/createChapter";

function MainUploadedVideoComponent() {
  const [isCreateChapterName, setIsCreateChapterName] = useState(false);
  // const [currentLessonResult, setCurrentLessonResult] = useState([]);
  // const [lessonLoader, setLessonLoader] = useState(false);
  const [
    callFunctionGetAllChapterAccordionsAtom,
    setCallFunctionGetAllChapterAccordionsAtom,
  ] = useRecoilState(getAllChapterAccordionsInCourseAtom);
  // const [trackOnLessonAccordionUpdatesAtom, setTrackOnLessonAccordionUpdatesAtom] = useRecoilState(getTrackOnLessonAccordionUpdatesAtom)
  const [allChapterDataListAtom, setAllChapterDataListAtom] = useRecoilState(
    allChapterDataListAtomFunc
  );

  useEffect(() => {
    getAllChapterAccordions();
  }, [callFunctionGetAllChapterAccordionsAtom.changeOccur]);

  const getAllChapterAccordions = async () => {
    const userCode = JSON.parse(localStorage.getItem("userCode"));
    const request = {
      apiUrl:
        API_CONSTANTS.BASE_URL +
        API_CONSTANTS.GET_ALL_CHAPTER +
        `/?courseCode=${userCode?.data}`,
      method: API_METHOD.GET,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      // alert(JSON.stringify(result.data[0].courseOverview));
      // reset();
      setAllChapterDataListAtom(result);
    } else if (result.status > 200) {
      console.log("errror", result);
    }
  };
  let isBlock = true;
  if (allChapterDataListAtom) {
    if (allChapterDataListAtom.data[0].courseStatus == "INREVIEW") {
      isBlock = true;
    } else {
      isBlock = false;
    }
  }

  return (
    <>
      {/* {<pre>{JSON.stringify(allChapterDataListAtom,null,4)}</pre>} */}
      <ChapterList />
      {/* Creating chapter form */}
      {isCreateChapterName ? (
        <CreateChapter setIsCreateChapterName={setIsCreateChapterName} />
      ) : (
        <div className="d-flex justify-content-end pt-4 ">
          <button
            className="border-0 bg-prime text-white fz-14px py-2 w-25 rounded-3 text-center"
            role="button"
            disabled={isBlock ? true : false}
            onClick={() => setIsCreateChapterName(true)}
          >
            + Create New Chapter
          </button>
        </div>
      )}
    </>
  );
}

export default MainUploadedVideoComponent;
