import moment from 'moment';
import React from 'react'

function GetTimeDifference({ cList }) {
    let startTime = moment(cList?.startTime, "HH:mm");
    let endTime = moment(cList?.liveClassEndedTime, "HH:mm");
    let duration = moment.duration(endTime.diff(startTime));
    let hours = parseInt(duration.asHours());
    let minutes = parseInt(duration.asMinutes()) - hours * 60;
    let result = hours + ' hour and ' + minutes + ' minutes.'

    return (
        <>
        {result}</>
    )
}

export default GetTimeDifference