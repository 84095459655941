import {
    provideDyteDesignSystem,
} from '@dytesdk/react-ui-kit';
import {
    DyteProvider,
    useDyteClient,
} from '@dytesdk/react-web-core';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import InstructorDyteCall from "../../components/dyteCalls/instructorDyteCall";
import StudentDyteCall from '../../components/dyteCalls/studentDyteCall';

function AppRoomAuth() {
    const { roomId } = useParams();
    const authToken = roomId;
    console.log("authToken for app is: ", authToken);

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const userType = query.get('usertype'); 
    console.log("userType data for app is: ", userType);

    const [meeting, initMeeting] = useDyteClient();
    const [isDyteLoaded, setIsDyteLoaded] = useState(false);

    useEffect(() => {
        if (!authToken) {
            console.log("==============================================================================");
            return;
        }
        if (authToken) {
            provideDyteDesignSystem(document.body, {
                theme: 'light',
            });

            initMeeting({
                authToken,
                defaults: {
                    audio: false,
                    video: false,
                },
            }).then((meet) => {
                meet.join();
                setIsDyteLoaded(true);
            })
        }
    }, [])

  // ---onEnd Live code
  const onLiveAppEnd = async () => {
    if (window.ReactNativeWebView) {
      // ensure window.ReactNativeWebView is there, otherwise, web app might crash if is not there
      window.ReactNativeWebView.postMessage("LIVE_END");
    }
  };
  // ---onEnd Live code End INSTRUCTOR_LEFT

   const onInstructorLeave = async () => {
     if (window.ReactNativeWebView) {
       // ensure window.ReactNativeWebView is there, otherwise, web app might crash if is not there
       window.ReactNativeWebView.postMessage("INSTRUCTOR_LEFT");
     }
   };

    if (userType && isDyteLoaded) {
        return (
          <DyteProvider
            value={meeting}
            fallback={<div>Loading... Please Wait.</div>}
          >
            {userType == "INSTRUCTOR" ? (
              <InstructorDyteCall
                handleEnd={() => onLiveAppEnd()}
                handleLeave={() => onInstructorLeave()}
              />
            ) : (
              <StudentDyteCall handleStudentEnd={() => onLiveAppEnd()} />
            )}
          </DyteProvider>
        );
    }

    return (
        <div className=' align-items-center justify-content-center vh-100 d-flex'>
            <span className="spinner-border spinner-border-lg text-prime" role="status" aria-hidden="true"></span>
            <span className="visually-hidden">Loading...Dyte.</span>
        </div>
    )
};

export default AppRoomAuth