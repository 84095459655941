import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { AiFillFile, AiOutlineCloseCircle } from "react-icons/ai";
import { FaEnvelope } from "react-icons/fa";
import { IoMdSend } from "react-icons/io";
import { MdOutlineClose } from "react-icons/md";
import swal from "sweetalert";
import { socket } from "../../../common/constants";
import "../../../css/chat.css";
import "../../../css/smallUtilities.css";
import ErroImg from "../../../images/icons/error.png";
import AttachIcon from "../../svg-icons/attachIcon";

// let socket = io("wss://api.dev.qlearning.academy/ticket");
const attachStyle = {
  position: "absolute",
  left: "26px",
  top: "12px",
  bottom: "0",
  zIndex: 1,
  right: "auto",
};
const pxMed = {
  padding: "13px 35px",
};
const filePreview = {
  position: "absolute",
  zIndex: "9",
  width: "100%",
  textAlign: "center",
  bottom: "-7px",
  background: "rgb(248 248 248)",
  height: "120px",
};
const closeFile = {
  position: "absolute",
  left: "5px",
  top: "8px",
};
const aln = {
  right: "18px",
  position: "absolute",
  top: "auto",
  bottom: "auto",
};

function RecordedRiseTicketUi({ review, currentCourseObj }) {
  const [isOpenChatWindow, setisOpenChatWindow] = useState(false);
  const message = useRef("");
  const [msgList, setMsgList] = useState([]);
  const [status, setStatus] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [localUserData, setLocalUserData] = useState(
    JSON.parse(localStorage.getItem("localUserData"))
  );
  const [file, setFile] = useState({
    fileData: "",
    fileName: "",
  });
  const [loading, setLoading] = useState(false);

  console.log(socket.connected);

  const receiveMessages = useCallback((response) => {
    console.log("recev live chat-------->");
    console.log(response);
    if (response.type == "FILE") {
      setMsgList((chatMessages) => [
        ...chatMessages,
        {
          userName: response.userName,
          time: response.createdAt,
          message: response.message,
          type: response.type,
          fileName: response.fileName,
        },
      ]);
    } else {
      setMsgList((chatMessages) => [
        ...chatMessages,
        {
          userName: response.userName,
          time: response.createdAt,
          message: response.message,
        },
      ]);
    }
  }, []);
  useEffect(() => {
    if (isOpenChatWindow) {
      socket.open();

      socket.on("connection-success", async (response) => {
        console.log(`Socket connected ${response.socketId}`);
      });

      socket.emit(
        "join-instructor",
        {
          courseCode: currentCourseObj?.courseCode,
          userName: JSON.parse(localStorage.getItem("localUserData")).name,
        },
        async (res) => {
          console.log(`join-instructor ${res}`);
          console.log(res);
        }
      );

      socket.emit(
        "getPreviousMessage",
        { courseCode: currentCourseObj?.courseCode, ticketType: "COURSE" },
        (response) => {
          console.log("getPreviousMessage");
          console.log(response);

          setMsgList((chatMessages) => [...response]);
        }
      );
      // ======To reset msg count=========
      socket.emit(
        "resetMessageCount",
        {
          courseCode: currentCourseObj?.courseCode,
          userType: JSON.parse(localStorage.getItem("localUserData")).userType,
        },
        (response) => {
          // console.log("Reset Message Count Ran!", response)
        }
      );
      // ======To reset msg end=========

      socket.on("message", receiveMessages);
    } else {
      socket.off("message", receiveMessages);
      socket.close();
      socket.on("disconnect", function () {
        console.log("client disconnected from server");
      });
    }
    return () => {
      socket.close();
      socket.on("disconnect", function () {
        console.log("client disconnected from server");
      });
    };
  }, [isOpenChatWindow]);

  // For text msg
  const sendMsg = (e) => {
    e.preventDefault();
     if (isSubmitting || !message.current.value) return;
        setIsSubmitting(true);
    if (message.current.value) {
      // console.log(
      //     {
      //         message: message.current.value,
      //         courseCode: currentCourseObj?.courseCode,
      //         roomName: currentCourseObj?.courseCode,
      //         userName: localUserData.name,
      //         ticketType: "COURSE",
      //         courseType: "RECORDED",
      //         userId: localUserData.userId,
      //         type: "TEXT"
      //     }
      // )

      socket.emit(
        "sendMessage",
        {
          message: message.current.value,
          courseCode: currentCourseObj?.courseCode,
          roomName: currentCourseObj?.courseCode,
          userName: localUserData.name,
          ticketType: "COURSE",
          courseType: "RECORDED",
          userId: localUserData.userId,
          type: "TEXT",
        },
        (responseData) => {
          console.log("sendMessage callback");
          console.log(responseData);
          if (responseData.success) {
            setMsgList((chatMessages) => [
              ...chatMessages,
              {
                userName: localUserData.name,
                dt: moment().format(),
                message: message.current.value,
              },
            ]);
            setTimeout(() => {
              message.current.value = "";
            }, 500);
          }
        }
      );
      setTimeout(() => {
        setIsSubmitting(false);
      }, 1000);
      // finalSend();
    }
  };
  // For file upload

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      sendMsg(e);
    }
  };

  const sendFiles = (e) => {
    if (e.target.files) {
      let files = e.target.files;
      setFile({
        fileData: files[0],
        fileName: files[0].name,
      });
    }
  };
  const onConfrimUpload = () => {
    if (file && file.fileData && file.fileData.size > 1024 * 1024) {
      // File size is greater than 1MB, show a SweetAlert popup
      swal({
        title: "File Size Limit Exceeded",
        text: "Please upload a file smaller than 1MB.",
        icon: ErroImg,
        className: "error-window",
        button: true,
        closeOnClickOutside: false,
      });
      return;
    }
    setLoading(true);
    socket.emit(
      "upload",
      {
        file: file?.fileData,
        fileName: file?.fileName,
        courseCode: currentCourseObj?.courseCode,
        roomName: currentCourseObj?.courseCode,
        userName: localUserData.name,
        userId: localUserData?.userId,
        ticketType: "COURSE",
        courseType: "RECORDED",
        type: "FILE",
      },
      (res) => {
        console.log(res);
        setMsgList((chatMessages) => [
          ...chatMessages,
          {
            userName: res?.userName,
            dt: moment().format(),
            type: "FILE",
            fileName: res.fileName,
            message: res.message,
          },
        ]);
        setLoading(false);
        setFile({
          fileData: "",
          fileName: "",
        });
      }
    );
  };
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [msgList]);
  useEffect(() => {
    if (
      currentCourseObj?.courseStatus == "BANNED" ||
      currentCourseObj?.courseStatus == "REJECTED"
    ) {
      setStatus("Rejected");
    }
    if (currentCourseObj?.courseStatus == "INREVIEW") {
      setStatus("In Review");
    }
    if (currentCourseObj?.courseStatus == "ACTIVE") {
      setStatus("Active");
    }
  }, [currentCourseObj?.courseStatus]);

  const openChatWindow = () => {
    setisOpenChatWindow(!isOpenChatWindow);
  };
  const onCancelUpload = () => {
    setFile({
      fileData: "",
      fileName: "",
    });
  };
  return (
    <div className="col position-relative">
      {/* {JSON.stringify(msgList)} */}

      <div className=" justify-content-end d-flex">
        <button
          className={`rounded-pill   px-4 py-2 fz-15px border-0 fw-500 text-white ${currentCourseObj?.courseStatus}`}
          onClick={review}
        >
          {status}
        </button>
      </div>
      <div className=" h-75  justify-content-end d-flex align-items-end ">
        {isOpenChatWindow ? (
          <div className="chatWindowRaiseTicket ticket-chat">
            <div className="d-flex justify-content-between px-4 pt-4 align-items-lg-start">
              <h6 className="m-0 p-0">Ticket</h6>{" "}
              <div
                onClick={() => setisOpenChatWindow(false)}
                className="adjus-x"
              >
                <MdOutlineClose />
              </div>
            </div>
            <div className="chatHeight position-relative">
              {msgList?.map((val, index) => (
                <div key={index} className="py-1 " ref={messagesEndRef}>
                  <p
                    className={`${
                      val?.userName == localUserData.name
                        ? "text-end"
                        : "text-start"
                    } m-0 p-0 fw-600 fz-14px`}
                  >
                    <span className="text-muted fz-10px fw-400 me-1 ">
                      {moment(val?.dt).calendar()}
                    </span>
                    {val?.userName == localUserData.name ? "Me" : val?.userName}
                  </p>
                  {val?.type == "FILE" ? (
                    <div
                      className={` d-block   ${
                        val?.userName?.replace(/\s/g, "") ==
                        localUserData.name?.replace(/\s/g, "")
                          ? "text-end"
                          : "text-start"
                      } `}
                    >
                      <a
                        className={`text-decoration-none text-prime d-inline-flex  fw-500 text-prime  fz-12px bg-pink py-2 px-3 rounded `}
                        href={val?.message}
                        title={val?.message?.split("/").pop()}
                      >
                        {val?.message?.split("/").pop().substr(0, 30)}...
                        <img
                          src="https://cdn-icons-png.flaticon.com/512/2820/2820279.png"
                          className="ms-2  d-block"
                          style={{
                            height: "18px",
                            width: "18px",
                            objectFit: "contain",
                          }}
                        />
                      </a>
                    </div>
                  ) : (
                    <div
                      className={`${
                        val?.userName == localUserData.name
                          ? "text-end"
                          : "text-start"
                      } fw-500 fz-15px`}
                    >
                      {val?.message.split("\n").map((line, i) => (
                        <p key={i} className="m-0 p-0">
                          {line}
                        </p>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
            {file?.fileName ? (
              <div style={filePreview} className="p-3">
                <AiFillFile size={30} color={"grey"} />
                <div className="d-flex justify-content-center align-items-center">
                  <p className="fz-13px m-0">{file?.fileName}</p>{" "}
                  <button
                    className="bg-transparent border-0"
                    disabled={loading ? true : false}
                    style={closeFile}
                    onClick={onCancelUpload}
                  >
                    <AiOutlineCloseCircle size={18} />
                  </button>
                  <button
                    className="bg-transparent border-0"
                    style={aln}
                    onClick={onConfrimUpload}
                    role="button"
                    disabled={loading ? true : false}
                  >
                    {loading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Loading...</span>
                      </>
                    ) : (
                      <IoMdSend className="ms-2" size={20} />
                    )}
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
            <form
              className="w-100 d-flex px-3 pb-2  position-relative"
              onSubmit={sendMsg}
            >
              <input
                className="d-none"
                id="attachFile"
                type="file"
                accept="image/gif, image/jpeg, image/png"
                onChange={(event) => {
                  sendFiles(event);
                }}
                onClick={(event) => {
                  event.target.value = null;
                }}
              />
              <label htmlFor="attachFile" role="button">
                <span style={attachStyle}>
                  <AttachIcon />
                </span>
              </label>
              <textarea
                className=" default-inpt rounded-9 pe-4"
                style={{
                  overflow: "hidden",
                  padding: "13px 40px",
                  maxHeight: "50px",
                  height: "50px",
                  minHeight: "50px",
                  resize: "none",
                }}
                placeholder="write a message..."
                type={"text"}
                ref={message}
                onKeyDown={handleKeyDown}
                disabled={isSubmitting}
              />
              <button
                className="border-0 align-arrow  fz-18px bg-transparent text-prime"
                type="submit"
                disabled={isSubmitting}
              >
                <IoMdSend />
              </button>
            </form>
          </div>
        ) : (
          ""
        )}

        <button
          onClick={openChatWindow}
          className=" d-flex justify-content-center align-items-center rounded-9 bg-second w-100 py-3 fz-15px border-0 fw-500 text-prime"
        >
          <FaEnvelope className="me-1" /> Raise a Ticket
        </button>
        {currentCourseObj?.newMessageCount ? (
          <div className="position-relative">
            <div className="ticket-notification-badge">
              {currentCourseObj?.newMessageCount}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default RecordedRiseTicketUi;

// =======Previous code==========

// const finalSend = async () => {
//     let msgObj = {
//         message: message.current.value,
//         messageTime: moment().format()
//     }
//     let msgArry = [msgObj]
//     const requestBodyParam = JSON.stringify({
//         courseCode: currentCourseObj.courseCode,
//         messages: msgArry
//     })

//     const request = {
//         apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.RAISE_COURSE_TICKET,
//         method: API_METHOD.POST,
//         requestBodyParams: requestBodyParam,
//     }
//     let result = await API_CALL(request)
//     if (result.status === 200) {
//         message.current.value = "";
//     } else if (result.status > 200) {
//         swal({
//             title: "ERROR",
//             text: result?.message,
//             icon: 'https://cdn-icons-png.flaticon.com/512/2569/2569174.png',
//             className: "error-window",
//             button: true,
//         })
//     }
// }
// useEffect(() => {
//     getAllMsg();
// }, [])

// const getAllMsg = async () => {

//     const request = {
//         apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.GET_OPEN_TICKET_BY_COURSE_CODE + `/?courseCode=${currentCourseObj?.courseCode}`,
//         method: API_METHOD.GET,
//     }
//     let result = await API_CALL(request)
//     if (result.status === 200) {

//         setMsgList(result.data[0].messages);

//     } else if (result.status > 200) {
//         swal({
//             title: "ERROR",
//             text: result.message,
//             icon: 'https://cdn-icons-png.flaticon.com/512/2569/2569174.png',
//             className: "error-window",
//             button: true,
//         })
//     }
// }
