import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { useRecoilState } from "recoil";
import swal from "sweetalert";
import { API_CALL } from "../../../../api/ApiServiceCall";
import { API_CONSTANTS, API_METHOD } from "../../../../common/constants";
import { getCredentialLoginAccountProfileDetailAtom } from "../../../atom/getCredentialLoginAccountProfileDetail";

function UserProfile() {
  const [firstName, setFirstName] = useState({ value: "", error: "" });
  const [middleName, setMiddleName] = useState();
  const [lastName, setLastName] = useState({ value: "", error: "" });
  const [about, setAbout] = useState({ value: "", error: "" });
  const [mobileNo, setMobileNo] = useState({ value: "", error: "" });
  const [experience, setExperience] = useState({ value: "", error: "" });
  const [chipData, setChipData] = useState({ value: "", error: "" });
  const [chip, setChip] = useState([]);
  const [available, setAvailable] = useState({ value: "", error: "" });
  const [fromTime, setFromTime] = useState({ value: "", error: "" });
  const [fb, setFb] = useState({
    value: "https://www.facebook.com/",
    error: "",
  });
  const [insta, setInsta] = useState({
    value: "https://www.instagram.com/",
    error: "",
  });
  const [twitter, setTwitter] = useState({
    value: "https://twitter.com/",
    error: "",
  });
  const [linkdin, setLinkdin] = useState({
    value: "https://www.linkedin.com/",
    error: "",
  });
  const [toTime, setToTime] = useState({ value: "", error: "" });
  const [loading, setLoading] = useState(false);
  const [getCredentialUserDetail, setCredentialUserDetail] = useState(
    JSON.parse(localStorage.getItem("localUserData"))
  );
  const [
    credentialLoginAccountProfileDetailAtom,
    setCredentialLoginAccountProfileDetailAtom,
  ] = useRecoilState(getCredentialLoginAccountProfileDetailAtom);
  const firstNameFocusRef = useRef(null);
  const LastNameFocusRef = useRef(null);
  const aboutFocusRef = useRef(null);
  const xpFocusRef = useRef(null);
  const chipFocusRef = useRef(null);
  const availableFieldFocusRef = useRef(null);
  const fromTimeFocusRef = useRef(null);
  const toTimeFocusRef = useRef(null);
  // ---------------------api call getAllProfileData------------
  useEffect(() => {
    getAllProfileData();
  }, [credentialLoginAccountProfileDetailAtom?.data?.profileImgPath]);

  // ---End
  // --------------------------Get Profile Data API-----------
  const getAllProfileData = async () => {
    const userCode = JSON.parse(localStorage.getItem("userCode"));
    const request = {
      apiUrl:
        API_CONSTANTS.BASE_URL +
        API_CONSTANTS.GET_INSTRUCTOR_BY_EMAIL +
        `?instructorEmail=${
          getCredentialUserDetail?.email ||
          getCredentialUserDetail?.data?.user?.email
        }`,
      method: API_METHOD.GET,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      setCredentialLoginAccountProfileDetailAtom(result);
      setFormDatas(result.data); //function call
    } else {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }
  };

  // ----------End
  // Setting Data For form from API-------------------------------
  const setFormDatas = (apiData) => {
    setFirstName({ value: apiData.firstName, error: "" });
    setMiddleName(apiData.middleName);
    setLastName({ value: apiData.lastName, error: "" });
    setAbout({ value: apiData.aboutYou, error: "" });
    setExperience({ value: apiData.yearsOfExperience, error: "" });
    setChip(apiData?.categories || []);
    setAvailable({ value: apiData.availablePerHours, error: "" });
    setFromTime({ value: apiData.fromTime, error: "" });
    setFb({ value: apiData.facebook, error: "" });
    setInsta({ value: apiData.instagram, error: "" });
    setTwitter({ value: apiData.twitter, error: "" });
    setLinkdin({ value: apiData.linkedin, error: "" });
    setToTime({ value: apiData.toTime, error: "" });
    setMobileNo(apiData.mobileNumber);
  };

  // END------------
  //Form change
  const onChange = (e) => {
    const { name, value } = e.target;
    if (name == "firstName") {
      if (value) {
        setFirstName({ value: e.target.value, error: "" });
      } else {
        setFirstName({ value: "", error: "Please fill this field" });
      }
    }
    if (name == "middleName") {
      if (value) {
        setMiddleName(e.target.value);
      }
    }
    if (name == "lastName") {
      if (value) {
        setLastName({ value: e.target.value, error: "" });
      } else {
        setLastName({ value: "", error: "Please fill this field" });
      }
    }
    if (name == "about") {
      if (value) {
        setAbout({ value: e.target.value, error: "" });
      } else {
        setAbout({ value: "", error: "Please fill this field" });
      }
    }
    if (name == "experience") {
      if (value) {
        setExperience({ value: e.target.value, error: "" });
      } else {
        setExperience({ value: "", error: "Please fill this field" });
      }
    }
    if (name == "available") {
      if (Number(value) <= 0 || Number(value) > 24) {
        if (value === "") {
          setAvailable({ value: "", error: "Please fill this field" });
        } else {
          setAvailable({ value: "", error: "Invalid" });
        }
      } else {
        setAvailable({ value: e.target.value, error: "" });
      }
    }
   if (name == "fromTime") {
      if (value) {
        if (
          moment(e.target.value, "h:mm").isBefore(
            moment(toTime.value, "h:mm")
          )
        ){
        setToTime({ value: toTime.value, error: "" });
 
        }
        setFromTime({ value: e.target.value, error: "" });
      } else {
        setFromTime({ value: "", error: "Please fill this field" });
      }
    }
    if (name == "toTime") {
      if (value) {
        if (
          moment(e.target.value, "h:mm").isAfter(
            moment(fromTime.value, "h:mm")
          )
        ) {
          setFromTime({ value: fromTime.value, error: "" });
        }
        setToTime({ value: e.target.value, error: "" });
      } else {
        setToTime({ value: "", error: "Please fill this field" });
      }
    }
    if (name == "fb") {
      if (value.startsWith("https://www.facebook.com/")) {
        setFb({ value: e.target.value, error: "" });
      } else {
        setFb({ value: e.target.value, error: "Invalid" });
      }
    }
    if (name == "insta") {
      if (value.startsWith("https://www.instagram.com/")) {
        setInsta({ value: e.target.value, error: "" });
      } else {
        setInsta({ value: e.target.value, error: "Invalid" });
      }
    }
    if (name == "linkdin") {
      if (value.startsWith("https://www.linkedin.com/")) {
        setLinkdin({ value: e.target.value, error: "" });
      } else {
        setLinkdin({ value: e.target.value, error: "Invalid" });
      }
    }
    if (name == "twitter") {
      if (value.startsWith("https://twitter.com/")) {
        setTwitter({ value: e.target.value, error: "" });
      } else {
        setTwitter({ value: e.target.value, error: "Invalid" });
      }
    }
  };
  //onSubmit
  const onSubmit = (e) => {
    e.preventDefault();
    let hasError = false;

    const checkField = (field, setField, focusRef) => {
      if (!field.value) {
        setField({ value: "", error: "Please fill this field" });
        focusRef.current.focus();
        hasError = true;
      }
    };

    checkField(toTime, setToTime, toTimeFocusRef);
    checkField(fromTime, setFromTime, fromTimeFocusRef);
    checkField(available, setAvailable, availableFieldFocusRef);
    checkField(experience, setExperience, xpFocusRef);
    checkField(about, setAbout, aboutFocusRef);
    checkField(lastName, setLastName, LastNameFocusRef);
    checkField(firstName, setFirstName, firstNameFocusRef);

    if (!chipData.value && chip.length < 1) {
      setChipData({ ...chipData, error: "Please fill this field" });
      chipFocusRef.current.focus();
      hasError = true;
    }

    if (hasError) {
      return;
    }

    const errorList = [
      firstName.error,
      lastName.error,
      about.error,
      experience.error,
      chipData.error,
      available.error,
      fromTime.error,
      fb.error,
      insta.error,
      twitter.error,
      linkdin.error,
      toTime.error,
    ];
    const isErrorFilter = errorList.filter((err) => err !== "");
    if (isErrorFilter.length > 0) {
      return;
    }
    (async () => {
      setLoading(true);
      const requestBodyParam = JSON.stringify(
        {
          yearsOfExperience: experience.value,
          availablePerHours: available.value,
          aboutYou: about.value,
          instructorFullname:
            firstName.value.trim() + middleName.trim() + lastName.value.trim(),
          categories: chip,
          facebook: fb.value,
          instagram: insta.value,
          linkedin: linkdin.value,
          twitter: twitter.value,
          fromTime: fromTime.value,
          toTime: toTime.value,
          firstName: firstName.value.trim(),
          lastName: lastName.value.trim(),
          middleName: middleName.trim(),
          mobileNumber: mobileNo.value,
        },
        null,
        4
      );
      const request = {
        apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.CREATE_INSTRUCTOR,
        method: API_METHOD.POST,
        requestBodyParams: requestBodyParam,
      };
      try {
        const result = await API_CALL(request);
        if (result.status === 200) {
          setLoading(false);
          getAllProfileData();
          swal("", "Successfully Submitted Profile", "success");
        } else {
          swal({
            title: "ERROR",
            text: result.message,
            icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
            className: "error-window",
            button: true,
          });
          setLoading(false);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        setLoading(false);
      }
    })();
  };

  // END-------------------------------

  //Chip
  let chipsChange = (e) => {
    if (e.target.value) {
      setChipData({ value: e.target.value, error: "" });
    } else {
      if (chip.length < 1) {
        setChipData({ value: "", error: "Please fill this field" });
      } else {
        setChipData({ value: "", error: "" });
      }
    }
  };
  const handleEnter = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      if (chipData.value.trim() && chipData.value != "") {
        const isDuplicateValue = chip.every((chipsName) => {
          if (
            chipsName.toUpperCase().trim() ===
            e.target.value.toUpperCase().trim()
          ) {
            return false;
          }
          return true;
        });
        if (isDuplicateValue) {
          setChip((oldArray) => [...oldArray, chipData.value]);
        }
        setChipData({ value: "", error: "" });
      }
    }
  };
  const onDelete = (myId) => {
    const updates = chip.filter((each, idx) => idx !== myId);
    setChip(updates);
  };
  return (
    <div id="profilePage" className="login-col pt-5 ">
      <form onSubmit={onSubmit}>
        <div className="row">
          <div className="col">
            <label className="fw-400" htmlFor="firstName">
              First Name<span className="text-danger">*</span>
            </label>
            <input
              className="mb-2"
              placeholder="First name "
              type="text"
              name="firstName"
              defaultValue={firstName.value}
              ref={firstNameFocusRef}
              onChange={onChange}
              onBlur={(e) => {
                if (e.target.value.trim() === "") {
                  setFirstName({
                    value: e.target.value,
                    error: "First Name is required",
                  });
                }
              }}
            />
            <small className="text-danger mb-2 d-block fz-12px fw-500">
              {firstName.error ? firstName.error : ""}
            </small>
          </div>
          <div className="col">
            <label className="fw-400" htmlFor="middleName">
              Middle Name
            </label>
            <input
              defaultValue={middleName}
              className="mb-2"
              placeholder="Middle name "
              type="text"
              id="middleName"
              name="middleName"
              onChange={onChange}
            />
          </div>
          <div className="col">
            <label className="fw-400" htmlFor="lastName">
              Last Name<span className="text-danger">*</span>
            </label>
            <input
              className="mb-2"
              placeholder="Last name "
              type="text"
              id="lastName"
              name="lastName"
              ref={LastNameFocusRef}
              defaultValue={lastName.value}
              onChange={onChange}
              onBlur={(e) => {
                if (e.target.value.trim() === "") {
                  setLastName({
                    value: e.target.value,
                    error: "Last Name is required",
                  });
                }
              }}
            />
            <small className="text-danger mb-2 d-block fz-12px fw-500">
              {lastName.error ? lastName.error : ""}
            </small>
          </div>
        </div>
        <div className="col">
          <label className="fw-400" htmlFor="about">
            About You<span className="text-danger">*</span>
          </label>
          <textarea
            className="default-inpt"
            rows="5"
            name="about"
            ref={aboutFocusRef}
            defaultValue={about.value}
            onChange={onChange}
            placeholder="Tell something about you..."
            onBlur={(e) => {
              if (e.target.value.trim() === "") {
                setAbout({ value: e.target.value, error: "About is required" });
              }
            }}
          />
          <small className="text-danger mb-2 d-block fz-12px fw-500">
            {about.error ? about.error : ""}
          </small>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <label className="pt-3 fw-400">Mobile Number</label>
            <input
              placeholder="Enter Mobile Number"
              value={mobileNo}
              className="ph-frm border-0"
              disabled
              onChange={(e) => setMobileNo(e.target.value)}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <label className="pt-3 fw-400">
            Experience<span className="text-danger">*</span>
          </label>
          <div className="position-relative">
            <span className="position-absolute hrs">Years</span>
            <input
              type="text"
              maxLength={2}
              placeholder="Example: 10"
              name="experience"
              ref={xpFocusRef}
              defaultValue={experience.value}
              onChange={onChange}
              required
              onBlur={(e) => {
                if (!e.target.value.match(/^\d+(\.\d+)?$/)) {
                  setExperience({
                    value: e.target.value,
                    error: "Invalid experience",
                  });
                }
              }}
            />
          </div>
          <small className="text-danger mb-2 d-block fz-12px fw-500">
            {experience.error ? experience.error : ""}
          </small>
        </div>
        <div className="col-lg-12">
          <label className="pt-3 mb-1 fw-400">
            Categories Of Expertise<span className="text-danger">*</span>
          </label>
          <div className="pb-2 word-break-all">
            {chip.map((datas, i) => (
              <li key={i} className="chip-style" onClick={() => onDelete(i)}>
                <IoMdClose className="ps-end" /> {datas}
              </li>
            ))}
          </div>
          <div className="justify-content-lg-start w-100">
            <input
              type="text"
              placeholder="category"
              ref={chipFocusRef}
              value={chipData.value}
              onKeyDown={handleEnter}
              onChange={chipsChange}
            />
          </div>
          <small className="text-danger mb-2 d-block fz-12px fw-500">
            {chipData.error ? chipData.error : ""}
          </small>
        </div>
        <div className="row">
          <div className="col-lg-5">
            <label className="pt-3 fw-400">
              Available In A Week<span className="text-danger">*</span>
            </label>
            <div className="position-relative">
              <span className="position-absolute hrs">Hrs</span>
              <input
                type="number"
                maxLength={2}
                name="available"
                ref={availableFieldFocusRef}
                defaultValue={available.value}
                onChange={onChange}
                onBlur={(e) => {
                  if (
                    e.target.value.trim() === "" ||
                    !e.target.value.match(/^\d+$/)
                  ) {
                    setAvailable({
                      value: e.target.value,
                      error: "Invalid available hours",
                    });
                  }
                }}
              />
              <small className="text-danger mb-2 d-block fz-12px fw-500">
                {available.error ? available.error : ""}
              </small>
            </div>
          </div>
          <div className="col-lg-7">
            <label className="pt-3 fw-400">
              Available Time<span className="text-danger">*</span>
            </label>
            <div className="row">
              <div className="col d-flex align-items-baseline">
                From:
                <input
                  type="time"
                  name="fromTime"
                  ref={fromTimeFocusRef}
                  defaultValue={fromTime.value}
                  onChange={onChange}
                  onBlur={(e) => {
                    if (
                      toTime.value &&
                      !moment(e.target.value, "HH:mm").isBefore(
                        moment(toTime.value, "HH:mm")
                      )
                    ) {
                      setFromTime({ value: e.target.value, error: "Invalid" });
                    }
                  }}
                />
              </div>
              <div className="col d-flex align-items-baseline">
                To:
                <input
                  type="time"
                  name="toTime"
                  ref={toTimeFocusRef}
                  defaultValue={toTime.value}
                  onChange={onChange}
                  onBlur={(e) => {
                    if (
                      fromTime.value &&
                      !moment(e.target.value, "HH:mm").isAfter(
                        moment(fromTime.value, "HH:mm")
                      )
                    ) {
                      setToTime({ value: e.target.value, error: "Invalid" });
                    }
                  }}
                />
              </div>
              <div className="row">
                <div className="col">
                  <small className="text-danger mb-2 d-block fz-12px fw-500">
                    {fromTime.error ? fromTime.error : ""}
                  </small>
                </div>
                <div className="col">
                  <small className="text-danger mb-2 d-block px-3 fz-12px fw-500">
                    {toTime.error ? toTime.error : ""}
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
        <label className="fw-400">Social Link (Optional)</label>
        <div className="cst-drop p-5">
          <div className="d-flex">
            <svg
              className="position-relative trans-y50 soc-ico"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.0012 2.50244C8.09869 2.50244 2.50244 8.09869 2.50244 15.0012C2.50244 21.2387 7.07244 26.4087 13.0487 27.3499V18.6149H9.87369V15.0012H13.0487V12.2474C13.0487 9.11244 14.9149 7.38369 17.7687 7.38369C19.1362 7.38369 20.5687 7.62744 20.5687 7.62744V10.7012H18.9887C17.4387 10.7012 16.9537 11.6662 16.9537 12.6549V14.9987H20.4174L19.8637 18.6124H16.9537V27.3474C22.9299 26.4112 27.4999 21.2399 27.4999 15.0012C27.4999 8.09869 21.9037 2.50244 15.0012 2.50244Z"
                fill="#395061"
              />
            </svg>
            <div className="d-flex flex-column w-100">
              <input
                type="text"
                name="fb"
                value={fb.value}
                onChange={onChange}
                onBlur={() => {
                  if (fb.error) {
                    setFb({ value: "https://www.facebook.com/", error: "" });
                  }
                }}
                style={{
                  color: "blue",
                  textDecoration: "underline",
                }}
                className="bg-white mb-2 "
              />
            </div>
          </div>
          <small className="text-danger mb-2 d-block fz-12px fw-500">
            {fb.error ? fb.error : ""}
          </small>

          <div className="d-flex ">
            <svg
              className="position-relative trans-y50 soc-ico"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.9987 9.22135C11.8062 9.22135 9.21994 11.8088 9.21994 15.0001C9.21994 18.1926 11.8062 20.7801 14.9987 20.7801C18.1887 20.7801 20.7774 18.1926 20.7774 15.0001C20.7774 11.8088 18.1887 9.22135 14.9987 9.22135ZM14.9987 18.7551C12.9249 18.7551 11.2437 17.0738 11.2437 15.0013C11.2437 12.9276 12.9249 11.2476 14.9987 11.2476C17.0724 11.2476 18.7512 12.9276 18.7512 15.0013C18.7512 17.0738 17.0724 18.7551 14.9987 18.7551Z"
                fill="#395061"
              />
              <path
                d="M21.0074 10.3563C21.7516 10.3563 22.3549 9.75305 22.3549 9.00885C22.3549 8.26464 21.7516 7.66135 21.0074 7.66135C20.2632 7.66135 19.6599 8.26464 19.6599 9.00885C19.6599 9.75305 20.2632 10.3563 21.0074 10.3563Z"
                fill="#395061"
              />

              <path
                d="M25.6662 7.63885C25.0799 6.1276 23.8862 4.9326 22.3749 4.34885C21.5012 4.0201 20.5774 3.84385 19.6424 3.82385C18.4387 3.77135 18.0574 3.75635 15.0049 3.75635C11.9524 3.75635 11.5612 3.75635 10.3674 3.82385C9.43494 3.8426 8.51119 4.01885 7.63744 4.34885C6.12494 4.9326 4.93119 6.1276 4.34619 7.63885C4.01744 8.51385 3.84119 9.43635 3.82244 10.3713C3.76869 11.5738 3.75244 11.9551 3.75244 15.0088C3.75244 18.0613 3.75244 18.4501 3.82244 19.6463C3.84119 20.5813 4.01744 21.5038 4.34619 22.3801C4.93244 23.8901 6.12619 25.0851 7.63869 25.6701C8.50869 26.0101 9.43244 26.2026 10.3699 26.2326C11.5737 26.2851 11.9549 26.3013 15.0074 26.3013C18.0599 26.3013 18.4512 26.3013 19.6449 26.2326C20.5787 26.2138 21.5024 26.0363 22.3774 25.7088C23.8887 25.1226 25.0824 23.9288 25.6687 22.4176C25.9974 21.5426 26.1737 20.6201 26.1924 19.6851C26.2462 18.4826 26.2624 18.1013 26.2624 15.0476C26.2624 11.9938 26.2624 11.6063 26.1924 10.4101C26.1762 9.4626 26.0012 8.52385 25.6662 7.63885ZM24.1437 19.5538C24.1349 20.2738 24.0049 20.9876 23.7549 21.6638C23.3737 22.6476 22.5974 23.4251 21.6149 23.8026C20.9462 24.0513 20.2412 24.1813 19.5274 24.1913C18.3399 24.2463 18.0049 24.2601 14.9599 24.2601C11.9124 24.2601 11.6012 24.2601 10.3912 24.1913C9.67994 24.1826 8.97244 24.0513 8.30494 23.8026C7.31869 23.4263 6.53744 22.6488 6.15619 21.6638C5.91119 20.9963 5.77869 20.2901 5.76744 19.5776C5.71369 18.3901 5.70119 18.0551 5.70119 15.0101C5.70119 11.9638 5.70119 11.6526 5.76744 10.4413C5.77619 9.72135 5.90619 9.00885 6.15619 8.3326C6.53744 7.34635 7.31869 6.5701 8.30494 6.1926C8.97244 5.9451 9.67994 5.81385 10.3912 5.80385C11.5799 5.7501 11.9137 5.7351 14.9599 5.7351C18.0062 5.7351 18.3187 5.7351 19.5274 5.80385C20.2412 5.8126 20.9462 5.94385 21.6149 6.1926C22.5974 6.57135 23.3737 7.34885 23.7549 8.3326C23.9999 9.0001 24.1324 9.70635 24.1437 10.4188C24.1974 11.6076 24.2112 11.9413 24.2112 14.9876C24.2112 18.0326 24.2112 18.3601 24.1574 19.5551H24.1437V19.5538Z"
                fill="#395061"
              />
            </svg>
            <div className="d-flex flex-column w-100">
              <input
                type="text"
                name="insta"
                value={insta.value}
                onBlur={() => {
                  if (insta.error) {
                    setInsta({
                      value: "https://www.instagram.com/",
                      error: "",
                    });
                  }
                }}
                style={{
                  color: "blue",
                  textDecoration: "underline",
                }}
                onChange={onChange}
                className="bg-white mb-2"
              />
            </div>
          </div>
          <small className="text-danger mb-2 d-block fz-12px fw-500">
            {insta.error ? insta.error : ""}
          </small>

          <div className="d-flex ">
            <svg
              className="position-relative trans-y50 linkdin"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.201 0.76001H2.79727C1.67102 0.76001 0.759766 1.67126 0.759766 2.79751V21.2025C0.759766 22.3275 1.67102 23.2413 2.79727 23.2413H21.2023C22.3273 23.2413 23.241 22.3288 23.241 21.2025V2.79751C23.2398 1.67001 22.3273 0.76001 21.201 0.76001ZM8.27227 5.13126C9.01852 5.13126 9.64477 5.72126 9.64477 6.47876C9.64477 7.24876 9.01852 7.82876 8.27227 7.82876V7.85626C7.51727 7.85626 6.90977 7.24876 6.90977 6.49626C6.90977 5.74001 7.51727 5.13126 8.27227 5.13126ZM17.4648 18.0713C16.0748 18.0713 15.401 17.2438 15.401 16.1138C15.401 15.7888 15.4423 15.4475 15.5435 15.0988L16.2035 12.7163C16.2885 12.4563 16.311 12.21 16.311 11.99C16.311 11.2213 15.8423 10.76 15.0973 10.76C14.146 10.76 13.5223 11.4388 13.1973 12.7575L11.906 17.94H9.64227L10.051 16.3113C9.38352 17.4025 8.46227 18.0763 7.32227 18.0763C5.94477 18.0763 5.30102 17.2863 5.30102 16.0963C5.30852 15.7563 5.34477 15.4138 5.42102 15.0825L6.45352 10.875H4.85602L5.34102 9.08751H9.18477L7.66477 15.0925C7.56477 15.4788 7.52727 15.7913 7.52727 16.015C7.52727 16.3988 7.71227 16.51 8.00852 16.58C8.19102 16.6175 9.62727 16.5938 10.406 14.86L11.4035 10.875H9.78602L10.2748 9.12001H13.7585L13.3098 11.1413C13.9148 10.0175 15.1248 8.93876 16.3173 8.93876C17.5823 8.93876 18.636 9.84876 18.636 11.5775C18.636 12.0775 18.5573 12.5725 18.411 13.0525L17.761 15.375C17.7073 15.61 17.6673 15.8088 17.6673 15.99C17.6673 16.3975 17.8373 16.595 18.1273 16.595C18.4248 16.595 18.8185 16.3688 19.251 15.13L20.136 15.47C19.6185 17.3113 18.6585 18.0713 17.4648 18.0713Z"
                fill="#395061"
              />
            </svg>
            <div className="d-flex flex-column w-100">
              <input
                type="text"
                name="linkdin"
                value={linkdin.value}
                onChange={onChange}
                onBlur={() => {
                  if (linkdin.error) {
                    setLinkdin({
                      value: "https://www.linkedin.com/",
                      error: "",
                    });
                  }
                }}
                style={{
                  color: "blue",
                  textDecoration: "underline",
                }}
                className="bg-white mb-2"
              />
            </div>
          </div>
          <small className="text-danger mb-2 d-block fz-12px fw-500">
            {linkdin.error ? linkdin.error : ""}
          </small>

          <div className="d-flex ">
            <svg
              className="position-relative trans-y50 soc-ico"
              viewBox="0 0 30 30"
              fill=" rgb(57, 80, 97)"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
            </svg>
            <div className="d-flex flex-column w-100">
              <input
                type="text"
                name="twitter"
                value={twitter.value}
                onChange={onChange}
                onBlur={() => {
                  if (twitter.error) {
                    setTwitter({ value: "https://twitter.com/", error: "" });
                  }
                }}
                style={{
                  color: "blue",
                  textDecoration: "underline",
                }}
                className="bg-white mb-2"
              />
            </div>
          </div>
            <small className="text-danger mb-2 d-block fz-12px fw-500">
            {twitter.error ? twitter.error : ""}
          </small>
        </div>

        <div className="d-flex justify-content-end my-3">
          <button
            type="submit"
            className="text-center mt-auto border-0 w-25 fz-10px bg-prime text-white fz-14px py-2 rounded-3"
            disabled={loading ? true : false}
          >
            {loading ? "Please Wait..." : "Save Changes"}
          </button>
        </div>
      </form>
    </div>
  );
}

export default UserProfile;
