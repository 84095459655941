import React, { useState } from 'react';
import { FiTrash2 } from "react-icons/fi";
import { MdModeEditOutline } from "react-icons/md";
import { useRecoilState } from 'recoil';
import swal from "sweetalert";
import { API_CALL } from '../../../../../../../api/ApiServiceCall';
import { API_CONSTANTS, API_METHOD } from '../../../../../../../common/constants';
import { clickedChapterDataListAtomFunc } from '../../../../../../atom/clickedChapterDataListAtomFunc';
import { currentViewComponentLsnAssessAtomFunc } from '../../../../../../atom/lsnChapAssessComponentRender';
import LeftArrow from '../../../../../../svg-icons/leftArrow';
import SingleChapterView from '../singleChapterView';
import EditClickedAssessment from './editClickedAssessment';

function ViewAssessment({ singleClickedAssessment }) {

    const [currentViewComponentLsnAssessAtom, setCurrentViewComponentLsnAssessAtom] = useRecoilState(currentViewComponentLsnAssessAtomFunc)
    const [clickedChapterDataListAtom, setClickedChapterDataListAtom] = useRecoilState(clickedChapterDataListAtomFunc)
    const [getClickedLessonData, setClickedLessonData] = useState(singleClickedAssessment)
    const [isEdit, setIsEdit] = useState(false)
    const getLessonbyLessonOrderFunc = async () => {
        const request = {
            apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.GET_LESSON_BY_LESSON_ORDER + `/?courseCode=${clickedChapterDataListAtom.data.courseCode}&chapterOrder=${clickedChapterDataListAtom.data.chapterOrder}&lessonOrder=${getClickedLessonData?.lessonOrder}`,
            method: API_METHOD.GET,
        }
        let result = await API_CALL(request)
        if (result.status === 200) {
            setClickedLessonData(result.data.lesson);
            // alert(JSON.stringify(result));
        } else if (result.status > 200) {
            console.log('errror', result)
        }
    }

    //onDelete Lesson
    const onDeleteLesson = async () => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover",
            icon: 'https://cdn-icons-png.flaticon.com/512/6460/6460112.png',
            imageClass: 'img-warning',
            buttons: true,
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                const requestBodyParam = JSON.stringify({
                    "courseCode": clickedChapterDataListAtom.data.courseCode,
                    "lessonOrder": getClickedLessonData.lessonOrder,
                    "chapterOrder": clickedChapterDataListAtom.data.chapterOrder
                });
                // alert(requestBodyParam)
                const request = {
                    apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.DELETE_LESSON,
                    method: API_METHOD.POST,
                    requestBodyParams: requestBodyParam,
                }
                let result = await API_CALL(request)
                if (result.status === 200) {
                    setCurrentViewComponentLsnAssessAtom(<SingleChapterView clickedChapterData={clickedChapterDataListAtom} />)
                } else if (result.status > 200) {
                    swal({
                        title: "ERROR",
                        text: result.message,
                        icon: 'https://cdn-icons-png.flaticon.com/512/2569/2569174.png',
                        className: "error-window",
                        button: true,
                    })
                }
            }
        });
    }


    return (
        <>
            {/* {JSON.stringify(getClickedLessonData)} */}
            {/* {JSON.stringify(clickedChapterDataListAtom?.data?.courseStatus)} */}

             <div className="d-flex align-items-center mb-3">
                <small role={"button"} className="fz-16px fw-500 me-auto" onClick={() => setCurrentViewComponentLsnAssessAtom(<SingleChapterView clickedChapterData={clickedChapterDataListAtom} />)}><LeftArrow size={8} /> {getClickedLessonData.lessonName}</small>
                {clickedChapterDataListAtom?.data?.courseStatus === "INREVIEW" ? "" : <> <div type="button" onClick={() => setIsEdit(true)}><MdModeEditOutline className='text-prime ms-3 mx-2' /></div>
                    <div type="button" onClick={onDeleteLesson}><FiTrash2 className='text-danger' /></div></>}
            </div> 

            {isEdit ? <EditClickedAssessment getLessonbyLessonOrderFunc={getLessonbyLessonOrderFunc} setIsEdit={setIsEdit} getClickedLessonData={getClickedLessonData} /> :
                <>
                    {/* {JSON.stringify(getClickedLessonData)} */}
                    {/* ---View assessment Content----- */}
                    {getClickedLessonData.assessmentDetails.length ? "" : <><p className="fz-14px text-muted fw-400">No Assessment Data</p> <button onClick={() => setIsEdit(true)} className='border-0 fz-14px fw-400 text-prime p-2 px-3 rounded-1'>Add Now {"->"}</button></>}
                    {getClickedLessonData.assessmentDetails.map((assessmentData, assesQuestionIndex) => {
                        return (
                            <div key={assessmentData._id}>
                                <h6>
                                    {assesQuestionIndex + 1 + "."}{assessmentData.assessmentQuestion}
                                </h6>
                                {
                                    assessmentData.assessmentChoice.map((assessmentOptions, assessmentOptionKey) => {
                                        return (
                                            <p key={assessmentOptionKey + assessmentOptions}>
                                                option{assessmentOptionKey + 1} -{assessmentOptions}
                                            </p>
                                        )
                                    })
                                }
                            </div>
                        )
                    })}
                    {/* ---View assesment Content End----- */}
                </>
            }

        </>
    )
}

export default ViewAssessment