import { useState ,useEffect} from "react";
import { useForm } from "react-hook-form";
import "react-quill/dist/quill.snow.css";
import { useRecoilState } from "recoil";
import swal from "sweetalert";
import { API_CALL } from "../../../../../api/ApiServiceCall";
import { API_CONSTANTS, API_METHOD } from "../../../../../common/constants";
import { OverviewDataState } from "../../../../atom/overviewData";
import { OverviewTriggerRefetch } from "../../../../atom/overviewRefetchTrigger";

export default function PricingEdit({ setIsEdit }) {
  const [overviewData, setOverviewData] = useRecoilState(OverviewDataState);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [currencyList, setCurrencyList] = useState([]);
  const [overviewRefecthTrigger, setOverviewRefecthTrigger] = useRecoilState(
    OverviewTriggerRefetch
  );
  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [loading, setLoading] = useState(false);
  const onSubmit = async (data) => {
    setLoading(true);
    const userCode = JSON.parse(localStorage.getItem("userCode"));
    const requestBodyParam = JSON.stringify({
      currency: data.currency,
      fee: data.fee,
      courseCode: userCode.data,
    });
    const request = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.UPDATE_COURSE_PRICING,
      method: API_METHOD.POST,
      requestBodyParams: requestBodyParam,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      setOverviewRefecthTrigger({
        overView: "change occur from overview content Page!",
      });
      setLoading(false);
      setIsEdit(false);
    } else if (result.status > 200) {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
      setLoading(false);
    }
  };
  useEffect(() => {
    if ( overviewData?.data[0]?.subCategory) {
      getPriceBySubCategory(overviewData?.data[0]?.subCategory);
    }
  }, [overviewData?.data[0]?.subCategory]);


  const getPriceBySubCategory = async (subCatCode) => {
    const request = {
      apiUrl:
        API_CONSTANTS.BASE_URL +
        API_CONSTANTS.GET_FEES_BY_SUB_CATEGORY_CODE(subCatCode),
      method: API_METHOD.GET,
    };
    
    let result = await API_CALL(request);
    
    if (result.status === 200) {
      setCurrencyList(result.data);
    } else if (result.status > 200) {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }
  };
 
  const watchShowCount = watch("updatetitle", ""); // you can supply default value as second argument
  return (
    <>
      <div className="container overview-card">
        <form id="hook-form-pricing" onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-6">
              <label className="py-4 fw-500">Currency</label>
              <div className="tag-ajus">
                <select
                  disabled
                  defaultValue={overviewData?.data[0]?.currency}
                  {...register("currency")}
                >
                  <option value="DEFAULT" disabled>
                    Select Currency
                  </option>
                  <option value="INR">₹</option>
                  {/* <option value="USD">$</option> */}
                </select>
              </div>
            </div>
            <div className="col-8"></div>
            <div className="col-6">
              <label className="py-4 fw-500">Fee</label>
              <div className="tag-ajus">
                <select
                  defaultValue={overviewData?.data[0]?.fee}
                  {...register("fee")}
                >
                  <option value="DEFAULT" disabled>
                    Select Fee
                  </option>
                <option value="999">999</option>
              {currencyList.map((item, index, array) => (
                <option value={item?.price} key={index}>
                  {item?.price} VideoCount({item?.videoCount})
                </option>
              ))}
                </select>
              </div>
            </div>
          </div>
        </form>
        <div className="d-flex justify-content-end pt-3">
          <div
            className="border-0 bg-secondary text-white fz-14px py-2 w-14 rounded-3 text-center"
            type="button"
            role="button"
            onClick={() => setIsEdit(false)}
          >
            Cancel
          </div>
          <button
            className="ms-lg-3 border-0 bg-prime text-white fz-14px py-2 w-14 rounded-3 text-center"
            type="submit"
            form="hook-form-pricing"
          >
            Done
          </button>
        </div>
      </div>
    </>
  );
}
