import moment from "moment";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { STORAGE_KEY } from "../../../../common/constants";
import useCookie from "../../../../common/cookie_hook";
import { getCredentialLoginAccountProfileDetailAtom } from "../../../atom/getCredentialLoginAccountProfileDetail";
import MobileIcon from "../../../svg-icons/mobileIcon";
import AccountActivity from "./accountActivity";
import ChangeEmail from "./change-email";
import VerifyChangeEmailOTP from "./change-email/verifyotp";
import ChangeMobileNumber from "./change-mob-number/ChangeMobileNumber";
import ChangePassword from "./change-password";
import DeleteConfirm from "./deleteConfirm";
import DeleteConfirmStep2 from "./deleteStep2";
function AccountSetting() {
  const [show, setShow] = useState(false); //modal
  const [getSelectedModal, setSelectedModal] = useState("");
  const handleClose = () => setShow(false); //close modal
  const handleShow = () => setShow(true); //show modal
  const [getUserDetail, setUserDetail] = useState(
    JSON.parse(localStorage.getItem("localUserData"))
  );
  const [getCredentialUserDetail, setCredentialUserDetail] = useState(
    JSON.parse(localStorage.getItem("localUserData"))
  );
  const navigate = useNavigate();
  const [cookie, updateCookie] = useCookie(STORAGE_KEY.AUTH);
  const [isDelete, setDelete] = useState("");

  const DeleteAccount = async () => {
    setSelectedModal("");
    setDelete("DeleteStep1");
    setShow(true);
  };
  const [
    credentialLoginAccountProfileDetailAtom,
    setCredentialLoginAccountProfileDetailAtom,
  ] = useRecoilState(getCredentialLoginAccountProfileDetailAtom);

  return (
    <div className="py-4">
      <ul className="acc-set list-style-none p-0 m-0">
        {getUserDetail?.data?.user?.email ? (
          ""
        ) : (
          <>
            {" "}
            <li>
              <div className="row d-flex align-items-center">
                <div className="col-lg-7">
                  <div className="row gap-3">
                    <div className="col-lg-1">
                      <svg
                        width="45"
                        height="45"
                        viewBox="0 0 45 45"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M22.5 45C34.9264 45 45 34.9264 45 22.5C45 10.0736 34.9264 0 22.5 0C10.0736 0 0 10.0736 0 22.5C0 34.9264 10.0736 45 22.5 45Z"
                          fill="#395061"
                        />
                        <path
                          d="M10.4971 19.4996L22.0531 26.8541C22.1851 26.9516 22.3411 26.9996 22.4971 26.9996C22.6531 26.9996 22.8091 26.9516 22.9411 26.8541L34.4971 19.4996L22.9471 10.6496C22.6801 10.4501 22.3141 10.4501 22.0471 10.6496L10.4971 19.4996Z"
                          fill="#56B4FC"
                        />
                        <path
                          d="M29.9985 10.5H14.9985C14.172 10.5 13.4985 11.1735 13.4985 12V28.5C13.4985 28.914 13.8345 29.25 14.2485 29.25H30.7485C31.1625 29.25 31.4985 28.914 31.4985 28.5V12C31.4985 11.1735 30.8265 10.5 29.9985 10.5Z"
                          fill="white"
                        />
                        <path
                          opacity="0.48"
                          d="M17.2466 15H27.7466C28.1606 15 28.4966 14.664 28.4966 14.25C28.4966 13.836 28.1606 13.5 27.7466 13.5H17.2466C16.8326 13.5 16.4966 13.836 16.4966 14.25C16.4966 14.664 16.8326 15 17.2466 15Z"
                          fill="#888E9D"
                        />
                        <path
                          opacity="0.48"
                          d="M27.7466 16.5H17.2466C16.8326 16.5 16.4966 16.836 16.4966 17.25C16.4966 17.664 16.8326 18 17.2466 18H27.7466C28.1606 18 28.4966 17.664 28.4966 17.25C28.4966 16.836 28.1606 16.5 27.7466 16.5Z"
                          fill="#888E9D"
                        />
                        <path
                          opacity="0.48"
                          d="M23.2466 19.5H17.2466C16.8326 19.5 16.4966 19.836 16.4966 20.25C16.4966 20.664 16.8326 21 17.2466 21H23.2466C23.6606 21 23.9966 20.664 23.9966 20.25C23.9966 19.836 23.6606 19.5 23.2466 19.5Z"
                          fill="#888E9D"
                        />
                        <path
                          d="M22.9411 26.8545C22.8091 26.952 22.6531 27 22.4971 27C22.3411 27 22.1851 26.952 22.0531 26.8545L10.4971 19.5V33C10.4971 33.828 11.1691 34.5 11.9971 34.5H32.9971C33.8251 34.5 34.4971 33.828 34.4971 33V19.5L22.9411 26.8545Z"
                          fill="#BFE9FF"
                        />
                        <path
                          d="M32.9971 34.5H11.9971C11.1556 34.5 10.4971 33.8415 10.4971 33C10.4971 32.7615 10.6111 32.5365 10.8031 32.3955L22.0531 24.8955C22.1851 24.798 22.3411 24.75 22.4971 24.75C22.6531 24.75 22.8091 24.798 22.9411 24.8955L34.1911 32.3955C34.3831 32.5365 34.4971 32.7615 34.4971 33C34.4971 33.8415 33.8386 34.5 32.9971 34.5Z"
                          fill="#56B4FC"
                        />
                      </svg>
                    </div>
                    <div className="col">
                      <p className="m-0">Email Address</p>
                      {/* <p className='text-muted m-0'>{getUserDetail?.result?.user?.email || getCredentialUserDetail?.email}</p> */}
                      <p className="text-muted m-0">
                        {credentialLoginAccountProfileDetailAtom?.data?.email}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col justify-content-end  d-flex">
                  <button
                    onClick={() => {
                      setSelectedModal("changeEmail");
                      setDelete("");
                      handleShow();
                    }}
                    role="button"
                    className="text-center border-0 w-35 my-3 fz-10px bg-prime text-white fz-14px py-2 rounded-3"
                  >
                    Change
                  </button>
                </div>
              </div>
            </li>
            {/* -------- */}
            <li>
              <div className="row d-flex align-items-center">
                <div className="col-lg-7">
                  <div className="row gap-3">
                    <div className="col-lg-1">
                      <svg
                        width="45"
                        height="45"
                        viewBox="0 0 45 45"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M22.4995 44.9991C34.9257 44.9991 44.9991 34.9257 44.9991 22.4995C44.9991 10.0734 34.9257 0 22.4995 0C10.0734 0 0 10.0734 0 22.4995C0 34.9257 10.0734 44.9991 22.4995 44.9991Z"
                          fill="#395061"
                        />
                        <g clipPath="url(#clip0_246_4213)">
                          <path
                            d="M22.4995 10.5C29.1265 10.5 34.4993 15.8711 34.4993 22.4966C34.4993 29.1224 29.1265 34.4933 22.4995 34.4933C15.8723 34.4933 10.4998 29.1224 10.4998 22.4966C10.4998 15.8709 15.8723 10.5 22.4995 10.5Z"
                            fill="white"
                          />
                          <path
                            d="M23.4595 15.2988C20.0134 15.2988 17.2196 18.0916 17.2196 21.5371C17.2196 22.4697 17.4258 23.3529 17.7928 24.148L16.9446 24.9957C16.7255 25.2148 16.7382 26.3249 16.7365 26.3412C16.7269 26.3511 15.7053 26.235 15.4802 26.4598L15.0292 26.9303C14.7422 27.2175 14.8075 28.2729 14.8024 28.2549L14.8012 28.2564C14.8046 28.2571 13.769 28.1707 13.5492 28.39L13.0425 28.8968C12.8179 29.1216 12.8755 30.1811 12.8789 30.1787C12.8801 30.1773 11.8032 30.1451 11.6902 30.2485C10.9786 30.8995 10.4998 31.4391 10.4998 31.4391L10.5041 34.4997C10.5041 34.5016 13.5549 34.4942 13.5549 34.4942L20.8479 27.2024C21.6665 27.5806 22.5577 27.7761 23.4595 27.7753C26.9056 27.7753 29.6994 24.9818 29.6994 21.5371C29.6994 18.0919 26.9056 15.2988 23.4595 15.2988ZM25.0773 18.5334C25.2676 18.4913 25.4646 18.4899 25.6555 18.5291C25.8464 18.5683 26.0269 18.6473 26.1852 18.761C26.3435 18.8746 26.4761 19.0203 26.5743 19.1887C26.6725 19.357 26.734 19.5441 26.755 19.7378C26.776 19.9315 26.7559 20.1274 26.6961 20.3128C26.6362 20.4983 26.5379 20.669 26.4077 20.8139C26.2774 20.9588 26.118 21.0746 25.9399 21.1538C25.7618 21.2329 25.5691 21.2737 25.3742 21.2734C25.1488 21.3113 24.9177 21.2949 24.7 21.2254C24.4822 21.1559 24.2843 21.0354 24.1226 20.8739C23.9613 20.7123 23.8408 20.5144 23.7714 20.2969C23.7019 20.0793 23.6854 19.8483 23.7233 19.6231C23.7909 19.3144 23.962 19.038 24.2083 18.8398C24.4546 18.6416 24.7612 18.5335 25.0773 18.5334Z"
                            fill="#56B4FC"
                          />
                          <path
                            d="M26.496 18.4929C26.1351 18.1323 25.6457 17.9297 25.1355 17.9297C24.6252 17.9297 24.1358 18.1323 23.7749 18.4929C23.4143 18.8537 23.2118 19.3429 23.2118 19.853C23.2118 20.363 23.4143 20.8522 23.7749 21.213C23.9536 21.3917 24.1657 21.5335 24.3991 21.6302C24.6325 21.7269 24.8828 21.7766 25.1355 21.7766C25.3882 21.7766 25.6384 21.7269 25.8718 21.6302C26.1053 21.5335 26.3174 21.3917 26.496 21.213C26.6747 21.0344 26.8164 20.8224 26.9131 20.589C27.0098 20.3557 27.0595 20.1056 27.0595 19.853C27.0595 19.6004 27.0098 19.3503 26.9131 19.1169C26.8164 18.8835 26.6747 18.6715 26.496 18.4929ZM25.9795 20.6967C25.8682 20.8079 25.7361 20.8962 25.5906 20.9564C25.4452 21.0166 25.2893 21.0476 25.1319 21.0475C24.9745 21.0475 24.8187 21.0165 24.6733 20.9563C24.5278 20.896 24.3957 20.8077 24.2844 20.6965C24.0597 20.4717 23.9334 20.1669 23.9335 19.8491C23.9335 19.5313 24.0599 19.2265 24.2847 19.0018C24.3959 18.8905 24.528 18.8022 24.6734 18.742C24.8188 18.6817 24.9747 18.6507 25.1321 18.6507C25.2895 18.6507 25.4453 18.6817 25.5907 18.742C25.7362 18.8022 25.8683 18.8905 25.9795 19.0018C26.0909 19.1131 26.1792 19.2452 26.2395 19.3906C26.2998 19.536 26.3308 19.6918 26.3308 19.8492C26.3308 20.0067 26.2998 20.1625 26.2395 20.3079C26.1792 20.4533 26.0909 20.5854 25.9795 20.6967ZM19.587 26.426C19.3981 26.2771 19.2178 26.1173 19.0473 25.9476L10.5042 34.4883V34.4986C10.5042 34.4991 10.957 34.4986 11.5136 34.4971L19.587 26.426Z"
                            fill="#F0E1EB"
                          />
                          <path
                            d="M28.7315 10.5C25.8727 10.5 23.5013 12.5831 23.0501 15.3133C23.1864 15.3041 23.323 15.2992 23.4596 15.2987C23.7209 15.2987 23.9777 15.3166 24.2304 15.3481C24.6535 13.2487 26.5075 11.6675 28.7315 11.6675C31.2676 11.6675 33.3238 13.7225 33.3238 16.2584C33.3238 18.7938 31.2685 20.8493 28.7315 20.8493C27.0902 20.8493 25.6543 19.9855 24.8419 18.6908C24.6312 18.7418 24.4383 18.8493 24.2842 19.0018C24.1418 19.1459 24.0389 19.3242 23.9852 19.5195C25.0239 21.0275 26.7619 22.0168 28.7315 22.0168C31.9124 22.0168 34.4914 19.4387 34.4914 16.2584C34.4914 13.0783 31.9129 10.5 28.7315 10.5Z"
                            fill="#EBECED"
                          />
                          <path
                            d="M25.1645 19.1422C25.0483 18.9984 24.9406 18.8478 24.8419 18.6914C24.7909 18.7042 24.7406 18.72 24.6915 18.7389L24.69 18.7394C24.6442 18.7575 24.5994 18.7783 24.5559 18.8015L24.487 18.844L24.4241 18.8843C24.379 18.9179 24.3351 18.9539 24.2938 18.9942L24.276 19.0117C24.238 19.0511 24.2027 19.093 24.1704 19.1372L24.1265 19.2034L24.0927 19.2584C24.0469 19.341 24.0108 19.4286 23.9849 19.5194C24.0931 19.6766 24.21 19.8265 24.3336 19.9719L25.1645 19.1422Z"
                            fill="#C8C9CB"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_246_4213">
                            <rect
                              width="23.9995"
                              height="23.9995"
                              fill="white"
                              transform="translate(10.4998 10.5)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div className="col">
                      <p className="m-0">Password</p>
                      <p className="text-muted m-0">************</p>
                    </div>
                  </div>
                </div>
                <div className="col justify-content-end  d-flex">
                  <button
                    onClick={() => {
                      setSelectedModal("changePassword");
                      setDelete("");
                      handleShow();
                    }}
                    role="button"
                    className="text-center border-0 w-35 my-3 fz-10px bg-prime text-white fz-14px py-2 rounded-3"
                  >
                    Change
                  </button>
                </div>
              </div>
            </li>
          </>
        )}
        {/* -------- */}
        <li>
          {/* <p>{JSON.stringify(credentialLoginAccountProfileDetailAtom?.data)}</p> */}
          <div className="row d-flex align-items-center">
            <div className="col-lg-7">
              <div className="row gap-3">
                <div className="col-lg-1">
                  <MobileIcon />
                </div>
                <div className="col">
                  <p className="m-0">Mobile Number</p>
                  <p className="text-muted m-0 fz-15px">
                    {
                      credentialLoginAccountProfileDetailAtom?.data
                        ?.mobileNumber
                    }
                  </p>
                  {/* <p className='text-muted m-0 fz-15px'>{credentialLoginAccountProfileDetailAtom?.data?.mobileNumber?.startsWith("+") ? credentialLoginAccountProfileDetailAtom?.data?.mobileNumber : "+" + credentialLoginAccountProfileDetailAtom?.data?.mobileNumber}</p> */}
                </div>
              </div>
            </div>
            <div className="col justify-content-end  d-flex">
              <button
                role="button"
                className="text-center border-0 w-35 my-3 fz-10px bg-prime text-white fz-14px py-2 rounded-3"
                onClick={() => {
                  setSelectedModal("changeMobileNumber");
                  setDelete("");
                  handleShow();
                }}
              >
                Change
              </button>
            </div>
          </div>
        </li>
        {/* ---------- */}
        <li>
          <div className="row d-flex align-items-center">
            <div className="col-lg-7">
              <div className="row gap-3">
                <div className="col-lg-1">
                  <svg
                    width="53"
                    height="53"
                    viewBox="0 0 53 53"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g filter="url(#filter0_d_246_4136)">
                      <path
                        d="M26.5 45C38.9264 45 49 34.9264 49 22.5C49 10.0736 38.9264 0 26.5 0C14.0736 0 4 10.0736 4 22.5C4 34.9264 14.0736 45 26.5 45Z"
                        fill="#395061"
                      />
                      <path
                        d="M36.9473 13.0998L26.9724 10.0306C26.8956 10 26.8189 10 26.7422 10C26.6655 10 26.5888 10 26.5119 10.0306L16.5371 13.0998C16.2148 13.2073 16 13.4988 16 13.8365V21.6118C16 32.799 26.4046 36.129 26.5119 36.1597C26.5888 36.1751 26.6655 36.1905 26.7422 36.1905C26.8189 36.1905 26.8956 36.1751 26.9724 36.1597C27.0797 36.129 37.4844 32.799 37.4844 21.6118V13.8365C37.4844 13.4988 37.2695 13.2073 36.9473 13.0998Z"
                        fill="#56B4FC"
                      />
                      <path
                        d="M37.4844 13.8365V21.6118C37.4844 32.799 27.0797 36.129 26.9724 36.1597C26.8956 36.1751 26.8189 36.1905 26.7422 36.1905V10C26.8189 10 26.8956 10 26.9724 10.0306L36.9473 13.0998C37.2695 13.2073 37.4844 13.4988 37.4844 13.8365Z"
                        fill="#F0E1EB"
                      />
                      <path
                        d="M31.022 28.2044C30.7478 28.08 30.571 27.8066 30.571 27.5053V26.5852C30.571 25.9453 28.5088 25.4492 26.7345 25.4492C24.9601 25.4492 22.898 25.9453 22.898 26.5852V27.5053C22.898 27.8066 22.7212 28.08 22.4469 28.2044C22.1727 28.3288 21.8505 28.2801 21.6234 28.0816C19.9951 26.6474 19.0615 24.5695 19.0615 22.38C19.0615 18.1494 22.5039 14.707 26.7345 14.707C30.9651 14.707 34.4075 18.1494 34.4075 22.38C34.4075 24.5695 33.4738 26.6474 31.8456 28.0816C31.6206 28.2783 31.2999 28.33 31.022 28.2044Z"
                        fill="white"
                      />
                      <path
                        d="M30.5787 26.5852V27.5053C30.5787 27.8066 30.7555 28.08 31.0298 28.2044C31.3076 28.33 31.6282 28.2782 31.8533 28.0816C33.4815 26.6474 34.4152 24.5695 34.4152 22.38C34.4152 18.1494 30.9728 14.707 26.7422 14.707V25.4492C28.5166 25.4492 30.5787 25.9453 30.5787 26.5852Z"
                        fill="#F4F7FA"
                      />
                      <path
                        d="M26.7446 23.9138C25.0519 23.9138 23.6754 22.5373 23.6754 20.8446C23.6754 19.1519 25.0519 17.7754 26.7446 17.7754C28.4373 17.7754 29.8138 19.1519 29.8138 20.8446C29.8138 22.5373 28.4373 23.9138 26.7446 23.9138Z"
                        fill="#395061"
                      />
                      <path
                        d="M26.7422 30.0525C24.8307 30.0525 23.0121 29.3548 21.6214 28.0878C21.4618 27.9424 21.3711 27.7363 21.3711 27.5205V26.5846C21.3711 23.989 26.1922 23.9141 26.7422 23.9141C27.2922 23.9141 32.1133 23.989 32.1133 26.5846V27.5205C32.1133 27.7363 32.0226 27.9424 31.863 28.0878C30.4723 29.3548 28.6537 30.0525 26.7422 30.0525ZM31.346 27.5205H31.361H31.346Z"
                        fill="#395061"
                      />
                      <path
                        d="M31.863 28.0878C32.0226 27.9424 32.1133 27.7363 32.1133 27.5205V26.5846C32.1133 23.989 27.2922 23.9141 26.7422 23.9141V30.0525C28.6537 30.0525 30.4723 29.3548 31.863 28.0878Z"
                        fill="#395061"
                      />
                      <path
                        d="M29.8114 20.8446C29.8114 19.1519 28.4349 17.7754 26.7422 17.7754V23.9138C28.4349 23.9138 29.8114 22.5373 29.8114 20.8446Z"
                        fill="#395061"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter0_d_246_4136"
                        x="0"
                        y="0"
                        width="53"
                        height="53"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                      >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_246_4136"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_246_4136"
                          result="shape"
                        />
                      </filter>
                    </defs>
                  </svg>
                </div>
                <div className="col">
                  <p className="m-0">Account Activity</p>
                  <p className="text-muted m-0">Log In Alert</p>
                </div>
              </div>
            </div>
            <div className="col justify-content-end  d-flex">
              <button
                role="button"
                onClick={() => {
                  setSelectedModal("viewAccountActivity");
                  setDelete("");
                  handleShow();
                }}
                className="text-center border-0 w-35 my-3 fz-10px bg-prime text-white fz-14px py-2 rounded-3"
              >
                View
              </button>
            </div>
          </div>
        </li>
        {/* ------------- */}
        <li>
          <div className="row d-flex align-items-center">
            <div className="col-lg-7">
              <div className="row gap-3">
                <div className="col-lg-1">
                  <svg
                    width="53"
                    height="45"
                    viewBox="0 0 45 45"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22.5 45C34.9264 45 45 34.9264 45 22.5C45 10.0736 34.9264 0 22.5 0C10.0736 0 0 10.0736 0 22.5C0 34.9264 10.0736 45 22.5 45Z"
                      fill="#395061"
                    />
                    <path
                      d="M20.1634 37.6801H12.9833C10.2308 37.6801 8 35.4493 8 32.6968V29.6781C8 25.5557 11.3409 22.2148 15.4632 22.2148H16.7531C20.8755 22.2148 22.9147 37.6801 20.1634 37.6801Z"
                      fill="white"
                    />
                    <path
                      d="M32.3262 29.6781V32.6968C32.3262 35.4493 30.0954 37.6801 27.3442 37.6801H20.1641V27.0983L23.5731 22.2148H24.863C28.9853 22.2148 32.3262 25.5557 32.3262 29.6781Z"
                      fill="#F4F7FA"
                    />
                    <path
                      d="M30.1089 40.2003C34.0157 40.2003 37.1827 37.0333 37.1827 33.1265C37.1827 29.2198 34.0157 26.0527 30.1089 26.0527C26.2022 26.0527 23.0352 29.2198 23.0352 33.1265C23.0352 37.0333 26.2022 40.2003 30.1089 40.2003Z"
                      fill="#FF5A4E"
                    />
                    <path
                      d="M30.1055 26.0527V40.2003C34.0122 40.2003 37.1793 37.0333 37.1793 33.1265C37.1793 29.2198 34.0122 26.0527 30.1055 26.0527Z"
                      fill="#DB2C42"
                    />
                    <path
                      d="M34.3592 31.7598H31.4729H25.8535V34.4928H31.4729H34.3592V31.7598Z"
                      fill="white"
                    />
                    <path
                      d="M30.1055 31.7598H34.3583V34.4928H30.1055V31.7598Z"
                      fill="#E8E8E8"
                    />
                    <path
                      d="M23.5732 17.375H16.7539V23.0063L20.1635 24.065L23.5732 23.0063V17.375Z"
                      fill="#F0E1EB"
                    />
                    <path
                      d="M23.5735 17.375H20.1641V24.0649L23.5735 23.0063V17.375Z"
                      fill="#F0E1EB"
                    />
                    <path
                      d="M20.1647 7.49414C17.9572 7.49414 15.3483 8.68603 15.1359 11.9113C14.565 12.0216 14.1595 12.7704 14.2234 13.3758L14.3212 14.7535C14.3882 15.3892 14.947 15.8533 15.5629 15.7842L15.6691 15.7727C16.1003 16.9291 16.6146 17.7323 17.1551 18.2957C18.1974 19.383 19.3375 19.5808 20.1647 19.7116C20.9919 19.5807 22.3721 7.49414 20.1647 7.49414Z"
                      fill="#F0E1EB"
                    />
                    <path
                      d="M23.1736 18.2957C23.7141 17.7323 24.2285 16.929 24.6596 15.7727L24.7658 15.7842C25.3816 15.8533 25.9404 15.3892 26.0074 14.7535L26.1052 13.3758C26.169 12.7704 25.7636 12.0216 25.1926 11.9113C24.9802 8.6862 22.3715 7.49423 20.1641 7.49414V19.7115C20.9914 19.5808 22.1314 19.3829 23.1736 18.2957Z"
                      fill="#F0E1EB"
                    />
                    <path
                      d="M24.3768 6.16483L23.7532 6.96244C22.859 5.93693 20.1675 4 20.1675 4C20.1675 4 17.4758 5.93693 16.5816 6.96244L15.958 6.16483C15.958 6.16483 13.5148 8.5595 14.8789 12.0978L15.5593 13.8768L17.0547 9.82513L20.1675 11.3869L23.2801 9.82513L24.7755 13.8768L25.4559 12.0978C26.8199 8.55941 24.3768 6.16483 24.3768 6.16483Z"
                      fill="#15171A"
                    />
                    <path
                      d="M23.2766 9.82513L24.7721 13.8768L25.4525 12.0978C26.8164 8.5595 24.3733 6.16483 24.3733 6.16483L23.7497 6.96244C22.8555 5.93693 20.1641 4 20.1641 4V11.3869L23.2766 9.82513Z"
                      fill="#525967"
                    />
                    <path
                      d="M20.1639 22.2148C20.1639 22.2148 20.8362 26.3194 20.1644 27.0981H19.8239C18.1285 27.0981 16.7539 25.7243 16.7539 24.0289V22.2148H20.1639Z"
                      fill="#F0E1EB"
                    />
                    <path
                      d="M23.5734 22.2148V24.0289C23.5734 25.7243 22.1997 27.0981 20.5043 27.0981H20.1641V22.2148H23.5734Z"
                      fill="#F0E1EB"
                    />
                  </svg>
                </div>
                <div className="col">
                  <p className="m-0">Delete Account</p>
                  {!credentialLoginAccountProfileDetailAtom?.data
                    ?.againActivated &&
                  credentialLoginAccountProfileDetailAtom?.data
                    ?.deactivateReqDate ? (
                    <p className="text-danger m-0 fz-14px fw-500">
                      Deactivating on{" "}
                      {moment(
                        credentialLoginAccountProfileDetailAtom?.data
                          ?.deactivateCompletionDate
                      ).format("DD/MM/YYYY hh:mm A")}{" "}
                    </p>
                  ) : (
                    <p className="text-muted m-0">
                      Deactivate your account Permanently
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="col justify-content-end  d-flex">
              <button
                disabled={
                  !credentialLoginAccountProfileDetailAtom?.data
                    ?.againActivated &&
                  credentialLoginAccountProfileDetailAtom?.data
                    ?.deactivateReqRaised
                }
                role="button"
                className="text-center border-0 w-35 my-3 fz-10px bg-prime text-white fz-14px py-2 rounded-3"
                onClick={DeleteAccount}
              >
                Deactivate
              </button>
            </div>
          </div>
        </li>
        {/* -------- */}
      </ul>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        backdrop={"static"}
        className="mh"
      >
        {getSelectedModal === "changeEmail" ? (
          <ChangeEmail
            email={
              getUserDetail?.result?.user?.email ||
              getCredentialUserDetail?.email
            }
            setSelectedModal={() => setSelectedModal("verify")}
          />
        ) : null}
        {getSelectedModal === "verify" ? (
          <VerifyChangeEmailOTP
            email={
              getUserDetail?.result?.user?.email ||
              getCredentialUserDetail?.email
            }
            setSelectedModal={() => setSelectedModal("changePassword")}
          />
        ) : null}
        {getSelectedModal === "changePassword" ? (
          <ChangePassword setShow={() => setShow(false)} />
        ) : null}
        {getSelectedModal === "viewAccountActivity" ? (
          <AccountActivity setSelectedModal={() => setSelectedModal("")} />
        ) : null}
        {getSelectedModal === "changeMobileNumber" ? (
          <ChangeMobileNumber closeModal={() => setShow(false)} />
        ) : null}

        {/* =====Delete Step===== */}
        {isDelete === "DeleteStep1" ? (
          <DeleteConfirm
            setDelete={() => setDelete("DeleteStep2")}
            setShow={() => setShow(false)}
          />
        ) : null}
        {isDelete === "DeleteStep2" ? (
          <DeleteConfirmStep2 setShow={() => setShow(false)} />
        ) : null}
        {/* {isDelete==="DeleteStep2" ? (<DeleteInstructorSuccess setShow={() => setShow(false)} />) : null} setDelete={()=>setDelete("DeleteStep3")}*/}
        {/* {selected == 'forgot_pass' ? (<ForgotPassword setEmailid={(id) => setEmailid(id)} setSelected={() => setSelected("verify_otp")} />) : null}
                {selected == 'verify_otp' ? <VerifyOTP getEmailid={getEmailid} setSelected={() => setSelected("create_password")} /> : null}
                {selected == 'create_password' ? <CreateNewPassword getEmailid={getEmailid} setSelected={() => setSelected("success")} /> : null}
                {selected == 'success' ? <CreateNewPasswordSuccess setSelected={() => setSelected("forgot_pass")} handleClose={handleClose} /> : null} */}
      </Modal>
    </div>
  );
}

export default AccountSetting;
