import { getAuth, signInWithPhoneNumber } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import swal from "sweetalert";
import { API_CALL } from "../../../../../api/ApiServiceCall";
import { API_CONSTANTS, API_METHOD } from "../../../../../common/constants";
import { getCredentialLoginAccountProfileDetailAtom } from "../../../../atom/getCredentialLoginAccountProfileDetail";

function VerifyMobileOtp({ mobileNumber, closeModal }) {
  const [otp, setOtp] = useState();
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(60);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [
    credentialLoginAccountProfileDetailAtom,
    setCredentialLoginAccountProfileDetailAtom,
  ] = useRecoilState(getCredentialLoginAccountProfileDetailAtom);

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });
  const getAllProfileData = async () => {
    const request = {
      apiUrl:
        API_CONSTANTS.BASE_URL +
        API_CONSTANTS.GET_INSTRUCTOR_BY_EMAIL +
        `?instructorEmail=${credentialLoginAccountProfileDetailAtom?.data?.email}`,
      method: API_METHOD.GET,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      setCredentialLoginAccountProfileDetailAtom(result);
      setLoading(false);
      closeModal();
      swal({
        title: "Mobile number was updated successfully!",
        icon: "success",
        button: "ok",
        className: "swal-ql-theme-btn",
      });
    } else if (result.status > 200) {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }
  };
  let changeMobileNumAPI = async (user) => {
    const oldMbNum =
      credentialLoginAccountProfileDetailAtom?.data?.mobileNumber?.startsWith(
        ""
      )
        ? credentialLoginAccountProfileDetailAtom?.data?.mobileNumber
        : "+91" + credentialLoginAccountProfileDetailAtom?.data?.mobileNumber;
    const requestBodyParam = JSON.stringify({
      oldMobileNumber: oldMbNum,
      newMobileNumber: user.phoneNumber,
      // email: credentialLoginAccountProfileDetailAtom?.data?.email,
      // userType: "INSTRUCTOR",
    });
    const request = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.CHANGE_MOBILE_NUM,
      method: API_METHOD.POST,
      requestBodyParams: requestBodyParam,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      setLoading(false);

      getAllProfileData();
    } else if (result.status > 200) {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }
  };
  function handleSubmit(e) {
    setError("");
    setLoading(true);
    e.preventDefault();
    let confirmationResult = window.confirmationResult;
    confirmationResult
      .confirm(otp)
      .then((result) => {
        const user = result.user;
        // console.log(user);
        changeMobileNumAPI(user);
      })
      .catch((error) => {
        console.log(error.message);
        setError("OTP verification failed! please try again.");
        setLoading(false);
      });
  }
  const resendOtp = () => {
    setSeconds(60);
    setError("");
    const auth = getAuth();
    const appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, "+91" + mobileNumber, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
      })
      .catch((error) => {
        console.log(error);
        setSeconds(0);
      });
  };

  return (
    <div>
      {/* {JSON.stringify(credentialLoginAccountProfileDetailAtom.data)} */}
      <p className="fz-16px pb-1 text-black">
        We Sent 6 Digit OTP to +91{mobileNumber}
      </p>
      <form className="login-col" onSubmit={handleSubmit}>
        <label className="fz-15px text-prime">Enter the OTP</label>
        <input
          className="mb-2"
          defaultValue={otp}
          onChange={(e) => setOtp(e.target.value)}
          onInput={(e) => {
            if (e.target.value.trim() == "") {
              e.target.value = "";
            }
            if (!e.target.value.match(/^(\d+(\.\d+)?)$/)) {
              e.target.value = e.target.value.replace(/\D/g, "");
            }
          }}
          type="text"
          maxLength={6}
          minLength={6}
          required
        />
        <div className="d-flex align-items-center justify-content-end mb-2 otp">
          {" "}
          {minutes === 0 && seconds === 0 ? null : (
            <h6 className="m-0">
              {" "}
              {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
            </h6>
          )}
          <button
            role="button"
            type="button"
            onClick={resendOtp}
            disabled={seconds === 0 ? false : true}
            className="ms-2 mb-0 w-auto border-0 bg-transparent"
          >
            Resend
          </button>
        </div>
        {error && (
          <small className="text-danger fw-500 pb-2 d-block">{error}</small>
        )}
        <button
          className="login-form-btn"
          type="submit"
          disabled={loading || seconds === 0 ? true : false}
        >
          {loading ? "Please wait..." : "Verify"}
        </button>
      </form>
    </div>
  );
}

export default VerifyMobileOtp;
