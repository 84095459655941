import React, { useEffect, useState, useRef } from "react";

const Timer = ({ initialMinutes = 0, initialSeconds = 60, onExpire }) => {
  const [minutes, setMinutes] = useState(initialMinutes);
  const [seconds, setSeconds] = useState(initialSeconds);
  const intervalRef = useRef(null);

  useEffect(() => {
    if (intervalRef.current) clearInterval(intervalRef.current);

    intervalRef.current = setInterval(() => {
      if (seconds > 0) {
        setSeconds((prevSeconds) => prevSeconds - 1);
      } else if (seconds === 0) {
        if (minutes > 0) {
          setMinutes((prevMinutes) => prevMinutes - 1);
          setSeconds(59);
        } else {
          clearInterval(intervalRef.current);
          if (onExpire) onExpire();
        }
      }
    }, 1000);

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, [seconds, minutes, onExpire]);

  return (
    <h6 className="m-0">
      {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
    </h6>
  );
};

export default React.memo(Timer);
