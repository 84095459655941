import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import swal from "sweetalert";
import { API_CALL } from "../../../../../api/ApiServiceCall";
import { API_CONSTANTS, API_METHOD } from "../../../../../common/constants";
import { liveCourseFormDataState } from "../../../../atom/liveCourseFormDataState";
import { liveCourseStepFormViewAtomState } from "../../../../atom/liveCourseStepFormViewAtom";
function Step3LiveCourse() {
  const [
    liveCourseStepFormViewAtomComponent,
    setLiveCourseStepFormViewAtomComponent,
  ] = useRecoilState(liveCourseStepFormViewAtomState);
  const [liveCourseFormDataAtom, setLiveCourseFormDataAtom] = useRecoilState(
    liveCourseFormDataState
  );
  const [isValidate, setIsValidate] = useState({
    duration: false,
    from: false,
    day: false,
  });
  const [datePlaceHolder, setDatePlaceHolder] = useState("");
  const [loading, setLoading] = useState(0);
  const navigate = useNavigate();
  const [liveCourseFormDataStep3, setLiveCourseFormDataStep3] = useState({
    duration: "",
    from: "",
  });
  const [day, setDay] = useState([
    {
      day: "sunday",
      endTime: "",
      id: "0",
      isAvailable: false,
      startTime: "",
      isError: false,
    },
    {
      day: "monday",
      endTime: "",
      id: "1",
      isAvailable: false,
      startTime: "",
      isError: false,
    },
    {
      day: "tuesday",
      endTime: "",
      id: "2",
      isAvailable: false,
      startTime: "",
      isError: false,
    },
    {
      day: "wednesday",
      endTime: "",
      id: "3",
      isAvailable: false,
      startTime: "",
      isError: false,
    },
    {
      day: "thursday",
      endTime: "",
      id: "4",
      isAvailable: false,
      startTime: "",
      isError: false,
    },
    {
      day: "friday",
      endTime: "",
      id: "5",
      isAvailable: false,
      startTime: "",
      isError: false,
    },
    {
      day: "saturday",
      endTime: "",
      id: "6",
      isAvailable: false,
      startTime: "",
      isError: false,
    },
  ]);
  let isErrorVar = day.some((classTimeData) => classTimeData.isError === true);
  const finalSubmit = (e) => {
    e.preventDefault();
    const { toTime } = e.target.elements;
    // console.log(toTime.value)
    let isDayValidationResult = day.some(
      (classTimeData, index) =>
        classTimeData.isAvailable &&
        classTimeData.startTime &&
        classTimeData.endTime &&
        !classTimeData.isError
    );
    let isErrorChecking = day.some(
      (classTimeData) => classTimeData.isError === true
    );
    const isAllow =
      liveCourseFormDataStep3.duration &&
      liveCourseFormDataStep3.from &&
      isDayValidationResult &&
      !isErrorChecking;

    if (isAllow) {
      finalSubmitApprove(toTime.value);
    } else {
      setIsValidate({ duration: true, from: true, day: true });
      day.forEach((classData) => {
        if (classData.isError || !isDayValidationResult) {
          setIsDayValidation(true);
        } else {
          setIsDayValidation(false);
        }
      });
    }
  };
  const finalSubmitApprove = async (toTime) => {
    // const filterCheckedDay = day.filter(item => item.isAvailable == true).map(filteredData => (
    //     filteredData
    // ))
    const finalDayList = { classTime: day };
    const finalData = {
      ...liveCourseFormDataAtom,
      ...liveCourseFormDataStep3,
      ...finalDayList,
    };
    setLoading(true);
    // console.log("-----------Form Data-------");
    // console.log();
    // console.log("------------------");
    const formData = new FormData();
    formData.append("courseName", finalData.courseTitle);
    formData.append("courseOverview", finalData.courseOverview);
    // formData.append('nextCourseDetails', finalData.nextCourseDetails);
    formData.append("catogory", finalData.category);
    formData.append("subCategory", finalData.subCategory);
    formData.append("currency", finalData.currency);
    formData.append("fee", finalData.fee);
    formData.append("courseDuration", finalData.duration);
    formData.append("fromTime", finalData.from);
    formData.append(
      "toTime",
      moment(toTime, "DD-MM-YYYY").format("YYYY-MM-DD")
    );
    formData.append("classTime", JSON.stringify(finalData.classTime));
    formData.append("thumbnail", finalData.thumbNail);
    formData.append("thumbnail", finalData.introVideo);
    // console.log(Object.fromEntries(formData));
    for (var pair of formData.entries()) {
      console.log("formData Append---->", pair[0] + ", " + pair[1]);
    }
    let requestBodyParam = formData;
    const usrdetailforHeader = JSON.parse(
      localStorage.getItem("localUserData")
    );
    let apiHeader = {
      "x-auth-token": usrdetailforHeader?.jwt,
    };
    if (usrdetailforHeader?.data?.user?.email) {
      apiHeader = {
        token: usrdetailforHeader?.data?.user?.email,
        gmailUserType: "INSTRUCTOR",
      };
    }
    const request = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.CREATE_LIVE_COURSE,
      method: API_METHOD.POST,
      headers: apiHeader,
      requestBodyParams: requestBodyParam,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      setLoading(false);
      navigate("/courses/live");
    } else if (result.status > 200) {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
      setLoading(false);
    }
  };

  const handleInputChange = (e, index) => {
    if (e.target.name == "isAvailable") {
      const { name, checked } = e.target;
      const list = [...day];
      list[index][name] = checked;
      if (
        list[index].isAvailable ||
        list[index].startTime ||
        list[index].endTime
      ) {
        list[index].isError = true;
      } else {
        list[index].isError = false;
      }
      if (
        list[index].isAvailable &&
        list[index].startTime &&
        list[index].endTime
      ) {
        list[index].isError = false;
      }
      setDay(list);
    } else {
      const { name, value } = e.target;
      const list = [...day];
      list[index][name] = value;
      if (
        list[index].isAvailable ||
        list[index].startTime ||
        list[index].endTime
      ) {
        list[index].isError = true;
      } else {
        list[index].isError = false;
      }
      if (
        list[index].isAvailable &&
        list[index].startTime &&
        list[index].endTime
      ) {
        list[index].isError = false;
      }
      setDay(list);
    }
  };
  const [today, setToday] = useState(new Date().toISOString().split("T")[0]);
  const [isDayValidation, setIsDayValidation] = useState(false);

  const validateForm = () => {
    day.forEach((classTimeData, index) => {
      if (
        classTimeData.isAvailable ||
        classTimeData.startTime ||
        classTimeData.endTime
      ) {
        if (
          classTimeData.startTime &&
          classTimeData.endTime &&
          classTimeData.isAvailable
        ) {
          setIsDayValidation(false);
        } else {
          setIsDayValidation(true);
        }
      }
    });
  };
  useEffect(() => {
    validateForm();
  }, [day]);

  let createdDate = moment(new Date(liveCourseFormDataStep3?.from))
    .utc()
    .format();

  let toDate;
  if (datePlaceHolder == "dd/mm/yyyy") {
    toDate = moment(createdDate)
      .add(Number(liveCourseFormDataStep3?.duration.split("")[0]), "M")
      .format("DD/MM/YYYY");
  } else {
    toDate = moment(createdDate)
      .add(Number(liveCourseFormDataStep3?.duration.split("")[0]), "M")
      .format("DD-MM-YYYY");
  }
  useEffect(() => {
    detectBrowser();
  }, []);
  function detectBrowser() {
    const userAgent = navigator.userAgent;
    if (userAgent.indexOf("Firefox") !== -1) {
      setDatePlaceHolder("dd/mm/yyyy");
    } else if (userAgent.indexOf("Chrome") !== -1) {
      setDatePlaceHolder("dd-mm-yyyy");
    } else {
      setDatePlaceHolder("dd/mm/yyyy");
    }
  }
  return (
    <>
      <form onSubmit={finalSubmit}>
        <div className="container live-course-stp3">
          {/* <h5>{isErrorVar.toString()}===={isErrorVar.toString()}</h5> */}
          <div className="row">
            <div className="col-12">
              <label className="py-3 fw-500">Course Duration</label>
              <div className="tag-ajus">
                <select
                  defaultValue={liveCourseFormDataStep3.duration}
                  onBlur={() =>
                    setIsValidate({ ...isValidate, duration: true })
                  }
                  onChange={(e) =>
                    setLiveCourseFormDataStep3({
                      ...liveCourseFormDataStep3,
                      duration: e.target.value,
                    })
                  }
                >
                  <option value="" disabled>
                    Select Duration<span className="text-danger">*</span>
                  </option>
                  <option value="1month">1 month</option>
                  <option value="2months">2 months</option>
                  <option value="3months">3 months</option>
                  <option value="6months">6 months</option>
                </select>
              </div>
              {isValidate.duration &&
              liveCourseFormDataStep3.duration === "" ? (
                <small className="text-danger my-0 d-block fz-12px mt-3 fw-500">
                  Please Fill this field
                </small>
              ) : (
                ""
              )}
            </div>
            <div className="col-6 ">
              <label className="py-3 fw-500">From</label>
              <input
                type="date"
                onKeyDown={(e) => {
                  if (e.code) e.preventDefault();
                }}
                min={moment(today, "YYYY-MM-DD")
                  .add(1, "day")
                  .format("YYYY-MM-DD")}
                max={moment(today, "YYYY-MM-DD")
                  .add(4, "years")
                  .format("YYYY/MM/DD")}
                className="default-inpt"
                placeholder=" "
                onBlur={() => setIsValidate({ ...isValidate, from: true })}
                onChange={(e) =>
                  setLiveCourseFormDataStep3({
                    ...liveCourseFormDataStep3,
                    from: e.target.value,
                  })
                }
                disabled={liveCourseFormDataStep3.duration ? false : true}
              />
              {isValidate.from && liveCourseFormDataStep3.from === "" ? (
                <small className="text-danger my-0 d-block fz-12px mt-3 fw-500">
                  Please Fill this field
                </small>
              ) : (
                ""
              )}
            </div>
            <div className="col-6 ">
              <label className="py-3 fw-500">To</label>
              <input
                type="text"
                className="default-inpt"
                placeholder={datePlaceHolder}
                id="toTime"
                defaultValue={toDate == "Invalid date" ? "" : toDate}
                key={toDate}
                disabled={true}
              />
            </div>
          </div>
          <div className="my-4"></div>
          <h6>Class Time</h6>
          <div className="my-3"></div>

          {(isValidate.day && isDayValidation) || isErrorVar ? (
            <small className="text-danger my-0 d-block fz-12px mt-3 fw-500">
              Please fill out the fields properly, at least one field must be
              filled out
            </small>
          ) : (
            ""
          )}
          <div className="row">
            <div className="col-6 pe-4">
              {day.slice(0, 4).map((classTime, indx) => {
                return (
                  <div key={classTime.id}>
                    <div className=" mt-4 row d-flex align-items-center">
                      <div className="col-lg-3">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={classTime.day}
                          name="isAvailable"
                          onChange={(e) => handleInputChange(e, indx)}
                        />
                        <label
                          className=" mx-2 w-0  form-check-label fz-13px fw-600"
                          htmlFor={classTime.day}
                        >
                          {classTime.day}
                        </label>
                      </div>
                      <div className="col-lg-4">
                        <input
                          type="time"
                          className="default-inpt cst-clock"
                          name="startTime"
                          onChange={(e) => handleInputChange(e, indx)}
                        />
                        {/* {day[indx].endTime && day[indx].startTime ? moment(day[indx].startTime, 'h:mm').isBefore(moment(day[indx].endTime, 'h:mm')) ? "" : <small className='text-danger'>Invalid</small> : ""} */}
                      </div>
                      <div className="col-lg-1">
                        <p className="m-0 p-0 fz-13px fw-600">to</p>
                      </div>
                      <div className="col-lg-4">
                        <input
                          type="time"
                          className="default-inpt cst-clock"
                          name="endTime"
                          onChange={(e) => handleInputChange(e, indx)}
                        />
                        {/* {day[indx].startTime && day[indx].endTime ? moment(day[indx].endTime, 'h:mm').isAfter(moment(day[indx].startTime, 'h:mm')) ? "" : <small className='text-danger'>Invalid</small> : ""} */}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="col-6 pe-4">
              {day.slice(4, 7).map((classTime, indx) => {
                return (
                  <div key={classTime.id}>
                    <div className=" mt-4 row d-flex align-items-center">
                      <div className="col-lg-3">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={classTime.day}
                          name="isAvailable"
                          onChange={(e) => handleInputChange(e, indx + 4)}
                        />
                        <label
                          className=" mx-2  form-check-label fz-13px fw-600"
                          htmlFor={classTime.day}
                        >
                          {classTime.day}
                        </label>
                      </div>
                      <div className="col-lg-4">
                        <input
                          type="time"
                          className="default-inpt cst-clock"
                          name="startTime"
                          onChange={(e) => handleInputChange(e, indx + 4)}
                        />
                        {/* {day[indx + 4].endTime && day[indx + 4].startTime ? moment(day[indx + 4].startTime, 'h:mm').isBefore(moment(day[indx + 4].endTime, 'h:mm')) ? "" : <small className='text-danger'>Invalid</small> : ""} */}
                      </div>
                      <div className="col-lg-1">
                        <p className="m-0 p-0 fz-13px fw-600">to</p>
                      </div>
                      <div className="col-lg-4">
                        <input
                          type="time"
                          className="default-inpt cst-clock"
                          name="endTime"
                          onChange={(e) => handleInputChange(e, indx + 4)}
                        />
                        {/* {day[indx + 4].endTime && day[indx + 4].startTime ? moment(day[indx + 4].endTime, 'h:mm').isAfter(moment(day[indx + 4].startTime, 'h:mm')) ? "" : <small className='text-danger'>Invalid</small> : ""} */}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="py-3"></div>
        <div className="d-flex justify-content-between">
          <button
            type="button"
            className=" border-0 bg-secondary text-white fz-14px py-12px w-14 rounded-3 text-center"
            onClick={() =>
              setLiveCourseStepFormViewAtomComponent({ componentName: "Step2" })
            }
          >
            Back
          </button>
          <button
            type="submit"
            className=" border-0 bg-prime text-white fz-14px py-12px w-14 rounded-3 text-center"
            disabled={loading ? true : false}
          >
            {loading ? "Please Wait..." : "Create"}
          </button>
        </div>
        <div className="py-3"></div>
        {/* <pre>{JSON.stringify(day, null, 4)}</pre> */}
      </form>
    </>
  );
}
export default Step3LiveCourse;
