//import { useState, useEffect } from 'react';
import { useSetRecoilState } from "recoil";
// import { useNavigate } from 'react-router-dom';
import swal from "sweetalert";
// import CartCourseCard from './CartCourseCard'; useRecoilValue,
// import CoursesList from '../common/CoursesList';
import { currencySymbol } from "../common/constants";
import { API_CALL } from "./../../api/ApiServiceCall";
import {
  API_CONSTANTS,
  API_METHOD,
  PICTURE_STORE,
} from "./../../common/constants";
import { cartState } from "./../atoms/cart-atom";

function AssessmentCard({ assessment, disableRemove }) {
  const setCart = useSetRecoilState(cartState);

  const getCart = async () => {
    const request = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.CART,
      method: API_METHOD.GET,
    };

    let result = await API_CALL(request);

    if (result.status === 200) {
      setCart({ ...result.data });
    } else if (result.status > 200) {
      if (result.message == "Token is Not Valid") return null;

      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }
  };

  const handleDelete = async (assessmentCode) => {
    // return alert(assessment.assessmentCode);

    const request = {
      apiUrl:
        API_CONSTANTS.BASE_URL +
        API_CONSTANTS.REMOVE_ASSESSMENT_FROM_CART(assessmentCode),
      method: API_METHOD.DELETE,
    };

    let result = await API_CALL(request);

    if (result.status === 200) {
      getCart();

      swal({
        title: "Success",
        text: "Assessment Removed from the Cart",
        icon: PICTURE_STORE.TickImage,
        imageHeight: 580,
        imageWidth: 580,
        className: "success-window",
        button: true,
      });
    } else if (result.status > 200) {
      if (result.message == "Token is Not Valid") return null;

      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }
  };

  return (
    <div
      className="bg-white d-flex justify-content-between align-items-center py-4 rounded-4 mb-4"
      style={{ paddingLeft: "2.1em", paddingRight: "2.1em" }}
    >
      <div className="d-flex gap-3 align-items-center">
        <svg
          width="50"
          height="50"
          viewBox="0 0 70 70"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="35" cy="35" r="35" fill="#F0E1EB" />
          <g clip-path="url(#clip0_712_4674)">
            <path
              d="M26.9211 37.2179C27.0429 37.3461 27.2142 37.4199 27.3911 37.4201H27.392C27.5686 37.4201 27.7397 37.3469 27.8618 37.2192L28.6978 36.3433C28.8933 36.1385 28.8858 35.8139 28.6809 35.6184C28.4761 35.4229 28.1516 35.4304 27.956 35.6353L27.3926 36.2255L27.2965 36.1244C27.1014 35.9191 26.7769 35.9109 26.5717 36.1058C26.3664 36.3009 26.3581 36.6254 26.5531 36.8307L26.9211 37.2179Z"
              fill="#395061"
            />
            <path
              d="M30.5759 34.3535C30.5759 33.8823 30.1926 33.499 29.7214 33.499H25.5322C25.061 33.499 24.6777 33.8823 24.6777 34.3535V38.5426C24.6777 39.0138 25.061 39.3971 25.5322 39.3971H29.7214C30.1926 39.3971 30.5759 39.0138 30.5759 38.5426V34.3535ZM29.5505 38.3718H25.7031V34.5245H29.5505V38.3718Z"
              fill="#395061"
            />
            <path
              d="M32.5732 39.3975H39.8494C40.1326 39.3975 40.3621 39.168 40.3621 38.8848C40.3621 38.6016 40.1326 38.3721 39.8494 38.3721H32.5732C32.2901 38.3721 32.0605 38.6016 32.0605 38.8848C32.0605 39.168 32.2901 39.3975 32.5732 39.3975Z"
              fill="#395061"
            />
            <path
              d="M32.5732 37.3037H39.8494C40.1326 37.3037 40.3621 37.0742 40.3621 36.791C40.3621 36.5078 40.1326 36.2783 39.8494 36.2783H32.5732C32.2901 36.2783 32.0605 36.5078 32.0605 36.791C32.0605 37.0742 32.2901 37.3037 32.5732 37.3037Z"
              fill="#395061"
            />
            <path
              d="M26.9212 30.0179C27.0431 30.1461 27.2145 30.2197 27.3913 30.2199H27.3919C27.5686 30.2199 27.7398 30.1466 27.8617 30.0189L28.6977 29.1431C28.8933 28.9383 28.8857 28.6138 28.6809 28.4182C28.4761 28.2228 28.1516 28.2302 27.956 28.435L27.3926 29.0252L27.2965 28.9241C27.1014 28.7189 26.7769 28.7107 26.5717 28.9056C26.3664 29.1006 26.3581 29.4252 26.5531 29.6305L26.9212 30.0179Z"
              fill="#395061"
            />
            <path
              d="M30.5759 27.1533C30.5759 26.6821 30.1926 26.2988 29.7214 26.2988H25.5322C25.061 26.2988 24.6777 26.6821 24.6777 27.1533V31.3425C24.6777 31.8137 25.061 32.197 25.5322 32.197H29.7214C30.1926 32.197 30.5759 31.8137 30.5759 31.3425V27.1533ZM29.5505 31.1716H25.7031V27.3242H29.5505V31.1716Z"
              fill="#395061"
            />
            <path
              d="M32.5732 32.1973H39.8494C40.1326 32.1973 40.3621 31.9678 40.3621 31.6846C40.3621 31.4014 40.1326 31.1719 39.8494 31.1719H32.5732C32.2901 31.1719 32.0605 31.4014 32.0605 31.6846C32.0605 31.9678 32.2901 32.1973 32.5732 32.1973Z"
              fill="#395061"
            />
            <path
              d="M32.5732 30.1035H39.8494C40.1326 30.1035 40.3621 29.874 40.3621 29.5908C40.3621 29.3076 40.1326 29.0781 39.8494 29.0781H32.5732C32.2901 29.0781 32.0605 29.3076 32.0605 29.5908C32.0605 29.874 32.2901 30.1035 32.5732 30.1035Z"
              fill="#395061"
            />
            <path
              d="M26.9212 44.431C27.0431 44.5593 27.2145 44.6329 27.3913 44.633H27.3919C27.5686 44.633 27.7398 44.5598 27.8617 44.4321L28.6977 43.5562C28.8933 43.3514 28.8857 43.0269 28.6809 42.8314C28.4761 42.6358 28.1516 42.6433 27.956 42.8482L27.3926 43.4384L27.2965 43.3373C27.1014 43.1321 26.7769 43.1238 26.5717 43.3188C26.3664 43.5138 26.3581 43.8384 26.5531 44.0436L26.9212 44.431Z"
              fill="#395061"
            />
            <path
              d="M29.7214 40.7119H25.5322C25.061 40.7119 24.6777 41.0952 24.6777 41.5664V45.7556C24.6777 46.2268 25.061 46.6101 25.5322 46.6101H29.7214C30.1926 46.6101 30.5759 46.2268 30.5759 45.7556V41.5664C30.5759 41.0952 30.1926 40.7119 29.7214 40.7119ZM29.5505 45.5847H25.7031V41.7373H29.5505V45.5847Z"
              fill="#395061"
            />
            <path
              d="M41.285 45.585H32.5732C32.2901 45.585 32.0605 45.8144 32.0605 46.0977C32.0605 46.3809 32.2901 46.6104 32.5732 46.6104H41.285C41.5681 46.6104 41.7977 46.3809 41.7977 46.0977C41.7977 45.8144 41.5681 45.585 41.285 45.585Z"
              fill="#395061"
            />
            <path
              d="M32.0605 44.0039C32.0605 44.2871 32.2901 44.5166 32.5732 44.5166H41.285C41.5681 44.5166 41.7977 44.2871 41.7977 44.0039C41.7977 43.7207 41.5681 43.4912 41.285 43.4912H32.5732C32.2901 43.4912 32.0605 43.7208 32.0605 44.0039Z"
              fill="#395061"
            />
            <path
              d="M44.3568 46.0083C44.0737 46.0083 43.8442 46.2378 43.8442 46.521V49.7563C43.8442 50.0606 43.5966 50.3081 43.2923 50.3081H23.1827C22.8784 50.3081 22.6309 50.0606 22.6309 49.7563V22.2562C22.6309 21.952 22.8784 21.7044 23.1827 21.7044H27.5158V22.9611C27.5158 23.7158 28.1299 24.3298 28.8847 24.3298H37.5904C38.3451 24.3298 38.9592 23.7158 38.9592 22.9611V21.7044H40.3076C40.5907 21.7044 40.8203 21.4749 40.8203 21.1917C40.8203 20.9084 40.5907 20.679 40.3076 20.679H38.9592V19.7509C38.9592 18.9962 38.3451 18.3821 37.5904 18.3821H35.8748V17.9044C35.8748 17.0381 35.1701 16.3335 34.3039 16.3335H32.1711C31.3049 16.3335 30.6002 17.0382 30.6002 17.9044V18.382H28.8847C28.1299 18.382 27.5158 18.9961 27.5158 19.7508V20.6789H23.1827C22.313 20.6789 21.6055 21.3865 21.6055 22.2561V49.7562C21.6055 50.6258 22.313 51.3334 23.1827 51.3334H43.2924C44.162 51.3334 44.8696 50.6258 44.8696 49.7562V46.521C44.8695 46.2378 44.64 46.0083 44.3568 46.0083ZM28.5412 19.7509C28.5412 19.5615 28.6953 19.4075 28.8847 19.4075H30.8515C31.2783 19.4075 31.6256 19.0603 31.6256 18.6334V17.9044C31.6256 17.6036 31.8703 17.3589 32.1711 17.3589H34.3039C34.6047 17.3589 34.8494 17.6036 34.8494 17.9044V18.6334C34.8494 19.0602 35.1967 19.4074 35.6235 19.4074H37.5904C37.7797 19.4074 37.9338 19.5615 37.9338 19.7508V22.9609C37.9338 23.1503 37.7797 23.3043 37.5904 23.3043H28.8847C28.6953 23.3043 28.5412 23.1502 28.5412 22.9609V19.7509Z"
              fill="#395061"
            />
            <path
              d="M47.6691 23.7315H46.6829V19.8587C46.6829 18.9183 45.9179 18.1533 44.9775 18.1533H43.7562C42.8159 18.1533 42.0508 18.9183 42.0508 19.8587C42.0508 19.8587 42.0513 38.3576 42.0513 38.3612C42.0513 38.3647 42.0508 38.3683 42.0508 38.3718V40.2665C42.0508 40.5949 42.118 40.9137 42.2507 41.2141L43.5342 44.1205C43.682 44.4552 44.001 44.6631 44.3669 44.6631C44.7327 44.6631 45.0518 44.4552 45.1995 44.1205L46.483 41.2141C46.6157 40.9136 46.6829 40.5948 46.6829 40.2664V38.3718C46.6829 38.3682 46.6825 38.3647 46.6824 38.3612C46.6825 38.3576 46.6829 35.0176 46.6829 35.0176C46.6829 34.7344 46.4534 34.5049 46.1703 34.5049C45.8871 34.5049 45.6576 34.7344 45.6576 35.0176V37.8378H43.0762V22.985H45.6576V32.8848C45.6576 33.168 45.8871 33.3975 46.1703 33.3975C46.4534 33.3975 46.6829 33.168 46.6829 32.8848V24.7569H47.669C47.6879 24.7569 47.7032 24.7723 47.7032 24.7911V30.5356C47.7032 30.8188 47.9328 31.0483 48.2159 31.0483C48.499 31.0483 48.7286 30.8188 48.7286 30.5356V24.7911C48.7287 24.2068 48.2534 23.7315 47.6691 23.7315ZM45.6576 40.2665C45.6576 40.4514 45.6197 40.6308 45.545 40.8L44.3669 43.4679L43.1888 40.8C43.1141 40.6308 43.0763 40.4514 43.0763 40.2665V38.8846H45.6576V40.2665H45.6576ZM43.0762 21.9596V19.8587C43.0762 19.4838 43.3813 19.1787 43.7563 19.1787H44.9776C45.3525 19.1787 45.6576 19.4838 45.6576 19.8587V21.9596H43.0762V21.9596Z"
              fill="#395061"
            />
          </g>
          <defs>
            <clipPath id="clip0_712_4674">
              <rect
                width="35"
                height="35"
                fill="white"
                transform="translate(17.666 16.3335)"
              />
            </clipPath>
          </defs>
        </svg>
        <div>
          <h3 className="mb-0 fs-5">{assessment.assessmentTitle}</h3>
          <p
            className="mt-1 mb-1"
            style={{ fontSize: "0.9rem", color: "gray" }}
          >
            By: {assessment.instructorName}
          </p>
          <span className="mt-4 fw-500">
            {currencySymbol[assessment.currency]}
            {assessment.fee}
          </span>
        </div>
      </div>
      {/* assessment.asses */}
      {!disableRemove && (
        <div>
          <button
            onClick={() => handleDelete(assessment.assessmentCode)}
            className="btn px-3 py-1 align-self-end"
            style={{ backgroundColor: "#F0E1EB", color: "#F65656" }}
          >
            Remove
          </button>
        </div>
      )}
    </div>
  );
}

export default AssessmentCard;
