import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { liveCourseFormDataState } from "../../../../atom/liveCourseFormDataState";
import { liveCourseStepFormViewAtomState } from "../../../../atom/liveCourseStepFormViewAtom";
import MyS3Component from "../../../../s3/s3";
function Step2LiveCourse() {
  const [
    liveCourseStepFormViewAtomComponent,
    setLiveCourseStepFormViewAtomComponent,
  ] = useRecoilState(liveCourseStepFormViewAtomState);
  const [liveCourseFormDataAtom, setLiveCourseFormDataAtom] = useRecoilState(
    liveCourseFormDataState
  );
  const [liveCourseFormDataStep2, setLiveCourseFormDataStep2] = useState({
    currency: "INR",
    fee: "",
    thumbNail: "",
    introVideo: "",
  });
  const [isValidate, setIsValidate] = useState({
    currency: false,
    fee: false,
    thumbNail: false,
    introVideo: false,
  });
  const [source, setSource] = useState();
  const [image, setImage] = useState("");
  const [imageFileValidation, setImageFileValidation] = useState("");
  const [videoValidation, setVideoValidation] = useState("");
  const nextStep3 = () => {
    // setLiveCourseStepFormViewAtomComponent({ componentName: "Step3" });

    const isAllow =
      liveCourseFormDataStep2.currency &&
      liveCourseFormDataStep2.fee &&
      liveCourseFormDataStep2.thumbNail &&
      liveCourseFormDataStep2.introVideo;
    if (isAllow) {
      setLiveCourseFormDataAtom({
        ...liveCourseFormDataAtom,
        ...liveCourseFormDataStep2,
      });
      setLiveCourseStepFormViewAtomComponent({ componentName: "Step3" });
    } else {
      setIsValidate({
        currency: true,
        fee: true,
        thumbNail: true,
        introVideo: true,
      });
    }
  };
  //for video preview
  const handleFileChange = (e) => {
    let file = e.target.files[0];
    const uniqueId = "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
    let fileExtension = file.name.split(".").pop();
    const uniqueFileName = `${uniqueId}.${fileExtension}`;
    file = new File([file], uniqueFileName, { type: file.type });
    if (file.type == "video/mp4") {
      setVideoValidation(false);
      setLiveCourseFormDataStep2({
        ...liveCourseFormDataStep2,
        introVideo: file,
      });
      const url = URL.createObjectURL(file);
      setSource(url);
    } else {
      setVideoValidation("Please select the mp4 format");
    }
  };
  //for Image preview
  async function readImage(e, func) {
    let file = e.target.files[0];
    const uniqueId = "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
    let fileExtension = file.name.split(".").pop();
    const uniqueFileName = `${uniqueId}.${fileExtension}`;
    file = new File([file], uniqueFileName, { type: file.type });
    console.log(4, { file });

    let fileFormat = fileExtension.toLowerCase();

    if (fileFormat == "png" || fileFormat == "jpeg" || fileFormat == "jpg") {
      setImageFileValidation(false);
      setLiveCourseFormDataStep2({
        ...liveCourseFormDataStep2,
        thumbNail: file,
      });
      const reader = new FileReader();
      reader.onload = function (e) {
        let binaryData = e.target.result;
        let base64String = window.btoa(binaryData);
        func(base64String);
      };

      let image = reader.readAsBinaryString(file);
      return image;
    } else {
      setImageFileValidation("Please select JPG, PNG, or JPEG format");
    }
  }
  const sameWH = {
    height: "350px",
    // objectFit: "cover",
    width: "100%",
    borderRadius: "7px",
  };
  return (
    <div className="container">
      {/* {JSON.stringify(liveCourseFormDataStep2)} */}
      <div className="row">
        <div className="col-6">
          <label className="py-3 fw-500">Currency</label>
          <div className="tag-ajus">
            <select
              disabled
              defaultValue={liveCourseFormDataStep2.currency}
              onBlur={() => setIsValidate({ ...isValidate, currency: true })}
              onChange={(e) =>
                setLiveCourseFormDataStep2({
                  ...liveCourseFormDataStep2,
                  currency: e.target.value,
                })
              }
            >
              <option value="" disabled>
                Select Currency
              </option>
              <option value="INR">₹</option>
              <option value="USD">$</option>
            </select>
          </div>
          {isValidate.currency && liveCourseFormDataStep2.currency === "" ? (
            <small className="text-danger my-0 d-block fz-12px my-2 fw-500">
              Please Fill this field
            </small>
          ) : (
            ""
          )}
        </div>
        <div className="col-6 ">
          <label className="py-3 fw-500">Fee</label>
          <div className="tag-ajus">
            <select
              defaultValue={liveCourseFormDataStep2.fee}
              onBlur={() => setIsValidate({ ...isValidate, fee: true })}
              onChange={(e) =>
                setLiveCourseFormDataStep2({
                  ...liveCourseFormDataStep2,
                  fee: e.target.value,
                })
              }
            >
              <option value="" disabled>
                Select Fee
              </option>
              <option value="1000">1000</option>
              <option value="2000">2000</option>
              <option value="5000">5000</option>
            </select>
          </div>
          {isValidate.fee && liveCourseFormDataStep2.fee === "" ? (
            <small className="text-danger my-0 d-block fz-12px my-2 fw-500">
              Please Fill this field
            </small>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="my-5"></div>
      <div className="row">
        <div className="col">
          <h6>Thumbnail</h6>
          {imageFileValidation ? (
            <small className="text-danger mb-2 d-block fz-12px fw-500">
              {imageFileValidation}
            </small>
          ) : (
            ""
          )}
          <p className="text-muted fz-12px">
            Width 600 px, Height 350px. Format will be JPG, PNG, JPEG.
          </p>
          {image ? (
            <img
              src={`data:image/jpeg;base64,${image}`}
              alt="uploaded"
              className="mb-3 rounded-3 w-100"
              style={sameWH}
            />
          ) : (
            ""
          )}
          <input
            className={image ? "d-none file-prim" : "file-prim"}
            id="change_image"
            type="file"
            accept="image/gif, image/jpeg, image/png"
            onChange={(event) => {
              readImage(event, setImage);
            }}
          />
          {image ? (
            <label
              htmlFor="change_image"
              className="w-100 py-2 text-center text-white fz-13px bg-secondary rounded-2"
              role="button"
            >
              Change Image
            </label>
          ) : (
            ""
          )}
          {isValidate.thumbNail && liveCourseFormDataStep2.thumbNail === "" ? (
            <small className="text-danger my-0 d-block fz-12px my-1 fw-500">
              Please Fill this field
            </small>
          ) : (
            ""
          )}
        </div>

        <div className="col mx-2 px-0">
          <h6>Intro Video</h6>
          {videoValidation ? (
            <small className="text-danger mb-2 d-block fz-12px fw-500">
              {videoValidation}
            </small>
          ) : (
            ""
          )}
          <p className="text-muted fz-12px">
            Width 600 px, Height 350px. Format will be mp3 or mp4.
          </p>
          {source ? (
            <>
              {" "}
              <MyS3Component
                style={sameWH}
                className="VideoInput_video"
                width="100%"
                height="auto"
                controls
                imageSrc={source}
              />
            </>
          ) : (
            ""
          )}

          <input
            className={source ? "d-none file-prim" : "file-prim"}
            id="change_video"
            type="file"
            onChange={handleFileChange}
            accept=".mp4"
          />
          {source ? (
            <label
              htmlFor="change_video"
              className="w-100 py-2 text-center text-white fz-13px bg-secondary rounded-2"
              role="button"
            >
              Change intro Video
            </label>
          ) : (
            ""
          )}
          {isValidate.introVideo &&
          liveCourseFormDataStep2.introVideo === "" ? (
            <small className="text-danger my-0 d-block fz-12px my-1 fw-500">
              Please Fill this field
            </small>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="py-3"></div>
      <div className="d-flex justify-content-between">
        <button
          type="button"
          className=" border-0 bg-secondary text-white fz-14px py-12px w-14 rounded-3 text-center"
          onClick={() =>
            setLiveCourseStepFormViewAtomComponent({ componentName: "Step1" })
          }
        >
          Back
        </button>
        <button
          type="button"
          className=" border-0 bg-prime text-white fz-14px py-12px w-14 rounded-3 text-center"
          onClick={nextStep3}
        >
          Next
        </button>
      </div>
      <div className="py-3"></div>
    </div>
  );
}

export default Step2LiveCourse;
