import React from "react";

function EditIcon() {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.976666 15.9325C0.351502 16.5575 0.000188791 17.4052 0 18.2892L0 20H1.71083C2.59482 19.9998 3.44252 19.6485 4.0675 19.0233L15.1867 7.90419L12.0958 4.81335L0.976666 15.9325Z"
        fill="#395061"
      />
      <path
        d="M19.2877 0.712554C19.0848 0.509417 18.8438 0.348267 18.5786 0.238318C18.3134 0.128369 18.029 0.0717773 17.7419 0.0717773C17.4548 0.0717773 17.1705 0.128369 16.9052 0.238318C16.64 0.348267 16.399 0.509417 16.1961 0.712554L13.2744 3.63505L16.3652 6.72588L19.2877 3.80422C19.4909 3.60129 19.652 3.36032 19.762 3.09508C19.8719 2.82983 19.9285 2.54552 19.9285 2.25839C19.9285 1.97126 19.8719 1.68694 19.762 1.4217C19.652 1.15645 19.4909 0.91548 19.2877 0.712554V0.712554Z"
        fill="#395061"
      />
    </svg>
  );
}

export default EditIcon;
