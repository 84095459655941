import { STORAGE_KEY } from "./../../common/constants";

// Hook for localStorage Data
export const useIsLoggedIn = () => {
  const authCookie = document.cookie
    .split("; ")
    .reduce((total, currentCookie) => {
      const item = currentCookie.split("=");
      const storedKey = item[0];
      const storedValue = item[1];
      return STORAGE_KEY.AUTH === storedKey
        ? decodeURIComponent(storedValue)
        : total;
    }, "");

  let user = localStorage.getItem(STORAGE_KEY.LOCAL_USER_DATA);
  user = user ? JSON.parse(user) : user;
  let isLoggedIn = authCookie && user;

  return { isLoggedIn, user };
};
