import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { API_CALL } from "../../../api/ApiServiceCall";
import { API_CONSTANTS, API_METHOD } from "../../../common/constants";

// =========Instructor============
function Notification() {
  const [notificationSetting, setNotificationSetting] = useState();
  //const [loading, setLoading] = useState(false);

  useEffect(() => {
    getNotificationSettingData();
  }, []);

  const getNotificationSettingData = async () => {
    const request = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.GET_NOTIFICATION_MAIN_DATA,
      method: API_METHOD.GET,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      if (result?.data?.length === 0) {
        // swal({
        //   title: "ERROR",
        //   text: "No notification settings found. Please reach out to the administrator.",
        //   icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        //   className: "error-window",
        //   button: true,
        // });
      } else {
        setNotificationSetting(result?.data[0]?.notificationMainList);
      }
    } else if (result.status > 200) {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }
  };

  const handleInputChange = async (e, nCode) => {
    const requestBodyParam = JSON.stringify({
      notificationCode: nCode,
      isNotify: e.target.checked,
    });
    const request = {
      apiUrl:
        API_CONSTANTS.BASE_URL + API_CONSTANTS.UPDATE_NOTIFICATION_MAIN_DATA,
      method: API_METHOD.POST,
      requestBodyParams: requestBodyParam,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
    } else if (result.status > 200) {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }
  };

  return (
    <div className="px-3 mt-5">
      <p className="fw-500">Email Notifications</p>
      {notificationSetting ? (
        notificationSetting?.slice(3, 6)?.map((nsData) => (
          <div key={nsData._id}>
            <div className="py-2"></div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                defaultChecked={nsData.isNotify}
                id={nsData?.notificationCode}
                onChange={(e) => handleInputChange(e, nsData?.notificationCode)}
              />
              <label
                className="form-check-label"
                htmlFor={nsData?.notificationCode}
              >
                {nsData.content}
              </label>
            </div>
          </div>
        ))
      ) : (
        <>
          <span
            className="spinner-border text-secondary spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Loading...</span>
        </>
      )}
      <div className="py-4"></div>
      <p className="fw-500">App Push Notifications</p>
      {notificationSetting ? (
        notificationSetting?.slice(0, 3)?.map((nsData) => (
          <div key={nsData._id}>
            <div className="py-2"></div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                defaultChecked={nsData.isNotify}
                id={nsData?.notificationCode}
                onChange={(e) => handleInputChange(e, nsData?.notificationCode)}
              />
              <label
                className="form-check-label"
                htmlFor={nsData?.notificationCode}
              >
                {nsData.content}
              </label>
            </div>
          </div>
        ))
      ) : (
        <>
          <span
            className="spinner-border text-secondary spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Loading...</span>
        </>
      )}
      <div className="py-4"></div>
    </div>
  );
}

export default Notification;

// OLD

// function Notification() {
//     return (
//         <div className='px-3 mt-5'>
//             <p className='fw-500'>Email Notifications</p>
//             <div className="py-2"></div>
//             <div className="form-check">
//                 <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked1" />
//                 <label className="form-check-label" htmlFor="flexCheckChecked1">
//                     Promotional Offers, Recommendation courses from QLearning
//                 </label>
//             </div>
//             <div className="py-2"></div>
//             <div className="form-check">
//                 <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked2" />
//                 <label className="form-check-label" htmlFor="flexCheckChecked2">
//                     Students course completion
//                 </label>
//             </div>
//             <div className="py-2"></div>
//             <div className="form-check">
//                 <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked3" />
//                 <label className="form-check-label" htmlFor="flexCheckChecked3">
//                     Subscriptions & Newsletters
//                 </label>
//             </div>
//             <div className="py-4"></div>
//             {/* ------------------------------- */}
//             <p className='fw-500'>App Push Notifications</p>
//             <div className="py-2"></div>
//             <div className="form-check">
//                 <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked01" />
//                 <label className="form-check-label" htmlFor="flexCheckChecked01">
//                     Promotional Offers, Recommendation Courses From QLearning
//                 </label>
//             </div>
//             <div className="py-2"></div>
//             <div className="form-check">
//                 <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked02" />
//                 <label className="form-check-label" htmlFor="flexCheckChecked02">
//                     Students Course Completion
//                 </label>
//             </div>
//             <div className="py-2"></div>
//             <div className="form-check">
//                 <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked03" />
//                 <label className="form-check-label" htmlFor="flexCheckChecked03">
//                     Subscriptions & Newsletters
//                 </label>
//             </div>
//             <div className="py-2"></div>
//         </div>
//     )
// }

// export default Notification
