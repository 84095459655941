import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import swal from "sweetalert";
import { API_CALL } from "../../../../../api/ApiServiceCall";
import { API_CONSTANTS, API_METHOD } from "../../../../../common/constants";
import { isSingleLiveCourseEditAtomFunc } from "../../../../atom/isSingleLiveCourseEditAtom";
import { singleLiveCourseDataAtomFunc } from "../../../../atom/singleLiveCourseDataAtom";
import EditIcon from "../../../../svg-icons/editIcon";

function LiveCourseStep3OverviewData() {
  const [singleLiveCourseDataAtom, setSingleLiveCourseDataAtom] =
    useRecoilState(singleLiveCourseDataAtomFunc);
  const [isSingleLiveCourseEditAtom, setIsSingleLiveCourseEditAtom] =
    useRecoilState(isSingleLiveCourseEditAtomFunc);
  let isBlock = true;
  if (singleLiveCourseDataAtom) {
    if (singleLiveCourseDataAtom?.courseStatus == "INREVIEW") {
      isBlock = true;
    } else {
      isBlock = false;
    }
  }
  const [day, setDay] = useState([]);
  useEffect(() => {
    getCurrentLiveCourseData();
  }, [isSingleLiveCourseEditAtom.refetchOnChange]);

  const getCurrentLiveCourseData = async () => {
    const courseCode = localStorage.getItem("courseCode");
    const request = {
      apiUrl:
        API_CONSTANTS.BASE_URL +
        API_CONSTANTS.GET_LIVE_COURSE_BY_COURSE_CODE +
        `/?courseCode=${courseCode}`,
      method: API_METHOD.GET,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      setDay(result.data[0].classTime);
    } else if (result.status > 200) {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }
  };
  const classTimeJsx = singleLiveCourseDataAtom.classTime ? (
    <div className="row  clock-icon-d-none">
      {/* <pre>{JSON.stringify(singleLiveCourseDataAtom, null, 3)}</pre> */}
      <div className="col-6 pe-4">
        {day?.slice(0, 4)?.map((classTime, indx) => {
          return (
            <div key={classTime.id}>
              <div className=" mt-4 row d-flex align-items-center">
                <div className="col-lg-3 text-center">
                  <input
                    className="form-check-input opacity-100"
                    type="checkbox"
                    defaultChecked={classTime.isAvailable}
                    disabled={true}
                  />
                  <label className="w-100 opacity-100 form-check-label fz-13px fw-600 ">
                    {classTime.day}
                  </label>
                </div>
                <div className="col-lg-4">
                  <input
                    type="time"
                    className="default-inpt cst-clock"
                    defaultValue={classTime.startTime}
                    readOnly={true}
                  />
                </div>
                <div className="col-lg-1">
                  <p className="m-0 p-0 fz-13px fw-600">to</p>
                </div>
                <div className="col-lg-4">
                  <input
                    type="time"
                    className="default-inpt cst-clock"
                    defaultValue={classTime.endTime}
                    readOnly={true}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="col-6 pe-4">
        {day?.slice(4, 7)?.map((classTime, indx) => {
          return (
            <div key={classTime.id}>
              <div className=" mt-4 row d-flex align-items-center">
                <div className="col-lg-3 text-center">
                  <input
                    className="form-check-input opacity-100"
                    type="checkbox"
                    defaultChecked={classTime.isAvailable}
                    disabled={true}
                  />
                  <label className=" w-100 opacity-100 form-check-label fz-13px fw-600">
                    {classTime.day}
                  </label>
                </div>
                <div className="col-lg-4">
                  <input
                    type="time"
                    className="default-inpt  cst-clock"
                    defaultValue={classTime.startTime}
                    readOnly={true}
                  />
                </div>
                <div className="col-lg-1">
                  <p className="m-0 p-0 fz-13px fw-600">to</p>
                </div>
                <div className="col-lg-4">
                  <input
                    type="time"
                    className="default-inpt cst-clock"
                    defaultValue={classTime.endTime}
                    readOnly={true}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  ) : (
    "Loading"
  );

  return (
    <>
      {isBlock ? (
        ""
      ) : (
        <div
          className="d-flex justify-content-end mt-2"
          role="button"
          onClick={() =>
            setIsSingleLiveCourseEditAtom({
              ...isSingleLiveCourseEditAtom,
              edit: true,
            })
          }
        >
          <EditIcon />
          &nbsp;Edit
        </div>
      )}
      <div className="p-3">
        {/* <pre>{JSON.stringify(singleLiveCourseDataAtom, null, 3)}</pre> */}
        <div className="row ">
          <div className="col-lg-12">
            <p className="text-muted mb-1 fz-16px fw-500">Course Duration</p>
            <h6 className="text-dark">
              {singleLiveCourseDataAtom?.courseDuration || "Loading..."}
            </h6>
          </div>
          <div className="py-2"></div>
          <div className="col-lg-6">
            <p className="text-muted mb-1 fz-16px fw-500">From</p>
            {/* {singleLiveCourseDataAtom.fromTime} */}
            <h6 className="text-dark">
              {singleLiveCourseDataAtom.fromTime
                ? moment(singleLiveCourseDataAtom.fromTime).format("DD-MM-YYYY")
                : "Loading..."}
            </h6>
          </div>
          <div className="col-lg-6">
            <p className="text-muted mb-1 fz-16px fw-500">To</p>

            <h6 className="text-dark">
              {singleLiveCourseDataAtom.toTime
                ? moment(singleLiveCourseDataAtom.toTime).format("DD-MM-YYYY")
                : "Loading..."}
            </h6>
          </div>
          <div className="py-2"></div>
          <div className="col-lg-12">
            <p className="text-muted mb-1 fz-16px fw-500">Class Time</p>
          </div>
          {classTimeJsx}
        </div>
      </div>
    </>
  );
}

export default LiveCourseStep3OverviewData;
