import React from "react";

const OtpInput = ({ otp, setOtp }) => {
  return (
    <input
      className="mb-2"
      value={otp}
      onChange={(e) => setOtp(e.target.value)}
      onInput={(e) => {
        if (e.target.value.trim() === "") {
          e.target.value = "";
        }
        if (!e.target.value.match(/^(\d+(\.\d+)?)$/)) {
          e.target.value = e.target.value.replace(/\D/g, "");
        }
      }}
      type="text"
      maxLength={6}
      minLength={6}
      required
    />
  );
};

export default React.memo(OtpInput);
