import moment from "moment";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import swal from "sweetalert";
import { API_CALL } from "../../../api/ApiServiceCall";
import { API_CONSTANTS, API_METHOD } from "../../../common/constants";
import { overviewCustomTabAtomState } from "../../atom/overviewCustomTab";

function DemoClassTab() {
  const [activeComponentListAtom, setActiveComponentListAtom] = useRecoilState(
    overviewCustomTabAtomState
  );
  const [scheduledDemoClassData, setScheduledDemoClassData] = useState(null);
  useEffect(() => {
    getScheduledDemoClasses();
  }, []);
  // courseCode=${clickedDemoClassData?.courseCode}&demoId=${clickedDemoClassData._id}
  const getScheduledDemoClasses = async () => {
    const clickedDemoClassData = JSON.parse(
      localStorage.getItem("DemoClassData")
    );
    const request = {
      apiUrl:
        API_CONSTANTS.BASE_URL +
        API_CONSTANTS.GET_DEMO_CLASS_LIST_BY_COURSE_CODE +
        `?courseCode=${clickedDemoClassData?.courseCode}`,
      method: API_METHOD.GET,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      setScheduledDemoClassData(result.data);
    } else if (result.status > 200) {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }
  };
  // useEffect(() => {
  //     getScheduledDemoClasses()
  // }, [])

  // const getScheduledDemoClasses = async () => {
  //     const clickedDemoClassData = JSON.parse(localStorage.getItem("DemoClassData"));
  //     const request = {
  //         apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.GET_COMPLETED_DEMO_CLASS_LIST_BY_COURSE_CODE + `?courseCode=${clickedDemoClassData?.courseCode}`,
  //         method: API_METHOD.GET,
  //     }
  //     let result = await API_CALL(request)
  //     if (result.status === 200) {
  //         setScheduledDemoClassData(result.data)
  //     } else if (result.status > 200) {
  //         swal({
  //             title: "ERROR",
  //             text: result.message,
  //             icon: 'https://cdn-icons-png.flaticon.com/512/2569/2569174.png',
  //             className: "error-window",
  //             button: true,
  //         })
  //     }
  // }
  const dateTimeEntryCheck = (clickedDemoClassData) => {
    let apiDate = moment(clickedDemoClassData.demoDate).format("YYYY-MM-DD");
    let apiTime = clickedDemoClassData.demoTime;
    let splittedTime = apiTime.split(":");
    let updatedDateTime = moment(apiDate).set({
      hour: splittedTime[0],
      minute: splittedTime[1],
    });
    let frmtToAddGrace = moment(updatedDateTime);
    let withOneHrGrace = moment(frmtToAddGrace).add("1", "hours");
    // console.log("Date Time:without grace ", moment(updatedDateTime).format());
    // console.log("Date Time:grace ", moment(withOneHrGrace).format());

    if (moment().isSameOrBefore(moment(withOneHrGrace))) {
      return false;
    } else {
      return true;
    }
  };
  return (
    <div>
      {/* <pre>{JSON.stringify(scheduledDemoClassData, null, 4)}</pre> */}
      <div className="table-responsive-sm ">
        <table className="table table-borderless ">
          <thead>
            <tr>
              <th className="text-muted fw-500 fz-15px w-25">Date</th>
              <th className="text-muted fw-500 fz-15px w-25">Time</th>
              <th className="text-muted fw-500 fz-15px w-25">Topic name</th>
              <th className="text-muted fw-500 fz-15px w-25 text-center">
                No Of Booked
              </th>

              <th className="text-muted fw-500 fz-15px w-25">Status</th>
            </tr>
          </thead>
          <tbody>
            {!scheduledDemoClassData ? (
              <tr className="text-center">
                <td colSpan={5}>Loading...</td>
              </tr>
            ) : scheduledDemoClassData.length ? (
              scheduledDemoClassData.map((dcData, index, array) => (
                <tr key={dcData._id}>
                  <td className="pe-2">
                    {moment(dcData?.demoDate).format("DD MMM,YYYY")}
                  </td>
                  <td className="pe-2">
                    {moment(dcData?.demoTime, "HH:mm").format("h:mm a")}
                  </td>
                  <td className="pe-3">{dcData?.topicName}</td>
                  <td className=" text-center">
                    {dcData?.demoClassRequestCount}
                  </td>
                  {dateTimeEntryCheck(dcData) ? (
                    <td
                      className={
                        dcData.status == "COMPLETED"
                          ? dcData.status
                          : "text-danger"
                      }
                    >
                      {dcData.status == "COMPLETED" ? dcData.status : "Expired"}
                    </td>
                  ) : (
                    <td className={dcData.status}>{dcData.status}</td>
                  )}
                </tr>
              ))
            ) : (
              "No data available"
            )}
          </tbody>
        </table>
        {/* <pre>{JSON.stringify(scheduledDemoClassData, null, 4)}</pre> */}
      </div>
    </div>
  );
}

export default DemoClassTab;
