// "https://api-ql.devrejola.com/"
// BASE_URL: "http://api.ql.rejola.com/",
// console.log("-------",userCode.data);
// change
import { io } from "socket.io-client";
import TickImage from "../images/icons/success-icon.png"
const { REACT_APP_BASE_URL, REACT_APP_SOCKETLINK } = process.env;

export const API_CONSTANTS = {
    //INSTRUCTOR API START=====================================
    BASE_URL: REACT_APP_BASE_URL,
    SOCKET_TICKET_URL: REACT_APP_SOCKETLINK + "/ticket",
    FCM: "https://lb-dev.qlearning.academy/notification/register/fcm",
    REGISTER_END_POINT: "register",
    LOGIN_END_POINT: "login",
    GMAIL_REGISTER: 'gmailRegister',
    RESEND_OTP: "resendOTP",
    RESET_INSTRUCTOR_PASSWORD: 'resetInstructorPassword',
    FORGOT_PASSWORD_OTP: 'forgotverificationemail',
    VERIFY_PASSWORD_OTP: 'forgotPasswordVerifyCode',
    VERIFY_OTP: 'registerVerifyCode',
    RESET_PASSWORD: 'resetpassword',
    CREATE_COURSE: 'createCourse',
    GETALLCOURSE: 'getAllCourse',
    GET_COURSE_OVERVIEW: `getCourseOverview`,
    CREATE_FAQ: 'createFaq',
    UPDATE_THUMBNAIL_INTRO_VIDEO: 'updateThumbnailIntroVideo',
    DELETE_FAQ: 'deletefaq',
    GETALLFAQ: 'getAllFaq',
    GET_ALL_CATEGORY: 'getAllCategory',
    GET_SUB_CATEGORY_BY_CATEGORYCODE: 'getSubCategorybyCategoryCode',
    CREATE_CHAPTER_NAME: 'createChapter',
    GET_ALL_CHAPTER: 'getallchapter',
    DELETE_CHAPTER_NAME: 'deleteChapter',
    UPDATE_COURSE_DETAILS: 'updateCourseDetails',
    UPDATE_COURSE_PRICING: 'updateCoursePricing',
    CREATE_LESSON: 'createLesson',
    GET_LESSONS_BY_COURSE: 'getLessonbyCourse',
    GET_LESSON_BY_LESSON_ORDER: 'getLessonbyLessonOrder',
    GET_COURSE_BY_INSTRUCTOR: 'getCoursebyInstructor',
    GET_CHAPTER_BY_CHAPTER_ORDER: 'getChapterByChapterOrder',
    CREATE_ASSESMENT: 'createAssesment',
    DELETE_INSTRUCTOR: 'deleteInstructor',
    DELETE_INSTRUCTOR_VERIFY_CODE: 'deleteInstructorVerifyCode',
    CREATE_INSTRUCTOR: 'createInstructor',
    GET_INSTRUCTOR_BY_EMAIL: 'getInstructorByEmail',
    DELETE_COURSE: 'deleteCourse',
    EACH_COURSE_LEARNERS_LIST: 'eachCourseLearnersList',
    CHANGE_INSTRUCTOR_EMAIL: 'changeInstructorEmail',
    CHANGE_INSTRUCTOR_EMAIL_VERIFY_CODE: 'changeInstructorEmailVerifyCode',
    USER_ACCOUNT_ACTIVITY: 'userAccountActivity',
    DELETE_ASSESMENT: 'deleteAssesment',
    DELETE_LESSON: 'deleteLesson',
    DELETE_ASSESSMENT_BY_ID: 'deleteAssesmentById',
    UPLOAD_PROFILE_IMAGE: 'uploadProfileImage',
    CREATE_SCHEDULE_LIVE_CLASS: 'createScheduleLiveClass',
    GET_ALL_LIVE_CLASS_BY_COURSE_CODE: 'getAllLiveClassByCourseCode',
    START_LIVE_CLASS: 'startLiveClass',
    END_LIVE_CLASS: 'endLiveClass',
    DELETE_LIVE_CLASS: 'deleteLiveClass',
    DELETE_RESOURCE: 'deleteresource',
    CREATE_LIVE_COURSE: 'createLiveCourse',
    GET_LIVE_COURSE_BY_INSTRUCTOR: 'getLiveCoursebyInstructor',
    CREATE_LIVE_COURSE_FAQ: 'createLiveCourseFaq',
    DELETE_LIVE_COURSE_FAQ: 'deleteLiveCoursefaq',
    GET_LIVE_COURSE_BY_COURSE_CODE: 'getLiveCoursebyCourseCode',
    CREATE_LIVE_COURSE_ASSESSMENT: 'createLiveCourseAssessment',
    GET_ASSESSMENT_BY_LIVE_COURSE_CODE: 'getAssessmentByLiveCourseCode',
    UPDATE_LIVE_COURSE_DETAILS: 'updateLiveCourseDetails',
    UPDATE_LIVE_COURSE_VIDEO: 'updateLiveCourseVideo',
    UPDATE_LIVE_COURSE_DURATION: 'updateLiveCourseDuration',
    DELETE_LIVE_COURSE_ASSESSMENT_BY_ASSESSMENT_CODE: 'deleteLiveCourseAssesmentByAssessmentCode',
    CREATE_LIVE_COURSE_ASSESSMENT_DETAILS: 'createLiveCourseAssessmentDetails',
    CREATE_PAYMENT_INFO: 'createupdatePaymentInfo',
    GET_PAYMENT_INFO_BY_EMAIL: 'getPaymentInfoByEmail',
    DELETE_PAYMENT_INFO: 'deletePaymentInfo',
    UPDATE_PAYMENT_INFO: 'updatePaymentInfo',
    SUBMIT_FOR_REVIEW_RECORDED: 'submitForReview',
    SUBMIT_FOR_REVIEW_LIVE_COURSE: 'submitLiveCourseForReview',
    RAISE_COURSE_TICKET: 'v1/ticket/raiseCourseTicket',
    GET_OPEN_TICKET_BY_COURSE_CODE: 'v1/ticket/getOpenTicketByCourseCode',
    GET_ALL_TICKET_BY_LIVE_COURSE_CODE: 'v1/ticket/getAllTicketByLiveCourseCode',
    RAISE_LIVE_COURSE_TICKET: 'v1/ticket/raiseLiveCourseTicket',
    UPDATE_LIVE_COURSE_ASSESSMENT_TITLE: 'updaeteLiveCourseAssessmentTitle',
    CREATE_UPDATE_ASSESSMENT_DETAILS: 'createUpdateAssessmentDetails',
    GET_ASSESSMENT_BY_EMAIL: 'getAssessmentbyEmail',
    GET_ASSESSMENT_BY_ASSESSMENT_CODE: `getAssessmentbyAssessmentCode`,
    CREATE_UPDATE_ASSESSMENT: 'createUpdateAssessment',
    DELETE_INDEP_ASSESMENT_BY_ASSESSMENT_CODE: 'deleteIndepAssesmentByAssessmentCode',
    GET_LIVE_COURSE_ASSESSMENT_BY_ASSESSMENT_CODE: 'getLiveCourseAssessmentByAssessmentCode',
    DELETE_LIVE_COURSE: 'deleteLiveCourse',
    INSTRUCTOR_DASHBOARD: 'instructorDashboard',
    RAISE_WITHDRAW_TICKET: 'v1/ticket/raiseWithdrawTicket',
    GET_WITHDRAW_TICKET_BY_EMAIL: 'v1/ticket/getWithdrawTicketByEmail',
    GET_GOTO_LIVE_COURSE: "getGotoLiveCourse",
    START_LIVE_COURSE_CLASS: "startLiveCourseClass",
    GET_SCHEDULED_LIVE_COURSE_CLASS: "getScheduledLiveCourseClass",
    CREATE_LIVE_COURSE_CLASS: "CreateLiveCourseClass",
    DELETE_LIVE_COURSE_ASSESSMENT_BY_ASSESSMENT_ORDER: "deleteLiveCourseAssesmentByAssessmentOrder",
    DELETE_LIVE_COURSE_ASSESSMENT_BY_ASSESSMENT_ID: "deleteLiveCourseAssesmentByAssessmentId",
    END_LIVE_COURSE_CLASS: "endLiveCourseClass",
    GET_COMPLETED_LIVE_COURSE_CLASS: "getCompletedLiveCourseClass",
    GOTO_LIVE_COURSE: "GotoLiveCourse",
    DELETE_IND_ASSESSMENT_BY_ID: 'deleteIndAssesmentById',
    GET_NOTIFICATIONS: "v1/notifications/getNotifications",
    GET_NOTIFICATION_MAIN_DATA: 'v1/notifications/getNotificationMainData',
    UPDATE_NOTIFICATION_MAIN_DATA: 'v1/notifications/updateNotificationMainData',
    DEACTIVATE_INSTRUCTOR_ACCOUNT: "deactivateAccount",
    CHANGE_MOBILE_NUM: "changeMobileNumber",
    GET_ACCOUNT_DETAILS_BY_MOBILE_NUM: "getAccountDetailsbyMobileNum",
    SUBMIT_IND_ASSESSMENT_FOR_REVIEW: "submitIndAssessmentForReview",
    CHECK_MOBILE_NUM: 'checkMobileNum',
    NOTIFICATION_MARK_ALL_AS_READ: 'v1/notifications/markAllAsRead',
    LOGIN_WITH_MOBILE_NUM: 'loginWithMobileNum',
    GET_FEES_BY_SUB_CATEGORY_CODE: (param) => `api/v1/admin/getFeesBySubCategoryCode?subCategoryCode=${param}&currency=${"INR"}`,
    JOIN_LIVE: (param) => `joinlive/${param?.liveUUID}/${param?.jwt}`,
    LOGOUT_INSTRUCTOR: "logout",
    WITHDRAW_DASHBOARD_DATA: "v1/withdraw/withdrawDashboardData",
    RAISE_WITH_DRAW_REQUEST: "v1/withdraw/raiseWithdrawRequest",
    GET_WITHDRAW_REQUEST: "v1/withdraw/getWithdrawRequest",
    ENABLE_DEMO: "v1/live/course/enableDemo",
    GET_PURCHASE_DETAILS: "v1/withdraw/getTransactionDetails",
    GET_DEMO_ENABLED_COURSES: "v1/live/course/getDemoEnabledCourses",
    SCHEDULE_DEMO: "v1/live/course/scheduleDemo",
    GET_DEMO_CLASS_LIST_BY_COURSE_CODE: "v1/live/course/getDemoClassListbyCourseCode",
    GET_COMPLETED_DEMO_CLASS_LIST_BY_COURSE_CODE: "v1/live/course/getCompletedDemoClassListbyCourseCode",
    START_LIVE_DEMO: "v1/live/course/startLiveDemo",
    END_LIVE_DEMO: "v1/live/course/endLiveDemo",
    REQUEST_FOR_DISCOUNT: "v1/courseDiscount/requestForDiscount",
    GET_DISCOUNT_REQUEST: "v1/courseDiscount/getDiscountRequest",
    GET_ACTIVE_COURSE_BY_INSTRUCTOR: "v1/courseDiscount/getActiveDicountCoursebyInstructor",
    COUPON_SUBMIT_FOR_APPROVAL: "v1/courseDiscount/submitForApproval",
    ENABLE_DISCOUNT_TO_COURSE: "v1/courseDiscount/enableDiscountToCourse",
    COURSE_REVIEW: "courseReview",
    MY_LEARNERS: "v1/withdraw/myLearners",
    GET_ALL_STUDENT: "api/v1/messaging/getAllMessages",
    //INSTRUCTOR API END=====================================
    // ========STUDENT API'S START============================================================================
    CREATE_STUDENT: "createStudent",
    CHANGE_STUDENT_EMAIL: "changeStudentEmail",
    RESET_STUDENT_PASSWORD: "resetStudentPassword",
    DELETE_STUDENT: "deleteStudent",
    GET_STUDENT_BY_EMAIL: 'getStudentByEmail',
    // GET_STUDENT_BY_EMAIL: (email) => `getStudentByEmail?email=${email}`,
    CHANGE_STUDENT_EMAIL_VERIFY_CODE: 'changeStudentEmailVerifyCode',
    DELETE_STUDENT_VERIFY_CODE: "deleteStudentVerifyCode",
    POPULAR_COURSES: "searchCourse?popularCourse=true",
    // POPULAR_COURSES: "getAllLiveCourse",
    // LIVE_COURSES: "searchCourse?popularCourse=true",
    COURSES_BY_CATEGORY_CODE: (categoryCode) => `searchCourse?givenCategoryName=${categoryCode}`,
    LIVE_COURSES: 'getAllLiveCourse',
    ALL_COURSES: 'searchCourse',
    SUB_CATEGORY_BY_CATEGORY_CODE: (categoryCode) => `getSubCategorybyCategoryCode?categoryCode=${categoryCode}`,
    CART: 'api/v1/cart',
    ADD_TO_CART: 'api/v1/cart',
    INSTRUCTOR_PROFILE: (instructorId) => `getInstructorById?_id=${instructorId}`,
    SEARCH_COURSES: (searchString) => `suggestCourseByName?givenCourseName=${searchString}`,
    COURSES_BY_INSTRUCTOR: (instructorId) => `getCourseByInstructorId?instructorId=${instructorId}`,
    DELETE_CART_BY_COURSE_ID: (courseId) => `api/v1/cart/${courseId}`,
    COURSE_BY_COURSE_CODE: (courseCode) => `getCourseByCourseCode?courseCode=${courseCode}&liveURL=https://test.com`,
    FAQ_BY_COURSE_CODE: (courseCode) => `getAllFaq?courseCode=${courseCode}`,
    COURSE_RATING_BY_COURSE_CODE_TEST: (courseCode) => `api/v1/course/${courseCode}/rating`,
    WISH_LIST: "api/v1/wishList",
    DELETE_WISHLIST_BY_COURSE_CODE: (courseCode) => `api/v1/wishList/${courseCode}`,
    RESOURCES_BY_COURSE_CODE: (courseCode, chapterOrder, lessonOrder) => `getResources?courseCode=${courseCode}&chapterOrder=${chapterOrder}&lessonOrder=${lessonOrder}`,
    PAYMENT_CHECKOUT: 'api/v1/payment/checkout',
    // api/v1/payment/payVerification
    PAYMENT_VERIFY: 'api/v1/payment/verification',
    // PAYMENT_VERIFY: 'api/v1/payment/payVerification',
    CREATE_BILLING_ADDRESS: 'api/v1/myAccount/createBillingAddress',
    LESSON_COMPLETED: (courseCode) => `api/v1/course/${courseCode}/progress`,
    ASSESSMENT_SUBMIT: (courseCode) => `api/v1/course/${courseCode}/progress`,
    DELETE_ASSESSMENT: (courseCode) => `api/v1/course/${courseCode}/delete-progress`,
    PURCHASE_HISTORY: "api/v1/purchaseHistory",
    PAYMENT_HISTORY: "api/v1/order/paymentHistory",
    PURCHASED_COURSE: "api/v1/purchaseHistory",
    COURSES_IN_PROGRESS: "api/v1/purchaseHistory?isCompleted=false",
    COMPLEATED_COURSES: "api/v1/purchaseHistory?isCompleted=true",
    DEACTIVATE_ACCOUNT: "deactivateAccount",
    ALSO_BUY_COURSES: "api/v1/cart/alsoBuy",
    FEATURED_COURSES: 'searchCourse?newCourse=true',
    ASSESSMENTS: "getAllActivatedAssessment",
    INDEPENDANT_ASSESSMENT: "searchIndependentAssessment?givenSubCategoryName=SC1005",
    CHECKOUT_NOW: "api/v1/payment/checkout-now",
    REMOVE_CART_ITEMS: "api/v1/payment/removeCartItems",
    MARK_ALL_NOTIFICATIONS_AS_READ: "v1/notifications/markAllAsRead",
    ORDER_SUMMARY: "api/v1/payment/checkout-now",
    SINGLE_COURSE_ORDER_SUMMARY: (courseCode) => `api/v1/payment/checkout-now/${courseCode}`,
    LOGOUT_STUDENT: "logout",
    TOP_COURSES: 'searchCourse?topCourse=true',
    SEARCH_INDEPENDENT_ASSESSMENTS: (searchQuery) => `searchIndependentAssessment?givenAssessmentTitle=${searchQuery}`,
    REMOVE_COURSE_FROM_CART: (code) => `api/v1/cart/clearCartByCourseCode?courseCode=${code}`,
    REMOVE_ASSESSMENT_FROM_CART: (code) => `api/v1/cart/clearCartByCourseCode?assessmentCode=${code}`,
    PURCHASED_ASSESSMENTS: "api/v1/purchaseHistory?isAssessment=true",
    COMPLEATED_ASSESSMENTS: "api/v1/purchaseHistory?isAssessmentCompleted=true",
    RECOMMENDED_FOR_YOU: "api/v1/recommendedForYou",
    ASSESSMENT_BY_ASSESSMENT_CODE: (assessmentCode) => `getAssessmentbyAssessmentCode?assessmentCode=${assessmentCode}`,
    RETRY_ASSESSMENT: (assessmentCode) => `api/v1/assessment/${assessmentCode}/delete-progress`,
    ASSESSMENT_SUBMIT_ANSWERS: (assessmentCode) => `api/v1/assessment/${assessmentCode}/progress`,
    JOIN_LIVE_ALERT: "joinLiveAlert",
    REQUEST_FOR_DEMO_CLASS: 'v1/live/course/demoRequest',
    // GET_DEMO_CLASS: (courseCode) => `/v1/live/course/getDemoClassListbyCourseCode?courseCode=${courseCode}&liveUrl=https://demoTest.com`,
    GET_DEMO_CLASS: (courseCode) => `v1/live/course/getDemoReqbyCourseCode?courseCode=${courseCode}&liveUrl=https://demoTest.com`,
    DISCOUNT_CODES: "v1/courseDiscount/getDiscountCodesForCartItems",
    COURSE_DISCOUNT_CODE: "v1/courseDiscount/getDiscountCodeForCourse",
    APPLY_PROMO_CODE: "v1/courseDiscount/applyPromoCode",
    PURCHASE_COURSE_CHECK: "api/v1/isStudentPurchasedCourseCheck",
    GET_ALL_INSTRUCTORS_FOR_CHAT: (userId) => `api/v1/messaging/getAllMessages?userType=STUDENT&userId=${userId}`
    // userId=63be4fb2a3496cec3e0c2df3
}
export const PICTURE_STORE = {
    TickImage: TickImage
}
export const COMPONENT_IDENTIFIER = {
    COURSE: "course",
    COURSE_OVERVIEW: "course_overview",
    HOME: "home",
    PROFILE: "profile",
    DASHBOARD: "dashboard",
    STUDENT_PROFILE: "student_Profile",
    STUDENT_HOME: "student_home"
}
export const STORAGE_KEY = {
    AUTH: "auth",
    LOCAL_USER_DATA: "localUserData"
}
export const API_METHOD = {
    GET: "GET",
    POST: "POST",
    DELETE: "DELETE",
    PUT: "PUT",
    PATCH: "PATCH"
}
// -----Socket-----
export const socket = io(REACT_APP_SOCKETLINK + "/ticket", {
    autoConnect: false
});
export const socketWebrtc = io(REACT_APP_SOCKETLINK, {
    autoConnect: false
});
export const messagingSocket = io(REACT_APP_SOCKETLINK + "/messaging", {
    autoConnect: false
});


// -----Socket end-----
export let BANK_NAMES = {
    ALL_BANK_NAMES: [
        { value: "Bank of Baroda", label: "Bank of Baroda" },
        { value: "Bank of India", label: "Bank of India" },
        { value: "Bank of Maharashtra", label: "Bank of Maharashtra" },
        { value: "Canara Bank", label: "Canara Bank" },
        { value: "Central Bank of India", label: "Central Bank of India" },
        { value: "Indian Bank", label: "Indian Bank" },
        { value: "Indian Overseas Bank", label: "Indian Overseas Bank" },
        { value: "Punjab & Sind Bank", label: "Punjab & Sind Bank" },
        { value: "Punjab National Bank", label: "Punjab National Bank" },
        { value: "State Bank of India", label: "State Bank of India" },
        { value: "UCO Bank", label: "UCO Bank" },
        { value: "Union Bank of India", label: "Union Bank of India" },
        { value: "Axis Bank Ltd", label: "Axis Bank Ltd" },
        { value: "Bandhan Bank Ltd", label: "Bandhan Bank Ltd" },
        { value: "CSB Bank Ltd", label: "CSB Bank Ltd" },
        { value: "City Union Bank Ltd", label: "City Union Bank Ltd" },
        { value: "DCB Bank Ltd", label: "DCB Bank Ltd" },
        { value: "Dhanlaxmi Bank Ltd", label: "Dhanlaxmi Bank Ltd" },
        { value: "Federal Bank Ltd", label: "Federal Bank Ltd" },
        { value: "HDFC Bank Ltd", label: "HDFC Bank Ltd" },
        { value: "ICICI Bank Ltd", label: "ICICI Bank Ltd" },
        { value: "Induslnd Bank Ltd", label: "Induslnd Bank Ltd" },
        { value: "IDFC First Bank Ltd", label: "IDFC First Bank Ltd" },
        { value: "Jammu & Kashmir Bank Ltd", label: "Jammu & Kashmir Bank Ltd" },
        { value: "Karnataka Bank Ltd", label: "Karnataka Bank Ltd" },
        { value: "Karur Vysya Bank Ltd", label: "Karur Vysya Bank Ltd" },
        { value: "Kotak Mahindra Bank Ltd", label: "Kotak Mahindra Bank Ltd" },
        { value: "Nainital Bank Ltd", label: "Nainital Bank Ltd" },
        { value: "RBL Bank Ltd", label: "RBL Bank Ltd" },
        { value: "South Indian Bank Ltd", label: "South Indian Bank Ltd" },
        { value: "Tamilnad Mercantile Bank Ltd", label: "Tamilnad Mercantile Bank Ltd" },
        { value: "YES Bank Ltd", label: "YES Bank Ltd" },
        { value: "IDBI Bank Ltd", label: "IDBI Bank Ltd" },
        { value: "AB Bank Ltd", label: "AB Bank Ltd" },
        { value: "Abu Dhabi Commercial Bank Ltd", label: "Abu Dhabi Commercial Bank Ltd" },
        { value: "American Express Banking Corporation", label: "American Express Banking Corporation" },
        { value: "Australia and New Zealand Banking Group Ltd", label: "Australia and New Zealand Banking Group Ltd" },
        { value: "Barclays Bank Plc", label: "Barclays Bank Plc" },
        { value: "Bank of America", label: "Bank of America" },
        { value: "Bank of Bahrain & Kuwait BSC", label: "Bank of Bahrain & Kuwait BSC" },
        { value: "Bank of Ceylon", label: "Bank of Ceylon" },
        { value: "Bank of China", label: "Bank of China" },
        { value: "Bank of Nova Scotia", label: "Bank of Nova Scotia" },
        { value: "BNP Paribas", label: "BNP Paribas" },
        { value: "Citibank N.A", label: "Citibank N.A" },
        { value: "Cooperatieve Rabobank U.A", label: "Cooperatieve Rabobank U.A" },
        { value: "Credit Agricole Corporate & Investment Bank", label: "Credit Agricole Corporate & Investment Bank" },
        { value: "Credit Suisse A.G", label: "Credit Suisse A.G" },
        { value: "CTBC Bank Co., Ltd", label: "CTBC Bank Co., Ltd" },
        { value: "DBS Bank India Limited*", label: "DBS Bank India Limited*" },
        { value: "Deutsche Bank", label: "Deutsche Bank" },
        { value: "Doha Bank Q.P.S.C", label: "Doha Bank Q.P.S.C" },
        { value: "Emirates Bank NBD", label: "Emirates Bank NBD" },
        { value: "First Abu Dhabi Bank PJSC", label: "First Abu Dhabi Bank PJSC" },
        { value: "FirstRand Bank Ltd", label: "FirstRand Bank Ltd" },
        { value: "HSBC Ltd", label: "HSBC Ltd" },
        { value: "Industrial & Commercial Bank of China Ltd", label: "Industrial & Commercial Bank of China Ltd" },
        { value: "Industrial Bank of Korea", label: "Industrial Bank of Korea" },
        { value: "J.P. Morgan Chase Bank N.A", label: "J.P. Morgan Chase Bank N.A" },
        { value: "JSC VTB Bank", label: "JSC VTB Bank" },
        { value: "KEB Hana Bank", label: "KEB Hana Bank" },
        { value: "Kookmin Bank", label: "Kookmin Bank" },
        { value: "Krung Thai Bank Public Co. Ltd", label: "Krung Thai Bank Public Co. Ltd" },
        { value: "Mashreq Bank PSC", label: "Mashreq Bank PSC" },
        { value: "Mizuho  Bank Ltd", label: "Mizuho  Bank Ltd" },
        { value: "MUFG Bank, Ltd", label: "MUFG Bank, Ltd" },
        { value: "NatWest Markets Plc", label: "NatWest Markets Plc" },
        { value: "PT Bank Maybank Indonesia TBK", label: "PT Bank Maybank Indonesia TBK" },
        { value: "Qatar National Bank (Q.P.S.C.)", label: "Qatar National Bank (Q.P.S.C.)" },
        { value: "Sberbank", label: "Sberbank" },
        { value: "SBM Bank (India) Limited*", label: "SBM Bank (India) Limited*" },
        { value: "Shinhan Bank", label: "Shinhan Bank" },
        { value: "Societe Generale", label: "Societe Generale" },
        { value: "Sonali Bank Ltd", label: "Sonali Bank Ltd" },
        { value: "Standard Chartered Bank", label: "Standard Chartered Bank" },
        { value: "Sumitomo Mitsui Banking Corporation", label: "Sumitomo Mitsui Banking Corporation" },
        { value: "United Overseas Bank Ltd", label: "United Overseas Bank Ltd" },
        { value: "Woori Bank", label: "Woori Bank" },
    ]
}
