import React from "react";

function LessonIcon() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="17.5" cy="17.5" r="17.5" fill="#F0E1EB" />
      <path
        d="M25.1001 16.6339C25.7668 17.0188 25.7668 17.981 25.1001 18.3659L14.4501 24.5147C13.7834 24.8996 12.9501 24.4185 12.9501 23.6487L12.9501 11.3511C12.9501 10.5813 13.7834 10.1002 14.4501 10.4851L25.1001 16.6339Z"
        fill="#395061"
      />
    </svg>
  );
}

export default LessonIcon;
