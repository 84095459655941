import {
  DyteChatToggle,
  DyteGrid,
  DyteLeaveButton,
  DyteMeetingTitle,
  DyteParticipantCount,
  // DyteHeader,
  DyteParticipantsAudio,
  DyteSidebar,
  DyteSpinner,
  defaultConfig,
} from "@dytesdk/react-ui-kit";
import { useDyteMeeting, useDyteSelector } from "@dytesdk/react-web-core";
import { useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import "./index.css";

const config = { ...defaultConfig };

if (config.root) {
  config.root["dyte-participant-tile"] =
    config.root["dyte-participant-tile"].children;
}

function StudentDyteCall({ handleStudentEnd }) {
  const navigate = useNavigate();
  const { meeting } = useDyteMeeting();
  const roomJoined = useDyteSelector((m) => m.self.roomJoined);
  const roomState = useDyteSelector((m) => m.self.roomState);
  const configs = useDyteSelector((m) => m.self.config);
  const getAllDevices = useDyteSelector((m) => m.self.getAllDevices());

  const [states, updateStates] = useReducer(
    (state, payload) => ({
      ...state,
      ...payload,
    }),
    { meeting: "joined", activeSidebar: false }
  );

  useEffect(() => {
    if (window.ReactNativeWebView) {
      // ensure window.ReactNativeWebView is there, otherwise, web app might crash if is not there
      window.ReactNativeWebView.postMessage("LIVE_START");
    }
  }, []);

  useEffect(() => {
    if (roomState === "left" || roomState === "ended") {
      handleStudentEnd();
    }
  }, [roomState]);

  const handleLeaveMeeting = async () => {
    await meeting.leaveRoom();
    // setIsMeetingEnded(true);
  };

  // if(roomState === "ended") {
  //     return <DyteEndedScreen />
  // }

  if (!meeting) {
    return <DyteSpinner />;
  }

  // if (!roomJoined) {
  //   return <DyteSetupScreen meeting={meeting} />;
  // }

  return (
    <div
      className="instructor-dyte-container"
      ref={(el) => {
        el?.addEventListener("dyteStateUpdate", (e) => {
          updateStates(e.detail);
        });
      }}
    >
      <DyteParticipantsAudio meeting={meeting} />
      <div>
        <DyteMeetingTitle meeting={meeting} />
        <DyteParticipantCount meeting={meeting} />
      </div>

      <main className="instructor-dyte-main">
        <DyteGrid meeting={meeting} config={config} />
        {states.activeSidebar && (
          <DyteSidebar meeting={meeting} states={states} />
        )}
      </main>

      <footer className="instructor-dyte-footer">
        <DyteLeaveButton onClick={() => handleLeaveMeeting()} />
        <DyteChatToggle meeting={meeting} />
      </footer>
    </div>
  );
}

export default StudentDyteCall;
