import { useState, useEffect, useReducer } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    DyteProvider,
    useDyteClient,
} from '@dytesdk/react-web-core';
import {
    provideDyteDesignSystem,
    defaultConfig,
} from '@dytesdk/react-ui-kit';
import moment from 'moment';
import swal from 'sweetalert';
import InstructorDyteCall from "../../components/dyteCalls/instructorDyteCall";
import StudentDyteCall from '../../components/dyteCalls/studentDyteCall';
import { API_CALL } from '../../api/ApiServiceCall';
import { API_CONSTANTS, API_METHOD } from '../../common/constants';

function PrivateRoomRoute() {
    const navigate = useNavigate();
    const { roomId } = useParams();
    const authToken = roomId;

    const [meeting, initMeeting] = useDyteClient();
    const [isDyteLoaded, setIsDyteLoaded] = useState(false);
    const userData = JSON.parse(localStorage.getItem('localUserData'));

    useEffect(() => {
        if (!authToken) {
            console.log("==============================================================================");
            return;
        }
        if (authToken) {
            provideDyteDesignSystem(document.body, {
                theme: 'light',
            });

            initMeeting({
                authToken,
                defaults: {
                    audio: false,
                    video: false,
                },
            }).then((meet) => {
                meet.join();
                setIsDyteLoaded(true);
            })
        } else {
            if (userData?.userType == "INSTRUCTOR") {
                navigate("/login");
            } else {
                navigate(`/student-login`);
            }
        }
    }, []);

    // ---onEnd Live code
    const onLiveEnd = async () => {
      const liveCourseData = JSON.parse(localStorage.getItem('liveCourseData'));
  
      const requestBodyParam = JSON.stringify({
        ...liveCourseData,
        classDuration: moment().format(),  
      })
      const request = {
        apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.END_LIVE_COURSE_CLASS,
        method: API_METHOD.POST,
        requestBodyParams: requestBodyParam,
      }
      let result = await API_CALL(request)
      if (result.status === 200) {
        localStorage.removeItem('liveCourseData');      
        navigate("/courses/live");
      } else if (result.status > 200) {
        swal({
            title: "ERROR",
            text: result.message,
            icon: 'https://cdn-icons-png.flaticon.com/512/2569/2569174.png',
            className: "error-window",
            button: true,
        })
      }
    }
    // ---onEnd Live code End

    const onStudentDemoEnd = () => {
        const courseCode = localStorage.getItem('courseCode');
        localStorage.removeItem("courseCode");         
        navigate(`/course/${courseCode}`);
    }

     const onInstructorLeave = async () => {
       if (window.ReactNativeWebView) {
         // ensure window.ReactNativeWebView is there, otherwise, web app might crash if is not there
         window.ReactNativeWebView.postMessage("INSTRUCTOR_LEFT");
       }
     };

    if (userData && isDyteLoaded) {
        return (
          <DyteProvider
            value={meeting}
            fallback={<div>Loading... Please Wait.</div>}
          >
            {userData?.userType == "INSTRUCTOR" ? (
              <InstructorDyteCall
                handleEnd={() => onLiveEnd()}
                handleLeave={() => onInstructorLeave()}
              />
            ) : (
              <StudentDyteCall handleStudentEnd={() => onStudentDemoEnd()} />
            )}
          </DyteProvider>
        );
    }

    return (
        <div className=' align-items-center justify-content-center vh-100 d-flex'>
            <span className="spinner-border spinner-border-lg text-prime" role="status" aria-hidden="true"></span>
            <span className="visually-hidden">Loading...Dyte.</span>
        </div>
    )
};

export default PrivateRoomRoute