import React, { useEffect, useRef, useState } from 'react'
import { Container, Modal } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai"
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import { API_CALL } from '../../../../../api/ApiServiceCall';
import { API_CONSTANTS, API_METHOD, STORAGE_KEY } from '../../../../../common/constants';
import useCookie from '../../../../../common/cookie_hook';
function ChangePassword({ setSelectedModal, setShow }) {

    const { register, handleSubmit, formState: { errors }, getValues, reset } = useForm({ mode: 'onChange' });
    const [EyeVisible, setEyeVisible] = useState({ "current": "password", "newOne": "password", "confirm": "password" });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [cookie, updateCookie] = useCookie(STORAGE_KEY.AUTH)
    const navigate = useNavigate()
    const onSubmit = async (data) => {
        setLoading(true);
        const requestBodyParam = JSON.stringify({
            currentPassword: data.currentPassword,
            newPassword: data.password,
            confirmPassword: data.confirm
        })
        const request = {
            apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.RESET_INSTRUCTOR_PASSWORD,
            method: API_METHOD.POST,
            requestBodyParams: requestBodyParam,
        }
        let result = await API_CALL(request)
        if (result.status === 200) {

            setLoading(false);
            setShow();

            swal("Your Password Changed!", `Login with your new password: ${data.password}`, "success").then(() => {
                localStorage.clear();
                updateCookie(null);
                navigate("/login")
            })

        } else if (result.status > 200) {
            setError(result.message);
            setLoading(false);

        }
    }
    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

    return (
        <div>    <Container>
            <Modal.Header closeButton className='mh'>
                <Modal.Title className='mdlt'>Change Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className='login-col' onSubmit={handleSubmit(onSubmit)}>
                    <label htmlFor='newpass'>Current Password</label>
                    <div className='position-relative'>
                        <input
                            maxLength={16}
                            className='mb-2'
                            placeholder="Password "
                            type={EyeVisible.current}
                            {...register("currentPassword", {
                                required: {
                                    value: true,
                                    message: "Please fill this field",
                                }, minLength: { value: 8, message: 'Too short' },
                                pattern: {
                                    value: strongRegex,
                                    message: "Must contain at least one special character, one number and one uppercase and lowercase letter, and at least 8 or more characters and maximum 16 Characters"
                                }
                            })}
                        />

                        <small className='text-danger mb-2 d-block fz-12px fw-500'> {errors.currentPassword && <>{errors.currentPassword.message}</>}</small>
                        <div className='passfield'>{EyeVisible.current === "password" ? <span onClick={() => setEyeVisible({ ...EyeVisible, current: "text" })}><AiOutlineEyeInvisible /></span> : <span onClick={() => setEyeVisible({ ...EyeVisible, current: "password" })}><AiOutlineEye /></span>}</div>
                    </div>
                    <label htmlFor='newpass'>New Password</label>
                    <div className='position-relative'>  <input
                        maxLength={16}
                        className='mb-2'
                        placeholder="Password "
                        type={EyeVisible.newOne}
                        id='newpass'
                        {...register("password", { required: true, minLength: { value: 8, message: 'Too short' }, pattern: strongRegex })}
                    />
                        <div className='passfield'>{EyeVisible.newOne === "password" ? <span onClick={() => setEyeVisible({ ...EyeVisible, newOne: "text" })}><AiOutlineEyeInvisible /></span> : <span onClick={() => setEyeVisible({ ...EyeVisible, newOne: "password" })}><AiOutlineEye /></span>}</div>
                    </div>


                    <small className='text-danger mb-2 d-block fz-12px fw-500'> {errors.password?.type === 'required' && "Please fill this field"}</small>
                    <small className='text-danger mb-2 d-block fz-12px fw-500'> {errors.password?.type === 'pattern' && "Must contain at least one special character, one number and one uppercase and lowercase letter, and at least 8 or more characters and maximum 16 Characters"}</small>
                    <small className='text-danger mb-2 d-block fz-12px fw-500'> {errors.password && <>{errors.password.message}</>}</small>
                    <label htmlFor='Confirm'>Confirm New Password</label>
                    <div className='position-relative'> <input
                        maxLength={16}
                        placeholder="Confirm Password"
                        type={EyeVisible.confirm}
                        className='mb-2'
                        id='Confirm'
                        {...register("confirm", {
                            required: {
                                value: true,
                                message: "Please fill this field",
                            }, minLength: { value: 8, message: 'Too short' },
                            pattern: {
                                value: strongRegex,
                                message: "Must contain at least one special character, one number and one uppercase and lowercase letter, and at least 8 or more characters and maximum 16 Characters"
                            }, validate: value => {
                                if (value === getValues('password')) { return true } else { return <span>Password fields doesn't match</span> }
                            }
                        })}
                    />
                        <div className='passfield'>{EyeVisible.confirm === "password" ? <span onClick={() => setEyeVisible({ ...EyeVisible, confirm: 'text' })}><AiOutlineEyeInvisible /></span> : <span onClick={() => setEyeVisible({ ...EyeVisible, confirm: 'password' })}><AiOutlineEye /></span>}</div>
                    </div>
                    <small className='text-danger mb-2 d-block fz-12px fw-500'> {errors.confirm && <p>{errors.confirm.message}</p>}</small>
                    <button className='login-form-btn' disabled={loading ? true : false}>
                        {loading ? "Please Wait..." : "Change Password"}
                    </button>
                    <small className='text-danger mb-2 d-block fz-15px fw-500 text-center'> {error}</small>

                </form>
            </Modal.Body>
        </Container></div>
    )
}

export default ChangePassword

