import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AiFillFile, AiOutlineCloseCircle } from 'react-icons/ai';
import { FaEnvelope } from 'react-icons/fa';
import { IoMdSend } from 'react-icons/io';
import { MdOutlineClose } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { socket } from '../../../../common/constants';
import '../../../../css/chat.css';
import AttachIcon from '../../../svg-icons/attachIcon';
import swal from "sweetalert";
import ErroImg from '../../../../images/icons/error.png';

// let socket = io("wss://api.dev.qlearning.academy/ticket");
const attachStyle = {
    position: "absolute",
    left: "26px",
    top: "12px",
    zIndex: 2,
    bottom: "0",
    right: "auto"
}
const pxMed = {
    padding: "13px 35px"
}
const filePreview = { "position": "absolute", "zIndex": "9", "width": "100%", "textAlign": "center", "bottom": "-7px", "background": "rgb(248 248 248)", "height": "120px" }
const closeFile = {
    "position": "absolute",
    left: "5px",
    top: "8px"
}
const aln = { "right": "18px", "position": "absolute", "top": "auto", "bottom": "auto" }

function LiveCourseReviewCard({ courseCode, review, liveCourseData }) {
    const [isOpenChatWindow, setisOpenChatWindow] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

    const [status, setStatus] = useState("")
    const message = useRef("");
    const [msgList, setMsgList] = useState([])
    const [localUserData, setLocalUserData] = useState(JSON.parse(localStorage.getItem("localUserData")));
    const [file, setFile] = useState({
        fileData: "",
        fileName: ""
    })
    const [loading, setLoading] = useState(false)
    console.log(socket.connected);
    useEffect(() => {


        if (isOpenChatWindow) {

            socket.open();

            socket.on("connection-success", async (response) => {
                console.log(`Socket connected ${response.socketId}`);

            });

            socket.emit('join-instructor', { courseCode: liveCourseData?.courseCode, userName: JSON.parse(localStorage.getItem("localUserData")).name }, async res => {
                console.log(`join-instructor ${res}`);
                console.log(res)
            })

            socket.emit("getPreviousMessage", { courseCode: liveCourseData?.courseCode, ticketType: "COURSE" }, (response) => {
                console.log("getPreviousMessage")
                console.log(response)

                setMsgList(chatMessages => ([
                    ...response
                ]));
            });
            // ======To reset msg count=========
            socket.emit("resetMessageCount", { courseCode: liveCourseData?.courseCode, userType: JSON.parse(localStorage.getItem("localUserData")).userType }, (response) => {
                // console.log("Reset Message Count Ran!", response)
            });
            // ======To reset msg end=========
            socket.on("message", receiveMessages);

        } else {
            socket.off("message", receiveMessages);
            socket.close();

        }
        return () => {
            socket.close();
        }
    }, [isOpenChatWindow])


    const receiveMessages = useCallback((response) => {
        console.log("recev live chat-------->");
        console.log(response);
        if (response.type == "FILE") {
            setMsgList(chatMessages => ([
                ...chatMessages,
                {
                    userName: response.userName,
                    time: response.createdAt,
                    message: response.message,
                    type: response.type,
                    fileName: response.fileName,
                }
            ]));
        } else {
            setMsgList(chatMessages => ([
                ...chatMessages,
                {
                    userName: response.userName,
                    time: response.createdAt,
                    message: response.message
                }
            ]));
        }
    }, [])

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
          e.preventDefault();
          sendMsg(e);
        }
    }
    
    // For text msg
    const sendMsg = (e) => {
if (isSubmitting || !message.current.value) return;
setIsSubmitting(true);
        e.preventDefault()
        if (message.current.value) {
            socket.emit("sendMessage", {
                message: message.current.value,
                courseCode: liveCourseData?.courseCode,
                roomName: liveCourseData?.courseCode,
                userName: localUserData.name,
                ticketType: "COURSE",
                courseType: "LIVE",
                userId: localUserData.userId,
                type: "TEXT"
            }, () => {
                console.log("sendMessage callback!")
                setMsgList(chatMessages => ([
                    ...chatMessages,
                    {
                        userName: localUserData.name,
                        dt: moment().format(),
                        message: message.current.value
                    }
                ]));
                setTimeout(() => {
                    message.current.value = '';
                }, 500)
            })
            // finalSend();
setTimeout(() => {
  setIsSubmitting(false);
}, 1000);
        }

    }
    // For file upload

    const sendFiles = (e) => {

        if (e.target.files) {
            let files = e.target.files;
            setFile({
                fileData: files[0],
                fileName: files[0].name
            });
        }
    }
    const onConfrimUpload = () => {
        if (file && file.fileData && file.fileData.size > 1024 * 1024) {
            // File size is greater than 1MB, show a SweetAlert popup
            swal({
                title: "File Size Limit Exceeded",
                text: "Please upload a file smaller than 1MB.",
                icon: ErroImg,
                className: "error-window",
                button: true,
                closeOnClickOutside: false
            })
            return;
}
        setLoading(true)

        socket.emit("upload", {
            file: file?.fileData,
            fileName: file?.fileName,
            courseCode: liveCourseData?.courseCode,
            roomName: liveCourseData?.courseCode,
            userName: localUserData.name,
            userId: localUserData?.userId,
            ticketType: "COURSE",
            courseType: "LIVE",
            type: "FILE"
        }, (res) => {

            setMsgList(chatMessages => ([
                ...chatMessages,
                {
                    userName: res?.userName,
                    dt: moment().format(),
                    type: "FILE",
                    fileName: res.fileName,
                    message: res.message
                }
            ]));
            setLoading(false)
            setFile({
                fileData: "",
                fileName: ""
            });
        });

    }
    const onCancelUpload = () => {
        setFile({
            fileData: "",
            fileName: ""
        });
    }

    const messagesEndRef = useRef(null)

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(() => {
        scrollToBottom()
    }, [msgList]);
    useEffect(() => {
        if( (liveCourseData?.courseStatus == 'BANNED')||(liveCourseData?.courseStatus === 'REJECTED')) {
            setStatus("Rejected")
        }
        if (liveCourseData?.courseStatus == 'INREVIEW') {
            setStatus("In Review")
        }
        if (liveCourseData?.courseStatus == 'ACTIVE') {
            setStatus("Active")
        }

    }, [liveCourseData?.courseStatus])
    const openChatWindow = () => {
        setisOpenChatWindow(!isOpenChatWindow);
    }
    return (
      <div className="col ">
        <div className=" justify-content-end d-flex">
          <Link
            to={"/courses/live-courses-overview"}
            className={`rounded-pill   px-4 text-center py-2 fz-15px border-0 fw-500 text-white ${liveCourseData?.courseStatus}`}
            onClick={review}
          >
            {status}
          </Link>
        </div>
        <div className=" h-75 position-relative justify-content-end d-flex align-items-end">
          {isOpenChatWindow ? (
            <div className="chatWindowRaiseTicket ticket-chat">
              <div className="d-flex justify-content-between px-4 pt-4 align-items-lg-start">
                <h6 className="m-0 p-0">Ticket</h6>{" "}
                <div
                  onClick={() => setisOpenChatWindow(false)}
                  className="adjus-x"
                >
                  <MdOutlineClose />
                </div>
              </div>
              <div className="chatHeight position-relative">
                {msgList?.map((val, index) => (
                  <div key={index} className="py-1 " ref={messagesEndRef}>
                    <p
                      className={`${
                        val?.userName == localUserData.name
                          ? "text-end"
                          : "text-start"
                      } m-0 p-0 fw-600 fz-14px`}
                    >
                      <span className="text-muted fz-10px fw-400 me-1 ">
                        {moment(val?.dt).calendar()}
                      </span>
                      {val?.userName == localUserData.name
                        ? "Me"
                        : val?.userName}
                    </p>
                    {val?.type == "FILE" ? (
                      <div
                        className={` d-block   ${
                          val?.userName?.replace(/\s/g, "") ==
                          localUserData.name?.replace(/\s/g, "")
                            ? "text-end"
                            : "text-start"
                        } `}
                      >
                        <a
                          className={`text-decoration-none text-prime d-inline-flex  fw-500 text-prime  fz-12px bg-pink py-2 px-3 rounded `}
                          href={val?.message}
                          title={val?.message?.split("/").pop()}
                        >
                          {val?.message?.split("/").pop().substr(0, 30)}...
                          <img
                            src="https://cdn-icons-png.flaticon.com/512/2820/2820279.png"
                            className="ms-2  d-block"
                            style={{
                              height: "18px",
                              width: "18px",
                              objectFit: "contain",
                            }}
                          />
                        </a>
                      </div>
                    ) : (
                      <div
                        className={`${
                          val?.userName == localUserData.name
                            ? "text-end"
                            : "text-start"
                        } fw-500 fz-15px`}
                      >
                        {val?.message.split("\n").map((line, i) => (
                          <p key={i} className="m-0 p-0">
                            {line}
                          </p>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
              {file?.fileName ? (
                <div style={filePreview} className="p-3">
                  <AiFillFile size={30} color={"grey"} />
                  <div className="d-flex justify-content-center align-items-center">
                    <p className="fz-13px m-0">{file?.fileName}</p>{" "}
                    <button
                      className="bg-transparent border-0"
                      disabled={loading ? true : false}
                      style={closeFile}
                      onClick={onCancelUpload}
                    >
                      <AiOutlineCloseCircle size={18} />
                    </button>
                    <button
                      className="bg-transparent border-0"
                      style={aln}
                      onClick={onConfrimUpload}
                      role="button"
                      disabled={loading ? true : false}
                    >
                      {loading ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span className="visually-hidden">Loading...</span>
                        </>
                      ) : (
                        <IoMdSend className="ms-2" size={20} />
                      )}
                    </button>
                  </div>
                </div>
              ) : (
                ""
              )}
              <form
                className="w-100 d-flex px-3 pb-2  position-relative"
                onSubmit={sendMsg}
              >
                <input
                  className="d-none"
                  id="attachFile"
                  type="file"
                  accept="image/gif, image/jpeg, image/png"
                  onClick={(event) => {
                    event.target.value = null;
                  }}
                  onChange={(event) => {
                    sendFiles(event);
                  }}
                />
                <label htmlFor="attachFile" role="button">
                  <span style={attachStyle}>
                    <AttachIcon />
                  </span>
                </label>
                <textarea
                  className=" default-inpt rounded-9 pe-4"
                  style={{
                    overflow: "hidden",
                    padding: "13px 40px",
                    maxHeight: "50px",
                    height: "50px",
                    minHeight: "50px",
                    resize: "none",
                  }}
                  placeholder="write a message..."
                  type={"text"}
                  ref={message}
                  disabled={isSubmitting}
                  onKeyDown={handleKeyDown}
                />
                <button
                  className="border-0 align-arrow  fz-18px bg-transparent text-prime"
                  type="submit"
                  disabled={isSubmitting}
                >
                  <IoMdSend />
                </button>
              </form>
            </div>
          ) : (
            ""
          )}
          <button
            onClick={openChatWindow}
            className=" d-flex justify-content-center align-items-center rounded-9 bg-second w-100 py-3 fz-15px border-0 fw-500 text-prime"
          >
            <FaEnvelope className="me-1" /> Raise a Ticket
          </button>
          {liveCourseData?.newMessageCount ? (
            <div className="position-relative">
              <div className="ticket-notification-badge">
                {liveCourseData?.newMessageCount}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
}

export default LiveCourseReviewCard


