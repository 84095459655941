import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import swal from "sweetalert";
import { API_CALL } from "../../../../../../../api/ApiServiceCall";
import {
  API_CONSTANTS,
  API_METHOD,
} from "../../../../../../../common/constants";
import { liveCourseAssessmentViewAtomFunc } from "../../../../../../atom/liveCourseAssessmentViewAtom";
import { liveCourseClickedAssessmentDataAtomFunc } from "../../../../../../atom/liveCourseClickedAssessmentDataAtom";

function EditAssessmentTitle() {
  const [liveCourseAssessmentViewAtom, setLiveCourseAssessmentViewAtom] =
    useRecoilState(liveCourseAssessmentViewAtomFunc);
  const [
    liveCourseClickedAssessmentDataAtom,
    setLiveCourseClickedAssessmentDataAtom,
  ] = useRecoilState(liveCourseClickedAssessmentDataAtomFunc);
  const [assessmentTitle, setAssessmentTitle] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setAssessmentTitle(liveCourseClickedAssessmentDataAtom.assessmentTitle);
  }, []);
  const createAssessmentTitle = async (e) => {
    e.preventDefault();
    const courseCode = localStorage.getItem("courseCode");
    setLoading(true);
    let requestBodyParam = JSON.stringify({
      courseCode: courseCode,
      assessmentTitle: assessmentTitle,
      assessmentCode: liveCourseClickedAssessmentDataAtom.assessmentCode,
    });
    // alert(requestBodyParam)
    const request = {
      apiUrl:
        API_CONSTANTS.BASE_URL +
        API_CONSTANTS.UPDATE_LIVE_COURSE_ASSESSMENT_TITLE,
      method: API_METHOD.POST,
      requestBodyParams: requestBodyParam,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      setLoading(false);
      setLiveCourseAssessmentViewAtom(false);
    } else if (result.status > 200) {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
      setLoading(false);
    }
  };

  return (
    <div>
      {/* {JSON.stringify(liveCourseClickedAssessmentDataAtom)} */}

      <form className="my-3" onSubmit={createAssessmentTitle}>
        <input
          type={"text"}
          className="default-inpt"
          defaultValue={assessmentTitle}
          onChange={(e) => setAssessmentTitle(e.target.value)}
          required
        />
        <div className="d-flex justify-content-end align-items-center">
          <button
            type="button"
            onClick={() => setLiveCourseAssessmentViewAtom(false)}
            className="px-3 border-0 bg-secondary text-white fz-14px py-2 mx-2 rounded-3"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-3 my-3 border-0 bg-prime text-white fz-14px py-2 rounded-3"
            disabled={loading}
          >
            {loading ? "Please Wait..." : "Done"}
          </button>
        </div>
      </form>
    </div>
  );
}

export default EditAssessmentTitle;
