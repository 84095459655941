import React from "react";

function AssesmentIcon() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="17.5" cy="17.5" r="17.5" fill="#F0E1EB" />
      <g clipPath="url(#clip0_619_4312)">
        <path
          d="M13.961 18.6089C14.0219 18.673 14.1076 18.7099 14.196 18.7101H14.1965C14.2848 18.7101 14.3703 18.6735 14.4314 18.6096L14.8494 18.1717C14.9471 18.0693 14.9434 17.907 14.841 17.8092C14.7385 17.7115 14.5763 17.7152 14.4785 17.8176L14.1968 18.1128L14.1487 18.0622C14.0512 17.9595 13.8889 17.9554 13.7863 18.0529C13.6837 18.1504 13.6795 18.3127 13.777 18.4154L13.961 18.6089Z"
          fill="#395061"
        />
        <path
          d="M15.788 17.1768C15.788 16.9412 15.5963 16.7495 15.3607 16.7495H13.2661C13.0305 16.7495 12.8389 16.9412 12.8389 17.1768V19.2713C12.8389 19.5069 13.0305 19.6986 13.2661 19.6986H15.3607C15.5963 19.6986 15.788 19.5069 15.788 19.2713V17.1768ZM15.2753 19.1859H13.3516V17.2622H15.2753V19.1859Z"
          fill="#395061"
        />
        <path
          d="M16.7866 19.6987H20.4247C20.5663 19.6987 20.6811 19.584 20.6811 19.4424C20.6811 19.3008 20.5663 19.186 20.4247 19.186H16.7866C16.645 19.186 16.5303 19.3008 16.5303 19.4424C16.5303 19.584 16.645 19.6987 16.7866 19.6987Z"
          fill="#395061"
        />
        <path
          d="M16.7866 18.6519H20.4247C20.5663 18.6519 20.6811 18.5371 20.6811 18.3955C20.6811 18.2539 20.5663 18.1392 20.4247 18.1392H16.7866C16.645 18.1392 16.5303 18.2539 16.5303 18.3955C16.5303 18.5371 16.645 18.6519 16.7866 18.6519Z"
          fill="#395061"
        />
        <path
          d="M13.9611 15.0089C14.022 15.0731 14.1077 15.1099 14.1962 15.1099H14.1965C14.2848 15.1099 14.3704 15.0733 14.4313 15.0095L14.8494 14.5715C14.9471 14.4691 14.9434 14.3069 14.841 14.2091C14.7386 14.1114 14.5763 14.1151 14.4785 14.2175L14.1968 14.5126L14.1487 14.4621C14.0512 14.3594 13.8889 14.3553 13.7863 14.4528C13.6837 14.5503 13.6795 14.7126 13.777 14.8152L13.9611 15.0089Z"
          fill="#395061"
        />
        <path
          d="M15.788 13.5767C15.788 13.3411 15.5963 13.1494 15.3607 13.1494H13.2661C13.0305 13.1494 12.8389 13.3411 12.8389 13.5767V15.6713C12.8389 15.9069 13.0305 16.0985 13.2661 16.0985H15.3607C15.5963 16.0985 15.788 15.9069 15.788 15.6713V13.5767ZM15.2753 15.5858H13.3516V13.6621H15.2753V15.5858Z"
          fill="#395061"
        />
        <path
          d="M16.7866 16.0986H20.4247C20.5663 16.0986 20.6811 15.9839 20.6811 15.8423C20.6811 15.7007 20.5663 15.5859 20.4247 15.5859H16.7866C16.645 15.5859 16.5303 15.7007 16.5303 15.8423C16.5303 15.9839 16.645 16.0986 16.7866 16.0986Z"
          fill="#395061"
        />
        <path
          d="M16.7866 15.0518H20.4247C20.5663 15.0518 20.6811 14.937 20.6811 14.7954C20.6811 14.6538 20.5663 14.5391 20.4247 14.5391H16.7866C16.645 14.5391 16.5303 14.6538 16.5303 14.7954C16.5303 14.937 16.645 15.0518 16.7866 15.0518Z"
          fill="#395061"
        />
        <path
          d="M13.9611 22.2155C14.022 22.2796 14.1077 22.3164 14.1962 22.3165H14.1965C14.2848 22.3165 14.3704 22.2799 14.4313 22.216L14.8494 21.7781C14.9471 21.6757 14.9434 21.5135 14.841 21.4157C14.7386 21.3179 14.5763 21.3216 14.4785 21.4241L14.1968 21.7192L14.1487 21.6686C14.0512 21.566 13.8889 21.5619 13.7863 21.6594C13.6837 21.7569 13.6795 21.9192 13.777 22.0218L13.9611 22.2155Z"
          fill="#395061"
        />
        <path
          d="M15.3607 20.356H13.2661C13.0305 20.356 12.8389 20.5476 12.8389 20.7832V22.8778C12.8389 23.1134 13.0305 23.305 13.2661 23.305H15.3607C15.5963 23.305 15.788 23.1134 15.788 22.8778V20.7832C15.788 20.5476 15.5963 20.356 15.3607 20.356ZM15.2753 22.7924H13.3516V20.8687H15.2753V22.7924Z"
          fill="#395061"
        />
        <path
          d="M21.1425 22.7925H16.7866C16.645 22.7925 16.5303 22.9072 16.5303 23.0488C16.5303 23.1904 16.645 23.3052 16.7866 23.3052H21.1425C21.2841 23.3052 21.3988 23.1904 21.3988 23.0488C21.3988 22.9072 21.2841 22.7925 21.1425 22.7925Z"
          fill="#395061"
        />
        <path
          d="M16.5303 22.002C16.5303 22.1436 16.645 22.2583 16.7866 22.2583H21.1425C21.2841 22.2583 21.3988 22.1436 21.3988 22.002C21.3988 21.8603 21.2841 21.7456 21.1425 21.7456H16.7866C16.645 21.7456 16.5303 21.8604 16.5303 22.002Z"
          fill="#395061"
        />
        <path
          d="M22.6784 23.0041C22.5369 23.0041 22.4221 23.1189 22.4221 23.2605V24.8782C22.4221 25.0303 22.2983 25.1541 22.1461 25.1541H12.0913C11.9392 25.1541 11.8154 25.0303 11.8154 24.8782V11.1281C11.8154 10.976 11.9392 10.8522 12.0913 10.8522H14.2579V11.4805C14.2579 11.8579 14.5649 12.1649 14.9423 12.1649H19.2952C19.6726 12.1649 19.9796 11.8579 19.9796 11.4805V10.8522H20.6538C20.7954 10.8522 20.9101 10.7374 20.9101 10.5958C20.9101 10.4542 20.7954 10.3395 20.6538 10.3395H19.9796V9.87546C19.9796 9.49808 19.6726 9.19104 19.2952 9.19104H18.4374V8.9522C18.4374 8.51907 18.085 8.16675 17.652 8.16675H16.5856C16.1525 8.16675 15.8001 8.51911 15.8001 8.9522V9.19101H14.9423C14.5649 9.19101 14.2579 9.49805 14.2579 9.87542V10.3394H12.0913C11.6565 10.3394 11.3027 10.6932 11.3027 11.128V24.8781C11.3027 25.3129 11.6565 25.6667 12.0913 25.6667H22.1462C22.581 25.6667 22.9348 25.3129 22.9348 24.8781V23.2605C22.9348 23.1189 22.82 23.0041 22.6784 23.0041V23.0041ZM14.7706 9.87546C14.7706 9.78075 14.8476 9.70374 14.9423 9.70374H15.9257C16.1392 9.70374 16.3128 9.53014 16.3128 9.31672V8.9522C16.3128 8.80181 16.4352 8.67944 16.5856 8.67944H17.652C17.8023 8.67944 17.9247 8.80181 17.9247 8.9522V9.31669C17.9247 9.53011 18.0983 9.70371 18.3118 9.70371H19.2952C19.3899 9.70371 19.4669 9.78075 19.4669 9.87542V11.4805C19.4669 11.5751 19.3899 11.6522 19.2952 11.6522H14.9423C14.8476 11.6522 14.7706 11.5751 14.7706 11.4805V9.87546Z"
          fill="#395061"
        />
        <path
          d="M24.3345 11.8658H23.8415V9.92937C23.8415 9.45916 23.459 9.07666 22.9888 9.07666H22.3781C21.9079 9.07666 21.5254 9.45916 21.5254 9.92937C21.5254 9.92937 21.5256 19.1788 21.5257 19.1806C21.5256 19.1824 21.5254 19.1841 21.5254 19.1859V20.1332C21.5254 20.2974 21.559 20.4568 21.6254 20.607L22.2671 22.0603C22.341 22.2276 22.5005 22.3316 22.6834 22.3316C22.8664 22.3316 23.0259 22.2276 23.0998 22.0603L23.7415 20.6071C23.8078 20.4568 23.8415 20.2974 23.8415 20.1332V19.1859C23.8415 19.1841 23.8412 19.1824 23.8412 19.1806C23.8412 19.1788 23.8415 17.5088 23.8415 17.5088C23.8415 17.3672 23.7267 17.2524 23.5851 17.2524C23.4436 17.2524 23.3288 17.3672 23.3288 17.5088V18.9189H22.0381V11.4925H23.3288V16.4424C23.3288 16.584 23.4436 16.6987 23.5851 16.6987C23.7267 16.6987 23.8415 16.584 23.8415 16.4424V12.3785H24.3345C24.3439 12.3785 24.3516 12.3861 24.3516 12.3955V15.2678C24.3516 15.4094 24.4664 15.5241 24.608 15.5241C24.7495 15.5241 24.8643 15.4094 24.8643 15.2678V12.3955C24.8643 12.1034 24.6267 11.8658 24.3345 11.8658V11.8658ZM23.3288 20.1332C23.3288 20.2257 23.3098 20.3154 23.2725 20.4L22.6835 21.7339L22.0944 20.4C22.0571 20.3154 22.0382 20.2257 22.0382 20.1333V19.4423H23.3288V20.1332H23.3288ZM22.0381 10.9798V9.92937C22.0381 9.7419 22.1906 9.58936 22.3781 9.58936H22.9888C23.1763 9.58936 23.3288 9.7419 23.3288 9.92937V10.9798H22.0381V10.9798Z"
          fill="#395061"
        />
      </g>
      <defs>
        <clipPath id="clip0_619_4312">
          <rect
            width="17.5"
            height="17.5"
            fill="white"
            transform="translate(9.3335 8.16675)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default AssesmentIcon;
