import React, { useEffect } from 'react'
import { useRecoilState } from 'recoil';
import moment from 'moment';
import swal from 'sweetalert';
import { notificationsState } from '../atoms/notificationsAtom';
import { API_CALL } from '../../api/ApiServiceCall';
import { API_CONSTANTS, API_METHOD } from '../../common/constants';
import './../css/notifications.css'


function Notifications() {
    const [notifications, setNotifications] = useRecoilState(notificationsState);

    const markAllNotificationsAsMarked = async () => {
        setNotifications(notificationData => ({
            ...notificationData,
            count: 0,
        }));

        const requestBodyParam = JSON.stringify({
            userID: notifications.result.userId
        });

        const request = {
            apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.MARK_ALL_NOTIFICATIONS_AS_READ,
            method: API_METHOD.POST,
            requestBodyParams: requestBodyParam,
        };

        let result = await API_CALL(request);
        if (result.status === 200) {
            setNotifications(notificationData => ({
                ...notificationData,
                count: 0,
            }));
        } else if (result.status > 200) {
            swal({
                title: "ERROR",
                text: result.message,
                icon: 'https://cdn-icons-png.flaticon.com/512/2569/2569174.png',
                className: "error-window",
                button: true,
            })
        }
    }

    useEffect(() => {
        markAllNotificationsAsMarked();
    }, [])

    console.log("notificationskaveri", notifications)

    return (
        <div className="student-conatiner py-6">
            <h1 className="fs-4 my-4">Notifications</h1>
            <div>
            {notifications?.result?.notificationList?.length > 0 ? notifications?.result?.notificationList.map(notification => (
                    <div key={notifications._id} className="py-3 border-bottom" style={{
                        opacity: notification.isRead ? '0.4' : 1,
                        display: 'flex',
                        alignItems: 'flex-start'
                    }}>
                        <div className='rounded-3' style={{ backgroundColor: '#F0E1EB', marginRight: 15 }}>
                            <span className='d-block p-2 rounded-2'>
                                <svg width="20" height="20" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M23.7138 16.8792L21.0055 7.11779C20.4271 5.02968 19.167 3.19502 17.4256 1.9058C15.6841 0.616574 13.5615 -0.0531225 11.3956 0.00329378C9.22957 0.0597101 7.1447 0.838997 5.47275 2.21713C3.80081 3.59526 2.63787 5.49303 2.16902 7.60841L0.0742324 17.0355C-0.0271293 17.4922 -0.024657 17.9658 0.0814671 18.4215C0.187591 18.8771 0.394659 19.3031 0.687393 19.668C0.980128 20.0329 1.35106 20.3275 1.77282 20.5299C2.19458 20.7323 2.65641 20.8375 3.12423 20.8376H6.88152C7.1206 22.015 7.75937 23.0735 8.68959 23.8338C9.61981 24.5941 10.7843 25.0095 11.9857 25.0095C13.1871 25.0095 14.3516 24.5941 15.2818 23.8338C16.212 23.0735 16.8508 22.015 17.0899 20.8376H20.7044C21.1858 20.8374 21.6606 20.7259 22.0918 20.512C22.523 20.2981 22.899 19.9874 23.1904 19.6043C23.4818 19.2211 23.6807 18.7759 23.7717 18.3032C23.8627 17.8305 23.8432 17.3432 23.7149 16.8792H23.7138ZM11.9857 22.9209C11.3417 22.9182 10.7142 22.7167 10.1891 22.3437C9.66405 21.9708 9.26703 21.4448 9.05236 20.8376H14.919C14.7044 21.4448 14.3073 21.9708 13.7822 22.3437C13.2572 22.7167 12.6297 22.9182 11.9857 22.9209ZM21.5326 18.3428C21.4354 18.4716 21.3094 18.5759 21.1647 18.6474C21.02 18.7188 20.8606 18.7554 20.6992 18.7542H3.12423C2.96825 18.7542 2.81427 18.7192 2.67366 18.6517C2.53304 18.5842 2.40937 18.486 2.31179 18.3643C2.2142 18.2426 2.14519 18.1006 2.10984 17.9486C2.0745 17.7967 2.07372 17.6388 2.10757 17.4865L4.20236 8.05945C4.57147 6.39925 5.48497 4.91008 6.79762 3.82867C8.11028 2.74726 9.74676 2.13568 11.4469 2.09114C13.1471 2.0466 14.8133 2.57166 16.1808 3.58286C17.5483 4.59406 18.5385 6.03336 18.994 7.67195L21.7024 17.4334C21.7466 17.5878 21.7543 17.7503 21.7248 17.9081C21.6953 18.066 21.6295 18.2148 21.5326 18.3428Z" fill="#395061" />
                                </svg>
                            </span>
                        </div>
                        <div style={{ flexGrow: 1 }}>
                            <div className="d-md-flex">
                                <h3 className="mb-0" style={{ flexGrow: 1, fontSize: '1rem' }}>
                                    {notification.subject}
                                </h3>
                                <span style={{ flexShrink: 0, fontSize: '0.8rem', color: '#777777' }}>
                                    {moment(new Date(notification.notificationDate)).format('DD/MM/YYYY, hh:mm A')}
                                </span>
                            </div>
                        </div>
                    </div>
                )) : (
                    <p>No Notifications</p>
                )}
            </div>
        </div>
    )
}

export default Notifications