
import { useEffect } from 'react';
import swal from 'sweetalert';
import { useRecoilState } from 'recoil';
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from 'react-router-dom'
import { assessmentState } from '../atoms/assessment';
import { API_CALL } from '../../api/ApiServiceCall';
import { API_CONSTANTS, API_METHOD } from '../../common/constants';

function Assessment() {
    const [assessment, setAssessment] = useRecoilState(assessmentState);
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const navigate = useNavigate();
    let { search } = useLocation();

    const onSubmit = async (data) => {
        const answers = Object.keys(data).map(answer => ({
            assessmentOrder: answer,
            answer: data[answer]
        }));

        let body = assessment.isLiveCourse ? {
            assessmentCode: assessment.assessmentCode,
            answers: answers
        } : {
            chapterOrder: assessment.chapterOrder,
            lessonOrder: assessment.lessonOrder,
            answers: answers
        };

        body = JSON.stringify(body);

        const request = {
            apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.ASSESSMENT_SUBMIT(assessment.courseCode),
            method: API_METHOD.POST,
            requestBodyParams: body,
        }
        let result = await API_CALL(request)
        if (result.status === 200) {
            const updatedAnswers = result.data.answers.map(answer => ({
                ...answer,
                assessmentPercentage: result.data.assessmentPercentage,
                isAssessmentPass: result.data.isAssessmentPass,
                assessmentPoints: result.data.assessmentPoints,
                courseProgressPercentage: result.data.courseProgressPercentage
            }));

            setAssessment({
                ...assessment,
                isCompleted: true,
                assessmentDetails: updatedAnswers,
                assessmentPercentage: result.data.assessmentPercentage,
                isAssessmentPass: result.data.isAssessmentPass,
                assessmentPoints: result.data.assessmentPoints,
                courseProgressPercentage: result.data.courseProgressPercentage,
                totalPoints: result.data.totalPoints,
            })
            reset();
        } else if (result.status > 200) {
            swal({
                title: "ERROR",
                text: result.message,
                icon: 'https://cdn-icons-png.flaticon.com/512/2569/2569174.png',
                className: "error-window",
                button: true,
            })
        }
    }

    const deleteAssessment = async (courseCode, chapterOrder, lessonOrder) => {
        const body = assessment.isLiveCourse ? JSON.stringify({
            assessmentCode: assessment.assessmentCode
        }) : JSON.stringify({
            chapterOrder,
            lessonOrder,
        });

        // assessment.isLiveCourse

        const request = {
            apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.DELETE_ASSESSMENT(courseCode),
            method: API_METHOD.POST,
            requestBodyParams: body,
        }
        let result = await API_CALL(request)
        if (result.status === 200) {
            setAssessment({ ...assessment, isCompleted: false })
        } else if (result.status > 200) {
            swal({
                title: "ERROR",
                text: result.message,
                icon: 'https://cdn-icons-png.flaticon.com/512/2569/2569174.png',
                className: "error-window",
                button: true,
            })
        }
    }


    // useEffect(() => {
    //     const query = new URLSearchParams(search);
    //     const courseCode = query.get('courseCode');

    //     if ((!assessment.lessonName) && search && courseCode) {
    //         navigate(`/course/${courseCode}`)
    //     }
    // }, [search, assessment])


    return (
        <div className="mt-4">
            {/* <p>{JSON.stringify(assessment)}</p> */}
            {assessment.isCompleted && assessment.isAssessmentPass && (
                <div className="border" style={{ backgroundColor: 'rgba(41, 211, 99, 0.2)' }}>
                    <div className="student-conatiner py-4 d-flex justify-content-between align-items-center">
                        <div>
                            <h3 className="fs-5">Congratulations! You Passed!</h3>
                            <div className="mt-3">
                                <span className="me-3" style={{ fontSize: '0.9rem' }}>Grade Received {assessment?.assessmentPercentage} %</span>
                                <span style={{ fontSize: '0.9rem' }}>To Pass 80 % Or Higher</span>
                            </div>
                        </div>
                        <button onClick={() => navigate(`/course/${assessment.courseCode}`)} className="btn text-white rounded-1 px-4" style={{ backgroundColor: '#29D363' }}>
                            Go To Next
                        </button>
                    </div>
                </div>
            )}

            {assessment.isCompleted && !assessment.isAssessmentPass && (
                <div className="border" style={{ backgroundColor: 'rgba(247, 86, 86, 0.2)' }}>
                    <div className="student-conatiner py-4 d-flex justify-content-between align-items-center">
                        <div>
                            <h3 className="fs-5">Try Again! Once You Are Ready</h3>
                            <div className="mt-3">
                                <span className="me-3" style={{ fontSize: '0.9rem' }}>Grade Received {assessment?.assessmentPercentage} %</span>
                                <span style={{ fontSize: '0.9rem' }}>To Pass 80 % Or Higher</span>
                            </div>
                        </div>
                        <button
                            onClick={() => deleteAssessment(assessment.courseCode, assessment.chapterOrder, assessment.lessonOrder)}
                            className="btn text-white rounded-1 px-4"
                            style={{ backgroundColor: '#395061' }}
                        >
                            Try Again
                        </button>
                    </div>
                </div>
            )}
            <div className="student-conatiner py-5">
                <div>
                    <h2 className="fs-3">Test your knowledge on {assessment.lessonName}</h2>
                    {assessment.assessmentDetails && assessment.assessmentDetails.length > 0 && (
                    <p className="mt-3">Total Points: {assessment.assessmentDetails.reduce((total, item) => item.isCorrect?total + item.point:total+0, 0)}</p>
                )}
                </div>
                <div className="d-flex gap-4 mt-5">
                    <form onSubmit={handleSubmit(onSubmit)} className="w-100 assessment-form">
                        {assessment.assessmentDetails?.map((item, index) => (
                            <div key={item.assessmentOrder}>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-center gap-3">
                                        <h3 className="mb-0" style={{ fontSize: '1.2rem' }}>
                                            {index + 1}. {item.assessmentQuestion}?
                                        </h3>
                                        {assessment.isCompleted && item.hasOwnProperty('isCorrect') && item.isCorrect && (
                                            <span className="rounded-pill d-flex align-items-center gap-2" style={{ backgroundColor: 'rgba(41, 211, 99, 0.2)', fontSize: '0.8rem', padding: '0.5em' }}>
                                                <svg width="22" height="22" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14 0C6.27813 0 0 6.27813 0 14C0 21.7219 6.27813 28 14 28C21.7219 28 28 21.7219 28 14C28 6.27813 21.7219 0 14 0Z" fill="#29D363" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.7695 9.28037C21.1086 9.61943 21.1086 10.1772 20.7695 10.5163L12.5664 18.7194C12.3969 18.889 12.1727 18.9765 11.9484 18.9765C11.7242 18.9765 11.5 18.889 11.3305 18.7194L7.22891 14.6179C6.88984 14.2788 6.88984 13.721 7.22891 13.3819C7.56797 13.0429 8.12578 13.0429 8.46484 13.3819L11.9484 16.8655L19.5336 9.28037C19.8727 8.93584 20.4305 8.93584 20.7695 9.28037Z" fill="white" />
                                                </svg>
                                                <span className="pe-2" style={{ color: '#29D363' }}>Correct</span>
                                            </span>
                                        )}
                                        {assessment.isCompleted && item.hasOwnProperty('isCorrect') && !item.isCorrect && (
                                            <span className="rounded-pill d-flex align-items-center gap-2" style={{ backgroundColor: 'rgba(247, 86, 86, 0.3)', fontSize: '0.8rem', padding: '0.5em' }}>

                                                <span style={{ backgroundColor: '#F75656', borderRadius: '50%', width: '25px', height: '25px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="#F75656" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.01246 5.9999L0.605332 1.59279C0.332077 1.31954 0.332077 0.8781 0.605332 0.604844C0.878588 0.331588 1.32 0.331588 1.59326 0.604844L6.00039 5.012L10.4075 0.604844C10.6808 0.331588 11.1222 0.331588 11.3954 0.604844C11.6687 0.8781 11.6687 1.31954 11.3954 1.59279L6.98832 5.9999L11.3954 10.407C11.6687 10.6803 11.6687 11.1217 11.3954 11.395C11.1222 11.6682 10.6808 11.6682 10.4075 11.395L6.00039 6.98781L1.59326 11.395C1.32 11.6682 0.878588 11.6682 0.605332 11.395C0.332077 11.1217 0.332077 10.6803 0.605332 10.407L5.01246 5.9999Z" fill="white" />
                                                    </svg>
                                                </span>

                                                <span className="pe-2" style={{ color: '#F75656' }}>Wrong</span>
                                            </span>
                                        )}
                                    </div>
                                    <span className="py-1 px-2 rounded-1" style={{ backgroundColor: 'lightgray', fontSize: '0.9rem', flexShrink: 0, marginLeft: '20px' }}>
                                        {item.point} {item.point === 1 ? 'Point' : 'Points'}
                                    </span>
                                </div>


                                {/* <div className="p-4">
                                    {item.assessmentChoice?.map(choice => (
                                        <div className="mb-3 d-flex align-items-center">
                                            <input
                                                type="radio"
                                                id={`${index + 1}`}
                                                name={`${index + 1}`}
                                                value={choice}
                                                style={{ accentColor: '#232323' }}
                                                {...register(`${index + 1}`, { required: true })}
                                            />
                                            <label for={choice} className="ms-3">
                                                {choice}
                                            </label>
                                        </div>
                                    ))}
                                    <small className='text-danger mb-2 d-block fz-12px fw-500'> {errors[`${index + 1}`]?.type === 'required' && `Please Give Answer to ${item.assessmentQuestion}`}</small>
                                </div> */}

                                {assessment?.isCompleted && assessment.isAssessmentPass ? (
                                    <div className="p-4">
                                        {item.assessmentChoice?.map(choice => (
                                            <div key={choice} className="mb-3 d-flex align-items-center">
                                                <input
                                                    type="radio"
                                                    id={`${index + 1}`}
                                                    name={`${index + 1}`}
                                                    value={choice}
                                                    style={{ accentColor: '#232323' }}
                                                    {...register(`${index + 1}`, { required: true })}
                                                    checked={item.givenAssessmentAnswer === choice}
                                                />
                                                <label for={choice} className="ms-3">
                                                    {choice}
                                                </label>
                                            </div>
                                        ))}
                                        <small className='text-danger mb-2 d-block fz-12px fw-500'> {errors[`${index + 1}`]?.type === 'required' && `Please Give Answer to ${item.assessmentQuestion}`}</small>
                                    </div>
                                ) : (
                                    <div className="p-4">
                                        {item.assessmentChoice?.map(choice => (
                                            <div key={choice} className="mb-3 d-flex align-items-center">
                                                <input
                                                    type="radio"
                                                    id={`${index + 1}`}
                                                    name={`${index + 1}`}
                                                    value={choice}
                                                    style={{ accentColor: '#232323' }}
                                                    {...register(`${index + 1}`, { required: true })}
                                                // checked={item.givenAssessmentAnswer === choice}
                                                />
                                                <label for={choice} className="ms-3">
                                                    {choice}
                                                </label>
                                            </div>
                                        ))}
                                        <small className='text-danger mb-2 d-block fz-12px fw-500'> {errors[`${index + 1}`]?.type === 'required' && `Please Give Answer to ${item.assessmentQuestion}`}</small>
                                    </div>
                                )}



                            </div>
                        ))}

                        {!assessment.isCompleted && (
                            <div className="d-flex align-items-center">
                                <button className="btn px-5 py-2 rounded-1 text-white" style={{ backgroundColor: '#29D363' }}>
                                    Submit
                                </button>
                                <div className="px-5 py-2">
                                    <button onClick={() => {
                                        reset();
                                        navigate(`/course/${assessment.courseCode}`)
                                    }} type="button" className="btn" style={{ color: '#395061' }}>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        )}


                    </form>
                </div>
            </div>
            <br /><br /><br /><br /><br /><br />
        </div>
    )
}

export default Assessment