import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import swal from "sweetalert";
import { API_CALL } from "../../../api/ApiServiceCall";
import {
  API_CONSTANTS,
  API_METHOD,
  STORAGE_KEY,
} from "../../../common/constants";
import useCookie from "../../../common/cookie_hook";
import { RichTextState } from "./atom-rich-textbox-value.js/rich-txt-atom";

function Step1CourseDetail({
  setCurrentStep,
  register,
  watch,
  selectedSubCategory,
  setSelectedSubCategory,
}) {
  const {
    setValue,
    formState: { errors },
  } = useForm();
  const watchShowCount = watch("courseName", ""); // you can supply default value as second argument
  const [richTextValue, setRichTextValue] = useRecoilState(RichTextState);
  const [getCategory, setGetCategory] = useState([]);
  const [getSubCategory, setGetSubCategory] = useState(false);
  const [getCategoryForFormValidation, setCategoryForFormValidation] =
    useState("");
  // ---Temp Logic------
  const [cookie, updateCookie] = useCookie(STORAGE_KEY.AUTH);
  const navigate = useNavigate();
  // ---Temp Logic end------

  useEffect(() => {
    getAllCategory();
  }, []);
  const getAllCategory = async () => {
    const request = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.GET_ALL_CATEGORY,
      method: API_METHOD.GET,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      setGetCategory(result.data);
      // alert(JSON.stringify(result));
    } else if (result.status > 200) {
      // ---Temp Logic------
      if (result.message == "Token is Not Valid") {
        // swal("Already LoggedIn in Other device or Other browser! Try to Login Again").then(() => {
        //   localStorage.clear();
        //   updateCookie(null);
        //   navigate("/login")
        // })
      } else {
        swal({
          title: "ERROR",
          text: result.message,
          icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
          className: "error-window",
          button: true,
        });
      }
      // ---Temp Logic end------
    }
  };

  let formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
  ];

  const getCurrentCategoryCode = (e) => {
    setSelectedSubCategory("");
    getSubCategoryByCode(e.target.value);
    setCategoryForFormValidation(e.target.value);
  };
  const getSubCategoryByCode = async (catCode) => {
    const request = {
      apiUrl:
        API_CONSTANTS.BASE_URL +
        API_CONSTANTS.GET_SUB_CATEGORY_BY_CATEGORYCODE +
        `/?categoryCode=${catCode}`,
      method: API_METHOD.GET,
    };
    let result = await API_CALL(request);

    if (result.status === 200) {
      setGetSubCategory(result);
    } else if (result.status > 200) {
      console.log("errror", result);
    }
  };

  const [isFocused, setisFocused] = useState({
    courseNameFocused: false,
    catogoryFocused: false,
    subCategoryFocused: false,
    richtxt: false,
  });

  const watchingRequiredFields = watch([
    "courseName",
    "catogory",
    "subCategory",
  ]); //this will give array value so we are converting to object
  // console.log(watchingRequiredFields)
  const watchingRequiredFieldsData = {
    courseName: watchingRequiredFields[0],
    catogory: watchingRequiredFields[1],
  };
  const navigateStep2 = () => {
    const validate =
      watchingRequiredFieldsData.courseName &&
      getCategoryForFormValidation &&
      selectedSubCategory &&
      richTextValue &&
      richTextValue !== "<p><br></p>";
    if (validate) {
      setCurrentStep("step2");
    } else {
      setisFocused({
        courseNameFocused: true,
        catogoryFocused: true,
        subCategoryFocused: true,
        richtxt: true,
      });
    }
  };
  useEffect(() => {
    setSelectedSubCategory("");
  }, [getSubCategory]);

  return (
    <div className="login-col">
      <label className="fz-14px" htmlFor="tile">
        Course Title
      </label>
      <div className="d-flex position-relative">
        <input
          type="text"
          name="courseName"
          className="mb-2"
          {...register("courseName")}
          maxLength="120"
          onBlur={() => setisFocused({ ...isFocused, courseNameFocused: true })}
        />
        <div className="counter"> {`${watchShowCount.length}/120`}</div>
      </div>
      <small className="text-danger mb-1 d-block fz-12px fw-500">
        {isFocused.courseNameFocused &&
        watchingRequiredFieldsData.courseName === ""
          ? "Please Fill this field"
          : ""}
      </small>

      <label className="fz-14px" htmlFor="tile">
        Course Overview
      </label>
      <ReactQuill
        onBlur={() => setisFocused({ ...isFocused, richtxt: true })}
        formats={formats}
        theme="snow"
        onChange={setRichTextValue}
        className="rich-h"
      />
      <div className="py-4"></div>
      <div className="py-1"></div>
      <small className="text-danger my-0 d-block fz-12px fw-500">
        {(isFocused.richtxt && richTextValue === "") ||
        (isFocused.richtxt && richTextValue == "<p><br></p>")
          ? "Please Fill this field"
          : " "}
      </small>
      <div className="row">
        <div className="col">
          <label className="fz-14px">Category</label>

          <div
            className="tag-ajus mb-2"
            onClick={() =>
              setisFocused({ ...isFocused, catogoryFocused: true })
            }
          >
            <select
              defaultValue={""}
              {...register("catogory")}
              onChange={getCurrentCategoryCode}
            >
              <option value="" className="d-none">
                Select a category
              </option>
              {getCategory
                ? getCategory.map((catDatas, index) => (
                    <option key={index * 4} value={catDatas.categoryCode}>
                      {catDatas.categoryName}
                    </option>
                  ))
                : ""}
            </select>
          </div>
          <small className="text-danger my-0 d-block fz-12px fw-500">
            {isFocused.catogoryFocused && getCategoryForFormValidation === ""
              ? "Please Fill this field"
              : ""}
          </small>
        </div>
        <div className="col-lg-1"></div>
        <div className="col">
          <label className="fz-14px">Sub - Category</label>
          <div className="tag-ajus mb-2">
            <select
              defaultValue={selectedSubCategory}
              onChange={(e) => setSelectedSubCategory(e.target.value)}
              onBlur={() =>
                setisFocused({ ...isFocused, subCategoryFocused: true })
              }
            >
              <option value="" className="d-none">
                Select a Sub-Category
              </option>
              {getSubCategory
                ? getSubCategory.data.map((subCatDatas, index) => (
                    <>
                      <option
                        key={subCatDatas._id + "key" + index}
                        value={subCatDatas.subCategoryCode}
                      >
                        {subCatDatas.subCategoryName}
                      </option>
                    </>
                  ))
                : ""}
            </select>
          </div>
          <small className="text-danger my-0 d-block fz-12px fw-500">
            {isFocused.subCategoryFocused && selectedSubCategory === ""
              ? "Please Fill this field"
              : ""}
          </small>
        </div>
      </div>
      <div className="d-flex  justify-content-end py-4">
        <div
          className="  border-0 bg-prime text-white fz-14px py-12px w-14 rounded-3 text-center"
          type="button"
          onClick={navigateStep2}
        >
          Next
        </div>
      </div>
    </div>
  );
}

export default Step1CourseDetail;
