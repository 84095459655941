import React, { useState } from "react";

function Faq() {
  return (
    <div className="student-conatiner py-5 mt-4">
      <h1 className="fs-4 my-4">Frequently Asked Quetions</h1>
      <FAQItem
        heading="1. How can I access the Q-Learning Platform?"
        answer="If you are new to the platform, go to the Login page and hit 'Create Account' or you can also access through ‘Signup with Google’.
            If you already have an account, you can input the login credentials and access the platform."
      />
      <FAQItem
        heading="2. How can I create an account on Q-Learning platform?"
        answer="On the log-in screen, click ‘Create Account’ and provide your basic information to create an account."
      />
      <FAQItem
        heading="3. How can I manage my profile?"
        answer="Go to the dashboard and tap on ‘Profile Icon’ to view your personal information. If you want to alter any data, make the necessary changes and click 'Save Changes'."
      />
      <FAQItem
        heading="4. What categories or subjects can I teach on this platform?"
        answer="You can teach a variety of courses and provide diverse educational content to students all over the world. Like Information Technology, Business Entrepreneurship, Creative Arts, Health & Fitness, and many others."
      />
      <FAQItem
        heading="5. How do I create a new course on this platform?"
        answer="On the dashboard, navigate to Courses->Recorded Course/Live Course and hit ‘Create New Course’. Input the necessary data and later submit the created course for review."
      />
      <FAQItem
        heading="6. How do I create assessments?"
        answer="Navigate to Courses->Assessments on the dashboard and hit 'Create New Assessment'. Add the required MCQs (Multiple Choice Questions) with weightage and then submit for review."
      />
      <FAQItem
        heading="7. How can I schedule my teaching sessions?"
        answer="Go to Dashboard->Courses->Live Courses, where you can set your availability by selecting the days and time that work best for you."
      />
      <FAQItem
        heading="8. Can I hold live virtual classes or webinars with learners?"
        answer="Yes, you have the option of creating demo classes."
      />
      <FAQItem
        heading="9. Can I set a price for my course?"
        answer="No, you cannot set the price. Currently, price can be selected from a drop-down list while creating the course."
      />
      <FAQItem
        heading="10. How can I promote my courses to attract learners?"
        answer="You can promote your courses by offering attractive discounts."
      />
      <FAQItem
        heading="11. How can I create a coupon?"
        answer="Go to the dashboard->Profile->coupons tab and fill in all the required fields such as coupon name, discount, and validity. Later, submit for review."
      />
      <FAQItem
        heading="12. Can I communicate with the learners?"
        answer="Yes, you can communicate with the learners through the application's messaging feature."
      />
      <FAQItem
        heading="13. How can I track the progress and performance of the learner?"
        answer="No, you cannot track the learners' progress.  However, once a learner completes a course, you will receive an email notification."
      />
      <FAQItem
        heading="14. Can I offer certificates or badges to the learners upon their course completion?"
        answer="Yes, you can provide certificates to the learners upon the course completion. These digital credentials recognize and validate learners' achievements, allowing them to showcase their accomplishments."
      />
      <FAQItem
        heading="15. Can I share my personal email and phone number with the learners?"
        answer="Yes, you can share with the learners. It entirely depends on your own personal interests."
      />
      <FAQItem
        heading="16. How can I raise a ticket?"
        answer="To raise a ticket, simply click the ‘Raise a Ticket’ button at the specific course or assessment where you are experiencing difficulties."
      />
      <FAQItem
        heading="17. Where can I submit my Payout Information? "
        answer="To add your payout information, navigate to dashboard->Profile->Payout Info tab and provide your bank details and click 'Submit'."
      />
      <FAQItem
        heading="18. How can I receive payment for the courses/assessments purchased by learners?"
        answer="To get the payment, go to dashboard->current balance->hit withdrawal button and enter the amount you wish to receive, later click the withdraw button. (The admin will process your withdrawal request, and your payment will be credited to your account.)"
      />
      <FAQItem
        heading="19. Can I cancel or edit my Course? Is there a time restriction for cancelling or editing?"
        answer="No, we cannot cancel or amend a course once it has been created. It can only be deactivated by the administrator."
      />
      <FAQItem
        heading="20. How can I interact with other instructors or join instructor communities on this platform?"
        answer="At the moment, instructor-to-instructor interaction is not supported. This functionality, however, is being planned for the future."
      />
    </div>
  );
}

function FAQItem({ heading, answer }) {
  const [open, setOpen] = useState(false);

  return (
    <div
      className="p-3 rounded-2 prevent-text-select my-3"
      style={{ backgroundColor: " #F3F3F3" }}
    >
      <div
        className="d-flex justify-content-between align-items-center flex-row flex-nowrap"
        style={{ cursor: "pointer" }}
        onClick={() => setOpen((prevState) => !prevState)}
      >
        <h2
          className="fs-6 p-0 m-0 fw-normal"
          style={{ color: open ? "#000" : "#395061" }}
        >
          {heading}
        </h2>
        {open ? (
          <svg
            width="15"
            height="9"
            className="flex-shrink-0"
            viewBox="0 0 15 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.937438 8.20062C1.18599 8.2004 1.42429 8.10149 1.59994 7.92562L6.39493 3.13062C6.54003 2.98549 6.7123 2.87036 6.9019 2.79181C7.09149 2.71326 7.29471 2.67283 7.49993 2.67283C7.70516 2.67283 7.90837 2.71326 8.09797 2.79181C8.28757 2.87036 8.45984 2.98549 8.60493 3.13062L13.3968 7.91937C13.5736 8.09015 13.8104 8.18464 14.0562 8.1825C14.3021 8.18037 14.5372 8.08177 14.711 7.90795C14.8848 7.73413 14.9834 7.499 14.9856 7.25319C14.9877 7.00738 14.8932 6.77056 14.7224 6.59375L9.93431 1.805C9.28914 1.1611 8.41487 0.799471 7.50337 0.799471C6.59187 0.799471 5.7176 1.1611 5.07244 1.805L0.277438 6.6C0.146428 6.73092 0.0571336 6.89772 0.0208244 7.07934C-0.0154839 7.26097 0.00281807 7.44927 0.0734234 7.62051C0.144029 7.79174 0.263773 7.93822 0.417543 8.04146C0.571315 8.14471 0.752222 8.20009 0.937438 8.20062Z"
              fill="#395061"
            />
          </svg>
        ) : (
          <svg
            width="15"
            height="9"
            className="flex-shrink-0"
            viewBox="0 0 15 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.0626 0.799378C13.814 0.799597 13.5757 0.898512 13.4001 1.07438L8.60507 5.86938C8.45997 6.01451 8.2877 6.12964 8.0981 6.20819C7.90851 6.28674 7.70529 6.32717 7.50007 6.32717C7.29484 6.32717 7.09163 6.28674 6.90203 6.20819C6.71243 6.12964 6.54016 6.01451 6.39507 5.86938L1.6032 1.08063C1.42638 0.909854 1.18957 0.815359 0.943757 0.817496C0.697948 0.819632 0.462812 0.918227 0.288992 1.09205C0.115172 1.26587 0.0165757 1.501 0.0144397 1.74681C0.0123037 1.99262 0.106798 2.22944 0.277571 2.40625L5.06569 7.195C5.71086 7.8389 6.58513 8.20053 7.49663 8.20053C8.40813 8.20053 9.2824 7.8389 9.92756 7.195L14.7226 2.4C14.8536 2.26908 14.9429 2.10228 14.9792 1.92066C15.0155 1.73903 14.9972 1.55073 14.9266 1.3795C14.856 1.20826 14.7362 1.06178 14.5825 0.958539C14.4287 0.855294 14.2478 0.799911 14.0626 0.799378Z"
              fill="#395061"
            />
          </svg>
        )}
      </div>
      {open && (
        <div
          className="mt-2 text-muted"
          style={{ fontSize: "0.9rem", lineHeight: "1.7" }}
        >
          {answer}
          {/* It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using */}
        </div>
      )}
    </div>
  );
}

export default Faq;
