import React from "react";
import { useForm } from "react-hook-form";
import { useRecoilState } from "recoil";
import swal from "sweetalert";
import { API_CALL } from "../../../../../api/ApiServiceCall";
import { API_CONSTANTS, API_METHOD } from "../../../../../common/constants";
import { getAllChapterAccordionsInCourseAtom } from "../../../../atom/callFunctionGetAllChapterAccordionsAtom";

function CreateChapter({ setIsCreateChapterName, clickedChapterDataForEdit }) {
  const {
    register,
    reset,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      chapterName: clickedChapterDataForEdit?.chapterName || "",
    },
  });
  const [
    callFunctionGetAllChapterAccordionsAtom,
    setCallFunctionGetAllChapterAccordionsAtom,
  ] = useRecoilState(getAllChapterAccordionsInCourseAtom);

  // below(onSubmit) for creating chapter
  const onSubmit = async (data) => {
    const userCode = JSON.parse(localStorage.getItem("userCode"));
    // setLoading(true);
    let requestBodyParam = JSON.stringify({
      chapterName: data.chapterName,
      courseCode: userCode.data,
      chapterOrder: clickedChapterDataForEdit?.chapterOrder || "", // this key is for edit action
    });
    const request = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.CREATE_CHAPTER_NAME,
      method: API_METHOD.POST,
      requestBodyParams: requestBodyParam,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      reset();
      setIsCreateChapterName(false);
      setCallFunctionGetAllChapterAccordionsAtom({
        changeOccur: Math.random(),
      });
    } else if (result.status > 200) {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }
  };
  return (
    <div>
      {" "}
      <div className="login-col pt-4">
        <form id="chapterName" onSubmit={handleSubmit(onSubmit)}>
          <input
            type="text"
            placeholder="Chapter Name"
            {...register("chapterName")}
            required
          />
        </form>
      </div>
      {/* {JSON.stringify(clickedChapterDataForEdit)} */}
      <div className="d-flex justify-content-end ">
        <button
          form="lessonName"
          className="border-0 bg-secondary text-white fz-14px py-2 px-5 rounded-3 text-center mx-2"
          role="button"
          onClick={() => setIsCreateChapterName(false)}
        >
          Cancel
        </button>
        <button
          form="chapterName"
          className="border-0 bg-prime text-white fz-14px py-2 px-5 rounded-3 text-center"
          role="button"
          type="submit"
        >
          Done
        </button>
      </div>
    </div>
  );
}

export default CreateChapter;
