import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useResetRecoilState } from 'recoil';
import { API_CALL } from '../../../../api/ApiServiceCall';
import { API_CONSTANTS, API_METHOD } from '../../../../common/constants';
import InstructorLoader from '../../../../common/InstructorLoader';
import Paginate from '../../../../common/Pagination';
import { singleLiveCourseDataAtomFunc } from '../../../atom/singleLiveCourseDataAtom';
import LiveCourseCard from './liveCourseCard';
import socket from '../../../../common/Socket';


function LiveCoursesList() {
    const [liveCoursesListState, setLiveCoursesListState] = useState(null);
    const resetsSingleLiveCourseDataAtom = useResetRecoilState(singleLiveCourseDataAtomFunc);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [itemOffset, setItemOffset] = useState(0);
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(null);
    const courseListRef = useRef(null)
    const [SECONDS] = useState(5000);

    useEffect(() => {
        getLiveCoursesList();
        resetsSingleLiveCourseDataAtom();
         const interval = setInterval(() => {
           newMessageCount(courseListRef.current);
         }, SECONDS);
        socket.open();
        socket.on("connection-success", async (response) => {
            console.log(`Socket connected ${response.socketId}`);
        });

        return () => {
            socket.disconnect();
            socket.close();
            clearInterval(interval);
            socket.off("newMessageCount")
            socket.on("disconnect", function () { });
        }
    }, []);

    const newMessageCount = (data) => {
        socket.emit("newMessageCount", { ticketType: "COURSE" }, async (response) => {
            console.log("newMessageCount", response)
            let apiArry = data;
            let socketArry = response;
            let combinedArry = apiArry.map(obj1 => {
                let obj2 = socketArry.find(obj2 => obj1.courseCode === obj2.courseCode);
                return Object.assign({}, obj1, obj2);
            });
            setLiveCoursesListState(combinedArry)
        });
    };
    
    const getLiveCoursesList = async () => {
        const request = {
            apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.GET_LIVE_COURSE_BY_INSTRUCTOR,
            method: API_METHOD.GET,
        }
        let result = await API_CALL(request);
        if (result.status === 200) {
            courseListRef.current = result.data
            setLiveCoursesListState(result.data);
            newMessageCount(result.data)
        } else if (result.status > 200) {
            console.log('errror', result)
        }
    }
    // ----pagination start----------
    useEffect(() => {
        if (liveCoursesListState) {
            // console.log(liveCoursesListState)
            const endOffset = itemOffset + Number(itemsPerPage);
            let sliceItem = liveCoursesListState.slice(itemOffset, endOffset);
            setCurrentItems(sliceItem)
            let pageCountResult = Math.ceil(liveCoursesListState.length / itemsPerPage);
            setPageCount(pageCountResult)
        }
    }, [liveCoursesListState, itemOffset, itemsPerPage])

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % liveCoursesListState.length;
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
    };
    // ----pagination end----------

    return (
        <div className='px-4 mt-5'>
            <div className="d-flex justify-content-end  mx-1 mb-3">
                <Link to="/courses/live/step-form" ><button className=' border-0 bg-prime text-white fz-14px py-26px rounded-9 px-5'>+ Create New Course</button></Link>
            </div>
            {/* <pre>{JSON.stringify(currentItems, null, 4)}</pre> */}

            {!currentItems ? <InstructorLoader /> : currentItems.length ? currentItems?.map((liveCourseData,id) => (
                <LiveCourseCard key={liveCourseData.courseCode} getLiveCoursesList={getLiveCoursesList} liveCourseData={liveCourseData} currentItems={currentItems} data={id} />
            )) : <div className='d-flex align-items-center justify-content-center py-5'>
                    <h6 className={`text-muted fz-19px text-center`} > No course created</h6>
                </div>
            }
            <div className="px-4">
                {
                    currentItems?.length ? <div className="row mx-1">
                        <div className="col-lg-8">
                            <Paginate onPageChange={handlePageClick} pageCount={pageCount} isShow={currentItems?.length} />
                        </div>
                        <div className="col-lg-4 fz-13px fw-500 text-muted text-end">
                            Show  <select className='px-2 py-1 ' onChange={(e) => setItemsPerPage(e.target.value)}>
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={75}>75</option>
                                <option value={100}>100</option>
                            </select> courses per page
                        </div>
                    </div> : ""
                }
            </div>
            <div className="py-4"></div>
        </div >
    )
}

export default LiveCoursesList