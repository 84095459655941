import React from "react";
import { useRecoilState } from "recoil";
import { liveCourseAssessmentViewAtomFunc } from "../../../../../../atom/liveCourseAssessmentViewAtom";
import { liveCourseClickedAssessmentDataAtomFunc } from "../../../../../../atom/liveCourseClickedAssessmentDataAtom";
import CreateAssessmentInsideClickedOne from "./createAssessmentInsideClickedOne";
import InsideDataAssessment from "./insideDataAssessment";

function ClickedAssessmentView() {
  const [liveCourseAssessmentViewAtom, setLiveCourseAssessmentViewAtom] =
    useRecoilState(liveCourseAssessmentViewAtomFunc);
  const [
    liveCourseClickedAssessmentDataAtom,
    setLiveCourseClickedAssessmentDataAtom,
  ] = useRecoilState(liveCourseClickedAssessmentDataAtomFunc);

  return (
    <>
      {/* <pre>{JSON.stringify(liveCourseClickedAssessmentDataAtom, null, 3)}</pre> */}
      {liveCourseClickedAssessmentDataAtom.assessmentDetails.length ? (
        <InsideDataAssessment />
      ) : (
        <CreateAssessmentInsideClickedOne />
      )}
    </>
  );
}
export default ClickedAssessmentView;
