import React, { useEffect, useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import swal from "sweetalert";
import { API_CALL } from "../../../api/ApiServiceCall";
import { API_CONSTANTS, API_METHOD } from "../../../common/constants";
import LatestTransactionTable from "./LatestTransactionTable";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import PurchaseList from "./PurchaseList";
import socket from "../../../common/Socket";
import "../../../css/transaction-page.css";

function Transaction() {
  const [transactionPageData, setTsransactionPageData] = useState(null);
  const [transactionPageTableData, setTransactionPageTableData] =
    useState(null);
  const [purchaseListData, setPurchaseListData] = useState(null);
  const [show, setShow] = useState(false);
  const [withdrawAmount, setWithdrawAmount] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setWithdrawAmount("");
    setShow(true);
  };
  const transactionListRef = useRef(null);
  const [SECONDS] = useState(5000);

  useEffect(() => {
    getTransactionPageData();
    getWithdrawRequest();
    getPurchaseList();
    const interval = setInterval(() => {
      newMessageCount(transactionListRef.current);
    }, SECONDS);
    socket.open();
    socket.on("connection-success", async (response) => {
      console.log(`Socket connected ${response.socketId}`);
    });
    return () => {
      socket.close();
      socket.disconnect();
      socket.off("newMessageCount");
      clearInterval(interval);
      socket.on("disconnect", function () {});
    };
  }, []);

  const newMessageCount = (data) => {
    socket.emit(
      "newMessageCount",
      { ticketType: "TRANSACTION" },
      async (response) => {
        let apiArry = data;
        let socketArry = response;
        let combinedArry = apiArry.map((obj1) => {
          let obj2 = socketArry.find((obj2) => obj1._id === obj2.courseCode);
          return Object.assign({}, obj1, obj2);
        });
        setTransactionPageTableData(combinedArry);
      }
    );
  };

  const getTransactionPageData = async () => {
    const request = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.WITHDRAW_DASHBOARD_DATA,
      method: API_METHOD.GET,
    };
    let result = await API_CALL(request);
    console.log(result.data,"result.data");
    if (result.status === 200) {
      setTsransactionPageData(result.data);
    } else if (result.status > 200) {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }
  };
  const getPurchaseList = async () => {
    const request = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.GET_PURCHASE_DETAILS,
      method: API_METHOD.GET,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      setPurchaseListData(result.data);
    } else if (result.status > 200) {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }
  };

  const onWithdraw = async (e) => {
    e.preventDefault();
    const requestBodyParam = JSON.stringify({
      requestedAmount: withdrawAmount,
      // withdrawId: ""
    });
    const request = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.RAISE_WITH_DRAW_REQUEST,
      method: API_METHOD.POST,
      requestBodyParams: requestBodyParam,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      handleClose();
      swal({
        title: "Your fund is on the way",
        className: "success-window",
        icon: "success",
        button: true,
      });
      getWithdrawRequest();
    } else if (result.status > 200) {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }
  };
  const getWithdrawRequest = async () => {
    const request = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.GET_WITHDRAW_REQUEST,
      method: API_METHOD.GET,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      transactionListRef.current = result.data;
      setTransactionPageTableData(result.data);
      newMessageCount(result.data);
    } else if (result.status > 200) {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }
  };

  return (
    <div className="py-3 px-4">
      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        centered
        backdrop={"static"}
        className="mh"
      >
        <Modal.Header closeButton className="mh">
          <Modal.Title className="mdlt">Withdraw Fund</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="fz-16px pb-1 text-black">Enter Amount</p>
          <form className="login-col" onSubmit={onWithdraw}>
            <input
              type={"number"}
              value={withdrawAmount}
              max={
                transactionPageData?.TotalRevenueWithoutTax -
                transactionPageData?.paidAmount
              }
              min="0"
              onChange={(e) => setWithdrawAmount(e.target.value.trim())}
              required
            />
            <button
              type={"submit"}
              className="border-0 bg-prime text-white fz-14px py-2 mb-4 px-4 rounded-3 text-center"
            >
              Withdraw
            </button>
          </form>
        </Modal.Body>
      </Modal>

      <div className="row">
        <div className="col-lg d-lg-flex align-items-lg-stretch">
          <div className="crd-ds w-100">
            <h5 className="fz-17px fw-500">Current Balance</h5>
            <h6 className="m-0 fz-16px  fw-600">
              ₹
              {transactionPageData?.TotalRevenueWithoutTax -
                transactionPageData?.paidAmount || 0}
            </h6>
            <div className="d-flex flex-column h-60px">
              <div className="mt-auto">
                <button
                  className="  border-0 bg-prime text-white fz-14px w-100 py-2 rounded-3"
                  onClick={handleShow}
                >
                  Withdraw
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg d-lg-flex align-items-lg-stretch">
          <div className="crd-ds w-100">
            <h5 className="fz-17px fw-500">Total Revenue</h5>
            <h6 className="m-0 fz-16px pt-1 fw-600">
              ₹{transactionPageData?.TotalRevenueWithoutTax}
            </h6>
            <div className="d-flex flex-column h-60px">
              <div className="mt-auto">
                <div className="row">
                  <div className="col-lg">
                    <p className="m-0 text-muted fw-500 fz-12px"> This Month</p>
                  </div>
                  <div className="col-lg text-end">
                    <p className="m-0 fw-500 text-muted fz-12px">
                      {" "}
                      ₹{transactionPageData?.currentMonthRevenue}{" "}
                    </p>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-lg">
                    <p className="m-0 fw-500 text-muted fz-12px"> Last Month</p>
                  </div>
                  <div className="col-lg text-end">
                    <p className="m-0 fw-500 text-muted fz-12px">
                      ₹{transactionPageData?.lastMonthRevenue}{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg d-lg-flex align-items-lg-stretch">
          <div className="crd-ds w-100">
            <h5 className="fz-17px fw-500">Total Enrollments</h5>
            <h6 className="m-0 fz-16px pt-1 fw-600">
              {transactionPageData?.totalEnrollment}{" "}
            </h6>
            <div className="d-flex flex-column h-60px">
              <div className="mt-auto">
                <div className="row">
                  <div className="col-lg">
                    <p className="m-0 text-muted fw-500 fz-12px"> This Month</p>
                  </div>
                  <div className="col-lg text-end">
                    <p className="m-0 fw-500 text-muted fz-12px">
                      {" "}
                      {transactionPageData?.currentMonthTotalEnrollment}{" "}
                    </p>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-lg">
                    <p className="m-0 fw-500 text-muted fz-12px"> Last Month</p>
                  </div>
                  <div className="col-lg text-end">
                    <p className="m-0 fw-500 text-muted fz-12px">
                      {" "}
                      {transactionPageData?.lastMonthTotalEnrollment}{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg d-lg-flex align-items-lg-stretch">
          <div className="crd-ds w-100">
            <h5 className="fz-17px fw-500">Total Learners</h5>
            <h6 className="m-0 fz-16px pt-1 fw-600">
              {transactionPageData?.totalLearners}
            </h6>
            <div className="d-flex flex-column h-60px">
              <div className="mt-auto">
                <div className="row">
                  <div className="col-lg-7">
                    <p className="m-0 text-muted text-nowrap fw-500 fz-12px">
                      Joined This Month
                    </p>
                  </div>
                  <div className="col-lg text-end">
                    <p className="m-0 fw-500 text-muted fz-12px">
                      {transactionPageData?.currentMonthEnrollment}
                    </p>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-lg-7">
                    <p className="m-0 fw-500 text-nowrap text-muted fz-12px">
                      Joined Last Month
                    </p>
                  </div>
                  <div className="col-lg text-end">
                    <p className="m-0 fw-500 text-muted fz-12px">
                      {transactionPageData?.lastMonthEnrollment}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <pre>{JSON.stringify(transactionPageTableData, null, 4)}</pre> */}

      <div className="video-tabs overview-card my-5">
        {/*<Tab.Container id="left-tabs-example" defaultActiveKey="first" animation={true}  mountOnEnter unmountOnExit> */}
        <div className="py-2"></div>
        <Tab.Container
          id="left-tabs-example"
          defaultActiveKey="first"
          mountOnEnter
          unmountOnExit
        >
          <Row>
            <Col sm={12}>
              <Nav variant="pills">
                <Nav.Item>
                  <Nav.Link eventKey="first" className="px-5 fz-15px">
                    Transactions History
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="second"
                    className="px-5 fz-15px"
                    disabled={false}
                  >
                    Purchasers List
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={12}>
              <Tab.Content>
                <Tab.Pane eventKey="second">
                  <div className="py-2">
                    <PurchaseList purchaseListData={purchaseListData} />
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="first">
                  <div className="py-2">
                    <LatestTransactionTable
                      transactionPageTableData={transactionPageTableData}
                    />
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </div>
  );
}

export default Transaction;
