import { useState, useEffect } from 'react';
import swal from 'sweetalert';
import moment from 'moment';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { API_CALL } from '../../api/ApiServiceCall';
import { API_CONSTANTS, API_METHOD } from '../../common/constants';
import { currencySymbol } from '../common/constants';
import InvoicePDF from '../pdf-components/invoicePDF';
import InvoiceIcon from '../icons/InvoiceIcon'

function PaymentHistory() {
    const [paymentHistory, setPaymentHistory] = useState({
        loading: false,
        error: null,
        data: []
    });

    const getPaymentHistory = async () => {
        setPaymentHistory({ loading: true, data: [], error: null });

        const request = {
            apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.PAYMENT_HISTORY,
            method: API_METHOD.GET,
        }

        let result = await API_CALL(request);

        if (result.status === 200) {
            const paymentHistoryCourses = result.data.reduce((totalPayments, payment) => {
                const courses = payment.items.map((item) => ({
                    courseName: item.courseName,
                    courseCode: item.courseCode,
                    fee: payment.total,
                    subTotal:payment.subTotal,
                    taxValue:payment.taxValue,
                    thumbNailImagePath: item.thumbNailImagePath,
                    instructorName: item.instructorName,
                    instructorId: item.instructorId,
                    _id: item._id,
                    currency: payment.currencyCode,
                    createdTime: payment.createdTime,
                    orderStatus: payment.orderStatus,
                    razorpayOrderId: payment.razorpayOrderId,
                }));

                return [...totalPayments, ...courses];
            }, []);

            setPaymentHistory({
              loading: false,
              data: result?.data,
              error: null,
            });
        } else if (result.status > 200) {
            setPaymentHistory({ loading: false, data: [], error: result.message });
            swal({
                title: "ERROR",
                text: result.message,
                icon: 'https://cdn-icons-png.flaticon.com/512/2569/2569174.png',
                className: "error-window",
                button: true,
            })
        }
    }

    useEffect(() => {
        getPaymentHistory();
    }, [])

    // PAYMENT_COMPLETED
    // PAYMENT_INPROGRESS
    // PAYMENT_FAILED

    return (
      <div style={{ maxHeight: "500px", overflowY: "auto" }}>
        {/* <div className="d-flex justify-content-between align-items-center mb-4"> */}
        {/* <h2 className="fs-3 mb-0">Payment History</h2> */}
        {/* <button className="btn px-4" style={{ backgroundColor: '#F0E1EB' }}>
                    <svg width="18" height="18" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_297_3585)">
                            <path d="M22.3958 18.75H19.2708C18.9833 18.75 18.75 18.5167 18.75 18.2292C18.75 17.9417 18.9833 17.7083 19.2708 17.7083H22.3958C23.2573 17.7083 23.9583 17.0073 23.9583 16.1458V8.85417C23.9583 7.99271 23.2573 7.29167 22.3958 7.29167H2.60417C1.74271 7.29167 1.04167 7.99271 1.04167 8.85417V16.1458C1.04167 17.0073 1.74271 17.7083 2.60417 17.7083H5.72917C6.01666 17.7083 6.25 17.9417 6.25 18.2292C6.25 18.5167 6.01666 18.75 5.72917 18.75H2.60417C1.16771 18.75 0 17.5812 0 16.1458V8.85417C0 7.41875 1.16771 6.25 2.60417 6.25H22.3958C23.8323 6.25 25 7.41875 25 8.85417V16.1458C25 17.5812 23.8323 18.75 22.3958 18.75Z" fill="#395061" />
                            <path d="M15.1038 21.8752H8.85384C8.56634 21.8752 8.33301 21.6418 8.33301 21.3543C8.33301 21.0668 8.56634 20.8335 8.85384 20.8335H15.1038C15.3913 20.8335 15.6247 21.0668 15.6247 21.3543C15.6247 21.6418 15.3913 21.8752 15.1038 21.8752Z" fill="#395061" />
                            <path d="M15.1038 19.7917H8.85384C8.56634 19.7917 8.33301 19.5583 8.33301 19.2708C8.33301 18.9833 8.56634 18.75 8.85384 18.75H15.1038C15.3913 18.75 15.6247 18.9833 15.6247 19.2708C15.6247 19.5583 15.3913 19.7917 15.1038 19.7917Z" fill="#395061" />
                            <path d="M10.9372 17.7082H8.85384C8.56634 17.7082 8.33301 17.4748 8.33301 17.1873C8.33301 16.8998 8.56634 16.6665 8.85384 16.6665H10.9372C11.2247 16.6665 11.458 16.8998 11.458 17.1873C11.458 17.4748 11.2247 17.7082 10.9372 17.7082Z" fill="#395061" />
                            <path d="M19.2705 7.29166C18.983 7.29166 18.7497 7.05833 18.7497 6.77083V2.60417C18.7497 1.74271 18.0486 1.04167 17.1872 1.04167H7.81218C6.95072 1.04167 6.24967 1.74271 6.24967 2.60417V6.77083C6.24967 7.05833 6.01634 7.29166 5.72884 7.29166C5.44134 7.29166 5.20801 7.05833 5.20801 6.77083V2.60417C5.20801 1.16875 6.37572 0 7.81218 0H17.1872C18.6236 0 19.7913 1.16875 19.7913 2.60417V6.77083C19.7913 7.05833 19.558 7.29166 19.2705 7.29166Z" fill="#395061" />
                            <path d="M17.1872 24.9998H7.81218C6.37572 24.9998 5.20801 23.8311 5.20801 22.3957V14.0623C5.20801 13.7748 5.44134 13.5415 5.72884 13.5415H19.2705C19.558 13.5415 19.7913 13.7748 19.7913 14.0623V22.3957C19.7913 23.8311 18.6236 24.9998 17.1872 24.9998ZM6.24967 14.5832V22.3957C6.24967 23.2571 6.95072 23.9582 7.81218 23.9582H17.1872C18.0486 23.9582 18.7497 23.2571 18.7497 22.3957V14.5832H6.24967Z" fill="#395061" />
                        </g>
                        <defs>
                            <clipPath id="clip0_297_3585">
                                <rect width="25" height="25" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    <span className="ms-2">Print</span>
                </button> */}
        {/* </div> */}

       

        <table
          style={{
            borderCollapse: "separate",
            borderSpacing: "0 1.8em",
            margin: "0",
          }}
        >
          <tr>
            <th
              className="text-secondary fw-normal"
              style={{ fontSize: "1.2rem" }}
            >
              Course Name
            </th>
            <th
              className="text-secondary fw-normal"
              style={{ fontSize: "1.2rem" }}
            >
              Time Stamp
            </th>
            <th
              className="text-secondary fw-normal"
              style={{ fontSize: "1.2rem", padding: "25px" }}
            >
              Fee
            </th>
            <th
              className="text-secondary fw-normal"
              style={{ fontSize: "1.2rem", padding: "20px" }}
            >
              Status
            </th>
            <th
              className="text-secondary fw-normal"
              style={{ fontSize: "1.2rem" }}
            >
              Invoice
            </th>
          </tr>
          <>
            {paymentHistory.data
              .sort((a, b) => new Date(b.createdTime) - new Date(a.createdTime))
              .map((payment) => (
                <tr style={{ marginBottom: "10px" }}>
                  <td style={{ width: "60%", whiteSpace: "nowrap" }}>
                    <b>#Order ID</b> {payment.razorpayPaymentId}
                  </td>
                  <td style={{ margin: "0 0px", padding: " 0 10px 0 0" }}>
                    {moment(payment.createdTime).format("D MMMM, YYYY")}
                  </td>
                  <td style={{ margin: "0 0px", padding: "20px" }}>
                    {currencySymbol[payment.currency]}
                    {payment.total}
                  </td>
                  <td style={{ margin: "0 0px", padding: "10px" }}>
                    {payment.orderStatus === "PAYMENT_COMPLETED" && (
                      <span
                        className="d-block rounded-pill text-center"
                        style={{
                          width: "75px",
                          backgroundColor: "#c8f9d7",
                          color: "#29D363",
                          fontSize: "0.7rem",
                          paddingTop: "6px",
                          paddingBottom: "6px",
                        }}
                      >
                        Confirmed
                      </span>
                    )}
                    {payment.orderStatus === "PAYMENT_INPROGRESS" && (
                      <span
                        className="d-block rounded-pill text-center"
                        style={{
                          width: "75px",
                          backgroundColor: "#f5edde",
                          color: "#FFBE40",
                          fontSize: "0.7rem",
                          paddingTop: "6px",
                          paddingBottom: "6px",
                        }}
                      >
                        Pending
                      </span>
                    )}
                    {payment.orderStatus === "PAYMENT_FAILED" && (
                      <span
                        className="d-block rounded-pill text-center"
                        style={{
                          width: "75px",
                          backgroundColor: "#f6e1e1",
                          color: "#F75656",
                          fontSize: "0.7rem",
                          paddingTop: "6px",
                          paddingBottom: "6px",
                        }}
                      >
                        Cancelled
                      </span>
                    )}
                  </td>
                  <td style={{ margin: "0px" }}>
                    <PDFDownloadLink
                      className="btn text-white py-2 rounded-3 flex-grow-1"
                      style={{ background: "#fff", flexBasis: 0 }}
                      document={
                        <InvoicePDF
                          data={{
                            courseName: payment.items,
                            amount: payment.total,
                            subTotal: payment.subTotal,
                            taxValue: payment.taxValue,
                            taxPersentage: 5,
                          }}
                        />
                      }
                      fileName="invoice.pdf"
                    >
                      {({ loading, error }) =>
                        loading ? <InvoiceIcon /> : <InvoiceIcon />
                      }
                    </PDFDownloadLink>
                  </td>
                </tr>
              ))}
          </>
          {/* <tr>
                    <td>Complete Digital Marketing Course to Become Professional</td>
                    <td className="fw-normal">22 February, 2022</td>
                    <td>$12.99</td>
                    <td>
                        <span className="d-block rounded-pill text-center" style={{ width: '75px', backgroundColor: '#c8f9d7', color: '#29D363', fontSize: '0.7rem', paddingTop: '6px', paddingBottom: '6px' }}>
                            Confirmed
                        </span>
                    </td>
                    <td>
                        <button className="btn rounded-pill d-flex justify-content-center align-items-center" style={{ backgroundColor: '#f4edf2', height: '33px', width: '33px' }}>
                            <svg width="17" height="20" viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.7469 9.89467V5.56902C15.7469 5.44521 15.6897 5.33081 15.6087 5.2403L10.7591 0.147705C10.6686 0.0524902 10.5399 0 10.4113 0H2.72231C1.3028 0 0.168945 1.15758 0.168945 2.57725V17.1167C0.168945 18.5364 1.3028 19.6749 2.72231 19.6749H8.79164C9.93963 21.5805 12.0263 22.8571 14.4034 22.8571C18.0144 22.8571 20.9633 19.9226 20.9633 16.3069C20.9682 13.1484 18.7004 10.5092 15.7469 9.89467ZM10.8878 1.67219L14.1415 5.09731H12.031C11.4022 5.09731 10.8878 4.57816 10.8878 3.94932V1.67219ZM2.72231 18.7221C1.83154 18.7221 1.12179 18.0075 1.12179 17.1167V2.57725C1.12179 1.68161 1.83154 0.952846 2.72231 0.952846H9.93492V3.94932C9.93492 5.1069 10.8735 6.05015 12.031 6.05015H14.794V9.77068C14.6512 9.76597 14.5368 9.75168 14.413 9.75168C12.7504 9.75168 11.2212 10.3901 10.0683 11.3906H4.01817C3.75607 11.3906 3.54175 11.6049 3.54175 11.8668C3.54175 12.1289 3.75607 12.3432 4.01817 12.3432H9.18227C8.84396 12.8197 8.56285 13.2961 8.34382 13.8201H4.01817C3.75607 13.8201 3.54175 14.0344 3.54175 14.2965C3.54175 14.5585 3.75607 14.773 4.01817 14.773H8.03411C7.915 15.2494 7.8531 15.7781 7.8531 16.3069C7.8531 17.1643 8.01981 18.0123 8.31993 18.727H2.72231V18.7221ZM14.4083 21.9092C11.3213 21.9092 8.81065 19.3985 8.81065 16.3116C8.81065 13.2246 11.3164 10.7139 14.4083 10.7139C17.5 10.7139 20.0057 13.2246 20.0057 16.3116C20.0057 19.3985 17.4953 21.9092 14.4083 21.9092Z" fill="#395061" />
                                <path d="M4.01842 9.95675H8.8442C9.10631 9.95675 9.32063 9.74226 9.32063 9.48033C9.32063 9.21823 9.10631 9.00391 8.8442 9.00391H4.01842C3.75631 9.00391 3.54199 9.21823 3.54199 9.48033C3.54199 9.74226 3.75631 9.95675 4.01842 9.95675Z" fill="#395061" />
                                <path d="M16.8468 16.2117L14.889 18.3221V13.12C14.889 12.8579 14.6745 12.6436 14.4126 12.6436C14.1505 12.6436 13.9361 12.8579 13.9361 13.12V18.3221L11.9638 16.2117C11.7828 16.0212 11.478 16.0069 11.2874 16.188C11.0968 16.369 11.0825 16.6691 11.2637 16.8597L14.0505 19.8562C14.141 19.9514 14.2649 20.0086 14.3983 20.0086C14.5317 20.0086 14.6555 19.9514 14.746 19.8562L17.5377 16.8597C17.7188 16.6691 17.7092 16.3643 17.5186 16.188C17.3232 16.0069 17.028 16.0212 16.8468 16.2117Z" fill="#395061" />
                            </svg>
                        </button>
                    </td>
                </tr> */}

          {/* <tr>
                    <td>Complete Digital Marketing Course to Become Professional</td>
                    <td className="fw-normal">22 February, 2022</td>
                    <td>$12.99</td>
                    <td>
                        <span className="d-block rounded-pill text-center" style={{ width: '75px', backgroundColor: '#f5edde', color: '#FFBE40', fontSize: '0.7rem', paddingTop: '6px', paddingBottom: '6px' }}>
                            Pending
                        </span>
                    </td>
                    <td>
                        <button className="btn rounded-pill d-flex justify-content-center align-items-center" style={{ backgroundColor: '#f4edf2', height: '33px', width: '33px' }}>
                            <svg width="17" height="20" viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.7469 9.89467V5.56902C15.7469 5.44521 15.6897 5.33081 15.6087 5.2403L10.7591 0.147705C10.6686 0.0524902 10.5399 0 10.4113 0H2.72231C1.3028 0 0.168945 1.15758 0.168945 2.57725V17.1167C0.168945 18.5364 1.3028 19.6749 2.72231 19.6749H8.79164C9.93963 21.5805 12.0263 22.8571 14.4034 22.8571C18.0144 22.8571 20.9633 19.9226 20.9633 16.3069C20.9682 13.1484 18.7004 10.5092 15.7469 9.89467ZM10.8878 1.67219L14.1415 5.09731H12.031C11.4022 5.09731 10.8878 4.57816 10.8878 3.94932V1.67219ZM2.72231 18.7221C1.83154 18.7221 1.12179 18.0075 1.12179 17.1167V2.57725C1.12179 1.68161 1.83154 0.952846 2.72231 0.952846H9.93492V3.94932C9.93492 5.1069 10.8735 6.05015 12.031 6.05015H14.794V9.77068C14.6512 9.76597 14.5368 9.75168 14.413 9.75168C12.7504 9.75168 11.2212 10.3901 10.0683 11.3906H4.01817C3.75607 11.3906 3.54175 11.6049 3.54175 11.8668C3.54175 12.1289 3.75607 12.3432 4.01817 12.3432H9.18227C8.84396 12.8197 8.56285 13.2961 8.34382 13.8201H4.01817C3.75607 13.8201 3.54175 14.0344 3.54175 14.2965C3.54175 14.5585 3.75607 14.773 4.01817 14.773H8.03411C7.915 15.2494 7.8531 15.7781 7.8531 16.3069C7.8531 17.1643 8.01981 18.0123 8.31993 18.727H2.72231V18.7221ZM14.4083 21.9092C11.3213 21.9092 8.81065 19.3985 8.81065 16.3116C8.81065 13.2246 11.3164 10.7139 14.4083 10.7139C17.5 10.7139 20.0057 13.2246 20.0057 16.3116C20.0057 19.3985 17.4953 21.9092 14.4083 21.9092Z" fill="#395061" />
                                <path d="M4.01842 9.95675H8.8442C9.10631 9.95675 9.32063 9.74226 9.32063 9.48033C9.32063 9.21823 9.10631 9.00391 8.8442 9.00391H4.01842C3.75631 9.00391 3.54199 9.21823 3.54199 9.48033C3.54199 9.74226 3.75631 9.95675 4.01842 9.95675Z" fill="#395061" />
                                <path d="M16.8468 16.2117L14.889 18.3221V13.12C14.889 12.8579 14.6745 12.6436 14.4126 12.6436C14.1505 12.6436 13.9361 12.8579 13.9361 13.12V18.3221L11.9638 16.2117C11.7828 16.0212 11.478 16.0069 11.2874 16.188C11.0968 16.369 11.0825 16.6691 11.2637 16.8597L14.0505 19.8562C14.141 19.9514 14.2649 20.0086 14.3983 20.0086C14.5317 20.0086 14.6555 19.9514 14.746 19.8562L17.5377 16.8597C17.7188 16.6691 17.7092 16.3643 17.5186 16.188C17.3232 16.0069 17.028 16.0212 16.8468 16.2117Z" fill="#395061" />
                            </svg>
                        </button>
                    </td>
                </tr> */}

          {/* <tr>
                    <td>Complete Digital Marketing Course to Become Professional</td>
                    <td className="fw-normal">22 February, 2022</td>
                    <td>$12.99</td>
                    <td>
                        <span className="d-block rounded-pill text-center" style={{ width: '75px', backgroundColor: '#c8f9d7', color: '#29D363', fontSize: '0.7rem', paddingTop: '6px', paddingBottom: '6px' }}>
                            Confirmed
                        </span>
                    </td>
                    <td>
                        <button className="btn rounded-pill d-flex justify-content-center align-items-center" style={{ backgroundColor: '#f4edf2', height: '33px', width: '33px' }}>
                            <svg width="17" height="20" viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.7469 9.89467V5.56902C15.7469 5.44521 15.6897 5.33081 15.6087 5.2403L10.7591 0.147705C10.6686 0.0524902 10.5399 0 10.4113 0H2.72231C1.3028 0 0.168945 1.15758 0.168945 2.57725V17.1167C0.168945 18.5364 1.3028 19.6749 2.72231 19.6749H8.79164C9.93963 21.5805 12.0263 22.8571 14.4034 22.8571C18.0144 22.8571 20.9633 19.9226 20.9633 16.3069C20.9682 13.1484 18.7004 10.5092 15.7469 9.89467ZM10.8878 1.67219L14.1415 5.09731H12.031C11.4022 5.09731 10.8878 4.57816 10.8878 3.94932V1.67219ZM2.72231 18.7221C1.83154 18.7221 1.12179 18.0075 1.12179 17.1167V2.57725C1.12179 1.68161 1.83154 0.952846 2.72231 0.952846H9.93492V3.94932C9.93492 5.1069 10.8735 6.05015 12.031 6.05015H14.794V9.77068C14.6512 9.76597 14.5368 9.75168 14.413 9.75168C12.7504 9.75168 11.2212 10.3901 10.0683 11.3906H4.01817C3.75607 11.3906 3.54175 11.6049 3.54175 11.8668C3.54175 12.1289 3.75607 12.3432 4.01817 12.3432H9.18227C8.84396 12.8197 8.56285 13.2961 8.34382 13.8201H4.01817C3.75607 13.8201 3.54175 14.0344 3.54175 14.2965C3.54175 14.5585 3.75607 14.773 4.01817 14.773H8.03411C7.915 15.2494 7.8531 15.7781 7.8531 16.3069C7.8531 17.1643 8.01981 18.0123 8.31993 18.727H2.72231V18.7221ZM14.4083 21.9092C11.3213 21.9092 8.81065 19.3985 8.81065 16.3116C8.81065 13.2246 11.3164 10.7139 14.4083 10.7139C17.5 10.7139 20.0057 13.2246 20.0057 16.3116C20.0057 19.3985 17.4953 21.9092 14.4083 21.9092Z" fill="#395061" />
                                <path d="M4.01842 9.95675H8.8442C9.10631 9.95675 9.32063 9.74226 9.32063 9.48033C9.32063 9.21823 9.10631 9.00391 8.8442 9.00391H4.01842C3.75631 9.00391 3.54199 9.21823 3.54199 9.48033C3.54199 9.74226 3.75631 9.95675 4.01842 9.95675Z" fill="#395061" />
                                <path d="M16.8468 16.2117L14.889 18.3221V13.12C14.889 12.8579 14.6745 12.6436 14.4126 12.6436C14.1505 12.6436 13.9361 12.8579 13.9361 13.12V18.3221L11.9638 16.2117C11.7828 16.0212 11.478 16.0069 11.2874 16.188C11.0968 16.369 11.0825 16.6691 11.2637 16.8597L14.0505 19.8562C14.141 19.9514 14.2649 20.0086 14.3983 20.0086C14.5317 20.0086 14.6555 19.9514 14.746 19.8562L17.5377 16.8597C17.7188 16.6691 17.7092 16.3643 17.5186 16.188C17.3232 16.0069 17.028 16.0212 16.8468 16.2117Z" fill="#395061" />
                            </svg>
                        </button>
                    </td>
                </tr> */}

          {/* <tr>
                    <td>Complete Digital Marketing Course to Become Professional</td>
                    <td className="fw-normal">22 February, 2022</td>
                    <td>$12.99</td>
                    <td>
                        <span className="d-block rounded-pill text-center" style={{ width: '75px', backgroundColor: '#f6e1e1', color: '#F75656', fontSize: '0.7rem', paddingTop: '6px', paddingBottom: '6px' }}>
                            Cancelled
                        </span>
                    </td>
                    <td>
                        <button className="btn rounded-pill d-flex justify-content-center align-items-center" style={{ backgroundColor: '#f4edf2', height: '33px', width: '33px' }}>
                            <svg width="17" height="20" viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.7469 9.89467V5.56902C15.7469 5.44521 15.6897 5.33081 15.6087 5.2403L10.7591 0.147705C10.6686 0.0524902 10.5399 0 10.4113 0H2.72231C1.3028 0 0.168945 1.15758 0.168945 2.57725V17.1167C0.168945 18.5364 1.3028 19.6749 2.72231 19.6749H8.79164C9.93963 21.5805 12.0263 22.8571 14.4034 22.8571C18.0144 22.8571 20.9633 19.9226 20.9633 16.3069C20.9682 13.1484 18.7004 10.5092 15.7469 9.89467ZM10.8878 1.67219L14.1415 5.09731H12.031C11.4022 5.09731 10.8878 4.57816 10.8878 3.94932V1.67219ZM2.72231 18.7221C1.83154 18.7221 1.12179 18.0075 1.12179 17.1167V2.57725C1.12179 1.68161 1.83154 0.952846 2.72231 0.952846H9.93492V3.94932C9.93492 5.1069 10.8735 6.05015 12.031 6.05015H14.794V9.77068C14.6512 9.76597 14.5368 9.75168 14.413 9.75168C12.7504 9.75168 11.2212 10.3901 10.0683 11.3906H4.01817C3.75607 11.3906 3.54175 11.6049 3.54175 11.8668C3.54175 12.1289 3.75607 12.3432 4.01817 12.3432H9.18227C8.84396 12.8197 8.56285 13.2961 8.34382 13.8201H4.01817C3.75607 13.8201 3.54175 14.0344 3.54175 14.2965C3.54175 14.5585 3.75607 14.773 4.01817 14.773H8.03411C7.915 15.2494 7.8531 15.7781 7.8531 16.3069C7.8531 17.1643 8.01981 18.0123 8.31993 18.727H2.72231V18.7221ZM14.4083 21.9092C11.3213 21.9092 8.81065 19.3985 8.81065 16.3116C8.81065 13.2246 11.3164 10.7139 14.4083 10.7139C17.5 10.7139 20.0057 13.2246 20.0057 16.3116C20.0057 19.3985 17.4953 21.9092 14.4083 21.9092Z" fill="#395061" />
                                <path d="M4.01842 9.95675H8.8442C9.10631 9.95675 9.32063 9.74226 9.32063 9.48033C9.32063 9.21823 9.10631 9.00391 8.8442 9.00391H4.01842C3.75631 9.00391 3.54199 9.21823 3.54199 9.48033C3.54199 9.74226 3.75631 9.95675 4.01842 9.95675Z" fill="#395061" />
                                <path d="M16.8468 16.2117L14.889 18.3221V13.12C14.889 12.8579 14.6745 12.6436 14.4126 12.6436C14.1505 12.6436 13.9361 12.8579 13.9361 13.12V18.3221L11.9638 16.2117C11.7828 16.0212 11.478 16.0069 11.2874 16.188C11.0968 16.369 11.0825 16.6691 11.2637 16.8597L14.0505 19.8562C14.141 19.9514 14.2649 20.0086 14.3983 20.0086C14.5317 20.0086 14.6555 19.9514 14.746 19.8562L17.5377 16.8597C17.7188 16.6691 17.7092 16.3643 17.5186 16.188C17.3232 16.0069 17.028 16.0212 16.8468 16.2117Z" fill="#395061" />
                            </svg>
                        </button>
                    </td>
                </tr> */}
        </table>
      </div>
    );
}

export default PaymentHistory