import React, { useState } from "react";
import { useRecoilState } from "recoil";
import swal from "sweetalert";
import { API_CALL } from "../../../../../../api/ApiServiceCall";
import { API_CONSTANTS, API_METHOD } from "../../../../../../common/constants";
import { refetchAssessmentTitleListAtomFunc } from "../../../../../atom/refetchAssessmentTitleListAtom";
import { cancelAssessmentName } from "../liveCourseTab";

function CreateAssessmentName() {
  const { setIsCancelAssessmentName } = React.useContext(cancelAssessmentName);
  const [assessmentTitle, setAssessmentTitle] = useState("");
  const [refetchAssessmentTitleListAtom, setRefetchAssessmentTitleListAtom] =
    useRecoilState(refetchAssessmentTitleListAtomFunc);
  const [loading, setLoading] = useState(false);

  const createAssessmentTitle = async (e) => {
    e.preventDefault();
    const courseCode = localStorage.getItem("courseCode");
    setLoading(true);
    let requestBodyParam = JSON.stringify({
      courseCode: courseCode,
      assessmentTitle: assessmentTitle,
    });
    // alert(requestBodyParam)
    const request = {
      apiUrl:
        API_CONSTANTS.BASE_URL + API_CONSTANTS.CREATE_LIVE_COURSE_ASSESSMENT,
      method: API_METHOD.POST,
      requestBodyParams: requestBodyParam,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      setLoading(false);
      setIsCancelAssessmentName(false);
      setRefetchAssessmentTitleListAtom(result.data);
    } else if (result.status > 200) {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
      setLoading(false);
    }
  };

  return (
    <form className="my-3" onSubmit={createAssessmentTitle}>
      <input
        type={"text"}
        placeholder="Enter the Assessment Title here"
        className="default-inpt"
        onChange={(e) => setAssessmentTitle(e.target.value)}
        required
      />
      <div className="d-flex justify-content-end align-items-center">
        <button
          type="button"
          onClick={() => setIsCancelAssessmentName(false)}
          className="px-3 border-0 bg-secondary text-white fz-14px py-2 mx-2 rounded-3"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="px-3 my-3 border-0 bg-prime text-white fz-14px py-2 rounded-3"
          disabled={loading}
        >
          {loading ? "Please Wait..." : "Done"}
        </button>
      </div>
    </form>
  );
}

export default CreateAssessmentName;
