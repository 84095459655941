import React from "react";

function CloseFileIcon({ size }) {
  return (
    <svg
      width={size}
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.3" cx="19" cy="19" r="15" fill="#F75656" />
      <path
        d="M21.7445 23.7091L14.794 16.7585L16.3709 15.1816L23.3215 22.1322L21.7445 23.7091ZM16.3709 23.7091L14.794 22.1322L21.7445 15.1816L23.3215 16.7586L16.3709 23.7091Z"
        fill="#F75656"
      />
    </svg>
  );
}

export default CloseFileIcon;
