import React, { useEffect, useState } from "react";
import { Container, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { API_CONSTANTS } from "../../common/constants";

function VerifyOTP({ setSelected, getEmailid }) {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const resendOtp = async () => {
    setSeconds(60);
    setLoading(true);
    const resendOtptoEmail = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: getEmailid,
        userType: "STUDENT",
      }),
    };
    console.log(resendOtptoEmail);
    fetch(API_CONSTANTS.BASE_URL + API_CONSTANTS.RESEND_OTP, resendOtptoEmail)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 200) {
          setLoading(false);
          setSeconds(60);
        } else if (result.status > 200) {
          setError(result.message);
          setLoading(false);
        }
        console.log("Success Resend:", result);
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
      });
  };

  // ======
  const onSubmit = async (data) => {
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: getEmailid,
        code: data.otp,
        userType: "STUDENT",
      }),
    };
    //  console.log("option check",requestOptions);
    fetch(
      API_CONSTANTS.BASE_URL + API_CONSTANTS.VERIFY_PASSWORD_OTP,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 200) {
          console.log(result.data);
          setLoading(false);
          setSelected();
          // reset();
        } else if (result.status > 200) {
          setError(result.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(60);
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <Container>
      <Modal.Header closeButton className="mh">
        <Modal.Title className="mdlt">Verify It’s You</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="fz-16px pb-1 text-black">
          We Sent 6 Digit OTP to {getEmailid}
        </p>
        <form className="login-col" onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="otp">Enter 6 Digit OTP Code</label>
          <input
            type="number"
            placeholder="Enter 6 Digit OTP Code"
            id="otp"
            className=" mb-2 "
            {...register("otp", { required: true })}
          />
          <small className="text-danger mb-2 d-block fz-12px fw-500">
            {" "}
            {errors.otp?.type === "required" &&
              "Please check your Email enter OTP"}
          </small>
          <div className="d-flex align-items-center justify-content-end mt-2 mb-3 otp">
            {" "}
            {minutes === 0 && seconds === 0 ? null : (
              <h6 className="m-0">
                {" "}
                {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
              </h6>
            )}
            <button
              role="button"
              onClick={resendOtp}
              disabled={seconds === 0 ? false : true}
              className="ms-2 mb-0 w-auto border-0 bg-transparent"
            >
              Resend
            </button>
          </div>
          <button className="login-form-btn" disabled={loading ? true : false}>
            {loading ? "Please wait..." : "Verify"}
          </button>
          <small className="text-danger mb-2 d-block fz-15px fw-500 text-center">
            {" "}
            {error}
          </small>
        </form>
      </Modal.Body>
    </Container>
  );
}

export default VerifyOTP;
