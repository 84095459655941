import React, { useEffect, useState } from "react";
import { Accordion, Card, useAccordionButton } from "react-bootstrap";
import { BiMessageDetail } from "react-icons/bi";
import { Link } from "react-router-dom";
import { API_CALL } from "../../../api/ApiServiceCall";
import { API_CONSTANTS, API_METHOD } from "../../../common/constants";
import "../../../css/live-class.css";
import MyS3Component from "../../s3/s3";

function StudentLearnersList() {
  const [studentList, setStudentList] = useState([]);
  const [studentListRestore, setStudentListRestore] = useState([]);
  const [courseName, setcourseName] = useState(
    JSON.parse(localStorage.getItem("currentUserCode"))
  );
  const [searchString, setSearchString] = useState("");
  useEffect(() => {
    getAllStudentList();
  }, []);
  const getAllStudentList = async () => {
    // const userCode = JSON.parse(localStorage.getItem('currentUserCode'));
    const request = {
      apiUrl:
        API_CONSTANTS.BASE_URL +
        API_CONSTANTS.EACH_COURSE_LEARNERS_LIST +
        `/?courseCode=${courseName.currentCourseCode}`,
      method: API_METHOD.GET,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      setStudentList(result.data);
      setStudentListRestore(result.data);
    } else if (result.status > 200) {
      console.log("errror", result);
    }
  };

  const handleSearchChange = (searchData) => {
    setSearchString(searchData);
    const searchedName = studentList
      .filter((item) =>
        item.learnerEmail
          .toString()
          .toLowerCase()
          .includes(searchData.toString().toLowerCase())
      )
      .map((filteredName) => filteredName);
    setStudentList(searchedName);
    if (searchData === "") {
      setStudentList(studentListRestore);
    }
  };

  return (
    <div className="container liv-acc px-lg-5">
      <div className="py-3"></div>
      <div className="d-flex justify-content-end align-items-center">
        <small className="fz-13px text-muted fw-500 mb-3">
          {" "}
          TOTAL LEARNERS - {studentList.length}
        </small>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <h6 className="m-0 fw-500">
          {" "}
          <Link to={"/courses/recorded"} className=" text-dark">
            Recorded Courses
          </Link>{" "}
          /{" "}
          <span className="text-muted">
            {courseName.currentCourseCourseName}
          </span>{" "}
        </h6>

        <div className="srch-bg ms-lg-3">
          <input
            className="srch-ui"
            type="search"
            placeholder="Search learners by name"
            value={searchString}
            onChange={(e) => handleSearchChange(e.target.value)}
          />
        </div>
      </div>

      {studentList.length != 0
        ? studentList.map((studDetails, i) => (
            <div key={i}>
              {/* defaultActiveKey={0} */}
              <Accordion className="my-4 ">
                <Card className="border-0 p-3">
                  <Card.Header className="border-0 bg-white ">
                    <CustomToggle studDetails={studDetails} eventKey={i + 1}>
                      <BiMessageDetail size={16} /> Messages
                    </CustomToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey={i}>
                    <Card.Body>
                      <h6>All Details</h6>
                      <p>View Details Will Show here</p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </div>
          ))
        : "No Data"}
    </div>
  );
}

export default StudentLearnersList;

function CustomToggle({ children, eventKey, studDetails }) {
  const decoratedOnClick = useAccordionButton(eventKey, () => console.log());

  return (
    <div className="row">
      <div className="col-lg-6">
        <div className="d-flex">
          {/* <img
            src={API_CONSTANTS.BASE_URL + studDetails.learnerImgPath}
            className="img"
          /> */}

          <MyS3Component
            imageUrl={API_CONSTANTS.BASE_URL + studDetails.learnerImgPath}
            className="img"
          />

          <div className="ms-3">
            <p className="fw-500 mb-2">{studDetails.learnerEmail}</p>
            <p className="text-muted fz-14px mb-2">{studDetails.learnerName}</p>
            <div className="d-flex align-items-center">
              {/* <p className='fz-14px fw-500 text-prime'>{studDetails.courseName}</p> */}
              <p className="fz-14px fw-500 text-prime">Purchase: </p>
              <p className="fz-14px fw-500 text-prime ms-1">
                {studDetails.coursePrice}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-6 m-auto">
        <div className="d-flex justify-content-end">
          <button
            className="border-0 bg-prime text-white px-4  fz-14px py-2 rounded-3"
            type="button"
            onClick={decoratedOnClick}
          >
            {children}
          </button>
        </div>
      </div>
    </div>
  );
}
