import React, { useState } from "react";
import { Container, Modal } from "react-bootstrap";
import { FiTrash2 } from "react-icons/fi";
import { MdModeEdit } from "react-icons/md";
import swal from "sweetalert";
import { API_CALL } from "../../../../api/ApiServiceCall";
import { API_CONSTANTS, API_METHOD } from "../../../../common/constants";
import EditClickedAccountDetail from "./EditClickedAccountDetail";
// import { getCredentialLoginAccountProfileDetailAtom } from '../../../atom/getCredentialLoginAccountProfileDetail';

function AccountLists({ accountList, getPaymentInfo }) {
  const [show, setShow] = useState(false);
  const [editComponent, setEditComponent] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const onDelete = async (accData) => {
    swal({
      title: "Are you sure?",
      text: "You cannot recover deleted data once it has been deleted",
      icon: "https://cdn-icons-png.flaticon.com/512/6460/6460112.png",
      imageClass: "img-warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        const requestBodyParam = JSON.stringify({
          paymentInfoId: accData._id,
        });
        const request = {
          apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.DELETE_PAYMENT_INFO,
          method: API_METHOD.POST,
          requestBodyParams: requestBodyParam,
        };
        let result = await API_CALL(request);
        if (result.status === 200) {
          getPaymentInfo();
        } else if (result.status > 200) {
          swal({
            title: "ERROR",
            text: result.message,
            icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
            className: "error-window",
            button: true,
          });
        }
      }
    });
  };
  const onEdit = (accData) => {
    setEditComponent(
      <EditClickedAccountDetail
        clickedAccData={accData}
        handleClose={handleClose}
        getPaymentInfo={getPaymentInfo}
      />
    );
    handleShow();
  };

  return (
    <div>
      {/* <pre>{JSON.stringify(accountList,null,3)}</pre> */}
      {!accountList ? (
        <div className="d-flex justify-content-center align-items-center">
          <span
            className="spinner-border spinner-border-sm text-secondary"
            role="status"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Loading...</span>
        </div>
      ) : accountList.length ? (
        accountList.map((accList, index) => (
          <div
            className="row m-auto brd-btm py-3 my-3 px-lg-3 align-items-center"
            key={accList._id}
            style={{ borderBottom: "1px solid #f0e1eb" }}
          >
            <div className="col-lg-8">
              <h6 className="text-prime fz-15px ">
                Account name :{" "}
                <span className="text-muted">{accList.accountHolderName}</span>
              </h6>
              <h6 className="text-prime fz-15px">
                Account no :{" "}
                <span className="text-muted fw-400 fz-14px">************</span>
              </h6>
              <h6 className="text-prime fz-15px">
                Bank name :{" "}
                <span className="text-muted">{accList.bankName}</span>
              </h6>
              <h6 className="m-0 text-prime fz-15px">
                IFSC : <span className="text-muted fw-400">*******</span>
              </h6>
            </div>
            <div className="col-lg-4 m-auto">
              <div className="text-end">
                <p
                  className="fw-500 text-prime"
                  role="button"
                  onClick={() => onEdit(accList)}
                >
                  {" "}
                  <MdModeEdit className="mx-1" size={21} />
                  Edit
                </p>
                <p
                  className="del fw-500 m-0"
                  role="button"
                  onClick={() => onDelete(accList)}
                >
                  <FiTrash2 className="mx-1" />
                  Delete
                </p>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="text-center">
          <p>No Payment Info is available</p>
        </div>
      )}
      <Container>
        <Modal
          show={show}
          onHide={handleClose}
          centered
          size="lg"
          backdrop="static"
        >
          <div className="mh">
            <Modal.Header closeButton>
              <Modal.Title>
                <h5 className="m-0 p-0 text-prime fz-19px">
                  Edit: Bank Account Detail
                </h5>
              </Modal.Title>
            </Modal.Header>
          </div>
          <Modal.Body>{editComponent ? editComponent : ""}</Modal.Body>
        </Modal>
      </Container>
    </div>
  );
}

export default AccountLists;
