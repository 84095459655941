import React from "react";

function TableMessageIcon({ width }) {
  return (
    <svg
      width={width}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="20" fill="#F0E1EB" />
      <g clipPath="url(#clip0_1022_4178)">
        <path
          d="M21.4327 21.9317C21.0062 22.216 20.5108 22.3663 20 22.3663C19.4893 22.3663 18.9939 22.216 18.5674 21.9317L11.5427 17.2484C11.5038 17.2224 11.4658 17.1953 11.4286 17.1675V24.8415C11.4286 25.7213 12.1426 26.4196 13.0067 26.4196H26.9933C27.8731 26.4196 28.5714 25.7056 28.5714 24.8415V17.1674C28.5341 17.1954 28.4961 17.2225 28.457 17.2485L21.4327 21.9317Z"
          fill="#395061"
        />
        <path
          d="M12.0999 16.4126L19.1246 21.0959C19.3905 21.2732 19.6952 21.3618 20 21.3618C20.3048 21.3618 20.6096 21.2732 20.8755 21.0959L27.9001 16.4126C28.3205 16.1326 28.5714 15.6638 28.5714 15.1579C28.5714 14.288 27.8637 13.5803 26.9939 13.5803H13.0062C12.1363 13.5804 11.4286 14.288 11.4286 15.1587C11.4286 15.6638 11.6796 16.1326 12.0999 16.4126Z"
          fill="#395061"
        />
      </g>
      <defs>
        <clipPath id="clip0_1022_4178">
          <rect
            width="17.1429"
            height="17.1429"
            fill="white"
            transform="translate(11.4286 11.4286)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default TableMessageIcon;
