import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useResetRecoilState } from "recoil";
import swal from "sweetalert";
import { API_CALL } from "../../api/ApiServiceCall";
import { API_CONSTANTS, API_METHOD } from "../../common/constants";
import { RichTextState } from "./course-steps/atom-rich-textbox-value.js/rich-txt-atom";
import Step1CourseDetail from "./course-steps/step1";
import Step2CourseDetail from "./course-steps/step2";
import Step3CourseDetail from "./course-steps/step3";

function Courses() {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState("step1");
  const [formData, setFormData] = useState("");
  const [loading, setLoading] = useState("");
  const [currencyList, setCurrencyList] = useState([]);
  const [thumbnailImageData, setThumbnailImageData] = useState("");
  const [introVideoData, setIntroVideoData] = useState("");
  const [richTextValue, setRichTextValue] = useRecoilState(RichTextState); //Recoil getting state value from step1
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm();

  useEffect(() => {
    resetRichtextbox();
  }, []);

  const resetRichtextbox = useResetRecoilState(RichTextState);
  const onSubmit = async (data) => {
    // console.log(data)
    setLoading(true);
    // setFormData({ ...data, richtext: richTextValue, thumbnailImage: data.thumbnailImage[0] });
    const inputData = {
      ...data,
      currency: "INR",
      courseOverview: richTextValue,
    };
    const formData = new FormData();
    Object.entries(inputData).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append("subCategory", selectedSubCategory);
    formData.append("userType", "INSTRUCTOR");
    formData.append("courseType", "IT");
    formData.append("thumbnail", thumbnailImageData);
    formData.append("thumbnail", introVideoData);
    for (var pair of formData.entries()) {
      console.log("CreateCourse=======>", pair[0] + ", " + pair[1]);
    }
    let requestBodyParam = formData;
    const usrdetailforHeader = JSON.parse(
      localStorage.getItem("localUserData")
    );
    let apiHeader = {
      "x-auth-token": usrdetailforHeader?.jwt,
    };
    if (usrdetailforHeader?.data?.user?.email) {
      apiHeader = {
        token: usrdetailforHeader?.data?.user?.email,
        gmailUserType: "INSTRUCTOR",
      };
    }
    const request = {
      url: API_CONSTANTS.BASE_URL + API_CONSTANTS.CREATE_COURSE,
      method: API_METHOD.POST,
      onUploadProgress: (progressEvent) => {
        let percentComplete = progressEvent.loaded / progressEvent.total;
        percentComplete = parseInt(percentComplete * 100);
        // console.log(percentComplete)
        setLoading(percentComplete);
      },
      headers: apiHeader,
      data: requestBodyParam,
    };
    axios(request)
      .then((response) => {
        if (response.status == 200) {
          setLoading(false);
          navigate("/courses/recorded");
        }
      })
      .catch((error) => {
        swal({
          title: "ERROR",
          text: error?.response?.data?.message,
          icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
          className: "error-window",
          button: true,
        });
        setLoading(false);
      });
    // let result = await API_CALL(request)
    // if (result.status === 200) {
    //   setLoading(false);
    //   navigate('/courses/recorded')

    // } else if (result.status > 200) {
    //   swal(result.message)
    //   setLoading(false);
    // }
  };
  useEffect(() => {
    if (selectedSubCategory) {
      getPriceBySubCategory(selectedSubCategory);
    }
  }, [selectedSubCategory]);

  const getPriceBySubCategory = async (subCatCode) => {
    const request = {
      apiUrl:
        API_CONSTANTS.BASE_URL +
        API_CONSTANTS.GET_FEES_BY_SUB_CATEGORY_CODE(subCatCode),
      method: API_METHOD.GET,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      setCurrencyList(result.data);
    } else if (result.status > 200) {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }
  };
  return (
    <div className="container">
      {/* {JSON.stringify(formData,null,2)} */}
      {/* {richTextValue} */}
      <div className=" py-6">
        <ul className="list-unstyled multi-steps">
          {/* <li onClick={() => setCurrentStep("step1")} role="button" className="is-active">Course Details</li>
          <li onClick={() => setCurrentStep("step2")} role="button" className={currentStep === "step2" || currentStep === "step3" ? "is-active" : ""}>Pricing</li>
          <li onClick={() => setCurrentStep("step3")} role="button" className={currentStep === "step3" ? "is-active" : ""}>Thumbnail &amp; Intro</li> */}
          <li className="is-active">Course Details</li>
          <li
            className={
              currentStep === "step2" || currentStep === "step3"
                ? "is-active"
                : ""
            }
          >
            Pricing
          </li>
          <li className={currentStep === "step3" ? "is-active" : ""}>
            Thumbnail &amp; Intro
          </li>
        </ul>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={currentStep === "step1" ? "d-block" : "d-none"}>
          {" "}
          <Step1CourseDetail
            selectedSubCategory={selectedSubCategory}
            setSelectedSubCategory={setSelectedSubCategory}
            watch={watch}
            register={register}
            setCurrentStep={(getStep) => setCurrentStep(getStep)}
          />
        </div>
        <div className={currentStep === "step2" ? "d-block" : "d-none"}>
          <Step2CourseDetail
            currencyList={currencyList}
            watch={watch}
            register={register}
            setCurrentStep={(getStep) => setCurrentStep(getStep)}
          />
        </div>
        <div className={currentStep === "step3" ? "d-block" : "d-none"}>
          <Step3CourseDetail
            setThumbnailImageData={setThumbnailImageData}
            setIntroVideoData={setIntroVideoData}
            register={register}
            setCurrentStep={(getStep) => setCurrentStep(getStep)}
            loading={loading}
          />
        </div>
      </form>
    </div>
  );
}

export default Courses;
