import { useState } from 'react'
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from 'react-icons/md';

function FilterAccordion({ title, data, handleSubCategorySelect }) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="my-3">
            <button className="btn px-0 d-block w-100 text-start d-flex justify-content-between" onClick={() => setIsOpen(!isOpen)}>
                <span style={{ color: isOpen ? '#395061' : 'gray' }}>{title}</span>
                {isOpen ? <MdOutlineKeyboardArrowUp size="1.3rem" color="#395061" /> : <MdOutlineKeyboardArrowDown size="1.3rem" color="gray" />}
            </button>
            {isOpen && (
                <div className="mt-2">
                    {data.map((item, index) => (
                        <div key={index} className="d-flex align-items-center my-1">
                            <input type="checkbox" id={item.name} name={item.name} value={item.value} className="mr-2 inline-block" onChange={handleSubCategorySelect} />
                            <label htmlFor={item.name} className="ms-2" style={{ fontSize: '0.7rem', color: '#395061' }}>
                                {item.label}
                            </label>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

export default FilterAccordion