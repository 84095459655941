import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { MdModeEdit } from "react-icons/md";
import swal from "sweetalert";
import { API_CALL } from "../../../../../api/ApiServiceCall";
import { API_CONSTANTS, API_METHOD } from "../../../../../common/constants";
import EditFaqLiveCourse from "./editFaqLiveCourse";

function CreateLiveCourseFaq() {
  const [createFaq, setCreateFaq] = useState(false);
  const [loading, setLoading] = useState(false);
  const [faqData, setFaqData] = useState("");
  const [formData, setFormData] = useState([]);
  const [IsFaqActiveIndex, setIsFaqActiveIndex] = useState(-1);
  const {
    register,
    reset,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm();
  // const [faq, setFaq] = useState();

  React.useEffect(() => {
    getAllFaq();
  }, []);

  const getAllFaq = async () => {
    const userCode = localStorage.getItem("courseCode");
    const request = {
      apiUrl:
        API_CONSTANTS.BASE_URL +
        API_CONSTANTS.GET_LIVE_COURSE_BY_COURSE_CODE +
        `/?courseCode=${userCode}`,
      method: API_METHOD.GET,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      setFaqData(result);
    } else if (result.status > 200) {
      // swal('Error', result.message)
    }
  };
  const onDelete = async (data) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover",
      icon: "https://cdn-icons-png.flaticon.com/512/6460/6460112.png",
      imageClass: "img-warning",
      animation: false,
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        const courseCode = localStorage.getItem("courseCode");
        let requestBodyParam = JSON.stringify({
          courseCode: courseCode,
          faqOrder: data,
        });
        const request = {
          apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.DELETE_LIVE_COURSE_FAQ,
          method: API_METHOD.POST,
          requestBodyParams: requestBodyParam,
        };
        let result = await API_CALL(request);
        if (result.status === 200) {
          getAllFaq();
        } else if (result.status > 200) {
          swal({
            title: "ERROR",
            text: result.message,
            icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
            className: "error-window",
            button: true,
          });
        }
      }
    });
  };
  const onSubmit = async (data) => {
    const userCode = localStorage.getItem("courseCode");
    setLoading(true);
    let requestBodyParam = JSON.stringify({
      question: data.question,
      answer: data.answer,
      courseCode: userCode,
    });
    const request = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.CREATE_LIVE_COURSE_FAQ,
      method: API_METHOD.POST,
      requestBodyParams: requestBodyParam,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      reset();
      setCreateFaq(false);
      setLoading(false);
      getAllFaq();
      // reset();
    } else if (result.status > 200) {
      // setError(result.message);
      setLoading(false);
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }
  };
  let isBlock = true;
  if (faqData) {
    if (faqData.data[0].courseStatus == "INREVIEW") {
      isBlock = true;
    } else {
      isBlock = false;
    }
  }
  const onCreateFaq = () => {
    reset();
    setCreateFaq(true);
  };
  return (
    <div className="faq-sec">
      {/* <pre>{JSON.stringify(faqData,null,4)}</pre> */}

      <div className="container overview-card">
        <Accordion defaultActiveKey="00" flush>
          {faqData
            ? faqData?.data[0]?.faqList.map((datas, i) => {
                const isFaqActive = i == IsFaqActiveIndex;
                return (
                  <Accordion.Item
                    key={i}
                    eventKey={i}
                    className="position-relative"
                  >
                    <div className="d-flex justify-content-end pt-2">
                      {isBlock ? (
                        ""
                      ) : (
                        <div>
                          {" "}
                          {loading ? (
                            <>
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </>
                          ) : (
                            <div>
                              <svg
                                onClick={() => onDelete(datas.faqOrder)}
                                className="adjus-trs"
                                width="17"
                                role="button"
                                height="17"
                                viewBox="0 0 21 25"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M19.8757 4.16667H16.6465C16.4047 2.99105 15.7651 1.93473 14.8353 1.17575C13.9055 0.41676 12.7425 0.00151513 11.5423 0L9.45899 0C8.25877 0.00151513 7.09578 0.41676 6.16602 1.17575C5.23626 1.93473 4.59659 2.99105 4.35482 4.16667H1.12565C0.849384 4.16667 0.584432 4.27641 0.389082 4.47176C0.193731 4.66711 0.0839844 4.93206 0.0839844 5.20833C0.0839844 5.4846 0.193731 5.74955 0.389082 5.9449C0.584432 6.14025 0.849384 6.25 1.12565 6.25H2.16732V19.7917C2.16897 21.1725 2.71824 22.4963 3.69463 23.4727C4.67103 24.4491 5.99482 24.9983 7.37565 25H13.6257C15.0065 24.9983 16.3303 24.4491 17.3067 23.4727C18.2831 22.4963 18.8323 21.1725 18.834 19.7917V6.25H19.8757C20.1519 6.25 20.4169 6.14025 20.6122 5.9449C20.8076 5.74955 20.9173 5.4846 20.9173 5.20833C20.9173 4.93206 20.8076 4.66711 20.6122 4.47176C20.4169 4.27641 20.1519 4.16667 19.8757 4.16667V4.16667ZM9.45899 2.08333H11.5423C12.1884 2.08412 12.8185 2.28477 13.3461 2.65775C13.8737 3.03074 14.273 3.5578 14.4892 4.16667H6.51211C6.72834 3.5578 7.12763 3.03074 7.65522 2.65775C8.18281 2.28477 8.81287 2.08412 9.45899 2.08333V2.08333ZM16.7507 19.7917C16.7507 20.6205 16.4214 21.4153 15.8354 22.0014C15.2493 22.5874 14.4545 22.9167 13.6257 22.9167H7.37565C6.54685 22.9167 5.752 22.5874 5.16594 22.0014C4.57989 21.4153 4.25065 20.6205 4.25065 19.7917V6.25H16.7507V19.7917Z"
                                  fill="#F75656"
                                />
                                <path
                                  d="M8.41667 18.75C8.69293 18.75 8.95788 18.6402 9.15323 18.4449C9.34858 18.2495 9.45833 17.9846 9.45833 17.7083V11.4583C9.45833 11.182 9.34858 10.9171 9.15323 10.7217C8.95788 10.5264 8.69293 10.4166 8.41667 10.4166C8.1404 10.4166 7.87545 10.5264 7.6801 10.7217C7.48475 10.9171 7.375 11.182 7.375 11.4583V17.7083C7.375 17.9846 7.48475 18.2495 7.6801 18.4449C7.87545 18.6402 8.1404 18.75 8.41667 18.75Z"
                                  fill="#F75656"
                                />
                                <path
                                  d="M12.5827 18.75C12.859 18.75 13.1239 18.6402 13.3193 18.4449C13.5146 18.2495 13.6244 17.9846 13.6244 17.7083V11.4583C13.6244 11.182 13.5146 10.9171 13.3193 10.7217C13.1239 10.5264 12.859 10.4166 12.5827 10.4166C12.3064 10.4166 12.0415 10.5264 11.8461 10.7217C11.6508 10.9171 11.541 11.182 11.541 11.4583V17.7083C11.541 17.9846 11.6508 18.2495 11.8461 18.4449C12.0415 18.6402 12.3064 18.75 12.5827 18.75Z"
                                  fill="#F75656"
                                />
                              </svg>{" "}
                              <p
                                className="adjus-faq-edit"
                                role={"button"}
                                onClick={() => setIsFaqActiveIndex(i)}
                              >
                                <MdModeEdit className="mx-1" size={21} />
                              </p>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <Accordion.Header>{datas.question}</Accordion.Header>
                    <Accordion.Body>{datas.answer}</Accordion.Body>

                    {isFaqActive ? (
                      <EditFaqLiveCourse
                        getUpdatedFaq={getAllFaq}
                        question={datas.question}
                        faqOrder={datas.faqOrder}
                        answer={datas.answer}
                        setIsFaqActiveIndex={setIsFaqActiveIndex}
                      />
                    ) : (
                      ""
                    )}
                  </Accordion.Item>
                );
              })
            : "loading"}
        </Accordion>
        {createFaq ? (
          <>
            <div className="login-col">
              <form id="hook-form-faq" onSubmit={handleSubmit(onSubmit)}>
                <input
                  type="text"
                  className="w-100 mb-3"
                  placeholder="Question"
                  onInput={(e) => {
                    if (e.target.value.trim() == "") {
                      e.target.value = "";
                    }
                  }}
                  {...register("question")}
                  autoComplete="off"
                  required
                />
                <textarea
                  cols="6"
                  rows="5"
                  className="w-100 default-inpt"
                  onInput={(e) => {
                    if (e.target.value.trim() == "") {
                      e.target.value = "";
                    }
                  }}
                  placeholder="Answer"
                  {...register("answer")}
                  required
                />
              </form>
            </div>

            <div className="d-flex justify-content-end pt-3">
              <div
                className="border-0 bg-secondary text-white fz-14px py-2 w-14 rounded-3 text-center"
                type="button"
                role="button"
                onClick={() => setCreateFaq(false)}
              >
                Cancel
              </div>
              <button
                className="ms-lg-3 border-0 bg-prime text-white fz-14px py-2 w-14 rounded-3 text-center"
                type="submit"
                form="hook-form-faq"
                disabled={loading ? true : false}
              >
                {loading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Loading...</span>
                  </>
                ) : (
                  "Done"
                )}
              </button>
            </div>
          </>
        ) : null}
        {createFaq ? (
          ""
        ) : (
          <button
            className="border-0 bg-prime text-white mt-3 fz-14px py-2 px-4 rounded-3"
            disabled={isBlock ? true : false}
            onClick={onCreateFaq}
          >
            Create New
          </button>
        )}
      </div>
    </div>
  );
}

export default CreateLiveCourseFaq;
