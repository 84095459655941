import React, { useState } from "react";
import { useRecoilState } from "recoil";
import swal from "sweetalert";
import { API_CALL } from "../../../../../api/ApiServiceCall";
import { API_CONSTANTS, API_METHOD } from "../../../../../common/constants";
import { isSingleLiveCourseEditAtomFunc } from "../../../../atom/isSingleLiveCourseEditAtom";
import { singleLiveCourseDataAtomFunc } from "../../../../atom/singleLiveCourseDataAtom";
import MyS3Component from "../../../../s3/s3";
function Step2LiveCourseEdit() {
  const [singleLiveCourseDataAtom, setSingleLiveCourseDataAtom] =
    useRecoilState(singleLiveCourseDataAtomFunc);
  const [isSingleLiveCourseEditAtom, setIsSingleLiveCourseEditAtom] =
    useRecoilState(isSingleLiveCourseEditAtomFunc);
  const [imageFileValidation, setImageFileValidation] = useState("");
  const [videoValidation, setVideoValidation] = useState("");
  const [liveCourseFormDataStep2, setLiveCourseFormDataStep2] = useState({
    currency: singleLiveCourseDataAtom?.currency,
    fee: singleLiveCourseDataAtom?.fee,
    thumbNail: "",
    introVideo: "",
  });
  const [loading, setLoading] = useState(false);
  const [source, setSource] = useState(
    ""
  );
  const [image, setImage] = useState(
    ""
  );
  const updateStep2 = async () => {
    const courseCode = localStorage.getItem("courseCode");
    setLoading(true);
    // console.log("=====================")
    // console.log(liveCourseFormDataStep2)
    const formData = new FormData();
    formData.append("courseCode", courseCode);
    formData.append("currency", liveCourseFormDataStep2?.currency);
    formData.append("fee", liveCourseFormDataStep2?.fee);
    formData.append("thumbnail", liveCourseFormDataStep2?.thumbNail);
    formData.append("thumbnail", liveCourseFormDataStep2?.introVideo);
    // for (var pair of formData.entries()) {
    //     console.log("CreateCourse=======>", pair);
    // }
    let requestBodyParam = formData;
    const usrdetailforHeader = JSON.parse(
      localStorage.getItem("localUserData")
    );
    let apiHeader = {
      "x-auth-token": usrdetailforHeader?.jwt,
    };
    if (usrdetailforHeader?.data?.user?.email) {
      apiHeader = {
        token: usrdetailforHeader?.data?.user?.email,
        gmailUserType: "INSTRUCTOR",
      };
    }
    const request = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.UPDATE_LIVE_COURSE_VIDEO,
      method: API_METHOD.POST,
      headers: apiHeader,
      requestBodyParams: requestBodyParam,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      setLoading(false);
      setIsSingleLiveCourseEditAtom({
        refetchOnChange: Math.random(),
        edit: false,
      });
    } else if (result.status > 200) {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
      setLoading(false);
    }
  };
  //for video preview
  const handleFileChange = (e) => {
    let file = e.target.files[0];
    const uniqueId = "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
    let fileExtension = file.name.split(".").pop();
    const uniqueFileName = `${uniqueId}.${fileExtension}`;
    file = new File([file], uniqueFileName, { type: file.type });
    if (file.type == "video/mp4") {
      setVideoValidation(false);
      setLiveCourseFormDataStep2({
        ...liveCourseFormDataStep2,
        introVideo: file,
      });
      const url = URL.createObjectURL(file);
      setSource(url);
    } else {
      setVideoValidation("Please select the mp4 format");
    }
  };
  //for Image preview
  async function readImage(e) {
    let file = e.target.files[0];
    const uniqueId = "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
    let fileExtension = file.name.split(".").pop();
    const uniqueFileName = `${uniqueId}.${fileExtension}`;
    file = new File([file], uniqueFileName, { type: file.type });
    console.log(3, { file });

    let fileFormat = fileExtension.toLowerCase();
    if (fileFormat == "png" || fileFormat == "jpeg" || fileFormat == "jpg") {
      setImageFileValidation(false);
      setLiveCourseFormDataStep2({
        ...liveCourseFormDataStep2,
        thumbNail: file,
      });
      const reader = new FileReader();
      reader.onload = function (e) {
        let binaryData = e.target.result;
        let base64String = window.btoa(binaryData);
        setImage(`data:image/jpeg;base64,${base64String}`);
      };
      let image = reader.readAsBinaryString(file);
      return image;
    } else {
      setImageFileValidation("Please select JPG, PNG, or JPEG format");
    }
  }
  const sameWH = {
    height: "350px",
    // objectFit: "cover",
    width: "100%",
    borderRadius: "7px",
  };
  return (
    <div className="container">
      {/* {JSON.stringify(liveCourseFormDataStep2)} */}
      <div className="row">
        <div className="col-6">
          <label className="py-3 fw-500">Currency</label>
          <div className="tag-ajus">
            <select
              defaultValue={liveCourseFormDataStep2.currency}
              onChange={(e) =>
                setLiveCourseFormDataStep2({
                  ...liveCourseFormDataStep2,
                  currency: e.target.value,
                })
              }
            >
              <option value="" disabled>
                Select Currency
              </option>
              <option value="INR">₹</option>
              <option value="USD">$</option>
            </select>
          </div>
        </div>
        <div className="col-6 ">
          <label className="py-3 fw-500">Fee</label>
          <div className="tag-ajus">
            <select
              defaultValue={liveCourseFormDataStep2.fee}
              onChange={(e) =>
                setLiveCourseFormDataStep2({
                  ...liveCourseFormDataStep2,
                  fee: e.target.value,
                })
              }
            >
              <option value="" disabled>
                Select Fee
              </option>
              <option value="1000">1000</option>
              <option value="2000">2000</option>
              <option value="5000">5000</option>
            </select>
          </div>
        </div>
      </div>
      <div className="my-5"></div>
      <div className="row">
        <div className="col">
          <h6>Thumbnail</h6>
          {imageFileValidation ? (
            <small className="text-danger mb-2 d-block fz-12px fw-500">
              {imageFileValidation}
            </small>
          ) : (
            ""
          )}
          <p className="text-muted fz-12px">
            Width 600 px, Height 350px. Format will be JPG, PNG, JPEG.
          </p>

          {singleLiveCourseDataAtom && image ? (
            ""
          ) : (
            <MyS3Component
              imageUrl={singleLiveCourseDataAtom?.thumbNailImagePath}
            />
          )}
          {image ? (
            <img
              src={image}
              alt="uploaded"
              className="mb-3 rounded-3 w-100"
              style={sameWH}
            />
          ) : (
            ""
          )}
          <input
            className={singleLiveCourseDataAtom ? "d-none" : "file-prim"}
            id="change_image"
            type="file"
            accept="image/gif, image/jpeg, image/png"
            onChange={(event) => {
              readImage(event);
            }}
          />
          {singleLiveCourseDataAtom ? (
            <label
              htmlFor="change_image"
              className="w-100 py-2 text-center text-white fz-13px bg-secondary rounded-2"
              role="button"
            >
              Change Image
            </label>
          ) : (
            ""
          )}
        </div>

        <div className="col mx-2 px-0">
          <h6>Intro Video</h6>
          {videoValidation ? (
            <small className="text-danger mb-2 d-block fz-12px fw-500">
              {videoValidation}
            </small>
          ) : (
            ""
          )}
          <p className="text-muted fz-12px">
            Width 600 px, Height 350px. Format will be mp4.
          </p>
          {singleLiveCourseDataAtom && source ? (
            ""
          ) : (
            <MyS3Component
              imageUrl={singleLiveCourseDataAtom?.introVideoPath}
              className="VideoInput_video"
              style={sameWH}
              width="100%"
              height="auto"
              controls
            />
          )}
          {source ? (
            <>
              {" "}
              <video
                className="VideoInput_video"
                style={sameWH}
                width="100%"
                height="auto"
                controls
                src={source}
              />
            </>
          ) : (
            ""
          )}

          <input
            className={
              singleLiveCourseDataAtom ? "d-none file-prim" : "file-prim"
            }
            id="change_video"
            type="file"
            onChange={handleFileChange}
            accept=".mp4"
          />
          {singleLiveCourseDataAtom ? (
            <label
              htmlFor="change_video"
              className="w-100 py-2 text-center text-white fz-13px bg-secondary rounded-2"
              role="button"
            >
              Change intro Video
            </label>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="py-3"></div>
      <div className="d-flex justify-content-end">
        <button
          className="border-0 bg-secondary text-white fz-14px py-2 px-4 mx-2  rounded-3 text-center"
          type="button"
          disabled={loading ? true : false}
          onClick={() =>
            setIsSingleLiveCourseEditAtom({
              ...isSingleLiveCourseEditAtom,
              edit: false,
            })
          }
        >
          Cancel
        </button>
        <button
          type="button"
          className=" border-0 bg-prime text-white fz-14px py-12px w-14 rounded-3 text-center"
          onClick={updateStep2}
          disabled={loading ? true : false}
        >
          {loading ? "Please wait..." : "Save"}
        </button>
      </div>
      <div className="py-3"></div>
    </div>
  );
}

export default Step2LiveCourseEdit;
