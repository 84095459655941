import { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import ReactFlagsSelect from "react-flags-select";
import swal from 'sweetalert';
import Modal from 'react-bootstrap/Modal';
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom'
import Select from 'react-select';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { API_CALL } from '../../api/ApiServiceCall';
import { API_CONSTANTS, API_METHOD, PICTURE_STORE } from '../../common/constants';
import qlearningLogo from './../../images/student/qlearning-logo.png';
import paypalImage from './../../images/student/paypal.png';
import upiImage from './../../images/student/upi.png';
import masterCardImage from './../../images/student/master.png';
import discoveryImage from './../../images/student/discovery.png';
import visaImage from './../../images/student/visa.png';
import americanExpressImage from './../../images/student/american-express.png';
import { cartState } from '../atoms/cart-atom';
import { currencySymbol } from '../common/constants';
import CartCourseCard from '../cart/CartCourseCard';
import AssessmentCard from '../cart/AssessmentCard';
import InvoicePDF from './InvoicePDF'
import { useParsedSearchParams } from '../hooks/useParsedSearchParams'

function Checkout() {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [country, setCountry] = useState("IN");
    const [selectedState, setSelectedState] = useState("");
    const [paymentSuccessModalShow, setPaymentSuccessModalShow] = useState({
        status: false,
        data: null
    });

    useEffect(() => {
        if (paymentSuccessModalShow.status) {handleClose();}
    }, [paymentSuccessModalShow]);

    const [paymentFailedModalShow, setPaymentFailedModalShow] = useState(false);
    const [courseDetails, setCourseDetails] = useState({
        loading: false,
        error: null,
        data: null
    });
    const [promoCode, setPromoCode] = useState({
        code: '',
        submited: false
    });
    const [discountCodes, setDiscountCodes] = useState({
        loading: false,
        list: [],
        error: null
    });
    const [isPromocodeInputFocus, setIsPromocodeInputFocus] = useState(false);
    const [cart, setCart] = useRecoilState(cartState);
    const navigate = useNavigate();

    const searchParams = useParsedSearchParams();

    const checkoutHandler = async (addressLine1, addressLine2) => {
        setPaymentFailedModalShow(false);

        // CHECKOUT_NOW - single course
        // PAYMENT_CHECKOUT - all courses

        const body = {};

        if (searchParams?.courseCode) {
            body.courseCode = searchParams?.courseCode;
            // console.log('LOGGED');
        }

        if (searchParams?.courseCode && promoCode?.code) {
            body.couponName = promoCode?.code
        }

        if (searchParams?.assessmentCode) {
            body.assessmentCode = searchParams?.assessmentCode
        }

        // Single course
        // const requestBodyParam = JSON.stringify(body)

        const singleCourseRequest = {
            apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.CHECKOUT_NOW,
            method: API_METHOD.POST,
            requestBodyParams: JSON.stringify(body),
        }

        // all courses
        const cartCourseRequest = {
            apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.PAYMENT_CHECKOUT,
            method: API_METHOD.POST
        }

        const request = searchParams?.courseCode || searchParams?.assessmentCode ? singleCourseRequest : cartCourseRequest;
        const result = await API_CALL(request);
        const order = result.data;

        if (result.status === 200) {

            let localUserData = localStorage.getItem('localUserData');
            localUserData = JSON.parse(localUserData);

            const options = {
                key: process.env.REACT_APP_RAZORPAY_KEY,
                amount: order.amount,
                currency: order.currency,
                name: "Qlearning",
                description: "",
                image: qlearningLogo,
                order_id: order.id,
                "handler": async function (response) {
                    let requestBodyParam = JSON.stringify({
                        orderCreationId: order.id,
                        razorpay_payment_id: response.razorpay_payment_id,
                        razorpay_order_id: response.razorpay_order_id,
                        razorpay_signature: response.razorpay_signature,
                    })

                    const request = {
                        apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.PAYMENT_VERIFY,
                        method: API_METHOD.POST,
                        requestBodyParams: requestBodyParam
                    }

                    let result = await API_CALL(request)
                    if (result.status === 200) {
                        setCart({ items: [] });
                        setPaymentSuccessModalShow({
                            status: true,
                            data: {
                                ...result.data,
                                subtotalBeforeDiscount: result?.data?.items?.reduce((total, item) => {
                                    return total + item?.fee;
                                }, 0)
                            }
                        });

                    } else if (result.status > 200) {

                        setError(result.message);
                        swal({
                            title: "ERROR",
                            text: result.message,
                            icon: 'https://cdn-icons-png.flaticon.com/512/2569/2569174.png',
                            className: "error-window",
                            button: true,
                        })
                    }
                },
                prefill: {
                    name: localUserData.name,
                    email: localUserData.email,
                    // contact: "9618021355"
                },
                notes: {
                    name: localUserData.name,
                    email: localUserData.email,
                    addressLine1,
                    addressLine2
                },
                theme: {
                    "color": "#395061"
                },
                onClose: function () {
                    // setCart({ ...cart, items: [] });
                }
            };


            // CLEAR CART HERE
            // setCart({ ...cart, items: [] });


            const razor = new window.Razorpay(options);

            razor.on('payment.failed', function (response) {

                // setCart({ ...cart, items: [] });

                // alert('FAILED');

                // alert(response.error.code);
                // alert(response.error.description);
                // alert(response.error.source);
                // alert(response.error.step);
                // alert(response.error.reason);
                // alert(response.error.metadata.order_id);
                // alert(response.error.metadata.payment_id);

                // razor.close();

                // setTimeout(() => {
                //     setPaymentFailedModalShow(true);
                // }, 5000)
            });

            razor.open();

        } else if (result.status > 200) {
            setError(result.message);
            swal({
                title: "ERROR",
                text: result.message,
                icon: 'https://cdn-icons-png.flaticon.com/512/2569/2569174.png',
                className: "error-window",
                button: true,
            })
        }
    }

    const onSubmit = async (data) => {
        setLoading(true);

        let requestBodyParam = JSON.stringify({
            country: country,
            addressLine1: data.addressLine1,
            addressLine2: data.addressLine2,
            state: selectedState
        });

        const request = {
            apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.CREATE_BILLING_ADDRESS,
            method: API_METHOD.POST,
            requestBodyParams: requestBodyParam
        };

        let result = await API_CALL(request)
        if (result.status === 200) {
            checkoutHandler(data.addressLine1, data.addressLine2);
        } else if (result.status > 200) {
            setError(result.message);
            swal({
                title: "ERROR",
                text: result.message,
                icon: 'https://cdn-icons-png.flaticon.com/512/2569/2569174.png',
                className: "error-window",
                button: true,
            })
        }

        setLoading(false);
    }

    const options = [
        {
            value: "Andaman and Nicobar Islands",
            label: "Andaman and Nicobar Islands"
        },
        {
            value: "Andhra Pradesh",
            label: "Andhra Pradesh"
        },
        {
            value: "Arunachal Pradesh",
            label: "Arunachal Pradesh"
        },
        {
            value: "Assam",
            label: "Assam"
        },
        {
            value: "Bihar",
            label: "Bihar"
        },
        {
            value: "Chandigarh",
            label: "Chandigarh"
        },
        {
            value: "Chhattisgarh",
            label: "Chhattisgarh"
        },
        {
            value: "Dadra and Nagar Haveli",
            label: "Dadra and Nagar Haveli"
        },
        {
            value: "Daman and Diu",
            label: "Daman and Diu"
        },
        {
            value: "Delhi",
            label: "Delhi"
        },
        {
            value: "Goa",
            label: "Goa"
        },
        {
            value: "Gujarat",
            label: "Gujarat"
        },
        {
            value: "Haryana",
            label: "Haryana"
        },
        {
            value: "Himachal Pradesh",
            label: "Himachal Pradesh"
        },
        {
            value: "Jammu and Kashmir",
            label: "Jammu and Kashmir"
        },
        {
            value: "Jharkhand",
            label: "Jharkhand"
        },
        {
            value: "Karnataka",
            label: "Karnataka"
        },
        {
            value: "Kerala",
            label: "Kerala"
        },
        {
            value: "Ladakh",
            label: "Ladakh"
        },
        {
            value: "Lakshadweep",
            label: "Lakshadweep"
        },
        {
            value: "Madhya Pradesh",
            label: "Madhya Pradesh"
        },
        {
            value: "Maharashtra",
            label: "Maharashtra"
        },
        {
            value: "Manipur",
            label: "Manipur"
        },
        {
            value: "Meghalaya",
            label: "Meghalaya"
        },
        {
            value: "Mizoram",
            label: "Mizoram"
        },
        {
            value: "Nagaland",
            label: "Nagaland"
        },
        {
            value: "Odisha",
            label: "Odisha"
        },
        {
            value: "Puducherry",
            label: "Puducherry"
        },
        {
            value: "Punjab",
            label: "Punjab"
        },
        {
            value: "Rajasthan",
            label: "Rajasthan"
        },
        {
            value: "Sikkim",
            label: "Sikkim"
        },
        {
            value: "Tamil Nadu",
            label: "Tamil Nadu"
        },
        {
            value: "Telangana",
            label: "Telangana"
        },
        {
            value: "Tripura",
            label: "Tripura"
        },
        {
            value: "Uttar Pradesh",
            label: "Uttar Pradesh"
        },
        {
            value: "Uttarakhand",
            label: "Uttarakhand"
        },
        {
            value: "West Bengal",
            label: "West Bengal"
        }
    ];

    // SINGLE_COURSE_ORDER_SUMMARY

    const getCourseDetails = async (queryString) => {
        // console.log(JSON.stringify(body));

        setCourseDetails({ loading: true, data: null, error: null });

        // SINGLE_COURSE_ORDER_SUMMARY
        //  ORDER_SUMMARY

        const request = {
            apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.ORDER_SUMMARY + queryString,
            method: API_METHOD.GET
            // requestBodyParams: JSON.stringify(body)
        }

        let result = await API_CALL(request);

        if (result.status === 200) {
            setCourseDetails({ loading: false, data: result.data, error: null });
        } else if (result.status > 200) {
            setCourseDetails({ loading: false, data: null, error: result.message });
            swal({
                title: "ERROR",
                text: result.message,
                icon: 'https://cdn-icons-png.flaticon.com/512/2569/2569174.png',
                className: "error-window",
                button: true,
            })
        }
    }


    // const body = {};

    //     if (searchParams.courseCode) {
    //         body.courseCode = searchParams.courseCode
    //     }

    //     if (searchParams.assessmentCode) {
    //         body.assessmentCode = searchParams.assessmentCode
    //     }

    const handlePromoCodeSubmit = async (promoCode, clear) => {
        const code = clear ? "" : promoCode
        getCourseDetails(`?courseCode=${searchParams.courseCode}&couponName=${code}`);
        const requestBodyParam = JSON.stringify({
            "couponName": code,
            clear
        });

        const request = {
            apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.APPLY_PROMO_CODE,
            method: API_METHOD.POST,
            requestBodyParams: requestBodyParam,
        }

        let result = await API_CALL(request)
        if (result.status === 200) {
            setCart(result.data);
        } else if (result.status > 200) {
            swal({
                title: "ERROR",
                text: result.message,
                icon: 'https://cdn-icons-png.flaticon.com/512/2569/2569174.png',
                className: "error-window",
                button: true,
            })
        }
    }

    useEffect(() => {
        if (searchParams.courseCode) {
            // getCourseDetails({
            //     courseCode: searchParams.courseCode
            // })
            getCourseDetails(`?courseCode=${searchParams.courseCode}`);
        }
        if (searchParams.assessmentCode) {
            // getCourseDetails({
            //     assessmentCode: searchParams.assessmentCode
            // })
            getCourseDetails(`?assessmentCode=${searchParams.assessmentCode}`);
        }
    }, [searchParams.courseCode, searchParams.assessmentCode])

    useEffect(() => {
        if(promoCode.code === "" && isPromocodeInputFocus){
            handlePromoCodeSubmit(promoCode.code, true)
        }
    }, [promoCode.code])

    const getDiscountCodes = async (courseCode) => {
        setDiscountCodes({ loading: true, list: [], error: null });

        const request = {
            apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.COURSE_DISCOUNT_CODE +`?courseCode=${courseCode}`,
            method: API_METHOD.GET,
        }

        let result = await API_CALL(request);

        if (result.status === 200) {
            setDiscountCodes({ loading: false, list: result.data ? result.data : [], error: null });
            // alert(courseCode);
        } else if (result.status > 200) {
            swal({
                title: "ERROR",
                text: result.message,
                icon: 'https://cdn-icons-png.flaticon.com/512/2569/2569174.png',
                className: "error-window",
                button: true,
            })
            setDiscountCodes({ loading: false, list: [], error: result.message });
            
        }
    }

    useEffect(() => {
        if (searchParams.courseCode) {
            getDiscountCodes(searchParams.courseCode);
        }
    }, [searchParams.courseCode])


    useEffect(() => {
        if (cart.couponName) {
            setPromoCode({
                code: cart.couponName,
                submited: false
            })
        }
    }, [cart?.couponName])

    return (
        <div className="student-conatiner py-5 mt-4">
            <h2 className="fs-3" > Billing Address</h2>

            {/* <p>{JSON.stringify(courseDetails)}</p> */}

            <div className="d-flex align-items-start gap-4 mt-4">
                <div className="flex-grow-1">
                    <div className="login-col p-4 bg-white rounded-4 mb-5">
                        <form>
                            <div>
                                <label htmlFor='country'>Select your country</label>
                                <ReactFlagsSelect
                                    selected={country}
                                    onSelect={(code) => {
                                        console.log(code)
                                        setCountry(code)
                                    }}
                                    countries={["IN"]}
                                    style={{ backgroundColor: 'lightgray' }}
                                    selectButtonClassName="bg-gray border-0"
                                // searchable={true}
                                />
                                <small className='text-danger mb-2 d-block fz-12px fw-500'> {!country && "Please Select Country"}</small>
                            </div>

                            <div>
                                <Select
                                    placeholder="Select State"
                                    options={options}
                                    defaultInputValue={selectedState}
                                    className="fz-14px mb-2"
                                    name="bankName"
                                    onChange={(e) => setSelectedState(e.value)}
                                />
                            </div>

                            <div>
                                <label htmlFor='address-line-1'>Address</label>
                                <input style={{ marginBottom: '13px' }} placeholder='Address Line 1 ' type="string" id='addressLine1' {...register("addressLine1", { required: true })} />
                                <small className='text-danger d-block fz-12px fw-500 mb-2'> {errors.addressLine1?.type === 'required' && "Please Enter your address"}</small>
                                <input style={{ marginBottom: '13px' }} placeholder='Address Line 2 ' type="string" id='addressLine2' {...register("addressLine2")} />
                            </div>
                        </form>
                    </div>
                    {/* <PaymentTabs /> */}
                </div>

                {(searchParams.courseCode && courseDetails.data) || (searchParams.assessmentCode && courseDetails.data) ? (
                    <div>
                        <div className="p-4 bg-white rounded-4" style={{ width: '300px' }}>
                            <h4 className="mb-3" style={{ color: '#395061', fontSize: '1.1rem' }}>Order Summary</h4>
                            <div className="py-2 mb-4" style={{ borderBottom: '1.5px solid #395061' }}>

                                {/* {(searchParams.courseCode || searchParams.assessmentCode) && (
                                    <p>Promocode</p>
                                )} */}

                                {/* <p>Mani {searchParams.courseCode} - {searchParams.assessmentCode}</p> */}

                                {searchParams.courseCode && courseDetails.data && (
                                    <>
                                        <p style={{ color: '#8C8C8C' }}>Have Discount Code?</p>
                                        <div className="rounded-2" style={{ overflow: 'hidden', marginBottom: '15px' }}>
                                            <div style={{ display: 'flex', backgroundColor: '#f1f1f1' }}>
                                                <input
                                                    type="text"
                                                    className="bg-transparent border-0 py-2 ps-2"
                                                    style={{ width: '100%' }}
                                                    value={promoCode.code}
                                                    onFocus={() => setIsPromocodeInputFocus(true)}
                                                    // onBlur={() => setIsPromocodeInputFocus(false)}
                                                    onChange={e => setPromoCode({ code: e.target.value, submited: false })}
                                                />
                                                <button onClick={() => handlePromoCodeSubmit(promoCode.code, false)} disabled={!promoCode.code} className="rounded-3 border-0 d-block text-white px-3" style={{ backgroundColor: '#395061', fontSize: '0.8rem' }}>
                                                    Apply
                                                </button>
                                            </div>
                                            {isPromocodeInputFocus && discountCodes?.list?.length > 0 && (
                                                <div style={{ backgroundColor: '#fff', border: '1px solid lightgray', marginTop: '10px' }}>
                                                    {discountCodes?.list?.map(discountCode => (
                                                        <button
                                                            className="btn d-block"
                                                            style={{ borderBottom: '1px solid lightgray', width: '100%', borderRadius: 0, textAlign: 'left' }}
                                                            onClick={() => {
                                                                setPromoCode({ code: discountCode.couponName, submited: false });
                                                                setIsPromocodeInputFocus(false);
                                                            }}>
                                                            {discountCode.couponName}
                                                        </button>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </>
                                )}

                                <div className="d-flex justify-content-between align-items-center mt-1">
                                    <h5 className="fs-6" style={{ color: '#8C8C8C' }}>Sub Total</h5>
                                    <span style={{ color: '#395061' }}>
                                        {currencySymbol[courseDetails.data?.currencyCode]}{parseFloat(courseDetails.data?.subTotal)?.toFixed(2)}
                                    </span>
                                </div>
                                {courseDetails.data?.discountPercentage && courseDetails.data?.discountValue && (
                                    <div className="d-flex justify-content-between align-items-center mt-1">
                                        <h5 className="fs-6" style={{ color: '#8C8C8C' }}>Discount({courseDetails.data?.discountPercentage}%)</h5>
                                        <span style={{ color: '#F75656' }}>-{currencySymbol[courseDetails.data?.currencyCode]}{parseFloat(courseDetails.data?.discountValue)?.toFixed(2)}</span>
                                    </div>
                                )}
                                <div className="d-flex justify-content-between align-items-center mt-1">
                                    <h5 className="fs-6" style={{ color: '#8C8C8C' }}>Tax ({courseDetails.data?.taxPercentage}%)</h5>
                                    <span style={{ color: '#395061' }}>
                                        {currencySymbol[courseDetails.data?.currencyCode]}{parseFloat(courseDetails.data?.taxValue)?.toFixed(2)}
                                    </span>
                                </div>
                            </div>
                            <div className="py-2 mb-5">
                                <div className="d-flex justify-content-between align-items-center mt-1">
                                    <h5 className="fs-6 py-0 my-0" style={{ color: '#395061' }}>Total</h5>
                                    <span style={{ color: '#395061' }}>
                                        {currencySymbol[courseDetails.data?.currencyCode]}{parseFloat(courseDetails.data?.total)?.toFixed(2)}
                                    </span>
                                </div>
                            </div>
                            <button onClick={handleSubmit((data) => onSubmit(data))} className="btn py-2 w-100 text-white" style={{ backgroundColor: '#395061' }}>
                                Proceed
                            </button>
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className="p-4 bg-white rounded-4" style={{ width: '300px' }}>
                            <h4 className="mb-3" style={{ color: '#395061', fontSize: '1.1rem' }}>Order Summary</h4>
                            <div className="py-2" style={{ borderBottom: '1.5px solid #395061' }}>
                                <div className="d-flex justify-content-between align-items-center mt-1">
                                    <h5 className="fs-6" style={{ color: '#8C8C8C' }}>Sub Total</h5>
                                    <span style={{ color: '#395061' }}>
                                        {currencySymbol[cart.currencyCode]}{cart.subTotal}
                                    </span>
                                </div>

                                {(cart.discountPercentage > 0 && cart.discountValue > 0) && (
                                    <div className="d-flex justify-content-between align-items-center mt-1">
                                        <h5 className="fs-6" style={{ color: '#8C8C8C' }}>Discount({cart.discountPercentage}%)</h5>
                                        <span style={{ color: '#F75656' }}>-{currencySymbol[cart.currencyCode]}{cart.discountValue}</span>
                                    </div>
                                )}
                                <div className="d-flex justify-content-between align-items-center mt-1">
                                    <h5 className="fs-6" style={{ color: '#8C8C8C' }}>Tax ({cart.taxPercentage}%)</h5>
                                    <span style={{ color: '#395061' }}>
                                        {currencySymbol[cart.currencyCode]}{parseFloat(cart.taxValue)?.toFixed(2)}
                                    </span>
                                </div>
                            </div>
                            <div className="py-2 mb-5">
                                <div className="d-flex justify-content-between align-items-center mt-1">
                                    <h5 className="fs-6 py-0 my-0" style={{ color: '#395061' }}>Total</h5>
                                    <span style={{ color: '#395061' }}>
                                        {currencySymbol[cart.currencyCode]}{cart.total}
                                    </span>
                                </div>
                            </div>
                            <button onClick={handleSubmit((data) => onSubmit(data))} className="btn py-2 w-100 text-white" style={{ backgroundColor: '#395061' }}>
                                Proceed
                            </button>
                        </div>
                    </div>
                )}




            </div>
            <PaymentSuccessModal
                show={paymentSuccessModalShow.status}
                data={paymentSuccessModalShow.data}
                onHide={() => {
                    setPaymentSuccessModalShow({
                        status: false,
                        data: null
                    })

                    if (searchParams.assessmentCode) {
                        return navigate('/my-assessments');
                    }

                    navigate('/student-dashboard')
                }}
                closePaymentSuccessModal={() => {
                    setPaymentSuccessModalShow({
                        status: false,
                        data: null
                    });

                    if (searchParams.assessmentCode) {
                        return navigate('/my-assessments');
                    }

                    navigate('/student-dashboard')
                }}
            />
            <PaymentFailedModal
                show={paymentFailedModalShow}
                onHide={() => setPaymentFailedModalShow(false)}
                checkoutHandler={checkoutHandler}
            />


            {
                !courseDetails.data && (
                    <div>
                        {cart?.items?.filter(course => course.courseCode).length > 0 && cart?.items?.filter(course => course.courseCode)?.map(cartCourse => {
                            // if (cartCourse.assessmentCode) return null;
                            return <CartCourseCard key={cartCourse._id} course={cartCourse} liveCourse={cartCourse.isLive} disableRemove />
                        })}
                        {cart?.items?.filter(course => course.assessmentCode).length > 0 && cart?.items?.filter(course => course.assessmentCode)?.map(assessment => <AssessmentCard key={assessment.assessmentCode} assessment={assessment} disableRemove />)}
                    </div>
                )
            }

            <div>
                {
                    searchParams.courseCode && courseDetails.data && courseDetails.data?.items && (
                        <CartCourseCard
                            course={courseDetails.data.items}
                            liveCourse={courseDetails.data.items.isLive}
                            disableRemove
                        />
                    )
                }
            </div>


            <div>
                {searchParams.assessmentCode && courseDetails.data && courseDetails.data?.items && (
                    <AssessmentCard assessment={courseDetails.data.items} disableRemove />
                )}
            </div>



        </div>

    )
}

function PaymentSuccessModal({ closePaymentSuccessModal, data, ...props }) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header style={{ borderBottom: 0 }} closeButton></Modal.Header>
            <Modal.Body>
                <img
                    src={PICTURE_STORE.TickImage}
                    alt="success pic"
                    style={{ marginTop: '-40px', display: 'block', width: '300px', marginLeft: 'auto', marginRight: 'auto' }}
                />
                <h4 className="mt-2 fs-4 text-center">
                    Payment Successfully Done
                </h4>

                {/* <p>{JSON.stringify(data)}</p> */}

                <div className="mt-4 mb-3 d-flex justify-content-center gap-4" style={{ maxWidth: '330px', marginLeft: 'auto', marginRight: 'auto' }}>
                    <PDFDownloadLink className="btn text-white py-2 rounded-3 flex-grow-1" style={{ background: 'gray', flexBasis: 0 }} document={<InvoicePDF data={data} />} fileName="invoice.pdf">
                        {({ loading, error }) => (loading ? 'Loading Invoice...' : 'Invoice')}
                    </PDFDownloadLink>
                    <button onClick={closePaymentSuccessModal} className="btn text-white py-2 rounded-3 flex-grow-1" style={{ background: '#395061', flexBasis: 0 }}>
                        Done
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

const handleClose = async() => {
   
    const request = {
            apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.REMOVE_CART_ITEMS,
            method: API_METHOD.POST,
        };
        let result = await API_CALL(request)
}

function PaymentFailedModal({ checkoutHandler, ...props }) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header style={{ borderBottom: 0 }} closeButton></Modal.Header>
            <Modal.Body>
                <img
                    src={PICTURE_STORE.TickImage}
                    alt="success pic"
                    style={{ marginTop: '-40px', display: 'block', width: '300px', marginLeft: 'auto', marginRight: 'auto' }}
                />
                <h4 className="mt-2 fs-4 text-center">
                    An Error Occured! Try Again
                </h4>
                <div className="mt-4 mb-3 d-flex justify-content-center" style={{ maxWidth: '330px', marginLeft: 'auto', marginRight: 'auto' }}>
                    <button onClick={checkoutHandler} className="btn text-white py-2 rounded-3 px-4" style={{ background: '#395061', cursor: 'pointer' }}>
                        Try Again
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

function PaymentTabs() {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [paymentType, setPaymentType] = useState("paypal");

    return (
        <div>
            <div className="d-flex gap-4">
                <button onClick={() => setPaymentType('paypal')} className="d-flex flex-column justify-content-center align-items-center flex-grow-1 btn bg-white rounded-4 py-4" style={{ flexBasis: 0 }}>
                    <img src={paypalImage} alt="paypal" width="60" />
                    <h5 className="fs-6 m-0 p-0 mt-3">PayPal</h5>
                </button>

                <button onClick={() => setPaymentType('card')} className="d-flex flex-column justify-content-center align-items-center flex-grow-1 btn bg-white rounded-4 py-4" style={{ flexBasis: 0 }}>
                    <div className="d-flex gap-3 justify-content-between align-items-center">
                        <img src={masterCardImage} alt="paypal" width="25" />
                        <img src={discoveryImage} alt="discovery" width="25" />
                        <img src={visaImage} alt="visa" width="25" />
                        <img src={americanExpressImage} alt="american express" width="25" />
                    </div>
                    <h5 className="fs-6 m-0 p-0 mt-4">PayPal</h5>
                </button>

                <button onClick={() => setPaymentType('upi')} className="d-flex flex-column justify-content-center align-items-center flex-grow-1 btn bg-white rounded-4 py-4" style={{ flexBasis: 0 }}>
                    <img src={upiImage} alt="upi" width="90" />
                    <h5 className="fs-6 m-0 p-0 mt-3">Upi</h5>
                </button>
            </div>

            <div className="mt-4">
                {paymentType === "paypal" && (
                    <div className="bg-white p-4 rounded-3">
                        <p>
                            In order to complete your transaction, we will transfer you over to PayPal's
                            secure servers.
                        </p>
                        <div className="d-flex justify-content-end align-items-center text-muted">
                            <svg width="24" height="30" className="me-2" viewBox="0 0 34 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M28.6653 14.04V11.6666C28.6653 5.22336 23.442 0 16.9987 0C10.5553 0 5.33203 5.22336 5.33203 11.6666V14.04C2.29875 15.3638 0.336406 18.3571 0.332031 21.6666V31.6666C0.3375 36.2667 4.06524 39.9945 8.66531 40H25.332C29.932 39.9945 33.6598 36.2667 33.6653 31.6666V21.6666C33.661 18.3571 31.6986 15.3638 28.6653 14.04ZM18.6653 28.3334C18.6653 29.2538 17.9191 30 16.9987 30C16.0782 30 15.332 29.2538 15.332 28.3334V25C15.332 24.0795 16.0782 23.3334 16.9987 23.3334C17.9191 23.3334 18.6653 24.0795 18.6653 25V28.3334ZM25.332 13.3334H8.66531V11.6667C8.66531 7.06437 12.3963 3.33336 16.9987 3.33336C21.6011 3.33336 25.332 7.0643 25.332 11.6667V13.3334Z" fill="#8C8C8C" />
                            </svg>
                            <span style={{ fontSize: '0.7rem' }}>
                                Secure <br /> Connection
                            </span>
                        </div>
                    </div>
                )}

                {paymentType === "card" && (
                    <div className="bg-white p-4 rounded-3">
                        <div className="d-flex px-3 gap-3 justify-content-between align-items-center" style={{ width: '400px' }}>
                            <img src={masterCardImage} alt="paypal" width="45" />
                            <img src={discoveryImage} alt="discovery" width="45" />
                            <img src={visaImage} alt="visa" width="45" />
                            <img src={americanExpressImage} alt="american express" width="45" />
                        </div>

                        <div className="login-col mt-5">
                            <form>
                                <div>
                                    <label htmlFor='country'>Card Number</label>
                                    <input style={{ marginBottom: '13px' }} placeholder='Address Lin  1 ' type="country" id='country' {...register("country", { required: true })} />
                                    <small className='text-danger mb-2 d-block fz-12px fw-500'> {errors.country?.type === 'required' && "Please Select Country"}</small>
                                </div>
                                <div>
                                    <label htmlFor='country'>Name On Card</label>
                                    <input style={{ marginBottom: '13px' }} placeholder='Name On Card' type="country" id='country' {...register("country", { required: true })} />
                                    <small className='text-danger mb-2 d-block fz-12px fw-500'> {errors.country?.type === 'required' && "Please Select Country"}</small>
                                </div>

                                <div className="d-flex gap-4">
                                    <div>
                                        <label htmlFor='country'>Expiration Date</label>
                                        <input style={{ marginBottom: '13px' }} placeholder='MM / YYYY' type="country" id='country' {...register("country", { required: true })} />
                                        <small className='text-danger mb-2 d-block fz-12px fw-500'> {errors.country?.type === 'required' && "Please Select Country"}</small>
                                    </div>
                                    <div className="flex-grow-1">
                                        <label htmlFor='country'>CVV</label>
                                        <input style={{ marginBottom: '13px' }} placeholder='CVV' type="country" id='country' {...register("country", { required: true })} />
                                        <small className='text-danger mb-2 d-block fz-12px fw-500'> {errors.country?.type === 'required' && "Please Select Country"}</small>
                                    </div>
                                </div>

                                <div className="d-flex gap-4">
                                    <div className="flex-grow-1" style={{ flexBasis: 0 }}>
                                        <label htmlFor='country'>ZIP / Postal Code</label>
                                        <input style={{ marginBottom: '13px' }} placeholder='ZIP CODE' type="country" id='country' {...register("country", { required: true })} />
                                        <small className='text-danger mb-2 d-block fz-12px fw-500'> {errors.country?.type === 'required' && "Please Select Country"}</small>
                                    </div>
                                    <div className="mt-1 flex-grow-1 d-flex justify-content-center align-items-center" style={{ flexBasis: 0 }}>
                                        <input
                                            type="checkbox"
                                            className="m-0 p-0"
                                            style={{
                                                display: 'inline',
                                                width: '30px',
                                                accentColor: '#395061',
                                                zoom: '1.5',
                                                cursor: 'pointer'
                                            }}
                                            id="save-card"
                                        />
                                        <label htmlFor='save-card' className="m-0 p-0 prevent-text-select" style={{ cursor: 'pointer' }}>Save This Card</label>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                )}
                {paymentType === "upi" && (
                    <div>UPI</div>
                )}
            </div>
        </div >
    )
}

export default Checkout