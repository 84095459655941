import { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import CartCourseCard from './CartCourseCard';
import CoursesList from '../common/CoursesList';
import { cartState } from './../atoms/cart-atom';
import { currencySymbol } from '../common/constants';
import { API_CALL } from './../../api/ApiServiceCall';
import { API_CONSTANTS, API_METHOD } from './../../common/constants';
import AssessmentCard from './AssessmentCard';

function Cart() {
    const [courses, setCourses] = useState({ loading: true, list: [], error: null });
    const [discountCodes, setDiscountCodes] = useState({
        loading: false,
        list: [],
        error: null
    });
    const [isPromocodeInputFocus, setIsPromocodeInputFocus] = useState(false);
    const [promoCode, setPromoCode] = useState({
        code: '',
        submited: false
    });
    const [cartEmpty, setCartEmpty] = useState(true);
    const [cart, setCart] = useRecoilState(cartState);
    const [dataLoaded, setDataLoaded] = useState(false);
    const navigate = useNavigate();

    const fetchData = async () => {
        try {
            const [discountCodesResult, coursesResult, cartDataResult] = await Promise.all([
                getDiscountCodes(),
                getCourses(),
                getCartData()
            ]);
            
            setDataLoaded(true);
        } catch (error) {
            console.error("Error fetching data:", error);
            setDataLoaded(true); // Set dataLoaded to true even in case of an error to avoid indefinite loading
        }
    };
    
    useEffect(() => {
        fetchData();
    }, []);
    
    const getDiscountCodes = async () => {
        try {
            const request = {
                apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.DISCOUNT_CODES,
                method: API_METHOD.GET,
            };
            const result = await API_CALL(request);
    
            if (result.status === 200) {
                setDiscountCodes({ loading: false, list: result.data || [], error: null });
            } else if (result.status > 200) {
                setDiscountCodes({ loading: false, list: [], error: result.message });
    
                swal({
                    title: "ERROR",
                    text: result.message,
                    icon: 'https://cdn-icons-png.flaticon.com/512/2569/2569174.png',
                    className: "error-window",
                    button: true,
                });
            }
            return result;
        } catch (error) {
            console.error("Error fetching discount codes:", error);
            throw error;
        }
    };
    
    const getCourses = async () => {
        try {
            const request = {
                apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.ALSO_BUY_COURSES,
                method: API_METHOD.GET,
            };
            const result = await API_CALL(request);
    
            if (result.status === 200) {
                setCourses({ loading: false, list: result.data || [], error: null });
            } else if (result.status > 200) {
                setCourses({ loading: false, list: [], error: result.message });
    
                swal({
                    title: "ERROR",
                    text: result.message,
                    icon: 'https://cdn-icons-png.flaticon.com/512/2569/2569174.png',
                    className: "error-window",
                    button: true,
                });
            }
            return result;
        } catch (error) {
            console.error("Error fetching courses:", error);
            throw error;
        }
    };
    
    const getCartData = async () => {
        try {
            const request = {
                apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.CART,
                method: API_METHOD.GET,
            };
            const result = await API_CALL(request);
    
            if (result.status === 200) {
                setCart(result.data);
                if(result.data.items.length > 0){
                    setCartEmpty(false);
                }
            } else if (result.status > 200) {
                swal({
                    title: "ERROR",
                    text: result.message,
                    icon: 'https://cdn-icons-png.flaticon.com/512/2569/2569174.png',
                    className: "error-window",
                    button: true,
                });
            }
            return result;
        } catch (error) {
            console.error("Error fetching cart data:", error);
            throw error;
        }
    };
    

    const handlePromoCodeSubmit = async (promoCode, clear) => {
        const code = clear ? "" : promoCode
        const requestBodyParam = JSON.stringify({
            "couponName": code,
            clear
        });

        const request = {
            apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.APPLY_PROMO_CODE,
            method: API_METHOD.POST,
            requestBodyParams: requestBodyParam,
        }

        let result = await API_CALL(request)
        if (result.status === 200) {
            setCart(result.data);
        } else if (result.status > 200) {
            swal({
                title: "ERROR",
                text: result.message,
                icon: 'https://cdn-icons-png.flaticon.com/512/2569/2569174.png',
                className: "error-window",
                button: true,
            })
        }
    }

    useEffect(() => {
        if (cart.couponName) {
            setPromoCode({
                code: cart.couponName,
                submited: false
            })
        }
    }, [cart?.couponName])

    useEffect(() => {
        if(promoCode.code === "" && isPromocodeInputFocus){
            handlePromoCodeSubmit(promoCode.code, true)
        }
    }, [promoCode.code])

    if (!dataLoaded) {
        return (
            <div className="student-container py-5 mt-4" style={{ marginLeft: '20px' }}>
                <h2 className="fs-3">My Cart</h2>
                <div style={{ height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div className="spinner-border" role="status">
                        <span className="sr-only"></span>
                    </div>
                </div>
            </div>
        );
    }
    // if (cartEmpty) {
    //     return (
    //         <div className="student-container py-5 mt-4" style={{ marginLeft: '20px' }}>
    //             <h2 className="fs-3">My Cart</h2>
    //          {cartEmpty ? (
    //                 <div style={{ height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    //                     <p style={{ color: '#8C8C8C' }}>Cart Empty</p>
    //                 </div>
    //           ) : (
    //                <div style={{ height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    //              </div>
    //           )}              
    //         </div>
    //     );
    // }
//    if (cartEmpty) {
//         return (
//             <div className="student-container py-5 mt-4" style={{ marginLeft: '20px' }}>
//                 <h2 className="fs-3">My Cart</h2>
//                     <div style={{ height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
//                         <p style={{ color: '#8C8C8C' }}>Cart Empty</p>
//                     </div>
               
//             </div>
//         );
//     }

    return (
        <div className="student-conatiner py-5 mt-4">
            <h2 className="fs-3">My Cart</h2>
            <div className="d-flex gap-4 mt-4">
                <div className="flex-grow-1">
                    {cart?.items?.length === 0 && <p style={{ color: '#8C8C8C' }}>Cart Empty</p>}
                    {cart?.items?.filter(course => course?.courseCode).length > 0 && cart?.items?.filter(course => course?.courseCode)?.map(cartCourse => <CartCourseCard key={cartCourse._id} course={cartCourse} liveCourse={cartCourse.isLive} />)}
                    {cart?.items?.filter(course => course?.assessmentCode).length > 0 && cart?.items?.map(assessment => {
                        if (assessment.courseCode) return null;
                        return <AssessmentCard key={assessment.assessmentCode} assessment={assessment} />
                    })}
                    {/* <AssessmentCard /> */}
                </div>

                {cart.subTotal && (
                    <div>
                        <div className="p-4 bg-white rounded-4" style={{ width: '300px' }}>
                            <h4 className="fs-6" style={{ color: '#8C8C8C' }}>Sub Total</h4>
                            <p className="fs-5 fw-semibold">{currencySymbol[cart.currencyCode]}{parseFloat(cart.subTotal)?.toFixed(2)}</p>

                            {/* <p className="fs-5 fw-semibold">
                            {isNaN(parseFloat(cart.subTotal)) ? `` : `${currencySymbol[cart.currencyCode]}${parseFloat(cart.subTotal)?.toFixed(2)}`}
                        </p> */}


                            <p style={{ color: '#8C8C8C' }}>Have Discount Code?</p>
                            <div className="rounded-2" style={{ overflow: 'hidden' }}>

                                <div style={{ display: 'flex', backgroundColor: '#f1f1f1' }}>
                                    <input
                                        type="text"
                                        className="bg-transparent border-0 py-2 ps-2"
                                        style={{ width: '100%' }}
                                        value={promoCode.code}
                                        onFocus={() => setIsPromocodeInputFocus(true)}
                                        // onBlur={() => setIsPromocodeInputFocus(false)}
                                        onChange={e => setPromoCode({ code: e.target.value, submited: false })}
                                    />
                                    {/* setPromoCode(data => ({ ...data, submited: true })) */}
                                    <button onClick={() => handlePromoCodeSubmit(promoCode.code, false)} disabled={!promoCode.code} className="rounded-3 border-0 d-block text-white px-3" style={{ backgroundColor: '#395061', fontSize: '0.8rem' }}>
                                        Apply
                                    </button>
                                </div>

                                {/* {!promoCode.code && <p className='my-2 text-danger'>Please enter promo code</p>} */}

                                {/* {promoCode.submited && <p className='my-2 text-danger'>{promoCode.code ? 'Promo code is invalid' : 'Please enter promo code'}</p>} */}

                                {/* <p>{JSON.stringify(discountCodes?.list)}</p> */}

                                {isPromocodeInputFocus && discountCodes?.list?.length > 0 && (
                                    <div style={{ backgroundColor: '#fff', border: '1px solid lightgray', marginTop: '10px' }}>
                                        {discountCodes?.list?.map(discountCode => (
                                            <button
                                                className="btn d-block"
                                                style={{ borderBottom: '1px solid lightgray', width: '100%', borderRadius: 0, textAlign: 'left' }}
                                                onClick={() => {
                                                    setPromoCode({ code: discountCode.couponName, submited: false });
                                                    setIsPromocodeInputFocus(false);
                                                }}>
                                                {discountCode.couponName}
                                            </button>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <div className="py-2" style={{ borderBottom: '1.5px solid #395061' }}>
                                {(cart?.discountPercentage > 0 && cart?.discountValue > 0) && (
                                    <div className="d-flex justify-content-between align-items-center pt-2">
                                        <h5 className="fs-6" style={{ color: '#8C8C8C' }}>Discount({cart.discountPercentage}%)</h5>
                                        <span style={{ color: '#F65656' }}>-{currencySymbol[cart.currencyCode]}{parseFloat(cart.discountValue)?.toFixed(2)}</span>
                                    </div>
                                )}
                                {cart.taxPercentage && cart.taxValue && (
                                    <div className="d-flex justify-content-between align-items-center mt-1">
                                        <h5 className="fs-6" style={{ color: '#8C8C8C' }}>Tax ({cart.taxPercentage}%)</h5>
                                        <span style={{ color: '#395061' }}>
                                            {currencySymbol[cart.currencyCode]}{parseFloat(cart.taxValue)?.toFixed(2)}
                                        </span>
                                    </div>
                                )}
                            </div>

                            <div className="py-2">
                                <div className="d-flex justify-content-between align-items-center mt-1">
                                    <h5 className="fs-6" style={{ color: '#395061' }}>Total</h5>
                                    <span style={{ color: '#395061' }}>
                                        {currencySymbol[cart.currencyCode]}{parseFloat(cart.total)?.toFixed(2)}
                                    </span>
                                </div>
                            </div>
                            <button onClick={() => navigate('/checkout')} className="btn w-100 text-white" style={{ backgroundColor: '#395061' }}>
                                Checkout
                            </button>
                        </div>
                    </div>
                )}




            </div>

            {courses?.list?.length > 0 && <CoursesList title="Also you can Buy" data={{ courses: courses.list }} />}
        </div>
    )
}

export default Cart