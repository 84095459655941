import React from "react";

function ContactUs() {
  return (
    <div className="contianer-fluid mt-4">
      <div className="row m-0">
        <div className="col-sm-6 px-0">
          <img
            src="https://images.unsplash.com/photo-1567473030492-533b30c5494c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8bWFpbHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60"
            alt="support banner"
            className="d-block w-100"
          />
        </div>
        <div
          className="col-sm-6 py-3"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ width: "400px" }}>
            <form>
              <div className="row">
                <div className="mb-3 col">
                  <label for="exampleInputEmail1" class="form-label">
                    First name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                  />
                </div>
                <div class="mb-3 col">
                  <label for="exampleInputPassword1" className="form-label">
                    Last name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputPassword1"
                  />
                </div>
              </div>

              <div className="mb-3">
                <label for="exampleInputEmail1" class="form-label">
                  Email address
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                />
              </div>
              <div class="mb-3">
                <label for="exampleInputPassword1" className="form-label">
                  Message
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputPassword1"
                />
              </div>
              <button
                type="submit"
                className="btn btn-primary"
                style={{ backgroundColor: "#395061" }}
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
