import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import { API_CALL } from '../../../../api/ApiServiceCall';
import { API_CONSTANTS, API_METHOD } from '../../../../common/constants';

export const EditIndependentAssessment = () => {

    const [getCategory, setGetCategory] = useState([])
    const [getSubCategory, setGetSubCategory] = useState(false)
    const [isValidate, setIsValidate] = useState({
        courseTitle: false, currency: false, fee: false, getCategoryFormData: false, getSubCategoryFormData: false
    })
    const [assessmentStatus, setAssessmentStatus] = useState(null);
    const [loading, setLoading] = useState(false);
    const [gettingDataLoader, setGettingDataLoader] = useState(false);
    const [getCategoryFormData, setGetCategoryFormData] = useState("")
    const [getSubCategoryFormData, setGetSubCategoryFormData] = useState("")
    const [independentAssessmentTitle, setIndependentAssessmentTitle] = useState("");
    const [independentAssessmentCurrency, setIndependentAssessmentCurrency] = useState({
        currency: "",
        fee: ""
    });

    const navigate = useNavigate();
    useEffect(() => {
        getAssessmentByCode();
    }, [])
    const getAssessmentByCode = async () => {
        setGettingDataLoader(true)
        const assessmentCode = localStorage.getItem('independentAssessmentCode')
        const request = {
            apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.GET_ASSESSMENT_BY_ASSESSMENT_CODE + `/?assessmentCode=${assessmentCode}`,
            method: API_METHOD.GET,
        }
        let result = await API_CALL(request)
        if (result.status === 200) {
            setAssessmentStatus(result?.data?.assessmentStatus)
            setIndependentAssessmentTitle(result.data.assessmentTitle)
            setIndependentAssessmentCurrency({
                currency: result.data.currency,
                fee: result.data.fee
            });
            setGettingDataLoader(false)
            getAllCategory(result.data);
        } else {
            swal({
                title: "ERROR",
                text: result.message,
                icon: 'https://cdn-icons-png.flaticon.com/512/2569/2569174.png',
                className: "error-window",
                button: true,
            })
            setGettingDataLoader(false)
        }

    }
    const getAllCategory = async (assessData) => {
        const request = {
            apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.GET_ALL_CATEGORY,
            method: API_METHOD.GET,
        }
        let result = await API_CALL(request)
        if (result.status === 200) {
            setGetCategory(result.data);
            getSubCategoryByCode(assessData, assessData.catogory)
        } else {
            swal({
                title: "ERROR",
                text: result.message,
                icon: 'https://cdn-icons-png.flaticon.com/512/2569/2569174.png',
                className: "error-window",
                button: true,
            })
        }

    }

    const getCurrentCategoryCode = (e) => {
        setGetSubCategoryFormData("")
        setGetCategoryFormData(e.target.value)
        // const index = e.target.selectedIndex;
        // const el = e.target.childNodes[index]
        // const option = el.getAttribute('id');
        // setGetSubCategoryFormData("")
        getSubCategoryByCode("", e.target.value)
    }
    // ========get subcategory========
    const getSubCategoryByCode = async (assessData, catCode) => {
        const request = {
            apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.GET_SUB_CATEGORY_BY_CATEGORYCODE + `/?categoryCode=${catCode}`,
            method: API_METHOD.GET,
        }
        let result = await API_CALL(request)

        if (result.status === 200) {
            setGetSubCategory(result);
            if (assessData) {
                setGetCategoryFormData(assessData.catogory)
                setGetSubCategoryFormData(assessData.subCategory)
            }
        } else if (result.status > 200) {
            console.log('errror', result)
        }
    }

    const submitAssessment = (e) => {
        e.preventDefault()

        const isAllow = independentAssessmentTitle && getCategoryFormData && getSubCategoryFormData && independentAssessmentCurrency.currency && independentAssessmentCurrency.fee
        if (isAllow) {
            allowCreateAssessment();

        } else {
            setIsValidate({ courseTitle: true, getCategoryFormData: true, getSubCategoryFormData: true, currency: true, fee: true });

        }
    }

    const allowCreateAssessment = async () => {
        const assessmentCode = localStorage.getItem('independentAssessmentCode')
        setLoading(true);
        const requestBodyParam = JSON.stringify({
            assessmentTitle: independentAssessmentTitle,
            catogory: getCategoryFormData,
            subCategory: getSubCategoryFormData,
            currency: independentAssessmentCurrency.currency,
            fee: independentAssessmentCurrency.fee,
            assessmentCode: assessmentCode,
            isEdit: true
        })
        // console.log(requestBodyParam)
        const request = {
            apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.CREATE_UPDATE_ASSESSMENT_DETAILS,
            method: API_METHOD.POST,
            requestBodyParams: requestBodyParam,
        }
        let result = await API_CALL(request)
        if (result.status === 200) {
            setLoading(false);
            navigate('/courses/live/inner-independent-assessment');
        } else if (result.status > 200) {
            swal({
                title: "ERROR",
                text: result.message,
                icon: 'https://cdn-icons-png.flaticon.com/512/2569/2569174.png',
                className: "error-window",
                button: true,
            })
            setLoading(false);
        }
    }
    const statusCodes = ["INREVIEW"]
    const nextNavigator = () => {
        navigate('/courses/live/inner-independent-assessment');
    }

    return (
        <div className='px-5 pt-4'>
            {/* {getCategoryFormData}===
    {getSubCategoryFormData} */}
            {/* <pre>{JSON.stringify(assessmentApiData, null, 4)}</pre> */}
            {/* <p>{independentAssessmentTitle}</p>
            <p>{getCategoryFormData}</p>
            <p>{getSubCategoryFormData}</p>
            <p>{independentAssessmentCurrency.currency}</p>
            <p>{independentAssessmentCurrency.fee}</p> */}

            {gettingDataLoader ? <div className='text-center'><span className="spinner-border text-secondary spinner-border-sm" role="status" aria-hidden="true"></span>
                <span className="visually-hidden">Loading...</span></div> :
                <form onSubmit={submitAssessment}>
                    <label className='pb-2 fz-14px text-dark fw-500'>Assessment Title</label>
                    <input disabled={statusCodes.includes(assessmentStatus) ? true : false} type="text" className="default-inpt" defaultValue={independentAssessmentTitle} onChange={e => setIndependentAssessmentTitle(e.target.value)} />
                    {isValidate.courseTitle && independentAssessmentTitle === "" ? <small className='text-danger my-0 d-block fz-12px mt-3 fw-500'>Please Fill this field</small> : ""}

                    <div className="py-3"></div>
                    <div className="row">
                        <div className="col">
                            <label className="fz-14px text-dark fw-500 pb-3">Category</label>

                            <div className='tag-ajus mb-2'>
                                <select disabled={statusCodes.includes(assessmentStatus) ? true : false} defaultValue={getCategoryFormData} key={getCategoryFormData} onChange={getCurrentCategoryCode} onBlur={() => setIsValidate({ ...isValidate, getCategoryFormData: true })}>
                                    <option value="" className='d-none'>Select a category</option>
                                    {getCategory ? getCategory.map((catDatas, index) => (

                                        <option key={catDatas._id + index} value={catDatas.categoryCode}>{catDatas.categoryName}</option>

                                    )) : <option value="" >Loading...</option>}
                                </select>
                            </div>
                            {isValidate.getCategoryFormData && getCategoryFormData === "" ? <small className='text-danger my-0 d-block fz-12px mt-3 fw-500'>Please Fill this field</small> : ""}

                        </div>
                        <div className="col">
                            <label className="fz-14px text-dark fw-500 pb-3">Sub - Category</label>
                            <div className='tag-ajus mb-2'>
                                <select disabled={statusCodes.includes(assessmentStatus) ? true : false} defaultValue={getSubCategoryFormData} key={getSubCategoryFormData} onChange={(e) => setGetSubCategoryFormData(e.target.value)} onBlur={() => setIsValidate({ ...isValidate, getSubCategoryFormData: true })}>
                                    <option value="" className='d-none'>Select a Sub - category</option>
                                    {getSubCategory ? getSubCategory.data.map((subCatDatas, index) => (

                                        <option key={subCatDatas._id + "key" + index} value={subCatDatas.subCategoryCode} > {subCatDatas.subCategoryName}</option>

                                    )) : <option value={getSubCategoryFormData}>{getSubCategoryFormData}</option>}
                                </select>
                            </div>
                            {isValidate.getSubCategoryFormData && getSubCategoryFormData === "" ? <small className='text-danger my-0 d-block fz-12px mt-3 fw-500'>Please Fill this field</small> : ""}


                        </div>
                    </div>
                    <div className="row py-4">
                        <div className="col-6">
                            <label className="py-3 fw-500">Currency</label>
                            <div className='tag-ajus'>
                                <select disabled={statusCodes.includes(assessmentStatus) ? true : false} defaultValue={independentAssessmentCurrency.currency} key={independentAssessmentCurrency.currency} onBlur={() => setIsValidate({ ...isValidate, currency: true })} onChange={(e) => setIndependentAssessmentCurrency({ ...independentAssessmentCurrency, currency: e.target.value })}>
                                    <option value="" disabled>Select Currency</option>
                                    <option value="INR">₹</option>
                                    <option value="USD">$</option>
                                </select>
                            </div>
                            {isValidate.currency && independentAssessmentCurrency.currency === "" ? <small className='text-danger my-0 d-block fz-12px my-2 fw-500'>Please Fill this field</small> : ""}

                        </div>
                        <div className='col-6 '>
                            <label className="py-3 fw-500">Fee</label>
                            <div className='tag-ajus'>
                                <select disabled={statusCodes.includes(assessmentStatus) ? true : false} defaultValue={independentAssessmentCurrency.fee} key={independentAssessmentCurrency.currency} onBlur={() => setIsValidate({ ...isValidate, fee: true })} onChange={(e) => setIndependentAssessmentCurrency({ ...independentAssessmentCurrency, fee: e.target.value })}>
                                    <option value="" disabled>Select Fee</option>
                                    <option value="1000">1000</option>
                                    <option value="2000">2000</option>
                                    <option value="5000">5000</option>
                                </select>
                            </div>
                            {isValidate.fee && independentAssessmentCurrency.fee === "" ? <small className='text-danger my-0 d-block fz-12px my-2 fw-500'>Please Fill this field</small> : ""}
                        </div>

                    </div>
                    <div className='d-flex  justify-content-end align-items-end  py-5 '>

                        {statusCodes.includes(assessmentStatus) ?
                            <button onClick={nextNavigator} className='border-0 bg-prime text-white fz-14px py-12px w-14 rounded-3 text-center' role='button' >
                                Next
                            </button> :
                            <button className='border-0 bg-prime text-white fz-14px py-12px w-14 rounded-3 text-center' type='submit' disabled={loading ? true : false}>
                                {loading ? "Saving..." : "Next"}
                            </button>

                        }

                    </div>
                </form>}  </div>
    )
}
