import React from "react";

function RightArrow(props) {
  return (
    <svg
      width={props.size}
      viewBox="0 0 16 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.315132 28.2326L12.6639 15.8838C12.892 15.6462 13.0194 15.3295 13.0194 15.0001C13.0194 14.6706 12.892 14.354 12.6639 14.1163L0.322634 1.77507L2.09013 0.00756777L14.4314 12.3488C15.1132 13.0633 15.4937 14.013 15.4937 15.0007C15.4937 15.9883 15.1132 16.938 14.4314 17.6526L2.08263 30.0001L0.315132 28.2326Z"
        fill="black"
      />
    </svg>
  );
}

export default RightArrow;
