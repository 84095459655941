import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import swal from "sweetalert";
import { API_CALL } from "../../../api/ApiServiceCall";
import InstructorLoader from "../../../common/InstructorLoader";
import { API_CONSTANTS, API_METHOD } from "../../../common/constants";
import messagesStyles from "../../../css/messages.module.css";
import ChatWindow from "../chat/ChatWindow";
import MyS3Component from "../../s3/s3";

const sListStyle = { height: "60px", width: "60px", objectFit: "cover" };

function MessagesMain() {
  const [isOpenChat, setIsOpenChat] = useState(false);
  const [imgLink, setImgLink] = useState(
    "https://cdn-icons-png.flaticon.com/512/149/149071.png"
  );
  const [studentList, setStudentList] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [studentListBackup, setStudentListBackup] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");
  // Receive live chat callback
  const receiveMessages = useCallback((response) => {
    console.log("recev live chat-------->");
    console.log(response);
    setStudentList((prevMsgList) => [
      {
        userName: response.userName,
        dt: response.createdAt,
        message: response.message,
        senderId: response.senderId,
      },
      ...prevMsgList, // Previous messages
    ]);
  }, []);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      await getAllStudentList();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      swal({
        title: "Error",
        text: error.message,
        icon: "error",
        className: "error-window",
        button: true,
      });
    }
  };
  const maxString = (str, maxLength) => {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + "...";
    }
    return str;
  };
  useEffect(() => {
    fetchData();
  }, []);

  const getAllStudentList = async () => {
    let user = JSON.parse(localStorage.getItem("localUserData"));
    const request = {
      apiUrl:
        API_CONSTANTS.BASE_URL +
        API_CONSTANTS.GET_ALL_STUDENT +
        `?userType=${"INSTRUCTOR"}&userId=${user.userId}`,
      method: API_METHOD.GET,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      const sortedList = result?.data?.sort((a, b) => {
        const aLastActive = moment(a.lastActive).isValid()
          ? moment(a.lastActive)
          : moment();
        const bLastActive = moment(b.lastActive).isValid()
          ? moment(b.lastActive)
          : moment();

        if (!moment(a.lastActive).isValid()) return 1;
        if (!moment(b.lastActive).isValid()) return -1;

        return bLastActive - aLastActive;
      });
      setStudentList(sortedList);
      setStudentListBackup(sortedList);
      if (id) {
        const data = sortedList.filter((s) => s.id === id);
        setIsOpenChat(
          <ChatWindow
            clickedStudentData={data[0]}
            updateStudentListAndSort={updateStudentListAndSort}
          />
        );
      }
    } else if (result.status > 200) {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }
  };
  const imageNotFound = (imgId) => {
    const updatedList = studentList.map((data) => {
      if (data.id === imgId) {
        data.profileImgPath =
          "https://cdn-icons-png.flaticon.com/512/149/149071.png";
      }
      return data;
    });
    setStudentList(updatedList);
  };

  const updateStudentListAndSort = () => {
    getAllStudentList();
  };
  const onUserClick = (clickedData) => {
    setIsOpenChat(
      <ChatWindow
        clickedStudentData={clickedData}
        updateStudentListAndSort={updateStudentListAndSort}
      />
    );
  };
  const onSearch = (event) => {
    setSearchValue(event.target.value);
    const searchQuery = event.target.value.toLowerCase().trim();
    if (searchQuery) {
      const dataArry = studentListBackup;
      const filteredData = dataArry.filter((item) => {
        const fullNameLower = item.fullName.toLowerCase();
        const searchTerms = searchQuery.split(" ");
        return searchTerms.every((term) => fullNameLower.includes(term));
      });

      setStudentList(filteredData);
    } else {
      setStudentList(studentListBackup);
    }
  };
  console.log({ studentList });
  return (
    <div className=" px-4 mx-3">
      <div className="py-3"></div>
      <div className="row">
        <div className=" "></div>
        <div
          className={`${messagesStyles.bxSd} ${messagesStyles.csScroller} rounded-1 py-4 col-4 bg-white  `}
        >
          <div className="srch-bg ">
            <input
              className="srch-ui"
              type="search"
              value={searchValue}
              onChange={onSearch}
              placeholder="Search by name..."
            />
          </div>
          <div className="py-3"></div>

          {isLoading ? (
            <InstructorLoader />
          ) : (
            <ul className={`${messagesStyles.height} list-unstyled`}>
              {studentList && studentList.length > 0 ? (
                studentList.map((sData) => (
                  <li
                    className={
                      moment(sData?.lastActive).fromNow() == "Invalid date"
                        ? "d-none"
                        : ""
                    }
                    onClick={() => onUserClick(sData)}
                    role="button"
                    key={sData?.id}
                  >
                    <div className="row g-0">
                      <div className="col-2">
                        {sData?.profileImgPath ? (
                          <MyS3Component
                            imageUrl={sData?.profileImgPath}
                            alt={"Profile Image"}
                            className="rounded-circle"
                            style={sListStyle}
                            onError={() => imageNotFound(sData.id)}
                          />
                        ) : (
                          <img
                            src={
                              "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                            }
                            alt={"Profile Image"}
                            style={sListStyle}
                            className="rounded-circle"
                          />
                        )}
                      </div>
                      <div className="col my-auto mx-2">
                        <h6>{sData?.fullName}</h6>
                        <p
                          style={{
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {maxString(sData?.message, 20)}
                        </p>
                        {/* <h6>test@yahoo.com</h6> */}
                      </div>
                      <div className="col-2 my-auto">
                        <h6 className="m-0 text-muted fz-13px">
                          {moment(sData?.lastActive).isValid()
                            ? moment(sData?.lastActive).fromNow()
                            : " "}
                        </h6>
                      </div>
                    </div>
                    <hr></hr>
                  </li>
                ))
              ) : (
                <div className="d-flex align-items-center justify-content-center py-5">
                  <h6 className="text-center fz-19px text-muted">
                    No Student Found!
                  </h6>
                </div>
              )}
            </ul>
          )}
        </div>
        <div className="col  ">{isOpenChat ? isOpenChat : ""}</div>
      </div>
      <div className="py-4"></div>
    </div>
  );
}

export default MessagesMain;
