import React from 'react'
import { IoIosStar } from 'react-icons/io'

function Ratings({ rating, ratingCount }) {

    if (rating == 0) {
        return (
            <div className='star-rating' >
                <IoIosStar className='fz-17px text-secondary' />
                <IoIosStar className='fz-17px  text-secondary' />
                <IoIosStar className='fz-17px text-secondary' />
                <IoIosStar className='fz-17px text-secondary' />
                <IoIosStar className='fz-17px text-secondary' />
                <span className='mx-1 fz-14px fw-500 text-prime text-end'>({ratingCount})</span>
            </div>
        )
    }

    if (rating == 1) {
        return (
            <div className='star-rating' >
                <IoIosStar className='fz-17px  ' />
                <IoIosStar className='fz-17px text-secondary' />
                <IoIosStar className='fz-17px text-secondary' />
                <IoIosStar className='fz-17px text-secondary' />
                <IoIosStar className='fz-17px text-secondary' />
                <span className='fz-14px fw-500 text-prime'>({ratingCount})</span>

            </div>
        )
    }

    if (rating == 2) {
        return (
            <div className='star-rating' >
                <IoIosStar className='fz-17px ' />
                <IoIosStar className='fz-17px ' />
                <IoIosStar className='fz-17px text-secondary' />
                <IoIosStar className='fz-17px  text-secondary' />
                <IoIosStar className='fz-17px text-secondary' />

                <span className='fz-14px fw-500 text-prime'>({ratingCount})</span>

            </div>
        )
    }

    if (rating == 3) {
        return (
            <div className='star-rating' >
                <IoIosStar className='fz-17px' />
                <IoIosStar className='fz-17px' />
                <IoIosStar className='fz-17px' />
                <IoIosStar className='fz-17px text-secondary' />
                <IoIosStar className='fz-17px  text-secondary' />
                <span className='fz-14px fw-500 text-prime'>({ratingCount})</span>

            </div>
        )
    }

    if (rating == 4) {
        return (
            <div className='star-rating' >
                <IoIosStar className='fz-17px  ' />
                <IoIosStar className='fz-17px' />
                <IoIosStar className='fz-17px' />
                <IoIosStar className='fz-17px' />
                <IoIosStar className='fz-17px text-secondary' />
                <span className='fz-14px fw-500 text-prime'>({ratingCount})</span>

            </div>
        )
    }

    if (rating == 5) {
        return (
            <div className='star-rating' >
                <IoIosStar className='fz-17px' />
                <IoIosStar className='fz-17px ' />
                <IoIosStar className='fz-17px' />
                <IoIosStar className='fz-17px' />
                <IoIosStar className='fz-17px' />
                <span className='fz-14px fw-500 text-prime'>({ratingCount})</span>

            </div>
        )
    }
}

export default Ratings