import AWS from "aws-sdk";
import React, { useEffect, useState, forwardRef } from "react";
import VideoPlayer from "../../student/common/VideoPlayer";

const {
  REACT_APP_AWS_ACCESS_KEY_ID,
  REACT_APP_AWS_SECRET_KEY,
  REACT_APP_API_REGION,
  REACT_APP_S3_BUCKETNAME,
} = process.env;

const MyS3Component = forwardRef((value, ref) => {
  const [mediaURL, setMediaURL] = useState(null);
  const [mediaType, setMediaType] = useState(null);

  useEffect(() => {
    console.log("Generating signed URL...", value?.imageUrl);
    const s3 = new AWS.S3({
      accessKeyId: REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: REACT_APP_AWS_SECRET_KEY,
      region: REACT_APP_API_REGION,
    });

    const bucketName = REACT_APP_S3_BUCKETNAME;
    const objectKey = value?.imageUrl
      ?.toString()
      ?.split("/")
      ?.slice(3)
      ?.join("/");
    const fileExtension = objectKey?.split(".").pop();

    let contentType;
    let mediaTypeTemp;

    switch (fileExtension) {
      case "mp4":
        contentType = "video/mp4";
        mediaTypeTemp = "video";
        break;
      case "png":
      case "jpg":
      case "jpeg":
      case "gif":
        contentType = `image/${fileExtension}`;
        mediaTypeTemp = "image";
        break;
      case "docx":
        contentType =
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
        mediaTypeTemp = "document";
        break;
      case "pptx":
        contentType =
          "application/vnd.openxmlformats-officedocument.presentationml.presentation";
        mediaTypeTemp = "presentation";
        break;
      case "txt":
        contentType = "text/plain";
        mediaTypeTemp = "text";
        break;
      case "pdf":
        contentType = "application/pdf";
        mediaTypeTemp = "pdf";
        break;
      case "xlsx":
        contentType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        mediaTypeTemp = "spreadsheet";
        break;
      case "xls":
        contentType = "application/vnd.ms-excel";
        mediaTypeTemp = "spreadsheet";
        break;
      default:
        console.error("Unsupported file type");
        return;
    }

    setMediaType(mediaTypeTemp);
    console.log({ mediaURL, value, mediaTypeTemp });
    const params = {
      Bucket: bucketName,
      Key: objectKey,
      Expires: 300, // URL expiration time in seconds
      ResponseContentType: contentType,
    };

    s3.getSignedUrl("getObject", params, (err, url) => {
      if (err) {
        console.error("Error generating signed URL:", err);
      } else {
        console.log("Signed URL:", url);
        setMediaURL(url);
      }
    });
  }, [value?.imageUrl]);

  if (mediaURL && value.isVideoPlayer && mediaType === "video") {
    return (
      <VideoPlayer
        videoUrl={mediaURL}
        onEnded={value.handleVideoOver}
        onLoadedData={value.handleVideoLoad}
        controls={value.controls ? value.controls : true}
      />
    );
  }

  if (
    mediaURL && value.isFiles &&
    ["document", "presentation", "text", "pdf", "spreadsheet"].includes(
      mediaType
    )
  ) {
    return (
      <a
        href={mediaURL}
        download={mediaURL || "filename.ext"}
        style={value?.style}
      >
        {value?.resourceName || "Download File"}
      </a>
    );
  }

  if (mediaURL && mediaType === "image") {
    return (
      <img
        src={mediaURL}
        className={value?.className ? value?.className : "rounded-3"}
        alt={value?.alt ? value?.alt : ""}
        style={value?.style ? value?.style : {}}
        ref={ref}
        onError={value?.onError}
      />
    );
  }

  if (mediaURL && mediaType === "video" && !value.isVideoPlayer) {
    return (
      <video
        className="video-brd m-0"
        controls
        style={value?.style ? value?.style : {}}
      >
        <source type="video/mp4" src={mediaURL} />
      </video>
    );
  }

  return null;
});

export default MyS3Component;
