import React from "react";

function LeftArrow({ size }) {
  return (
    <svg
      width={size}
      height="30"
      viewBox="0 0 16 30"
      fill="#39500"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15.6854 1.76768L3.3366 14.1164C3.10849 14.3541 2.98111 14.6708 2.98111 15.0002C2.98111 15.3296 3.10849 15.6463 3.3366 15.8839L15.6779 28.2252L13.9104 29.9927L1.5691 17.6514C0.887258 16.9369 0.506835 15.9872 0.506835 14.9996C0.506835 14.0119 0.887258 13.0622 1.5691 12.3477L13.9179 0.000175844L15.6854 1.76768Z" />
    </svg>
  );
}

export default LeftArrow;
