import React from 'react';
import Toast from 'react-bootstrap/Toast';
import { RiCloseCircleFill } from "react-icons/ri";
import { useRecoilState } from 'recoil';
import { updatingToastMessageAtomFunc } from '../components/atom/setToastMessage';
function ToastComponent() {
    const [toastMessage, setToastMessage] = useRecoilState(updatingToastMessageAtomFunc);

    return (

        <Toast onClose={() => setToastMessage({active:false,message:""})} show={toastMessage.active} delay={3000} autohide="true">
            <Toast.Header className='toast-default-position'>
                <div className='toast-default-position'>
                    <small className="fz-15px fw-400 text-prime">{toastMessage.message}</small>
                    <button className='bg-transparent border-0 my-0 py-0' onClick={() => setToastMessage(false)}><RiCloseCircleFill size={20} className='text-prime' /></button>
                </div>
            </Toast.Header>
        </Toast>

    );
}

export default ToastComponent;

