import { Col, Nav, Row, Tab } from "react-bootstrap";
import CompletedAssessments from "./CompletedAssessments";
import PurchasedAssessments from "./PurchasedAssessments";

function MyAssessments() {
  return (
    <div className="student-conatiner tch">
      <div className="py-4"></div>
      <h1 className="fs-4 my-4">My Assessments</h1>
      <Tab.Container
        id="hr-tabs-example"
        defaultActiveKey="purchased-assessments"
        mountOnEnter={true}
      >
        <Row>
          <Col sm={12}>
            <Nav variant="pills brd-btm">
              <Nav.Item>
                <Nav.Link eventKey="purchased-assessments">Purchased</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="completed-assessments">Completed</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={12} className="mt-4">
            <Tab.Content>
              <Tab.Pane eventKey="purchased-assessments">
                <PurchasedAssessments />
              </Tab.Pane>
              <Tab.Pane eventKey="completed-assessments">
                <CompletedAssessments />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
      <div className="py-4"></div>
    </div>
  );
}

export default MyAssessments;
