import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { IoMdSend } from "react-icons/io";
import ScrollToBottom from "react-scroll-to-bottom";
import { messagingSocket } from "../../../common/constants";
import messagesStyles from "../../../css/messages.module.css";
import MyS3Component from "../../s3/s3";
const sListStyle = { height: "60px", width: "60px", objectFit: "cover" };
const pxMed = {
  padding: "13px 20px",
};
const imgDp = {
  height: "40px",
  width: "40px",
  borderRadius: "51px",
  objectFit: "cover",
};
function ChatWindow({ clickedStudentData, updateStudentListAndSort }) {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const message = useRef("");
  const [msgList, setMsgList] = useState([]);
  const localUserData = JSON.parse(localStorage.getItem("localUserData"));
  const messagesEndRef = useRef(null);
  const profileImgRef = useRef(null);
  const handleImageError = (profileImgRef) => {
    profileImgRef.current.src =
      "https://cdn-icons-png.flaticon.com/512/149/149071.png";
  };
  // when id change clickedStudentData?.id below effect will call----\/
  useEffect(() => {
    if (!isFirstRender) {
      // console.log("next next renders")
      setMsgList([]);
      initSocket();
    }
  }, [clickedStudentData?.id]);
  // ----------------------------------------------------------------/\
  // this will call only once--\/
  useEffect(() => {
    // console.log("first render")
    setIsFirstRender(false);
    setMsgList([]);
    initSocket();
    messagingSocket.open();
    messagingSocket.on("connection-success", async (response) => {
      console.log(`Socket connected ${response.socketId}`);
    });
    messagingSocket.on("message", receiveMessages);

    return () => {
      messagingSocket.off("message", receiveMessages);
      messagingSocket.close();
      console.log("dis");
      messagingSocket.disconnect();
      messagingSocket.on("disconnect", function () {});
    };
  }, []);
  // ---------------------------/\
  // ------------Inti socket------------
  const initSocket = () => {
    // -----------On join----------
    messagingSocket.emit(
      "join",
      { studentId: clickedStudentData?.id, instructorId: localUserData.userId },
      (res) => {
        console.log("--------msg");
        console.log(res);
      }
    );
    // -----------On join end----------
    // -----------On Prev msg end----------
    messagingSocket.emit(
      "getPreviousMessage",
      { studentId: clickedStudentData?.id, instructorId: localUserData.userId },
      (response) => {
        console.log("Previous msg list", response);
        if (response?.messages) {
          setMsgList((chatMessages) => [...response?.messages]);
        } else {
          setMsgList([]);
        }
      }
    );
    // -----------On Prev msg end----------
  };
  // ------------Inti socket end------------

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      sendMsg(e);
    }
  };
  // ---------Msg ---------------
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    scrollToBottom();
  }, [msgList]);
  // For text msg----------
  const sendMsg = (e) => {
    e.preventDefault();
    if (isSending) return;
    setIsSending(true);
    e.target.disabled = true;
    if (message.current.value) {
      messagingSocket.emit(
        "sendMessage",
        {
          studentId: clickedStudentData?.id,
          instructorId: localUserData.userId,
          message: message.current.value,
          userName: localUserData.name,
          senderId: localUserData.userId,
        },
        (responseData) => {
          console.log("sendMessage callback");
          console.log(responseData);
          if (responseData.success) {
            setMsgList((chatMessages) => [
              ...chatMessages,
              {
                userName: localUserData.name,
                dt: moment().format(),
                message: message.current.value,
                senderId: localUserData.userId,
              },
            ]);
            setTimeout(() => {
              message.current.value = "";
              e.target.disabled = false;
              setIsSending(false);
            }, 500);
            updateStudentListAndSort();
          } else {
            setIsSending(false);
          }
        }
      );
    } else {
      setIsSending(false);
    }
  };
  // ---------Msg end---------------
  const convertLinks = (text) => {
    const urlRegex = /((https?:\/\/[^\s]+)|www\.[^\s]+)/g;
    const words = text.split(/\s+/);
    const withLinks = words.map((word, index) => {
      if (word.match(urlRegex)) {
        // If the word is a URL, convert it to a link
        const url = word.startsWith("www.") ? `http://${word}` : word;
        return (
          <a
            key={index}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "blue", textDecoration: "underline !important" }}
          >
            {word}
          </a>
        );
      } else {
        return word;
      }
    });

    return withLinks;
  };
  // -----Receive live chat-------
  const receiveMessages = useCallback((response) => {
    console.log("recev live chat-------->");
    console.log(response);
    setMsgList((chatMessages) => [
      ...chatMessages,
      {
        userName: response.userName,
        dt: response.createdAt,
        message: response.message,
        senderId: response.senderId,
        // type: response.type,
      },
    ]);
  }, []);
  // -----Receive live chat------- end
  return (
    <div
      className={`${messagesStyles.bxSd} ${messagesStyles.csScroller} rounded-1  ms-lg-3  h-100 bg-white w-100 py-3 `}
    >
      <div className="row g-0 mx-3">
        <div className="col-1 my-auto">
          {clickedStudentData?.profileImgPath == null ||
          clickedStudentData?.profileImgPath == undefined ? (
            <img
              src={"https://cdn-icons-png.flaticon.com/512/149/149071.png"}
              alt={"Profile Image"}
              style={sListStyle}
              className=" rounded-circle"
            />
          ) : (
            <MyS3Component
              imageUrl={clickedStudentData?.profileImgPath}
              alt={"Profile Image"}
              className=" rounded-circle"
              style={sListStyle}
              ref={profileImgRef}
              onError={() => handleImageError(profileImgRef)}
            />
          )}
        </div>
        <div className="col my-auto mx-2">
          <h6 className="mb-1">{clickedStudentData?.fullName}</h6>
          {/* <h6 className='m-0 text-muted fz-13px'> {moment(clickedStudentData?.lastActive).fromNow() == "Invalid date" ? moment().fromNow() : moment(clickedStudentData?.lastActive).fromNow()}</h6> */}
        </div>
        <div className="py-2"></div>
        <hr></hr>
      </div>
      <div
        className={`${messagesStyles.csScroller} px-3`}
        style={{ width: "100%" }}
      >
        <div className={`${messagesStyles.chatHeight} `}>
          <ScrollToBottom
            className="chat-scroller"
            scrollViewClassName="chat-scroll-view"
          >
            {msgList?.map((val, index) => (
              <div
                key={index}
                className="py-1 px-2 g-0"
                style={{
                  display: "flex",
                  justifyContent:
                    localUserData.userId === val.senderId
                      ? "flex-end"
                      : "flex-start",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {/* ${val?.senderId == localUserData.userId ? "text-end" : "text-start"} */}
                {/* ${val?.senderId == localUserData.userId ? "text-end" : "text-start"} */}
                <div className="col-0">
                  {/* {val?.senderId == localUserData.userId ?
                                    <img src={credentialLoginAccountProfileDetailAtom?.data?.profileImgPath ? credentialLoginAccountProfileDetailAtom?.data?.profileImgPath : 'https://cdn-icons-png.flaticon.com/512/149/149071.png'} style={imgDp} /> :
                                    <img src={allMsgData?.studentProfileImageUrl ? allMsgData?.studentProfileImageUrl : 'https://cdn-icons-png.flaticon.com/512/149/149071.png'} style={imgDp} />
                                } */}
                </div>
                <div
                  style={{
                    flexGrow: 1,
                    paddingLeft: "10px",
                    display: "flex",
                    justifyContent:
                      localUserData.userId === val.senderId
                        ? "flex-end"
                        : "flex-start",
                  }}
                >
                  <div
                    className="message-container"
                    style={{
                      borderRadius: "9px",
                      padding: "5px",
                      display: "flex",
                      flexDirection: "column",
                      //textAlign: localUserData.userId === val.senderId ? 'right' : 'left'
                    }}
                  >
                    <div
                      style={{
                        padding: "10px",
                        borderRadius: "10px",
                        display: "flex",
                        backgroundColor:
                          localUserData.userId === val.senderId
                            ? "rgb(232,232,232)"
                            : "white",
                      }}
                    >
                      <p
                        className={` m-0 p-0 fw-600 fz-14px`}
                        style={{
                          wordWrap: "break-word",
                          maxWidth: "400px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        {/* <span> {val?.senderId == localUserData.userId ? localUserData?.name : allMsgData.studentName} </span>   */}
                        <p
                          className={` m-0 p-0 fw-500 fz-15px text-prime`}
                          style={{ wordWrap: "break-word" }}
                        >
                          {val?.message.split("\n").map((line, lineIndex) => (
                            <React.Fragment key={lineIndex}>
                              {lineIndex > 0 && <br />}
                              {line.startsWith("http") ||
                              line.startsWith("www") ? (
                                convertLinks(line)
                              ) : (
                                <span style={{ whiteSpace: "pre-wrap" }}>
                                  {line}
                                </span>
                              )}
                            </React.Fragment>
                          ))}
                        </p>
                      </p>
                    </div>
                    <div className="text-muted fz-10px fw-400 me-1 ">
                      {moment(val?.dt).format("DD/MM/YYYY, hh:mm A")}{" "}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </ScrollToBottom>
        </div>
        {/* -------Input---------- */}
        <form
          className="w-100 d-flex mt-2   pb-2  position-relative"
          onSubmit={sendMsg}
        >
          <textarea
            className=" default-inpt rounded-9 pe-4"
            style={{
              overflow: "hidden",
              maxHeight: "50px",
              height: "50px",
              minHeight: "50px",
              resize: "none",
              pxMed,
            }}
            placeholder="write a messagee..."
            type={"text"}
            ref={message}
            onKeyDown={handleKeyDown}
          />
          <button
            className="border-0 align-arrow  fz-18px bg-transparent text-prime"
            type="submit"
          >
            <IoMdSend />
          </button>
        </form>
      </div>
    </div>
  );
}
export default ChatWindow;
