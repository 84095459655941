import { atom, selector } from "recoil";
import { API_CONSTANTS } from "./../../common/constants";

const { BASE_URL, ALL_COURSES } = API_CONSTANTS;

export const coursesEndpointState = atom({
  key: "coursesEndpoint-4d54-8f8e-b5a8a1e6dbd6",
  default: BASE_URL + ALL_COURSES + "?",
});

export const searchState = atom({
  key: "search-b7484ce1-9d5da040260a",
  default: {
    typingstring: "",
    typedString: "",
  },
});

export const selectedCategoryCodeState = atom({
  key: "selectedCategoryState-08-b01d-fe683557654e",
  default: "",
});

// New, Popular
export const extraFilterState = atom({
  key: "extraFilterState-6a7afbea-272-b9aa624c257d",
  default: {
    key: "",
    value: "",
  },
});

// Filter
export const filterState = atom({
  key: "filterState-dae7a36f-12213f6a960f",
  default: {
    selectedSubCategories: [],
    prices: [
      {
        name: "price-0-100",
        value: "0 - 100",
        label: "0 - 100",
        courseFeeStart: 0,
        courseFeeEnd: 100,
        selected: false,
      },
      {
        name: "price-101-200",
        value: "101 - 200",
        label: "101 - 200",
        courseFeeStart: 101,
        courseFeeEnd: 200,
        selected: false,
      },
      {
        name: "price-201-300",
        value: "201 - 300",
        label: "201 - 1300",
        courseFeeStart: 201,
        courseFeeEnd: 300,
        selected: false,
      },
    ],
  },
});

export const courseSearchFullEndpointState = selector({
  key: "courseSearchFullEndpoint-34b7-46c4-9edb-93c1f73ce03b",
  get: ({ get }) => {
    let coursesEndpoint = get(coursesEndpointState);
    const search = get(searchState);
    const selectedCategoryCode = get(selectedCategoryCodeState);
    const extraFilter = get(extraFilterState);
    const filter = get(filterState);

    if (search.typedString) {
      coursesEndpoint += `givenCourseName=${search.typedString}&`;
    }

    if (selectedCategoryCode) {
      coursesEndpoint += `givenCategoryName=${selectedCategoryCode}&`;
    }

    if (extraFilter.key && extraFilter.value) {
      coursesEndpoint += `${extraFilter.key}=${extraFilter.value}&`;
    }

    const subCategoryParamsString = filter.selectedSubCategories.reduce(
      (total, currentCode) => {
        return (total += `givenSubCategoryName=${currentCode}&`);
      },
      ""
    );

    coursesEndpoint += subCategoryParamsString;

    const pricesString = filter.prices.reduce((total, current) => {
      return (total += current.selected
        ? `courseFeeStart=${current.courseFeeStart}&courseFeeEnd=${current.courseFeeEnd}&`
        : "");
    }, "");

    coursesEndpoint += pricesString;

    return coursesEndpoint;
  },
});
