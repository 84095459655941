import { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import swal from 'sweetalert';
import { useSetRecoilState } from 'recoil';
import { Modal } from 'react-bootstrap'
import { AiFillAndroid, AiFillApple } from 'react-icons/ai'
import CategoryCourses from './CategoryCourses';
import CoursesList from '../common/CoursesList';
// import LiveCoursesList from '../common/LiveCoursesList';
import studentImg from "../../images/home/student.png";
import instructorImg from "../../images/home/instructor.png";
import tealRectangle from "../../images/home/rectangle-teal.png";
import litePinkRectangle from "../../images/home/rectangle-pink.png";
import LearnersCarousel from "./LearnersCarousel";
import { API_CALL } from './../../api/ApiServiceCall';
import { API_CONSTANTS, API_METHOD } from './../../common/constants';
import { selectedCategoryCodeState } from '../atoms/course-list-atom';
import { useIsLoggedIn } from '../hooks/useIsLoggedIn';
import InstructorAppRedirectModal from '../../common/InstructorAppRedirectModal';

// Caregory Icons
import designIcon from '../../images/home/design.svg';
import codingIcon from '../../images/home/coding.svg';
import financeIcon from '../../images/home/finance.svg';
import filmPhotographyIcon from '../../images/home/film-photography.svg';
import itSoftwareIcon from '../../images/home/it-software.svg';
import marketingIcon from '../../images/home/marketing.svg';
import musicalNotesIcon from '../../images/home/musical-notes.svg';
import pieChartIcon from '../../images/home/pie-chart.svg';

// Start Learning Images
import buyCourseImg from '../../images/home/buy-course.png';
import createAccountImg from '../../images/home/create-account.png';
import selectCourseImg from '../../images/home/select-course.png';
import learnImg from '../../images/home/learn.png';
import "../css/home-resposive.css";

function Home() {
    const [popularCourses, setPopularCourses] = useState({ loading: true, courses: [], error: null });
    const [featuredCourses, setFeaturedCourses] = useState({ loading: true, courses: [], error: null });
    console.log('featuredCourses are', featuredCourses);
    const [liveCourses, setLiveCourses] = useState({ loading: true, courses: [], error: null });
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const setSelectedCategoryCode = useSetRecoilState(selectedCategoryCodeState);
    const [startLearningVideo, setStartLearningVideo] = useState({
        title: '',
        url: ''
    });
    const { isLoggedIn } = useIsLoggedIn();

    const getPopularCourses = async () => {
        setPopularCourses({ loading: true, courses: [], error: null });

        const request = {
            apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.POPULAR_COURSES,
            method: API_METHOD.GET,
        }

        let result = await API_CALL(request);

        if (result.status === 200) {
            setPopularCourses({ loading: false, courses: result.data, error: null });
        } else if (result.status > 200) {
            setPopularCourses({ loading: false, courses: [], error: result.message });

            if (result.message == "Token is Not Valid") return null

            swal({
                title: "ERROR",
                text: result.message,
                icon: 'https://cdn-icons-png.flaticon.com/512/2569/2569174.png',
                className: "error-window",
                button: true,
            })
        }
    }

    const getLiveCourses = async () => {
        setLiveCourses({ loading: true, courses: [], error: null });

        const request = {
            apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.LIVE_COURSES,
            method: API_METHOD.GET,
        }

        let result = await API_CALL(request);

        if (result.status === 200) {

            // result.data.forEach(item => {
            //     console.log(item.toTime);
            // });


            // console.log("LIVE CLASSES+++++++++", result.data.toTime);

            const futureLiveCourses = result?.data?.filter(course => {
                const currentDate = new Date();
                const targetDate = new Date(course.toTime);
                targetDate.setDate(targetDate.getDate() + 1);

                if (currentDate > targetDate) {
                    return false;
                } else if (currentDate < targetDate) {
                    return true;
                } else {
                    return true;
                }
            })

            setLiveCourses({ loading: false, courses: futureLiveCourses, error: null });
        } else if (result.status > 200) {
            setLiveCourses({ loading: false, courses: [], error: result.message });

            if (result.message == "Token is Not Valid") return null

            swal({
                title: "ERROR",
                text: result.message,
                icon: 'https://cdn-icons-png.flaticon.com/512/2569/2569174.png',
                className: "error-window",
                button: true,
            })
        }
    }

    const getFeaturedCourses = async () => {
        setFeaturedCourses({ loading: true, courses: [], error: null });

        const request = {
            apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.FEATURED_COURSES,
            method: API_METHOD.GET,
        }

        let result = await API_CALL(request);

        if (result.status === 200) {
            setFeaturedCourses({ loading: false, courses: result.data, error: null });
        } else if (result.status > 200) {
            setFeaturedCourses({ loading: false, courses: [], error: result.message });

            if (result.message == "Token is Not Valid") return null

            swal({
                title: "ERROR",
                text: result.message,
                icon: 'https://cdn-icons-png.flaticon.com/512/2569/2569174.png',
                className: "error-window",
                button: true,
            })
        }
    }


    // FEATURED_COURSES

    useEffect(() => {
        setSelectedCategoryCode('');
        getFeaturedCourses();
        getLiveCourses();
        getPopularCourses();
    }, [])

    const handleExploreAll = () => {
        navigate('/student-courses')
    }

    const handleCategorySelect = (categoryCode) => {


        // alert(categoryCode);

        setSelectedCategoryCode(categoryCode);
        navigate('/student-courses')
    }


    // if (navigator?.userAgentData?.mobile) {
    //     handleShow();
    // }


    const handleClose = () => setShow(false);
    const handleShow = () => { setShow(true); }

    useEffect(() => {
        const userAgent = window.navigator.userAgent;
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) {
            //modal will show if true
            handleShow();
        }
    }, [])

    return (
        <div className="student-home">
            <div className="hero-container bg-white">
                <div className="row mx-auto pt-4">
                    <div className="hero-content pt-5 col d-flex flex-column justify-content-center align-items-start" style={{ paddingBottm: '0.1px' }}>
                        <h2 className="teal-text hero-heading">
                            <div>Step into the realm of 
                            and infinite eLearning odyssey 
                            and elevate your skills!</div>
                        </h2>
                        <p className="my-1 py-0">Learn top-of-the-line, on-demand, and interactive courses crafted by our expert instructors.</p>
                        <button onClick={handleExploreAll} className="btn explore-all-btn teal-text py-2 px-4 hero-explore-all-btn" style={{ background: 'crimson', display: 'flex', alignItems: 'center', flexShrink: 0 }}>
                            <span className="me-2" style={{ color: '#395061' }}>Explore Courses</span>
                            <svg width="18" height="12" viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M22.6531 9.23649L15.3614 16.5281C15.165 16.7179 14.9018 16.8229 14.6287 16.8205C14.3556 16.8181 14.0943 16.7086 13.9012 16.5155C13.7081 16.3223 13.5985 16.0611 13.5961 15.7879C13.5938 15.5148 13.6988 15.2517 13.8885 15.0552L19.4021 9.5417H1.08329C0.807026 9.5417 0.542074 9.43195 0.346723 9.2366C0.151373 9.04125 0.041626 8.7763 0.041626 8.50003C0.041626 8.22376 0.151373 7.95881 0.346723 7.76346C0.542074 7.56811 0.807026 7.45836 1.08329 7.45836H19.4021L13.8885 1.94483C13.789 1.84874 13.7097 1.7338 13.6551 1.60671C13.6005 1.47962 13.5717 1.34293 13.5705 1.20462C13.5693 1.06631 13.5957 0.929144 13.6481 0.801126C13.7004 0.673111 13.7778 0.556807 13.8756 0.459002C13.9734 0.361197 14.0897 0.283848 14.2177 0.231472C14.3457 0.179096 14.4829 0.15274 14.6212 0.153942C14.7595 0.155144 14.8962 0.183882 15.0233 0.238474C15.1504 0.293066 15.2653 0.372421 15.3614 0.47191L22.6531 7.76357C22.8484 7.95891 22.9581 8.22382 22.9581 8.50003C22.9581 8.77624 22.8484 9.04115 22.6531 9.23649Z" fill="#395061" />
                            </svg>
                        </button>
                    </div>
                    <div className="col pt-5 home-hero-right-section" style={{
                        backgroundImage: `url(${litePinkRectangle}),url(${tealRectangle})`,
                        backgroundSize: '100%, 100%',
                        backgroundRepeat: 'no-repeat, no-repeat',
                    }}>
                        <img src={studentImg} alt="Student with Books" className="student-image" />
                    </div>
                </div>
            </div>
            <div className="home-container">
                <CategoryCourses />
                <div className="horizontal-courses-container">
                    {featuredCourses.courses?.length > 0 && <CoursesList title="Featured Courses" data={featuredCourses} />}
                    {popularCourses.courses?.length > 0 && <CoursesList title="Popular Courses" data={popularCourses} />}
                    {liveCourses.courses?.length > 0 && <CoursesList title="Live Courses" data={liveCourses} />}                            
                </div>



                <section className="attend-assesments p-5   d-flex justify-content-between align-items-center" style={{ background: '#F0E1EB' }}>
                    <div style={{ maxWidth: '700px' }}>
                        <h2 className="fs-3 teal-text">Attend Assessments</h2>
                        <p className="fs-6 mt-3">Learn on-the-go with Qlearning where a world of knowledge awaits! </p>
                    </div>
                    <div className="d-flex flex-column justify-content-between">
                        <Link to="/all-assessments" className="btn text-white px-5 py-3" style={{ backgroundColor: '#395061' }}>
                        Explore Assessments
                        </Link>
                        {isLoggedIn && (
                            <Link to="/my-assessments" className="btn text-white px-2 py-3 mt-3" style={{ backgroundColor: '#395061' }}>
                                My Assessments
                            </Link>
                        )}
                    </div>
                </section>
                <section className="courses-category-section  mt-5">
                    <h2 className="fs-3">Select your desired course by category</h2>
                    <div className="row g-3 mt-2">
                        <div className="col-6 col-sm-3">
                            <div className="p-3 rounded-4 text-center bg-white teal-text category-box-hover" style={{ cursor: 'pointer' }} onClick={() => handleCategorySelect('CA1005')}>
                                <img src={designIcon} alt="design icon" className="mt-3" />
                                <h4 className="fs-6 mt-3">Design</h4>
                            </div>
                        </div>
                        <div className="col-6 col-sm-3">
                            <div className="p-3 rounded-4 text-center bg-white teal-text category-box-hover" style={{ cursor: 'pointer' }} onClick={() => handleCategorySelect('CA1019')}>
                                <img src={codingIcon} alt="coding icon" className="mt-3" />
                                <h4 className="fs-6 mt-3">Development</h4>
                            </div>
                        </div>
                        <div className="col-6 col-sm-3">
                            <div className="p-3 rounded-4 text-center bg-white teal-text category-box-hover" style={{ cursor: 'pointer' }} onClick={() => handleCategorySelect('CA1022')}>
                                <img src={marketingIcon} alt="Marketing icon" className="mt-3" />
                                <h4 className="fs-6 mt-3">Marketing</h4>
                            </div>
                        </div>
                        <div className="col-6 col-sm-3">
                            <div className="p-3 rounded-4 text-center bg-white teal-text category-box-hover" style={{ cursor: 'pointer' }} onClick={() => handleCategorySelect('CA1002')}>
                                <img src={itSoftwareIcon} alt="it and software icon" className="mt-3" />
                                <h4 className="fs-6 mt-3">It & Software</h4>
                            </div>
                        </div>
                        <div className="col-6 col-sm-3">
                            <div className="p-3 rounded-4 text-center bg-white teal-text category-box-hover" style={{ cursor: 'pointer' }} onClick={() => handleCategorySelect('CA1018')}>
                                <img src={financeIcon} alt="Finance icon" className="mt-3" />
                                <h4 className="fs-6 mt-3">Finance</h4>
                            </div>
                        </div>
                        <div className="col-6 col-sm-3">
                            <div className="p-3 rounded-4 text-center bg-white teal-text category-box-hover" style={{ cursor: 'pointer' }} onClick={() => handleCategorySelect('CA1001')}>
                                <img src={filmPhotographyIcon} alt="Film photography icon" className="mt-3" />
                                <h4 className="fs-6 mt-3">Film & Photography</h4>
                            </div>
                        </div>
                        <div className="col-6 col-sm-3">
                            <div className="p-3 rounded-4 text-center bg-white teal-text category-box-hover" style={{ cursor: 'pointer' }} onClick={() => handleCategorySelect('CA1020')}>
                                <img src={pieChartIcon} alt="Pie Chart Icon" className="mt-3" />
                                <h4 className="fs-6 mt-3">Business</h4>
                            </div>
                        </div>
                        <div className="col-6 col-sm-3">
                            <div className="p-3 rounded-4 text-center bg-white teal-text category-box-hover" style={{ cursor: 'pointer' }} onClick={() => handleCategorySelect('CA1021')}>
                                <img src={musicalNotesIcon} alt="Musical Notes Icon" className="mt-3" />
                                <h4 className="fs-6 mt-3">Music</h4>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="start-learning-section p-5 mt-5" style={{ background: '#F0E1EB', color: '#395061' }}>
                    <h2 className="fs-4">Get Started Now!</h2>
                    <div className="learning-section mt-4">
                        <div className="learn-card">
                            <div
                                // onClick={() => setStartLearningVideo({
                                //     title: 'Create Account',
                                //     url: 'https://dev-ql-files.s3.ap-south-1.amazonaws.com/course%2Fvideo-1681119315704-create-account.mov'
                                // })}
                                className="py-5 rounded-4 text-center bg-white"
                                style={{
                                    color: '#395061',
                                    // cursor: 'pointer'
                                }}>
                                <img src={createAccountImg} alt="account image" />
                                <h4 className="fs-6 mt-3 mb-0">Create an Account</h4>
                            </div>
                        </div>
                        <div className="learn-card">
                            <div
                                // onClick={() => setStartLearningVideo({
                                //     title: 'Select Course',
                                //     url: 'https://dev-ql-files.s3.ap-south-1.amazonaws.com/course%2Fvideo-1681119624234-select-courses.mov'
                                // })}
                                className="py-5 rounded-4 text-center bg-white"
                                style={{ color: '#395061',
                                //  cursor: 'pointer'
                                  }}>
                                <img src={selectCourseImg} alt="account image" />
                                <h4 className="fs-6 mt-3 mb-0">Select Course</h4>
                            </div>
                        </div>
                        <div className="learn-card">
                            <div 
                            // onClick={() => setStartLearningVideo({
                            //     title: 'Buy Course',
                            //     url: 'https://dev-ql-files.s3.ap-south-1.amazonaws.com/course%2Fvideo-1681119514738-buy-course.mov'
                            // })} 
                            className="py-5 rounded-4 text-center bg-white"
                             style={{ color: '#395061',
                            //   cursor: 'pointer'
                               }}>
                                <img src={buyCourseImg} alt="account image" />
                                <h4 className="fs-6 mt-3 mb-0">Buy Course</h4>
                            </div>
                        </div>
                        <div className="learn-card">
                            <div 
                            // onClick={() => setStartLearningVideo({
                            //     title: 'Learn',
                            //     url: 'https://dev-ql-files.s3.ap-south-1.amazonaws.com/course%2Fvideo-1681119420039-learn.mov'
                            // })} 
                            className="py-5 rounded-4 text-center bg-white" style={{ color: '#395061',
                            //  cursor: 'pointer' 
                             }}>
                                <img src={learnImg} alt="account image" />
                                <h4 className="fs-6 mt-3 mb-0">Start Learning</h4>
                            </div>
                        </div>
                    </div>
                </section>
                <Modal show={startLearningVideo.url} onHide={() => setStartLearningVideo({ title: '', url: '' })} animation={true} centered size="lg" backdrop={"static"} className='mh'>
                    <Modal.Header closeButton={true} className='mh'>
                        <Modal.Title className='mdlt'>
                            {startLearningVideo.title}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <video
                            src={startLearningVideo.url}
                            controls
                            style={{ width: '100%' }}
                        />
                    </Modal.Body>
                </Modal>
                <LearnersCarousel />
                <section className="become-an-instructor rounded-5 d-flex mt-5">
                    <div className="p-5">
                        <h2 className="fs-3 teal-text">Become A Teacher Today</h2>
                        <p>“Join our team of passionate teachers and share your knowledge with eager learners from around the world."</p>
                        <button className="btn text-white px-4 py-2" style={{ backgroundColor: '#395061' }} onClick={() => navigate('/')}>
                            <span className="me-2">Register for Free</span>
                            <svg width="18" height="12" viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M22.6531 9.23649L15.3614 16.5281C15.165 16.7179 14.9018 16.8229 14.6287 16.8205C14.3556 16.8181 14.0943 16.7086 13.9012 16.5155C13.7081 16.3223 13.5985 16.0611 13.5961 15.7879C13.5938 15.5148 13.6988 15.2517 13.8885 15.0552L19.4021 9.5417H1.08329C0.807026 9.5417 0.542074 9.43195 0.346723 9.2366C0.151373 9.04125 0.041626 8.7763 0.041626 8.50003C0.041626 8.22376 0.151373 7.95881 0.346723 7.76346C0.542074 7.56811 0.807026 7.45836 1.08329 7.45836H19.4021L13.8885 1.94483C13.789 1.84874 13.7097 1.7338 13.6551 1.60671C13.6005 1.47962 13.5717 1.34293 13.5705 1.20462C13.5693 1.06631 13.5957 0.929144 13.6481 0.801126C13.7004 0.673111 13.7778 0.556807 13.8756 0.459002C13.9734 0.361197 14.0897 0.283848 14.2177 0.231472C14.3457 0.179096 14.4829 0.15274 14.6212 0.153942C14.7595 0.155144 14.8962 0.183882 15.0233 0.238474C15.1504 0.293066 15.2653 0.372421 15.3614 0.47191L22.6531 7.76357C22.8484 7.95891 22.9581 8.22382 22.9581 8.50003C22.9581 8.77624 22.8484 9.04115 22.6531 9.23649Z" fill="#fff" />
                            </svg>
                        </button>
                    </div>
                    <div className="d-flex align-items-end instructor-image">
                        <img src={instructorImg} alt="Instructor" />
                    </div>
                </section>
            </div>
            {/* <InstructorAppRedirectModal /> */}
            <br /><br /><br />
        </div>
    )
}

export default Home