import React, { useEffect } from "react";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import { useRecoilState } from "recoil";
import { currentViewComponentLsnAssessAtomFunc } from "../../../atom/lsnChapAssessComponentRender";
import MainUploadedVideoComponent from "./main-uploaded-video-component/mainUploadedVideoComponent";

function VideoOverview() {
  useEffect(() => {
    setCurrentViewComponentLsnAssessAtom(""); //resetting the value if routing link change!
  }, []);

  const [
    currentViewComponentLsnAssessAtom,
    setCurrentViewComponentLsnAssessAtom,
  ] = useRecoilState(currentViewComponentLsnAssessAtomFunc);

  return (
    <div className="container video-tabs overview-card">
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <Row>
          <Col sm={12}>
            <Nav variant="pills">
              <Nav.Item>
                <Nav.Link eventKey="first">Uploaded Videos</Nav.Link>
              </Nav.Item>
              {/* <Nav.Item>
                                <Nav.Link className='px-5' eventKey="second">Live Videos</Nav.Link>
                            </Nav.Item> */}
            </Nav>
          </Col>
          <Col sm={12}>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                {currentViewComponentLsnAssessAtom ? (
                  currentViewComponentLsnAssessAtom
                ) : (
                  <MainUploadedVideoComponent />
                )}
              </Tab.Pane>
              {/* --------- */}
              {/* <Tab.Pane eventKey="second"> */}
              {/* <LiveClassVideoContent /> */}

              {/* <LiveClassMainView /> */}

              {/* </Tab.Pane> */}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
}
export default VideoOverview;
