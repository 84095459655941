import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { API_CALL } from "../../api/ApiServiceCall";
import { API_CONSTANTS, API_METHOD } from "../../common/constants";
import { wishListState } from "../atoms/wishlist-atom";
import WishListCourse from "./WishListCourse";

function WishList() {
  const [wishList, setWishList] = useRecoilState(wishListState);
  const [isLoading, setIsLoading] = useState(true); // New state to handle loading indicator

  const getData = async () => {
    try {
      const [wishListResult] = await Promise.all([
        API_CALL({
          apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.WISH_LIST,
          method: API_METHOD.GET,
        }),
      ]);
      if (
        wishListResult.status === 200 ||
        wishListResult.message === "No items added."
      ) {
        setWishList(wishListResult.data ? wishListResult.data : []);
      } else if (wishListResult.status > 200) {
        console.error("Error fetching wish list:", wishListResult.message);
      }
    } catch (error) {
      console.error("Unexpected error:", error);
    } finally {
      setIsLoading(false); // Update loading state regardless of success or failure
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="student-conatiner py-5 mt-4">
      <h2 className="fs-3">Wishlist</h2>
      <div className="mt-4">
        {isLoading ? ( // Display loading spinner
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : wishList?.length > 0 ? (
          wishList.map((course) => (
            <WishListCourse
              key={course._id}
              course={course}
              liveCourse={course.isLive}
              getData={getData}
            />
          ))
        ) : (
          <p style={{ color: "gray" }}>Your wishlist is empty!</p>
        )}
      </div>
    </div>
  );
}

export default WishList;
