import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useRecoilState } from "recoil";
import swal from "sweetalert";
import { API_CALL } from "../../../../../api/ApiServiceCall";
import { API_CONSTANTS, API_METHOD } from "../../../../../common/constants";
import { liveCourseFormDataState } from "../../../../atom/liveCourseFormDataState";
import { liveCourseStepFormViewAtomState } from "../../../../atom/liveCourseStepFormViewAtom";
function Step1LiveCourse() {
  const [getCategory, setGetCategory] = useState([]);
  const [getSubCategory, setGetSubCategory] = useState(false);
  const [getCategoryForFormValidation, setCategoryForFormValidation] =
    useState(false);
  const [
    liveCourseStepFormViewAtomComponent,
    setLiveCourseStepFormViewAtomComponent,
  ] = useRecoilState(liveCourseStepFormViewAtomState);
  const [liveCourseFormDataAtom, setLiveCourseFormDataAtom] = useRecoilState(
    liveCourseFormDataState
  );
  useEffect(() => {
    getAllCategory();
  }, []);
  const getAllCategory = async () => {
    const request = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.GET_ALL_CATEGORY,
      method: API_METHOD.GET,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      setGetCategory(result.data);
      // alert(JSON.stringify(result));
    } else {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }
  };

  const getCurrentCategoryCode = (e) => {
    setGetSubCategoryFormData("");
    setGetCategoryFormData(e.target.value);
    // const index = e.target.selectedIndex;
    // const el = e.target.childNodes[index]
    // const option = el.getAttribute('id');
    getSubCategoryByCode(e.target.value);
    setCategoryForFormValidation(e.target.value);
  };
  const getSubCategoryByCode = async (catCode) => {
    const request = {
      apiUrl:
        API_CONSTANTS.BASE_URL +
        API_CONSTANTS.GET_SUB_CATEGORY_BY_CATEGORYCODE +
        `/?categoryCode=${catCode}`,
      method: API_METHOD.GET,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      setGetSubCategory(result);
    } else if (result.status > 200) {
      console.log("errror", result);
    }
  };
  // const onBlurGetInputData = (e) => {
  //     setLiveCourseFormDataAtom({ ...liveCourseFormDataAtom })
  // }
  const [liveCourseTitle, setLiveCourseTitle] = useState("");
  const [liveCourseOverview, setLiveCourseOverview] = useState("");
  // const [liveNextCourseDetails, setLiveNextCourseDetails] = useState("");
  const [getCategoryFormData, setGetCategoryFormData] = useState("");
  const [getSubCategoryFormData, setGetSubCategoryFormData] = useState("");
  const [isValidate, setIsValidate] = useState({
    courseTitle: false,
    liveCourseOverview: false,
    liveNextCourseDetails: false,
    getCategoryFormData: false,
    getSubCategoryFormData: false,
  });

  const nextStep2 = (e) => {
    e.preventDefault();
    // setLiveCourseStepFormViewAtomComponent({ componentName: "Step2" })
    const isAllow =
      liveCourseTitle &&
      liveCourseOverview &&
      getCategoryFormData &&
      getSubCategoryFormData;
    if (isAllow) {
      setLiveCourseStepFormViewAtomComponent({ componentName: "Step2" });
      setLiveCourseFormDataAtom({
        courseTitle: liveCourseTitle,
        courseOverview: liveCourseOverview,
        // nextCourseDetails: liveNextCourseDetails,
        category: getCategoryFormData,
        subCategory: getSubCategoryFormData,
      });
    } else {
      setIsValidate({
        courseTitle: true,
        liveCourseOverview: true,
        getCategoryFormData: true,
        getSubCategoryFormData: true,
      });
    }
  };
  return (
    <div>
      {/* {liveCourseOverview} */}
      <form onSubmit={nextStep2}>
        <label className="pb-2 fz-14px text-dark fw-500">Course Title</label>
        <input
          type="text"
          className="default-inpt"
          defaultValue={liveCourseTitle}
          onBlur={() => setIsValidate({ ...isValidate, courseTitle: true })}
          onChange={(e) => setLiveCourseTitle(e.target.value)}
        />
        {isValidate.courseTitle && liveCourseTitle === "" ? (
          <small className="text-danger my-0 d-block fz-12px mt-3 fw-500">
            Please Fill this field
          </small>
        ) : (
          ""
        )}
        <div className="py-2"></div>
        <label className="pb-2 fz-14px text-dark fw-500">Course Overview</label>
        <ReactQuill
          className="rich-h"
          onChange={setLiveCourseOverview}
          onBlur={() =>
            setIsValidate({ ...isValidate, liveCourseOverview: true })
          }
        />
        {liveCourseOverview == "<p><br></p>" && setLiveCourseOverview("")}

        <div className="pt-5"></div>
        {isValidate.liveCourseOverview && liveCourseOverview === "" ? (
          <small className="text-danger my-0 d-block fz-12px mt-3 fw-500">
            Please Fill this field
          </small>
        ) : (
          ""
        )}
        <div className="pt-1"></div>
        {/* <label className='pb-2 fz-14px text-dark fw-500'>Next Course Details</label>
                <ReactQuill className="rich-h" onChange={setLiveNextCourseDetails} onBlur={() => setIsValidate({ ...isValidate, liveNextCourseDetails: true })} />
                {liveNextCourseDetails == "<p><br></p>" && setLiveNextCourseDetails("")} */}
        {/* <div className="pt-5"></div>
                {isValidate.liveNextCourseDetails && liveNextCourseDetails === "" ? <small className='text-danger my-0 d-block fz-12px mt-3 fw-500'>Please Fill this field</small> : ""}
                <div className="pt-1"></div> */}

        <div className="row">
          <div className="col">
            <label className="fz-14px text-dark fw-500 pb-3">Category</label>

            <div className="tag-ajus mb-2">
              <select
                defaultValue={""}
                onChange={getCurrentCategoryCode}
                onBlur={() =>
                  setIsValidate({ ...isValidate, getCategoryFormData: true })
                }
              >
                <option value="" className="d-none">
                  Select a category
                </option>
                {getCategory ? (
                  getCategory.map((catDatas, index) => (
                    <option
                      key={catDatas._id + index}
                      value={catDatas.categoryCode}
                    >
                      {catDatas.categoryName}
                    </option>
                  ))
                ) : (
                  <option value="">Loading...</option>
                )}
              </select>
            </div>
            {isValidate.getCategoryFormData && getCategoryFormData === "" ? (
              <small className="text-danger my-0 d-block fz-12px mt-3 fw-500">
                Please Fill this field
              </small>
            ) : (
              ""
            )}
            {/* {getCategoryFormData} */}
          </div>
          <div className="col-lg-1"></div>
          <div className="col">
            <label className="fz-14px text-dark fw-500 pb-3">
              Sub - Category
            </label>
            <div className="tag-ajus mb-2">
              <select
                defaultValue={""}
                onChange={(e) => setGetSubCategoryFormData(e.target.value)}
                onBlur={() =>
                  setIsValidate({ ...isValidate, getSubCategoryFormData: true })
                }
              >
                <option value="" className="d-none">
                  Select a Sub - category
                </option>
                {getSubCategory
                  ? getSubCategory.data.map((subCatDatas, index) => (
                      <option
                        key={subCatDatas._id + "key" + index}
                        value={subCatDatas.subCategoryCode}
                      >
                        {subCatDatas.subCategoryName}
                      </option>
                    ))
                  : ""}
              </select>
            </div>
            {isValidate.getSubCategoryFormData &&
            getSubCategoryFormData === "" ? (
              <small className="text-danger my-0 d-block fz-12px mt-3 fw-500">
                Please Fill this field
              </small>
            ) : (
              ""
            )}
            {/* {getSubCategoryFormData} */}
          </div>
        </div>
        <div className="d-flex  justify-content-end py-4">
          <button
            className="border-0 bg-prime text-white fz-14px py-12px w-14 rounded-3 text-center"
            type="submit"
          >
            Next
          </button>
        </div>
      </form>

      <div className="pt-3"></div>
    </div>
  );
}

export default Step1LiveCourse;
