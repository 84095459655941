import React, { useEffect, useState } from "react";
import { Nav, Navbar, Offcanvas } from "react-bootstrap";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import swal from "sweetalert";
import { API_CALL } from "../../api/ApiServiceCall";
import { API_CONSTANTS, API_METHOD, STORAGE_KEY } from "../../common/constants";
import useCookie from "../../common/cookie_hook";
import "../../css/responsive.css";
import { notificationsDataAtomState } from "../atom/notificationsDataAtomState";
import notificationStyles from "../courses/notification-module-css/notification.module.css";
import CredentialProfileImageForMenu from "../profile/teacher/profile-image-upload/credentialProfileImage";
import NotificationIcon from "../svg-icons/notificationIcon";
import CoursesSidebar from "./sidebar";
function CourseTopMenu() {
  const isLoggedIn = useCookie(STORAGE_KEY.AUTH);
  const localStorageData = JSON.parse(
    localStorage.getItem(STORAGE_KEY.LOCAL_USER_DATA)
  );
  const location = useLocation();
  const [title, setTitle] = useState("Course");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    changeTitle(location.pathname);
  }, [location]);

  const changeTitle = (path) => {
    switch (path) {
      case "/transaction":
        setTitle("Instructor Dashboard");
        break;
      case "/courses/recorded/learnslist":
        setTitle("Learners List");
        break;
      case "/courses/recorded":
        setTitle("Recorded Courses");
        break;
      case "/mylearners":
        setTitle("My Learners");
        break;
      case "/demo-class/view":
        setTitle("Demo Class");
        break;
      case "/courses/live":
        setTitle("Live Courses");
        break;
      case "/courses/live/assessment":
        setTitle("Assessments");
        break;
      case "/courses/live/create-assessment":
        setTitle("Create Assessment");
        break;
      case "/courses/live/edit-assessment":
        setTitle("Edit Assessment");
        break;
      case "/courses/live/inner-independent-assessment":
        setTitle("Create Assessment");
        break;
      case "/demo-class":
        setTitle("Demo Class");
        break;
      case "/messages":
        setTitle("Messages");
        break;
      default:
        setTitle("Course");
        break;
    }
  };

  const [getNotificationsAtom, setNotificationsAtom] = useRecoilState(
    notificationsDataAtomState
  );
  const getAllNotifications = async () => {
    const request = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.GET_NOTIFICATIONS,
      method: API_METHOD.GET,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      setNotificationsAtom(result.data);
    } else if (result.status > 200) {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }
  };
  useEffect(() => {
    getAllNotifications();
    // let interval = setInterval(() => getAllNotifications(), 5000);
    // return () => clearInterval(interval);
  }, []);
  return (
    <>
      {/* Mobile menu---------------------- */}
      <div className="sm-block ">
        <div className="row g-1  text-end  py-3">
          <div className="col-2 my-auto text-start">
            <button
              type="button"
              className=" bg-transparent border-0"
              onClick={handleShow}
            >
              <GiHamburgerMenu size={18} className="text-prime" />
            </button>
          </div>
          {/* <div className="col  my-auto"> {title}</div> */}
          <div className="col my-auto">
            {" "}
            <Link to="/student-home" className="menu-font-style me-lg-4">
              Become A Student
            </Link>
          </div>

          <div className="col-2 my-auto">
            <Link className="menu-font-style" to="/transaction">
              Dashboard
            </Link>
          </div>

          <div className="col my-auto">
            {" "}
            <Link
              to="/instructor-notification"
              className="notification mx-lg-4 position-relative"
            >
              <span
                className={`${
                  getNotificationsAtom.count
                    ? notificationStyles.badge
                    : "d-none"
                }`}
              >
                {getNotificationsAtom.count}
              </span>
              <NotificationIcon classNameProp={"bell-svg"} />
            </Link>
          </div>
          <div className="col my-auto">
            {isLoggedIn && localStorageData ? (
              <Link to="/profile/teacher/teacher-profile">
                {<CredentialProfileImageForMenu />}
              </Link>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          {/* <Offcanvas.Title><Link to="/"><FullLogoIcon /></Link></Offcanvas.Title> */}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <CoursesSidebar />
        </Offcanvas.Body>
      </Offcanvas>

      {/* Mobile menu end---------------------- */}

      {/* ===========PC menu============ */}
      <Navbar className="py-lg-3 px-4  lg-block" expand="lg">
        <div className="p-0 mx-2 d-flex align-items-center">
          {/* {title} this title state will change automatically according to page */}
          <Navbar.Brand className="fz-16px fw-500">{title}</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto align-items-lg-center">
              <Nav.Item className="ml-lg-auto">
                <Link to="/student-home" className="menu-font-style me-lg-4">
                  Become A Student
                </Link>
              </Nav.Item>
              <Nav.Item className="ml-lg-auto">
                <Link className="menu-font-style" to="/transaction">
                  Dashboard
                </Link>
              </Nav.Item>

              <Link
                to="/instructor-notification"
                className="notification mx-lg-4 position-relative"
              >
                <span
                  className={`${
                    getNotificationsAtom.count
                      ? notificationStyles.badge
                      : "d-none"
                  }`}
                >
                  {getNotificationsAtom.count}
                </span>
                <NotificationIcon classNameProp={"bell-svg"} />
              </Link>
              {isLoggedIn && localStorageData ? (
                <Link to="/profile/teacher/teacher-profile">
                  {<CredentialProfileImageForMenu />}
                </Link>
              ) : (
                ""
              )}
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </>
  );
}

export default CourseTopMenu;
