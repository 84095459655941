import React from "react";
import { useRecoilState } from "recoil";
import { currentViewComponentLsnAssessAtomFunc } from "../../../../../../atom/lsnChapAssessComponentRender";
import AssesmentIcon from "../../../../../../svg-icons/assesmentIcon";
import RightArrow from "../../../../../../svg-icons/rightArrow";
import ViewAssessment from "./viewAssessment";

function AssessmentList({ assessmentListData }) {
  const [
    currentViewComponentLsnAssessAtom,
    setCurrentViewComponentLsnAssessAtom,
  ] = useRecoilState(currentViewComponentLsnAssessAtomFunc);

  return (
    <div>
      {/* <pre> {JSON.stringify(assessmentListData, null, 1)}</pre> */}
      <div
        className="chp-list d-flex justify-content-md-between align-items-center"
        onClick={() =>
          setCurrentViewComponentLsnAssessAtom(
            <ViewAssessment singleClickedAssessment={assessmentListData} />
          )
        }
      >
        <h1 className="m-0 fz-16px fw-500">
          <AssesmentIcon /> {assessmentListData.lessonName}
        </h1>
        <RightArrow size={8} />
      </div>
    </div>
  );
}

export default AssessmentList;
