import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useRecoilState } from "recoil";
import swal from "sweetalert";
import {
  API_CONSTANTS,
  API_METHOD,
} from "../../../../../../../common/constants";
import { clickedChapterDataListAtomFunc } from "../../../../../../atom/clickedChapterDataListAtomFunc";
import { currentViewComponentLsnAssessAtomFunc } from "../../../../../../atom/lsnChapAssessComponentRender";
import CloseFileIcon from "../../../../../../svg-icons/closeFileIcon";
import LeftArrow from "../../../../../../svg-icons/leftArrow";
import UploadIcon from "../../../../../../svg-icons/uploadIcon";
import SingleChapterView from "../singleChapterView";
import MyS3Component from "../../../../../../s3/s3";

function LessonCreationForm() {
  const {
    register,
    reset,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [source, setSource] = React.useState();
  const [loading, setLoading] = useState(false);
  const [resource, setResource] = useState([]);
  const [disableSave, setDisableSave] = useState(false);
  const [
    currentViewComponentLsnAssessAtom,
    setCurrentViewComponentLsnAssessAtom,
  ] = useRecoilState(currentViewComponentLsnAssessAtomFunc);
  const [clickedChapterDataListAtom, setClickedChapterDataListAtom] =
    useRecoilState(clickedChapterDataListAtomFunc);
  const [imageFileValidation, setImageFileValidation] = useState(false);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    let fileFormat = file.name.split(".").pop();
    if (fileFormat == "mp4") {
      setImageFileValidation(false);
      setDisableSave(false);
      const url = URL.createObjectURL(file);
      setSource(url);
    } else {
      setImageFileValidation("The video file format should be mp4");
      setDisableSave(true);
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);

    try {
    const userCode = JSON.parse(localStorage.getItem("userCode"));
    const formData = new FormData();
    formData.append("lessonName", data.lessonName);
    formData.append("courseCode", userCode.data);
      formData.append("chapterOrder", clickedChapterDataListAtom.data.chapterOrder);
      formData.append("fileCount", resource.length + 1);
      formData.append("lesson", data.lesson[0]);

      resource.forEach((resourceData, index) => {
        formData.append("lesson", resource[index]);
      });

      const usrdetailforHeader = JSON.parse(localStorage.getItem("localUserData"));
      let apiHeader = {
        "x-auth-token": usrdetailforHeader?.jwt,
      };

      if (usrdetailforHeader?.data?.user?.email) {
        apiHeader = {
          token: usrdetailforHeader?.data?.user?.email,
          gmailUserType: "INSTRUCTOR",
        };
      }

      const requestConfig = {
        url: API_CONSTANTS.BASE_URL + API_CONSTANTS.CREATE_LESSON,
        method: API_METHOD.POST,
        onUploadProgress: (progressEvent) => {
          console.log("progressEvent", progressEvent)
          let percentComplete = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          setLoading(percentComplete);
        },
        headers: apiHeader,
        data: formData,
      };

      const response = await axios(requestConfig);

      if (response.status === 200) {
        setLoading(false);
        setCurrentViewComponentLsnAssessAtom(
          <SingleChapterView clickedChapterData={clickedChapterDataListAtom} />
        );
      }
    } catch (error) {
      setLoading(false);

      if (error.response) {
        swal({
          title: "ERROR",
          text: error?.response?.data?.message,
          icon: "error",
          className: "error-window",
          button: true,
        });
      } else {
        swal({
          title: "ERROR",
          text: error.message,
          icon: "error",
          className: "error-window",
          button: true,
        });
      }
    }
  };


  const removeResource = (resIndex) => {
    const updatedFileList = [...resource];
    updatedFileList.splice(resIndex, 1);
    setResource(updatedFileList);
  };

  const onChangeGetFilesSelected = (e) => {
    const fileArray = e.target.files;
    let filesArr = Array.prototype.slice.call(fileArray);

    const supportedExtensions = [".docx", ".pptx", ".txt", ".pdf", ".xlsx", ".xls"];
    const filteredFiles = filesArr.filter((file) => {
      const extension = file.name.split(".").pop().toLowerCase();
      return supportedExtensions.includes("." + extension);
    });

    const unsupportedFiles = filesArr.filter(
      (file) => !filteredFiles.includes(file)
    );

    if (unsupportedFiles.length > 0) {
      const unsupportedExtensions = unsupportedFiles
        .map((file) => file.name.split(".").pop().toLowerCase())
        .join(", ");

      setImageFileValidation(
        `Unsupported file(s) detected: ${unsupportedExtensions}. Please upload files with extensions .docx, .pptx, .txt, .pdf, .xlsx, or .xls.`
      );
    } else {
      setImageFileValidation("");
    }

    const convertToNoSpaces = (name) => {
      return name.replace(/\s+/g, '_'); // Replace spaces with underscores
    };

    const convertedFiles = filteredFiles.map(file => {
      const newName = convertToNoSpaces(file.name);
      return new File([file], newName, { type: file.type });
    });

    setResource((prevState) => prevState.concat(convertedFiles));
  };

  return (
    <div>
      <>
        <div className="d-flex justify-content-end align-items-center">
          <button
            form="lessonName"
            className="border-0 fz-15px   bg-transparent   me-auto"
            role="button"
            onClick={() =>
              setCurrentViewComponentLsnAssessAtom(
                <SingleChapterView
                  clickedChapterData={clickedChapterDataListAtom}
                />
              )
            }
          >
            <LeftArrow size={7} /> Back
          </button>
          {/* <button className="p-0 m-0 border-0 bg-transparent fz-14px fw-500 text-danger" onClick={() => setCurrentViewComponentLsnAssessAtom(<SingleChapterView clickedChapterData={clickedChapterDataListAtom} />)}> <FiTrash2 size={17} className="text-danger" /> Delete</button> */}
        </div>
        <div className="login-col pt-4">
          <form id="lessonName" onSubmit={handleSubmit(onSubmit)}>
            <input
              type="text"
              placeholder="Lesson Name Here"
              {...register("lessonName")}
              required
            />
            <p className="text-muted fz-12px">
              <b className="fw-500">Note:</b> The video file format should be
              mp4.{" "}
            </p>
            {source ? (
              <>
                {" "}
                <video
                  className="VideoInput_video rounded-2"
                  width="100%"
                  height="auto"
                  controls
                  src={source}
                />
              </>
            ) : (
              ""
            )}
            {imageFileValidation ? (
              <small className="text-danger mb-2 d-block fz-12px fw-500">
                {imageFileValidation}
              </small>
            ) : (
              ""
            )}

            <input
              className={source ? "d-none" : "file-prim"}
              id="change_video"
              type="file"
              {...register("lesson")}
              onChange={handleFileChange}
              accept=".mp4"
              required
            />
            <h6>Resources</h6>

            <p className="text-muted fz-12px">
              <b className="fw-500">Note:</b> Supported file formats are .docx,
              .pptx, .txt, .pdf, .xlsx, .xls{" "}
            </p>
            <input
              id="uploadFile"
              type="file"
              onClick={(e) => (e.currentTarget.value = null)}
              onChange={onChangeGetFilesSelected}
              multiple={true}
              accept=".docx,.pptx,.txt,.pdf,.xlsx,.xls"
              className="d-none"
            />
            {resource
              ? resource.map((file, index) => (
                  <div key={index}>
                    <div
                      className="d-inline-block"
                      type="button"
                      onClick={() => removeResource(index)}
                    >
                      {" "}
                      <CloseFileIcon size={29} />
                    </div>
                    {file.name}
                  </div>
                ))
              : ""}
          </form>
        </div>
        <div className="d-flex align-items-center justify-content-end">
          <label
            htmlFor="uploadFile"
            className="border-0 bg-prime text-white me-auto my-3 ms-lg-2 fz-14px py-2 px-4 rounded-3 "
            type="button"
            disabled={loading ? true : false}
          >
            <UploadIcon size={17} /> Upload Resource
          </label>
        </div>
        <div className="d-flex align-items-center justify-content-end">
          <button
            form="lessonName"
            className="border-0 bg-prime text-white ms-lg-2 fz-14px py-2 px-4 rounded-3 text-center"
            role="button"
            type="submit"
            disabled={loading || disableSave}
          >
            {loading ? (
              <>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                {loading}% Uploading...
              </>
            ) : (
              "Save"
            )}
          </button>
        </div>

        {/* <div className="d-flex justify-content-end ">

                    <button form="lessonName" className='border-0 bg-secondary text-white fz-14px py-2 px-5 rounded-3 text-center' role="button" onClick={() => setCurrentViewComponentLsnAssessAtom(<SingleChapterView clickedChapterData={clickedChapterDataListAtom} />)}>Cancel</button>

                    <button form="lessonName" className='border-0 bg-prime text-white ms-lg-2 fz-14px py-2 px-5 rounded-3 text-center' role="button" type='submit' disabled={loading ? true : false} >{loading ? <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Uploading...</> : 'Save'}</button>
                </div> */}
      </>
    </div>
  );
}

export default LessonCreationForm;
