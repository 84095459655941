import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { COMPONENT_IDENTIFIER } from './common/constants';
import DemoClassAuth from './common/live-room/DemoClassAuth';
import PrivateRoomRoute from './common/live-room/PrivateRoomRoute';
import PageNotFound from './common/page_not_found';
import ToastComponent from './common/toastComponent';
import WrapperComponent from './common/wrapper_component';
import AppGverify from './components/app/appGverify';
import AppRoomAuth from './components/app/AppRoomAuth';
import DemoClassRoomAuthApp from './components/app/DemoClassRoomAuthApp';
import PrivacyPolicy from './components/app/privacyPolicy';
import AssessmentsPreview from './components/assessment';
import InstructorCoursePreview from './components/courses/course-preview';
import CourseOverview from './components/courses/course-steps/course_overview';
import LiveClassVideoContent from './components/courses/course-steps/video-inside-overview/liveVideoContent';
import Courses from './components/courses/createCourse';
import { CreateIndependentAssessment } from './components/courses/live-course/independent-assessment/createIndependentAssessment';
import { EditIndependentAssessment } from './components/courses/live-course/independent-assessment/editIndependentAssessment';
import { IndependentAssessment } from './components/courses/live-course/independent-assessment/independentAssessment';
import InnerIndependentAssessmentCreation from './components/courses/live-course/independent-assessment/innerIndependentAssessmentCreation';
import LiveCoursesList from './components/courses/live-course/live-course-list/liveCoursesList';
import OverviewLiveCourse from './components/courses/live-course/live-course-overview/overviewLiveCourse';
import LiveCoursesStepForm from './components/courses/live-course/live-course-step-form/liveCoursesStepForm';
import StudentLearnersList from './components/courses/recorded/learnersList';
import RecordedCourses from './components/courses/recorded/recordedCourses';
import Transaction from './components/courses/transaction';
import CreateAccount from './components/create-account/index';
import DashboardPage from './components/dashboard/dashboardPage';
import DemoClassList from './components/demoClass/DemoClassList';
import DemoClassOverview from './components/demoClass/DemoClassOverview';
import InstructorNotification from './components/instructor-notification/InstructorNotification';
import InstructorLogin from './components/login';
import MyLearners from './components/mylearners/myLearners';
import PrivateRoute from './components/PrivateRoute';
import TeacherProfile from './components/profile/teacher/teacher-profile';
import Teacher from './components/teacher/teacher';
import ContactUs from './static-pages/ContactUs';
import Faq from './static-pages/Faq';
import StudentFaq from './student/studentFaq';
import PrivacyPolicyStudent from './static-pages/PrivacyPolicy';
import Support from './static-pages/Support';
import TermsAndConditions from './static-pages/TermsAndConditions';
import Assessment from './student/assessment';
import Cart from './student/cart';
import Checkout from './student/checkout';
import Course from './student/course';
import CoursesPage from './student/courses';
import CreateStudentAccount from './student/create-account';
import Dashboard from './student/dashboard';
import Home from './student/home/home';
import Assessments from './student/independent-assessment/Assessments';
import AttendIndependantAssessment from './student/independent-assessment/AttendIndependantAssessment';
import MyAssessments from './student/independent-assessment/MyAssessments';
import InstructorProfile from './student/instructor-profile';
import LiveClass from './student/live-class';
import StudentLogin from './student/login';
import MyCourses from './student/my-courses';
import Notifications from './student/Notifications';
import PaymentHistory from './student/payment-history';
import StudentProfile from './student/profile';
import CoursesSearch from './student/search';
import ScrollToTop from './student/common/ScrollToTop';
import MessagesMain from './components/messages/main-page/MessagesMain';
// Below requestForToken from firebase is necessary for initiation.
// Don't remove requestForToken import
import { requestForToken } from './components/firebase/firebase';
import StudentMessagesMain from './student/messages/main-page/MessagesMain';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        {/* Below component for auto page scroll to top if we change page */}
        <ScrollToTop />
        <Routes>
          {/* --------App web view component-------- */}
          <Route path="*" element={<PageNotFound />} />
          <Route path="/live-room-app/:roomId" element={
            <AppRoomAuth />
          } />
          {/* <Route path="/demo-class-room-app/:roomId" element={
            <DemoClassRoomAuthApp />
          } /> */}

          {/* --------App web view component End-------- */}
          {/* These are public routes */}

          <Route path="/create-account" element={<PrivateRoute isSignupUrl={true} signupComponent={<CreateAccount />} />} />
          <Route path="app/policy.html" element={<PrivacyPolicy />} />
          <Route path="app/googleverify/" element={<AppGverify />} />
          {/* <Route path="/login" element={<PrivateRoute isloginUrl={true} loginComponent={<InstructorLogin />} />} /> */}
          <Route path="/login" element={<InstructorLogin />} />

          <Route path="" element={
            <WrapperComponent componentIdentifier={COMPONENT_IDENTIFIER.HOME}>
              <Teacher />
            </WrapperComponent>
          } />
          <Route path="/home" element={
            <WrapperComponent componentIdentifier={COMPONENT_IDENTIFIER.HOME}>
              <Teacher />
            </WrapperComponent>
          } />
          <Route path="/live-room/:roomId" element={
            // <WrapperComponent>
            <PrivateRoomRoute />
            // </WrapperComponent>
          } />
          {/* ------Demo class route------- */}
          <Route path="/live-demo-class/:roomId" element={
            <DemoClassAuth />
          } />

          {/* ------Demo class end ------- */}


          {/* These are private routes wrapped inside a PrivateRoute check */}
          <Route path="/" element={<PrivateRoute pagesFor="INSTRUCTOR" />} >
            <Route path="dashboard" element={
              <WrapperComponent componentIdentifier={COMPONENT_IDENTIFIER.DASHBOARD}>
                <DashboardPage />
              </WrapperComponent>
            } />
            <Route path="courses" element={
              <WrapperComponent componentIdentifier={COMPONENT_IDENTIFIER.COURSE}>
                <Courses />
              </WrapperComponent>
            } />
            <Route path="/courses/live-courses-overview" element={
              <WrapperComponent componentIdentifier={COMPONENT_IDENTIFIER.COURSE}>
                <OverviewLiveCourse />
              </WrapperComponent>
            } />

            <Route path="/courses/recorded" element={
              <WrapperComponent componentIdentifier={COMPONENT_IDENTIFIER.COURSE}>
                <RecordedCourses />
              </WrapperComponent>
            } />
            <Route path="/courses/live" element={
              <WrapperComponent componentIdentifier={COMPONENT_IDENTIFIER.COURSE}>
                <LiveCoursesList />
              </WrapperComponent>
            } />
            <Route path="/courses/live/step-form" element={
              <WrapperComponent componentIdentifier={COMPONENT_IDENTIFIER.COURSE}>
                <LiveCoursesStepForm />
              </WrapperComponent>
            } />
            <Route path="/courses/live/assessment" element={
              <WrapperComponent componentIdentifier={COMPONENT_IDENTIFIER.COURSE}>
                <IndependentAssessment />
              </WrapperComponent>
            } />
            <Route path="/courses/live/edit-assessment" element={
              <WrapperComponent componentIdentifier={COMPONENT_IDENTIFIER.COURSE}>
                <EditIndependentAssessment />
              </WrapperComponent>
            } />

            <Route path="/courses/live/create-assessment" element={
              <WrapperComponent componentIdentifier={COMPONENT_IDENTIFIER.COURSE}>
                <CreateIndependentAssessment />
              </WrapperComponent>
            } />
            <Route path="/courses/live/inner-independent-assessment" element={
              <WrapperComponent componentIdentifier={COMPONENT_IDENTIFIER.COURSE}>
                <InnerIndependentAssessmentCreation />
              </WrapperComponent>
            } />
            <Route path="/courses/recorded/learnslist" element={
              <WrapperComponent componentIdentifier={COMPONENT_IDENTIFIER.COURSE}>
                <StudentLearnersList />
              </WrapperComponent>
            } />
            <Route path="/transaction" element={
              <WrapperComponent componentIdentifier={COMPONENT_IDENTIFIER.COURSE_OVERVIEW}>
                <Transaction />
              </WrapperComponent>
            } />
            <Route path="/courses/overview" element={
              <WrapperComponent componentIdentifier={COMPONENT_IDENTIFIER.COURSE_OVERVIEW}>
                <CourseOverview />
              </WrapperComponent>
            } />
            <Route path="/instructor-live-class/:roomId" element={
              <WrapperComponent>
                <LiveClassVideoContent />
              </WrapperComponent>
            } />


            <Route path="/profile/teacher/teacher-profile" element={
              <WrapperComponent componentIdentifier={COMPONENT_IDENTIFIER.PROFILE}>
                <TeacherProfile />
              </WrapperComponent>
            } />
            <Route path="/instructor-notification" element={
              <WrapperComponent componentIdentifier={COMPONENT_IDENTIFIER.PROFILE}>
                <InstructorNotification />
              </WrapperComponent>
            } />
            <Route path="/demo-class" element={
              <WrapperComponent componentIdentifier={COMPONENT_IDENTIFIER.COURSE_OVERVIEW}>
                <DemoClassList />
              </WrapperComponent>
            } />
            <Route path="/demo-class/view" element={
              <WrapperComponent componentIdentifier={COMPONENT_IDENTIFIER.COURSE_OVERVIEW}>
                <DemoClassOverview />
              </WrapperComponent>
            } />
            <Route path="/mylearners" element={
              <WrapperComponent componentIdentifier={COMPONENT_IDENTIFIER.COURSE}>
                <MyLearners />
              </WrapperComponent>
            } />
            <Route path="/messages" element={
              <WrapperComponent componentIdentifier={COMPONENT_IDENTIFIER.COURSE}>
                <MessagesMain />
              </WrapperComponent>
            } />
            <Route path="/course-preview/:courseCode" element={
              <WrapperComponent componentIdentifier={COMPONENT_IDENTIFIER.HOME}>
                <div className="py-3"></div>
                <InstructorCoursePreview />
              </WrapperComponent>
            } />

            {/* AssessmentsPreview */}
            {/* /assessment */}

            <Route path="/assessment-preview" element={
              <WrapperComponent componentIdentifier={COMPONENT_IDENTIFIER.HOME}>
                <div className="py-3"></div>
                <AssessmentsPreview />
              </WrapperComponent>
            } />

            {/* -------------------Common Route For Live Start------------------------ */}
            {/* <Route path="/live-room/:roomId" element={
              <WrapperComponent>
                <PrivateRoomRoute />
              </WrapperComponent>
            } /> */}
          </Route>
          {/* -------------------Common Route For Live End------------------------ */}

          {/* Student public routes */}
          <Route path="/student-login" element={<PrivateRoute isloginUrl={true} isStudentLoginUrl={true} loginComponent={<StudentLogin />} />} />
          <Route path="/create-student-account" element={<PrivateRoute isSignupUrl={true} signupComponent={<CreateStudentAccount />} />} />
          <Route path="/student-home" element={
            <WrapperComponent componentIdentifier={COMPONENT_IDENTIFIER.STUDENT_HOME}>
              <Home />
            </WrapperComponent>
          } />
          <Route path="/instructor-profile/:instructorId" element={
            <WrapperComponent componentIdentifier={COMPONENT_IDENTIFIER.STUDENT_HOME}>
              <InstructorProfile />
              {/* <p>INSTRUCTOR</p> */}
            </WrapperComponent>
          } />
          {/* search */}
          {/* <Route path="/instructor-profile/:instructorId" element={
            <WrapperComponent componentIdentifier={COMPONENT_IDENTIFIER.STUDENT_HOME}>
              <InstructorProfile />
            </WrapperComponent>
          } /> */}
          <Route path="/search" element={
            <WrapperComponent componentIdentifier={COMPONENT_IDENTIFIER.STUDENT_HOME}>
              <CoursesSearch />
            </WrapperComponent>
          } />
          <Route path="/course/:courseCode" element={
            <WrapperComponent componentIdentifier={COMPONENT_IDENTIFIER.STUDENT_HOME}>
              <Course />
            </WrapperComponent>
          } />
          <Route path="/student-courses" element={
            <WrapperComponent componentIdentifier={COMPONENT_IDENTIFIER.STUDENT_HOME}>
              <CoursesPage />
            </WrapperComponent>
          } />
          <Route path="/live-class/:liveUUID" element={
            <WrapperComponent componentIdentifier={COMPONENT_IDENTIFIER.STUDENT_HOME}>
              <LiveClass />
            </WrapperComponent>
          } />

          <Route path="/all-assessments" element={
            <WrapperComponent componentIdentifier={COMPONENT_IDENTIFIER.STUDENT_HOME}>
              <Assessments />
            </WrapperComponent>
          } />




          {/* import ContactUs from './static-pages/ContactUs';
          import Faq from './static-pages/Faq';
          import PrivacyPolicyStudent from './static-pages/PrivacyPolicy';
          import Support from './static-pages/Support';
          import TermsAndConditions from './static-pages/TermsAndConditions'; */}

          <Route path="/contact-us" element={
            <WrapperComponent componentIdentifier={COMPONENT_IDENTIFIER.STUDENT_HOME}>
              <ContactUs />
            </WrapperComponent>
          } />

          <Route path="/faq" element={
            <WrapperComponent componentIdentifier={COMPONENT_IDENTIFIER.STUDENT_HOME}>
              <Faq />
            </WrapperComponent>
          } />

          <Route path="/student-faq" element={
            <WrapperComponent componentIdentifier={COMPONENT_IDENTIFIER.STUDENT_HOME}>
              <StudentFaq />
            </WrapperComponent>
          } />

          <Route path="/privacy-and-policy" element={
            <WrapperComponent componentIdentifier={COMPONENT_IDENTIFIER.STUDENT_HOME}>
              <PrivacyPolicyStudent />
            </WrapperComponent>
          } />

          <Route path="/support" element={
            <WrapperComponent componentIdentifier={COMPONENT_IDENTIFIER.STUDENT_HOME}>
              <Support />
            </WrapperComponent>
          } />

          <Route path="/terms-and-conditions" element={
            <WrapperComponent componentIdentifier={COMPONENT_IDENTIFIER.STUDENT_HOME}>
              <TermsAndConditions />
            </WrapperComponent>
          } />


          {/* Student private routes */}
          <Route path="/" element={<PrivateRoute pagesFor="STUDENT" />}>
            <Route path="/profile" element={
              <WrapperComponent componentIdentifier={COMPONENT_IDENTIFIER.STUDENT_PROFILE}>
                <StudentProfile />
              </WrapperComponent>
            } />
            <Route path="/student-dashboard" element={
              <WrapperComponent componentIdentifier={COMPONENT_IDENTIFIER.STUDENT_HOME}>
                <Dashboard />
              </WrapperComponent>
            } />
            <Route path="/cart" element={
              <WrapperComponent componentIdentifier={COMPONENT_IDENTIFIER.STUDENT_HOME}>
                <Cart />
              </WrapperComponent>
            } />
            <Route path="my-courses/:type" element={
              <WrapperComponent componentIdentifier={COMPONENT_IDENTIFIER.STUDENT_HOME}>
                <MyCourses />
              </WrapperComponent>
            } />
            <Route path="/checkout" element={
              <WrapperComponent componentIdentifier={COMPONENT_IDENTIFIER.STUDENT_HOME}>
                <Checkout />
              </WrapperComponent>
            } />
            <Route path="/assessment" element={
              <WrapperComponent componentIdentifier={COMPONENT_IDENTIFIER.STUDENT_HOME}>
                <Assessment />
              </WrapperComponent>
            } />
            <Route path="/payment-history" element={
              <WrapperComponent componentIdentifier={COMPONENT_IDENTIFIER.STUDENT_HOME}>
                <PaymentHistory />
              </WrapperComponent>
            } />
            <Route path="/attend-assessment" element={
              <WrapperComponent componentIdentifier={COMPONENT_IDENTIFIER.STUDENT_HOME}>
                <AttendIndependantAssessment />
              </WrapperComponent>
            } />

            <Route path="/my-assessments" element={
              <WrapperComponent componentIdentifier={COMPONENT_IDENTIFIER.STUDENT_HOME}>
                <MyAssessments />
              </WrapperComponent>
            } />

            {/* <Route path="/live-room/:roomId" element={
              <WrapperComponent>
                <PrivateRoomRoute />
              </WrapperComponent>
            } /> */}

            {/* Notifications */}

            <Route path="/notifications" element={
              <WrapperComponent componentIdentifier={COMPONENT_IDENTIFIER.STUDENT_HOME}>
                <Notifications />
              </WrapperComponent>
            } />

            {/* /messages */}

            <Route path="/message-to-instrcutor" element={
              <WrapperComponent componentIdentifier={COMPONENT_IDENTIFIER.STUDENT_HOME}>
                <StudentMessagesMain />
              </WrapperComponent>
            } />

          </Route>
        </Routes>
      </BrowserRouter>

      {/* =======Alert Toast Globally===== */}
      <ToastComponent />
      {/* =======Alert Toast Globally End===== */}

    </div >

  );
}

export default App;
