import { useContext } from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { BsArrowLeftCircleFill, BsArrowRightCircleFill } from "react-icons/bs";
import CourseCard from "../home/CourseCard";

function CoursesList({ title, data }) {
  return (
    <section className="my-4 horizontal-courses-container">
      {/*  */}
      <h2
        className="fs-4 mb-4 horizontal-courses-heading"
        style={{ paddingLeft: 0, marginLeft: 0 }}
      >
        {title}
      </h2>
      {data?.loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "300px" }}
        >
          <div class="spinner-border" role="status">
            <span class="sr-only"></span>
          </div>
        </div>
      ) : (
        <div style={{ marginLeft: "-30px" }}>
          {/* <pre>{JSON.stringify(data)}</pre> */}
          <ScrollMenu
            LeftArrow={LeftArrow}
            RightArrow={RightArrow}
            scrollContainerClassName="scroll-container"
          >
            {data?.courses?.map((course) => (
              <CourseCard
                key={course._id}
                itemId={course._id}
                course={course}
                overlayPlacement="right"
                liveCourse={course.isLive}
                forHorizontalList={true}
              />
            ))}
          </ScrollMenu>
        </div>
      )}
    </section>
  );
}

function LeftArrow() {
  const { isFirstItemVisible, scrollPrev } = useContext(VisibilityContext);

  return (
    <div
      className="d-flex align-items-center horizontal-scoll-button"
      style={{ visibility: isFirstItemVisible ? "hidden" : "visible" }}
    >
      <BsArrowLeftCircleFill
        style={{
          width: "45px",
          height: "45px",
          marginRight: "-20px",
          zIndex: "333",
          color: "rgba(57, 80, 97, 0.6)",
          cursor: "pointer",
        }}
        onClick={() => scrollPrev()}
      />
    </div>
  );
}

function RightArrow() {
  const { isLastItemVisible, scrollNext } = useContext(VisibilityContext);

  return (
    <div
      className="d-flex align-items-center horizontal-scoll-button"
      style={{ visibility: isLastItemVisible ? "hidden" : "visible" }}
    >
      <BsArrowRightCircleFill
        style={{
          width: "45px",
          height: "45px",
          marginLeft: "-35px",
          zIndex: "333",
          color: "rgba(57, 80, 97, 0.6)",
          cursor: "pointer",
        }}
        onClick={() => scrollNext()}
      />
    </div>
  );
}

export default CoursesList;
