import { atom } from "recoil";

export const cartState = atom({
  key: "cartCountState-1eecb3ac-bd4c-zd54-8f8e-b5a8a1e6d458",
  default: {
    subTotal: 0,
    currencyCode: "INR",
    discountCode: "",
    discountPercentage: "",
    discountValue: "",
    taxPercentage: 5,
    taxValue: 0,
    total: 0,
    items: [],
  },
});
