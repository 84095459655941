import { useRef, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { AiFillFastBackward, AiFillFastForward } from "react-icons/ai";
import { BiPause } from "react-icons/bi";
import { BsFillPlayFill } from "react-icons/bs";
import { GoMute, GoUnmute } from "react-icons/go";
import { RiFullscreenExitFill, RiFullscreenFill } from "react-icons/ri";
import ReactPlayer from "react-player";
import screenfull from "screenfull";
import "./video-player.css";

const format = (seconds) => {
  if (isNaN(seconds)) {
    return `00:00`;
  }
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = date.getUTCSeconds().toString().padStart(2, "0");
  if (hh) {
    return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
  }
  return `${mm}:${ss}`;
};

let count = 0;

function VideoPlayer({ thumbNailUrl, videoUrl, onEnded, enablePlayback }) {
  const [state, setState] = useState({
    light: true,
    playing: false,
    volume: 1,
    muted: false,
    playbackRate: 1.0,
    fullScreen: false,
    played: 0,
    seeking: false,
  });
  const [timeDisplayFormat, setTimeDisplayFormat] = useState("normal");

  const playerRef = useRef();
  const playerContainerRef = useRef();
  const controlsRef = useRef();

  const { light, playing, volume, muted, playbackRate, played, seeking } =
    state;

  const handlePlayIconClick = () => {
    setState((prevState) => ({
      ...prevState,
      light: false,
    }));
  };

  const handlePlayPause = () => {
    setState((prevState) => ({
      ...prevState,
      playing: !prevState.playing,
    }));
  };

  const handleRewind = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() - 10);
  };

  const handleFastForward = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() + 10);
  };

  const handleToggleMute = () => {
    setState((prevState) => ({
      ...prevState,
      muted: !prevState.muted,
    }));
  };

  const handleVolumeChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      volume: e.target.value / 100,
      muted: Number(e.target.value) === 0 ? true : false,
    }));
  };

  const handlePlayBackChange = (value) => {
    setState((prevState) => ({
      ...prevState,
      playbackRate: value,
    }));
  };

  const handleFullScreenToggle = () => {
    screenfull.toggle(playerContainerRef.current);
  };

  screenfull.onchange(() => {
    setState((prevState) => ({
      ...prevState,
      fullScreen: !prevState.fullScreen,
    }));
  });

  const handleProgress = (data) => {
    if (count > 3) {
      controlsRef.current.style.visibility = "hidden";
      count = 0;
    }

    if (controlsRef.current.style.visibility == "visible") {
      count += 1;
    }

    if (!seeking) {
      setState((prevState) => ({
        ...prevState,
        played: data.played,
      }));
    }
  };

  const handleSeek = (e) => {
    setState((prevState) => ({
      ...prevState,
      played: Number(e.target.value) / 100,
    }));
  };

  const handleSeekMouseDown = (e) => {
    setState((prevState) => ({
      ...prevState,
      seeking: true,
    }));
  };

  const handleSeekMouseUp = (e) => {
    setState((prevState) => ({
      ...prevState,
      seeking: false,
    }));

    playerRef.current.seekTo(e.target.value / 100);
  };

  const handleMouseMove = () => {
    console.log("mousemove");
    controlsRef.current.style.visibility = "visible";
    count = 0;
  };

  const hanldeMouseLeave = () => {
    controlsRef.current.style.visibility = "hidden";
    count = 0;
  };

  const currentTime =
    playerRef && playerRef.current
      ? playerRef.current.getCurrentTime()
      : "00:00";

  const duration =
    playerRef && playerRef.current ? playerRef.current.getDuration() : "00:00";

  const elapsedTime =
    timeDisplayFormat == "normal"
      ? format(currentTime)
      : `-${format(duration - currentTime)}`;

  const totalDuration = format(duration);

  return (
    <div
      className="position-relative rounded-4 overflow-hidden"
      ref={playerContainerRef}
      style={{ paddingTop: "56.25%" }}
      onMouseMove={handleMouseMove}
      onMouseLeave={hanldeMouseLeave}
    >
      <ReactPlayer
        light={light ? thumbNailUrl : false}
        // light={false}
        url={videoUrl}
        width="100%"
        height="100%"
        // style={{ borderRadius: '10px' }}
        playing={playing}
        // playing={true}
        muted={muted}
        volume={volume}
        playbackRate={playbackRate}
        className="d-block position-absolute top-0 left-0"
        playIcon={
          <svg
            onClick={handlePlayIconClick}
            width="70"
            height="70"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="50" cy="50" r="50" fill="white" />
            <path
              d="M68.5 45.6699C71.8333 47.5944 71.8333 52.4056 68.5 54.3301L44.5 68.1865C41.1667 70.111 37 67.7054 37 63.8564L37 36.1436C37 32.2946 41.1667 29.889 44.5 31.8135L68.5 45.6699Z"
              fill="#395061"
            />
          </svg>
        }
        onProgress={handleProgress}
        config={{
          file: {
            attributes: {
              controlsList: "nodownload",
            },
          },
        }}
        ref={playerRef}
        onEnded={onEnded}
        //className='position-absolute top-0 left-0'
        // controls
      />

      {/* !light */}

      {true && (
        <div
          className="position-absolute top-0 start-0 end-0 bottom-0 d-flex flex-column"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.1)" }}
          ref={controlsRef}
        >
          <div className="flex-grow-1" style={{ flexBasis: 0 }}></div>

          <div
            className="flex-grow-1 d-flex justify-content-center align-items-center"
            style={{ flexBasis: 0 }}
          >
            <AiFillFastBackward
              size="2.4rem"
              color="#fff"
              style={{ cursor: "pointer" }}
              onClick={handleRewind}
            />

            {playing ? (
              <BiPause
                size="3rem"
                color="#fff"
                style={{ cursor: "pointer" }}
                className="mx-4"
                onClick={handlePlayPause}
              />
            ) : (
              <BsFillPlayFill
                size="3rem"
                color="#fff"
                style={{ cursor: "pointer" }}
                className="mx-4"
                onClick={handlePlayPause}
              />
            )}

            <AiFillFastForward
              size="2.4rem"
              color="#fff"
              style={{ cursor: "pointer" }}
              onClick={handleFastForward}
            />
          </div>

          <div
            className="flex-grow-1 d-flex flex-column justify-content-end p-3 pt-0"
            style={{ flexBasis: 0 }}
          >
            <input
              type="range"
              min="0"
              max="100"
              value={played * 100}
              onChange={handleSeek}
              onMouseDown={handleSeekMouseDown}
              onMouseUp={handleSeekMouseUp}
              className="video-player-range-progress"
            />

            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                {muted ? (
                  <GoMute
                    size="1.6rem"
                    color="#fff"
                    style={{ cursor: "pointer" }}
                    onClick={handleToggleMute}
                  />
                ) : (
                  <GoUnmute
                    size="1.6rem"
                    color="#fff"
                    style={{ cursor: "pointer" }}
                    onClick={handleToggleMute}
                  />
                )}
                <input
                  type="range"
                  className="ms-4 mt-2 video-player-range-progress volume-range-progress"
                  min="0"
                  max="100"
                  value={volume * 100}
                  style={{ width: "120px" }}
                  onChange={handleVolumeChange}
                />

                <span
                  className="ms-4"
                  style={{ color: "#fff", width: "140px" }}
                >
                  {elapsedTime} / {totalDuration}
                </span>
              </div>
              <div className="d-flex align-items-center">
                {enablePlayback && (
                  <DropdownButton
                    key="up"
                    id={`dropdown-button-drop-up`}
                    drop="up"
                    variant="dark"
                    title={playbackRate + " X"}
                    style={{ fontSize: "1rem" }}
                    size="sm"
                    className="mx-4"
                  >
                    {[2.0, 1.5, 1.0, 0.5].map((item) => (
                      <Dropdown.Item
                        key={item}
                        eventKey="1"
                        className={`${
                          playbackRate === item && "bg-secondary text-white"
                        }`}
                        onClick={() => handlePlayBackChange(item)}
                      >
                        {item}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                )}

                {screenfull.isFullscreen ? (
                  <RiFullscreenExitFill
                    size="1.8rem"
                    color="#fff"
                    style={{ cursor: "pointer" }}
                    onClick={handleFullScreenToggle}
                  />
                ) : (
                  <RiFullscreenFill
                    size="1.8rem"
                    color="#fff"
                    style={{ cursor: "pointer" }}
                    onClick={handleFullScreenToggle}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

VideoPlayer.defaultProps = {
  enablePlayback: true,
};

export default VideoPlayer;
