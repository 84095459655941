import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { API_CALL } from "../../../api/ApiServiceCall";
import {
  API_CONSTANTS,
  API_METHOD,
  socketWebrtc,
} from "../../../common/constants";
import { handleInitMeeting } from "../../../utils/dyteApis";

function DemoTimeCounter({ clickedDemoClassData, dmData, app = false }) {
  console.log("clickedDemoClassData", clickedDemoClassData);
  console.log("clickedDemoClassData dmData", dmData);
  const [socket, setSocket] = useState(socketWebrtc);

  const [day, setDate] = useState(0);
  const [hour, setHour] = useState(0);
  const [min, setMin] = useState(0);
  const [sec, setSec] = useState(0);
  const [isJoinLive, setIsJoinLive] = useState(false);
  const navigate = useNavigate();

  let SEC;
  let MIN;
  let HOUR;
  let DAY;
  let date;

  useEffect(() => {
    const setTime = setInterval(() => {
      SEC = 1000;
      MIN = SEC * 60;
      HOUR = MIN * 60;
      DAY = HOUR * 24;
      date = Date.now;
      let mergedDateTime = moment(
        `${clickedDemoClassData?.demoDate} ${clickedDemoClassData?.demoTime}`,
        "YYYY-MM-DD HH:mm:ss"
      );
      let time = Date.parse(mergedDateTime) - Date.now();
      if (time <= 0) {
        clearInterval(setTime);
        setIsJoinLive(true);
      } else {
        setIsJoinLive(false);
        const dateTimeObj = {
          SEC: SEC,
          MIN: MIN,
          HOUR: HOUR,
          DAY: DAY,
          time: time,
        };
        getTime(dateTimeObj);
      }
    }, 1000);

    return () => {
      clearInterval(setTime);
    };
  }, [clickedDemoClassData.date, dmData]);

  const getTime = (arg) => {
    setDate(Math.floor(arg.time / arg.DAY));
    setHour(Math.floor((arg.time / (1000 * 60 * 60)) % 24));
    setMin(Math.floor((arg.time / arg.MIN) % 60));
    setSec(Math.floor((arg.time / arg.SEC) % 60));
  };
  const Timejsx = (
    <p className="p-0 m-0 fw-500">
      {day < 10 ? `0${day}` : day}
      <small className="fz-12px fw-600">d</small>:
      {hour < 10 ? `0${hour}` : hour}
      <small className="fz-12px fw-600">hr</small>:{min < 10 ? `0${min}` : min}
      <small className="fz-12px fw-600">m</small>
      {min == 0 ? (
        <>
          :{sec < 10 ? `0${sec}` : sec}
          <small className="fz-12px fw-600">s</small>
        </>
      ) : (
        ""
      )}
    </p>
  );

  const onStartClass = async () => {
    const userData = JSON.parse(localStorage.getItem("localUserData"));
    const roomId = clickedDemoClassData?.dyteId;
    const userType = "webinar_presenter";
    await handleInitMeeting(
      clickedDemoClassData?.topicName,
      userData,
      userType
    ).then((dyteData) => {
      onStartDemo(dyteData);
    });
  };

  //   ---onStart Live code
  const onStartDemo = async (dyteData) => {
    const requestBodyParam = JSON.stringify({
      courseCode: clickedDemoClassData?.courseCode,
      demoClassId: clickedDemoClassData?._id,
      dyteID: dyteData?.roomId,
      topicName: clickedDemoClassData?.topicName,
    });
    const request = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.START_LIVE_DEMO,
      method: API_METHOD.POST,
      requestBodyParams: requestBodyParam,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      navigate(`/live-demo-class/${dyteData?.tokenData}`);
      localStorage.setItem("demoClassId", clickedDemoClassData?._id);
    } else if (result.status > 200) {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        closeOnClickOutside: false,
        button: true,
      });
    }
  };
  // ---onStart Live code End

  // ===Globally call end==========

  const dateTimeEntryCheck = () => {
    let apiDate = moment(clickedDemoClassData.demoDate).format("YYYY-MM-DD");
    let apiTime = clickedDemoClassData.demoTime;
    let splittedTime = apiTime.split(":");
    let updatedDateTime = moment(apiDate).set({
      hour: splittedTime[0],
      minute: splittedTime[1],
    });
    let frmtToAddGrace = moment(updatedDateTime);
    let withOneHrGrace = moment(frmtToAddGrace).add("1", "hours");
    // console.log("Date Time:without grace ", moment(updatedDateTime).format());
    // console.log("Date Time:grace ", moment(withOneHrGrace).format());

    if (moment().isSameOrBefore(moment(withOneHrGrace))) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div>
      {/* <Link to={`/live-demo-class/${clickedDemoClassData?.instructorLink?.split("/")[1]}`} target="_blank">test</Link> */}
      {isJoinLive ? (
        dateTimeEntryCheck() ? (
          <button
            type="button"
            role={"button"}
            disabled
            className="bg-transparent border-0 text-danger fw-500 fz-15px"
          >
            Expired
          </button>
        ) : (
          <button
            type="button"
            role={"button"}
            className="bg-transparent border-0  fw-500 fz-15px"
            onClick={onStartClass}
          >
            Go Live
          </button>
        )
      ) : (
        Timejsx
      )}
    </div>
  );
}
export default DemoTimeCounter;
