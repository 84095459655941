import React, { useState, useEffect } from 'react';
import moment from 'moment';
import RaiseTicketChatWindowForTransaction from './RaiseTicket';
import Paginate from '../../../common/Pagination';
import '../../../css/transaction-page.css';

function LatestTransactionTable({ transactionPageTableData }) {
    const [isTicketOpen, setIsTicketOpen] = useState(-1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [itemOffset, setItemOffset] = useState(0);
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(null);
    //Pagination effect
    useEffect(() => {
        if (transactionPageTableData) {
            const endOffset = itemOffset + Number(itemsPerPage);
            let sliceItem = transactionPageTableData.slice(itemOffset, endOffset);
            setCurrentItems(sliceItem)
            let pageCountResult = Math.ceil(transactionPageTableData.length / Number(itemsPerPage));
            setPageCount(pageCountResult)
        }
    }, [transactionPageTableData, itemOffset, itemsPerPage])

    const handlePageClick = (event) => {
        const newOffset = (event.selected * Number(itemsPerPage)) % transactionPageTableData.length;
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
    };
    console.log("mustafa", transactionPageTableData)
    return (
        <div className=" ">
            {/* <pre>{JSON.stringify(transactionPageTableData, null, 4)}</pre> */}
            <div className="  bg-white rounded-3">
                <div className="px-lg-4 position-relative">
                    <div className="d-flex align-items-lg-center justify-content-lg-between">

                        {/* <div className="bg-pink px-4 py-2 rounded-2 d-flex align-items-center" role={"button"}>
                            <PrinterIcon width={19} />
                            <p className="ms-2 my-0 fz-14px fw-500 text-prime">Print</p>
                        </div> */}
                    </div>
                    <div className="py-2"></div>
                    {/* ------- */}
                    <div className="table-responsive-md">
                        <table className="table table-borderless ">
                            <thead>
                                <tr>
                                    <th className='text-muted fw-400 fz-15px'>Date</th>
                                    <th className='text-muted fw-400 fz-15px'>Email</th>
                                    <th className='text-muted fw-400 fz-15px'>Withdraw Fund</th>
                                    <th className='text-muted text-center fw-400 fz-15px'>Raise a Ticket</th>
                                    <th className='text-muted fw-400 fz-15px'>Status</th>

                                </tr>
                            </thead>
                            <tbody>
                                {!currentItems ? <tr className='text-center'>
                                    <td colSpan={"5"} className="py-4 fw-500 text-muted fz-15px">Loading...</td>
                                </tr> : currentItems.length ?
                                    currentItems.map((item, indx) => (
                                        <tr key={item?._id + indx}>
                                            {/* <td>{indx + 1}</td> */}
                                            <td>{moment(item?.requestedDate).format("DD MMM,YYYY")}</td>
                                            <td>{item?.instructorEmail}</td>
                                            <td>₹{item?.requestedAmount}</td>
                                            <td className='text-center'><RaiseTicketChatWindowForTransaction data={item} isTicketOpen={isTicketOpen} setIsTicketOpen={setIsTicketOpen} index={indx} /></td>
                                            <td><span className={`status w-100 ${item.requestedstatus}`}>{item.requestedstatus === 'PAID' ? 'RECIEVED' : item.requestedstatus }</span></td>
                                        </tr>
                                    ))
                                    : <tr className='text-center'>
                                        <td colSpan={"5"} className="py-4 fw-500 text-muted fz-15px">No data</td>
                                    </tr>}

                            </tbody>
                        </table>
                    </div>
                    {/* ------- */}
                    <div className="py-2"></div>

                    <div className="row">
                        <div className="col-lg-8">
                            <Paginate onPageChange={handlePageClick} pageCount={pageCount} isShow={currentItems?.length} />
                        </div>
                        <div className="col-lg-4 fz-13px fw-500 text-muted text-end">
                            Show  <select className='px-2 py-1 ' onChange={(e) => setItemsPerPage(e.target.value)}>
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={75}>75</option>
                                <option value={100}>100</option>
                            </select> transaction per page
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LatestTransactionTable
