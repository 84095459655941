import { Container, Modal } from "react-bootstrap";
import done from "../../images/login/success-note.png";
function CreateNewPasswordSuccess({ setSelected, handleClose }) {
  function handleSubmit() {
    handleClose();
    setSelected();
  }
  return (
    <Container>
      <Modal.Header closeButton className="mh"></Modal.Header>
      <Modal.Body>
        <img src={done.src} className="done" />
        <p className="text-center fw-bold py-3">Password Reset Successfully</p>
        <button
          className="login-form-btn mx-auto w-40 d-block mb-4"
          onClick={handleSubmit}
        >
          Done
        </button>
      </Modal.Body>
    </Container>
  );
}

export default CreateNewPasswordSuccess;
