import {
  DyteCameraToggle,
  DyteChatToggle,
  DyteEndedScreen,
  DyteGrid,
  DyteLeaveButton,
  DyteLeaveMeeting,
  DyteMeetingTitle,
  DyteMicToggle,
  DyteNotifications,
  DyteParticipantCount,
  DyteParticipantsAudio,
  DyteScreenShareToggle,
  DyteSidebar,
  DyteSpinner,
  defaultConfig,
} from "@dytesdk/react-ui-kit";
import { useDyteMeeting, useDyteSelector } from "@dytesdk/react-web-core";
import { useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./index.css";

const config = { ...defaultConfig };

if (config.root) {
  config.root["dyte-participant-tile"] =
    config.root["dyte-participant-tile"].children;
}

function InstructorDyteCall({ handleEnd, handleLeave }) {
  const navigate = useNavigate();
  const { meeting } = useDyteMeeting();
  const roomState = useDyteSelector((m) => m.self.roomState);
  console.log("roomState", roomState);

  const [states, updateStates] = useReducer(
    (state, payload) => ({
      ...state,
      ...payload,
    }),
    { meeting: "joined", activeSidebar: false }
  );

  const [isLeaveModalVisible, setIsLeaveModalVisible] = useState(false);

  const handleModalClick = (e) => {
    console.log("MODAL CANCEL CLICKED", e.target.innerText);
    setIsLeaveModalVisible(false);
  };

  useEffect(() => {
    console.log("++++++++++++++++++++++++++++++++++++++++++++++++");
    if (window.ReactNativeWebView) {
      // ensure window.ReactNativeWebView is there, otherwise, web app might crash if is not there
      window.ReactNativeWebView.postMessage("LIVE_START");
    }
  }, []);

  useEffect(() => {
    if (isLeaveModalVisible) {
      const dyteModalButtons = document
        .querySelector("dyte-leave-meeting")
        ?.shadowRoot?.querySelectorAll("dyte-button");

      if (dyteModalButtons?.length === 3) {
        const dyteCancelButtons = dyteModalButtons[0];
        const dyteLeaveButtons = dyteModalButtons[1];
        const dyteLeaveAllButtons = dyteModalButtons[2];

        dyteCancelButtons.addEventListener("click", handleModalClick);
        dyteLeaveButtons.addEventListener("click", handleModalClick);
        dyteLeaveAllButtons.addEventListener("click", handleModalClick);

        return () => {
          dyteCancelButtons.removeEventListener("click", handleModalClick);
          dyteLeaveButtons.removeEventListener("click", handleModalClick);
          dyteLeaveAllButtons.removeEventListener("click", handleModalClick);
        };
      }
    }
  }, [isLeaveModalVisible]);

  useEffect(() => {
    const liveCourseData = localStorage.getItem("liveCourseData");
    if (roomState === "ended") {
      handleEnd();
    }
    if (roomState === "left") {
      navigate(-1);
      handleLeave();
    }
  }, [roomState]);

  if (roomState === "ended") {
    return <DyteEndedScreen />;
  }

  if (!meeting) {
    return <DyteSpinner />;
  }

  return (
    <div
      className="instructor-dyte-container"
      ref={(el) => {
        el?.addEventListener("dyteStateUpdate", (e) => {
          updateStates(e.detail);
        });
      }}
    >
      <DyteParticipantsAudio meeting={meeting} />
      <DyteNotifications
        meeting={meeting}
        config={{
          config: {
            notifications: ["chat", "participant_joined", "participant_left"],
            notification_sounds: [
              "chat",
              "participant_joined",
              "participant_left",
            ],
            participant_joined_sound_notification_limit: 10,
            participant_chat_message_sound_notification_limit: 10,
          },
        }}
      />
      <div style={{ marginLeft: "23" }}>
        <DyteMeetingTitle meeting={meeting} />
        <DyteParticipantCount meeting={meeting} />
      </div>

      <main className="instructor-dyte-main">
        <DyteGrid meeting={meeting} config={config} />
        {states.activeSidebar && (
          <DyteSidebar meeting={meeting} states={states} />
        )}
      </main>

      <footer className="instructor-dyte-footer">
        <DyteMicToggle meeting={meeting} />
        <DyteCameraToggle meeting={meeting} />
        <DyteScreenShareToggle meeting={meeting} />
        <DyteLeaveButton onClick={() => setIsLeaveModalVisible(true)} />
        <DyteChatToggle meeting={meeting} />
      </footer>
      <div
        className={`instructor-dyte-leave-modal ${isLeaveModalVisible ? "modal-visible" : ""
          }`}
      >
        <DyteLeaveMeeting meeting={meeting} />
      </div>
    </div>
  );
}

export default InstructorDyteCall;
