import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import swal from "sweetalert";
import { API_CALL } from "../../../api/ApiServiceCall";
import {
  API_CONSTANTS,
  API_METHOD,
  STORAGE_KEY,
} from "../../../common/constants";
import useCookie from "../../../common/cookie_hook";
import { getCredentialLoginAccountProfileDetailAtom } from "../../../components/atom/getCredentialLoginAccountProfileDetail";
import { profileState } from "../../atoms/profileAtom";

function UserProfile({ updateUserDetails, setUserData }) {
  const [profileData, setProfileData] = useState("");
  const [profileLoading, setProfileLoading] = useState(true);
  const [getCredentialUserDetail, setCredentialUserDetail] = useState(
    JSON.parse(localStorage.getItem("localUserData"))
  );
  const [
    credentialLoginAccountProfileDetailAtom,
    setCredentialLoginAccountProfileDetailAtom,
  ] = useRecoilState(getCredentialLoginAccountProfileDetailAtom);
  const [profileAtom, setProfileAtom] = useRecoilState(profileState);
  const [changeOccur, setChangeOcuur] = useState("");
  const [phNo, setPhno] = useState("");
  const [validatePhone, setValidatePhone] = useState(false);
  const LastNameFocusRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [mobileNo, setMobileNo] = useState({ value: "", error: "" });
  const phFocusRef = useRef("");
  // ---Temp Logic------
  const [cookie, updateCookie] = useCookie(STORAGE_KEY.AUTH);
  // ---Temp Logic end------
  const getAllProfileData = async () => {
    setProfileLoading(true);
    const userCode = JSON.parse(localStorage.getItem("userCode"));
    let email =
      getCredentialUserDetail?.email ||
      getCredentialUserDetail?.data?.user?.email;
    const request = {
      apiUrl:
        API_CONSTANTS.BASE_URL +
        API_CONSTANTS.GET_STUDENT_BY_EMAIL +
        `?email=${email}`,
      // apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.GET_STUDENT_BY_EMAIL(getCredentialUserDetail?.email || getCredentialUserDetail?.data?.user?.email),
      method: API_METHOD.GET,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      setProfileData(result);
      setProfileAtom(result.data);
      setProfileLoading(false);
      // console.log(result)

      setCredentialLoginAccountProfileDetailAtom(result);
      setChip(result.data.categories);
      setHrsValue(result.data?.availablePerHours);
      setChangeOcuur(result.data.fullName);

      setUserData(result.data);

      // {
      //     firstName: result.data.firstName,
      //     lastName: result.data.lastName,
      //     middleName: result.data.middleName
      // }
    } else if (result.status > 200) {
      setProfileLoading(false);

      // ---Temp Logic------
      if (result.message == "Token is Not Valid") {
        // swal("Already LoggedIn in Other device or Other browser! Try to Login Again").then(() => {
        //     localStorage.clear();
        //     updateCookie(null);
        //     navigate("/student-login")
        // })
      }
      // ---Temp Logic end------
      else {
        swal({
          title: "ERROR",
          text: result.message,
          icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
          className: "error-window",
          button: true,
        });
      }
    }
  };

  const {
    register,
    watch,
    reset,
    getValues,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      fb: "https://www.facebook.com/",
      insta: "https://www.instagram.com/",
      linkdin: "https://www.linkedin.com/",
      twitter: "https://twitter.com/",
    },
  });

  // Social media validation variables
  const watchingSocialFields = watch(["fb", "insta", "linkdin", "twitter"]); //this will give array value so we are converting to object
  const watchSocialFields = {
    fb: watchingSocialFields[0],
    insta: watchingSocialFields[1],
    linkdin: watchingSocialFields[2],
    twitter: watchingSocialFields[3],
  };
  const FbError = watchSocialFields.fb.startsWith("https://www.facebook.com/");
  const instaError = watchSocialFields.insta.startsWith(
    "https://www.instagram.com/"
  );
  const linkdinError = watchSocialFields.linkdin.startsWith(
    "https://www.linkedin.com/"
  );
  const twitterError = watchSocialFields.twitter.startsWith(
    "https://twitter.com/"
  );
  const [enable, setenable] = useState(false);
  const [chip, setChip] = useState([]);

  const [hrsValue, setHrsValue] = useState("0");

  const navigate = useNavigate();

  const onError = (errors, e) => {
    hrsValue === 0 ? setenable(true) : setenable(false);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    console.log({ data });

    // if (phNo.length > 11) {
    const requestBodyParam = JSON.stringify(
      {
        firstName: data.first,
        lastName: data.last,
        middleName: data.middle,
        aboutStudent: data.about,
        mobileNumber: phNo,
        facebook: data.fb,
        instagram: data.insta,
        linkedin: data.linkdin,
        twitter: data.twitter,
      },
      null,
      4
    );

    const request = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.CREATE_STUDENT,
      method: API_METHOD.POST,
      requestBodyParams: requestBodyParam,
    };

    let result = await API_CALL(request);

    if (result.status === 200) {
      setLoading(false);
      getAllProfileData();

      let userData = JSON.parse(localStorage.getItem("localUserData"));
      userData = {
        ...userData,
        name: `${data.first} ${data.middle} ${data.last}`,
      };
      localStorage.setItem("localUserData", JSON.stringify(userData));
      updateUserDetails(userData);

      swal("Good job!", "Successfully Submitted Profile", "success");
    } else if (result.status > 200) {
      // ---Temp Logic------
      if (result.message == "Token is Not Valid") {
        swal(
          "Already LoggedIn in Other device or Other browser! Try to Login Again"
        ).then(() => {
          localStorage.clear();
          updateCookie(null);
          navigate("/student-login");
        });
      }
      // ---Temp Logic end------
      else {
        swal({
          title: "ERROR",
          text: result.message,
          icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
          className: "error-window",
          button: true,
        });
      }
      setLoading(false);
    }
    // } else {
    //     setLoading(false);
    //     setValidatePhone(true);
    //     // phFocusRef.current.scrollIntoView();
    // }
  };

  useEffect(() => {
    getAllProfileData();
    reset({
      fb: profileData?.data?.facebook || "https://www.facebook.com/",
      insta: profileData?.data?.instagram || "https://www.instagram.com/",
      linkdin: profileData?.data?.linkedin || "https://www.linkedin.com/",
      twitter: profileData?.data?.twitter || "https://twitter.com/",
    });
  }, [changeOccur]);

  useEffect(() => {
    if (profileData?.data?.mobileNumber) {
      setPhno(profileData?.data?.mobileNumber);
    }
    getAllProfileData();
  }, [
    credentialLoginAccountProfileDetailAtom?.data?.profileImgPath,
    profileData?.data?.mobileNumber,
  ]);

  useEffect(() => {
    //  && profileData.middleName && profileData.lastName
    if (profileData?.data?.firstName) {
      // alert(`${profileData.firstName} ${profileData.middleName} ${profileData.lastName}`)
      console.log(profileData);
    }
  }, [
    profileData?.data?.firstName,
    profileData?.data?.middleName,
    profileData?.data?.lastName,
  ]);

  const validateEmptyValue = (value) => {
    return !!value.trim();
  };

  if (profileLoading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "400px" }}
      >
        <div class="spinner-border" role="status">
          <span class="sr-only"></span>
        </div>
      </div>
    );
  }

  return (
    <div>
      <form
        id="profilePage"
        className="login-col pt-5"
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <div className="row">
          <div className="col">
            <label className="fw-400" htmlFor="firstName">
              First Name<span className="text-danger">*</span>
            </label>
            <input
              className="mb-2"
              placeholder="First Name "
              type="text"
              defaultValue={profileData?.data?.firstName}
              id="firstName"
              {...register("first", {
                required: true,
                validate: validateEmptyValue,
              })}
            />
            <small className="text-danger mb-2 d-block fz-12px fw-500">
              {(errors.first?.type === "required" ||
                errors.first?.type === "validate") &&
                "First name required"}
            </small>
          </div>
          <div className="col">
            <label className="fw-400" htmlFor="middleName">
              Middle Name
            </label>
            <input
              className="mb-2"
              placeholder="Middle Name "
              type="text"
              id="middleName"
              defaultValue={profileData?.data?.middleName}
              {...register("middle", { required: false })}
            />
          </div>
          <div className="col">
            <label className="fw-400" htmlFor="lastName">
              Last Name<span className="text-danger">*</span>
            </label>
            <input
              className="mb-2"
              placeholder="Last Name "
              type="text"
              id="lastName"
              defaultValue={profileData?.data?.lastName}
              {...register("last", {
                required: true,
                validate: validateEmptyValue,
              })}
            />
            <small className="text-danger mb-2 d-block fz-12px fw-500">
              {(errors.last?.type === "required" ||
                errors.last?.type === "validate") &&
                "Last name required"}
            </small>
          </div>
        </div>
        <small className="text-danger mb-2 d-block fz-12px fw-500">
          {" "}
          {errors.fullname?.type === "required" && "Please Enter your fullname"}
        </small>
        <label className="fw-400">
          About You<span className="text-danger">*</span>
        </label>
        <textarea
          defaultValue={profileData?.data?.aboutYou || ""}
          className="default-inpt"
          rows="5"
          type="text"
          placeholder="Tell Something about you..."
          {...register("about", {
            required: true,
            validate: validateEmptyValue,
          })}
        />
        <small className="text-danger mb-2 d-block fz-12px fw-500">
          {" "}
          {(errors.about?.type === "required" ||
            errors.about?.type === "validate") &&
            "Please Fill this Field"}
        </small>
        <div>
          <label className="pt-3 fw-400">
            Mobile Number<span className="text-danger"></span>
          </label>
          <PhoneInput
            country={"in"}
            countryCodeEditable={false}
            buttonClass="border-0 ps-1"
            className="ph-frm border-0"
            placeholder="Enter Mobile Number"
            value={phNo}
            disabled={true}
            ///disabled={true}
            // onChange={(phone) => setPhno(phone)}
            onBlur={() => setValidatePhone(true)}
            //autoFormat={false}
            inputProps={{
              name: "phone",
              required: false,
              autoFocus: false,
              required: false,
            }}
          />
          {/* <small className='text-danger my-2 d-block fz-12px fw-500'>   {validatePhone && phNo ? phNo.length > "11" ? "" : "invalid mobile number" : validatePhone && phNo == "" ? "Please enter your mobile number" : ""}</small> */}
          {/* <input className='mb-2' placeholder='Enter mobile number' type="text" id="Enter mobile number" disabled={true} defaultValue={profileData?.data?.mobileNumber} {...register("mobile",{required:true,validate:validateEmptyValue})}/>
                    <small className='text-danger mb--2 d-block fz-12px fw-500'>{(errors.mobileNo?.type ==='required' || errors.mobileNo?.type === 'validate') && "please Fill this field"}</small> */}
          {/* <PhoneInput
                        country={'in'}
                        countryCodeEditable={false}
                        buttonClass="border-0 ps-1"
                        className="ph-frm border-0"
                        placeholder='Enter Mobile Number'
                        value={phNo}
                        ///disabled={true}
                        onChange={(phone) => setPhno(phone)}
                        onBlur={() => setValidatePhone(true)}
                        //autoFormat={false}
                        inputProps={{
                            name: 'phone',
                            required: true,
                            autoFocus: false,
                            required: false,
                        }}
                    /> */}
          {/* <input
                        value={phNo}
                        disabled={true}
                        onChange={(phone) => setPhno(phone)}
                        // onBlur={() => setValidatePhone(true)}
                        //autoFormat={false}
                        inputProps={{
                            name: 'phone',
                            required: true,
                            autoFocus: false,
                            required: false,
                        }}
                    /> */}
          {/* <input
                         placeholder='Enter Mobile Number'
                         value={mobileNo}
                         className="ph-frm border-0"
                         disabled
                         onChange={(e)=>setMobileNo(e.target.value)}
                        /> */}
          {/* <small className='text-danger my-2 d-block fz-12px fw-500'>   {validatePhone && phNo ? phNo.length > "11" ? "" : "invalid mobile number" : validatePhone && phNo == "" ? "Please enter your mobile number" : ""}</small> */}
        </div>
        <label className="fw-400">Social Link (Optional)</label>
        <div className="cst-drop p-5">
          <div className="d-flex ">
            <svg
              className="position-relative trans-y50 soc-ico"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.0012 2.50244C8.09869 2.50244 2.50244 8.09869 2.50244 15.0012C2.50244 21.2387 7.07244 26.4087 13.0487 27.3499V18.6149H9.87369V15.0012H13.0487V12.2474C13.0487 9.11244 14.9149 7.38369 17.7687 7.38369C19.1362 7.38369 20.5687 7.62744 20.5687 7.62744V10.7012H18.9887C17.4387 10.7012 16.9537 11.6662 16.9537 12.6549V14.9987H20.4174L19.8637 18.6124H16.9537V27.3474C22.9299 26.4112 27.4999 21.2399 27.4999 15.0012C27.4999 8.09869 21.9037 2.50244 15.0012 2.50244Z"
                fill="#395061"
              />
            </svg>
            <div className="d-flex flex-column w-100">
              <input
                type="text"
                className="bg-white mb-2"
                {...register("fb", { required: false })}
                style={{ color: "blue", textDecoration: "underline" }}
                onBlur={() =>
                  FbError
                    ? ""
                    : reset({ ...getValues(), fb: "https://www.facebook.com/" })
                }
              />
              <small className="text-danger mb-3 fz-12px fw-500">
                {FbError ? "" : "Invalid URL"}
              </small>
            </div>
          </div>
          <div className="d-flex ">
            <svg
              className="position-relative trans-y50 soc-ico"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.9987 9.22135C11.8062 9.22135 9.21994 11.8088 9.21994 15.0001C9.21994 18.1926 11.8062 20.7801 14.9987 20.7801C18.1887 20.7801 20.7774 18.1926 20.7774 15.0001C20.7774 11.8088 18.1887 9.22135 14.9987 9.22135ZM14.9987 18.7551C12.9249 18.7551 11.2437 17.0738 11.2437 15.0013C11.2437 12.9276 12.9249 11.2476 14.9987 11.2476C17.0724 11.2476 18.7512 12.9276 18.7512 15.0013C18.7512 17.0738 17.0724 18.7551 14.9987 18.7551Z"
                fill="#395061"
              />
              <path
                d="M21.0074 10.3563C21.7516 10.3563 22.3549 9.75305 22.3549 9.00885C22.3549 8.26464 21.7516 7.66135 21.0074 7.66135C20.2632 7.66135 19.6599 8.26464 19.6599 9.00885C19.6599 9.75305 20.2632 10.3563 21.0074 10.3563Z"
                fill="#395061"
              />
              <path
                d="M25.6662 7.63885C25.0799 6.1276 23.8862 4.9326 22.3749 4.34885C21.5012 4.0201 20.5774 3.84385 19.6424 3.82385C18.4387 3.77135 18.0574 3.75635 15.0049 3.75635C11.9524 3.75635 11.5612 3.75635 10.3674 3.82385C9.43494 3.8426 8.51119 4.01885 7.63744 4.34885C6.12494 4.9326 4.93119 6.1276 4.34619 7.63885C4.01744 8.51385 3.84119 9.43635 3.82244 10.3713C3.76869 11.5738 3.75244 11.9551 3.75244 15.0088C3.75244 18.0613 3.75244 18.4501 3.82244 19.6463C3.84119 20.5813 4.01744 21.5038 4.34619 22.3801C4.93244 23.8901 6.12619 25.0851 7.63869 25.6701C8.50869 26.0101 9.43244 26.2026 10.3699 26.2326C11.5737 26.2851 11.9549 26.3013 15.0074 26.3013C18.0599 26.3013 18.4512 26.3013 19.6449 26.2326C20.5787 26.2138 21.5024 26.0363 22.3774 25.7088C23.8887 25.1226 25.0824 23.9288 25.6687 22.4176C25.9974 21.5426 26.1737 20.6201 26.1924 19.6851C26.2462 18.4826 26.2624 18.1013 26.2624 15.0476C26.2624 11.9938 26.2624 11.6063 26.1924 10.4101C26.1762 9.4626 26.0012 8.52385 25.6662 7.63885ZM24.1437 19.5538C24.1349 20.2738 24.0049 20.9876 23.7549 21.6638C23.3737 22.6476 22.5974 23.4251 21.6149 23.8026C20.9462 24.0513 20.2412 24.1813 19.5274 24.1913C18.3399 24.2463 18.0049 24.2601 14.9599 24.2601C11.9124 24.2601 11.6012 24.2601 10.3912 24.1913C9.67994 24.1826 8.97244 24.0513 8.30494 23.8026C7.31869 23.4263 6.53744 22.6488 6.15619 21.6638C5.91119 20.9963 5.77869 20.2901 5.76744 19.5776C5.71369 18.3901 5.70119 18.0551 5.70119 15.0101C5.70119 11.9638 5.70119 11.6526 5.76744 10.4413C5.77619 9.72135 5.90619 9.00885 6.15619 8.3326C6.53744 7.34635 7.31869 6.5701 8.30494 6.1926C8.97244 5.9451 9.67994 5.81385 10.3912 5.80385C11.5799 5.7501 11.9137 5.7351 14.9599 5.7351C18.0062 5.7351 18.3187 5.7351 19.5274 5.80385C20.2412 5.8126 20.9462 5.94385 21.6149 6.1926C22.5974 6.57135 23.3737 7.34885 23.7549 8.3326C23.9999 9.0001 24.1324 9.70635 24.1437 10.4188C24.1974 11.6076 24.2112 11.9413 24.2112 14.9876C24.2112 18.0326 24.2112 18.3601 24.1574 19.5551H24.1437V19.5538Z"
                fill="#395061"
              />
            </svg>
            <div className="d-flex flex-column w-100">
              <input
                type="text"
                className="bg-white mb-2"
                {...register("insta", { required: false })}
                style={{ color: "blue", textDecoration: "underline" }}
                onBlur={() =>
                  instaError
                    ? ""
                    : reset({
                        ...getValues(),
                        insta: "https://www.instagram.com/",
                      })
                }
              />
              <small className="text-danger mb-3 fz-12px fw-500">
                {instaError ? "" : "Invalid URL"}
              </small>
            </div>
          </div>
          <div className="d-flex ">
            <svg
              className="position-relative trans-y50 linkdin"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.201 0.76001H2.79727C1.67102 0.76001 0.759766 1.67126 0.759766 2.79751V21.2025C0.759766 22.3275 1.67102 23.2413 2.79727 23.2413H21.2023C22.3273 23.2413 23.241 22.3288 23.241 21.2025V2.79751C23.2398 1.67001 22.3273 0.76001 21.201 0.76001ZM8.27227 5.13126C9.01852 5.13126 9.64477 5.72126 9.64477 6.47876C9.64477 7.24876 9.01852 7.82876 8.27227 7.82876V7.85626C7.51727 7.85626 6.90977 7.24876 6.90977 6.49626C6.90977 5.74001 7.51727 5.13126 8.27227 5.13126ZM17.4648 18.0713C16.0748 18.0713 15.401 17.2438 15.401 16.1138C15.401 15.7888 15.4423 15.4475 15.5435 15.0988L16.2035 12.7163C16.2885 12.4563 16.311 12.21 16.311 11.99C16.311 11.2213 15.8423 10.76 15.0973 10.76C14.146 10.76 13.5223 11.4388 13.1973 12.7575L11.906 17.94H9.64227L10.051 16.3113C9.38352 17.4025 8.46227 18.0763 7.32227 18.0763C5.94477 18.0763 5.30102 17.2863 5.30102 16.0963C5.30852 15.7563 5.34477 15.4138 5.42102 15.0825L6.45352 10.875H4.85602L5.34102 9.08751H9.18477L7.66477 15.0925C7.56477 15.4788 7.52727 15.7913 7.52727 16.015C7.52727 16.3988 7.71227 16.51 8.00852 16.58C8.19102 16.6175 9.62727 16.5938 10.406 14.86L11.4035 10.875H9.78602L10.2748 9.12001H13.7585L13.3098 11.1413C13.9148 10.0175 15.1248 8.93876 16.3173 8.93876C17.5823 8.93876 18.636 9.84876 18.636 11.5775C18.636 12.0775 18.5573 12.5725 18.411 13.0525L17.761 15.375C17.7073 15.61 17.6673 15.8088 17.6673 15.99C17.6673 16.3975 17.8373 16.595 18.1273 16.595C18.4248 16.595 18.8185 16.3688 19.251 15.13L20.136 15.47C19.6185 17.3113 18.6585 18.0713 17.4648 18.0713Z"
                fill="#395061"
              />
            </svg>
            <div className="d-flex flex-column w-100">
              <input
                type="text"
                className="bg-white mb-2"
                {...register("linkdin", { required: false })}
                style={{ color: "blue", textDecoration: "underline" }}
                onBlur={() =>
                  linkdinError
                    ? ""
                    : reset({
                        ...getValues(),
                        linkdin: "https://www.linkedin.com/",
                      })
                }
              />
              <small className="text-danger mb-3 fz-12px fw-500">
                {linkdinError ? "" : "Invalid URL"}
              </small>
            </div>
          </div>

          <div className="d-flex ">
            <svg
              className="position-relative trans-y50 soc-ico"
              viewBox="0 0 30 30"
              fill=" rgb(57, 80, 97)"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
            </svg>
            <div className="d-flex flex-column w-100">
              <input
                type="text"
                className="bg-white mb-2"
                {...register("twitter", { required: false })}
                style={{ color: "blue", textDecoration: "underline" }}
                onBlur={() =>
                  twitterError
                    ? ""
                    : reset({ ...getValues(), twitter: "https://twitter.com/" })
                }
              />
              <small className="text-danger mb-3 fz-12px fw-500">
                {twitterError ? "" : "Invalid URL"}
              </small>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end my-3">
          <button
            type="submit"
            role="button"
            className="text-center mt-auto border-0 w-25 fz-10px bg-prime text-white fz-14px py-2 rounded-3"
            form="profilePage"
            disabled={loading ? true : false}
          >
            {loading ? "Please Wait..." : "Save Changes"}
          </button>
        </div>
      </form>
    </div>
  );
}

export default UserProfile;
