import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useRecoilState } from "recoil";
import swal from "sweetalert";
import { API_CALL } from "../../../../../api/ApiServiceCall";
import { API_CONSTANTS, API_METHOD } from "../../../../../common/constants";
import { isSingleLiveCourseEditAtomFunc } from "../../../../atom/isSingleLiveCourseEditAtom";
import { singleLiveCourseDataAtomFunc } from "../../../../atom/singleLiveCourseDataAtom";

function Step1LiveCourseEdit() {
  const [singleLiveCourseDataAtom, setSingleLiveCourseDataAtom] =
    useRecoilState(singleLiveCourseDataAtomFunc);
  const [isSingleLiveCourseEditAtom, setIsSingleLiveCourseEditAtom] =
    useRecoilState(isSingleLiveCourseEditAtomFunc);
  // ----
  const [getCategory, setGetCategory] = useState([]);
  const [getSubCategory, setGetSubCategory] = useState(false);
  const [getCategoryForFormValidation, setCategoryForFormValidation] =
    useState(false);
  // ---Data--
  const [liveCourseTitle, setLiveCourseTitle] = useState(
    singleLiveCourseDataAtom?.courseName
  );
  const [liveCourseOverview, setLiveCourseOverview] = useState(
    singleLiveCourseDataAtom?.courseOverview
  );
  const [getCategoryFormData, setGetCategoryFormData] = useState("");
  const [getSubCategoryFormData, setGetSubCategoryFormData] = useState("");
  const [isValidate, setIsValidate] = useState({
    courseTitle: false,
    liveCourseOverview: false,
    liveNextCourseDetails: false,
    getCategoryFormData: false,
    getSubCategoryFormData: false,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllCatSubCat();
  }, []);
  async function getAllCatSubCat() {
    await getAllCategory();
    await getSubCategoryByCode(singleLiveCourseDataAtom?.catogory);
    setGetCategoryFormData(singleLiveCourseDataAtom?.catogory);
    setGetSubCategoryFormData(singleLiveCourseDataAtom?.subCategory);
  }
  const getAllCategory = async () => {
    const request = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.GET_ALL_CATEGORY,
      method: API_METHOD.GET,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      setGetCategory(result.data);
    } else {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }
  };

  const getCurrentCategoryCode = (e) => {
    setGetSubCategoryFormData("");
    setGetCategoryFormData(e.target.value);
    // const index = e.target.selectedIndex;
    // const el = e.target.childNodes[index]
    // const option = el.getAttribute('id');
    getSubCategoryByCode(e.target.value);
    setCategoryForFormValidation(e.target.value);
  };
  const getSubCategoryByCode = async (catCode) => {
    const request = {
      apiUrl:
        API_CONSTANTS.BASE_URL +
        API_CONSTANTS.GET_SUB_CATEGORY_BY_CATEGORYCODE +
        `/?categoryCode=${catCode}`,
      method: API_METHOD.GET,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      setGetSubCategory(result);
    } else if (result.status > 200) {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }
  };
  // -----------update Data APi----------
  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const courseCode = localStorage.getItem("courseCode");
    const isAllow =
      liveCourseTitle &&
      liveCourseOverview &&
      getCategoryFormData &&
      getSubCategoryFormData;
    if (isAllow) {
      const requestBodyParam = JSON.stringify({
        courseName: liveCourseTitle,
        courseOverview: liveCourseOverview,
        catogory: getCategoryFormData,
        subCategory: getSubCategoryFormData,
        courseCode: courseCode,
      });
      // swal(requestBodyParam)
      const request = {
        apiUrl:
          API_CONSTANTS.BASE_URL + API_CONSTANTS.UPDATE_LIVE_COURSE_DETAILS,
        method: API_METHOD.POST,
        requestBodyParams: requestBodyParam,
      };
      let result = await API_CALL(request);
      if (result.status === 200) {
        setLoading(false);
        setIsSingleLiveCourseEditAtom({
          refetchOnChange: Math.random(),
          edit: false,
        });
      } else if (result.status > 200) {
        swal({
          title: "ERROR",
          text: result.message,
          icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
          className: "error-window",
          button: true,
        });
        setLoading(false);
      }
    } else {
      setLoading(false);
      setIsValidate({
        courseTitle: true,
        liveCourseOverview: true,
        getCategoryFormData: true,
        getSubCategoryFormData: true,
      });
    }
  };

  return (
    <div>
      <form onSubmit={onSubmit}>
        <label className="py-2 fz-14px text-dark fw-500">Course Title</label>
        <input
          type="text"
          onInput={(e) => {
            if (e.target.value.trim() == "") {
              e.target.value = "";
            }
          }}
          className="default-inpt"
          defaultValue={liveCourseTitle}
          onBlur={() => setIsValidate({ ...isValidate, courseTitle: true })}
          onChange={(e) => setLiveCourseTitle(e.target.value)}
        />
        {isValidate.courseTitle && liveCourseTitle === "" ? (
          <small className="text-danger my-0 d-block fz-12px mt-3 fw-500">
            Please Fill this field
          </small>
        ) : (
          ""
        )}
        <div className="py-2"></div>
        <label className="pb-2 fz-14px text-dark fw-500">Course Overview</label>
        <ReactQuill
          className="rich-h"
          defaultValue={liveCourseOverview}
          onChange={setLiveCourseOverview}
          onBlur={() =>
            setIsValidate({ ...isValidate, liveCourseOverview: true })
          }
        />
        {liveCourseOverview == "<p><br></p>" && setLiveCourseOverview("")}

        <div className="pt-5"></div>
        {isValidate.liveCourseOverview && liveCourseOverview === "" ? (
          <small className="text-danger my-0 d-block fz-12px mt-3 fw-500">
            Please Fill this field
          </small>
        ) : (
          ""
        )}
        <div className="pt-1"></div>

        <div className="row">
          <div className="col">
            <label className="fz-14px text-dark fw-500 pb-3">Category</label>

            <div className="tag-ajus mb-2">
              {/* {getCategoryFormData} */}
              <select
                defaultValue={getCategoryFormData}
                key={getCategoryFormData}
                onChange={getCurrentCategoryCode}
                onBlur={() =>
                  setIsValidate({ ...isValidate, getCategoryFormData: true })
                }
              >
                {/* <option value={getCategoryFormData} disabled>{catDatas.categoryName}</option> */}
                {getCategory
                  ? getCategory.map((catDatas, index) => (
                      <option
                        key={catDatas._id + index}
                        value={catDatas.categoryCode}
                      >
                        {catDatas.categoryName}
                      </option>
                    ))
                  : ""}
              </select>
            </div>
            {isValidate.getCategoryFormData && getCategoryFormData === "" ? (
              <small className="text-danger my-0 d-block fz-12px mt-3 fw-500">
                Please Fill this field
              </small>
            ) : (
              ""
            )}
          </div>
          <div className="col-lg-1"></div>
          <div className="col">
            <label className="fz-14px text-dark fw-500 pb-3">
              Sub - Category
            </label>
            <div className="tag-ajus mb-2">
              {/* {getSubCategoryFormData} */}
              <select
                defaultValue={getSubCategoryFormData}
                key={getSubCategoryFormData}
                onChange={(e) => setGetSubCategoryFormData(e.target.value)}
                onBlur={() =>
                  setIsValidate({ ...isValidate, getSubCategoryFormData: true })
                }
              >
                <option value="" className="d-none">
                  select sub-category
                </option>
                {getSubCategory
                  ? getSubCategory.data.map((subCatDatas, index) => (
                      <option
                        key={subCatDatas._id + "key" + index}
                        value={subCatDatas.subCategoryCode}
                      >
                        {subCatDatas.subCategoryName}
                      </option>
                    ))
                  : "Please select category first"}
              </select>
            </div>
            {isValidate.getSubCategoryFormData &&
            getSubCategoryFormData === "" ? (
              <small className="text-danger my-0 d-block fz-12px mt-3 fw-500">
                Please Fill this field
              </small>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="d-flex  justify-content-end py-1">
          <button
            className="border-0 bg-secondary text-white fz-14px py-2 px-4 mx-2  rounded-3 text-center"
            type="button"
            disabled={loading ? true : false}
            onClick={() =>
              setIsSingleLiveCourseEditAtom({
                ...isSingleLiveCourseEditAtom,
                edit: false,
              })
            }
          >
            Cancel
          </button>

          <button
            className="border-0 bg-prime text-white fz-14px py-2 px-4  rounded-3 text-center"
            type="submit"
            disabled={loading ? true : false}
          >
            {loading ? "Please wait..." : "Save Changes"}
          </button>
        </div>
      </form>
    </div>
  );
}

export default Step1LiveCourseEdit;
