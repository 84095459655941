import React, { useEffect, useState } from "react";
import { BiCircle } from "react-icons/bi";
import { FiTrash2 } from "react-icons/fi";
import { useRecoilState } from "recoil";
import swal from "sweetalert";
import { API_CALL } from "../../../../../../../api/ApiServiceCall";
import {
  API_CONSTANTS,
  API_METHOD,
} from "../../../../../../../common/constants";
import { clickedChapterDataListAtomFunc } from "../../../../../../atom/clickedChapterDataListAtomFunc";
import { currentViewComponentLsnAssessAtomFunc } from "../../../../../../atom/lsnChapAssessComponentRender";
import { updatingToastMessageAtomFunc } from "../../../../../../atom/setToastMessage";
import LeftArrow from "../../../../../../svg-icons/leftArrow";
import SingleChapterView from "../singleChapterView";

function AssessmentCreationForm() {
  const [inputList, setInputList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [assesmentName, setAssesmentName] = useState("");
  const [
    currentViewComponentLsnAssessAtom,
    setCurrentViewComponentLsnAssessAtom,
  ] = useRecoilState(currentViewComponentLsnAssessAtomFunc);
  const [clickedChapterDataListAtom, setClickedChapterDataListAtom] =
    useRecoilState(clickedChapterDataListAtomFunc);
  const [toastMessage, setToastMessage] = useRecoilState(
    updatingToastMessageAtomFunc
  );

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    if (name == "point") {
      if (e.target.value.length > e.target.getAttribute("maxLength")) {
        e.target.value = e.target.value.slice(
          0,
          e.target.getAttribute("maxLength")
        );
      } else if (e.target.value == "0") {
        // const list = [...inputList];
        // list[index][name] = 1;
        // setInputList(list);
        swal({
          text: "The point should be greater than 1 and less than 10",
          icon: "warning",
        });
      } else {
        //set valid value
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
      }
    } else {
      const list = [...inputList];
      list[index][name] = value;
      setInputList(list);
    }
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    swal({
      title: "Are you sure?",
      text: "To recreate this field after it's been deleted, you'll need to create it again",
      icon: "https://cdn-icons-png.flaticon.com/512/6460/6460112.png",
      imageClass: "img-warning",
      animation: false,
      className: "swal-center",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
      }
    });
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([
      ...inputList,
      {
        question: "",
        point: "1",
        option1: "",
        option2: "",
        option3: "",
        option4: "",
        answer: "",
      },
    ]);
  };

  const handleSubmitForAssessment = async (e) => {
    e.preventDefault();

    if (assesmentName.trim() === "") {
      swal({
        text: "Please enter the assessment name.",
        icon: "warning",
      });
      return;
    }

    if (
      inputList.length === 0 ||
      inputList.some(
        (item) =>
          item.question.trim() === "" ||
          item.option1.trim() === "" ||
          item.option2.trim() === "" ||
          item.option3.trim() === "" ||
          item.option4.trim() === "" ||
          item.answer.trim() === ""
      )
    ) {
      swal({
        text: "Please fill out all questions and options.",
        icon: "warning",
      });
      return;
    }

    setLoading(true);
    const assessmentDetails = inputList.map((items) => {
      return {
        assessmentQuestion: items.question,
        point: items.point,
        assessmentChoice: [
          items.option1,
          items.option2,
          items.option3,
          items.option4,
        ],
        assessmentAnswer: items.answer,
      };
    });
    const requestBodyParam = JSON.stringify({
      courseCode: clickedChapterDataListAtom.data.courseCode,
      chapterOrder: clickedChapterDataListAtom.data.chapterOrder,
      assessmentName: assesmentName,
      assessmentDetails: assessmentDetails,
    });
    // alert(requestBodyParam)
    const request = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.CREATE_ASSESMENT,
      method: API_METHOD.POST,
      requestBodyParams: requestBodyParam,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      setLoading(false);
      // setToastMessage({ active: true, message: "Assessment Created SuccessFully" });
      setCurrentViewComponentLsnAssessAtom(
        <SingleChapterView clickedChapterData={clickedChapterDataListAtom} />
      );
    } else if (result.status > 200) {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
      setLoading(false);
    }
  };
  useEffect(() => {
    var e = document.querySelectorAll("option");
    e.forEach((x) => {
      if (x.textContent.length > 80)
        x.textContent = x.textContent.substring(0, 80) + "...";
    });
  });

  const checkForDuplicateQuestion = (e, index) => {
    const prevList = [...inputList];
    prevList.splice(index, 1);
    const checkList = prevList.some((item) => {
      return (
        item?.question.trim().toLocaleLowerCase() ==
        e.target.value.trim().toLocaleLowerCase()
      );
    });

    if (checkList) {
      swal({
        text: "This question was already exists!",
        icon: "warning",
      });
      const list = [...inputList];
      list[index]["question"] = "";
      setInputList(list);
    }
  };
  const checkForDuplicateOptions = (e, index) => {
    if (e.target.value) {
      const prevList = [...inputList];
      const rawObj = prevList[index];
      const keysOnArry = Object.keys(rawObj);
      console.log(keysOnArry);
      let isDup = false;
      const currentValue = e.target.value.trim().toLocaleLowerCase();
      let option1 = rawObj.option1.trim().toLocaleLowerCase();
      let option2 = rawObj.option2.trim().toLocaleLowerCase();
      let option3 = rawObj.option3.trim().toLocaleLowerCase();
      let option4 = rawObj.option4.trim().toLocaleLowerCase();

      if (keysOnArry[2] == e.target.name) {
        // console.log("option1 Block")
        const isCurrentOptionSame =
          currentValue == option2 ||
          currentValue == option3 ||
          currentValue == option4;
        isDup = isCurrentOptionSame;
      }
      if (keysOnArry[3] == e.target.name) {
        // console.log("option2 Block")
        const isCurrentOptionSame =
          currentValue == option1 ||
          currentValue == option3 ||
          currentValue == option4;
        isDup = isCurrentOptionSame;
      }
      if (keysOnArry[4] == e.target.name) {
        // console.log("option3 Block")
        const isCurrentOptionSame =
          currentValue == option1 ||
          currentValue == option2 ||
          currentValue == option4;
        isDup = isCurrentOptionSame;
      }
      if (keysOnArry[5] == e.target.name) {
        // console.log("option4 Block");
        const isCurrentOptionSame =
          currentValue == option1 ||
          currentValue == option2 ||
          currentValue == option3;
        isDup = isCurrentOptionSame;
      }

      if (isDup) {
        swal({
          text: "This option was already exists for this question!",
          icon: "warning",
        });
        prevList[index][e.target.name] = "";
        setInputList(prevList);
      }
    }
  };
  return (
    <div>
      <>
        <div className="d-flex justify-content-end align-items-center">
          <button
            form="lessonName"
            className="border-0 fz-15px   bg-transparent   me-auto"
            role="button"
            onClick={() =>
              setCurrentViewComponentLsnAssessAtom(
                <SingleChapterView
                  clickedChapterData={clickedChapterDataListAtom}
                />
              )
            }
          >
            <LeftArrow size={7} /> Back
          </button>
          {/* <button className="p-0 m-0 border-0 bg-transparent fz-14px fw-500 text-danger" onClick={() => setCurrentViewComponentLsnAssessAtom(<SingleChapterView clickedChapterData={clickedChapterDataListAtom} />)}> <FiTrash2 size={17} className="text-danger" /> Delete</button> */}
        </div>
        <div className="p-2">
          <form id="assessment" onSubmit={handleSubmitForAssessment}>
            <div className="login-col pt-4  ">
              <input
                autoComplete="off"
                type="text"
                value={assesmentName}
                placeholder="Assessment Name Here*"
                onChange={(e) => setAssesmentName(e.target.value)}
                required
              />
            </div>
            <p className="fz-14px text-muted fw-400">
              Note: Please add the questions and answers to the assessment
            </p>
            {inputList.map((x, i) => {
              return (
                <div key={"assesment" + i}>
                  <div className="d-flex justify-content-between ">
                    <textarea
                      rows={"1"}
                      autoComplete="off"
                      onBlur={(e) => checkForDuplicateQuestion(e, i)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") e.preventDefault();
                      }}
                      type="text"
                      className="question-input"
                      name="question"
                      onChange={(e) => handleInputChange(e, i)}
                      value={x.question}
                      placeholder="Enter Question here*"
                      required
                    />

                    <div className="d-flex justify-content-end align-items-center">
                      Point* &nbsp;
                      <input
                        autoComplete="off"
                        maxLength={"1"}
                        type="number"
                        name="point"
                        onChange={(e) => handleInputChange(e, i)}
                        value={x.point}
                        className="point-input"
                        required
                      />
                      <div onClick={() => handleRemoveClick(i)}>
                        <FiTrash2 className="trsh-assesment" />
                      </div>
                    </div>
                  </div>
                  <div className="assesment-option">
                    <div>
                      <label>
                        <BiCircle />
                      </label>{" "}
                      <textarea
                        onBlur={(e) => checkForDuplicateOptions(e, i)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") e.preventDefault();
                        }}
                        autoComplete="off"
                        className="p-2 mx-2 w-50 border-0"
                        type="text"
                        placeholder="Option1*"
                        name="option1"
                        onChange={(e) => handleInputChange(e, i)}
                        value={x.option1}
                        required
                      />
                    </div>
                    <div>
                      <label>
                        <BiCircle />
                      </label>{" "}
                      <textarea
                        onBlur={(e) => checkForDuplicateOptions(e, i)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") e.preventDefault();
                        }}
                        autoComplete="off"
                        className="p-2 mx-2 w-50  border-0"
                        type="text"
                        name="option2"
                        placeholder="Option2*"
                        onChange={(e) => handleInputChange(e, i)}
                        value={x.option2}
                        required
                      />
                    </div>
                    <div>
                      <label>
                        <BiCircle />
                      </label>{" "}
                      <textarea
                        onBlur={(e) => checkForDuplicateOptions(e, i)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") e.preventDefault();
                        }}
                        autoComplete="off"
                        className="p-2 mx-2 w-50 border-0"
                        type="text"
                        name="option3"
                        placeholder="Option3*"
                        onChange={(e) => handleInputChange(e, i)}
                        value={x.option3}
                        required
                      />
                    </div>
                    <div>
                      <label>
                        <BiCircle />
                      </label>{" "}
                      <textarea
                        onBlur={(e) => checkForDuplicateOptions(e, i)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") e.preventDefault();
                        }}
                        autoComplete="off"
                        className="p-2 mx-2 w-50 border-0"
                        type="text"
                        name="option4"
                        placeholder="Option4*"
                        onChange={(e) => handleInputChange(e, i)}
                        value={x.option4}
                        required
                      />
                    </div>
                  </div>

                  <div className="tag-ajus w-50 rounded-2">
                    <select
                      name="answer"
                      onChange={(e) => handleInputChange(e, i)}
                      value={
                        x.answer === x.option1 ||
                        x.answer === x.option2 ||
                        x.answer === x.option3 ||
                        x.answer === x.option4
                          ? x.answer
                          : "Please Select Answer*"
                      }
                      required
                    >
                      <option value="">Please Select Answer*</option>
                      <option value={x.option1} title={x.option1}>
                        {x.option1}
                      </option>
                      <option value={x.option2} title={x.option2}>
                        {x.option2}
                      </option>
                      <option value={x.option3} title={x.option3}>
                        {x.option3}
                      </option>
                      <option value={x.option4} title={x.option4}>
                        {x.option4}
                      </option>
                    </select>
                  </div>

                  <div className="py-3"></div>
                </div>
              );
            })}
          </form>
        </div>

        <div className="row mb-3">
          <div className="col d-flex">
            <button
              className="border-0 bg-prime text-white fz-14px py-2 px-5 rounded-3 text-center"
              role="button"
              type="button"
              onClick={handleAddClick}
            >
              + Create New
            </button>
          </div>
          <div className="col-12 d-flex justify-content-end">
            {/* <button form="lessonName" className='border-0 bg-secondary text-white fz-14px py-2 px-5 mx-2 rounded-3 text-center' role="button" onClick={() => setCurrentViewComponentLsnAssessAtom(<SingleChapterView clickedChapterData={clickedChapterDataListAtom} />)}>Cancel</button> */}
            <button
              form="assessment"
              className="border-0 bg-prime text-white fz-14px py-2 px-5 rounded-3 text-center"
              role="button"
              type="submit"
              disabled={loading ? true : false}
            >
              {loading ? "Please Wait..." : "Save"}
            </button>
          </div>
        </div>
      </>
    </div>
  );
}

export default AssessmentCreationForm;
