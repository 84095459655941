import React from "react";

function PrivacyPolicy() {
  return (
    <div className="student-conatiner py-5 mt-4">
      <h1 className="fs-2 my-4">Privacy Policy</h1>
      <p>
        Our website, [website name], is committed to protecting the privacy of
        our users. This Privacy Policy applies to the information that we
        collect through our website, and explains how we use and protect that
        information.
      </p>
      <h3 className="fs-4">Information We Collect</h3>
      <p>
        We may collect personal information from users when they register for an
        account on our website, make a purchase, or sign up for a newsletter.
        The types of personal information that we may collect include name,
        email address, postal address, and phone number. We may also collect
        information about users' browsing habits, including the pages they visit
        on our website and the links they click on.
      </p>
      <h3 className="fs-4">Use of Information</h3>
      <p>
        We use the information that we collect for several purposes, including:
      </p>
      <ul>
        <li>
          To provide users with the products or services they have requested
        </li>
        <li>To improve the content and navigation of our website</li>
        <li>
          To send users information about new products or services that we think
          may be of interest to them
        </li>
        <li>
          To personalize users' experience on our website by showing them
          content that we think they will find relevant
        </li>
      </ul>
      <h3 className="fs-4">Protection of Information</h3>
      <p>
        We take the protection of personal information very seriously, and have
        implemented appropriate physical, electronic, and managerial procedures
        to safeguard and secure the information we collect. However, please note
        that no method of electronic transmission or storage is 100% secure, and
        we cannot guarantee the complete security of any information that is
        transmitted to or from our website.
      </p>
      <h3 className="fs-4">Changes to This Policy</h3>
      <p>
        We reserve the right to update or change this Privacy Policy at any
        time, and will post any revisions on this page. Users are encouraged to
        check this page periodically for any changes to this policy.
      </p>
    </div>
  );
}

export default PrivacyPolicy;
