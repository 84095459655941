import { useRef, useState } from "react";
import { useRecoilState } from "recoil";
import swal from "sweetalert";
import { API_CALL } from "../../../api/ApiServiceCall";
import { API_CONSTANTS, API_METHOD } from "../../../common/constants";
import { getCredentialLoginAccountProfileDetailAtom } from "../../../components/atom/getCredentialLoginAccountProfileDetail";
import MyS3Component from "../../../components/s3/s3";

function ProfileImageForm({ userData, credentialUserDetail }) {
  const [image, setImage] = useState(false);
  const [ProfileImage, setProfileImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [
    credentialLoginAccountProfileDetailAtom,
    setCredentialLoginAccountProfileDetailAtom,
  ] = useRecoilState(getCredentialLoginAccountProfileDetailAtom);
  const [imageFileValidation, setImageFileValidation] = useState(false);

  //for Image preview
  const readImage = (e) => {
    let file = e.target.files[0];
    const uniqueId = "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
    let fileExtension = file.name.split(".").pop();
    const uniqueFileName = `${uniqueId}.${fileExtension}`;
    file = new File([file], uniqueFileName, { type: file.type });
    let fileFormat = fileExtension.toLowerCase();
    console.log(8, { file });

    if (file.type == "image/png" || file.type == "image/jpeg") {
      setImageFileValidation(false);
      //set state to submit
      setProfileImage(file);
      const reader = new FileReader();
      reader.onload = function (e) {
        let binaryData = e.target.result;
        let base64String = window.btoa(binaryData);
        setImage(base64String);
      };

      let image = reader.readAsBinaryString(file);

      return image;
    } else {
      setImageFileValidation("Image format should JPEG or PNG.");
    }
  };
  //image submit

  const getAllProfileData = async (email) => {
    const request = {
      apiUrl:
        API_CONSTANTS.BASE_URL +
        API_CONSTANTS.GET_STUDENT_BY_EMAIL +
        `?email=${email}`,
      // apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.GET_STUDENT_BY_EMAIL(email),
      method: API_METHOD.GET,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      setCredentialLoginAccountProfileDetailAtom(result);
    }
  };

  const handleInputChangeInProfileImage = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("image", ProfileImage);
    let requestBodyParam = formData;
    const usrdetailforHeader = JSON.parse(
      localStorage.getItem("localUserData")
    );
    let apiHeader = {
      "x-auth-token": usrdetailforHeader?.jwt,
    };
    if (usrdetailforHeader?.data?.user?.email) {
      apiHeader = {
        token: usrdetailforHeader?.data?.user?.email,
        gmailUserType: "STUDENT",
      };
    }
    const request = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.UPLOAD_PROFILE_IMAGE,
      method: API_METHOD.POST,
      headers: apiHeader,
      requestBodyParams: requestBodyParam,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      setImage("");
      setLoading(false);
      //// GET Profile DATA FROM API and update
      if (credentialLoginAccountProfileDetailAtom?.data?.profileImgPath) {
        setCredentialLoginAccountProfileDetailAtom({
          isChangeOccur: Math.random(),
        });
      } else {
        console.log(
          "RAN - ",
          credentialLoginAccountProfileDetailAtom?.data?.email
        );
        getAllProfileData(credentialLoginAccountProfileDetailAtom?.data?.email);
      }
    } else if (result.status > 200) {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
      setLoading(false);
    }
  };
  const profileImgRef = useRef(null);
  const handleImageError = (profileImgRef) => {
    profileImgRef.current.src =
      "https://cdn-icons-png.flaticon.com/512/149/149071.png";
  };
  console.log(
    "CRED",
    credentialLoginAccountProfileDetailAtom?.data?.profileImgPath
  );

  return (
    <div div className="d-flex align-items-end ">
      <div className="position-relative">
        {/* <p>{JSON.stringify(credentialLoginAccountProfileDetailAtom)}</p> */}
        {/* {"Profile Image Normal login"} */}
        {/* { credentialLoginAccountProfileDetailAtom?.data?.profileImgPath.toString() } */}

        {image ? (
          <img
            src={`data:image/jpeg;base64,${image}`}
            className="img-adjs rounded-circle position-absolute"
          />
        ) : (
          ""
        )}

        {credentialLoginAccountProfileDetailAtom?.data?.profileImgPath ? (
          <MyS3Component
            imageUrl={credentialLoginAccountProfileDetailAtom?.data?.profileImgPath}
            alt="Profile Image"
            className="img-adjs rounded-circle"
            ref={profileImgRef}
            onError={() => handleImageError(profileImgRef)}
          />
        ) : (
          <img
            src={"https://cdn-icons-png.flaticon.com/512/149/149071.png"}
            alt={"Profile Image"}
            className="img-adjs rounded-circle"
          />
        )}

        <form id="profile_pic" onSubmit={handleInputChangeInProfileImage}>
          <input
            className={"d-none"}
            id="picIdentifier"
            type="file"
            accept="image/gif, image/jpeg, image/png"
            onChange={(event) => {
              readImage(event);
            }}
            required
          />
          <label
            role={"button"}
            htmlFor="picIdentifier"
            className="  position-absolute bottom-0"
            style={{ right: "-7px" }}
          >
            <svg
              width="30"
              height="30"
              viewBox="0 0 45 45"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                opacity="0.9"
                cx="22.5"
                cy="22.5"
                r="22"
                fill="white"
                stroke="#395061"
              />
              <path
                d="M16 18H17C17.5304 18 18.0391 17.7893 18.4142 17.4142C18.7893 17.0391 19 16.5304 19 16C19 15.7348 19.1054 15.4804 19.2929 15.2929C19.4804 15.1054 19.7348 15 20 15H26C26.2652 15 26.5196 15.1054 26.7071 15.2929C26.8946 15.4804 27 15.7348 27 16C27 16.5304 27.2107 17.0391 27.5858 17.4142C27.9609 17.7893 28.4696 18 29 18H30C30.5304 18 31.0391 18.2107 31.4142 18.5858C31.7893 18.9609 32 19.4696 32 20V29C32 29.5304 31.7893 30.0391 31.4142 30.4142C31.0391 30.7893 30.5304 31 30 31H16C15.4696 31 14.9609 30.7893 14.5858 30.4142C14.2107 30.0391 14 29.5304 14 29V20C14 19.4696 14.2107 18.9609 14.5858 18.5858C14.9609 18.2107 15.4696 18 16 18Z"
                stroke="#395061"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M23 27C24.6569 27 26 25.6569 26 24C26 22.3431 24.6569 21 23 21C21.3431 21 20 22.3431 20 24C20 25.6569 21.3431 27 23 27Z"
                stroke="#395061"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </label>
        </form>
        {image ? (
          <button
            form="profile_pic"
            type="submit"
            className="bg-prime text-white border-0 rounded-9 px-2 position-absolute fz-10px bg-red"
            style={{ bottom: "-15px" }}
            disabled={loading ? true : false}
          >
            {loading ? "Loading.." : "UPLOAD"}{" "}
          </button>
        ) : (
          ""
        )}
      </div>
      <div className="px-3">
        {imageFileValidation && (
          <small className="text-danger mb-2 d-block fz-12px fw-500">
            {imageFileValidation}
          </small>
        )}
        <p className="mb-1">
          {userData.firstName} {userData.middleName} {userData.lastName}
        </p>
        <p className="mute">{credentialUserDetail?.email}</p>
      </div>
    </div>
  );
}

export default ProfileImageForm;
