import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import { API_CALL } from "../../../api/ApiServiceCall";
import { API_CONSTANTS, API_METHOD } from "../../../common/constants";

function DemoClassTimings({
  setIsScheduledDemoClass,
  onEditDataDemoClass,
  setOnEditDataDemoClass,
  getScheduledDemoClasses,
}) {
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm();
  const [demoClassData, setDemoClassData] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  useEffect(() => {
    setDemoClassData(JSON.parse(localStorage.getItem("DemoClassData")));
    if (onEditDataDemoClass?._id) {
      let defaultValues = {};
      defaultValues.topicName = onEditDataDemoClass?.topicName;
      defaultValues.date = moment(onEditDataDemoClass?.demoDate).format(
        "YYYY-MM-DD"
      );
      defaultValues.time = onEditDataDemoClass?.demoTime;
      defaultValues.timeZone = onEditDataDemoClass?.timeZone;
      reset({ ...defaultValues });
    }
  }, [onEditDataDemoClass]);
  const clearValues = () => {
    let defaultValues = {};
    defaultValues.topicName = "";
    defaultValues.date = "";
    defaultValues.time = "";
    setOnEditDataDemoClass("");
    reset({ ...defaultValues });
  };
  const submitForm = async (data) => {
    setIsSubmitting(true);
    if (onEditDataDemoClass?._id) {
      //Updation
      const requestBodyParam = JSON.stringify({
        courseCode: demoClassData?.courseCode,
        demoClassId: onEditDataDemoClass?._id,
        demoDate: data.date,
        demoTime: data.time,
        timeZone: data.timeZone,
        topicName: data.topicName,
        liveUrl: `${window.location.origin}/live-demo-class`,
      });
      const request = {
        apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.SCHEDULE_DEMO,
        method: API_METHOD.POST,
        requestBodyParams: requestBodyParam,
      };
      let result = await API_CALL(request);
      if (result.status === 200) {
        getScheduledDemoClasses();
        swal("", "updated successfully", "success");
        reset();
        setIsScheduledDemoClass(false);
      } else if (result.status > 200) {
        swal({
          title: "ERROR",
          text: result.message,
          icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
          className: "error-window",
          button: true,
        });
      }
    } else {
      //Creation
      const requestBodyParam = JSON.stringify({
        courseCode: demoClassData?.courseCode,
        demoDate: data.date,
        demoTime: data.time,
        timeZone: data.timeZone,
        topicName: data.topicName,
        liveUrl: `${window.location.origin}/live-demo-class`,
      });
      const request = {
        apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.SCHEDULE_DEMO,
        method: API_METHOD.POST,
        requestBodyParams: requestBodyParam,
      };
      let result = await API_CALL(request);
      if (result.status === 200) {
        getScheduledDemoClasses();
        swal("", "Demo Class scheduled successfully", "success");
        reset();
        setIsScheduledDemoClass(false);
        setIsSubmitting(false);
      } else if (result.status > 200) {
        swal({
          title: "ERROR",
          text: result.message,
          icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
          className: "error-window",
          button: true,
        });
      }
    }
  };
  const [today, setToday] = useState(new Date().toISOString().split("T")[0]);

  return (
    <div>
      {/* {JSON.stringify(onEditDataDemoClass, 4, null)} */}
      <form onSubmit={handleSubmit(submitForm)}>
        <div className="row">
          <div className="col-12">
            <label className="text-muted fz-13px fw-500">Topic name</label>
            <input
              className="default-inpt  my-2"
              {...register("topicName", { required: "Please fill this field" })}
              type="text"
            />
            {errors?.topicName && (
              <small className="text-danger my-0 d-block fz-12px fw-500">
                {errors.topicName?.message}
              </small>
            )}
          </div>
          <div className="col-lg-6">
            <label className="text-muted fz-13px fw-500">Date</label>
            <input
              className="default-inpt  my-2"
              {...register("date", { required: "Please fill this field" })}
              type="date"
              onKeyDown={(e) => {
                if (e.code) e.preventDefault();
              }}
              min={today}
              max={moment(today, "YYYY-MM-DD")
                .add(4, "years")
                .format("YYYY-MM-DD")}
            />
            {errors?.date && (
              <small className="text-danger my-0 d-block fz-12px fw-500">
                {errors.date?.message}
              </small>
            )}
          </div>
          <div className="col-lg-6">
            <label className="text-muted fz-13px fw-500">Time</label>
            <input
              className="default-inpt  my-2"
              type="time"
              {...register("time", {
                required: "Please fill this field",
                validate: (value) => {
                  if (
                    moment(getValues("date")).format("YYYY-MM-DD") ==
                    moment().format("YYYY-MM-DD")
                  ) {
                    const givenTime = value; // Given time in HH:mm format
                    const currentTime = new Date(); // Current time
                    const givenHour = parseInt(givenTime.split(":")[0], 10); // Extract hour from given time
                    const givenMinute = parseInt(givenTime.split(":")[1], 10); // Extract minute from given time
                    const currentHour = currentTime.getHours(); // Get current hour
                    const currentMinute = currentTime.getMinutes(); // Get current minute
                    // Compare hours and minutes
                    if (
                      givenHour > currentHour ||
                      (givenHour === currentHour && givenMinute > currentMinute)
                    ) {
                      return true;
                    } else if (
                      givenHour < currentHour ||
                      (givenHour === currentHour && givenMinute < currentMinute)
                    ) {
                      return <span>Invalid</span>;
                    }
                  } else {
                    return true;
                  }
                },
              })}
            />
            {errors?.time && (
              <small className="text-danger my-0 d-block fz-12px fw-500">
                {errors.time?.message}
              </small>
            )}
          </div>
        </div>

        <label className="text-muted fz-13px fw-500">Time Zone</label>
        <div className="cst-drop my-2">
          <select
            className="py-3"
            {...register("timeZone", { required: "Please fill this field" })}
          >
            <option value="indian Standard Time">
              {"(GMT+05:30)"} Indian Standard Time
            </option>
          </select>
          {errors?.timeZone && (
            <small className="text-danger my-0 d-block fz-12px fw-500">
              {errors.timeZone?.message}
            </small>
          )}
        </div>
        <div className="d-flex justify-content-end pt-2">
        <button
                    className='bg-secondary border-0 text-white fz-14px rounded px-5 py-2 me-2'
                    onClick={() => { setIsScheduledDemoClass(false); clearValues() }}
                    disabled={isSubmitting} // Disable the button if form is being submitted
                >
                    Cancel
                </button>
                <button
                    className='border-0 bg-prime text-white fz-14px py-2 px-5 rounded-3'
                    disabled={isSubmitting} // Disable the button if form is being submitted
                >
                    {isSubmitting ? 'Submitting...' : 'Submit'}
                </button>
        </div>
      </form>
      {/* <pre>{JSON.stringify(demoClassData, null, 3)}</pre> */}
    </div>
  );
}

export default DemoClassTimings;