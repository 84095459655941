import { atom, selector } from "recoil";
import { API_CONSTANTS } from "./../../common/constants";

const { BASE_URL, ALL_COURSES } = API_CONSTANTS;

export const coursesEndpointState = atom({
  key: "coursesEndpoint-eeecb3ac-bd4c-4d54-8f8e-b5a8a1e6dbd6",
  default: BASE_URL + ALL_COURSES + "?",
});

export const selectedCategoryCodeState = atom({
  key: "selectedCategoryState-568fb47b-2efe-4908-b01d-fe683557654e",
  default: "",
});

// New, Popular
export const extraFilterState = atom({
  key: "extraFilterState-6a7afbea-272c-4870-b54a-b9aa624c257d",
  default: {
    key: "",
    value: "",
  },
});

// Filter
export const filterState = atom({
  key: "filterState-dae7a36f-8965-4993-8b6e-12213f6a960f",
  default: {
    selectedSubCategories: [],
    prices: [
      {
        name: "price-0-500",
        value: "0 - 500",
        label: "0 - 500",
        courseFeeStart: 0,
        courseFeeEnd: 500,
        selected: false,
      },
      {
        name: "price-500-1000",
        value: "500 - 1000",
        label: "500 - 1000",
        courseFeeStart: 500,
        courseFeeEnd: 1000,
        selected: false,
      },
      {
        name: "price-1000-2000",
        value: "1000 - 2000",
        label: "1000 - 2000",
        courseFeeStart: 1000,
        courseFeeEnd: 2000,
        selected: false,
      },
      {
        name: "price-2000-200000",
        value: "2000 - 200000",
        label: "2000 - above",
        courseFeeStart: 2000,
        courseFeeEnd: 200000,
        selected: false,
      },
    ],
  },
});

export const courseSearchFullEndpointState = selector({
  key: "courseSearchFullEndpoint-a65407f5-34b7-46c4-9edb-93c1f73ce03b",
  get: ({ get }) => {
    let coursesEndpoint = get(coursesEndpointState);
    const selectedCategoryCode = get(selectedCategoryCodeState);
    const extraFilter = get(extraFilterState);
    const filter = get(filterState);

    if (selectedCategoryCode) {
      coursesEndpoint += `givenCategoryName=${selectedCategoryCode}&`;
    }

    if (extraFilter.key && extraFilter.value) {
      coursesEndpoint += `${extraFilter.key}=${extraFilter.value}&`;
    }

    const subCategoryParamsString = filter.selectedSubCategories.reduce(
      (total, currentCode) => {
        return (total += `givenSubCategoryName=${currentCode}&`);
      },
      ""
    );

    coursesEndpoint += subCategoryParamsString;

    const pricesString = filter.prices.reduce((total, current) => {
      return (total += current.selected
        ? `courseFeeStart=${current.courseFeeStart}&courseFeeEnd=${current.courseFeeEnd}&`
        : "");
    }, "");

    coursesEndpoint += pricesString;

    return coursesEndpoint;
  },
});
