import moment from 'moment';
import Peer from 'peerjs';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FaMicrophoneSlash, FaVideoSlash } from "react-icons/fa";
import { BsThreeDotsVertical } from "react-icons/bs";
import { GrClose } from "react-icons/gr";
import { IoMdSend } from 'react-icons/io';
import { useNavigate, useParams } from 'react-router-dom';
import swal from 'sweetalert';
import { API_CALL } from '../../../../api/ApiServiceCall';
import { API_CONSTANTS, API_METHOD, socketWebrtc } from '../../../../common/constants';
import LeftArrow from '../../../svg-icons/leftArrow';
import CallIcon from './uploaded-videos/live-class/liveclass-icons/callIcon';
import ChatIcon from './uploaded-videos/live-class/liveclass-icons/chatIcon';
import MicIcon from './uploaded-videos/live-class/liveclass-icons/micIcon';
import ScreenShareIcon from './uploaded-videos/live-class/liveclass-icons/screenShareIcon';
import VideoIcon from './uploaded-videos/live-class/liveclass-icons/videoIcon';
import { RiStopFill } from "react-icons/ri"
const { REACT_APP_SOCKETHOST } = process.env;

function LiveClassVideoContent({ tokenData, app = false }) {
    const socket = socketWebrtc;
    const [isOpenChatWindow, setisOpenChatWindow] = useState(false);
    const [isOpenUserListWindow, setIsOpenUserListWindow] = useState(false);
    const [isOpenCamera, setIsOpenCamera] = useState(true);
    const [IsWebcamAudio, setIsWebcamAudio] = useState(false);
    const [userJoin, setUserJoin] = useState("")
    const [localMessage, setLocalMessage] = useState("")
    const [userList, setUserList] = useState([])
    const [isExpandMobileMenu, setIsExpandMobileMenu] = useState(false)
    const [isShareScreen, setShareScreen] = useState(false);
    const [isClassDisconnecting, setIsClassDisconnecting] = useState(false);
    const [isLiveStarted, setIsLiveStarted] = useState(false);
    const [isMessageSending, setIsMessageSending] = useState(false);
    const myVideo = useRef(null);
    const myVideoStream = useRef(null);
    const navigate = useNavigate()
    const { roomId } = useParams();
    let muteButton, stopVideo;
    var peer;
    let ROOM_ID = roomId //?
    let user = tokenData?.instructorName;//?
    const [msgList, setMsgList] = useState([])
    const messagesEndRef = useRef(null)
    console.log(socket.connected,'socket connected>>>>>>>>>>>>>>>>>>>>>>>')
    // ======Back==========
    const back = () => {
        navigate(-1)
    }
    // ========Back End=====
    // =============Mobile View check=========
    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
        if (tokenData) {
            console.log("Recorded course detected", tokenData);
            let apiTimeStamp = new Date(tokenData?.classTime).getTime();
            let momentObj = moment(apiTimeStamp);
            // let updatedMomentObj = momentObj.add(1, "hour");
            console.log("added timeStamp", moment(momentObj).format("DD/MM/YYYY, HH:mm A"));
            const currentTime = moment();
            const currentHour = currentTime.hours();
            const currentMinute = currentTime.minutes();
            if (momentObj.isSame(currentTime, "day")) {
                if(tokenData?.isLiveCourse) {
                  const startTimeVal = tokenData?.startTime?.split(':');
                  const endTimeVal = tokenData?.endTime?.split(':');
                  console.log("currentTime for live test", currentHour, " : ", currentMinute);
                  console.log("startTimeVal for live test", startTimeVal, "   and endTimeVal ", endTimeVal);
                  if (Number(endTimeVal[0]) < currentHour  &&  Number(endTimeVal[1]) < currentMinute) {
                      console.log("The updated hour is in the past.");
                  } else if (Number(startTimeVal[0]) > currentHour  &&  Number(startTimeVal[1]) > currentMinute) {
                      console.log("The updated hour is in the future.");
                  } else {
                      onStartLiveCourse();
                  }
                } else {   
                    const liveTimeAfterCheck = momentObj.isAfter(currentTime, "hour");
                    const liveTimeBeforeCheck = momentObj.isBefore(currentTime, "hour");
                    console.log("liveTimeAfterCheck for live demo class ", liveTimeAfterCheck);
                    console.log("liveTimeBeforeCheck for live demo class ", liveTimeBeforeCheck);
                    onStartLiveClass();
                }
            } else if (momentObj.isAfter(currentTime)) {
                futureTimeAlert();
            } else if (momentObj.isBefore(currentTime)) {
                swal({
                    title: "Expired",
                    text: "This Class Was Expired!",
                    icon: 'https://cdn-icons-png.flaticon.com/512/2569/2569174.png',
                    className: "error-window",
                    closeOnClickOutside: false,
                    button: false,
                }).then(() => {
                    navigate("/courses/recorded")
                    window.location.reload();
                });
            } else {
                futureTimeAlert();
            }

        }

        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }
        window.addEventListener('resize', handleWindowResize);

        
        socket.open();
        socket.on("connection-success", async (response) => {
            console.log(`Socket connected ${response.socketId}`);
        });
        socket.on("message", receiveMessages)
        socket.on("userJoin", handleUserJoin);

        // videoGrid = document.getElementById("video-grid");
        muteButton = document.querySelector("#muteButton");
        stopVideo = document.querySelector("#stopVideo");
        muteButton.addEventListener("click", () => {
            const enabled = myVideoStream.current.getAudioTracks()[0].enabled;
            if (enabled) {
                myVideoStream.current.getAudioTracks()[0].enabled = false;
            } else {
                myVideoStream.current.getAudioTracks()[0].enabled = true;
            }
        });
        stopVideo.addEventListener("click", () => {
            const enabled = myVideoStream.current.getVideoTracks()[0].enabled;
            if (enabled) {
                myVideoStream.current.getVideoTracks()[0].enabled = false;
            } else {
                myVideoStream.current.getVideoTracks()[0].enabled = true;
            }
        });
        myVideo.current.muted = true;
        getMedia();

        const interval = setInterval(() => {
          console.log("SOCKET.CONNECTED IN interval", socket.connected, "    and IsLiveStarted", isLiveStarted);
          if (socket.connected) {
            clearInterval(interval);
            var liveClassVideoEle = document.getElementById("liveClassVideo");
            liveClassVideoEle.style.display = "block";
            setIsLiveStarted(true);
          } else {
            console.log("SOCKET.CONNECTED taking time ", socket.connected);
          }
        }, 1000);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
            socket.off("message", receiveMessages);
            socket.off("userJoin", handleUserJoin);
            socket.close();
            clearInterval(interval);
            socket.disconnect();
            socket.on("disconnect", function () {});
        };
    }, []);
    
    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }
    // =============Mobile View check end=========
    // ===============Chat Code=========
    const openChatWindow = () => {
        setIsOpenUserListWindow(false)
        setisOpenChatWindow(!isOpenChatWindow)
    }
    const sendMessage = (e) => {
        console.log("Inst msg", localMessage);
        e.preventDefault();
        if (!localMessage) return null;
        setIsMessageSending(true);
        socket.emit("sendMessage", { message: localMessage }, () => {
            console.log("Inst msg sent successfully", localMessage);
            setIsMessageSending(false);
            setMsgList(chatMessages => ([
                ...chatMessages,
                {
                    user: tokenData?.instructorName,
                    message: localMessage,
                    time: Date.now()
                }
            ]));
            setLocalMessage("")
        })
    }
    const receiveMessages = useCallback((response) => {
        console.log(response);
        setMsgList(chatMessages => ([
            ...chatMessages,
            {
                user: response.userName,
                time: response.createdAt,
                message: response.message
            }
        ]));
    }, [])
    const handleUserJoin = (response) => {
        // ---getting user joined res----
        console.log(response)
        setUserJoin(response);
    }
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }
    // ----Chat useEffect
    useEffect(() => {
        scrollToBottom()
    }, [msgList, openChatWindow]);
    // ----Chat useEffect End
    // ============================Chat Code End=========
    // ===get user List -people=======
    const openUserList = () => {
        setisOpenChatWindow(false)
        setIsOpenUserListWindow(!isOpenUserListWindow)
    }
    const getUserList = () => {
        socket.emit("getPeerList", { roomName: roomId }, (response) => {
            console.log("userList", response)
            console.log("userCount", response.length)
            setUserList(response)
        })
    }
    // ===get user List -people ENd=======
    // ===========WEBRTC CODE=============
    const getMedia = () => {
        navigator.mediaDevices
            .getUserMedia({
                audio: true,
                video: true,
            })
            .then((stream) => {
                streamToRemote(stream);
                console.log("successfully connected to media");
                if (window.ReactNativeWebView){
                    window.ReactNativeWebView.postMessage('MEDIA_CONNECTION_SUCCESS');
                }
            })
            .catch((err) => {
                console.log("unable to connect to media", err);
                if (window.ReactNativeWebView){
                    window.ReactNativeWebView.postMessage('MEDIA_CONNECTION_FAILED');
                }
            });
    }
    
    const streamToRemote = (stream) => {
        console.log("Stream -------->")
        console.log(stream)
        myVideoStream.current = stream;
        peer = new Peer(
          {
            path: "/peerjs",
            // host: "d3i5qde2c24m97.cloudfront.net",
            // port: "3002", //443
            // host: "live.qlearning.academy",
            // port: "",
            host: REACT_APP_SOCKETHOST,
            port: "", //443
          }
        );
        console.log("Connected on default Port");
        peer.on("open",(id) => {
            console.log("join-room ---livevideocontent ROOM_ID", ROOM_ID);
            console.log("join-room ---livevideocontent peerId", id);
            console.log("join-room ---livevideocontent user", user);
            socket.emit("join-room", { roomName: ROOM_ID, peerId: id, userName: user });
        });
        peer.on("call", (call) => {
            console.log("call func instructor stream", stream);
            console.log("call func instructor call", call);
            call.answer(stream);
            // const video = document.createElement("video");
            call.on("stream", (userVideoStream) => {
                // addVideoStream(video, userVideoStream);
            });
        });
        socket.on("user-connected", (userId) => {
            connectToNewUser(userId, stream);
        });
        socket.on("user-disconnected", (userId) => {
            if (peer[userId]) {
                peer[userId].close();
            }
        });
        addVideoStream(stream);
    }
    const addVideoStream = (stream) => {
        console.log("video stream...", stream);
        console.log("myVideo.current element...", myVideo.current);
        myVideo.current.srcObject = stream;
        myVideo.current.addEventListener("loadedmetadata", () => {
            myVideo.current.play();
            socket.emit("reload", { roomName: roomId }, () => {
                console.log("Reload Ran")
            })
        });
    };
    const connectToNewUser = (userId, stream) => {
        const call = peer.call(userId, stream);
        // const video = document.createElement("video");
        call.on("stream", (userVideoStream) => {
            console.log("call.on", userId);
            // addVideoStream(video, userVideoStream);
        });
    };
    // ===========WEBRTC CODE End=============
    // ===========share screen CODE=============
    async function startSharing() {
        try {
            navigator.mediaDevices.getDisplayMedia({
                video: true,
                audio: true
            }).then(async stream => {
                const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true })
                stream.addTrack(audioStream.getAudioTracks()[0])
                console.log("Stream connected share screen...")
                console.log(stream)
                setShareScreen(true)
                // myVideo.current.srcObject = stream;
                myVideoStream.current.getVideoTracks()[0].enabled = false;
                myVideoStream.current = null;
                streamToRemote(stream)
            })

        } catch (error) {
            console.log(error);
        }
    }
    function stopSharing() {
        let tracks = myVideo.current.srcObject.getTracks();
        tracks.forEach((track) => track.stop());
        myVideo.current.srcObject = null;
        setShareScreen(false)
        // window.location.reload();
        getMedia();
    }
    // ===========share screen CODE End=============
    const futureTimeAlert = () => {
        swal({
            title: "Unable to Start!",
            text: "scheduled time was not yet came",
            icon: 'https://cdn-icons-png.flaticon.com/512/2569/2569174.png',
            className: "error-window",
            closeOnClickOutside: false,
            button: false,
        });
    }
    // ---onStart Live code
    const onStartLiveClass = async () => {
        const requestBodyParam = JSON.stringify({
            courseCode: tokenData?.courseCode,
            liveClassOrder: tokenData?.liveClassOrder,
            liveUUID: roomId
        })
        const request = {
            apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.START_LIVE_CLASS,
            method: API_METHOD.POST,
            requestBodyParams: requestBodyParam,
        }
        let result = await API_CALL(request)
        if (result.status === 200) {
            // const courseCode = { data: tokenData?.courseCode }
            // localStorage.setItem(JSON.stringify(courseCode))
        } else if (result.status > 200) {
            swal({
                title: "ERROR",
                text: result.message,
                icon: 'https://cdn-icons-png.flaticon.com/512/2569/2569174.png',
                className: "error-window",
                closeOnClickOutside: false,
                button: false,
            })
        }
    }
    // ---onStart Live code End 
    // ---onEnd Live code
    const onEndLiveClass = async () => {
        setIsClassDisconnecting(true);
        const requestBodyParam = JSON.stringify({
            courseCode: tokenData?.courseCode,
            liveClassOrder: tokenData?.liveClassOrder,
            liveUUID: roomId
        })
        const request = {
            apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.END_LIVE_CLASS,
            method: API_METHOD.POST,
            requestBodyParams: requestBodyParam,
        }
        let result = await API_CALL(request)
        if (result.status === 200) {
            socket.emit("end", { roomName: roomId }, () => {
                console.log("Socket instrucor live class ended");
                setIsClassDisconnecting(false);
                if (!app) {
                    // navigate("/courses/overview")
                    navigate("/courses/recorded")
                    window.location.reload();
                } else {
                    if (window.ReactNativeWebView) { // ensure window.ReactNativeWebView is there, otherwise, web app might crash if is not there
                        window.ReactNativeWebView.postMessage('LIVE_END')
                    }
                }
            })
        } else if (result.status > 200) {
            swal({
                title: "ERROR",
                text: result.message,
                icon: 'https://cdn-icons-png.flaticon.com/512/2569/2569174.png',
                className: "error-window",
                button: true,
            })
        }
    }
    // ---onStart Live code
    const onStartLiveCourse = async () => {
        const requestBodyParam = JSON.stringify({
            liveClassId: tokenData?.liveCourseClassId,
            courseCode: tokenData?.courseCode
        })
        const request = {
            apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.START_LIVE_COURSE_CLASS,
            method: API_METHOD.POST,
            requestBodyParams: requestBodyParam,
        }
        let result = await API_CALL(request)
        if (result.status === 200) {
            localStorage.setItem('courseCode', tokenData?.courseCode);
        } else if (result.status > 200) {
            swal({
                title: "ERROR",
                text: result.message,
                icon: 'https://cdn-icons-png.flaticon.com/512/2569/2569174.png',
                className: "error-window",
                closeOnClickOutside: false,
                button: false,
            })
        }
    }
    // ---onStart Live code End 
    // ---onEnd Live code
    const onEndLiveCourse = async () => {
        setIsClassDisconnecting(true);
        const requestBodyParam = JSON.stringify({
            liveClassId: tokenData?.liveCourseClassId,
            courseCode: tokenData?.courseCode,
            classDuration: moment().format(),

        })
        const request = {
            apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.END_LIVE_COURSE_CLASS,
            method: API_METHOD.POST,
            requestBodyParams: requestBodyParam,
        }
        let result = await API_CALL(request)
        if (result.status === 200) {
            socket.emit("end", { roomName: roomId }, () => {
                console.log("Instructor successfully ended the call");
                setIsClassDisconnecting(false);
                if (!app) {
                    navigate("/courses/live");
                    window.location.reload();
                } else {
                    if (window.ReactNativeWebView) { // ensure window.ReactNativeWebView is there, otherwise, web app might crash if is not there
                        window.ReactNativeWebView.postMessage('LIVE_END')
                    }
                }
            })
        } else if (result.status > 200) {
            swal({
                title: "ERROR",
                text: result.message,
                icon: 'https://cdn-icons-png.flaticon.com/512/2569/2569174.png',
                className: "error-window",
                button: true,
            })
        }
    }
    // ---onEnd Live code End
    // ================Live Course End=============
    // ===Globally call end==========
    const onCallEnd = () => {
        if (tokenData && tokenData?.isLiveCourse) {
            onEndLiveCourse();
        } else {
            onEndLiveClass();
        }
    }
    // ===Globally call end==========


    return (
        <div className="container px-5  position-relative">

            {/* <video id="video" width="0" height="0" autoPlay></video> */}
            {/* <pre>{JSON.stringify(tokenData, null, 4)}</pre> */}

            <div className="d-flex align-items-center mb-3 mt-2">
                {windowSize.innerWidth >= 1000 ? <div onClick={back} role="button" type="button"><LeftArrow size={8} /></div> : ""} <p className='ms-2 fw-500 fz-15px my-0'>{tokenData?.topicName}</p>
            </div>
            <div className='position-lg-relative'>
                {isOpenUserListWindow ? <div className='chat-window p-0 ticket-chat' >
                    <div className='d-flex justify-content-lg-end align-items-lg-center'>
                        <h6 className='me-auto m-0 px-3 py-4'>People ({userList?.length})</h6>
                        <button className='bg-transparent border-0 px-3' onClick={() => setIsOpenUserListWindow(false)}><GrClose /></button>
                    </div>
                    <ul className=" list-unstyled px-3" style={{ height: '200px', width: '300px', overflowY: 'scroll' }}>
                        {userList?.length ?
                            userList.map((item, inx) => (
                                <li key={inx}><span className="text-muted fz-14px">{item?.name ? "Name:" : ""}</span> <span className="text-prime fz-14px fw-500">{item?.name}</span></li>
                            )
                            ) : ""
                        }
                    </ul>
                </div> :
                    ""}
                {/* ---------Chat window---------- */}
                {isOpenChatWindow ?
                    <div className='chat-window p-0 ticket-chat' >
                        <div className='d-sm-flex justify-content-lg-end align-items-lg-center'>
                            <h6 className='me-auto m-0 px-3 py-4'>Chat</h6>
                            <button className='bg-transparent border-0 px-3' onClick={() => setisOpenChatWindow(false)}><GrClose /></button>
                        </div>
                        <div className="text-right px-3" style={{ height: '200px', overflowY: 'scroll' }}>
                            {userJoin?.username ? <p className="fz-11px bg-pink text-center py-2 text-prime rounded-3 ">{userJoin?.username} {new Date(userJoin?.createdAt).toLocaleTimeString([], { hour: '2-digit', minute: "2-digit" })}</p> : ""}
                            {msgList?.map((lst, indx) => (
                                <div key={lst + indx} className='' ref={messagesEndRef}>
                                    <p className='m-0 fw-600 fz-14px text-end'><span className='text-muted fz-10px fw-400'>{new Date(lst.time).toLocaleTimeString([], { hour: '2-digit', minute: "2-digit" })}</span> {lst?.user}</p>
                                    <p className='text-end fz-14px'>{lst?.message}</p>
                                </div>
                            ))}
                        </div>
                        <form onSubmit={sendMessage} className='p-2 bg-white mt-1 rounded-9 bottom-0 position-relative'>
                            {/* <button className='border-0 align-attach left-0 fz-18px bg-transparent text-prime' style={{
                                position: "absolute",
                                bottom: "15px",
                                left: "15px"
                            }}
                                type='button'><AttachIcon /></button> */}
                            <input className='chat-input pe-5' value={localMessage} type={"text"} onChange={(e) => setLocalMessage(e.target.value)} disabled={isMessageSending} />
                            <button className='border-0 align-arrow  fz-18px bg-transparent text-prime' disabled={isMessageSending} type={"submit"}><IoMdSend /></button>
                        </form>
                        {
                          isMessageSending &&
                          <div style={{fontSize: '10px', padding: '0 1rem 0.5rem', color: '#7c7c7c'}}>Sending...</div>
                        }
                    </div>
                    : ""}
                <div id={"video-grid"}>
                    {
                        isClassDisconnecting ? 
                        <div className="mt-4 p-4 rounded-3 d-flex justify-content-center align-items-center" style={{ backgroundColor: '#f2f3f4', height: '550px' }}>
                            <span style={{ fontSize: '1.2rem', textAlign: 'center' }}>
                                Disconnecting. Please wait...
                            </span>
                        </div> : <> 
                            {
                                !isLiveStarted &&
                                <div id='liveClassLoader' className="mt-4 p-4 rounded-3 d-flex justify-content-center align-items-center live-class-loader" style={{ backgroundColor: '#f2f3f4', height: '550px', display: 'block' }}>
                                    <span style={{ fontSize: '1.2rem', textAlign: 'center' }}>
                                        Connecting. Please wait...
                                    </span>
                                </div>
                            }
                            <video id='liveClassVideo' ref={myVideo} autoPlay width={"100%"} height={"100%"} style={{ display: 'none' }}></video>
                        </>
                    }
                </div>
            </div>
            {/* <h2>Width: {windowSize.innerWidth}</h2>

            <h2>Height: {windowSize.innerHeight}</h2> */}

            {/* --------For PC----------------------- */}
            {windowSize.innerWidth >= 1000 ?
                <div className="d-flex justify-content-center  align-items-center">
                    <div className='mx-lg-auto'>
                        <button id="stopVideo" className="border-0 rounded-circle wh-76" onClick={() => setIsOpenCamera(!isOpenCamera)} >
                            {isOpenCamera ? <VideoIcon /> : <FaVideoSlash fill='#395061' size={23} />}
                        </button>
                        <button id="muteButton" className="border-0 rounded-circle wh-76" onClick={() => setIsWebcamAudio(!IsWebcamAudio)}>
                            {IsWebcamAudio ? <FaMicrophoneSlash fill='#395061' size={23} /> : <MicIcon />}
                        </button>
                        {isShareScreen ?
                            <button className="border-0 rounded-circle wh-76" onClick={stopSharing} >
                                <RiStopFill size={"24"} color='#395061' />
                            </button> :
                            <button className="border-0 rounded-circle wh-76" onClick={startSharing} >
                                <ScreenShareIcon />
                            </button>
                        }
                        <button className="border-0 bg-danger rounded-circle wh-76 " onClick={onCallEnd}>
                            <CallIcon />
                            {/* <p id="stop" onClick={stopSharing}>Stop Sharing</p> */}
                        </button>
                    </div>
                    <div>
                        {/* <button className='border-0 bg-transparent' onClick={openUserList}>
                        <BiUser size={29} color={"#395061"} />
                    </button> */}
                        <button className='border-0 bg-transparent' onClick={openChatWindow}>
                            <ChatIcon />
                        </button>
                    </div>
                </div>
                :
                <>
                    {/* --------For Mobile----------------------- */}

                    <div className='position-relative'>
                        <div className="row  align-items-center">
                            <div className="col-3">
                                <button id="stopVideo" className="border-0 rounded-circle wh-76" onClick={() => setIsOpenCamera(!isOpenCamera)} >
                                    {isOpenCamera ? <VideoIcon /> : <FaVideoSlash fill='#395061' size={23} />}
                                </button>
                            </div>
                            <div className="col-3">
                                <button id="muteButton" className="border-0 rounded-circle wh-76" onClick={() => setIsWebcamAudio(!IsWebcamAudio)}>
                                    {IsWebcamAudio ? <FaMicrophoneSlash fill='#395061' size={23} /> : <MicIcon />}
                                </button>
                            </div>
                            <div className="col-3">
                                <button className="border-0 bg-danger rounded-circle wh-76 " onClick={onCallEnd}>
                                    <CallIcon />
                                </button>
                            </div>
                            <div className="col-3">
                                <button className="border-0  rounded-circle  wh-76" onClick={() => setIsExpandMobileMenu(!isExpandMobileMenu)}>
                                    <BsThreeDotsVertical size={20} />
                                </button>
                            </div>
                        </div>

                        {isExpandMobileMenu ?
                            <div className='mobile-webrtc-menu'>
                                {/* {isShareScreen ?
                                    <button className="border-0 rounded-circle wh-76" onClick={stopSharing} >
                                        <RiStopFill size={"24"} color='#395061' />
                                    </button> :
                                    <button className="border-0 rounded-circle wh-76" onClick={startSharing} >
                                        <ScreenShareIcon />
                                    </button>
                                }
                                <div className="my-2"></div> */}
                                <button className='border-0 rounded-circle expand' onClick={openChatWindow}>
                                    <svg width="30" height="20" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10 37.5C9.81625 37.5 9.63125 37.4587 9.4575 37.3762C9.02625 37.1675 8.75 36.7313 8.75 36.25V30H5C2.9325 30 1.25 28.3175 1.25 26.25V6.25C1.25 4.1825 2.9325 2.5 5 2.5H35C37.0675 2.5 38.75 4.1825 38.75 6.25V26.25C38.75 28.3175 37.0675 30 35 30H19.8137L10.7812 37.2262C10.555 37.4075 10.2788 37.5 10 37.5ZM5 5C4.31 5 3.75 5.56125 3.75 6.25V26.25C3.75 26.9387 4.31 27.5 5 27.5H10C10.6912 27.5 11.25 28.0588 11.25 28.75V33.65L18.5938 27.7738C18.8162 27.5963 19.09 27.5 19.375 27.5H35C35.69 27.5 36.25 26.9387 36.25 26.25V6.25C36.25 5.56125 35.69 5 35 5H5Z" fill="#395061" />
                                        <path d="M30 15H10C9.30875 15 8.75 14.44 8.75 13.75C8.75 13.06 9.30875 12.5 10 12.5H30C30.6912 12.5 31.25 13.06 31.25 13.75C31.25 14.44 30.6912 15 30 15Z" fill="#395061" />
                                        <path d="M20 20H10C9.30875 20 8.75 19.44 8.75 18.75C8.75 18.06 9.30875 17.5 10 17.5H20C20.6912 17.5 21.25 18.06 21.25 18.75C21.25 19.44 20.6912 20 20 20Z" fill="#395061" />
                                    </svg>
                                </button>
                            </div> : ""
                        }
                    </div>
                </>
            }
            {/* --------For Mobile----------------------- */}
            {/* <button id="produce" onClick={sendTransport}>start</button> */}
        </div >
    )
}
export default LiveClassVideoContent;
