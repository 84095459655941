import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import { API_CALL } from '../../../../api/ApiServiceCall';
import { API_CONSTANTS, API_METHOD } from '../../../../common/constants';
import { NewEmailState } from '../../../../components/atom/accountSettingNewEmailValue';
import { useRecoilState } from 'recoil';
import swal from 'sweetalert';

function ChangeEmail({ email, setSelectedModal }) {
    const [error, seterror] = useState(null);
    const [loading, setLoading] = useState(null);
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [newEmailAtom, setNewEmailAtom] = useRecoilState(NewEmailState);

    const onSubmit = async (data) => {
        setLoading(true);
        const requestBodyParam = JSON.stringify({
            email: email,
            newemail: data.newemail
        });

        setNewEmailAtom(data.newemail)
        const request = {
            apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.CHANGE_STUDENT_EMAIL,
            method: API_METHOD.POST,
            requestBodyParams: requestBodyParam,
        }
        let result = await API_CALL(request)
        if (result.status === 200) {
            setLoading(false);
            setSelectedModal();
        } else if (result.status > 200) {
            swal({
                title: "ERROR",
                text: result.message,
                icon: 'https://cdn-icons-png.flaticon.com/512/2569/2569174.png',
                className: "error-window",
                button: true,
            })
            setLoading(false);

        }
    }

    return (
        <div>
            <Modal.Header closeButton>
                <Modal.Title>Change Email</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className='login-col' onSubmit={handleSubmit(onSubmit)}>
                    <label>Your Present Email</label>
                    <input className='mb-2' type="email" value={email} readOnly />
                    <label htmlFor='frg_pss_email'>Type Your New Email</label>
                    <input className='mb-2' placeholder='Type Your Email' id="newemail" {...register("newemail", {
                        required: true,
                        pattern: {
                            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        },
                    })} />
                    <small className='text-danger mb-2 d-block fz-12px fw-500'> {errors.newemail?.type === 'required' && "Please Enter your Email"}</small>
                    <small className='text-danger mb-2 d-block fz-12px fw-500'> {errors.newemail?.type === 'pattern' && "Please enter a valid email"}</small>
                    <button className='login-form-btn' disabled={loading ? true : false}>{loading ? "Please wait..." : 'Change Email'}</button>
                    <small className='text-danger mb-2 d-block fz-15px fw-500 text-center'> {error}</small>
                </form>
            </Modal.Body>
        </div>
    )
}

export default ChangeEmail