import { useEffect, useState } from "react";
import { Container, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { API_CALL } from "../../api/ApiServiceCall";
import { API_CONSTANTS, API_METHOD } from "../../common/constants";

function VerifyOTPSignUp({ createAccDetails, handleClose }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const accountDetails = JSON.parse(createAccDetails);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const resendOtp = async () => {
    setLoading(true);
    let requestBody = JSON.stringify({
      email: accountDetails.email,
      userType: "STUDENT",
    });
    const request = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.RESEND_OTP,
      method: API_METHOD.POST,
      requestBodyParams: requestBody,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      setSeconds(60);
    } else if (result.status > 200) {
      setError(result.message);
    }
    setLoading(false);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    let requestBody = JSON.stringify({
      email: accountDetails.email,
      code: data.code,
      userType: "STUDENT",
    });
    const request = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.VERIFY_OTP,
      method: API_METHOD.POST,
      requestBodyParams: requestBody,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      console.log(result.data);
      setLoading(false);
      reset();
      handleClose();
      await swal({
        title: "Account Created Successfully Please Login Now!",
        text: "",
        icon: "success",
        button: "OK",
      });
      navigate("/student-login");
    } else if (result.status > 200) {
      setError(result.message);
      setLoading(false);
    }
  };

  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(60);
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <Container>
      <Modal.Header closeButton className="mh">
        <Modal.Title className="mdlt">Verify It’s You</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="fz-16px pb-1 text-black">
          We Sent 6 Digit OTP to {accountDetails.email}
        </p>
        <form className="login-col" onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="otp">Enter 6 Digit OTP Code</label>
          <input
            type="number"
            placeholder="Enter 6 Digit OTP Code"
            id="otp"
            {...register("code")}
            className="mb-0"
            required
          />
          <div className="d-flex align-items-center justify-content-end mt-2 mb-3 otp">
            {" "}
            {minutes === 0 && seconds === 0 ? null : (
              <h6 className="m-0">
                {" "}
                {minutes}:{seconds < 0 ? `0${seconds}` : seconds}
              </h6>
            )}
            <button
              role="button"
              type="button"
              onClick={resendOtp}
              disabled={seconds === 0 ? false : true}
              className="ms-2 mb-0 w-auto border-0 bg-transparent"
            >
              Resend
            </button>
          </div>
          <small className="text-danger mb-2 d-block fz-12px fw-500">
            {" "}
            {error}
          </small>
          <button
            className="login-form-btn"
            type="submit"
            disabled={seconds === 0 ? true : false}
          >
            {loading ? "Please wait..." : "Submit"}
          </button>
        </form>
      </Modal.Body>
    </Container>
  );
}

export default VerifyOTPSignUp;
