import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useRecoilState } from "recoil";
import swal from "sweetalert";
import { API_CALL } from "../../../../api/ApiServiceCall";
import { API_CONSTANTS, API_METHOD } from "../../../../common/constants";
import { OverviewDataState } from "../../../atom/overviewData";
import { OverviewTriggerRefetch } from "../../../atom/overviewRefetchTrigger";

export default function OverviewEdit({ setIsEdit }) {
  const [overviewData, setOverviewData] = useRecoilState(OverviewDataState);
  const [overviewRefecthTrigger, setOverviewRefecthTrigger] = useRecoilState(
    OverviewTriggerRefetch
  );
  const [richTextValue, setRichTextValue] = useState(
    overviewData ? overviewData?.data[0]?.courseOverview : ""
  );
  const [getSubCategory, setGetSubCategory] = useState(false);
  const [triggerChange, setChange] = useState("");
  const [getCategory, setGetCategory] = useState([]);
  const {
    reset,
    register,
    watch,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm({ mode: "onChange" });
  const [loading, setLoading] = useState(false);
  const onSubmit = async (data) => {
    if (richTextValue != "" && richTextValue != "<p><br></p>") {
      setLoading(true);
      const inputData = { data, courseOverview: richTextValue };
      const userCode = JSON.parse(localStorage.getItem("userCode"));
      const richtext = richTextValue
        ? richTextValue
        : overviewData?.data[0]?.courseOverview;
      const requestBodyParam = JSON.stringify({
        courseCode: userCode.data,
        courseOverview: richtext,
        catogory: data.updatecategory,
        subCategory: data.subCategory,
        courseName: data.updatetitle,
      });
      const request = {
        apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.UPDATE_COURSE_DETAILS,
        method: API_METHOD.POST,
        requestBodyParams: requestBodyParam,
      };
      let result = await API_CALL(request);
      if (result.status === 200) {
        setOverviewRefecthTrigger({
          overView: "change occur from overview content Page!",
        });
        setLoading(false);
        setIsEdit(false);
      } else if (result.status > 200) {
        swal({
          title: "ERROR",
          text: result.message,
          icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
          className: "error-window",
          button: true,
        });
        setLoading(false);
      }
    }
  };
  const watchShowCount = watch("updatetitle", ""); // you can supply default value as second argument

  const getAllCategory = async () => {
    const request = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.GET_ALL_CATEGORY,
      method: API_METHOD.GET,
    };
    let result = await API_CALL(request);

    if (result.status === 200) {
      setGetCategory(result.data);
    } else if (result.status > 200) {
      console.log("errror", result);
    }
  };

  const getCurrentCategoryCode = (e) => {
    getSubCategoryByCode(e.target.value);
    setValue("subCategory", "", { shouldValidate: true });
  };
  const getSubCategoryByCode = async (catCode) => {
    const request = {
      apiUrl:
        API_CONSTANTS.BASE_URL +
        API_CONSTANTS.GET_SUB_CATEGORY_BY_CATEGORYCODE +
        `/?categoryCode=${catCode}`,
      method: API_METHOD.GET,
    };
    let result = await API_CALL(request);

    if (result.status === 200) {
      setGetSubCategory(result);
    } else if (result.status > 200) {
      console.log("errror", result);
    }
  };
  useEffect(() => {
    getCatSubCat();
  }, []);

  async function getCatSubCat() {
    await getAllCategory();
    await getSubCategoryByCode(overviewData?.data[0]?.catogory);
    let defaultValues = {};
    defaultValues.updatecategory = overviewData?.data[0]?.catogory;
    defaultValues.updatetitle = overviewData?.data[0]?.courseName;
    defaultValues.subCategory = overviewData?.data[0]?.subCategory;
    reset({ ...defaultValues });
  }
  // useEffect(() => {

  // }, [getCategory, triggerChange]);
  return (
    <>
      <div className="container overview-card">
        <div className="login-col">
          <form id="hook-form" onSubmit={handleSubmit(onSubmit)}>
            <label className="fz-14px" htmlFor="tile">
              Course Title
            </label>
            <div className="d-flex position-relative">
              {" "}
              <input
                type="text"
                onInput={(e) => {
                  if (e.target.value.trim() == "") {
                    e.target.value = "";
                  }
                }}
                {...register("updatetitle", { required: true })}
                maxLength="120"
              />
              <div className="counter"> {`${watchShowCount.length}/120`}</div>
            </div>
            {errors.updatetitle?.type === "required" && (
              <small className="text-danger mb-2 d-block fz-12px fw-500">
                {" "}
                Please Fill this Field{" "}
              </small>
            )}

            <label className="fz-14px" htmlFor="tile">
              Course Overview
            </label>
            <ReactQuill
              theme="snow"
              defaultValue={richTextValue.trim()}
              onChange={setRichTextValue}
              className="rich-h"
            />
            <div className="py-4"></div>
            <div className="py-2"></div>
            {richTextValue == "" || richTextValue == "<p><br></p>" ? (
              <small className="text-danger mb-2 d-block fz-12px fw-500">
                {" "}
                Please Fill this Field{" "}
              </small>
            ) : (
              ""
            )}
            <div className="row">
              <div className="col">
                <label className="fz-14px">Category</label>

                <div className="tag-ajus">
                  <select
                    {...register("updatecategory")}
                    onChange={getCurrentCategoryCode}
                  >
                    {getCategory
                      ? getCategory.map((catDatas, index) => (
                          <option
                            key={catDatas._id + index}
                            value={catDatas.categoryCode}
                          >
                            {catDatas.categoryName}
                          </option>
                        ))
                      : ""}
                  </select>
                </div>
              </div>
              <div className="col-lg-1"></div>
              <div className="col">
                <label className="fz-14px">Sub - Category</label>
                <div className="tag-ajus">
                  <select
                    {...register("subCategory", {
                      required: "Please fill this field",
                    })}
                  >
                    <option value="" className="d-none">
                      Select a Sub - category
                    </option>
                    {/* <option className='d-none' value={overviewData ? overviewData?.data[0]?.subCategory : ""}>{overviewData ? overviewData?.data[0]?.subCategory : ""}</option> */}
                    {getSubCategory
                      ? getSubCategory.data.map((subCatDatas, index) => (
                          <option
                            key={subCatDatas._id + "key" + index}
                            value={subCatDatas.subCategoryCode}
                          >
                            {subCatDatas.subCategoryName}
                          </option>
                        ))
                      : ""}
                  </select>
                </div>
                {errors?.subCategory && (
                  <small className="text-danger mb-2 d-block fz-12px fw-500">
                    {errors.subCategory?.message}
                  </small>
                )}
              </div>
            </div>
          </form>
        </div>
        <div className="d-flex justify-content-end pt-3">
          <div
            className="border-0 bg-secondary text-white fz-14px py-2 w-14 rounded-3 text-center"
            type="button"
            role="button"
            onClick={() => setIsEdit(false)}
          >
            Cancel
          </div>
          <button
            className="ms-lg-3 border-0 bg-prime text-white fz-14px py-2 w-14 rounded-3 text-center"
            type="submit"
            form="hook-form"
          >
            Done
          </button>
        </div>
      </div>
    </>
  );
}
