import { encode } from "base-64";

const { REACT_APP_DYTE_USERNAME, REACT_APP_DYTE_PASSWORD } = process.env;
console.log("REACT_APP_DYTE_USERNAME", REACT_APP_DYTE_USERNAME);
console.log("REACT_APP_DYTE_PASSWORD", REACT_APP_DYTE_PASSWORD);

// const dyteAuthorization = `Basic ${window.btoa(`${REACT_APP_DYTE_USERNAME}:${REACT_APP_DYTE_PASSWORD}`)}`

const encodeStr = `${REACT_APP_DYTE_USERNAME}:${REACT_APP_DYTE_PASSWORD}`;
const dyteAuthorization = encode(encodeStr);

console.log("dyteAuthorization is: ", dyteAuthorization);

export const handleInitMeeting = async (meetingTitle, userData, userType) => {
  const reqData = {
    title: meetingTitle,
    // "preferred_region": "ap-south-1",
    record_on_start: false,
    // "live_stream_on_start": true
  };

  return await fetch("https://api.cluster.dyte.in/v2/meetings/", {
    method: "POST",
    headers: new Headers({
      Authorization: `Basic ${dyteAuthorization}`,
    }),
    body: JSON.stringify(reqData),
  })
    .then((response) => response.json())
    .then(async (responseData) => {
      console.log("Dyte response DATA", responseData, userData);
      console.log("userData.name is", userData.name);

      const roomId = responseData.data.id;

      const joinParticipantsRes = await handleJoinParticipants(
        roomId,
        userData,
        userType
      );
      console.log("joinParticipantsRes", joinParticipantsRes);
      return joinParticipantsRes;
    });
};

export const handleJoinParticipants = async (roomId, userData, userType) => {
  const addParticipentData = {
    name: userData.name,
    client_specific_id: userData.userId,
    preset_name: userType,
  };
  console.log("addParticipentData is", addParticipentData);

  return await fetch(
    `https://api.cluster.dyte.in/v2/meetings/${roomId}/participants`,
    {
      method: "POST",
      headers: new Headers({
        Authorization: `Basic ${dyteAuthorization}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(addParticipentData),
    }
  )
    .then((participentRes) => participentRes.json())
    .then(async (participentResData) => {
      console.log("Dyte PARTICIPENT response DATA", participentResData);

      const tokenData = participentResData.data.token;
      console.log("tokenData is", tokenData);

      // const liveStreamRes = await handleLiveStream(tokenData, roomId);
      // console.log("liveStreamRes", liveStreamRes);
      // return liveStreamRes;

      const renderData = { tokenData, roomId };
      console.log("2) Dyte webinor STREAM response DATA", {
        tokenData,
        roomId,
      });
      return renderData;
    });
};

// export const handleLiveStream = async (tokenData, roomId) => {
//     return await fetch(`https://api.dyte.io/v2/meetings/${roomId}/livestreams`, {
//         method: "POST",
//         headers: new Headers({
//             "Authorization": `Bearer ${tokenData}`,
//             "Content-Type": "application/json"
//         }),
//         body: JSON.stringify({ name: 'liveStream' }),
//     })
//         .then(liveStreamRes => liveStreamRes.json())
//         .then(liveStreamResData => {
//             console.log("Dyte LIVE STREAM response DATA", liveStreamResData);

//             const renderData = { tokenData, roomId };
//             console.log("2) Dyte LIVE STREAM response DATA", { tokenData, roomId });
//             return renderData;
//         })
// }
