import { Document, Page, StyleSheet } from "@react-pdf/renderer";
import React from "react";
import ReactDOMServer from "react-dom/server";
import Html from "react-pdf-html";

const invoiceHtml = (data) => {
  // const { subTotal: amount, taxPercentage: taxPersentage, discountPercentage, discountValue } = data;

  // const tax = (taxPersentage / 100) * amount;
  // const taxWithTotal = (amount + tax).toFixed(2);

  // // For Discount
  // const discountAmount = discountValue || (discountPercentage / 100) * amount;
  // const subtotal = amount - discountAmount;
  // const taxAmount = (taxPersentage / 100) * subtotal;
  // const totalAmount = (subtotal + taxAmount).toFixed(2);

  // Changed calclation

  const {
    subtotalBeforeDiscount,
    subTotal,
    currencyCode,
    taxPercentage,
    discountPercentage,
    discountValue,
    total,
    taxValue,
  } = data;

  //console.log('invoiceHtml', data)
  return (
    <html>
      <body>
        <style>
          {`
            body {
              font-family: Helvetica;
            }
            span {
              display: block;
            }
            tr {
              borderBottom: 1px solid lightgray;
            }
            tr.last:last-child {
              borderBottomWidth: 1.4px;
            }
            .courses th, .courses td {
              padding: 10px 14px;
              font-size: 14px;
            }
            .amount tr {
              border: none;
            }
            .amount th, .amount td {
              padding: 2px 14px;
              font-size: 11px;
            }
           `}
        </style>
        <div style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <span style={{ fontSize: "25px", color: "#A9A9A9" }}>Invoice</span>
          <div style={{ backgroundColor: "#36454F", padding: "15px" }}>
            <img
              src="https://ik.imagekit.io/42vct06fb/web_site/Group_1_7bAeFZnvQ.png?ik-sdk-version=javascript-1.4.3&updatedAt=1674044447460"
              alt="rupee"
              style={{ width: "80px" }}
            />
          </div>
        </div>
        <table className="courses" style={{ width: "100%", marginTop: "20px" }}>
          <tr
            style={{
              backgroundColor: "#36454F",
              color: "#fff",
              borderBottom: "none",
            }}
          >
            <th style={{ minWidth: "75%" }}>Course Name</th>
            <th style={{ textAlign: "center" }}>Price</th>
          </tr>

          {data.items.map((item) => (
            <tr key={item._id}>
              <td style={{ minWidth: "75%" }}>
                {item.courseName || item.assessmentTitle}
              </td>
              <td
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src="https://static.thenounproject.com/png/591777-200.png"
                  style={{
                    width: "22px",
                    display: "block",
                    padding: 0,
                    margin: 0,
                  }}
                  alt="Rupee"
                />
                <span
                  style={{
                    display: "block",
                    padding: 0,
                    margin: 0,
                    marginLeft: "-5px",
                  }}
                >
                  {Math.ceil(parseFloat(item.fee)) + ".00"}
                </span>
              </td>
            </tr>
          ))}
        </table>
        {!discountPercentage && (
          <table
            className="amount"
            style={{ width: "100%", marginTop: "12px" }}
          >
            <tr>
              <td style={{ minWidth: "75%", textAlign: "left" }}>Subtotal</td>
              <td
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* {data.currencyCode === "INR" && <img src="https://static.thenounproject.com/png/591777-200.png" style={{ width: '19px', display: 'block', padding: 0, margin: 0 }} alt="Rupee" />}
                            {data.currencyCode === "USD" && "$"} */}
                <img
                  src="https://static.thenounproject.com/png/591777-200.png"
                  style={{
                    width: "19px",
                    display: "block",
                    padding: 0,
                    margin: 0,
                  }}
                  alt="Rupee"
                />
                <span
                  style={{
                    display: "block",
                    padding: 0,
                    margin: 0,
                    marginLeft: "-4px",
                  }}
                >
                  {Math.ceil(subTotal) + ".00"}
                </span>
              </td>
            </tr>

            <tr>
              <td style={{ minWidth: "75%", textAlign: "left" }}>
                Tax({taxPercentage}%)
              </td>
              <td
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src="https://static.thenounproject.com/png/591777-200.png"
                  style={{
                    width: "19px",
                    display: "block",
                    padding: 0,
                    margin: 0,
                  }}
                  alt="Rupee"
                />
                <span
                  style={{
                    display: "block",
                    padding: 0,
                    margin: 0,
                    marginLeft: "-4px",
                  }}
                >
                  {Math.ceil(taxValue) + ".00"}
                </span>
              </td>
            </tr>
            <tr>
              <td style={{ minWidth: "75%", textAlign: "left" }}>Total</td>
              <td
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src="https://static.thenounproject.com/png/591777-200.png"
                  style={{
                    width: "19px",
                    display: "block",
                    padding: 0,
                    margin: 0,
                  }}
                  alt="Rupee"
                />
                <span
                  style={{
                    display: "block",
                    padding: 0,
                    margin: 0,
                    marginLeft: "-4px",
                  }}
                >
                  {Math.ceil(total) + ".00"}
                </span>
              </td>
            </tr>
          </table>
        )}

        {discountPercentage && (
          <table
            className="amount"
            style={{ width: "100%", marginTop: "12px" }}
          >
            <tr>
              <td style={{ minWidth: "75%", textAlign: "left" }}>Subtotal</td>
              <td
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* {data.currencyCode === "INR" && <img src="https://static.thenounproject.com/png/591777-200.png" style={{ width: '19px', display: 'block', padding: 0, margin: 0 }} alt="Rupee" />}
                            {data.currencyCode === "USD" && "$"} */}
                <img
                  src="https://static.thenounproject.com/png/591777-200.png"
                  style={{
                    width: "19px",
                    display: "block",
                    padding: 0,
                    margin: 0,
                  }}
                  alt="Rupee"
                />
                <span
                  style={{
                    display: "block",
                    padding: 0,
                    margin: 0,
                    marginLeft: "-4px",
                  }}
                >
                  {Math.ceil(subtotalBeforeDiscount) + ".00"}
                </span>
              </td>
            </tr>

            <tr>
              <td style={{ minWidth: "75%", textAlign: "left" }}>
                Discount {data.discountPercentage}%
              </td>
              <td
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* {data.currencyCode === "INR" && <img src="https://static.thenounproject.com/png/591777-200.png" style={{ width: '19px', display: 'block', padding: 0, margin: 0 }} alt="Rupee" />}
                            {data.currencyCode === "USD" && "$"} */}
                -
                <img
                  src="https://static.thenounproject.com/png/591777-200.png"
                  style={{
                    width: "19px",
                    display: "block",
                    padding: 0,
                    margin: 0,
                  }}
                  alt="Rupee"
                />
                <span
                  style={{
                    display: "block",
                    padding: 0,
                    margin: 0,
                    marginLeft: "-4px",
                  }}
                >
                  {Math.ceil(discountValue) + ".00"}
                </span>
              </td>
            </tr>

            <tr>
              <td style={{ minWidth: "75%", textAlign: "left" }}>
                Subtotal after discount
              </td>
              <td
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* {data.currencyCode === "INR" && <img src="https://static.thenounproject.com/png/591777-200.png" style={{ width: '19px', display: 'block', padding: 0, margin: 0 }} alt="Rupee" />}
                            {data.currencyCode === "USD" && "$"} */}
                <img
                  src="https://static.thenounproject.com/png/591777-200.png"
                  style={{
                    width: "19px",
                    display: "block",
                    padding: 0,
                    margin: 0,
                  }}
                  alt="Rupee"
                />
                <span
                  style={{
                    display: "block",
                    padding: 0,
                    margin: 0,
                    marginLeft: "-4px",
                  }}
                >
                  {Math.ceil(subTotal) + ".00"}
                </span>
              </td>
            </tr>

            <tr>
              <td style={{ minWidth: "75%", textAlign: "left" }}>
                Tax({taxPercentage}%)
              </td>
              <td
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src="https://static.thenounproject.com/png/591777-200.png"
                  style={{
                    width: "19px",
                    display: "block",
                    padding: 0,
                    margin: 0,
                  }}
                  alt="Rupee"
                />
                <span
                  style={{
                    display: "block",
                    padding: 0,
                    margin: 0,
                    marginLeft: "-4px",
                  }}
                >
                  {Math.ceil(taxValue) + ".00"}
                </span>
              </td>
            </tr>

            <tr>
              <td style={{ minWidth: "75%", textAlign: "left" }}>Total</td>
              <td
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src="https://static.thenounproject.com/png/591777-200.png"
                  style={{
                    width: "19px",
                    display: "block",
                    padding: 0,
                    margin: 0,
                  }}
                  alt="Rupee"
                />
                <span
                  style={{
                    display: "block",
                    padding: 0,
                    margin: 0,
                    marginLeft: "-4px",
                  }}
                >
                  {Math.ceil(total) + ".00"}
                </span>
              </td>
            </tr>
          </table>
        )}
        <div
          style={{
            backgroundColor: "#36454F",
            padding: "1px",
            marginTop: "8px",
          }}
        />
        <span style={{ fontSize: "12px", color: "gray", paddingTop: "10px" }}>
          Notes, any relevant info, terms, payment instructions, e.t.c
        </span>
      </body>
    </html>
  );
};

const html = (data) => {
  console.log("html", data);
  return ReactDOMServer.renderToStaticMarkup(invoiceHtml(data));
};

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    padding: "30px",
  },
});

const InvoicePDF = ({ data }) => {
  console.log("InvoicePDF", data);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Html>{html(data)}</Html>
      </Page>
    </Document>
  );
};

export default InvoicePDF;
