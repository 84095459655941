import React from 'react'

function InstructorLoader() {
    return (
        <div className='text-center'>
            <span className={`spinner-border spinner-border-sm text-prime`} role="status" aria-hidden="true"></span>
            <span className="visually-hidden">Loading...</span>
        </div>
    )
}

export default InstructorLoader