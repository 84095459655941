
import { Link } from 'react-router-dom';
import React, { useRef, useState } from 'react';
import { Carousel, Modal } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { API_CONSTANTS, API_METHOD, STORAGE_KEY } from '../../common/constants';
import CreateNewPassword from './create-new-pass';
import ForgotPassword from './forgot-password';
import CreateNewPasswordSuccess from './new-password-success';
import VerifyOTP from './verify-otp';
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { API_CALL } from '../../api/ApiServiceCall';
import useCookie from '../../common/cookie_hook';
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai"
import LoginWithMobileOtp from './otpLogin/LoginWithMobileOtp';
import InstructorAppRedirectModal from '../../common/InstructorAppRedirectModal';
import '../../css/live-class.css';

// TODO: Add SDKs for Firebase products that you want to use
function LogIn() {
    const { register, handleSubmit, watch, reset, formState: { errors } } = useForm({ mode: "onChange" });
    const [loading, setLoading] = useState(false);
    const [EyeVisible, setEyeVisible] = useState("password");
    const [loadingGoogle, setLoadingGoogle] = useState(false);
    const [cookie, updateCookie] = useCookie(STORAGE_KEY.AUTH);
    const [error, setError] = useState();
    const [getEmailid, setEmailid] = useState(null)//getting typed email from first modal
    const [show, setShow] = useState(false);//modal
    const [selected, setSelected] = useState("forgot_pass");//showing next step modal
    console.log("selected",selected);
    const handleClose = () => {setShow(false); setSelected("forgot_pass")}//close modal
    const handleShow = () => setShow(true);//show modal
    const slideControl = useRef(null);//slider
    const navigate = useNavigate();

    const onPrevClick = () => {
        slideControl.current.prev();
    };
    const onNextClick = () => {
        slideControl.current.next();
    };
    // https://firebase.google.com/docs/web/setup#available-libraries

    // Your web app's Firebase configuration
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional

    // Initialize Firebase
    // const app = initializeApp(firebaseConfig);
    // const analytics = getAnalytics(app);
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
        prompt: 'select_account'
    });
    // signOut(auth).then(() => {
    //     // Sign-out successful.
    //   }).catch((error) => {
    //     // An error happened.
    //   });


    const googleLogin = () => {

        signInWithPopup(auth, provider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                // The signed-in user info.
                UserDataHandlingToServer(result, token);

            }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
                // ...
            });

    }
    const UserDataHandlingToServer = async (data, token) => {
        // console.log(JSON.stringify(data));
        setLoadingGoogle(true);
        let requestBodyParam = JSON.stringify({
            fullName: data.user.displayName,
            email: data.user.email,
            isGmail: true,
            userType: 'INSTRUCTOR'
        })
        const request = {
            apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.LOGIN_END_POINT,
            method: API_METHOD.POST,
            requestBodyParams: requestBodyParam
        }
        // let result = await API_CALL(request).catch(error => console.log("need to handle error in login fetch", error))
        let result = await API_CALL(request)
        if (result.status === 200) {
            updateCookie(token);
            const setGoogData = JSON.stringify({ ...result.data, data, userType: "INSTRUCTOR" })
            localStorage.setItem(STORAGE_KEY.LOCAL_USER_DATA, setGoogData);
            setLoadingGoogle(false);
            navigate("/transaction")
        } else if (result.status > 200) {
            setError(result.message);
            setLoadingGoogle(false);
        }
    }
    const onSubmit = async (data) => {
        setLoading(true);
        let requestBodyParam = JSON.stringify({
            email: data.email,
            password: data.password,
            userType: 'INSTRUCTOR'
        })
        const request = {
            apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.LOGIN_END_POINT,
            method: API_METHOD.POST,
            requestBodyParams: requestBodyParam
        }
        // let result = await API_CALL(request).catch(error => console.log("need to handle error in login fetch", error))
        let result = await API_CALL(request)
        if (result.status === 200) {
            let localUserData = JSON.stringify({ name: result.data.Name, email: result.data.Email, userType: result.data.UserType, jwt: result.data.JWT, userId: result.data.userId });
            updateCookie(result.data.JWT)
            localStorage.setItem(STORAGE_KEY.LOCAL_USER_DATA, localUserData)
            navigate("/transaction")
        } else if (result.status > 200) {
            setError(result.message)
        }

        setLoading(false);
    }

    return (

        <div>
            <InstructorAppRedirectModal />

            <div className="row p-0 m-0 bg-wall">
                <div className="login-col col-lg-6 vh-100 align-items-center d-flex">
                    <div className='container'>
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12  col-lg-9  ms-auto ">
                                <div className='welcm-bk'>
                                    <h1>Welcome Back</h1>
                                    <div className="login-card">
                                        <form onSubmit={handleSubmit(onSubmit)}>

                                            <label htmlFor='email'>Email Address<span className='text-danger'>*</span></label>
                                            <input className='mb-2' placeholder='Enter your Email ' type="email" id='email' {...register("email", {
                                                required: {
                                                    value: true,
                                                    message: "Please enter your email address",
                                                },
                                                pattern: {
                                                    value:
                                                        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                    message: "Invalid email address",
                                                },
                                            })} />
                                            <small className='text-danger mb-2 d-block fz-12px fw-500'> {errors.email?.message && (errors.email?.message)}</small>

                                            <label htmlFor='pasword'>Password<span className='text-danger'>*</span></label>
                                            <div className='position-relative'>
                                                <input placeholder='Enter your Password ' type={EyeVisible} id='pasword' className='mb-3'  {...register("password", { required: true })} />
                                                <div className='passfield'>{EyeVisible === "password" ? <span onClick={() => setEyeVisible('text')}><AiOutlineEyeInvisible /></span> : <span onClick={() => setEyeVisible('password')}><AiOutlineEye /></span>}</div>
                                            </div>
                                            <small className='text-danger mb-2 d-block fz-12px fw-500'> {errors.password?.type === 'required' && "Please Enter your Password"}</small>

                                            <label className='justify-content-end d-flex mb-2 cursor-pointer' role="button" onClick={handleShow}>Forgot Password?</label>
                                            <button className='login-form-btn' disabled={loading ? true : false}>{loading ? <><div className="spinner-border text-white spinner-border-sm" role="status">

                                            </div> <span className='fz-13px'>Please wait...</span></> : 'Log In'}</button>
                                            <small className='text-danger mb-2 d-block fz-15px fw-500 text-center'> {error}</small>
                                        </form>

                                        <div className="row g-3">
                                            <div className="col-12 col-xxl-6">
                                                <button className='glogin-form-btn border-0 text-nowrap' style={{ background: "#f3f3f3", marginBottom: 0 }} onClick={googleLogin} disabled={loadingGoogle ? true : false}>{loadingGoogle ? "Please wait..." : <>
                                                    <svg className='g-icon' viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M33.6783 14.4572L19.4021 14.4565C18.7717 14.4565 18.2607 14.9675 18.2607 15.5979V20.1585C18.2607 20.7887 18.7717 21.2998 19.4021 21.2998H27.4415C26.5612 23.5844 24.9181 25.4977 22.8218 26.7134L26.2498 32.6477C31.7488 29.4674 34.9998 23.8873 34.9998 17.6407C34.9998 16.7513 34.9343 16.1155 34.8032 15.3996C34.7035 14.8556 34.2313 14.4572 33.6783 14.4572Z" fill="#167EE6" />
                                                        <path d="M17.5002 28.1521C13.5659 28.1521 10.1312 26.0025 8.28654 22.8215L2.35254 26.2418C5.37231 31.4756 11.0293 35 17.5002 35C20.6746 35 23.6699 34.1453 26.2502 32.6558V32.6476L22.8222 26.7134C21.2542 27.6228 19.4396 28.1521 17.5002 28.1521Z" fill="#12B347" />
                                                        <path d="M26.25 32.6558V32.6477L22.822 26.7134C21.254 27.6228 19.4396 28.1521 17.5 28.1521V35C20.6744 35 23.6698 34.1453 26.25 32.6558Z" fill="#0F993E" />
                                                        <path d="M6.84783 17.5C6.84783 15.5608 7.37707 13.7464 8.28632 12.1785L2.35231 8.75818C0.854629 11.3303 0 14.3175 0 17.5C0 20.6826 0.854629 23.6698 2.35231 26.2419L8.28632 22.8216C7.37707 21.2537 6.84783 19.4393 6.84783 17.5Z" fill="#FFD500" />
                                                        <path d="M17.5002 6.84783C20.0658 6.84783 22.4224 7.75947 24.2632 9.27589C24.7173 9.64995 25.3773 9.62295 25.7932 9.20698L29.0246 5.97564C29.4965 5.50368 29.4629 4.73115 28.9588 4.29379C25.8747 1.61827 21.862 0 17.5002 0C11.0293 0 5.37231 3.5244 2.35254 8.75813L8.28654 12.1784C10.1312 8.99746 13.5659 6.84783 17.5002 6.84783Z" fill="#FF4B26" />
                                                        <path d="M24.2629 9.27589C24.717 9.64995 25.3771 9.62295 25.793 9.20698L29.0244 5.97564C29.4963 5.50368 29.4626 4.73115 28.9585 4.29379C25.8745 1.6182 21.8618 0 17.5 0V6.84783C20.0655 6.84783 22.4222 7.75947 24.2629 9.27589Z" fill="#D93F21" />
                                                    </svg>
                                                    Log in With Google</>}</button>
                                            </div>
                                            <div className="col-12 col-xxl-6">
                                                <LoginWithMobileOtp />
                                            </div>
                                        </div>
                                        <div className="">

                                            <p className='acc-cr-link'><span>Don’t Have An Account?</span>{" "}{" "}<Link to="/create-account">Create Account</Link></p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-6 ">

                    <div className="bg-login-r">
                        <div className="container h-100 align-items-end d-flex" >
                            <div className="login-r-container ">
                                <Carousel indicators={false} controls={false} ref={slideControl} interval={5000}>
                                    <Carousel.Item>
                                        <div>
                                            <p>
                                            "Learning languages with QLearning was incredible. The interactive labs and cultural content immersed me in the language and broadened my horizons."
                                            </p>
                                            <p className="author m-0">- Carlos Gomez</p>
                                            <small className='info'>Language Enthusiast</small>
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div>
                                            <p>
                                            "QLearning's graphic design courses exceeded my expectations. The practical assignments and collaboration tools allowed me to apply my skills authentically."
                                            </p>
                                            <p className="author m-0">- Maya Lee</p>
                                            <small className='info'>Aspiring Graphic Designer</small>
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div>
                                            <p>
                                            "QLearning's wellness courses have been transformative. The interactive yoga sessions and holistic guidance have kickstarted my journey to a healthier lifestyle."
                                            </p>
                                            <p className="author m-0">- Emily Davis </p>
                                            <small className='info'>Wellness Journey Starter</small>
                                        </div>

                                    </Carousel.Item>

                                </Carousel>
                                <div className='controll-arrw '>
                                    <div className='slide-prev-frm me-3' onClick={onPrevClick}>
                                        <svg viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.346537 7.76351L7.63821 0.471853C7.83467 0.282105 8.0978 0.177111 8.37092 0.179484C8.64404 0.181857 8.9053 0.291408 9.09844 0.484542C9.29157 0.677675 9.40112 0.938938 9.40349 1.21206C9.40587 1.48518 9.30087 1.74831 9.11113 1.94477L3.59758 7.45831L21.9163 7.4583C22.1926 7.4583 22.4576 7.56805 22.6529 7.7634C22.8483 7.95875 22.958 8.2237 22.958 8.49997C22.958 8.77624 22.8483 9.04119 22.6529 9.23654C22.4576 9.43189 22.1926 9.54164 21.9163 9.54164L3.59758 9.54164L9.11113 15.0552C9.21062 15.1513 9.28997 15.2662 9.34457 15.3933C9.39916 15.5204 9.42789 15.6571 9.4291 15.7954C9.4303 15.9337 9.40394 16.0709 9.35157 16.1989C9.29919 16.3269 9.22184 16.4432 9.12404 16.541C9.02623 16.6388 8.90993 16.7162 8.78191 16.7685C8.6539 16.8209 8.51673 16.8473 8.37842 16.8461C8.24011 16.8449 8.10342 16.8161 7.97633 16.7615C7.84924 16.7069 7.7343 16.6276 7.63821 16.5281L0.346537 9.23643C0.151255 9.04109 0.0415522 8.77618 0.0415522 8.49997C0.0415521 8.22376 0.151255 7.95886 0.346537 7.76351V7.76351Z" fill="#F0E1EB" />
                                        </svg>

                                    </div>
                                    <div className='slide-next-frm' onClick={onNextClick}>
                                        <svg viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M22.6535 9.23649L15.3618 16.5281C15.1653 16.7179 14.9022 16.8229 14.6291 16.8205C14.356 16.8181 14.0947 16.7086 13.9016 16.5155C13.7084 16.3223 13.5989 16.0611 13.5965 15.7879C13.5941 15.5148 13.6991 15.2517 13.8889 15.0552L19.4024 9.5417H1.08366C0.807392 9.5417 0.54244 9.43195 0.347089 9.2366C0.151739 9.04125 0.0419922 8.7763 0.0419922 8.50003C0.0419922 8.22376 0.151739 7.95881 0.347089 7.76346C0.54244 7.56811 0.807392 7.45836 1.08366 7.45836H19.4024L13.8889 1.94483C13.7894 1.84874 13.71 1.7338 13.6554 1.60671C13.6008 1.47962 13.5721 1.34293 13.5709 1.20462C13.5697 1.06631 13.5961 0.929144 13.6484 0.801126C13.7008 0.673111 13.7782 0.556807 13.876 0.459002C13.9738 0.361197 14.0901 0.283848 14.2181 0.231472C14.3461 0.179096 14.4833 0.15274 14.6216 0.153942C14.7599 0.155144 14.8966 0.183882 15.0237 0.238474C15.1508 0.293066 15.2657 0.372421 15.3618 0.47191L22.6535 7.76357C22.8487 7.95891 22.9584 8.22382 22.9584 8.50003C22.9584 8.77624 22.8487 9.04115 22.6535 9.23649Z" fill="#395061" />
                                        </svg>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            {/* ================modal============== */}

            <Modal show={show} onHide={handleClose} animation={false} centered backdrop={"static"} className='mh'>
                {selected == 'forgot_pass' ? (<ForgotPassword setEmailid={(id) => setEmailid(id)} setSelected={() => setSelected("verify_otp")} />) : null}
                {selected == 'verify_otp' ? <VerifyOTP getEmailid={getEmailid} setSelected={() => setSelected("create_password")} /> : null}
                {selected == 'create_password' ? <CreateNewPassword getEmailid={getEmailid} setSelected={() => setSelected("success")} /> : null}
                {selected == 'success' ? <CreateNewPasswordSuccess setSelected={() => setSelected("forgot_pass")} handleClose={handleClose} /> : null}
            </Modal>

        </div>

    )
}

export default LogIn