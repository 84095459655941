import React, { useEffect, useState } from "react";
import { Accordion, Card, useAccordionButton } from "react-bootstrap";
import { BiMessageDetail } from "react-icons/bi";
import { Link } from "react-router-dom";
import { API_CALL } from "../../api/ApiServiceCall";
import InstructorLoader from "../../common/InstructorLoader";
import Paginate from "../../common/Pagination";
import { API_CONSTANTS, API_METHOD } from "../../common/constants";
import "../../css/live-class.css";

function MyLearners() {
  const [studentList, setStudentList] = useState(null);
  const [studentListRestore, setStudentListRestore] = useState([]);
  const [courseName, setcourseName] = useState(
    JSON.parse(localStorage.getItem("currentUserCode"))
  );
  const [searchString, setSearchString] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [itemOffset, setItemOffset] = useState(0);
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(null);
  useEffect(() => {
    getAllStudentList();
  }, []);
  const getAllStudentList = async () => {
    const request = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.MY_LEARNERS,
      method: API_METHOD.GET,
    };
    let result = await API_CALL(request);
    if (result.status === 200 && result.data) {
      const sortedData = [...result.data].sort((a, b) =>
        (a.studentName || "").localeCompare(b.studentName || "")
      );
      setStudentList(sortedData);
      setStudentListRestore(sortedData);
    } else if (result.status > 200) {
      console.log("errror", result);
    }
  };
  const handleSearchChange = (searchData) => {
    setSearchString(searchData);
    const searchedName = studentList
      .filter((item) =>
        item.studentEmail
          .toString()
          .toLowerCase()
          .includes(searchData.toString().toLowerCase())
      )
      .map((filteredName) => filteredName);
    setStudentList(searchedName);
    if (searchData === "") {
      setStudentList(studentListRestore);
    }
  };
  // ----pagination start----------
  useEffect(() => {
    if (studentList) {
      // console.log(studentList)
      const endOffset = itemOffset + Number(itemsPerPage);
      let sliceItem = studentList.slice(itemOffset, endOffset);
      setCurrentItems(sliceItem);
      let pageCountResult = Math.ceil(studentList.length / itemsPerPage);
      setPageCount(pageCountResult);
    }
  }, [studentList, itemOffset, itemsPerPage]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % studentList.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };
  return (
    <div className="px-4 mx-2 liv-acc ">
      <div className="py-3"></div>

      <div className="d-flex justify-content-between align-items-center">
        <small className="fz-13px text-dark fw-500 m-0 p-0">
          {" "}
          {studentList?.length} LEARNERS
        </small>
        <div className="srch-bg ms-lg-3">
          <input
            className="srch-ui"
            type="search"
            placeholder="Search learners by email"
            value={searchString}
            onChange={(e) => handleSearchChange(e.target.value)}
          />
        </div>
      </div>
      {!currentItems ? (
        <InstructorLoader />
      ) : currentItems.length ? (
        currentItems?.map((studDetails, i) => (
          <div key={i}>
            {/* defaultActiveKey={0} */}
            <Accordion className="my-4 ">
              <Card className="border-0 p-3">
                <Card.Header className="border-0 bg-white ">
                  <CustomToggle studDetails={studDetails} eventKey={i + 1}>
                    <BiMessageDetail size={16} /> Messages
                  </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey={i + 1}>
                  <Card.Body>
                    <h6>All Details</h6>
                    <p>View Details Will Show here</p>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>
        ))
      ) : (
        <div className="d-flex align-items-center justify-content-center py-5 my-5">
          <h6 className="text-center fz-19px text-muted">
            No learners present
          </h6>
        </div>
      )}
      {currentItems?.length ? (
        <div className="row mx-1">
          <div className="col-lg-8">
            <Paginate
              onPageChange={handlePageClick}
              pageCount={pageCount}
              isShow={currentItems?.length}
            />
          </div>
          <div className="col-lg-4 fz-13px fw-500 text-muted text-end">
            Show{" "}
            <select
              className="px-2 py-1 "
              onChange={(e) => setItemsPerPage(e.target.value)}
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={75}>75</option>
              <option value={100}>100</option>
            </select>{" "}
            learners per page
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

function CustomToggle({ children, eventKey, studDetails }) {
  const [imgLink, setImgLink] = useState(
    "https://cdn-icons-png.flaticon.com/512/149/149071.png"
  );
  useEffect(() => {
    console.log("RENDERING USE EFFECT");
    if (studDetails?.studentImagePath) {
      setImgLink(studDetails.studentImagePath);
    } else {
      setImgLink("https://cdn-icons-png.flaticon.com/512/149/149071.png");
    }
  }, [studDetails]);

  const decoratedOnClick = useAccordionButton(eventKey, () =>
    console.log(studDetails)
  );

  const imageNotFound = () => {
    console.log("IMAGE NOT FOUND FUNCTION CALLED");
    setImgLink("https://cdn-icons-png.flaticon.com/512/149/149071.png");
  };

  return (
    <div className="row">
      <div className="col-lg-7">
        <div className="d-flex">
          <img
            src={imgLink}
            className="img"
            onError={imageNotFound}
          />
          <div className="ms-3">
            <p className="fw-500 fz-15px mb-2">
              Name: {studDetails?.studentName}
            </p>
            <p className="fw-500 text-prime fz-14px mb-2">
              Email: {studDetails?.studentEmail}
            </p>
            <div className="d-flex align-items-center">
              <p className="fz-14px mb-2 fw-500 text-prime">
                Course:
                {studDetails?.orders.map((order) => (
                  <p key={order.courseId}>{order.courseName}</p>
                ))}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg m-auto">
        <div className="d-flex w-100 justify-content-end">
          <Link to={`/messages?id=${studDetails.studentId}`}>
            <button
              className="border-0 bg-prime text-white px-4 fz-14px py-2 rounded-3"
              type="button"
              onClick={decoratedOnClick}
            >
              {children}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}


export default MyLearners;
