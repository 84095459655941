import "bootstrap/dist/css/bootstrap.min.css";
import Peer from "peerjs";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ScrollToBottom from "react-scroll-to-bottom";
import { RecordRTCPromisesHandler } from "recordrtc";
import { io } from "socket.io-client";
import "../css/live-class.css";
import { useIsLoggedIn } from "./../hooks/useIsLoggedIn";
import "./offcanvas.css";
const { REACT_APP_SOCKETLINK, REACT_APP_SOCKETHOST } = process.env;
console.log("2) process.env REACT_APP_SOCKETLINK", REACT_APP_SOCKETLINK);
console.log("2) process.env REACT_APP_SOCKETHOST", REACT_APP_SOCKETHOST);

const socket = io(REACT_APP_SOCKETLINK, {
  autoConnect: false,
});

/// CHAT
function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}

function DemoClass({ tokenData }) {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [videoControls, setVideoControls] = useState(false);
  const [chatMessages, setChatMessages] = useState([]);
  const [isClassEnded, setIsClassEnded] = useState(false);
  const [instructorStarted, setInstructorStarted] = useState(false);
  const { user: userData } = useIsLoggedIn();
  const { roomId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const userIdFromURL = searchParams.get("userId");

  //console.log(userData.name);

  // ****************************************************
  const instructorVideoRef = useRef();

  let videoGrid, myVideo, muteButton, stopVideo;
  var recorder;
  var recordernormal;
  var frames = [];
  var peer;
  let myVideoStream;
  // uzxabcdefght
  let ROOM_ID = roomId;
  let user = userData?.name ? userData?.name : tokenData?.studentName;
  let isHost = false; //?
  useEffect(() => {
    // myVideo = document.createElement("video");
    // myVideo.setAttribute('student', true);
    // myVideo.muted = true;
    getMedia();
  }, []);

  const getMedia = () => {
    navigator.mediaDevices
      .getUserMedia({
        audio: true,
        video: true,
      })
      .then((stream) => {
        // stream
        myVideoStream = null;

        peer = new Peer({
          path: "/peerjs",
          host: REACT_APP_SOCKETHOST,
          port: "", //443
        });

        peer.on("open", (id) => {
          console.log("join-room demo class getMedia", id);
          console.log(userData);
          socket.emit("join-room", {
            roomName: ROOM_ID,
            peerId: id,
            userName: user,
            userId: tokenData?.studentId,
          });
        });

        peer.on("call", (call) => {
          console.log("peer call func demo class student", call);
          setInstructorStarted(true);
          call.answer(null);
          const video = document.createElement("video");
          call.on("stream", (userVideoStream) => {
            instructorVideoRef.current.srcObject = userVideoStream;
            console.log("✅✅✅userVideoStream✅✅✅:", userVideoStream);
            instructorVideoRef.current.play();
          });
        });

        // stream
        socket.on("user-connected", (userId) => {
          connectToNewUser(userId, null);
        });
        socket.on("user-disconnected", (userId) => {
          if (peer[userId]) {
            peer[userId].close();
          }
        });
        // stream
        // addVideoStream(myVideo, null);
      });
  };

  const addVideoStream = (video, stream) => {
    console.log("video stream...");
    video.srcObject = stream;
    video.addEventListener("loadedmetadata", () => {
      video.play();

      // Do not append student video to dom
      if (!video.hasAttribute("student")) {
        videoGrid.append(video);
      }

      if (isHost) {
        recorder = new RecordRTCPromisesHandler(stream, {
          videoBitsPerSecond: 2500000,
          mimeType: "video/webm",
        });

        // Start recording the video
        recorder
          .startRecording()
          .then(function () {
            console.info("Recording video ...");
          })
          .catch(function (error) {
            console.error("Cannot start video recording: ", error);
          });

        // release stream on stopRecording
        recorder.stream = stream;
      }
    });
  };
  const connectToNewUser = (userId, stream) => {
    const call = peer.call(userId, stream);
    const video = document.createElement("video");
    call.on("stream", (userVideoStream) => {
      console.log("call.on", userId);
      addVideoStream(video, userVideoStream);
    });
  };

  // ****************************************************

  // CHAT

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatMessages]);

  const receiveMessages = useCallback((response) => {
    setChatMessages((chatMessages) => [
      ...chatMessages,
      {
        user: response.userName,
        time: new Date(response.createdAt).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        }),
        message: response.message,
      },
    ]);
  }, []);

  const sendMessage = (e) => {
    e.preventDefault();

    if (!message) return null;

    setChatMessages((chatMessages) => [
      ...chatMessages,
      {
        user: user,
        time: formatAMPM(new Date()),
        message: message,
      },
    ]);

    setMessage("");

    socket.emit("sendMessage", { message }, () => {});
  };

  const handleUserJoin = (response) => {
    setChatMessages((chatMessages) => [
      ...chatMessages,
      {
        user: "",
        time: new Date(response.createdAt).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        }),
        message: response.userName,
      },
    ]);
  };

  const handleClassEnd = () => {
    // alert('Class Ended.');
    // console.log('Class ended');
    setIsClassEnded(true);
  };

  const handleReload = () => {
    setTimeout(() => {
      // navigate(location.pathname + '?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb3Vyc2VDb2RlIjoiMTE2ZTEzMWMtMWFlMi00NGMzLTliZDAtZDE0MzNkZGQ5NDU2Iiwic3R1ZGVudEVtYWlsIjoic2lwaWxlbDM0MkBxdWFtb3guY29tIiwic3R1ZGVudE5hbWUiOiJTYWkgTWFuaWthbnRhIEJhbmRhcnUiLCJzdHVkZW50SWQiOiI2M2NlNmViZTE5NzhlNDZjMGQ1ZGNjYTUiLCJkZW1vRGF0ZSI6IjIwMjMtMDUtMTZUMDA6MDA6MDAuMDAwWiIsImRlbW9UaW1lIjoiMTE6MzMiLCJkZW1vQ29kZSI6IjY0NjMxYzBlYmI2YWQ4MjI4MTA3NjA4YSIsInRvcGljTmFtZSI6IlBoYXJtYSIsInVzZXJUeXBlIjoiU1RVREVOVCIsImlhdCI6MTY4NDIxODEwMX0.jmWy4oPqdBtxHMUv5YsY2dwOMh6tLyhkMzTx0dF1pdk'); // navigate to the same route
      // history.push('/');
      window.location.reload();
      // getMedia();
      // reloadTest();
    }, 3000);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (instructorStarted) {
        clearInterval(intervalId);
      } else {
        window.location.reload();
      }
    }, 10000);

    return () => clearInterval(intervalId);
  }, [instructorStarted]);

  useEffect(() => {
    socket.open();
    socket.on("connection-success", async (response) => {
      console.log(`Socket connected ${response.socketId}`);
    });
    socket.on("message", receiveMessages);
    socket.on("userJoin", handleUserJoin);
    socket.on("end", handleClassEnd);
    socket.on("reload", handleReload);
    // sendMessage

    return () => {
      socket.off("message", receiveMessages);
      socket.off("userJoin", handleUserJoin);
      socket.off("end", handleClassEnd);
      socket.off("reload", handleReload);
      socket.disconnect();
      socket.on("disconnect", function () {});
    };
  }, []);

  if (isClassEnded) {
    return (
      <div className="student-conatiner py-5">
        <button className="btn" onClick={() => navigate(-1)}>
          <h2 className="fs-5 mb-0 heading-icon-container">
            <span className="display-none-sm">
              <svg
                width="11"
                height="23"
                viewBox="0 0 16 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.6854 1.7675L3.3366 14.1162C3.10849 14.3539 2.98111 14.6706 2.98111 15C2.98111 15.3294 3.10849 15.6461 3.3366 15.8837L15.6779 28.225L13.9104 29.9925L1.5691 17.6512C0.887258 16.9367 0.506835 15.987 0.506835 14.9994C0.506835 14.0117 0.887258 13.062 1.5691 12.3475L13.9179 -5.86214e-07L15.6854 1.7675Z"
                  fill="black"
                />
              </svg>
            </span>
            <span className="ms-3">
              Live Class {tokenData.liveClassOrder}: {tokenData.topicName}
            </span>
          </h2>
        </button>

        <div
          className="mt-4 p-4 rounded-3"
          style={{
            backgroundColor: "#f2f3f4",
            height: "500px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p style={{ textAlign: "center", fontSize: "1.2rem", color: "gray" }}>
            Class Ended by Instructor.
          </p>
        </div>
      </div>
    );
  }

  if (!instructorStarted) {
    return (
      <div className="student-conatiner py-5">
        <button className="btn" onClick={() => navigate(-1)}>
          <h2 className="fs-5 mb-0 heading-icon-container">
            <span className="display-none-sm">
              <svg
                width="11"
                height="23"
                viewBox="0 0 16 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.6854 1.7675L3.3366 14.1162C3.10849 14.3539 2.98111 14.6706 2.98111 15C2.98111 15.3294 3.10849 15.6461 3.3366 15.8837L15.6779 28.225L13.9104 29.9925L1.5691 17.6512C0.887258 16.9367 0.506835 15.987 0.506835 14.9994C0.506835 14.0117 0.887258 13.062 1.5691 12.3475L13.9179 -5.86214e-07L15.6854 1.7675Z"
                  fill="black"
                />
              </svg>
            </span>
            <span className="ms-3">
              Live Class {tokenData.liveClassOrder}: {tokenData.topicName}
            </span>
          </h2>
        </button>
        <div
          className="mt-4 p-4 rounded-3 d-flex justify-content-center align-items-center"
          style={{ backgroundColor: "#f2f3f4", height: "550px" }}
        >
          <span style={{ fontSize: "1.2rem", textAlign: "center" }}>
            Teacher has to start class. Please wait...
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className="student-conatiner py-5">
      <button className="btn" onClick={() => navigate(-1)}>
        {/* d-flex align-items-center  */}
        <h2 className="fs-5 mb-0 heading-icon-container">
          <span className="display-none-sm">
            <svg
              width="11"
              height="23"
              viewBox="0 0 16 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.6854 1.7675L3.3366 14.1162C3.10849 14.3539 2.98111 14.6706 2.98111 15C2.98111 15.3294 3.10849 15.6461 3.3366 15.8837L15.6779 28.225L13.9104 29.9925L1.5691 17.6512C0.887258 16.9367 0.506835 15.987 0.506835 14.9994C0.506835 14.0117 0.887258 13.062 1.5691 12.3475L13.9179 -5.86214e-07L15.6854 1.7675Z"
                fill="black"
              />
            </svg>
          </span>
          <span className="ms-3">
            Live Class {tokenData.liveClassOrder}: {tokenData.topicName}
          </span>
        </h2>
      </button>
      <div
        className="mt-4 p-4 rounded-3"
        style={{ backgroundColor: "#f2f3f4" }}
      >
        <div
          className="w-100 rounded-4 offcanvas-parent"
          style={{ position: "relative" }}
        >
          <div className="position-relative">
            <video
              id="student"
              ref={instructorVideoRef}
              style={{ width: "100%" }}
              controls={videoControls}
              autoPlay
            ></video>
            {show && (
              <div
                className="position-absolute bg-white p-4 d-flex flex-column display-none-sm"
                style={{ top: 0, bottom: 0, right: 0, width: "340px" }}
              >
                <div className="d-flex justify-content-between align-items-center mb-1">
                  <h3 className="fs-5 mb-0">Chat</h3>
                  <button className="btn" onClick={() => setShow(false)}>
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M8.23584 10L0.365968 2.13016C-0.121989 1.6422 -0.121989 0.853924 0.365968 0.365968C0.853924 -0.121989 1.64217 -0.121989 2.13012 0.365968L10 8.23588L17.8699 0.365968C18.3578 -0.121989 19.1461 -0.121989 19.634 0.365968C20.122 0.853924 20.122 1.6422 19.634 2.13016L11.7642 10L19.634 17.8698C20.122 18.3578 20.122 19.1461 19.634 19.634C19.1461 20.122 18.3578 20.122 17.8699 19.634L10 11.7641L2.13012 19.634C1.64217 20.122 0.853924 20.122 0.365968 19.634C-0.121989 19.1461 -0.121989 18.3578 0.365968 17.8698L8.23584 10Z"
                        fill="#395061"
                      />
                    </svg>
                  </button>
                </div>
                <div className="flex-grow-1" style={{ overflowY: "auto" }}>
                  <ScrollToBottom
                    className="chat-scroller"
                    scrollViewClassName="chat-scroll-view"
                  >
                    {chatMessages.map((message) => (
                      <div key={message._id}>
                        {message.user && (
                          <div
                            style={{ fontSize: "0.9rem" }}
                            className="d-flex align-items-center gap-2 mb-1"
                          >
                            <span className="fw-semibold">{message.user}</span>
                            <span
                              className="text-muted"
                              style={{ fontSize: "0.8rem" }}
                            >
                              {message.time}
                            </span>
                          </div>
                        )}
                        <p style={{ fontSize: "0.8rem" }}>
                          {message.message}
                          {!message.user && (
                            <span
                              className="text-muted"
                              style={{ fontSize: "0.8rem" }}
                            >
                              {" "}
                              {message.time}
                            </span>
                          )}
                        </p>
                      </div>
                    ))}
                  </ScrollToBottom>
                </div>
                <div className="mt-1">
                  <form
                    onSubmit={sendMessage}
                    className="d-flex rounded-pill overflow-hidden bg-gray p-1"
                    style={{ diplay: "block", width: "100%" }}
                  >
                    <input
                      type="text"
                      className="flex-grow-1 border-0 bg-transparent ps-3"
                      placeholder="Write Message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                    <button className="btn d-flex justify-content-center align-items-center">
                      <svg
                        width="17"
                        height="17"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.6722 7.99999C15.6722 7.45249 15.319 6.96499 14.7047 6.65749L2.38971 0.499992C2.08971 0.349993 1.79721 0.274993 1.52721 0.274993C1.13646 0.274993 0.792214 0.439992 0.573964 0.731743C0.387214 0.987493 0.207214 1.42999 0.424714 2.14999L1.81221 6.77749C1.85721 6.91249 1.88721 7.07674 1.90221 7.24999H9.49971C9.91221 7.24999 10.2497 7.58749 10.2497 7.99999C10.2497 8.41249 9.91221 8.74999 9.49971 8.74999H1.90221C1.88646 8.92249 1.85646 9.08749 1.81221 9.22249L0.424714 13.85C0.207214 14.57 0.387214 15.0125 0.574714 15.2675C0.942214 15.7625 1.63971 15.875 2.38971 15.5L14.7055 9.34249C15.3197 9.03499 15.6722 8.54749 15.6722 7.99999Z"
                          fill="#395061"
                        />
                      </svg>
                    </button>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
        <button
          onClick={() => setShow((show) => !show)}
          className="btn mt-4 d-block ms-auto"
        >
          <svg
            width="32"
            height="30"
            viewBox="0 0 38 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M34 0.5H4C1.9325 0.5 0.25 2.1825 0.25 4.25V24.25C0.25 26.3175 1.9325 28 4 28H7.75V34.25C7.75 34.7313 8.02625 35.1675 8.4575 35.3762C8.63125 35.4587 8.81625 35.5 9 35.5C9.27875 35.5 9.555 35.4075 9.78125 35.2262L18.8137 28H34C36.0675 28 37.75 26.3175 37.75 24.25V4.25C37.75 2.1825 36.0675 0.5 34 0.5ZM19 18H9C8.30875 18 7.75 17.44 7.75 16.75C7.75 16.06 8.30875 15.5 9 15.5H19C19.6912 15.5 20.25 16.06 20.25 16.75C20.25 17.44 19.6912 18 19 18ZM29 13H9C8.30875 13 7.75 12.44 7.75 11.75C7.75 11.06 8.30875 10.5 9 10.5H29C29.6912 10.5 30.25 11.06 30.25 11.75C30.25 12.44 29.6912 13 29 13Z"
              fill="#395061"
            />
          </svg>
        </button>
      </div>

      {show && (
        <div
          className="position-absolute bg-white p-4 display-none-lg"
          style={{
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            height: "100vh",
            display: "flex",
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <div
              className="mb-1"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3 className="fs-5 mb-0">Chat</h3>
              <button className="btn" onClick={() => setShow(false)}>
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.23584 10L0.365968 2.13016C-0.121989 1.6422 -0.121989 0.853924 0.365968 0.365968C0.853924 -0.121989 1.64217 -0.121989 2.13012 0.365968L10 8.23588L17.8699 0.365968C18.3578 -0.121989 19.1461 -0.121989 19.634 0.365968C20.122 0.853924 20.122 1.6422 19.634 2.13016L11.7642 10L19.634 17.8698C20.122 18.3578 20.122 19.1461 19.634 19.634C19.1461 20.122 18.3578 20.122 17.8699 19.634L10 11.7641L2.13012 19.634C1.64217 20.122 0.853924 20.122 0.365968 19.634C-0.121989 19.1461 -0.121989 18.3578 0.365968 17.8698L8.23584 10Z"
                    fill="#395061"
                  />
                </svg>
              </button>
            </div>

            <div style={{ overflowY: "auto", flexGrow: 1 }}>
              <ScrollToBottom
                className="chat-scroller"
                scrollViewClassName="chat-scroll-view"
              >
                {chatMessages.map((message) => (
                  <div key={message._id}>
                    {message.user && (
                      // d-flex align-items-center gap-2
                      <div style={{ fontSize: "0.9rem" }} className="mb-1">
                        <span className="fw-semibold me-2">{message.user}</span>
                        <span
                          className="text-muted"
                          style={{ fontSize: "0.8rem" }}
                        >
                          {message.time}
                        </span>
                      </div>
                    )}
                    <p style={{ fontSize: "0.8rem" }}>
                      {message.message}
                      {!message.user && (
                        <span
                          className="text-muted"
                          style={{ fontSize: "0.8rem" }}
                        >
                          {" "}
                          {message.time}
                        </span>
                      )}
                    </p>
                  </div>
                ))}
              </ScrollToBottom>
            </div>

            <div className="mt-1">
              <form
                onSubmit={sendMessage}
                className="rounded-pill overflow-hidden bg-gray p-1"
                style={{ display: "flex" }}
              >
                <input
                  type="text"
                  className="flex-grow-1 border-0 bg-transparent ps-3"
                  placeholder="Write Message..."
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <button className="btn d-flex justify-content-center align-items-center">
                  <svg
                    width="17"
                    height="17"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.6722 7.99999C15.6722 7.45249 15.319 6.96499 14.7047 6.65749L2.38971 0.499992C2.08971 0.349993 1.79721 0.274993 1.52721 0.274993C1.13646 0.274993 0.792214 0.439992 0.573964 0.731743C0.387214 0.987493 0.207214 1.42999 0.424714 2.14999L1.81221 6.77749C1.85721 6.91249 1.88721 7.07674 1.90221 7.24999H9.49971C9.91221 7.24999 10.2497 7.58749 10.2497 7.99999C10.2497 8.41249 9.91221 8.74999 9.49971 8.74999H1.90221C1.88646 8.92249 1.85646 9.08749 1.81221 9.22249L0.424714 13.85C0.207214 14.57 0.387214 15.0125 0.574714 15.2675C0.942214 15.7625 1.63971 15.875 2.38971 15.5L14.7055 9.34249C15.3197 9.03499 15.6722 8.54749 15.6722 7.99999Z"
                      fill="#395061"
                    />
                  </svg>
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default DemoClass;
