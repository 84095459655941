import { useEffect, useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import swal from "sweetalert";
import { API_CALL } from "../../api/ApiServiceCall";
import { API_CONSTANTS, API_METHOD, STORAGE_KEY } from "../../common/constants";
import useCookie from "../../common/cookie_hook";
import GetAppStoreIcon from "../../components/svg-icons/GetAppStoreIcon";
import GetPlayStoreIcon from "../../components/svg-icons/GetPlayStoreIcon";
import QlearningLogo from "../../components/svg-icons/mainLogo";
import { cartState } from "../atoms/cart-atom";
import { selectedCategoryCodeState } from "../atoms/course-list-atom";
import { notificationsState } from "../atoms/notificationsAtom";
import { searchState } from "../atoms/search-atom";
import { wishListState } from "../atoms/wishlist-atom";
import { useKeyPress } from "../hooks/useKeyPress";
import CredentialProfileImageFormenu from "../profile/profile-image-upload/credentialProfileImage";

const ListItem = ({
  item,
  active,
  setSelected,
  setHovered,
  handleSearchResultClick,
}) => (
  <button
    type="button"
    onClick={() => {
      setSelected(item);
      handleSearchResultClick(item.courseCode);
    }}
    // ${items.data.length !== index + 1 && 'border-bottom'}
    className={`${
      active ? "active" : ""
    } search-result my-0 rounded-0 btn py-2 text-start`}
    style={{
      fontSize: "0.8rem",
      cursor: "pointer",
      paddingLeft: "40px",
      border: active ? "1px solid #395061" : "1px solid #fff",
    }}
    onMouseEnter={() => setHovered(item)}
    onMouseLeave={() => setHovered(undefined)}
  >
    <span style={{ whiteSpace: "nowrap" }}>{item.courseName} </span>
    <span style={{ fontSize: "0.750rem", color: "gray", whiteSpace: "nowrap" }}>
      - By {item.instructorName}
    </span>
  </button>
);

function StudentMenu() {
  const [courses, setCourses] = useState({
    loading: false,
    error: null,
    data: [],
  });

  const [categories, setCategories] = useState({
    loading: false,
    error: null,
    data: [],
  });

  const [selected, setSelected] = useState(undefined);
  const downPress = useKeyPress("ArrowDown");
  const upPress = useKeyPress("ArrowUp");
  const enterPress = useKeyPress("Enter");
  const [cursor, setCursor] = useState(null);
  const [hovered, setHovered] = useState(undefined);
  const [search, setSearch] = useRecoilState(searchState);
  const setSelectedCategoryCode = useSetRecoilState(selectedCategoryCodeState);
  const navigate = useNavigate();
  const [cookie, updateCookie] = useCookie(STORAGE_KEY.AUTH);
  let location = useLocation();
  const localUser = localStorage.getItem(STORAGE_KEY.LOCAL_USER_DATA);
  const isAuthenticated = cookie && localUser;

  const userType = JSON.parse(localUser) ? JSON.parse(localUser).userType : "";

  const logoutFromDB = async () => {
    const requestBodyParam = JSON.stringify({});
    const request = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.LOGOUT_STUDENT,
      method: API_METHOD.POST,
      requestBodyParams: requestBodyParam,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      localStorage.clear();
      updateCookie(null);
      navigate("/student-login");
    } else if (result.status > 200) {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }
  };

  const customSignOut = () => {
    swal({
      title: "Are you sure you want To Log out?",
      text: "",
      icon: "warning",
      imageClass: "img-warning",
      buttons: true,
      buttons: ["No", "Yes"],
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        logoutFromDB();
      }
    });
  };

  const handleSubmit = (selected) => {
    setSearch({
      typingstring: "",
      typedString: selected
        ? selected.courseName
          ? selected.courseName
          : search.typingstring
        : search.typingstring,
    });

    setSelected(undefined);
    setCourses({ loading: false, error: null, data: [] });
    setCursor(null);
    navigate("/search");
  };

  const getCourses = async (searchString) => {
    if (searchString.length < 2) {
      return setCourses({ loading: false, data: [], error: null });
    }

    setCourses({ loading: true, data: [], error: null });

    const request = {
      apiUrl:
        API_CONSTANTS.BASE_URL + API_CONSTANTS.SEARCH_COURSES(searchString),
      method: API_METHOD.GET,
    };

    let result = await API_CALL(request);

    if (result.status === 200) {
      setCourses({ loading: false, data: result.data, error: null });
    } else if (result.status > 200) {
      setCourses({ loading: false, data: [], error: result.message });

      if (result.message == "Token is Not Valid") return null;

      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }
  };

  const handleSearchType = (e) => {
    setSearch({ ...search, typingstring: e.target.value });

    getCourses(e.target.value);
  };

  const handleSearchResultClick = (courseCode) => {
    setSearch({ ...search, typingstring: "" });
    setCourses({ loading: false, error: null, data: [] });
    navigate(`/course/${courseCode}`);
  };

  const getCategories = async () => {
    setCategories({ loading: true, courses: [], error: null });

    const request = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.GET_ALL_CATEGORY,
      method: API_METHOD.GET,
    };

    let result = await API_CALL(request);

    if (result.status === 200) {
      const data = result.data;
      if (data.length) {
        data[0].active = true;
      }
      setCategories({ loading: false, data: data, error: null });
    } else if (result.status > 200) {
      setCategories({ loading: false, data: [], error: result.message });

      if (result.message == "Token is Not Valid") return null;

      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }
  };

  const handleCategorySelect = (categoryCode) => {
    setSelectedCategoryCode(categoryCode);
    navigate("/student-courses");
  };

  useEffect(() => {
    setSearch({ ...search, typingstring: "" });
    setCourses({ loading: false, data: [], error: "" });
  }, [location.pathname]);

  useEffect(() => {
    if (courses.data.length && enterPress) {
      // console.log('RAN')
      // console.log(selected ? courses.data[0] : selected);
      // if (selected) {
      handleSubmit(selected);
      // }
      // handleSubmit(selected)
    }
  }, [enterPress]);

  useEffect(() => {
    if (courses.data.length && downPress) {
      setCursor((prevState) => {
        if (prevState === null) return 0;
        return prevState < courses.data.length - 1 ? prevState + 1 : prevState;
      });
    }
  }, [downPress]);

  useEffect(() => {
    if (courses.data.length && upPress) {
      setCursor((prevState) => (prevState > 0 ? prevState - 1 : prevState));
    }
  }, [upPress]);

  useEffect(() => {
    if (courses.data.length && downPress) {
      setSelected(courses.data[cursor]);
    }
  }, [cursor, downPress]);

  useEffect(() => {
    if (courses.data.length && upPress) {
      setSelected(courses.data[cursor]);
    }
  }, [cursor, upPress]);

  useEffect(() => {
    if (courses.data.length && hovered) {
      setCursor(courses.data.indexOf(hovered));
    }
  }, [hovered]);

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <Navbar className="nav-bg fixed-top py-1 " expand="xl">
      <div className="container-fluid">
        <Link to="/student-home">
          <QlearningLogo />
        </Link>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="me-auto my-2 my-lg-0" navbarScroll>
            <div className="d-flex">
              <div className="me-lg-0 tag">
                {/* handleCategorySelect */}
                <select
                  onChange={(e) => handleCategorySelect(e.target.value)}
                  style={{ width: "120px", backgroundPosition: "98px 16px" }}
                >
                  <option value="">Category</option>
                  {categories?.data?.map((category) => (
                    <option key={category._id} value={category.categoryCode}>
                      {category.categoryName}
                    </option>
                  ))}
                  {/* <option value="">Cooking</option>
                                    <option value="">Education</option>
                                    <option value="">Programming</option>
                                    <option value="">More</option> */}
                </select>
              </div>
              <div className="srch-bg ms-lg-3 position-relative">
                <input
                  className="srch-ui"
                  type="search"
                  placeholder="Search Course"
                  value={search.typingstring}
                  onChange={handleSearchType}
                  // onBlur={() => setSearch({ ...search, typingstring: "" })}
                />
                {courses.data.length > 0 && (
                  <div
                    className="position-absolute shadow-sm bg-white d-flex flex-column overflow-auto"
                    style={{
                      top: "40px",
                      maxHeight: "calc(100vh - 200px)",
                      minWidth: "350px",
                    }}
                  >
                    {courses.data.map((item, i) => (
                      <ListItem
                        key={item.id}
                        active={i === cursor}
                        item={item}
                        setSelected={setSelected}
                        setHovered={setHovered}
                        handleSearchResultClick={handleSearchResultClick}
                      />
                      // <button
                      //     type="button"
                      //     onClick={() => handleSearchResultClick(course.courseCode)}
                      //     className={`search-result my-0 rounded-0 btn py-2 text-start ${courses.data.length !== index + 1 && 'border-bottom'}`}
                      //     style={{ fontSize: '0.8rem', cursor: 'pointer', paddingLeft: '40px' }}
                      // >
                      //     <span style={{ whiteSpace: 'nowrap' }}>{course.courseName} </span>
                      //     <span style={{ fontSize: '0.750rem', color: 'gray', whiteSpace: 'nowrap' }}>
                      //         - By {course.instructorName}
                      //     </span>
                      // </button>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </Nav>
          <Nav.Item className="ml-lg-auto">
            <a href="#" className="menu-font-style me-lg-2">
              {" "}
              <GetPlayStoreIcon height={"35"} width={"100"} />
            </a>
          </Nav.Item>
          <Nav.Item className="ml-lg-auto">
            <a href="#" className="menu-font-style me-lg-3">
              {" "}
              <GetAppStoreIcon height={"36"} width={"100"} />
            </a>
          </Nav.Item>
          {userType !== "INSTRUCTOR" && (
            <Nav.Item className="ml-lg-auto my-3">
              <Link to="/login" className="menu-font-style me-lg-4">
                Become A Teacher
              </Link>
            </Nav.Item>
          )}
          <Nav.Item className="ml-lg-auto my-3">
            {isAuthenticated && (
              <Link className="menu-font-style me-lg-4" to="/student-dashboard">
                Dashboard
              </Link>
            )}
          </Nav.Item>

          {userType !== "INSTRUCTOR" && (
            <Nav.Item className="ml-lg-auto my-3">
              {localUser && localStorage ? (
                <Link to="/profile">{<CredentialProfileImageFormenu />}</Link>
              ) : (
                ""
              )}
            </Nav.Item>
          )}

          {isAuthenticated && userType === "STUDENT" && <CartAndWishList />}
          <Nav.Item className="ml-lg-auto">
            {isAuthenticated ? (
              <button className="login-btn" onClick={customSignOut}>
                Log Out
              </button>
            ) : (
              <Link to="/student-login">
                <button className="login-btn">Log In</button>
              </Link>
            )}
          </Nav.Item>
          <Nav.Item className="ml-lg-auto">
            {!isAuthenticated && (
              <Link to="/create-student-account">
                <button className="acc-btn ms-lg-3">Create Account</button>
              </Link>
            )}
          </Nav.Item>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
}

function CartAndWishList() {
  const [cart, setCart] = useRecoilState(cartState);
  const [wishList, setWishList] = useRecoilState(wishListState);
  const [notifications, setNotifications] = useRecoilState(notificationsState);

  const getData = async () => {
    try {
      const [cartResult, wishListResult, notificationsResult] =
        await Promise.all([
          API_CALL({
            apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.CART,
            method: API_METHOD.GET,
          }),
          API_CALL({
            apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.WISH_LIST,
            method: API_METHOD.GET,
          }),
          API_CALL({
            apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.GET_NOTIFICATIONS,
            method: API_METHOD.GET,
          }),
        ]);

      if (cartResult.status === 200) {
        setCart({ ...cartResult.data });
      } else if (cartResult.status > 200) {
        console.error("Error fetching cart data:", cartResult.message);
      }

      if (wishListResult.status === 200) {
        setWishList(wishListResult.data ? wishListResult.data : []);
      } else if (wishListResult.status > 200) {
        console.error("Error fetching wish list:", wishListResult.message);
      }

      if (notificationsResult.status === 200) {
        setNotifications(notificationsResult.data);
      } else if (notificationsResult.status > 200) {
        console.error(
          "Error fetching notifications:",
          notificationsResult.message
        );
      }
    } catch (error) {
      console.error("Unexpected error:", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  // const [getNotificationsAtom, setNotificationsAtom] = useRecoilState(notificationsDataAtomState);
  // const getAllNotifications = async () => {
  //     const request = {
  //         apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.GET_NOTIFICATIONS,
  //         method: API_METHOD.GET,
  //     }
  //     let result = await API_CALL(request)
  //     if (result.status === 200) {
  //         console.log(result.data)
  //         // setNotificationsAtom(result.data.result.notificationList)
  //     } else if (result.status > 200) {
  //         swal({
  //             title: "ERROR",
  //             text: result.message,
  //             icon: 'https://cdn-icons-png.flaticon.com/512/2569/2569174.png',
  //             className: "error-window",
  //             button: true,
  //         })
  //     }
  // }

  // useEffect(() => {
  //     getAllNotifications();
  // }, [])

  /// FETCH FROM SERVER

  return (
    <>
      <Nav.Item className="ml-lg-auto my-3">
        <Link to="/cart" className="me-lg-4 position-relative">
          {cart?.items?.length > 0 && (
            <p
              className="position-absolute rounded-circle text-white d-flex justify-content-center align-items-center"
              style={{
                width: "23px",
                height: "23px",
                fontSize: "0.8rem",
                backgroundColor: "#395061",
                top: "-13px",
                left: "10px",
              }}
            >
              {cart?.items?.length}
            </p>
          )}
          <svg
            className="cart"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_184_2835)">
              <path
                d="M23.6594 4.24688C23.3664 3.89525 22.9995 3.61245 22.5849 3.41856C22.1703 3.22467 21.7181 3.12444 21.2604 3.125H4.41875L4.375 2.75938C4.28547 1.99939 3.92019 1.29868 3.34843 0.790081C2.77666 0.281483 2.03815 0.000356035 1.27292 0L1.04167 0C0.765399 0 0.500448 0.109747 0.305097 0.305097C0.109747 0.500448 0 0.7654 0 1.04167C0 1.31793 0.109747 1.58289 0.305097 1.77824C0.500448 1.97359 0.765399 2.08333 1.04167 2.08333H1.27292C1.52806 2.08337 1.77431 2.17704 1.96497 2.34658C2.15563 2.51612 2.27744 2.74974 2.30729 3.00313L3.74062 15.1906C3.88943 16.4581 4.49841 17.6269 5.45199 18.475C6.40556 19.3232 7.63735 19.7917 8.91354 19.7917H19.7917C20.0679 19.7917 20.3329 19.6819 20.5282 19.4866C20.7236 19.2912 20.8333 19.0263 20.8333 18.75C20.8333 18.4737 20.7236 18.2088 20.5282 18.0134C20.3329 17.8181 20.0679 17.7083 19.7917 17.7083H8.91354C8.26881 17.7065 7.64042 17.5053 7.11452 17.1324C6.58861 16.7594 6.19095 16.2329 5.97604 15.625H18.3927C19.6138 15.6251 20.7962 15.1961 21.7331 14.413C22.6701 13.6298 23.3021 12.5424 23.5187 11.3406L24.3365 6.80521C24.4181 6.35522 24.3997 5.89278 24.2827 5.45066C24.1657 5.00855 23.9529 4.59758 23.6594 4.24688ZM22.2917 6.43542L21.4729 10.9708C21.3428 11.6927 20.963 12.3458 20.3999 12.8158C19.8367 13.2858 19.1262 13.5428 18.3927 13.5417H5.64479L4.66458 5.20833H21.2604C21.4134 5.20742 21.5648 5.24023 21.7037 5.30443C21.8426 5.36864 21.9656 5.46265 22.0641 5.5798C22.1625 5.69695 22.234 5.83435 22.2733 5.98223C22.3126 6.13011 22.3189 6.28484 22.2917 6.43542Z"
                fill="#395061"
              />
              <path
                d="M7.29159 25.0001C8.44218 25.0001 9.37492 24.0673 9.37492 22.9167C9.37492 21.7661 8.44218 20.8334 7.29159 20.8334C6.14099 20.8334 5.20825 21.7661 5.20825 22.9167C5.20825 24.0673 6.14099 25.0001 7.29159 25.0001Z"
                fill="#395061"
              />
              <path
                d="M17.7083 25.0001C18.8589 25.0001 19.7917 24.0673 19.7917 22.9167C19.7917 21.7661 18.8589 20.8334 17.7083 20.8334C16.5577 20.8334 15.625 21.7661 15.625 22.9167C15.625 24.0673 16.5577 25.0001 17.7083 25.0001Z"
                fill="#395061"
              />
            </g>
            <defs>
              <clipPath id="clip0_184_2835">
                <rect width="25" height="25" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </Link>
      </Nav.Item>
      <Nav.Item className="ml-lg-auto my-3">
        <Link to="/my-courses/wishlist" className="me-lg-4 position-relative">
          {wishList.length > 0 && (
            <p
              className="position-absolute rounded-circle text-white d-flex justify-content-center align-items-center"
              style={{
                width: "23px",
                height: "23px",
                fontSize: "0.8rem",
                backgroundColor: "#395061",
                top: "-13px",
                left: "10px",
              }}
            >
              {wishList.length}
            </p>
          )}
          <svg
            width="21"
            height="21"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_295_3062)">
              <path
                d="M11.6252 4.87683L12.5002 6.45761L13.3751 4.87683C13.858 4.0044 14.5625 3.27484 15.4175 2.76181C16.2642 2.25383 17.2287 1.97596 18.2155 1.95549C19.8103 2.03254 21.3115 2.73366 22.3943 3.90817C23.4839 5.08993 24.0615 6.65488 24.0009 8.26112L24.0002 8.27997V8.29883C24.0002 9.80416 23.3862 11.452 22.3562 13.1365C21.3326 14.8105 19.9469 16.4413 18.5202 17.8924C15.6672 20.794 12.7479 22.8786 12.5243 23.0369L12.5002 23.054L12.4783 23.0386C12.4779 23.0383 12.4775 23.0379 12.477 23.0376C12.2408 22.8693 9.32749 20.7872 6.48011 17.8916C5.05336 16.4407 3.66766 14.81 2.64407 13.1361C1.61411 11.4519 1.00016 9.80415 1.00016 8.29883V8.27997L0.999449 8.26112C0.938839 6.65488 1.51641 5.08993 2.60597 3.90817C3.68885 2.73366 5.18998 2.03254 6.78486 1.95549C7.77164 1.97596 8.73615 2.25383 9.58278 2.76181C10.4378 3.27484 11.1424 4.0044 11.6252 4.87683Z"
                stroke="#395061"
                stroke-width="2"
              />
            </g>
            <defs>
              <clipPath id="clip0_295_3062">
                <rect width="25" height="25" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </Link>
      </Nav.Item>
      <Nav.Item className="ml-lg-auto my-3">
        <Link to="/message-to-instrcutor" className="me-lg-4 position-relative">
          {/* {notifications.count > 0 && (
                        <p className="position-absolute rounded-circle text-white d-flex justify-content-center align-items-center" style={{ width: '23px', height: '23px', fontSize: '0.8rem', backgroundColor: 'crimson', top: '-13px', left: '10px' }}>
                            {notifications.count}
                        </p>
                    )} */}
          <svg
            width="23"
            height="23"
            data-name="Livello 1"
            id="Livello_1"
            viewBox="0 0 128 128"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title />
            <path d="M116.73,31.83a3,3,0,0,0-4.2-.61L64.14,67.34a1,1,0,0,1-1.2,0L15.5,31.06a3,3,0,1,0-3.64,4.77L49.16,64.36,12.27,92.16A3,3,0,1,0,15.88,97L54.11,68.14l5.18,4a7,7,0,0,0,8.43.06l5.44-4.06L111.84,97a3,3,0,1,0,3.59-4.81L78.17,64.35,116.12,36A3,3,0,0,0,116.73,31.83Z" />
            <path d="M113,19H15A15,15,0,0,0,0,34V94a15,15,0,0,0,15,15h98a15,15,0,0,0,15-15V34A15,15,0,0,0,113,19Zm9,75a9,9,0,0,1-9,9H15a9,9,0,0,1-9-9V34a9,9,0,0,1,9-9h98a9,9,0,0,1,9,9Z" />
          </svg>
        </Link>
      </Nav.Item>
      <Nav.Item className="ml-lg-auto my-3">
        <Link to="/notifications" className="me-lg-4 position-relative">
          {notifications.count > 0 && (
            <p
              className="position-absolute rounded-circle text-white d-flex justify-content-center align-items-center"
              style={{
                width: "23px",
                height: "23px",
                fontSize: "0.8rem",
                backgroundColor: "crimson",
                top: "-13px",
                left: "10px",
              }}
            >
              {notifications.count}
            </p>
          )}
          <svg
            width="23"
            height="23"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M24.2282 16.8751L21.5199 7.11364C20.9416 5.02553 19.6814 3.19087 17.94 1.90165C16.1986 0.612423 14.0759 -0.0572729 11.91 -0.000856608C9.74398 0.0555597 7.65911 0.834846 5.98716 2.21298C4.31521 3.59111 3.15227 5.48888 2.68343 7.60426L0.588637 17.0313C0.487275 17.4881 0.489747 17.9617 0.595871 18.4173C0.701996 18.8729 0.909063 19.2989 1.2018 19.6639C1.49453 20.0288 1.86546 20.3233 2.28722 20.5257C2.70898 20.7282 3.17081 20.8333 3.63864 20.8334H7.39593C7.63501 22.0108 8.27377 23.0693 9.20399 23.8296C10.1342 24.59 11.2987 25.0053 12.5001 25.0053C13.7015 25.0053 14.866 24.59 15.7962 23.8296C16.7264 23.0693 17.3652 22.0108 17.6043 20.8334H21.2188C21.7002 20.8332 22.175 20.7218 22.6062 20.5079C23.0374 20.2939 23.4134 19.9833 23.7048 19.6001C23.9962 19.217 24.1951 18.7717 24.2861 18.299C24.3771 17.8263 24.3576 17.339 24.2293 16.8751H24.2282ZM12.5001 22.9168C11.8561 22.9141 11.2286 22.7125 10.7035 22.3396C10.1785 21.9667 9.78143 21.4406 9.56676 20.8334H15.4334C15.2188 21.4406 14.8217 21.9667 14.2967 22.3396C13.7716 22.7125 13.1441 22.9141 12.5001 22.9168ZM22.047 18.3386C21.9498 18.4675 21.8238 18.5718 21.6791 18.6432C21.5344 18.7147 21.375 18.7513 21.2136 18.7501H3.63864C3.48266 18.7501 3.32868 18.715 3.18806 18.6475C3.04744 18.58 2.92378 18.4818 2.82619 18.3601C2.72861 18.2384 2.65959 18.0964 2.62425 17.9445C2.5889 17.7926 2.58812 17.6346 2.62197 17.4824L4.71676 8.0553C5.08588 6.3951 5.99937 4.90593 7.31203 3.82452C8.62468 2.74311 10.2612 2.13153 11.9613 2.08699C13.6615 2.04245 15.3277 2.56751 16.6952 3.57871C18.0627 4.58991 19.0529 6.02921 19.5084 7.6678L22.2168 17.4293C22.261 17.5836 22.2687 17.7461 22.2392 17.904C22.2097 18.0618 22.1439 18.2106 22.047 18.3386Z"
              fill="#395061"
            />
          </svg>
        </Link>
      </Nav.Item>
    </>
  );
}

export default StudentMenu;
