import React from "react";
import { Container, Modal } from "react-bootstrap";
import done from "../../images/login/success-note.png";
function CreateNewPasswordSuccess({ setSelected, handleClose }) {
  function handleSubmit() {
    handleClose();
    setSelected();
  }
  return (
    <div>
      {" "}
      <Container>
        <Modal.Header closeButton className="mh">
          {/* <Modal.Title className='mdlt'>Success</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <img src={done.src} className="done" />
          <p className="text-center fw-bold py-3">
            Password Reset Successfully
          </p>
          <button
            className="login-form-btn mx-auto w-40 d-block mb-4"
            onClick={handleSubmit}
          >
            Done
          </button>
        </Modal.Body>
      </Container>
    </div>
  );
}

export default CreateNewPasswordSuccess;
