import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import { useEffect, useRef, useState } from "react";
import { Carousel, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import "react-phone-input-2/lib/style.css";
import { Link, useNavigate } from "react-router-dom";
import { API_CALL } from "../../api/ApiServiceCall";
import InstructorAppRedirectModal from "../../common/InstructorAppRedirectModal";
import { API_CONSTANTS, API_METHOD, STORAGE_KEY } from "../../common/constants";
import useCookie from "../../common/cookie_hook";
import "../../css/live-class.css";
import "../../css/ph-form.css";
import VerifyOTPSignUp from "./verify-signup";

function CreateAccount() {
  const [cookie, updateCookie] = useCookie(STORAGE_KEY.AUTH);
  const [loading, setLoading] = useState(false);
  const [EyeVisible, setEyeVisible] = useState("password");
  const [loadingGoogle, setLoadingGoogle] = useState(false);
  const [createAccDetails, setCreateAccDetails] = useState("");
  const [error, setError] = useState();
  const [show, setShow] = useState(false);
  // =======
  const [phNo, setPhno] = useState("");
  const [validatePhone, setValidatePhone] = useState(false);
  const handleClose = () => setShow(false);

  const [showFocus, setShowFocus] = useState(false);
  // const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const onSubmit = async (data) => {
    console.log("pppppppppp:", phNo.length);
    if (phNo.length >= 10) {
      // checking phone is there or not
      setLoading(true);

      let requestBodyParam = JSON.stringify({
        firstName: data.first,
        middleName: data?.middle,
        lastName: data.last,
        email: data.email,
        password: data.password,
        userType: "STUDENT",
        mobileNumber: `+91${phNo}`,
      });

      // return console.log(`+${phNo}`);

      const request = {
        apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.REGISTER_END_POINT,
        method: API_METHOD.POST,
        requestBodyParams: requestBodyParam,
      };
      let result = await API_CALL(request);
      if (result.status === 200) {
        setCreateAccDetails(requestBodyParam);
        setLoading(false);
        setShow(true);
        // reset();
      } else if (result.status > 200) {
        setError(result.message);
        setLoading(false);
      }
    } else {
      // console.log(phNo.length);
    }
  };
  const slideControl = useRef(null);

  const onPrevClick = () => {
    slideControl.current.prev();
  };
  const onNextClick = () => {
    slideControl.current.next();
  };

  const firebaseConfig = {
    apiKey: process.env.REACT_APP_APIKEY,
    authDomain: process.env.REACT_APP_AUTHDOMAIN,
    projectId: process.env.REACT_APP_PROJECTID,
    storageBucket: process.env.REACT_APP_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERI,
    appId: process.env.REACT_APP_APPID,
  };

  // Initialize Firebase
  // const app = initializeApp(firebaseConfig);
  // const analytics = getAnalytics(app);
  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  provider.setCustomParameters({
    prompt: "select_account",
  });
  // signOut(auth).then(() => {
  //     // Sign-out successful.
  //   }).catch((error) => {
  //     // An error happened.
  //   });
  const googleLogin = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        UserDataHandlingToServer(result, token);
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  };
  const UserDataHandlingToServer = async (data, token) => {
    setLoadingGoogle(true);
    let requestBodyParam = JSON.stringify({
      fullName: data.user.displayName,
      email: data.user.email,
      isGmail: true,
      userType: "STUDENT",
      mobileNumber: "",
    });
    const request = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.GMAIL_REGISTER,
      method: API_METHOD.POST,
      requestBodyParams: requestBodyParam,
    };
    // let result = await API_CALL(request).catch(error => console.log("need to handle error in login fetch", error))
    let result = await API_CALL(request);
    if (result.status === 200) {
      updateCookie(token);
      const setGoogData = JSON.stringify({ data });
      localStorage.setItem(STORAGE_KEY.LOCAL_USER_DATA, setGoogData);
      setLoadingGoogle(false);
      navigate("/student-home");
    } else if (result.status > 200) {
      setError(result.message);
      setLoadingGoogle(false);
    }
  };

  const strongRegex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  );

  useEffect(() => {
    if (Object.keys(errors).length < 2 && validatePhone && phNo.length < 10) {
      document.querySelector("[placeholder='Enter Mobile Number']").focus();
    }
  }, [validatePhone, phNo, errors]);

  return (
    <div style={{ height: "100vh" }}>
      <InstructorAppRedirectModal />
      <div className="row p-0 m-0">
        <div className="login-col col-lg-6 py-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-9 ms-auto ">
                <div className="welcm-bk">
                  <h1>Create New Account</h1>
                  <div className="login-card">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row">
                        <div className="col-sm-6 col-md-6 col-lg-12 col-xl-6 m-0 pe-lg-0">
                          <label htmlFor="firstName">
                            First <span className="fz-13px text-danger">*</span>
                          </label>
                          <input
                            className="mb-2"
                            placeholder="First Name "
                            type="text"
                            id="firstName"
                            {...register("first", { required: true })}
                          />
                          <small className="text-danger mb-2 d-block fz-12px fw-500">
                            {errors.first?.type === "required" &&
                              "First Name required"}
                          </small>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-12 col-xl-6 m-0 pe-lg-0">
                          <label htmlFor="middleName">Middle</label>
                          <input
                            className="mb-2"
                            placeholder="Middle Name "
                            type="text"
                            id="middleName"
                            {...register("middle", { required: false })}
                          />
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 m-0 pe-lg-0">
                          <label htmlFor="lastName">
                            Last <span className="fz-13px text-danger">*</span>
                          </label>
                          <input
                            className="mb-2"
                            placeholder="Last Name "
                            type="text"
                            id="lastName"
                            {...register("last", { required: true })}
                          />
                          <small className="text-danger mb-2 d-block fz-12px fw-500">
                            {errors.last?.type === "required" &&
                              "Last Name required"}
                          </small>
                        </div>
                      </div>

                      <label htmlFor="signup_email">
                        Email Address{" "}
                        <span className="fz-13px text-danger">*</span>
                      </label>
                      <input
                        className="mb-2"
                        placeholder="Enter your Email "
                        type="email"
                        id="signup_email"
                        {...register("email", { required: true })}
                      />
                      <small className="text-danger mb-2 d-block fz-12px fw-500">
                        {" "}
                        {errors.email?.type === "required" &&
                          "Please Enter your Email"}
                      </small>
                      {/* <small className='text-danger mb-2 d-block fz-12px fw-500'>{errors?.email?.type === "validate" && (<p>Please Dont use space</p>)}</small> */}
                      <label htmlFor="ph_no">
                        Mobile Number{" "}
                        <span className="fz-13px text-danger">*</span>
                      </label>

                      <input
                        className="ph-frm border-0"
                        placeholder="Enter Mobile Number"
                        id="ph_no"
                        type="number"
                        value={phNo}
                        maxLength={11}
                        onChange={(e) => {
                          setPhno(e.target.value);
                          setError("");
                        }}
                        onBlur={() => setValidatePhone(true)}
                      />
                      <small className="text-danger my-2 d-block fz-12px fw-500">
                        {validatePhone && phNo
                          ? phNo.length === 10
                            ? ""
                            : "Invalid mobile number"
                          : validatePhone && phNo == ""
                          ? "Please enter your mobile number"
                          : ""}
                      </small>

                      {/* <PhoneInput
                                                country={'in'}
                                                countryCodeEditable={false}
                                                buttonClass="border-0 ps-1"
                                                className="ph-frm border-0"
                                                placeholder='Enter Mobile Number'
                                                value={phNo}
                                                onChange={(phone) => setPhno(phone)}
                                                onBlur={() => setValidatePhone(true)}
                                                inputProps={{
                                                    name: 'phone',
                                                    required: true,
                                                    autoFocus: false,
                                                    required: false,
                                                }}
                                            />

                                            <small className='text-danger my-2 d-block fz-12px fw-500'>
                                                {validatePhone && phNo ? phNo.length > "11" ? "" : "invalid mobile number" : validatePhone && phNo == "" ? "Please enter your mobile number" : ""}
                                            </small> */}

                      <label htmlFor="signup_pasword">
                        Password <span className="fz-13px text-danger">*</span>
                      </label>
                      <div className="position-relative">
                        <input
                          className="mb-2"
                          placeholder="Enter your Password"
                          maxLength={16}
                          type={EyeVisible}
                          id="signup_pasword"
                          {...register("password", {
                            required: true,
                            minLength: 8,
                            pattern: strongRegex,
                          })}
                        />
                        <div className="passfield">
                          {EyeVisible === "password" ? (
                            <span onClick={() => setEyeVisible("text")}>
                              <AiOutlineEyeInvisible />
                            </span>
                          ) : (
                            <span onClick={() => setEyeVisible("password")}>
                              <AiOutlineEye />
                            </span>
                          )}
                        </div>
                      </div>
                      <small className="text-danger mb-2 d-block fz-12px fw-500">
                        {errors?.password?.type === "minLength" && (
                          <p>It is Too short</p>
                        )}
                      </small>
                      <small className="text-danger mb-2 d-block fz-12px fw-500">
                        {errors?.password?.type === "pattern" && (
                          <p>
                            Please include combinations of Lowercase, Uppercase,
                            Numbers and special characters.
                          </p>
                        )}
                      </small>
                      <small className="text-danger mb-2 d-block fz-12px fw-500">
                        {errors.password?.type === "required" &&
                          "Please Enter Password"}
                      </small>
                      <button
                        className="login-form-btn m-0"
                        type="submit"
                        onClick={() => setValidatePhone(true)}
                        disabled={loading ? true : false}
                      >
                        {loading ? "Please wait..." : "Create Account"}
                      </button>
                    </form>
                    <small className="text-danger d-block  my-3 text-center fw-500">
                      {error}
                    </small>
                    <button
                      className="glogin-form-btn  mt-1"
                      onClick={googleLogin}
                      disabled={loadingGoogle ? true : false}
                    >
                      {loadingGoogle ? (
                        "Please wait..."
                      ) : (
                        <>
                          <svg
                            className="g-icon"
                            viewBox="0 0 35 35"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M33.6783 14.4572L19.4021 14.4565C18.7717 14.4565 18.2607 14.9675 18.2607 15.5979V20.1585C18.2607 20.7887 18.7717 21.2998 19.4021 21.2998H27.4415C26.5612 23.5844 24.9181 25.4977 22.8218 26.7134L26.2498 32.6477C31.7488 29.4674 34.9998 23.8873 34.9998 17.6407C34.9998 16.7513 34.9343 16.1155 34.8032 15.3996C34.7035 14.8556 34.2313 14.4572 33.6783 14.4572Z"
                              fill="#167EE6"
                            />
                            <path
                              d="M17.5002 28.1521C13.5659 28.1521 10.1312 26.0025 8.28654 22.8215L2.35254 26.2418C5.37231 31.4756 11.0293 35 17.5002 35C20.6746 35 23.6699 34.1453 26.2502 32.6558V32.6476L22.8222 26.7134C21.2542 27.6228 19.4396 28.1521 17.5002 28.1521Z"
                              fill="#12B347"
                            />
                            <path
                              d="M26.25 32.6558V32.6477L22.822 26.7134C21.254 27.6228 19.4396 28.1521 17.5 28.1521V35C20.6744 35 23.6698 34.1453 26.25 32.6558Z"
                              fill="#0F993E"
                            />
                            <path
                              d="M6.84783 17.5C6.84783 15.5608 7.37707 13.7464 8.28632 12.1785L2.35231 8.75818C0.854629 11.3303 0 14.3175 0 17.5C0 20.6826 0.854629 23.6698 2.35231 26.2419L8.28632 22.8216C7.37707 21.2537 6.84783 19.4393 6.84783 17.5Z"
                              fill="#FFD500"
                            />
                            <path
                              d="M17.5002 6.84783C20.0658 6.84783 22.4224 7.75947 24.2632 9.27589C24.7173 9.64995 25.3773 9.62295 25.7932 9.20698L29.0246 5.97564C29.4965 5.50368 29.4629 4.73115 28.9588 4.29379C25.8747 1.61827 21.862 0 17.5002 0C11.0293 0 5.37231 3.5244 2.35254 8.75813L8.28654 12.1784C10.1312 8.99746 13.5659 6.84783 17.5002 6.84783Z"
                              fill="#FF4B26"
                            />
                            <path
                              d="M24.2629 9.27589C24.717 9.64995 25.3771 9.62295 25.793 9.20698L29.0244 5.97564C29.4963 5.50368 29.4626 4.73115 28.9585 4.29379C25.8745 1.6182 21.8618 0 17.5 0V6.84783C20.0655 6.84783 22.4222 7.75947 24.2629 9.27589Z"
                              fill="#D93F21"
                            />
                          </svg>
                          Sign Up With Google
                        </>
                      )}
                    </button>
                    <div className="">
                      <p className="acc-cr-link">
                        <span>Already Have An Account?</span>{" "}
                        <Link to="/student-login">Log In</Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="col-lg-6 pe-0 d-none d-block"
          style={{ height: "100vh" }}
        >
          <div className="bg-signup-r h-100">
            <div className="container h-100 align-items-end d-flex">
              <div className="login-r-container ">
                <Carousel
                  indicators={false}
                  controls={false}
                  ref={slideControl}
                  interval={5000}
                >
                  <Carousel.Item>
                    <div>
                      <p>
                        "Learning languages with QLearning was incredible. The
                        interactive labs and cultural content immersed me in the
                        language and broadened my horizons."
                      </p>
                      <p className="author m-0">- Carlos Gomez</p>
                      <small className="info">Language Enthusiast</small>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div>
                      <p>
                        "QLearning's diverse courses satisfy my thirst for
                        knowledge. The interactive platform and expert
                        instructors make learning an exciting and continuous
                        adventure."
                      </p>
                      <p className="author m-0">- James Miller</p>
                      <small className="info">Lifelong Learner</small>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div>
                      <p>
                        : "QLearning's web development course gave me the skills
                        I needed to pursue my dream. The interactive assignments
                        and supportive community made learning enjoyable and
                        effective."
                      </p>
                      <p className="author m-0">- Amanda Rodriguez</p>
                      <small className="info">Aspiring Web Developer</small>
                    </div>
                  </Carousel.Item>
                </Carousel>
                <div className="controll-arrw ">
                  <div className="slide-prev-frm me-3" onClick={onPrevClick}>
                    <svg
                      viewBox="0 0 23 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.346537 7.76351L7.63821 0.471853C7.83467 0.282105 8.0978 0.177111 8.37092 0.179484C8.64404 0.181857 8.9053 0.291408 9.09844 0.484542C9.29157 0.677675 9.40112 0.938938 9.40349 1.21206C9.40587 1.48518 9.30087 1.74831 9.11113 1.94477L3.59758 7.45831L21.9163 7.4583C22.1926 7.4583 22.4576 7.56805 22.6529 7.7634C22.8483 7.95875 22.958 8.2237 22.958 8.49997C22.958 8.77624 22.8483 9.04119 22.6529 9.23654C22.4576 9.43189 22.1926 9.54164 21.9163 9.54164L3.59758 9.54164L9.11113 15.0552C9.21062 15.1513 9.28997 15.2662 9.34457 15.3933C9.39916 15.5204 9.42789 15.6571 9.4291 15.7954C9.4303 15.9337 9.40394 16.0709 9.35157 16.1989C9.29919 16.3269 9.22184 16.4432 9.12404 16.541C9.02623 16.6388 8.90993 16.7162 8.78191 16.7685C8.6539 16.8209 8.51673 16.8473 8.37842 16.8461C8.24011 16.8449 8.10342 16.8161 7.97633 16.7615C7.84924 16.7069 7.7343 16.6276 7.63821 16.5281L0.346537 9.23643C0.151255 9.04109 0.0415522 8.77618 0.0415522 8.49997C0.0415521 8.22376 0.151255 7.95886 0.346537 7.76351V7.76351Z"
                        fill="#F0E1EB"
                      />
                    </svg>
                  </div>
                  <div className="slide-next-frm" onClick={onNextClick}>
                    <svg
                      viewBox="0 0 23 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M22.6535 9.23649L15.3618 16.5281C15.1653 16.7179 14.9022 16.8229 14.6291 16.8205C14.356 16.8181 14.0947 16.7086 13.9016 16.5155C13.7084 16.3223 13.5989 16.0611 13.5965 15.7879C13.5941 15.5148 13.6991 15.2517 13.8889 15.0552L19.4024 9.5417H1.08366C0.807392 9.5417 0.54244 9.43195 0.347089 9.2366C0.151739 9.04125 0.0419922 8.7763 0.0419922 8.50003C0.0419922 8.22376 0.151739 7.95881 0.347089 7.76346C0.54244 7.56811 0.807392 7.45836 1.08366 7.45836H19.4024L13.8889 1.94483C13.7894 1.84874 13.71 1.7338 13.6554 1.60671C13.6008 1.47962 13.5721 1.34293 13.5709 1.20462C13.5697 1.06631 13.5961 0.929144 13.6484 0.801126C13.7008 0.673111 13.7782 0.556807 13.876 0.459002C13.9738 0.361197 14.0901 0.283848 14.2181 0.231472C14.3461 0.179096 14.4833 0.15274 14.6216 0.153942C14.7599 0.155144 14.8966 0.183882 15.0237 0.238474C15.1508 0.293066 15.2657 0.372421 15.3618 0.47191L22.6535 7.76357C22.8487 7.95891 22.9584 8.22382 22.9584 8.50003C22.9584 8.77624 22.8487 9.04115 22.6535 9.23649Z"
                        fill="#395061"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <style jsx="true">{`
            body {
              background: #f3f3f3;
            }
          `}</style>
        </div>
      </div>
      {/* ================modal============== */}
      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        centered
        backdrop={"static"}
        className="mh"
      >
        <VerifyOTPSignUp
          handleClose={handleClose}
          createAccDetails={createAccDetails}
        />
      </Modal>
    </div>
  );
}

export default CreateAccount;
