import { useState, useEffect } from 'react';
import swal from 'sweetalert';
import moment from 'moment';
import StarRatings from 'react-star-ratings';
import { API_CALL } from '../../../api/ApiServiceCall';
import { API_CONSTANTS, API_METHOD } from '../../../common/constants';
import MyS3Component from '../../s3/s3';

function CourseReviews({ rating, courseCode }) {

    const [ratingData, setRatingData] = useState({ loading: false, error: null, data: {} });

    const getRatingData = async (courseCode, selectedFilter = "") => {
        setRatingData({ loading: true, data: [], error: null });

        const request = {
            apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.COURSE_RATING_BY_COURSE_CODE_TEST(courseCode) + `?${selectedFilter}`,
            method: API_METHOD.GET,
        }

        let result = await API_CALL(request);

        if (result.status === 200) {
            setRatingData({ loading: false, data: result.data ? result.data : [], error: null });
        } else if (result.status > 200) {
            setRatingData({ loading: false, data: [], error: result.message });

            if (result.message == "Token is Not Valid") return null

            swal({
                title: "ERROR",
                text: result.message,
                icon: 'https://cdn-icons-png.flaticon.com/512/2569/2569174.png',
                className: "error-window",
                button: true,
            })
        }
    }

    useEffect(() => {
        if (courseCode) {
            getRatingData(courseCode)
        }
    }, [courseCode])


    const handleRatingFilter = e => {
        const selectedFilter = e.target.value;
        if (courseCode) {
            getRatingData(courseCode, selectedFilter)
        }
    }

    return (
        <>
            <div className="d-flex">
                <div>
                    <span className="text-center d-block fw-semibold" style={{ fontSize: '3.3rem', color: '#FFBE40' }}>
                        {rating}
                    </span>
                    <div>
                        <StarRatings
                            rating={rating}
                            starRatedColor="#F7AC16"
                            starDimension="22px"
                            starSpacing="1.1px"
                        />
                    </div>
                    <h6 className="text-center mt-2 mb-0 pb-0">
                        {ratingData.data.numberOfRatings} Ratings
                    </h6>
                </div>
                <div className="flex-grow-1">
                    <RatingProgress fill={ratingData.data.percentageOfFiveStar} percentage={ratingData.data.percentageOfFiveStar} state={5} />
                    <RatingProgress fill={ratingData.data.percentageOfFourStar} percentage={ratingData.data.percentageOfFourStar} state={4} />
                    <RatingProgress fill={ratingData.data.percentageOfThreeStar} percentage={ratingData.data.percentageOfThreeStar} state={3} />
                    <RatingProgress fill={ratingData.data.percentageOfTwoStar} percentage={ratingData.data.percentageOfTwoStar} state={2} />
                    <RatingProgress fill={ratingData.data.percentageOfOneStar} percentage={ratingData.data.percentageOfOneStar} state={1} />
                </div>
            </div>

            <div>
                <div className="tag ms-auto my-4" style={{ width: '100px' }}>
                    <select className="d-inline-block" onChange={handleRatingFilter}>
                        <option value="">Filter</option>
                        <option value="newestRating=true">Newest</option>
                        <option value="givenRating=5">5 Star</option>
                        <option value="givenRating=4">4 Star</option>
                        <option value="givenRating=3">3 Star</option>
                        <option value="givenRating=2">2 Star</option>
                        <option value="givenRating=1">1 Star</option>
                    </select>
                </div>
                {ratingData.data.reviewList && ratingData.data.reviewList.map(rating => <ReviewCard rating={rating} />)}
            </div>
        </>
    )
}

function RatingProgress({ fill, percentage, state }) {
    return (
        <div className="d-flex align-items-center">
            <div className="flex-grow-1 px-4">
                <div className="progress rounded-1" style={{ height: '10px' }}>
                    <div
                        className="progress-bar rounded-1"
                        role="progressbar"
                        aria-label="Basic example"
                        style={{ width: `${fill}%`, backgroundColor: '#395061' }}
                        aria-valuenow={fill}
                        aria-valuemin="0"
                        aria-valuemax="100"
                    ></div>
                </div>
            </div>

            <div className="mb-1 me-1">
                {[1, 2, 3, 4, 5].map(item => (
                    <svg width="16" height="16" className="me-1" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M11.6581 14.6167L7.5 12.0147L3.34192 14.6166C3.19086 14.7123 2.99601 14.705 2.85055 14.5995C2.7055 14.4943 2.63897 14.3115 2.68274 14.1377L3.87234 9.37878L0.157143 6.22884C0.019799 6.11382 -0.0338436 5.9267 0.0215275 5.75634C0.0768986 5.58554 0.230531 5.4658 0.409067 5.45335L5.25811 5.11474L7.0923 0.565288C7.22707 0.23312 7.77293 0.23312 7.90769 0.565288L9.74188 5.11474L14.5909 5.45335C14.7695 5.4658 14.9231 5.58554 14.9785 5.75634C15.0338 5.9267 14.9802 6.11382 14.8429 6.22884L11.1276 9.37884L12.3172 14.1377C12.361 14.3116 12.2945 14.4944 12.1494 14.5995C12.001 14.7073 11.8067 14.7098 11.6581 14.6167Z"
                            fill={item <= state ? '#F7AC16' : '#8C8C8C'}
                        />
                    </svg>
                ))}
            </div>
            <span className="ps-1" style={{ fontSize: '0.9rem', width: '30px' }}>{percentage}%</span>
        </div>
    )
}

function ReviewCard({ rating }) {
    console.log(rating.rating)
    return (
      <div className="d-flex">
        <div className="py-3 flex-shrink-0">
          {/* <img
                    src={rating.profileImgPath ? rating.profileImgPath : 'https://cdn-icons-png.flaticon.com/512/149/149071.png'}
                    alt="instructor"
                    style={{ width: '55px', height: '55px' }}
                    className="rounded-circle"
                /> */}

          <MyS3Component
            imageUrl={
              rating.profileImgPath
                ? rating.profileImgPath
                : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
            }
            
            alt="instructor"
            style={{ width: "55px", height: "55px" }}
            className="rounded-circle"
          />
        </div>
        <div className="flex-grow-1 p-3">
          <div className="py-2">
            <div className="d-flex justify-content-between align-items-center">
              <h6 className="m-0 p-0">{rating.userName}</h6>
              <span style={{ fontSize: "13px" }}>
                {moment(rating.createdTime).format("D MMMM, YYYY")}
              </span>
            </div>
            <StarRatings
              rating={rating.rating}
              starRatedColor="#F7AC16"
              starDimension="19px"
              starSpacing="1px"
            />
          </div>
          <p className="mt-1 mb-0 text-muted" style={{ fontSize: "14px" }}>
            {rating.reviewContent}
          </p>
        </div>
      </div>
    );
}

export default CourseReviews