import React, { useState } from "react";
import { FiTrash2 } from "react-icons/fi";
import { MdModeEditOutline } from "react-icons/md";
import { useRecoilState } from "recoil";
import swal from "sweetalert";
import { API_CALL } from "../../../../../../../api/ApiServiceCall";
import {
  API_CONSTANTS,
  API_METHOD,
} from "../../../../../../../common/constants";
import { clickedChapterDataListAtomFunc } from "../../../../../../atom/clickedChapterDataListAtomFunc";
import { currentViewComponentLsnAssessAtomFunc } from "../../../../../../atom/lsnChapAssessComponentRender";
import CloseFileIcon from "../../../../../../svg-icons/closeFileIcon";
import LeftArrow from "../../../../../../svg-icons/leftArrow";
import SingleChapterView from "../singleChapterView";
import EditClickedLesson from "./editClickedLesson";
import MyS3Component from "../../../../../../s3/s3";
function ViewLesson({ singleClickedLesson }) {
  const [
    currentViewComponentLsnAssessAtom,
    setCurrentViewComponentLsnAssessAtom,
  ] = useRecoilState(currentViewComponentLsnAssessAtomFunc);
  const [clickedChapterDataListAtom, setClickedChapterDataListAtom] =
    useRecoilState(clickedChapterDataListAtomFunc);
  const [getClickedLessonData, setClickedLessonData] =
    useState(singleClickedLesson);
  const [isEdit, setIsEdit] = useState(false);
  const getLessonbyLessonOrderFunc = async () => {
    const request = {
      apiUrl:
        API_CONSTANTS.BASE_URL +
        API_CONSTANTS.GET_LESSON_BY_LESSON_ORDER +
        `/?courseCode=${clickedChapterDataListAtom.data.courseCode}&chapterOrder=${clickedChapterDataListAtom.data.chapterOrder}&lessonOrder=${getClickedLessonData?.lessonOrder}`,
      method: API_METHOD.GET,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      setClickedLessonData(result.data.lesson);
      // alert(JSON.stringify(result));
    } else if (result.status > 200) {
      console.log("errror", result);
    }
  };

  //onDelete Lesson
  const onDeleteLesson = async () => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover",
      icon: "https://cdn-icons-png.flaticon.com/512/6460/6460112.png",
      imageClass: "img-warning",
      animation: false,
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        const requestBodyParam = JSON.stringify({
          courseCode: clickedChapterDataListAtom.data.courseCode,
          lessonOrder: getClickedLessonData.lessonOrder,
          chapterOrder: clickedChapterDataListAtom.data.chapterOrder,
        });
        // alert(requestBodyParam)
        const request = {
          apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.DELETE_LESSON,
          method: API_METHOD.POST,
          requestBodyParams: requestBodyParam,
        };
        let result = await API_CALL(request);
        if (result.status === 200) {
          setCurrentViewComponentLsnAssessAtom(
            <SingleChapterView
              clickedChapterData={clickedChapterDataListAtom}
            />
          );
        } else if (result.status > 200) {
          swal({
            title: "ERROR",
            text: result.message,
            icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
            className: "error-window",
            button: true,
          });
        }
      }
    });
  };

  //onDelete Resource
  const onDeleteResource = async (resourceName) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover",
      icon: "https://cdn-icons-png.flaticon.com/512/6460/6460112.png",
      imageClass: "img-warning",
      animation: false,
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        const requestBodyParam = JSON.stringify({
          courseCode: clickedChapterDataListAtom.data.courseCode,
          lessonOrder: getClickedLessonData.lessonOrder,
          chapterOrder: clickedChapterDataListAtom.data.chapterOrder,
          resourceName: resourceName,
        });
        // alert(requestBodyParam)
        const request = {
          apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.DELETE_RESOURCE,
          method: API_METHOD.POST,
          requestBodyParams: requestBodyParam,
        };
        let result = await API_CALL(request);
        if (result.status === 200) {
          getLessonbyLessonOrderFunc();
        } else if (result.status > 200) {
          swal({
            title: "ERROR",
            text: result.message,
            icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
            className: "error-window",
            button: true,
          });
        }
      }
    });
  };

  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <small
          role={"button"}
          className="fz-16px fw-500  me-2"
          onClick={() =>
            setCurrentViewComponentLsnAssessAtom(
              <SingleChapterView
                clickedChapterData={clickedChapterDataListAtom}
              />
            )
          }
        >
          <LeftArrow size={8} />{" "}
          <span className=" d-inline-block mx-2">
            {getClickedLessonData.lessonName}
          </span>
        </small>
        {clickedChapterDataListAtom?.data?.courseStatus == "INREVIEW" ? (
          ""
        ) : (
          <div className=" d-flex ms-auto">
            <div type="button" onClick={() => setIsEdit(true)}>
              <MdModeEditOutline className="text-prim mx-2" />
            </div>
            <div type="button" onClick={onDeleteLesson}>
              <FiTrash2 className="text-danger" />
            </div>
          </div>
        )}
      </div>

      {isEdit ? (
        <EditClickedLesson
          getLessonbyLessonOrderFunc={getLessonbyLessonOrderFunc}
          setIsEdit={setIsEdit}
          getClickedLessonData={getClickedLessonData}
        />
      ) : (
        <>
          {/* ---View Lesson Content----- */}
          <MyS3Component
            className="rounded-2"
            style={{width:"100%", height:"auto"}}
            controls
            imageUrl={getClickedLessonData.lessonVideoPath}
          />

          {/* <pre>{JSON.stringify(clickedChapterDataListAtom, null, 4)}</pre> */}
          {/* <pre>{JSON.stringify(getClickedLessonData, null, 4)}</pre>   */}
          {getClickedLessonData.resourceDetails.length == 0 ? (
            ""
          ) : (
            <h6>Resource2</h6>
          )}
          {getClickedLessonData?.resourceDetails
            ? getClickedLessonData?.resourceDetails.map(
                (resourceData, indx) => (
                  <div
                    className="d-flex align-items-center"
                    key={resourceData._id}
                  >
                    <div
                      className="d-inline-block"
                      type="button"
                      onClick={() =>
                        onDeleteResource(resourceData.resourceName)
                      }
                    >
                      {" "}
                      <CloseFileIcon size={29} />
                    </div>
                    <a
                      href={resourceData.resourcePath}
                      className="pt-1 px-1 fz-15px text-prime"
                    >
                      {" "}
                      {resourceData.resourceName}
                    </a>
                  </div>
                )
              )
            : ""}

          {/* ---View Lesson Content End----- */}
        </>
      )}
    </>
  );
}

export default ViewLesson;
