import { Modal } from 'react-bootstrap'
import { useRecoilState } from 'recoil';
import { API_CALL } from '../../../api/ApiServiceCall'
import { API_CONSTANTS, API_METHOD } from '../../../common/constants'
import { useState } from 'react';
import swal from 'sweetalert';
import { getCredentialLoginAccountProfileDetailAtom } from '../../../components/atom/getCredentialLoginAccountProfileDetail';

function DeleteConfirm({ setShow, setDelete, handleClose }) {
    const [getCredentialUserDetail, setCredentialUserDetail] = useState(JSON.parse(localStorage.getItem("localUserData")));
    const [credentialLoginAccountProfileDetail, setCredentialLoginAccountProfileDetail] = useRecoilState(getCredentialLoginAccountProfileDetailAtom);

    const getAllProfileData = async () => {
        const userCode = JSON.parse(localStorage.getItem('userCode'));
        let email=getCredentialUserDetail?.email || getCredentialUserDetail?.data?.user?.email
        const request = {
            apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.GET_STUDENT_BY_EMAIL + `?email=${email}`,
            // apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.GET_STUDENT_BY_EMAIL(getCredentialUserDetail?.email || getCredentialUserDetail?.data?.user?.email),
            method: API_METHOD.GET,
        }
        let result = await API_CALL(request)
        if (result.status === 200) {
            // console.log(result)

            setCredentialLoginAccountProfileDetail(result);

            setShow();
            handleClose();

            // setCredentialLoginAccountProfileDetailAtom(result)
        } else if (result.status > 200) {
            swal({
                title: "ERROR",
                text: result.message,
                icon: 'https://cdn-icons-png.flaticon.com/512/2569/2569174.png',
                className: "error-window",
                button: true,
            })
        }
    }

    const proceedStep2 = async () => {
        const requestBodyParam = JSON.stringify({
            // deactivateReqDate: "10-11-2022"
        })
        const request = {
            apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.DEACTIVATE_ACCOUNT,
            method: API_METHOD.POST,
            requestBodyParams: requestBodyParam,
        }
        let result = await API_CALL(request)
        if (result.status === 200) {
            //console.log(result);

            getAllProfileData();

        } else if (result.status > 200) {
            swal({
                title: "ERROR",
                text: result.message,
                icon: 'https://cdn-icons-png.flaticon.com/512/2569/2569174.png',
                className: "error-window",
                button: true,
            })
        }
    }

    return (
        <div>
            <Modal.Body>
                <Modal.Header closeButton onHide={handleClose} className='pt-3'>
                </Modal.Header>
                <div className="d-flex justify-content-center">
                    <svg width="127" height="100" viewBox="0 0 127 132" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M61.3125 103.5C61.325 94.2006 64.7878 85.2363 71.0305 78.3432C77.2732 71.4502 85.8517 67.1188 95.1047 66.1878C92.7407 63.1976 89.7285 60.7832 86.2954 59.1267C82.8622 57.4702 79.0978 56.6149 75.2859 56.6253C74.5582 56.6253 73.8405 56.7947 73.1896 57.1202C72.5387 57.4456 71.9726 57.9181 71.5359 58.5003C63.8109 68.705 40.0781 68.705 32.3531 58.5003C31.915 57.9198 31.3485 57.4484 30.698 57.1231C30.0474 56.7979 29.3305 56.6275 28.6031 56.6253C22.2756 56.5935 16.1677 58.9437 11.4935 63.2088C6.81935 67.4739 3.92116 73.3416 3.375 79.6456L0.375 112.875C0.375 117.848 2.35044 122.617 5.86675 126.134C9.38305 129.65 14.1522 131.625 19.125 131.625H74.0906C70.0798 128.118 66.8647 123.795 64.6609 118.944C62.4572 114.094 61.3155 108.828 61.3125 103.5Z" fill="#F75656" />
                        <path d="M51.9375 0.375C14.4375 0.375 14.4375 56.625 51.9375 56.625C89.4375 56.625 89.4375 0.375 51.9375 0.375Z" fill="#F75656" />
                        <path d="M98.8125 75.375C93.2499 75.375 87.8122 77.0245 83.1871 80.1149C78.562 83.2053 74.9571 87.5979 72.8284 92.737C70.6997 97.8762 70.1427 103.531 71.2279 108.987C72.3131 114.443 74.9918 119.454 78.9251 123.387C82.8585 127.321 87.8699 129.999 93.3256 131.085C98.7813 132.17 104.436 131.613 109.575 129.484C114.715 127.355 119.107 123.751 122.198 119.125C125.288 114.5 126.937 109.063 126.937 103.5C126.929 96.0434 123.963 88.8948 118.69 83.6222C113.418 78.3496 106.269 75.3837 98.8125 75.375ZM108.187 108.187H89.4375C88.1943 108.187 87.002 107.694 86.1229 106.815C85.2439 105.935 84.75 104.743 84.75 103.5C84.75 102.257 85.2439 101.065 86.1229 100.185C87.002 99.3064 88.1943 98.8125 89.4375 98.8125H108.187C109.431 98.8125 110.623 99.3064 111.502 100.185C112.381 101.065 112.875 102.257 112.875 103.5C112.875 104.743 112.381 105.935 111.502 106.815C110.623 107.694 109.431 108.187 108.187 108.187Z" fill="#F75656" />
                    </svg>
                </div>
                <p className='text-center mb-2  mt-3'>Are you sure you want to Deactivate this account?</p>

                <div className="d-flex justify-content-center">
                    <button role="button" className="text-center border-0 w-35 my-3 me-3 fz-10px bg-mute text-muted fw-500 fz-14px py-2 rounded-3" onClick={setShow}>Cancel</button>
                    <button role="button" className="text-center border-0  px-4 my-3 fz-10px bg-prime text-white fz-14px py-2 rounded-3" onClick={proceedStep2}>Yes, Deactivate Account</button>
                </div>
            </Modal.Body>
        </div>
    )
}

export default DeleteConfirm