import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import swal from "sweetalert";
import { API_CALL } from "../../../../../api/ApiServiceCall";
import { API_CONSTANTS, API_METHOD } from "../../../../../common/constants";
import { isSingleLiveCourseEditAtomFunc } from "../../../../atom/isSingleLiveCourseEditAtom";
import { singleLiveCourseDataAtomFunc } from "../../../../atom/singleLiveCourseDataAtom";
function Step3LiveCourseEdit() {
  const [isValidate, setIsValidate] = useState({
    duration: false,
    from: false,
    to: false,
    day: false,
  });
  const [singleLiveCourseDataAtom, setSingleLiveCourseDataAtom] =
    useRecoilState(singleLiveCourseDataAtomFunc);
  const [isSingleLiveCourseEditAtom, setIsSingleLiveCourseEditAtom] =
    useRecoilState(isSingleLiveCourseEditAtomFunc);
  const [loading, setLoading] = useState(0);
  const [isDayValidation, setIsDayValidation] = useState(false);
  const [datePlaceHolder, setDatePlaceHolder] = useState("");
  const navigate = useNavigate();
  const [liveCourseFormDataStep3, setLiveCourseFormDataStep3] = useState({
    duration: singleLiveCourseDataAtom.courseDuration,
    from: singleLiveCourseDataAtom?.fromTime,
    to: singleLiveCourseDataAtom?.toTime,
  });
  useEffect(() => {
    detectBrowser();
  }, []);
  function detectBrowser() {
    const userAgent = navigator.userAgent;
    if (userAgent.indexOf("Firefox") !== -1) {
      setDatePlaceHolder("dd/mm/yyyy");
      // alert("fire")
    } else if (userAgent.indexOf("Chrome") !== -1) {
      setDatePlaceHolder("dd-mm-yyyy");
      // alert("chrome")
    } else {
      setDatePlaceHolder("dd/mm/yyyy");
      // alert("default")
    }
  }
  useEffect(() => {
    getCurrentLiveCourseData();
  }, []);

  const getCurrentLiveCourseData = async () => {
    const courseCode = localStorage.getItem("courseCode");
    const request = {
      apiUrl:
        API_CONSTANTS.BASE_URL +
        API_CONSTANTS.GET_LIVE_COURSE_BY_COURSE_CODE +
        `/?courseCode=${courseCode}`,
      method: API_METHOD.GET,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      setDay(result.data[0].classTime);
    } else if (result.status > 200) {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }
  };
  const [day, setDay] = useState([]);

  const finalSubmit = (e) => {
    e.preventDefault();
    const { toTime } = e.target;
    let isDayValidationResult = day.some(
      (classTimeData, index) =>
        classTimeData.isAvailable &&
        classTimeData.startTime &&
        classTimeData.endTime &&
        !classTimeData.isError
    );
    let isErrorChecking = day.some(
      (classTimeData) => classTimeData.isError === true
    );
    const isAllow =
      liveCourseFormDataStep3.duration &&
      liveCourseFormDataStep3.from &&
      isDayValidationResult &&
      !isErrorChecking;
    if (isAllow) {
      finalSubmitApprove(toTime.value);
    } else {
      setIsValidate({ duration: true, from: true, day: true });
    }
  };
  let isErrorVar = day.some((classTimeData) => classTimeData.isError === true);
  const finalSubmitApprove = async (toTime) => {
    const finalDayList = { classTime: [...day] };
    const finalData = { ...liveCourseFormDataStep3, ...finalDayList };
    setLoading(true);
    const courseCode = localStorage.getItem("courseCode");

    const requestBodyParam = JSON.stringify({
      courseCode: courseCode,
      courseDuration: finalData.duration,
      fromTime: finalData.from,
      toTime: moment(toTime, "DD-MM-YYYY").format("YYYY-MM-DD"),
      classTime: finalData.classTime,
    });
    // swal(JSON.stringify(requestBodyParam, null, 4))
    const request = {
      apiUrl:
        API_CONSTANTS.BASE_URL + API_CONSTANTS.UPDATE_LIVE_COURSE_DURATION,
      method: API_METHOD.POST,
      requestBodyParams: requestBodyParam,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      setLoading(false);
      setIsSingleLiveCourseEditAtom({
        refetchOnChange: Math.random(),
        edit: false,
      });
    } else if (result.status > 200) {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
      setLoading(false);
      setIsSingleLiveCourseEditAtom({
        ...isSingleLiveCourseEditAtom,
        edit: false,
      })
    }
  };
  const handleInputChange = (e, index) => {
    if (e.target.name == "isAvailable") {
      const { name, checked } = e.target;
      let list = [...day];
      list[index][name] = checked;
      if (
        list[index].isAvailable ||
        list[index].startTime ||
        list[index].endTime
      ) {
        list[index].isError = true;
      } else {
        list[index].isError = false;
      }
      if (
        list[index].isAvailable &&
        list[index].startTime &&
        list[index].endTime
      ) {
        list[index].isError = false;
      }
      setDay(list);
    } else {
      const { name, value } = e.target;
      let list = [...day];
      list[index][name] = value;
      if (
        list[index].isAvailable ||
        list[index].startTime ||
        list[index].endTime
      ) {
        list[index].isError = true;
      } else {
        list[index].isError = false;
      }
      if (
        list[index].isAvailable &&
        list[index].startTime &&
        list[index].endTime
      ) {
        list[index].isError = false;
      }
      setDay(list);
    }
  };
  const [today, setToday] = useState(new Date().toISOString().split("T")[0]);

  const validateForm = () => {
    day.forEach((classTimeData, index) => {
      if (
        classTimeData.isAvailable ||
        classTimeData.startTime ||
        classTimeData.endTime
      ) {
        if (
          classTimeData.startTime &&
          classTimeData.endTime &&
          classTimeData.isAvailable
        ) {
          setIsDayValidation(false);
        } else {
          setIsDayValidation(true);
        }
      }
    });
  };
  useEffect(() => {
    validateForm();
  }, [day]);
  let toDate;
  if (datePlaceHolder == "dd/mm/yyyy") {
    toDate = moment(liveCourseFormDataStep3?.from)
      .add(liveCourseFormDataStep3?.duration?.split("")[0], "M")
      .format("DD/MM/YYYY");
  } else {
    toDate = moment(liveCourseFormDataStep3?.from)
      .add(liveCourseFormDataStep3?.duration?.split("")[0], "M")
      .format("DD-MM-YYYY");
  }

  return (
    <>
      {/* <pre>{JSON.stringify(singleLiveCourseDataAtom, null, 4)}</pre> */}
      <form onSubmit={finalSubmit}>
        <div className="container live-course-stp3">
          {/* <h5>{isErrorVar.toString()}===={isErrorVar.toString()}</h5> */}
          <div className="row">
            <div className="col-12">
              <label className="py-3 fw-500">Course Duration</label>
              <div className="tag-ajus">
                <select
                  defaultValue={liveCourseFormDataStep3.duration}
                  onBlur={() =>
                    setIsValidate({ ...isValidate, duration: true })
                  }
                  onChange={(e) =>
                    setLiveCourseFormDataStep3({
                      ...liveCourseFormDataStep3,
                      duration: e.target.value,
                    })
                  }
                >
                  <option value="" disabled>
                    Select Duration
                  </option>
                  <option value="1month">1 month</option>
                  <option value="2months">2 months</option>
                  <option value="3months">3 months</option>
                  <option value="6months">6 months</option>
                </select>
              </div>
              {isValidate.duration &&
              liveCourseFormDataStep3.duration === "" ? (
                <small className="text-danger my-0 d-block fz-12px mt-3 fw-500">
                  Please Fill this field
                </small>
              ) : (
                ""
              )}
            </div>
            <div className="col-6">
              <label className="py-3 fw-500">From</label>
              {/* {liveCourseFormDataStep3.from} */}
              {/* {moment(singleLiveCourseDataAtom.fromTime).format('YYYY-MM-DD')} */}

              <input
                type="date"
                onKeyDown={(e) => {
                  if (e.code) e.preventDefault();
                }}
                min={moment(today, "YYYY-MM-DD")
                  .add(1, "day")
                  .format("YYYY-MM-DD")}
                max={moment(today, "YYYY-MM-DD")
                  .add(4, "years")
                  .format("YYYY-MM-DD")}
                className="default-inpt"
                placeholder=" "
                onBlur={() => setIsValidate({ ...isValidate, from: true })}
                defaultValue={liveCourseFormDataStep3?.from}
                onChange={(e) =>
                  setLiveCourseFormDataStep3({
                    ...liveCourseFormDataStep3,
                    from: e.target.value,
                  })
                }
                disabled={liveCourseFormDataStep3.duration ? false : true}
              />
              {isValidate.from && liveCourseFormDataStep3.from === "" ? (
                <small className="text-danger my-0 d-block fz-12px mt-3 fw-500">
                  Please Fill this field
                </small>
              ) : (
                ""
              )}
            </div>
            <div className="col-6 ">
              <label className="py-3 fw-500">To</label>
              <input
                type="text"
                className="default-inpt"
                name="toTime"
                id="toTime"
                defaultValue={toDate == "Invalid date" ? "" : toDate}
                key={toDate}
                disabled={true}
              />
            </div>
          </div>
          <div className="my-4"></div>
          <h6>Class Time</h6>

          <div className="my-3"></div>

          {(isValidate.day && isDayValidation) || isErrorVar ? (
            <small className="text-danger my-0 d-block fz-12px mt-3 fw-500">
              Please fill out the fields properly, at least one field must be
              filled out
            </small>
          ) : (
            ""
          )}
          <div className="row">
            <div className="col-6 pe-4">
              {day.slice(0, 4).map((classTime, indx) => {
                return (
                  <div key={classTime.id}>
                    <div className=" mt-4 row d-flex align-items-center">
                      <div className="col-lg-3 text-center">
                        <input
                          className="form-check-input "
                          type="checkbox"
                          id={classTime.day}
                          name="isAvailable"
                          defaultChecked={classTime.isAvailable}
                          onChange={(e) => handleInputChange(e, indx)}
                        />
                        <label
                          className=" mx-2 w-100  form-check-label fz-13px fw-600"
                          htmlFor={classTime.day}
                        >
                          {classTime.day}
                        </label>
                      </div>
                      <div className="col-lg-4">
                        <input
                          type="time"
                          className="default-inpt cst-clock px-lg-2"
                          name="startTime"
                          defaultValue={classTime.startTime}
                          onChange={(e) => handleInputChange(e, indx)}
                        />
                        {/* {day[indx].endTime && day[indx].startTime ? moment(day[indx].startTime, 'h:mm').isBefore(moment(day[indx].endTime, 'h:mm')) ? "" : <small className='text-danger'>Invalid</small> : ""} */}
                      </div>
                      <div className="col-lg-1">
                        <p className="m-0 p-0 fz-13px fw-600">to</p>
                      </div>
                      <div className="col-lg-4">
                        <input
                          type="time"
                          className="default-inpt cst-clock px-lg-2"
                          name="endTime"
                          defaultValue={classTime.endTime}
                          onChange={(e) => handleInputChange(e, indx)}
                        />

                        {/* {day[indx].startTime && day[indx].endTime ? moment(day[indx].endTime, 'h:mm').isAfter(moment(day[indx].startTime, 'h:mm')) ? "" : <small className='text-danger'>Invalid</small> : ""} */}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="col-6 pe-4">
              {day.slice(4, 7).map((classTime, indx) => {
                return (
                  <div key={classTime.id}>
                    <div className=" mt-4 row d-flex align-items-center">
                      <div className="col-lg-3 text-center">
                        <input
                          className="form-check-input "
                          type="checkbox"
                          id={classTime.day}
                          name="isAvailable"
                          defaultChecked={classTime.isAvailable}
                          onChange={(e) => handleInputChange(e, indx + 4)}
                        />
                        <label
                          className=" mx-2 w-100 form-check-label fz-13px fw-600"
                          htmlFor={classTime.day}
                        >
                          {classTime.day}
                        </label>
                      </div>
                      <div className="col-lg-4">
                        <input
                          type="time"
                          className="default-inpt cst-clock px-lg-2"
                          name="startTime"
                          defaultValue={classTime.startTime}
                          onChange={(e) => handleInputChange(e, indx + 4)}
                        />
                        {/* {day[indx + 4].endTime && day[indx + 4].startTime ? moment(day[indx + 4].startTime, 'h:mm').isBefore(moment(day[indx + 4].endTime, 'h:mm')) ? "" : <small className='text-danger'>Invalid</small> : ""} */}
                      </div>
                      <div className="col-lg-1">
                        <p className="m-0 p-0 fz-14px fw-600">to</p>
                      </div>
                      <div className="col-lg-4">
                        <input
                          type="time"
                          className="default-inpt cst-clock px-lg-2"
                          name="endTime"
                          defaultValue={classTime.endTime}
                          onChange={(e) => handleInputChange(e, indx + 4)}
                        />
                        {/* {day[indx + 4].endTime && day[indx + 4].startTime ? moment(day[indx + 4].endTime, 'h:mm').isAfter(moment(day[indx + 4].startTime, 'h:mm')) ? "" : <small className='text-danger'>Invalid</small> : ""} */}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="py-3"></div>
        <div className="d-flex justify-content-end">
          <button
            className="border-0 bg-secondary text-white fz-14px py-2 px-4 mx-2  rounded-3 text-center"
            type="button"
            disabled={loading ? true : false}
            onClick={() =>
              setIsSingleLiveCourseEditAtom({
                ...isSingleLiveCourseEditAtom,
                edit: false,
              })
            }
          >
            Cancel
          </button>
          <button
            type="submit"
            className=" border-0 bg-prime text-white fz-14px py-12px w-14 rounded-3 text-center"
            disabled={loading ? true : false}
          >
            {loading ? "Please Wait..." : "Save"}
          </button>
        </div>
        <div className="py-3"></div>
        {/* <pre>{JSON.stringify(day, null, 4)}</pre> */}
      </form>
    </>
  );
}

export default Step3LiveCourseEdit;
