import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { FiAlertCircle } from "react-icons/fi";
import { useRecoilState } from "recoil";
import swal from "sweetalert";
import { API_CALL } from "../../../../../api/ApiServiceCall";
import { API_CONSTANTS, API_METHOD } from "../../../../../common/constants";
import { goLiveCourseViewAtomState } from "../../../../atom/goLiveCourseView";
import RightArrow from "../../../../svg-icons/rightArrow";
import DateTimeEntryCheck from "./DateTimeEntryCheck";
import GetTimeDifference from "./GetTimeDifference";

function LiveCourseGoLive() {
  const [viewComponentForGoLive, setViewComponentForGoLive] = useRecoilState(
    goLiveCourseViewAtomState
  );
  const [goLiveCourse, setGoLiveCourse] = useState([]);
  const [getCompletedLiveCourseList, setCompletedLiveCourseClassList] =
    useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  // setIsLoading(true);
  //     getGoLiveCourse();
  //     getCompletedLiveCourseClass();
  // }, [])
  useEffect(() => {
    // getGoLiveCourse();
    // getCompletedLiveCourseClass();
    // console.log("mounted")
    // }, [])
    const fetchData = async () => {
      try {
        setIsLoading(true);
        await getGoLiveCourse();
        await getCompletedLiveCourseClass();
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        swal({
          title: "Error",
          text: error.message,
          icon: "error",
          className: "error-window",
          button: true,
        });
      }
    };
    fetchData();
  }, []);
  // useEffect(async() => {

  //     await fetchData();
  // }, []);
  const getGoLiveCourse = async () => {
    const courseCode = localStorage.getItem("courseCode");

    const request = {
      apiUrl:
        API_CONSTANTS.BASE_URL +
        API_CONSTANTS.GET_SCHEDULED_LIVE_COURSE_CLASS +
        `/?courseCode=${courseCode}`,
      method: API_METHOD.GET,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      setGoLiveCourse(result.data);
      // console.log("===========================>",result)
    } else if (result.status > 200) {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }
  };

  const getCompletedLiveCourseClass = async () => {
    const courseCode = localStorage.getItem("courseCode");

    const request = {
      apiUrl:
        API_CONSTANTS.BASE_URL +
        API_CONSTANTS.GET_COMPLETED_LIVE_COURSE_CLASS +
        `/?courseCode=${courseCode}`,
      method: API_METHOD.GET,
    };
    let result = await API_CALL(request);
    if (result.status === 200) {
      setCompletedLiveCourseClassList(result.data);
    } else if (result.status > 200) {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }
  };
  console.log("isLoading:", isLoading);
  let checkForData = true;
  if (goLiveCourse) {
    if (goLiveCourse.length == 0) {
      checkForData = false;
    }
  }

  const statusCodes = ["INREVIEW", "BANNED"];
  // if((moment()).isAfter(moment().set({ hour: 16, minute: 0, second: 0, millisecond: 0 }))) {
  //     "Start Live:  is yet to come."
  // }

  return (
    <div>
      {/* <pre>{JSON.stringify(goLiveCourse, null, 3)}</pre> */}
      {isLoading ? (
        <div className="d-flex align-items-center justify-content-center py-5 my-5">
          <span
            className="spinner-border spinner-border-sm text-secondary"
            role="status"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Loading...</span>
        </div>
      ) : (
        <>
          <div className="py-2"></div>
          <h6 className="text-muted"> Upcoming Classes</h6>
          {goLiveCourse
            ? goLiveCourse.map((goLiveItem, indx) => (
                <div
                  disabled={statusCodes.includes(goLiveItem?.liveClassStatus)}
                  title={
                    statusCodes.includes(goLiveItem?.liveClassStatus)
                      ? "InReview"
                      : ""
                  }
                  className="w-100 bg-transparent border-0 chp-list d-flex justify-content-md-between align-items-center "
                  key={goLiveItem?.weekDay + indx}
                >
                  <div className=" flex-column">
                    <h1 className="m-0 fz-16px fw-500">
                      {goLiveItem?.liveCaption ? (
                        ""
                      ) : (
                        <FiAlertCircle
                          className="mb-1 me-1"
                          color={"red"}
                          size={15}
                          title="Please save the live caption to receive mail notifications"
                        />
                      )}
                      {goLiveItem?.weekDay +
                        " : " +
                        moment(goLiveItem?.scheduledDate).format("DD/MM/YYYY") +
                        " " +
                        moment(goLiveItem?.startTime, "hh:mm").format(
                          "hh:mm A"
                        ) +
                        " To " +
                        moment(goLiveItem?.endTime, "hh:mm").format("hh:mm A")}
                    </h1>
                    {/* {moment(goLiveItem?.endTime, "hh:mm").format("hh:mm A")} */}
                    <p className="m-0 pt-1 text-muted text-start fz-13px fw-500">
                      {goLiveItem?.liveCaption}
                    </p>
                  </div>
                  <div>
                    <DateTimeEntryCheck goLiveItemData={goLiveItem} />
                    {/* {dateTimeEntryCheck({
                            "liveClassOrder": 9,
                            "liveCaption": "",
                            "scheduledDate": "2023-04-03T00:00:00.000Z",
                            "startTime": "10:00",
                            "endTime": "11:00",
                            "weekDay": "WEDNESDAY",
                            "liveClassStatus": "CREATED",
                            "saveAfterLive": false,
                            "isLiveCompleted": false,
                            "createdTime": "2023-03-22T04:14:36.522Z",
                            "resourceDetails": [],
                            "_id": "641a80ac19db64bf8264c284"
                        }, indx)} */}
                  </div>
                </div>
              ))
            : ""}
          {checkForData ? (
            <div className="py-3"></div>
          ) : (
            <div className="d-flex align-items-center justify-content-center py-5 my-5">
              <p className="text-muted fz-13px">
                {" "}
                No upcoming classes this week{" "}
              </p>
            </div>
          )}

          <h6 className="text-muted"> Previous Classes</h6>
          {getCompletedLiveCourseList &&
          getCompletedLiveCourseList.length > 0 ? (
            getCompletedLiveCourseList.map((cList, indx) => (
              <div
                className="chp-list d-flex justify-content-md-between align-items-center cursor-default"
                key={cList?.scheduledDate + indx}
              >
                <div className=" flex-column">
                  <h1 className="m-0 fz-16px fw-500" role="button">
                    {cList?.weekDay +
                      " : " +
                      moment(cList?.scheduledDate).format("DD/MM/YYYY") +
                      " " +
                      cList?.startTime +
                      " To " +
                      cList?.endTime}
                  </h1>
                  <p className="m-0 pt-1 text-muted fz-13px fw-500">
                    {cList?.liveCaption} :<GetTimeDifference cList={cList} />{" "}
                  </p>
                  {/* <pre>{JSON.stringify(cList,null,3)}</pre> */}
                </div>
                <div role="button">
                  <RightArrow size={8} />
                </div>
              </div>
            ))
          ) : (
            <div className="d-flex align-items-center justify-content-center py-5 my-5">
              <p className="text-muted fz-13px"> No Previous classes </p>
            </div>
          )}
        </>
      )}
    </div>
  );
}
export default LiveCourseGoLive;
