import { getAuth, signInWithPhoneNumber } from "firebase/auth";
import React, { useState } from "react";
import { useRecoilState } from "recoil";
import swal from "sweetalert";
import { API_CALL } from "../../../api/ApiServiceCall";
import { API_CONSTANTS, API_METHOD } from "../../../common/constants";
import { mobileLoginModalComponentState } from "../../atom/mobileOtpLoginModalComponent";
import ChooseAccount from "./ChooseAccount";
import Timer from "./Timer"; 
import OtpInput from "./OtpInput";

function VerifyMobileOtpLogin({ mobileNumber, closeModal }) {
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [MobileLoginModalComponentAtom, setMobileLoginModalComponentAtom] =
    useRecoilState(mobileLoginModalComponentState);

  const getAccountDetailByMobileNum = async () => {
    const requestBodyParam = JSON.stringify({
      mobileNumber: "+91" + mobileNumber,
      userType: "INSTRUCTOR",
    });
    const request = {
      apiUrl:
        API_CONSTANTS.BASE_URL +
        API_CONSTANTS.GET_ACCOUNT_DETAILS_BY_MOBILE_NUM,
      method: API_METHOD.POST,
      requestBodyParams: requestBodyParam,
    };
    let result = await API_CALL(request);
    setLoading(false);
    if (result.status === 200) {
      setMobileLoginModalComponentAtom(
        <ChooseAccount
          accountDetailData={result.data}
          closeModal={closeModal}
        />
      );
    } else {
      swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    const confirmationResult = window.confirmationResult;

    confirmationResult
      .confirm(otp)
      .then((result) => {
        const user = result.user;
        getAccountDetailByMobileNum();
      })
      .catch((error) => {
        setLoading(false);
        console.error(error.message);
        setError("OTP verification failed! please try again.");
      });
  };

  const handleTimerExpire = () => {
    setError("OTP expired! Please resend OTP.");
  };

  const resendOtp = () => {
    setError("");
    const auth = getAuth();
    const appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, "+91" + mobileNumber, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
      })
      .catch((error) => {
        console.error(error);
        setError("Failed to send OTP, please try again.");
      });
  };

  return (
    <div>
      <p className="fz-16px pb-1 text-black">
        We Sent 6 Digit OTP to {mobileNumber}
      </p>
      <form className="login-col" onSubmit={handleSubmit}>
        <label className="fz-15px text-prime">Enter the 6 digit OTP</label>
        <OtpInput otp={otp} setOtp={setOtp} />
        <div className="d-flex align-items-center justify-content-end mb-2 otp">
          <Timer
            initialMinutes={0}
            initialSeconds={60}
            onExpire={handleTimerExpire}
          />
          <button
            role="button"
            type="button"
            onClick={resendOtp}
            disabled={loading}
            className="ms-2 mb-0 w-auto border-0 bg-transparent"
          >
            Resend
          </button>
        </div>
        {error && (
          <small className="text-danger fw-500 pb-2 d-block">{error}</small>
        )}
        <button className="login-form-btn" type="submit" disabled={loading}>
          {loading ? "Please wait..." : "Verify"}
        </button>
      </form>
    </div>
  );
}

export default VerifyMobileOtpLogin;
