import { useState } from 'react'
import { Modal, Container } from 'react-bootstrap'
import { useForm } from "react-hook-form";
import { API_CONSTANTS } from '../../common/constants';

function ForgotPassword({ setSelected, setEmailid }) {
    const { register, handleSubmit, watch, reset, formState: { errors } } = useForm();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();

    const onSubmit = async (data) => {
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: data.email,
                userType: 'STUDENT'
            })
        }
        console.log("option check", requestOptions);
        fetch(API_CONSTANTS.BASE_URL + API_CONSTANTS.FORGOT_PASSWORD_OTP, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === 200) {
                    setEmailid(data.email);
                    console.log(result.data)
                    setLoading(false);
                    setSelected();
                    // reset();
                } else if (result.status > 200) {
                    setError(result.message);
                    setLoading(false);
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }
    return (
        <>
            <Container>
                <Modal.Header closeButton className='mh'>
                    <Modal.Title className='mdlt'>Forgot Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className='login-col' onSubmit={handleSubmit(onSubmit)}>
                        <label htmlFor='frg_pss_email'>Type Your Email<span className='text-danger'>*</span></label>
                        <input className='mb-2' type="email" placeholder='Type Your Email' id="frg_pss_email" {...register("email", { required: true })} />
                        <small className='text-danger mb-2 d-block fz-12px fw-500'> {errors.email?.type === 'required' && "Please Enter your Email"}</small>
                        <button className='login-form-btn' disabled={loading ? true : false}>{loading ? "Please wait..." : 'Submit'}</button>
                        <small className='text-danger mb-2 d-block fz-15px fw-500 text-center'> {error}</small>
                    </form>
                </Modal.Body>
            </Container>
        </>
    )
}

export default ForgotPassword